/*
 * GoJS v1.4.27 JavaScript Library for HTML Canvas Diagrams
 * Northwoods Software, http://www.nwoods.com/
 * GoJS and Northwoods Software are registered trademarks of Northwoods Software Corporation.
 * Copyright (C) 1998-2015 by Northwoods Software Corporation.  All Rights Reserved.
 * THIS SOFTWARE IS LICENSED.  THE LICENSE AGREEMENT IS AT: http://www.gojs.net/1.4.27/doc/license.html.
 */
(function(window) { var aa,ba={};if(void 0===document.createElement("canvas").getContext)throw window.console&&window.console.log("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode."),Error("The HTML Canvas element is not supported in this browser,or this browser is in Compatibility mode.");if(!Object.prototype.__defineGetter__&&!Object.defineProperty)throw Error("GoJS requires a newer version of JavaScript");
Object.prototype.__defineGetter__&&!Object.defineProperty&&(Object.defineProperty=function(a,b,c){c.get&&a.__defineGetter__(b,c.get);c.set&&a.__defineSetter__(b,c.set)});Object.prototype.__lookupGetter__&&!Object.getOwnPropertyDescriptor&&(Object.getOwnPropertyDescriptor=function(a,b){return{get:a.__lookupGetter__(b),set:a.__lookupSetter__(b)}});Object.getPrototypeOf||(Object.getPrototypeOf=function(a){return a.__proto__});Object.isFrozen||(Object.isFrozen=function(a){return!0===a.pH});
Object.freeze||(Object.freeze=function(a){a.pH=!0});Array.isArray||(Array.isArray=function(a){return"[object Array]"===Object.prototype.toString.call(a)});Function.prototype.bind||(Function.prototype.bind=function(a){function b(){return g.apply(a,f.concat(d.call(arguments)))}function c(){}var d=Array.prototype.slice,f=d.call(arguments,1),g=this;c.prototype=this.prototype;b.prototype=new c;return b});
(function(){for(var a=0,b=["ms","moz","webkit","o"],c=0;c<b.length&&!window.requestAnimationFrame;++c)window.requestAnimationFrame=window[b[c]+"RequestAnimationFrame"],window.cancelAnimationFrame=window[b[c]+"CancelAnimationFrame"]||window[b[c]+"CancelRequestAnimationFrame"];window.requestAnimationFrame||(window.requestAnimationFrame=function(b){var c=(new Date).getTime(),g=Math.max(8,16-(c-a)),h=window.setTimeout(function(){b(c+g)},g);a=c+g;return h});window.cancelAnimationFrame||(window.cancelAnimationFrame=
function(a){clearTimeout(a)})})();var e={Rz:!1,uK:!1,FF:!1,QE:!1,ZH:!1,assert:!1,hK:null,kK:function(){},mK:function(){},lK:function(){},jK:function(){},rK:function(){},nK:function(){},pK:function(){},oK:function(){},iK:!1,OK:!1,LK:!1,MK:!1,BI:!1,qK:function(){}},e=null;ba.Debug=null;
var t={ji:Object.freeze([]),gA:{},CE:0,Gw:{},fA:0,$s:"...",kt:{},Lw:0,lB:[],lx:null,ln:document.createElement("canvas").getContext("2d"),Tp:"",fK:document.createElement("img"),$z:[],gB:"ontouchstart"in window,RK:"ongesturestart"in window,lE:!1,Ts:null,Us:null,Ss:null,lw:"",Um:window.navigator&&0<window.navigator.userAgent.indexOf("534.30")&&0<window.navigator.userAgent.indexOf("Android"),Qw:window.navigator&&0<window.navigator.userAgent.indexOf("MSIE 10.0"),tA:window.navigator&&0<window.navigator.userAgent.indexOf("Trident/7"),
Vm:0<=navigator.platform.toUpperCase().indexOf("MAC"),WI:navigator.platform.match(/(iPhone|iPod|iPad)/i),TG:!1,WG:!1,VG:!1,UG:!1,mE:null,Zp:!1,RG:!1,SG:!1,iE:function(a,b,c){var d;return function(){var f=this,g=arguments;clearTimeout(d);d=setTimeout(function(){d=null;c||a.apply(f,g)},b);c&&!d&&a.apply(f,g)}},m:function(a){e&&e.ZH&&alert(a);throw Error(a);},L:function(a,b){if(a.nb){var c="The object is frozen, so its properties cannot be set: "+a.toString();void 0!==b&&(c+="  to value: "+b);t.m(c)}},
l:function(a,b,c,d){a instanceof b||(c=t.$h(c),void 0!==d&&(c+="."+d),t.bc(a,b,c))},i:function(a,b,c,d){typeof a!==b&&(c=t.$h(c),void 0!==d&&(c+="."+d),t.bc(a,b,c))},q:function(a,b,c){"number"===typeof a&&isFinite(a)||(b=t.$h(b),void 0!==c&&(b+="."+c),t.m(b+" must be a real number type, and not NaN or Infinity: "+a))},Aa:function(a,b,c,d){a instanceof ca&&a.Re===b||(c=t.$h(c),void 0!==d&&(c+="."+d),t.bc(a,"a constant of class "+t.xg(b),c))},To:function(a,b){"string"===typeof a?da(a)||t.m('Color "'+
a+'" is not a valid color string for '+b):a instanceof ea||t.m("Value for "+b+" must be a color string or a Brush, not "+a)},bc:function(a,b,c,d){b=t.$h(b);c=t.$h(c);void 0!==d&&(c+="."+d);"string"===typeof a?t.m(c+" value is not an instance of "+b+': "'+a+'"'):t.m(c+" value is not an instance of "+b+": "+a)},ka:function(a,b,c,d){c=t.$h(c);void 0!==d&&(c+="."+d);t.m(c+" is not in the range "+b+": "+a)},ad:function(a){t.m(t.xg(a)+" constructor cannot take any arguments.")},Qb:function(a){t.m("Collection was modified during iteration: "+
a.toString()+"\n  Perhaps you should iterate over a copy of the collection,\n  or you could collect items to be removed from the collection after the iteration.")},trace:function(a){window&&window.console&&window.console.log(a)},qb:function(a){return"object"===typeof a&&null!==a},zc:null,Ym:function(a,b,c,d){for(b=0;b<a.length;b++){var f=a[b];t.qb(f)&&(f=t.yf(f,c,d));a[b]=f}return d||t.zc(a)?a:window.ko.observableArray(a)},yf:function(a,b,c){if(a)for(var d in a)"__gohashid"!==d&&(a[d]=t.hJ(a,d,b,
c));return a},hJ:function(a,b,c,d){var f=a[b],g;g=t.zc(f)?f:Array.isArray(f)?t.Ym(f,b,c,d):window.ko.observable(f);if(d){if(d=g.DB){h=c.__gohashid;d[h]&&(d[h].dispose(),delete d[h]);var h=!1,k;for(k in d){h=!0;break}}!h&&g.pu&&(g.pu.dispose(),delete g.pu)}else{g.pu||(g.pu=g.subscribe(function(a){g.to=a},null,"beforeChange"));d=g.DB;d||(g.DB=d={});var h=c.__gohashid;d[h]||(d[h]=g.subscribe(function(d){c.mx(a,b,g.to,d)}))}return g},isArray:function(a){return Array.isArray(a)||t.zc&&t.zc(a)||a instanceof
NodeList||a instanceof HTMLCollection},YI:function(a){return Array.isArray(a)||t.zc&&t.zc(a)},Rs:function(a,b,c){t.isArray(a)||t.bc(a,"Array or NodeList or HTMLCollection",b,c)},ub:function(a){t.zc&&t.zc(a)&&(a=a());return a.length},lb:function(a,b){t.zc&&t.zc(a)&&(a=a());Array.isArray(a);return a[b]},MD:function(a,b,c){t.zc&&t.zc(a)&&(a=a());Array.isArray(a)?a[b]=c:t.m("Cannot replace an object in an HTMLCollection or NodeList at "+b)},Pz:function(a,b){t.zc&&t.zc(a)&&(a=a());if(Array.isArray(a))return a.indexOf(b);
for(var c=a.length,d=0;d<c;d++)if(a[d]===b)return d;return-1},Ti:function(a,b,c){t.zc&&t.zc(a)&&(a=a());Array.isArray(a)?b>=a.length?a.push(c):a.splice(b,0,c):t.m("Cannot insert an object into an HTMLCollection or NodeList: "+c+" at "+b)},Ui:function(a,b){t.zc&&t.zc(a)&&(a=a());Array.isArray(a)?b>=a.length?a.pop():a.splice(b,1):t.m("Cannot remove an object from an HTMLCollection or NodeList at "+b)},by:[],K:function(){var a=t.by.pop();return void 0===a?new w:a},mc:function(a,b){var c=t.by.pop();if(void 0===
c)return new w(a,b);c.x=a;c.y=b;return c},B:function(a){t.by.push(a)},zB:[],Cl:function(){var a=t.zB.pop();return void 0===a?new fa:a},ck:function(a){t.zB.push(a)},ey:[],Gf:function(){var a=t.ey.pop();return void 0===a?new x:a},ik:function(a,b,c,d){var f=t.ey.pop();if(void 0===f)return new x(a,b,c,d);f.x=a;f.y=b;f.width=c;f.height=d;return f},hc:function(a){t.ey.push(a)},AB:[],oh:function(){var a=t.AB.pop();return void 0===a?new ga:a},We:function(a){t.AB.push(a)},gy:null,u:function(){var a=t.gy;return null!==
a?(t.gy=null,a):new ha},v:function(a){a.reset();t.gy=a},yB:[],Db:function(){var a=t.yB.pop();return void 0===a?[]:a},ya:function(a){a.length=0;t.yB.push(a)},BB:1,Ac:function(a){a.__gohashid=t.BB++},jt:function(a){var b=a.__gohashid;void 0===b&&(b=t.BB++,a.__gohashid=b);return b},od:function(a){return a.__gohashid},g:function(a,b,c){"name"!==b&&"length"!==b&&(a[b]=c)},ga:function(a,b){b.oy=a;ba[a]=b},Ka:function(a,b){function c(){}c.prototype=b.prototype;a.prototype=new c;a.prototype.constructor=a},
Xh:function(a){a.tH=!0},defineProperty:function(a,b,c,d,f){t.i(a,"function","Util.defineProperty:classfunc");t.i(b,"object","Util.defineProperty:propobj");t.i(c,"function","Util.defineProperty:getter");t.i(d,"function","Util.defineProperty:setter");for(var g in b){var h=b[g];b={get:c,set:d};if(void 0!==f)for(var k in f)b[k]=f[k];Object.defineProperty(a.prototype,g,b);f=Object.getOwnPropertyDescriptor(a.prototype,g);h&&f&&Object.defineProperty(a.prototype,h,f);if(e&&h){var l=h.charAt(0).toUpperCase()+
h.slice(1);h===l&&t.m('Defining capitalized property "'+l+'"!?');Object.defineProperty(a.prototype,l,{get:function(){t.Qw||t.tA||t.trace('Getting the property "'+l+'" is probably not what you intended: it is capitalized but should be spelled "'+h+'"')},set:function(){t.m('Setting the property "'+l+'" is probably not what you intended: it is capitalized but should be spelled "'+h+'"')}})}break}},A:function(a,b,c,d){t.i(a,"function","Util.defineReadOnlyProperty:classfunc");t.i(b,"object","Util.defineReadOnlyProperty:propobj");
t.i(c,"function","Util.defineReadOnlyProperty:getter");for(var f in b){var g=b[f];b={get:c,set:function(a){t.m('The property "'+g+'" is read-only and cannot be set to '+a)}};if(void 0!==d)for(var h in d)b[h]=d[h];Object.defineProperty(a.prototype,f,b);d=Object.getOwnPropertyDescriptor(a.prototype,f);g&&d&&Object.defineProperty(a.prototype,g,d);if(e&&g){var k=g.charAt(0).toUpperCase()+g.slice(1);Object.defineProperty(a.prototype,k,{get:function(){t.Qw||t.tA||t.trace('Getting the property "'+k+'" is probably not what you intended: it is capitalized but should be spelled "'+
g+'"')},set:function(){t.m('Setting the read-only property "'+k+'" is probably not what you intended: it is capitalized but should be spelled "'+g+'", and cannot be set anyway')}})}break}},Vd:function(a,b){for(var c in b)b[c]=!0;a.prototype.WC=b},$h:function(a){return void 0===a?"":"string"===typeof a?a:"function"===typeof a?t.xg(a):null===a?"*":""},xg:function(a){if("function"===typeof a){if(a.oy)return a.oy;if(a.name)return a.name;var b=a.toString(),c=b.indexOf("(");if(b=b.substring(9,c).trim())return a.oy=
b}else if("object"===typeof a&&a.constructor)return t.xg(a.constructor);return typeof a},w:function(a,b,c){t.i(a,"function","Util.defineEnumValue:classfunc");t.i(b,"string","Util.defineEnumValue:name");t.i(c,"number","Util.defineEnumValue:num");c=new ca(a,b,c);Object.freeze(c);a[b]=c;var d=a.cu;d||(d=new ia("string",ca),a.cu=d);d.add(b,c);return c},Pm:function(a,b){if(!b)return null;t.i(a,"function","Util.findEnumValueForName:classfunc");t.i(b,"string","Util.findEnumValueForName:name");var c=a.cu;
return c?c.za(b):null},gI:function(a,b,c,d){var f={},g;for(g in a){for(var h=!1,k=1;k<arguments.length;k++)if(arguments[k]===g){h=!0;break}h||(f[g]=a[g])}return f},ib:function(a,b){if(!a||!b)return null;var c=void 0;try{"function"===typeof b?c=b(a):"function"===typeof a.getAttribute?(c=a.getAttribute(b),null===c&&(c=void 0)):(c=a[b],t.zc&&t.zc(c)&&(c=c()))}catch(d){e&&t.trace("property get error: "+d.toString())}return c},Qa:function(a,b,c){if(a&&b)try{if("function"===typeof b)b(a,c);else if("function"===
typeof a.setAttribute)a.setAttribute(b,c);else if(t.zc){var d=a[b];t.zc(d)?d(c):a[b]=c}else a[b]=c}catch(f){e&&t.trace("property set error: "+f.toString())}},tx:function(a,b){t.i(a,"object","Setting properties requires Objects as arguments");t.i(b,"object","Setting properties requires Objects as arguments");var c=e;c||(e=t);var d=a instanceof y,f=a instanceof z,g;for(g in b){g||t.m("Setting properties requires non-empty property names");var h=a,k=g;if(d||f){var l=g.indexOf(".");if(0<l){var m=g.substring(0,
l);d?h=a.oe(m):(h=a[m])||(h=a.wb[m]);h?k=g.substr(l+1):t.m("Unable to find object named: "+m+" in "+a.toString()+" when trying to set property: "+g)}}if("_"!==k[0]&&!t.Jw(h,k))if(f&&t.Jw(a.wb,k))h=a.wb;else if(f&&ja(a,k)){a.Jz(k,b[k]);continue}else t.m('Trying to set undefined property "'+k+'" on object: '+h.toString());h[k]=b[g];"_"===k[0]&&(l=h.Ml,l||(l=[],h.Ml=l),l.push(k))}e=c;return a},Jw:function(a,b){for(var c=Object.getPrototypeOf(a);c&&c!==Function;){if(c.hasOwnProperty(b))return!0;var d=
c.WC;if(d&&d[b])return!0;c=Object.getPrototypeOf(c)}return!1},SD:function(a,b){if(!t.qb(b)||b instanceof Element||b instanceof CanvasRenderingContext2D)return"";var c="",d;for(d in b)if("string"!==typeof d)""===c&&(c=b+"\n"),c+="  "+d+" is not a string property\n";else if("_"!==d.charAt(0)&&!(2>=d.length)){var f=t.ib(b,d);null===f||"function"===typeof f||t.Jw(b,d)||(""===c&&(c=b+"\n"),c+='  unknown property "'+d+'" has value: '+f+" at "+a+"\n")}return c},jw:function(a,b){if(b&&"number"!==typeof b&&
"string"!==typeof b&&"boolean"!==typeof b&&"function"!==typeof b)if(void 0!==t.od(b)){if(!t.Gv.contains(b))if(t.Gv.add(b),t.gv.add(t.SD(a,b)),b instanceof B||b instanceof ma||b instanceof ia)for(var c=b.k;c.next();)t.jw(a+"["+c.key+"]",c.value);else for(c in b){var d=t.ib(b,c);if(void 0!==d&&null!==d&&t.qb(d)&&d!==b.WC){if(b instanceof na){if(d===b.no)continue}else if(b instanceof y){if("data"===c||d===b.Ol)continue;if("itemArray"===c||d===b.yi)continue;if(b instanceof C&&d===b.Fj)continue}else if(!(b instanceof
z))if(b instanceof oa){if("archetypeGroupData"===c||d===b.jy)continue}else if(b instanceof qa){if("archetypeLinkData"===c||d===b.ly)continue;if("archetypeLabelNodeData"===c||d===b.ky)continue}else if(b instanceof ra){if("archetypeNodeData"===c||d===b.xk)continue}else if(b instanceof E){if("nodeDataArray"===c||d===b.Me)continue;if("linkDataArray"===c||d===b.Xg||d===b.em)continue;if(d===b.Bc)continue;if(d===b.xh)continue}else if(b instanceof ta||b instanceof ua||b instanceof va)continue;t.jw(a+"."+
c,d)}}}else if(Array.isArray(b))for(c=0;c<b.length;c++)t.jw(a+"["+c+"]",b[c]);else t.gv.add(t.SD(a,b))},bI:function(a){void 0===t.Gv?t.Gv=new ma:t.Gv.clear();t.gv=new xa;t.jw("",a);a=t.gv.toString();t.gv=null;return a},EJ:function(a){for(var b=[],c=0;256>c;c++)b[c]=c;for(var d=0,f,c=0;256>c;c++)d=(d+b[c]+119)%256,f=b[c],b[c]=b[d],b[d]=f;for(var d=c=0,g="",h=0;h<a.length;h++)c=(c+1)%256,d=(d+b[c])%256,f=b[c],b[c]=b[d],b[d]=f,g+=String.fromCharCode(a.charCodeAt(h)^b[(b[c]+b[d])%256]);return g},OI:function(a){for(var b=
[],c=0;256>c;c++)b["0123456789abcdef".charAt(c>>4)+"0123456789abcdef".charAt(c&15)]=String.fromCharCode(c);a.length%2&&(a="0"+a);for(var d=[],f=0,c=0;c<a.length;c+=2)d[f++]=b[a.substr(c,2)];a=d.join("");return""===a?"0":a},Ha:function(a){return t.EJ(t.OI(a))},Gl:null,dw:"74ad01fef324148d4e8a58765a6a32b7",cI:"28fb4e",dI:"2bf840"};
t.Gl=function(){var a=document.createElement("canvas"),b=a.getContext("2d");b[t.Ha("7ca11abfd022028846")]=t.Ha("398c3597c01238");for(var c=["5da73c80a3330d854f9e5e671d6633","32ab5ff3b26f42dc0ed90f22412913b54ae6247590da4bb21c324ba3a84e385776","54a702f3e53909c447824c6706603faf4cfb236cdfda5de14c134ba1a95a2d4c7cc6f93c1387","74bf19bce72555874c86"],d=1;5>d;d++)b[t.Ha("7ca11abfd7330390")](t.Ha(c[d-1]),10,15*d+0);b[t.Ha("7ca11abfd022028846")]=t.Ha("39f046ebb36e4b");for(d=1;5>d;d++)b[t.Ha("7ca11abfd7330390")](t.Ha(c[d-
1]),10,15*d+0);if(4!==c.length||"5"!==c[0][0]||"7"!==c[3][0])t.w=function(a,b){var c=new ca(a,b,2);Object.freeze(c);a[b]=c;var d=a.cu;d||(d=new ia("string",ca),a.cu=d);d.add(b,c);return c};return a}();function ca(a,b,c){t.Ac(this);this.JB=a;this.Zb=b;this.yH=c}ca.prototype.toString=function(){return t.xg(this.JB)+"."+this.Zb};t.A(ca,{Re:"classType"},function(){return this.JB});t.A(ca,{name:"name"},function(){return this.Zb});t.A(ca,{value:"value"},function(){return this.yH});
function xa(){this.IB=[]}xa.prototype.toString=function(){return this.IB.join("")};xa.prototype.add=function(a){a&&this.IB.push(a)};function ya(){}t.A(ya,{k:"iterator"},function(){return this});ya.prototype.reset=ya.prototype.reset=function(){};ya.prototype.next=ya.prototype.hasNext=ya.prototype.next=function(){return!1};ya.prototype.first=ya.prototype.$a=function(){return null};ya.prototype.any=function(){return!1};ya.prototype.all=function(){return!0};ya.prototype.each=function(){};
t.A(ya,{count:"count"},function(){return 0});ya.prototype.cg=function(){};ya.prototype.toString=function(){return"EmptyIterator"};t.ag=new ya;function Aa(a){this.key=-1;this.value=a}t.Vd(Aa,{key:!0,value:!0});t.A(Aa,{k:"iterator"},function(){return this});Aa.prototype.reset=Aa.prototype.reset=function(){this.key=-1};Aa.prototype.next=Aa.prototype.hasNext=Aa.prototype.next=function(){return-1===this.key?(this.key=0,!0):!1};Aa.prototype.first=Aa.prototype.$a=function(){this.key=0;return this.value};
Aa.prototype.any=function(a){this.key=-1;return a(this.value)};Aa.prototype.all=function(a){this.key=-1;return a(this.value)};Aa.prototype.each=function(a){this.key=-1;a(this.value)};t.A(Aa,{count:"count"},function(){return 1});Aa.prototype.cg=function(){this.value=null};Aa.prototype.toString=function(){return"SingletonIterator("+this.value+")"};function Ba(a){this.Dd=a;this.Ij=null;this.reset()}t.Vd(Ba,{key:!0,value:!0});t.A(Ba,{k:"iterator"},function(){return this});t.g(Ba,"predicate",Ba.prototype.tl);
t.defineProperty(Ba,{tl:"predicate"},function(){return this.Ij},function(a){this.Ij=a});Ba.prototype.reset=Ba.prototype.reset=function(){var a=this.Dd;a.Cd=null;this.Ya=a.V;this.Vc=-1};Ba.prototype.next=Ba.prototype.hasNext=Ba.prototype.next=function(){var a=this.Dd;a.V!==this.Ya&&t.Qb(a);var a=a.n,b=a.length,c=++this.Vc,d=this.Ij;if(null!==d)for(;c<b;){var f=a[c];if(d(f))return this.key=this.Vc=c,this.value=f,!0;c++}else{if(c<b)return this.key=c,this.value=a[c],!0;this.cg()}return!1};
Ba.prototype.first=Ba.prototype.$a=function(){var a=this.Dd;this.Ya=a.V;this.Vc=0;var a=a.n,b=a.length,c=this.Ij;if(null!==c){for(var d=0;d<b;){var f=a[d];if(c(f))return this.key=this.Vc=d,this.value=f;d++}return null}return 0<b?(f=a[0],this.key=0,this.value=f):null};Ba.prototype.any=function(a){var b=this.Dd;b.Cd=null;var c=b.V;this.Vc=-1;for(var d=b.n,f=d.length,g=this.Ij,h=0;h<f;h++){var k=d[h];if(null===g||g(k)){if(a(k))return!0;b.V!==c&&t.Qb(b)}}return!1};
Ba.prototype.all=function(a){var b=this.Dd;b.Cd=null;var c=b.V;this.Vc=-1;for(var d=b.n,f=d.length,g=this.Ij,h=0;h<f;h++){var k=d[h];if(null===g||g(k)){if(!a(k))return!1;b.V!==c&&t.Qb(b)}}return!0};Ba.prototype.each=function(a){var b=this.Dd;b.Cd=null;var c=b.V;this.Vc=-1;for(var d=b.n,f=d.length,g=this.Ij,h=0;h<f;h++){var k=d[h];if(null===g||g(k))a(k),b.V!==c&&t.Qb(b)}};t.A(Ba,{count:"count"},function(){var a=this.Ij;if(null!==a){for(var b=0,c=this.Dd.n,d=c.length,f=0;f<d;f++)a(c[f])&&b++;return b}return this.Dd.n.length});
Ba.prototype.cg=function(){this.key=-1;this.value=null;this.Ya=-1;this.Ij=null;this.Dd.Cd=this};Ba.prototype.toString=function(){return"ListIterator@"+this.Vc+"/"+this.Dd.count};function Ca(a){this.Dd=a;this.reset()}t.Vd(Ca,{key:!0,value:!0});t.A(Ca,{k:"iterator"},function(){return this});Ca.prototype.reset=Ca.prototype.reset=function(){var a=this.Dd;a.fo=null;this.Ya=a.V;this.Vc=a.n.length};
Ca.prototype.next=Ca.prototype.hasNext=Ca.prototype.next=function(){var a=this.Dd;a.V!==this.Ya&&t.Qb(a);var b=--this.Vc;if(0<=b)return this.key=b,this.value=a.n[b],!0;this.cg();return!1};Ca.prototype.first=Ca.prototype.$a=function(){var a=this.Dd;this.Ya=a.V;var b=a.n;this.Vc=a=b.length-1;return 0<=a?(b=b[a],this.key=a,this.value=b):null};Ca.prototype.any=function(a){var b=this.Dd;b.fo=null;var c=b.V,d=b.n,f=d.length;this.Vc=f;for(f-=1;0<=f;f++){if(a(d[f]))return!0;b.V!==c&&t.Qb(b)}return!1};
Ca.prototype.all=function(a){var b=this.Dd;b.fo=null;var c=b.V,d=b.n,f=d.length;this.Vc=f;for(f-=1;0<=f;f++){if(!a(d[f]))return!1;b.V!==c&&t.Qb(b)}return!0};Ca.prototype.each=function(a){var b=this.Dd;b.fo=null;var c=b.V,d=b.n,f=d.length;this.Vc=f;for(f-=1;0<=f;f++)a(d[f]),b.V!==c&&t.Qb(b)};t.A(Ca,{count:"count"},function(){return this.Dd.n.length});Ca.prototype.cg=function(){this.key=-1;this.value=null;this.Ya=-1;this.Dd.fo=this};
Ca.prototype.toString=function(){return"ListIteratorBackwards("+this.Vc+"/"+this.Dd.count+")"};
function B(a){t.Ac(this);this.nb=!1;this.n=[];this.V=0;this.fo=this.Cd=null;void 0===a||null===a?this.da=null:"string"===typeof a?"object"===a||"string"===a||"number"===a||"boolean"===a||"function"===a?this.da=a:t.ka(a,"the string 'object', 'number', 'string', 'boolean', or 'function'","List constructor: type"):"function"===typeof a?this.da=a===Object?"object":a===String?"string":a===Number?"number":a===Boolean?"boolean":a===Function?"function":a:t.ka(a,"null, a primitive type name, or a class type",
"List constructor: type")}t.ga("List",B);B.prototype.Pg=function(a){null!==this.da&&("string"===typeof this.da?typeof a===this.da&&null!==a||t.bc(a,this.da):a instanceof this.da||t.bc(a,this.da))};B.prototype.Qd=function(){var a=this.V;a++;999999999<a&&(a=0);this.V=a};B.prototype.freeze=B.prototype.freeze=function(){this.nb=!0;return this};B.prototype.thaw=B.prototype.Ma=function(){this.nb=!1;return this};B.prototype.toString=function(){return"List("+t.$h(this.da)+")#"+t.od(this)};
B.prototype.add=B.prototype.add=function(a){null!==a&&(e&&this.Pg(a),t.L(this,a),this.n.push(a),this.Qd())};B.prototype.addAll=B.prototype.Oe=function(a){if(null===a)return this;t.L(this);var b=this.n;if(t.isArray(a))for(var c=t.ub(a),d=0;d<c;d++){var f=t.lb(a,d);e&&this.Pg(f);b.push(f)}else for(a=a.k;a.next();)f=a.value,e&&this.Pg(f),b.push(f);this.Qd();return this};B.prototype.clear=B.prototype.clear=function(){t.L(this);this.n.length=0;this.Qd()};
B.prototype.contains=B.prototype.has=B.prototype.contains=function(a){if(null===a)return!1;e&&this.Pg(a);return-1!==this.n.indexOf(a)};B.prototype.indexOf=B.prototype.indexOf=function(a){if(null===a)return-1;e&&this.Pg(a);return this.n.indexOf(a)};B.prototype.elt=B.prototype.get=B.prototype.wa=function(a){e&&t.q(a,B,"elt:i");var b=this.n;(0>a||a>=b.length)&&t.ka(a,"0 <= i < length",B,"elt:i");return b[a]};
B.prototype.setElt=B.prototype.set=B.prototype.Mg=function(a,b){e&&(this.Pg(b),t.q(a,B,"setElt:i"));var c=this.n;(0>a||a>=c.length)&&t.ka(a,"0 <= i < length",B,"setElt:i");t.L(this,a);c[a]=b};B.prototype.first=B.prototype.$a=function(){var a=this.n;return 0===a.length?null:a[0]};B.prototype.any=function(a){for(var b=this.n,c=this.V,d=b.length,f=0;f<d;f++){if(a(b[f]))return!0;this.V!==c&&t.Qb(this)}return!1};
B.prototype.all=function(a){for(var b=this.n,c=this.V,d=b.length,f=0;f<d;f++){if(!a(b[f]))return!1;this.V!==c&&t.Qb(this)}return!0};B.prototype.each=function(a){for(var b=this.n,c=this.V,d=b.length,f=0;f<d;f++)a(b[f]),this.V!==c&&t.Qb(this)};B.prototype.insertAt=B.prototype.Jd=function(a,b){e&&(this.Pg(b),t.q(a,B,"insertAt:i"));0>a&&t.ka(a,">= 0",B,"insertAt:i");t.L(this,a);var c=this.n;a>=c.length?c.push(b):c.splice(a,0,b);this.Qd();return!0};
B.prototype.remove=B.prototype["delete"]=B.prototype.remove=function(a){if(null===a)return!1;e&&this.Pg(a);t.L(this,a);var b=this.n;a=b.indexOf(a);if(-1===a)return!1;a===b.length-1?b.pop():b.splice(a,1);this.Qd();return!0};B.prototype.removeAt=B.prototype.qd=function(a){e&&t.q(a,B,"removeAt:i");var b=this.n;(0>a||a>=b.length)&&t.ka(a,"0 <= i < length",B,"removeAt:i");t.L(this,a);a===b.length-1?b.pop():b.splice(a,1);this.Qd()};
B.prototype.removeRange=B.prototype.removeRange=function(a,b){e&&(t.q(a,B,"removeRange:from"),t.q(b,B,"removeRange:to"));var c=this.n;(0>a||a>=c.length)&&t.ka(a,"0 <= from < length",B,"elt:from");(0>b||b>=c.length)&&t.ka(b,"0 <= to < length",B,"elt:to");t.L(this,a);var d=c.slice((b||a)+1||c.length);c.length=0>a?c.length+a:a;c.push.apply(c,d);this.Qd()};B.prototype.copy=function(){for(var a=new B(this.da),b=this.n,c=this.count,d=0;d<c;d++)a.add(b[d]);return a};
B.prototype.toArray=B.prototype.Ie=function(){for(var a=this.n,b=this.count,c=Array(b),d=0;d<b;d++)c[d]=a[d];return c};B.prototype.toSet=function(){for(var a=new ma(this.da),b=this.n,c=this.count,d=0;d<c;d++)a.add(b[d]);return a};B.prototype.sort=B.prototype.sort=function(a){e&&t.i(a,"function",B,"sort:sortfunc");t.L(this);this.n.sort(a);this.Qd();return this};
B.prototype.sortRange=B.prototype.Up=function(a,b,c){var d=this.n,f=d.length;void 0===b&&(b=0);void 0===c&&(c=f);e&&(t.i(a,"function",B,"sortRange:sortfunc"),t.q(b,B,"sortRange:from"),t.q(c,B,"sortRange:to"));t.L(this);var g=c-b;if(1>=g)return this;(0>b||b>=f-1)&&t.ka(b,"0 <= from < length",B,"sortRange:from");if(2===g)return c=d[b],f=d[b+1],0<a(c,f)&&(d[b]=f,d[b+1]=c,this.Qd()),this;if(0===b)if(c>=f)d.sort(a);else for(g=d.slice(0,c),g.sort(a),a=0;a<c;a++)d[a]=g[a];else if(c>=f)for(g=d.slice(b),g.sort(a),
a=b;a<f;a++)d[a]=g[a-b];else for(g=d.slice(b,c),g.sort(a),a=b;a<c;a++)d[a]=g[a-b];this.Qd();return this};B.prototype.reverse=B.prototype.reverse=function(){t.L(this);this.n.reverse();this.Qd();return this};t.A(B,{count:"count"},function(){return this.n.length});t.A(B,{size:"size"},function(){return this.n.length});t.A(B,{length:"length"},function(){return this.n.length});t.A(B,{k:"iterator"},function(){if(0>=this.n.length)return t.ag;var a=this.Cd;return null!==a?(a.reset(),a):new Ba(this)});
t.A(B,{Xm:"iteratorBackwards"},function(){if(0>=this.n.length)return t.ag;var a=this.fo;return null!==a?(a.reset(),a):new Ca(this)});function Da(a){this.p=a;this.reset()}t.Vd(Da,{key:!0,value:!0});t.A(Da,{k:"iterator"},function(){return this});Da.prototype.reset=Da.prototype.reset=function(){var a=this.p;a.Cd=null;this.Ya=a.V;this.yb=null};
Da.prototype.next=Da.prototype.hasNext=Da.prototype.next=function(){var a=this.p;a.V!==this.Ya&&t.Qb(a);var b=this.yb,b=null===b?a.cb:b.Tb;if(null!==b)return this.yb=b,this.value=b.value,this.key=b.key,!0;this.cg();return!1};Da.prototype.first=Da.prototype.$a=function(){var a=this.p;this.Ya=a.V;a=a.cb;if(null!==a){this.yb=a;var b=a.value;this.key=a.key;return this.value=b}return null};
Da.prototype.any=function(a){var b=this.p;b.Cd=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(a(d.value))return!0;b.V!==c&&t.Qb(b);d=d.Tb}return!1};Da.prototype.all=function(a){var b=this.p;b.Cd=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(!a(d.value))return!1;b.V!==c&&t.Qb(b);d=d.Tb}return!0};Da.prototype.each=function(a){var b=this.p;b.Cd=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;)a(d.value),b.V!==c&&t.Qb(b),d=d.Tb};t.A(Da,{count:"count"},function(){return this.p.ed});
Da.prototype.cg=function(){this.value=this.key=null;this.Ya=-1;this.p.Cd=this};Da.prototype.toString=function(){return null!==this.yb?"SetIterator@"+this.yb.value:"SetIterator"};
function ma(a){t.Ac(this);this.nb=!1;void 0===a||null===a?this.da=null:"string"===typeof a?"object"===a||"string"===a||"number"===a?this.da=a:t.ka(a,"the string 'object', 'number' or 'string'","Set constructor: type"):"function"===typeof a?this.da=a===Object?"object":a===String?"string":a===Number?"number":a:t.ka(a,"null, a primitive type name, or a class type","Set constructor: type");this.fd={};this.ed=0;this.Cd=null;this.V=0;this.Ih=this.cb=null}t.ga("Set",ma);
ma.prototype.Pg=function(a){null!==this.da&&("string"===typeof this.da?typeof a===this.da&&null!==a||t.bc(a,this.da):a instanceof this.da||t.bc(a,this.da))};ma.prototype.Qd=function(){var a=this.V;a++;999999999<a&&(a=0);this.V=a};ma.prototype.freeze=ma.prototype.freeze=function(){this.nb=!0;return this};ma.prototype.thaw=ma.prototype.Ma=function(){this.nb=!1;return this};ma.prototype.toString=function(){return"Set("+t.$h(this.da)+")#"+t.od(this)};
ma.prototype.add=ma.prototype.add=function(a){if(null===a)return!1;e&&this.Pg(a);t.L(this,a);var b=a;t.qb(a)&&(b=t.jt(a));return void 0===this.fd[b]?(this.ed++,a=new Ea(a,a),this.fd[b]=a,b=this.Ih,null===b?this.cb=a:(a.uo=b,b.Tb=a),this.Ih=a,this.Qd(),!0):!1};ma.prototype.addAll=ma.prototype.Oe=function(a){if(null===a)return this;t.L(this);if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++)this.add(t.lb(a,c));else for(a=a.k;a.next();)this.add(a.value);return this};
ma.prototype.contains=ma.prototype.has=ma.prototype.contains=function(a){if(null===a)return!1;e&&this.Pg(a);var b=a;return t.qb(a)&&(b=t.od(a),void 0===b)?!1:void 0!==this.fd[b]};ma.prototype.containsAll=function(a){if(null===a)return!0;for(a=a.k;a.next();)if(!this.contains(a.value))return!1;return!0};ma.prototype.containsAny=function(a){if(null===a)return!0;for(a=a.k;a.next();)if(this.contains(a.value))return!0;return!1};
ma.prototype.first=ma.prototype.$a=function(){var a=this.cb;return null===a?null:a.value};ma.prototype.any=function(a){for(var b=this.V,c=this.cb;null!==c;){if(a(c.value))return!0;this.V!==b&&t.Qb(this);c=c.Tb}return!1};ma.prototype.all=function(a){for(var b=this.V,c=this.cb;null!==c;){if(!a(c.value))return!1;this.V!==b&&t.Qb(this);c=c.Tb}return!0};ma.prototype.each=function(a){for(var b=this.V,c=this.cb;null!==c;)a(c.value),this.V!==b&&t.Qb(this),c=c.Tb};
ma.prototype.remove=ma.prototype["delete"]=ma.prototype.remove=function(a){if(null===a)return!1;e&&this.Pg(a);t.L(this,a);var b=a;if(t.qb(a)&&(b=t.od(a),void 0===b))return!1;a=this.fd[b];if(void 0===a)return!1;var c=a.Tb,d=a.uo;null!==c&&(c.uo=d);null!==d&&(d.Tb=c);this.cb===a&&(this.cb=c);this.Ih===a&&(this.Ih=d);delete this.fd[b];this.ed--;this.Qd();return!0};
ma.prototype.removeAll=function(a){if(null===a)return this;t.L(this);if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++)this.remove(t.lb(a,c));else for(a=a.k;a.next();)this.remove(a.value);return this};ma.prototype.retainAll=function(a){if(null===a||0===this.count)return this;t.L(this);var b=new ma(this.da);b.Oe(a);a=new B(this.da);for(var c=this.k;c.next();)b.contains(c.value)||a.add(c.value);for(b=a.k;b.next();)this.remove(b.value);return this};
ma.prototype.clear=ma.prototype.clear=function(){t.L(this);this.fd={};this.ed=0;this.Ih=this.cb=null;this.Qd()};ma.prototype.copy=function(){var a=new ma(this.da),b=this.fd,c;for(c in b)a.add(b[c].value);return a};ma.prototype.toArray=ma.prototype.Ie=function(){var a=Array(this.ed),b=this.fd,c=0,d;for(d in b)a[c]=b[d].value,c++;return a};ma.prototype.toList=function(){var a=new B(this.da),b=this.fd,c;for(c in b)a.add(b[c].value);return a};t.A(ma,{count:"count"},function(){return this.ed});
t.A(ma,{size:"size"},function(){return this.ed});t.A(ma,{k:"iterator"},function(){if(0>=this.ed)return t.ag;var a=this.Cd;return null!==a?(a.reset(),a):new Da(this)});function Fa(a){this.Xa=a;this.reset()}t.Vd(Fa,{key:!0,value:!0});t.A(Fa,{k:"iterator"},function(){return this});Fa.prototype.reset=Fa.prototype.reset=function(){this.Ya=this.Xa.V;this.yb=null};
Fa.prototype.next=Fa.prototype.hasNext=Fa.prototype.next=function(){var a=this.Xa;a.V!==this.Ya&&t.Qb(a);var b=this.yb,b=null===b?a.cb:b.Tb;if(null!==b)return this.yb=b,this.value=this.key=a=b.key,!0;this.cg();return!1};Fa.prototype.first=Fa.prototype.$a=function(){var a=this.Xa;this.Ya=a.V;a=a.cb;return null!==a?(this.yb=a,this.value=this.key=a=a.key):null};Fa.prototype.any=function(a){var b=this.Xa,c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(a(d.key))return!0;b.V!==c&&t.Qb(b);d=d.Tb}return!1};
Fa.prototype.all=function(a){var b=this.Xa,c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(!a(d.key))return!1;b.V!==c&&t.Qb(b);d=d.Tb}return!0};Fa.prototype.each=function(a){var b=this.Xa,c=b.V;this.yb=null;for(var d=b.cb;null!==d;)a(d.key),b.V!==c&&t.Qb(b),d=d.Tb};t.A(Fa,{count:"count"},function(){return this.Xa.ed});Fa.prototype.cg=function(){this.value=this.key=null;this.Ya=-1};Fa.prototype.toString=function(){return null!==this.yb?"MapKeySetIterator@"+this.yb.value:"MapKeySetIterator"};
function Ha(a){t.Ac(this);this.nb=!0;this.Xa=a}t.Ka(Ha,ma);Ha.prototype.freeze=function(){return this};Ha.prototype.Ma=function(){return this};Ha.prototype.toString=function(){return"MapKeySet("+this.Xa.toString()+")"};Ha.prototype.add=Ha.prototype.set=Ha.prototype.add=function(){t.m("This Set is read-only: "+this.toString());return!1};Ha.prototype.contains=Ha.prototype.has=Ha.prototype.contains=function(a){return this.Xa.contains(a)};
Ha.prototype.remove=Ha.prototype["delete"]=Ha.prototype.remove=function(){t.m("This Set is read-only: "+this.toString());return!1};Ha.prototype.clear=Ha.prototype.clear=function(){t.m("This Set is read-only: "+this.toString())};Ha.prototype.first=Ha.prototype.$a=function(){var a=this.Xa.cb;return null!==a?a.key:null};Ha.prototype.any=function(a){for(var b=this.Xa.cb;null!==b;){if(a(b.key))return!0;b=b.Tb}return!1};
Ha.prototype.all=function(a){for(var b=this.Xa.cb;null!==b;){if(!a(b.key))return!1;b=b.Tb}return!0};Ha.prototype.each=function(a){for(var b=this.Xa.cb;null!==b;)a(b.key),b=b.Tb};Ha.prototype.copy=function(){return new Ha(this.Xa)};Ha.prototype.toSet=function(){var a=new ma(this.Xa.Hh),b=this.Xa.fd,c;for(c in b)a.add(b[c].key);return a};Ha.prototype.toArray=Ha.prototype.Ie=function(){var a=this.Xa.fd,b=Array(this.Xa.ed),c=0,d;for(d in a)b[c]=a[d].key,c++;return b};
Ha.prototype.toList=function(){var a=new B(this.da),b=this.Xa.fd,c;for(c in b)a.add(b[c].key);return a};t.A(Ha,{count:"count"},function(){return this.Xa.ed});t.A(Ha,{size:"size"},function(){return this.Xa.ed});t.A(Ha,{k:"iterator"},function(){return 0>=this.Xa.ed?t.ag:new Fa(this.Xa)});function Ka(a){this.Xa=a;this.reset()}t.Vd(Ka,{key:!0,value:!0});t.A(Ka,{k:"iterator"},function(){return this});Ka.prototype.reset=Ka.prototype.reset=function(){var a=this.Xa;a.ho=null;this.Ya=a.V;this.yb=null};
Ka.prototype.next=Ka.prototype.hasNext=Ka.prototype.next=function(){var a=this.Xa;a.V!==this.Ya&&t.Qb(a);var b=this.yb,b=null===b?a.cb:b.Tb;if(null!==b)return this.yb=b,this.value=b.value,this.key=b.key,!0;this.cg();return!1};Ka.prototype.first=Ka.prototype.$a=function(){var a=this.Xa;this.Ya=a.V;a=a.cb;if(null!==a){this.yb=a;var b=a.value;this.key=a.key;return this.value=b}return null};
Ka.prototype.any=function(a){var b=this.Xa;b.ho=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(a(d.value))return!0;b.V!==c&&t.Qb(b);d=d.Tb}return!1};Ka.prototype.all=function(a){var b=this.Xa;b.ho=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(!a(d.value))return!1;b.V!==c&&t.Qb(b);d=d.Tb}return!0};Ka.prototype.each=function(a){var b=this.Xa;b.ho=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;)a(d.value),b.V!==c&&t.Qb(b),d=d.Tb};t.A(Ka,{count:"count"},function(){return this.Xa.ed});
Ka.prototype.cg=function(){this.value=this.key=null;this.Ya=-1;this.Xa.ho=this};Ka.prototype.toString=function(){return null!==this.yb?"MapValueSetIterator@"+this.yb.value:"MapValueSetIterator"};function Ea(a,b){this.key=a;this.value=b;this.uo=this.Tb=null}t.Vd(Ea,{key:!0,value:!0});Ea.prototype.toString=function(){return"{"+this.key+":"+this.value+"}"};function La(a){this.Xa=a;this.reset()}t.Vd(La,{key:!0,value:!0});t.A(La,{k:"iterator"},function(){return this});
La.prototype.reset=La.prototype.reset=function(){var a=this.Xa;a.Cd=null;this.Ya=a.V;this.yb=null};La.prototype.next=La.prototype.hasNext=La.prototype.next=function(){var a=this.Xa;a.V!==this.Ya&&t.Qb(a);var b=this.yb,b=null===b?a.cb:b.Tb;if(null!==b)return this.yb=b,this.key=b.key,this.value=b.value,!0;this.cg();return!1};La.prototype.first=La.prototype.$a=function(){var a=this.Xa;this.Ya=a.V;a=a.cb;return null!==a?(this.yb=a,this.key=a.key,this.value=a.value,a):null};
La.prototype.any=function(a){var b=this.Xa;b.Cd=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(a(d))return!0;b.V!==c&&t.Qb(b);d=d.Tb}return!1};La.prototype.all=function(a){var b=this.Xa;b.Cd=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;){if(!a(d))return!1;b.V!==c&&t.Qb(b);d=d.Tb}return!0};La.prototype.each=function(a){var b=this.Xa;b.Cd=null;var c=b.V;this.yb=null;for(var d=b.cb;null!==d;)a(d),b.V!==c&&t.Qb(b),d=d.Tb};t.A(La,{count:"count"},function(){return this.Xa.ed});
La.prototype.cg=function(){this.value=this.key=null;this.Ya=-1;this.Xa.Cd=this};La.prototype.toString=function(){return null!==this.yb?"MapIterator@"+this.yb:"MapIterator"};
function ia(a,b){t.Ac(this);this.nb=!1;void 0===a||null===a?this.Hh=null:"string"===typeof a?"object"===a||"string"===a||"number"===a?this.Hh=a:t.ka(a,"the string 'object', 'number' or 'string'","Map constructor: keytype"):"function"===typeof a?this.Hh=a===Object?"object":a===String?"string":a===Number?"number":a:t.ka(a,"null, a primitive type name, or a class type","Map constructor: keytype");void 0===b||null===b?this.Ri=null:"string"===typeof b?"object"===b||"string"===b||"boolean"===b||"number"===
b||"function"===b?this.Ri=b:t.ka(b,"the string 'object', 'number', 'string', 'boolean', or 'function'","Map constructor: valtype"):"function"===typeof b?this.Ri=b===Object?"object":b===String?"string":b===Number?"number":b===Boolean?"boolean":b===Function?"function":b:t.ka(b,"null, a primitive type name, or a class type","Map constructor: valtype");this.fd={};this.ed=0;this.ho=this.Cd=null;this.V=0;this.Ih=this.cb=null}t.ga("Map",ia);
function Ma(a,b){null!==a.Hh&&("string"===typeof a.Hh?typeof b===a.Hh&&null!==b||t.bc(b,a.Hh):b instanceof a.Hh||t.bc(b,a.Hh))}ia.prototype.Qd=function(){var a=this.V;a++;999999999<a&&(a=0);this.V=a};ia.prototype.freeze=ia.prototype.freeze=function(){this.nb=!0;return this};ia.prototype.thaw=ia.prototype.Ma=function(){this.nb=!1;return this};ia.prototype.toString=function(){return"Map("+t.$h(this.Hh)+","+t.$h(this.Ri)+")#"+t.od(this)};
ia.prototype.add=ia.prototype.set=ia.prototype.add=function(a,b){e&&(Ma(this,a),null!==this.Ri&&("string"===typeof this.Ri?typeof b===this.Ri&&null!==b||t.bc(b,this.Ri):b instanceof this.Ri||t.bc(b,this.Ri)));t.L(this,a);var c=a;t.qb(a)&&(c=t.jt(a));var d=this.fd[c];if(void 0===d)return this.ed++,d=new Ea(a,b),this.fd[c]=d,c=this.Ih,null===c?this.cb=d:(d.uo=c,c.Tb=d),this.Ih=d,this.Qd(),!0;d.value=b;return!1};
ia.prototype.addAll=ia.prototype.Oe=function(a){if(null===a)return this;if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++){var d=t.lb(a,c);this.add(d.key,d.value)}else for(e&&t.l(a,ia,ia,"addAll:map"),a=a.k;a.next();)this.add(a.key,a.value);return this};ia.prototype.first=ia.prototype.$a=function(){return this.cb};ia.prototype.any=function(a){for(var b=this.V,c=this.cb;null!==c;){if(a(c))return!0;this.V!==b&&t.Qb(this);c=c.Tb}return!1};
ia.prototype.all=function(a){for(var b=this.V,c=this.cb;null!==c;){if(!a(c))return!1;this.V!==b&&t.Qb(this);c=c.Tb}return!0};ia.prototype.each=function(a){for(var b=this.V,c=this.cb;null!==c;)a(c),this.V!==b&&t.Qb(this),c=c.Tb};ia.prototype.contains=ia.prototype.has=ia.prototype.contains=function(a){e&&Ma(this,a);var b=a;return t.qb(a)&&(b=t.od(a),void 0===b)?!1:void 0!==this.fd[b]};
ia.prototype.getValue=ia.prototype.get=ia.prototype.za=function(a){e&&Ma(this,a);var b=a;if(t.qb(a)&&(b=t.od(a),void 0===b))return null;a=this.fd[b];return void 0===a?null:a.value};
ia.prototype.remove=ia.prototype["delete"]=ia.prototype.remove=function(a){if(null===a)return!1;e&&Ma(this,a);t.L(this,a);var b=a;if(t.qb(a)&&(b=t.od(a),void 0===b))return!1;a=this.fd[b];if(void 0===a)return!1;var c=a.Tb,d=a.uo;null!==c&&(c.uo=d);null!==d&&(d.Tb=c);this.cb===a&&(this.cb=c);this.Ih===a&&(this.Ih=d);delete this.fd[b];this.ed--;this.Qd();return!0};ia.prototype.clear=ia.prototype.clear=function(){t.L(this);this.fd={};this.ed=0;this.Ih=this.cb=null;this.Qd()};
ia.prototype.copy=function(){var a=new ia(this.Hh,this.Ri),b=this.fd,c;for(c in b){var d=b[c];a.add(d.key,d.value)}return a};ia.prototype.toArray=ia.prototype.Ie=function(){var a=this.fd,b=Array(this.ed),c=0,d;for(d in a){var f=a[d];b[c]=new Ea(f.key,f.value);c++}return b};ia.prototype.toKeySet=ia.prototype.Fl=function(){return new Ha(this)};t.A(ia,{count:"count"},function(){return this.ed});t.A(ia,{size:"size"},function(){return this.ed});
t.A(ia,{k:"iterator"},function(){if(0>=this.count)return t.ag;var a=this.Cd;return null!==a?(a.reset(),a):new La(this)});t.A(ia,{BK:"iteratorKeys"},function(){return 0>=this.count?t.ag:new Fa(this)});t.A(ia,{jF:"iteratorValues"},function(){if(0>=this.count)return t.ag;var a=this.ho;return null!==a?(a.reset(),a):new Ka(this)});function w(a,b){void 0===a||void 0===b?this.y=this.x=0:!e||"number"===typeof a&&"number"===typeof b?(this.x=a,this.y=b):t.m("Invalid arguments to Point constructor")}
t.ga("Point",w);t.Xh(w);t.Vd(w,{x:!0,y:!0});w.prototype.assign=function(a){this.x=a.x;this.y=a.y};w.prototype.p=function(a,b){this.x=a;this.y=b};w.prototype.setTo=w.prototype.Sp=function(a,b){e&&(t.i(a,"number",w,"setTo:x"),t.i(b,"number",w,"setTo:y"));t.L(this);this.x=a;this.y=b;return this};w.prototype.set=w.prototype.set=function(a){e&&t.l(a,w,w,"set:p");t.L(this);this.x=a.x;this.y=a.y;return this};w.prototype.copy=function(){var a=new w;a.x=this.x;a.y=this.y;return a};
w.prototype.Ja=function(){this.nb=!0;Object.freeze(this);return this};w.prototype.Z=function(){return Object.isFrozen(this)?this:this.copy().freeze()};w.prototype.freeze=function(){this.nb=!0;return this};w.prototype.Ma=function(){Object.isFrozen(this)&&t.m("cannot thaw constant: "+this);this.nb=!1;return this};
w.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var f=0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));return new w(c,f)}return new w};w.stringify=function(a){return a instanceof w?a.x.toString()+" "+a.y.toString():a.toString()};w.prototype.toString=function(){return"Point("+this.x+","+this.y+")"};w.prototype.equals=w.prototype.M=function(a){return a instanceof w?this.x===a.x&&this.y===a.y:!1};
w.prototype.equalTo=function(a,b){return this.x===a&&this.y===b};w.prototype.equalsApprox=w.prototype.tf=function(a){return F.I(this.x,a.x)&&F.I(this.y,a.y)};w.prototype.add=w.prototype.add=function(a){e&&t.l(a,w,w,"add:p");t.L(this);this.x+=a.x;this.y+=a.y;return this};w.prototype.subtract=w.prototype.Vt=function(a){e&&t.l(a,w,w,"subtract:p");t.L(this);this.x-=a.x;this.y-=a.y;return this};
w.prototype.offset=w.prototype.offset=function(a,b){e&&(t.q(a,w,"offset:dx"),t.q(b,w,"offset:dy"));t.L(this);this.x+=a;this.y+=b;return this};w.prototype.rotate=w.prototype.rotate=function(a){e&&t.q(a,w,"rotate:angle");t.L(this);if(0===a)return this;var b=this.x,c=this.y;if(0===b&&0===c)return this;var d;90===a?(a=0,d=1):180===a?(a=-1,d=0):270===a?(a=0,d=-1):(d=a*Math.PI/180,a=Math.cos(d),d=Math.sin(d));this.x=a*b-d*c;this.y=d*b+a*c;return this};
w.prototype.scale=w.prototype.scale=function(a,b){e&&(t.q(a,w,"scale:sx"),t.q(b,w,"scale:sy"));this.x*=a;this.y*=b;return this};w.prototype.distanceSquaredPoint=w.prototype.Zj=function(a){e&&t.l(a,w,w,"distanceSquaredPoint:p");var b=a.x-this.x;a=a.y-this.y;return b*b+a*a};w.prototype.distanceSquared=w.prototype.Zs=function(a,b){e&&(t.q(a,w,"distanceSquared:px"),t.q(b,w,"distanceSquared:py"));var c=a-this.x,d=b-this.y;return c*c+d*d};
w.prototype.normalize=w.prototype.normalize=function(){t.L(this);var a=this.x,b=this.y,c=Math.sqrt(a*a+b*b);0<c&&(this.x=a/c,this.y=b/c);return this};w.prototype.directionPoint=w.prototype.aj=function(a){e&&t.l(a,w,w,"directionPoint:p");return Oa(a.x-this.x,a.y-this.y)};w.prototype.direction=w.prototype.direction=function(a,b){e&&(t.q(a,w,"direction:px"),t.q(b,w,"direction:py"));return Oa(a-this.x,b-this.y)};
function Oa(a,b){if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;var c=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?c=0>b?c+180:180-c:0>b&&(c=360-c);return c}w.prototype.projectOntoLineSegment=function(a,b,c,d){e&&(t.q(a,w,"projectOntoLineSegment:px"),t.q(b,w,"projectOntoLineSegment:py"),t.q(c,w,"projectOntoLineSegment:qx"),t.q(d,w,"projectOntoLineSegment:qy"));F.dn(a,b,c,d,this.x,this.y,this);return this};
w.prototype.projectOntoLineSegmentPoint=function(a,b){e&&(t.l(a,w,w,"projectOntoLineSegmentPoint:p"),t.l(b,w,w,"projectOntoLineSegmentPoint:q"));F.dn(a.x,a.y,b.x,b.y,this.x,this.y,this);return this};w.prototype.snapToGrid=function(a,b,c,d){e&&(t.q(a,w,"snapToGrid:originx"),t.q(b,w,"snapToGrid:originy"),t.q(c,w,"snapToGrid:cellwidth"),t.q(d,w,"snapToGrid:cellheight"));F.dt(this.x,this.y,a,b,c,d,this);return this};
w.prototype.snapToGridPoint=function(a,b){e&&(t.l(a,w,w,"snapToGridPoint:p"),t.l(b,fa,w,"snapToGridPoint:q"));F.dt(this.x,this.y,a.x,a.y,b.width,b.height,this);return this};w.prototype.setRectSpot=w.prototype.Qt=function(a,b){e&&(t.l(a,x,w,"setRectSpot:r"),t.l(b,H,w,"setRectSpot:spot"));t.L(this);this.x=a.x+b.x*a.width+b.offsetX;this.y=a.y+b.y*a.height+b.offsetY;return this};
w.prototype.setSpot=w.prototype.Rt=function(a,b,c,d,f){e&&(t.q(a,w,"setSpot:x"),t.q(b,w,"setSpot:y"),t.q(c,w,"setSpot:w"),t.q(d,w,"setSpot:h"),(0>c||0>d)&&t.m("Point.setSpot:Width and height cannot be negative"),t.l(f,H,w,"setSpot:spot"));t.L(this);this.x=a+f.x*c+f.offsetX;this.y=b+f.y*d+f.offsetY;return this};w.prototype.transform=function(a){e&&t.l(a,ga,w,"transform:t");a.Sa(this);return this};function Qa(a,b){e&&t.l(b,ga,w,"transformInverted:t");b.ai(a);return a}var Ra;
w.distanceLineSegmentSquared=Ra=function(a,b,c,d,f,g){e&&(t.q(a,w,"distanceLineSegmentSquared:px"),t.q(b,w,"distanceLineSegmentSquared:py"),t.q(c,w,"distanceLineSegmentSquared:ax"),t.q(d,w,"distanceLineSegmentSquared:ay"),t.q(f,w,"distanceLineSegmentSquared:bx"),t.q(g,w,"distanceLineSegmentSquared:by"));var h=f-c,k=g-d,l=h*h+k*k;c-=a;d-=b;var m=-c*h-d*k;if(0>=m||m>=l)return h=f-a,k=g-b,Math.min(c*c+d*d,h*h+k*k);a=h*d-k*c;return a*a/l};var Sa;
w.distanceSquared=Sa=function(a,b,c,d){e&&(t.q(a,w,"distanceSquared:px"),t.q(b,w,"distanceSquared:py"),t.q(c,w,"distanceSquared:qx"),t.q(d,w,"distanceSquared:qy"));a=c-a;b=d-b;return a*a+b*b};var Ta;
w.direction=Ta=function(a,b,c,d){e&&(t.q(a,w,"direction:px"),t.q(b,w,"direction:py"),t.q(c,w,"direction:qx"),t.q(d,w,"direction:qy"));a=c-a;b=d-b;if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;d=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?d=0>b?d+180:180-d:0>b&&(d=360-d);return d};w.prototype.isReal=w.prototype.N=function(){return isFinite(this.x)&&isFinite(this.y)};
function fa(a,b){void 0===a||void 0===b?this.height=this.width=0:!e||"number"===typeof a&&(0<=a||isNaN(a))&&"number"===typeof b&&(0<=b||isNaN(b))?(this.width=a,this.height=b):t.m("Invalid arguments to Size constructor")}t.ga("Size",fa);t.Xh(fa);t.Vd(fa,{width:!0,height:!0});fa.prototype.assign=function(a){this.width=a.width;this.height=a.height};fa.prototype.p=function(a,b){this.width=a;this.height=b};
fa.prototype.setTo=fa.prototype.Sp=function(a,b){e&&(t.i(a,"number",fa,"setTo:w"),t.i(b,"number",fa,"setTo:h"));0>a&&t.ka(a,">= 0",fa,"setTo:w");0>b&&t.ka(b,">= 0",fa,"setTo:h");t.L(this);this.width=a;this.height=b;return this};fa.prototype.set=fa.prototype.set=function(a){e&&t.l(a,fa,fa,"set:s");t.L(this);this.width=a.width;this.height=a.height;return this};fa.prototype.copy=function(){var a=new fa;a.width=this.width;a.height=this.height;return a};
fa.prototype.Ja=function(){this.nb=!0;Object.freeze(this);return this};fa.prototype.Z=function(){return Object.isFrozen(this)?this:this.copy().freeze()};fa.prototype.freeze=function(){this.nb=!0;return this};fa.prototype.Ma=function(){Object.isFrozen(this)&&t.m("cannot thaw constant: "+this);this.nb=!1;return this};
fa.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var f=0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));return new fa(c,f)}return new fa};fa.stringify=function(a){return a instanceof fa?a.width.toString()+" "+a.height.toString():a.toString()};fa.prototype.toString=function(){return"Size("+this.width+","+this.height+")"};
fa.prototype.equals=fa.prototype.M=function(a){return a instanceof fa?this.width===a.width&&this.height===a.height:!1};fa.prototype.equalTo=function(a,b){return this.width===a&&this.height===b};fa.prototype.equalsApprox=fa.prototype.tf=function(a){return F.I(this.width,a.width)&&F.I(this.height,a.height)};fa.prototype.isReal=fa.prototype.N=function(){return isFinite(this.width)&&isFinite(this.height)};
function x(a,b,c,d){void 0===a?this.height=this.width=this.y=this.x=0:a instanceof w?b instanceof w?(this.x=Math.min(a.x,b.x),this.y=Math.min(a.y,b.y),this.width=Math.abs(a.x-b.x),this.height=Math.abs(a.y-b.y)):b instanceof fa?(this.x=a.x,this.y=a.y,this.width=b.width,this.height=b.height):t.m("Incorrect arguments supplied"):!e||"number"===typeof a&&"number"===typeof b&&"number"===typeof c&&(0<=c||isNaN(c))&&"number"===typeof d&&(0<=d||isNaN(d))?(this.x=a,this.y=b,this.width=c,this.height=d):t.m("Invalid arguments to Rect constructor")}
t.ga("Rect",x);t.Xh(x);t.Vd(x,{x:!0,y:!0,width:!0,height:!0});x.prototype.assign=function(a){this.x=a.x;this.y=a.y;this.width=a.width;this.height=a.height};x.prototype.p=function(a,b,c,d){this.x=a;this.y=b;this.width=c;this.height=d};function Va(a,b,c){a.width=b;a.height=c}
x.prototype.setTo=x.prototype.Sp=function(a,b,c,d){e&&(t.i(a,"number",x,"setTo:x"),t.i(b,"number",x,"setTo:y"),t.i(c,"number",x,"setTo:w"),t.i(d,"number",x,"setTo:h"));0>c&&t.ka(c,">= 0",x,"setTo:w");0>d&&t.ka(d,">= 0",x,"setTo:h");t.L(this);this.x=a;this.y=b;this.width=c;this.height=d;return this};x.prototype.set=x.prototype.set=function(a){e&&t.l(a,x,x,"set:r");t.L(this);this.x=a.x;this.y=a.y;this.width=a.width;this.height=a.height;return this};
x.prototype.setPoint=x.prototype.Ef=function(a){e&&t.l(a,w,x,"setPoint:p");t.L(this);this.x=a.x;this.y=a.y;return this};x.prototype.setSize=function(a){e&&t.l(a,fa,x,"setSize:s");t.L(this);this.width=a.width;this.height=a.height;return this};x.prototype.copy=function(){var a=new x;a.x=this.x;a.y=this.y;a.width=this.width;a.height=this.height;return a};x.prototype.Ja=function(){this.nb=!0;Object.freeze(this);return this};x.prototype.Z=function(){return Object.isFrozen(this)?this:this.copy().freeze()};
x.prototype.freeze=function(){this.nb=!0;return this};x.prototype.Ma=function(){Object.isFrozen(this)&&t.m("cannot thaw constant: "+this);this.nb=!1;return this};x.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var f=0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));for(var g=0;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));for(var h=0;""===a[b];)b++;(d=a[b++])&&(h=parseFloat(d));return new x(c,f,g,h)}return new x};
x.stringify=function(a){return a instanceof x?a.x.toString()+" "+a.y.toString()+" "+a.width.toString()+" "+a.height.toString():a.toString()};x.prototype.toString=function(){return"Rect("+this.x+","+this.y+","+this.width+","+this.height+")"};x.prototype.equals=x.prototype.M=function(a){return a instanceof x?this.x===a.x&&this.y===a.y&&this.width===a.width&&this.height===a.height:!1};x.prototype.equalTo=function(a,b,c,d){return this.x===a&&this.y===b&&this.width===c&&this.height===d};
x.prototype.equalsApprox=x.prototype.tf=function(a){return F.I(this.x,a.x)&&F.I(this.y,a.y)&&F.I(this.width,a.width)&&F.I(this.height,a.height)};x.prototype.containsPoint=x.prototype.Fa=function(a){e&&t.l(a,w,x,"containsPoint:p");return this.x<=a.x&&this.x+this.width>=a.x&&this.y<=a.y&&this.y+this.height>=a.y};x.prototype.containsRect=x.prototype.Vj=function(a){e&&t.l(a,x,x,"containsRect:r");return this.x<=a.x&&a.x+a.width<=this.x+this.width&&this.y<=a.y&&a.y+a.height<=this.y+this.height};
x.prototype.contains=x.prototype.contains=function(a,b,c,d){e?(t.q(a,x,"contains:x"),t.q(b,x,"contains:y"),void 0===c?c=0:t.q(c,x,"contains:w"),void 0===d?d=0:t.q(d,x,"contains:h"),(0>c||0>d)&&t.m("Rect.contains:Width and height cannot be negative")):(void 0===c&&(c=0),void 0===d&&(d=0));return this.x<=a&&a+c<=this.x+this.width&&this.y<=b&&b+d<=this.y+this.height};x.prototype.reset=function(){t.L(this);this.height=this.width=this.y=this.x=0};
x.prototype.offset=x.prototype.offset=function(a,b){e&&(t.q(a,x,"offset:dx"),t.q(b,x,"offset:dy"));t.L(this);this.x+=a;this.y+=b;return this};x.prototype.inflate=x.prototype.Sf=function(a,b){e&&(t.q(a,x,"inflate:w"),t.q(b,x,"inflate:h"));return Wa(this,b,a,b,a)};x.prototype.addMargin=x.prototype.bw=function(a){e&&t.l(a,ab,x,"addMargin:m");return Wa(this,a.top,a.right,a.bottom,a.left)};
x.prototype.subtractMargin=x.prototype.YJ=function(a){e&&t.l(a,ab,x,"subtractMargin:m");return Wa(this,-a.top,-a.right,-a.bottom,-a.left)};x.prototype.grow=function(a,b,c,d){e&&(t.q(a,x,"grow:t"),t.q(b,x,"grow:r"),t.q(c,x,"grow:b"),t.q(d,x,"grow:l"));return Wa(this,a,b,c,d)};function Wa(a,b,c,d,f){t.L(a);var g=a.width;c+f<=-g?(a.x+=g/2,a.width=0):(a.x-=f,a.width+=c+f);c=a.height;b+d<=-c?(a.y+=c/2,a.height=0):(a.y-=b,a.height+=b+d);return a}
x.prototype.intersectRect=function(a){e&&t.l(a,x,x,"intersectRect:r");return cb(this,a.x,a.y,a.width,a.height)};x.prototype.intersect=function(a,b,c,d){e&&(t.q(a,x,"intersect:x"),t.q(b,x,"intersect:y"),t.q(c,x,"intersect:w"),t.q(d,x,"intersect:h"),(0>c||0>d)&&t.m("Rect.intersect:Width and height cannot be negative"));return cb(this,a,b,c,d)};
function cb(a,b,c,d,f){t.L(a);var g=Math.max(a.x,b),h=Math.max(a.y,c);b=Math.min(a.x+a.width,b+d);c=Math.min(a.y+a.height,c+f);a.x=g;a.y=h;a.width=Math.max(0,b-g);a.height=Math.max(0,c-h);return a}x.prototype.intersectsRect=x.prototype.Tf=function(a){e&&t.l(a,x,x,"intersectsRect:r");return this.$E(a.x,a.y,a.width,a.height)};
x.prototype.intersects=x.prototype.$E=function(a,b,c,d){e&&(t.q(a,x,"intersects:x"),t.q(b,x,"intersects:y"),t.q(a,x,"intersects:w"),t.q(b,x,"intersects:h"),(0>c||0>d)&&t.m("Rect.intersects:Width and height cannot be negative"));var f=this.width,g=this.x;if(Infinity!==f&&Infinity!==c&&(f+=g,c+=a,isNaN(c)||isNaN(f)||g>c||a>f))return!1;a=this.height;c=this.y;return Infinity!==a&&Infinity!==d&&(a+=c,d+=b,isNaN(d)||isNaN(a)||c>d||b>a)?!1:!0};
function eb(a,b){var c=a.width,d=b.width+10+10,f=a.x,g=b.x-10;if(f>d+g||g>c+f)return!1;c=a.height;d=b.height+10+10;f=a.y;g=b.y-10;return f>d+g||g>c+f?!1:!0}x.prototype.unionPoint=x.prototype.kj=function(a){e&&t.l(a,w,x,"unionPoint:p");return hb(this,a.x,a.y,0,0)};x.prototype.unionRect=x.prototype.ii=function(a){e&&t.l(a,x,x,"unionRect:r");return hb(this,a.x,a.y,a.width,a.height)};
x.prototype.union=x.prototype.OG=function(a,b,c,d){t.L(this);e?(t.q(a,x,"union:x"),t.q(b,x,"union:y"),void 0===c?c=0:t.q(c,x,"union:w"),void 0===d?d=0:t.q(d,x,"union:h"),(0>c||0>d)&&t.m("Rect.union:Width and height cannot be negative")):(void 0===c&&(c=0),void 0===d&&(d=0));return hb(this,a,b,c,d)};function hb(a,b,c,d,f){var g=Math.min(a.x,b),h=Math.min(a.y,c);b=Math.max(a.x+a.width,b+d);c=Math.max(a.y+a.height,c+f);a.x=g;a.y=h;a.width=b-g;a.height=c-h;return a}
x.prototype.setSpot=x.prototype.Rt=function(a,b,c){e&&(t.q(a,x,"setSpot:x"),t.q(b,x,"setSpot:y"),t.l(c,H,x,"setSpot:spot"));t.L(this);this.x=a-c.offsetX-c.x*this.width;this.y=b-c.offsetY-c.y*this.height;return this};var mb;
x.contains=mb=function(a,b,c,d,f,g,h,k){e?(t.q(a,x,"contains:rx"),t.q(b,x,"contains:ry"),t.q(c,x,"contains:rw"),t.q(d,x,"contains:rh"),t.q(f,x,"contains:x"),t.q(g,x,"contains:y"),void 0===h?h=0:t.q(h,x,"contains:w"),void 0===k?k=0:t.q(k,x,"contains:h"),(0>c||0>d||0>h||0>k)&&t.m("Rect.contains:Width and height cannot be negative")):(void 0===h&&(h=0),void 0===k&&(k=0));return a<=f&&f+h<=a+c&&b<=g&&g+k<=b+d};
x.intersects=function(a,b,c,d,f,g,h,k){e&&(t.q(a,x,"intersects:rx"),t.q(b,x,"intersects:ry"),t.q(c,x,"intersects:rw"),t.q(d,x,"intersects:rh"),t.q(f,x,"intersects:x"),t.q(g,x,"intersects:y"),t.q(h,x,"intersects:w"),t.q(k,x,"intersects:h"),(0>c||0>d||0>h||0>k)&&t.m("Rect.intersects:Width and height cannot be negative"));c+=a;h+=f;if(a>h||f>c)return!1;a=d+b;k+=g;return b>k||g>a?!1:!0};t.g(x,"left",x.prototype.left);
t.defineProperty(x,{left:"left"},function(){return this.x},function(a){t.L(this,a);e&&t.i(a,"number",x,"left");this.x=a});t.g(x,"top",x.prototype.top);t.defineProperty(x,{top:"top"},function(){return this.y},function(a){t.L(this,a);e&&t.i(a,"number",x,"top");this.y=a});t.g(x,"right",x.prototype.right);t.defineProperty(x,{right:"right"},function(){return this.x+this.width},function(a){t.L(this,a);e&&t.q(a,x,"right");this.x+=a-(this.x+this.width)});t.g(x,"bottom",x.prototype.bottom);
t.defineProperty(x,{bottom:"bottom"},function(){return this.y+this.height},function(a){t.L(this,a);e&&t.q(a,x,"top");this.y+=a-(this.y+this.height)});t.g(x,"position",x.prototype.position);t.defineProperty(x,{position:"position"},function(){return new w(this.x,this.y)},function(a){t.L(this,a);e&&t.l(a,w,x,"position");this.x=a.x;this.y=a.y});t.g(x,"size",x.prototype.size);
t.defineProperty(x,{size:"size"},function(){return new fa(this.width,this.height)},function(a){t.L(this,a);e&&t.l(a,fa,x,"size");this.width=a.width;this.height=a.height});t.g(x,"center",x.prototype.Sz);t.defineProperty(x,{Sz:"center"},function(){return new w(this.x+this.width/2,this.y+this.height/2)},function(a){t.L(this,a);e&&t.l(a,w,x,"center");this.x=a.x-this.width/2;this.y=a.y-this.height/2});t.g(x,"centerX",x.prototype.Da);
t.defineProperty(x,{Da:"centerX"},function(){return this.x+this.width/2},function(a){t.L(this,a);e&&t.q(a,x,"centerX");this.x=a-this.width/2});t.g(x,"centerY",x.prototype.Oa);t.defineProperty(x,{Oa:"centerY"},function(){return this.y+this.height/2},function(a){t.L(this,a);e&&t.q(a,x,"centerY");this.y=a-this.height/2});x.prototype.isReal=x.prototype.N=function(){return isFinite(this.x)&&isFinite(this.y)&&isFinite(this.width)&&isFinite(this.height)};
x.prototype.isEmpty=function(){return 0===this.width&&0===this.height};function ab(a,b,c,d){void 0===a?this.left=this.bottom=this.right=this.top=0:void 0===b?this.left=this.bottom=this.right=this.top=a:void 0===c?(d=b,this.top=a,this.right=b,this.bottom=a,this.left=d):void 0!==d?(this.top=a,this.right=b,this.bottom=c,this.left=d):t.m("Invalid arguments to Margin constructor")}t.ga("Margin",ab);t.Xh(ab);t.Vd(ab,{top:!0,right:!0,bottom:!0,left:!0});
ab.prototype.assign=function(a){this.top=a.top;this.right=a.right;this.bottom=a.bottom;this.left=a.left};ab.prototype.setTo=ab.prototype.Sp=function(a,b,c,d){e&&(t.i(a,"number",ab,"setTo:t"),t.i(b,"number",ab,"setTo:r"),t.i(c,"number",ab,"setTo:b"),t.i(d,"number",ab,"setTo:l"));t.L(this);this.top=a;this.right=b;this.bottom=c;this.left=d;return this};
ab.prototype.set=ab.prototype.set=function(a){e&&t.l(a,ab,ab,"assign:m");t.L(this);this.top=a.top;this.right=a.right;this.bottom=a.bottom;this.left=a.left;return this};ab.prototype.copy=function(){var a=new ab;a.top=this.top;a.right=this.right;a.bottom=this.bottom;a.left=this.left;return a};ab.prototype.Ja=function(){this.nb=!0;Object.freeze(this);return this};ab.prototype.Z=function(){return Object.isFrozen(this)?this:this.copy().freeze()};ab.prototype.freeze=function(){this.nb=!0;return this};
ab.prototype.Ma=function(){Object.isFrozen(this)&&t.m("cannot thaw constant: "+this);this.nb=!1;return this};ab.parse=function(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var f=void 0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));for(var g=void 0;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));for(var h=void 0;""===a[b];)b++;(d=a[b++])&&(h=parseFloat(d));return new ab(c,f,g,h)}return new ab};
ab.stringify=function(a){return a instanceof ab?a.top.toString()+" "+a.right.toString()+" "+a.bottom.toString()+" "+a.left.toString():a.toString()};ab.prototype.toString=function(){return"Margin("+this.top+","+this.right+","+this.bottom+","+this.left+")"};ab.prototype.equals=ab.prototype.M=function(a){return a instanceof ab?this.top===a.top&&this.right===a.right&&this.bottom===a.bottom&&this.left===a.left:!1};
ab.prototype.equalTo=function(a,b,c,d){return this.top===a&&this.right===b&&this.bottom===c&&this.left===d};ab.prototype.equalsApprox=ab.prototype.tf=function(a){return F.I(this.top,a.top)&&F.I(this.right,a.right)&&F.I(this.bottom,a.bottom)&&F.I(this.left,a.left)};ab.prototype.isReal=ab.prototype.N=function(){return isFinite(this.top)&&isFinite(this.right)&&isFinite(this.bottom)&&isFinite(this.left)};function ga(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0}t.Xh(ga);
t.Vd(ga,{m11:!0,m12:!0,m21:!0,m22:!0,dx:!0,dy:!0});ga.prototype.set=ga.prototype.set=function(a){e&&t.l(a,ga,ga,"set:t");this.m11=a.m11;this.m12=a.m12;this.m21=a.m21;this.m22=a.m22;this.dx=a.dx;this.dy=a.dy;return this};ga.prototype.copy=function(){var a=new ga;a.m11=this.m11;a.m12=this.m12;a.m21=this.m21;a.m22=this.m22;a.dx=this.dx;a.dy=this.dy;return a};ga.prototype.toString=function(){return"Transform("+this.m11+","+this.m12+","+this.m21+","+this.m22+","+this.dx+","+this.dy+")"};
ga.prototype.equals=ga.prototype.M=function(a){return a instanceof ga?this.m11===a.m11&&this.m12===a.m12&&this.m21===a.m21&&this.m22===a.m22&&this.dx===a.dx&&this.dy===a.dy:!1};ga.prototype.reset=ga.prototype.reset=function(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0};
ga.prototype.multiply=ga.prototype.multiply=function(a){e&&t.l(a,ga,ga,"multiply:matrix");var b=this.m12*a.m11+this.m22*a.m12,c=this.m11*a.m21+this.m21*a.m22,d=this.m12*a.m21+this.m22*a.m22,f=this.m11*a.dx+this.m21*a.dy+this.dx,g=this.m12*a.dx+this.m22*a.dy+this.dy;this.m11=this.m11*a.m11+this.m21*a.m12;this.m12=b;this.m21=c;this.m22=d;this.dx=f;this.dy=g;return this};
ga.prototype.multiplyInverted=ga.prototype.DF=function(a){e&&t.l(a,ga,ga,"multiplyInverted:matrix");var b=1/(a.m11*a.m22-a.m12*a.m21),c=a.m22*b,d=-a.m12*b,f=-a.m21*b,g=a.m11*b,h=b*(a.m21*a.dy-a.m22*a.dx),k=b*(a.m12*a.dx-a.m11*a.dy);a=this.m12*c+this.m22*d;b=this.m11*f+this.m21*g;f=this.m12*f+this.m22*g;g=this.m11*h+this.m21*k+this.dx;h=this.m12*h+this.m22*k+this.dy;this.m11=this.m11*c+this.m21*d;this.m12=a;this.m21=b;this.m22=f;this.dx=g;this.dy=h;return this};
ga.prototype.invert=ga.prototype.nA=function(){var a=1/(this.m11*this.m22-this.m12*this.m21),b=-this.m12*a,c=-this.m21*a,d=this.m11*a,f=a*(this.m21*this.dy-this.m22*this.dx),g=a*(this.m12*this.dx-this.m11*this.dy);this.m11=this.m22*a;this.m12=b;this.m21=c;this.m22=d;this.dx=f;this.dy=g};
ga.prototype.rotate=ga.prototype.rotate=function(a,b,c){e&&(t.q(a,ga,"rotate:angle"),t.q(b,ga,"rotate:rx"),t.q(c,ga,"rotate:ry"));this.translate(b,c);var d;0===a?(a=1,d=0):90===a?(a=0,d=1):180===a?(a=-1,d=0):270===a?(a=0,d=-1):(d=a*Math.PI/180,a=Math.cos(d),d=Math.sin(d));var f=this.m12*a+this.m22*d,g=this.m11*-d+this.m21*a,h=this.m12*-d+this.m22*a;this.m11=this.m11*a+this.m21*d;this.m12=f;this.m21=g;this.m22=h;this.translate(-b,-c)};
ga.prototype.translate=ga.prototype.translate=function(a,b){e&&(t.q(a,ga,"translate:x"),t.q(b,ga,"translate:y"));this.dx+=this.m11*a+this.m21*b;this.dy+=this.m12*a+this.m22*b};ga.prototype.scale=ga.prototype.scale=function(a,b){void 0===b&&(b=a);e&&(t.q(a,ga,"translate:sx"),t.q(b,ga,"translate:sy"));this.m11*=a;this.m12*=a;this.m21*=b;this.m22*=b};
ga.prototype.transformPoint=ga.prototype.Sa=function(a){e&&t.l(a,w,ga,"transformPoint:p");var b=a.x,c=a.y;a.x=b*this.m11+c*this.m21+this.dx;a.y=b*this.m12+c*this.m22+this.dy;return a};ga.prototype.invertedTransformPoint=ga.prototype.ai=function(a){e&&t.l(a,w,ga,"invertedTransformPoint:p");var b=1/(this.m11*this.m22-this.m12*this.m21),c=-this.m12*b,d=this.m11*b,f=b*(this.m12*this.dx-this.m11*this.dy),g=a.x,h=a.y;a.x=g*this.m22*b+h*-this.m21*b+b*(this.m21*this.dy-this.m22*this.dx);a.y=g*c+h*d+f;return a};
ga.prototype.transformRect=ga.prototype.JG=function(a){e&&t.l(a,x,ga,"transformRect:rect");var b=a.x,c=a.y,d=b+a.width,f=c+a.height,g=this.m11,h=this.m12,k=this.m21,l=this.m22,m=this.dx,n=this.dy,p=b*g+c*k+m,q=b*h+c*l+n,r=d*g+c*k+m,c=d*h+c*l+n,s=b*g+f*k+m,b=b*h+f*l+n,g=d*g+f*k+m,d=d*h+f*l+n,f=p,h=q,p=Math.min(p,r),f=Math.max(f,r),h=Math.min(h,c),q=Math.max(q,c),p=Math.min(p,s),f=Math.max(f,s),h=Math.min(h,b),q=Math.max(q,b),p=Math.min(p,g),f=Math.max(f,g),h=Math.min(h,d),q=Math.max(q,d);a.x=p;a.y=
h;a.width=f-p;a.height=q-h};ga.prototype.isIdentity=ga.prototype.qt=function(){return 1===this.m11&&0===this.m12&&0===this.m21&&1===this.m22&&0===this.dx&&0===this.dy};function H(a,b,c,d){void 0===a?this.offsetY=this.offsetX=this.y=this.x=0:(void 0===b&&(b=0),void 0===c&&(c=0),void 0===d&&(d=0),this.x=a,this.y=b,this.offsetX=c,this.offsetY=d)}t.ga("Spot",H);t.Xh(H);t.Vd(H,{x:!0,y:!0,offsetX:!0,offsetY:!0});H.prototype.assign=function(a){this.x=a.x;this.y=a.y;this.offsetX=a.offsetX;this.offsetY=a.offsetY};
H.prototype.setTo=H.prototype.Sp=function(a,b,c,d){e&&(pb(a,"setTo:x"),pb(b,"setTo:y"),qb(c,"setTo:offx"),qb(d,"setTo:offy"));t.L(this);this.x=a;this.y=b;this.offsetX=c;this.offsetY=d;return this};H.prototype.set=H.prototype.set=function(a){e&&t.l(a,H,H,"set:s");t.L(this);this.x=a.x;this.y=a.y;this.offsetX=a.offsetX;this.offsetY=a.offsetY;return this};H.prototype.copy=function(){var a=new H;a.x=this.x;a.y=this.y;a.offsetX=this.offsetX;a.offsetY=this.offsetY;return a};
H.prototype.Ja=function(){this.nb=!0;Object.freeze(this);return this};H.prototype.Z=function(){return Object.isFrozen(this)?this:this.copy().freeze()};H.prototype.freeze=function(){this.nb=!0;return this};H.prototype.Ma=function(){Object.isFrozen(this)&&t.m("cannot thaw constant: "+this);this.nb=!1;return this};function ub(a,b){a.x=NaN;a.y=NaN;a.offsetX=b;return a}function pb(a,b){(isNaN(a)||1<a||0>a)&&t.ka(a,"0 <= "+b+" <= 1",H,b)}
function qb(a,b){(isNaN(a)||Infinity===a||-Infinity===a)&&t.ka(a,"real number, not NaN or Infinity",H,b)}var vb;
H.parse=vb=function(a){if("string"===typeof a){a=a.trim();if("None"===a)return t.NONE;if("TopLeft"===a)return t.Xx;if("Top"===a||"TopCenter"===a||"MiddleTop"===a)return t.eq;if("TopRight"===a)return t.Zx;if("Left"===a||"LeftCenter"===a||"MiddleLeft"===a)return t.bq;if("Center"===a)return t.Lx;if("Right"===a||"RightCenter"===a||"MiddleRight"===a)return t.cq;if("BottomLeft"===a)return t.Gx;if("Bottom"===a||"BottomCenter"===a||"MiddleBottom"===a)return t.aq;if("BottomRight"===a)return t.Ix;if("TopSide"===
a)return t.ay;if("LeftSide"===a)return t.Px;if("RightSide"===a)return t.Vx;if("BottomSide"===a)return t.Kx;if("TopBottomSides"===a)return t.Wx;if("LeftRightSides"===a)return t.Ox;if("TopLeftSides"===a)return t.Yx;if("TopRightSides"===a)return t.$x;if("BottomLeftSides"===a)return t.Hx;if("BottomRightSides"===a)return t.Jx;if("NotTopSide"===a)return t.Tx;if("NotLeftSide"===a)return t.Rx;if("NotRightSide"===a)return t.Sx;if("NotBottomSide"===a)return t.Qx;if("AllSides"===a)return t.Fx;if("Default"===
a)return t.Mx;a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var f=0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));for(var g=0;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));for(var h=0;""===a[b];)b++;(d=a[b++])&&(h=parseFloat(d));return new H(c,f,g,h)}return new H};H.stringify=function(a){return a instanceof H?a.xd()?a.x.toString()+" "+a.y.toString()+" "+a.offsetX.toString()+" "+a.offsetY.toString():a.toString():a.toString()};
H.prototype.toString=function(){return this.xd()?0===this.offsetX&&0===this.offsetY?"Spot("+this.x+","+this.y+")":"Spot("+this.x+","+this.y+","+this.offsetX+","+this.offsetY+")":this.M(t.NONE)?"None":this.M(t.Xx)?"TopLeft":this.M(t.eq)?"Top":this.M(t.Zx)?"TopRight":this.M(t.bq)?"Left":this.M(t.Lx)?"Center":this.M(t.cq)?"Right":this.M(t.Gx)?"BottomLeft":this.M(t.aq)?"Bottom":this.M(t.Ix)?"BottomRight":this.M(t.ay)?"TopSide":this.M(t.Px)?"LeftSide":this.M(t.Vx)?"RightSide":this.M(t.Kx)?"BottomSide":
this.M(t.Wx)?"TopBottomSides":this.M(t.Ox)?"LeftRightSides":this.M(t.Yx)?"TopLeftSides":this.M(t.$x)?"TopRightSides":this.M(t.Hx)?"BottomLeftSides":this.M(t.Jx)?"BottomRightSides":this.M(t.Tx)?"NotTopSide":this.M(t.Rx)?"NotLeftSide":this.M(t.Sx)?"NotRightSide":this.M(t.Qx)?"NotBottomSide":this.M(t.Fx)?"AllSides":this.M(t.Mx)?"Default":"None"};
H.prototype.equals=H.prototype.M=function(a){return a instanceof H?(this.x===a.x||isNaN(this.x)&&isNaN(a.x))&&(this.y===a.y||isNaN(this.y)&&isNaN(a.y))&&this.offsetX===a.offsetX&&this.offsetY===a.offsetY:!1};H.prototype.opposite=function(){return new H(0.5-(this.x-0.5),0.5-(this.y-0.5),-this.offsetX,-this.offsetY)};H.prototype.includesSide=function(a){if(!this.rp()||!a.rp())return!1;a=a.offsetY;return(this.offsetY&a)===a};H.prototype.isSpot=H.prototype.xd=function(){return!isNaN(this.x)&&!isNaN(this.y)};
H.prototype.isNoSpot=H.prototype.Ge=function(){return isNaN(this.x)||isNaN(this.y)};H.prototype.isSide=H.prototype.rp=function(){return this.Ge()&&1===this.offsetX&&0!==this.offsetY};H.prototype.isDefault=H.prototype.Pc=function(){return isNaN(this.x)&&isNaN(this.y)&&-1===this.offsetX&&0===this.offsetY};t.bd=1;t.Jc=2;t.Tc=4;t.Sc=8;t.NONE=ub(new H(0,0,0,0),0).Ja();t.Mx=ub(new H(0,0,-1,0),-1).Ja();t.Xx=(new H(0,0,0,0)).Ja();t.eq=(new H(0.5,0,0,0)).Ja();t.Zx=(new H(1,0,0,0)).Ja();
t.bq=(new H(0,0.5,0,0)).Ja();t.Lx=(new H(0.5,0.5,0,0)).Ja();t.cq=(new H(1,0.5,0,0)).Ja();t.Gx=(new H(0,1,0,0)).Ja();t.aq=(new H(0.5,1,0,0)).Ja();t.Ix=(new H(1,1,0,0)).Ja();t.ay=ub(new H(0,0,1,t.bd),1).Ja();t.Px=ub(new H(0,0,1,t.Jc),1).Ja();t.Vx=ub(new H(0,0,1,t.Tc),1).Ja();t.Kx=ub(new H(0,0,1,t.Sc),1).Ja();t.Wx=ub(new H(0,0,1,t.bd|t.Sc),1).Ja();t.Ox=ub(new H(0,0,1,t.Jc|t.Tc),1).Ja();t.Yx=ub(new H(0,0,1,t.bd|t.Jc),1).Ja();t.$x=ub(new H(0,0,1,t.bd|t.Tc),1).Ja();t.Hx=ub(new H(0,0,1,t.Sc|t.Jc),1).Ja();
t.Jx=ub(new H(0,0,1,t.Sc|t.Tc),1).Ja();t.Tx=ub(new H(0,0,1,t.Jc|t.Tc|t.Sc),1).Ja();t.Rx=ub(new H(0,0,1,t.bd|t.Tc|t.Sc),1).Ja();t.Sx=ub(new H(0,0,1,t.bd|t.Jc|t.Sc),1).Ja();t.Qx=ub(new H(0,0,1,t.bd|t.Jc|t.Tc),1).Ja();t.Fx=ub(new H(0,0,1,t.bd|t.Jc|t.Tc|t.Sc),1).Ja();var wb;H.None=wb=t.NONE;var xb;H.Default=xb=t.Mx;var Eb;H.TopLeft=Eb=t.Xx;var Fb;H.TopCenter=Fb=t.eq;var Gb;H.TopRight=Gb=t.Zx;H.LeftCenter=t.bq;var Hb;H.Center=Hb=t.Lx;H.RightCenter=t.cq;var Lb;H.BottomLeft=Lb=t.Gx;var Mb;
H.BottomCenter=Mb=t.aq;var Pb;H.BottomRight=Pb=t.Ix;var Qb;H.MiddleTop=Qb=t.eq;var Vb;H.MiddleLeft=Vb=t.bq;var Wb;H.MiddleRight=Wb=t.cq;var Xb;H.MiddleBottom=Xb=t.aq;H.Top=t.eq;var Yb;H.Left=Yb=t.bq;var Zb;H.Right=Zb=t.cq;H.Bottom=t.aq;var ac;H.TopSide=ac=t.ay;var bc;H.LeftSide=bc=t.Px;var cc;H.RightSide=cc=t.Vx;var dc;H.BottomSide=dc=t.Kx;H.TopBottomSides=t.Wx;H.LeftRightSides=t.Ox;H.TopLeftSides=t.Yx;H.TopRightSides=t.$x;H.BottomLeftSides=t.Hx;H.BottomRightSides=t.Jx;H.NotTopSide=t.Tx;
H.NotLeftSide=t.Rx;H.NotRightSide=t.Sx;H.NotBottomSide=t.Qx;var gc;H.AllSides=gc=t.Fx;function hc(){this.cf=[1,0,0,1,0,0]}hc.prototype.copy=function(){var a=new hc;a.cf[0]=this.cf[0];a.cf[1]=this.cf[1];a.cf[2]=this.cf[2];a.cf[3]=this.cf[3];a.cf[4]=this.cf[4];a.cf[5]=this.cf[5];return a};function mc(a){this.type=a;this.r2=this.y2=this.x2=this.r1=this.y1=this.x1=0;this.XD=[]}mc.prototype.addColorStop=function(a,b){this.XD.push({offset:a,color:b})};
function nc(a,b,c){this.fillStyle="#000000";this.font="10px sans-serif";this.globalAlpha=1;this.lineCap="butt";this.Zw=0;this.lineJoin="miter";this.lineWidth=1;this.miterLimit=10;this.shadowBlur=0;this.shadowColor="rgba(0, 0, 0, 0)";this.shadowOffsetY=this.shadowOffsetX=0;this.strokeStyle="#000000";this.textAlign="start";this.document=b||document;this.wE=c;this.Vw=null;this.path=[];this.$i=new hc;this.stack=[];this.vf=[];this.qG=this.KE=this.mw=0;this.zw=a;this.tJ="http://www.w3.org/2000/svg";this.width=
this.zw.width;this.height=this.zw.height;this.Bl=oc(this,"svg",{width:this.width+"px",height:this.height+"px",UK:"0 0 "+this.zw.width+" "+this.zw.height});this.Bl.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns","http://www.w3.org/2000/svg");this.Bl.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:xlink","http://www.w3.org/1999/xlink");rc(this,1,0,0,1,0,0);a=oc(this,"clipPath",{id:"mainClip"});a.appendChild(oc(this,"rect",{x:0,y:0,width:this.width,height:this.height}));this.Bl.appendChild(a);
this.vf[0].setAttributeNS(null,"clip-path","url(#mainClip)")}aa=nc.prototype;aa.arc=function(a,b,c,d,f,g){sc(this,a,b,c,d,f,g)};aa.beginPath=function(){this.path=[]};aa.bezierCurveTo=function(a,b,c,d,f,g){this.path.push(["C",a,b,c,d,f,g])};aa.clearRect=function(){};aa.clip=function(){tc(this,"clipPath",this.path,new hc)};aa.closePath=function(){this.path.push(["z"])};aa.createLinearGradient=function(a,b,c,d){var f=new mc("linear");f.x1=a;f.y1=b;f.x2=c;f.y2=d;return f};aa.createPattern=function(){};
aa.createRadialGradient=function(a,b,c,d,f,g){var h=new mc("radial");h.x1=a;h.y1=b;h.r1=c;h.x2=d;h.y2=f;h.r2=g;return h};
aa.drawImage=function(a,b,c,d,f,g,h,k,l){a=[b,c,d,f,g,h,k,l,a];b=this.$i;f=a[8];c={x:0,y:0,width:f.naturalWidth,height:f.naturalHeight,href:f.src};d="";g=a[6]/a[2];h=a[7]/a[3];if(0!==a[4]||0!==a[5])d+=" translate("+a[4]+", "+a[5]+")";if(1!==g||1!==h)d+=" scale("+g+", "+h+")";if(0!==a[0]||0!==a[1])d+=" translate("+-a[0]+", "+-a[1]+")";if(0!==a[0]||0!==a[1]||a[2]!==f.naturalWidth||a[3]!==f.naturalHeight)f="CLIP"+this.mw,this.mw++,g=oc(this,"clipPath",{id:f}),g.appendChild(oc(this,"rect",{x:a[0],y:a[1],
width:a[2],height:a[3]})),this.Bl.appendChild(g),c["clip-path"]="url(#"+f+")";uc(this,"image",c,b,d);this.addElement("image",c)};aa.fill=function(){tc(this,"fill",this.path,this.$i)};aa.fillRect=function(a,b,c,d){vc(this,"fill",[a,b,c,d],this.$i)};aa.fillText=function(a,b,c){a=[a,b,c];b=this.textAlign;"left"===b?b="start":"right"===b?b="end":"center"===b&&(b="middle");b={x:a[1],y:a[2],style:"font: "+this.font,"text-anchor":b};uc(this,"fill",b,this.$i);this.addElement("text",b,a[0])};
aa.lineTo=function(a,b){this.path.push(["L",a,b])};aa.moveTo=function(a,b){this.path.push(["M",a,b])};aa.quadraticCurveTo=function(a,b,c,d){this.path.push(["Q",a,b,c,d])};aa.rect=function(a,b,c,d){this.path.push(["M",a,b],["L",a+c,b],["L",a+c,b+d],["L",a,b+d],["z"])};
aa.restore=function(){this.$i=this.stack.pop();this.path=this.stack.pop();var a=this.stack.pop();this.fillStyle=a.fillStyle;this.font=a.font;this.globalAlpha=a.globalAlpha;this.lineCap=a.lineCap;this.Zw=a.Zw;this.lineJoin=a.lineJoin;this.lineWidth=a.lineWidth;this.miterLimit=a.miterLimit;this.shadowBlur=a.shadowBlur;this.shadowColor=a.shadowColor;this.shadowOffsetX=a.shadowOffsetX;this.shadowOffsetY=a.shadowOffsetY;this.strokeStyle=a.strokeStyle;this.textAlign=a.textAlign};
aa.save=function(){this.stack.push({fillStyle:this.fillStyle,font:this.font,globalAlpha:this.globalAlpha,lineCap:this.lineCap,Zw:this.Zw,lineJoin:this.lineJoin,lineWidth:this.lineWidth,miterLimit:this.miterLimit,shadowBlur:this.shadowBlur,shadowColor:this.shadowColor,shadowOffsetX:this.shadowOffsetX,shadowOffsetY:this.shadowOffsetY,strokeStyle:this.strokeStyle,textAlign:this.textAlign});for(var a=[],b=0;b<this.path.length;b++)a.push(this.path[b]);this.stack.push(a);this.stack.push(this.$i.copy())};
aa.setTransform=function(a,b,c,d,f,g){1===a&&0===b&&0===c&&1===d&&0===f&&0===g||rc(this,a,b,c,d,f,g)};aa.scale=function(){};aa.stroke=function(){tc(this,"stroke",this.path,this.$i)};aa.strokeRect=function(a,b,c,d){vc(this,"stroke",[a,b,c,d],this.$i)};function oc(a,b,c,d){a=a.document.createElementNS(a.tJ,b);if(c)for(var f in c)a.setAttributeNS("href"===f?"http://www.w3.org/1999/xlink":"",f,c[f]);d&&(a.textContent=d);return a}
aa.addElement=function(a,b,c){a=oc(this,a,b,c);0<this.vf.length?this.vf[this.vf.length-1].appendChild(a):this.Bl.appendChild(a);return this.Vw=a};
function uc(a,b,c,d,f){1!==a.globalAlpha&&(c.opacity=a.globalAlpha);"fill"==b?(/^rgba\(/.test(a.fillStyle)?(a=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle),c.fill="rgb("+a[1]+","+a[2]+","+a[3]+")",c["fill-opacity"]=a[4]):c.fill=a.fillStyle instanceof mc?wc(a,a.fillStyle):a.fillStyle,c.stroke="none"):"stroke"==b&&(c.fill="none",/^rgba\(/.test(a.strokeStyle)?(b=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.strokeStyle),
c.stroke="rgb("+b[1]+","+b[2]+","+b[3]+")",c["stroke-opacity"]=b[4]):c.stroke=a.strokeStyle instanceof mc?wc(a,a.strokeStyle):a.strokeStyle,c["stroke-width"]=a.lineWidth,c["stroke-linecap"]=a.lineCap,c["stroke-linejoin"]=a.lineJoin,c["stroke-miterlimit"]=a.miterLimit);d=d.cf;d="matrix("+d[0]+", "+d[1]+", "+d[2]+", "+d[3]+", "+d[4]+", "+d[5]+")";void 0!==f&&(d+=f);c.transform=d}
function wc(a,b){var c="GRAD"+a.KE;a.KE++;var d;if("linear"===b.type)d={x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,id:c,gradientUnits:"userSpaceOnUse"},d=oc(a,"linearGradient",d);else if("radial"===b.type)d={x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,r1:b.r1,r2:b.r2,id:c},d=oc(a,"radialGradient",d);else throw Error("invalid gradient");for(var f=b.XD,g=f.length,h=[],k=0;k<g;k++){var l=f[k],m=l.color,l={offset:l.offset,"stop-color":m};/^rgba\(/.test(m)&&(m=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(m),
l["stop-color"]="rgb("+m[1]+","+m[2]+","+m[3]+")",l["stop-opacity"]=m[4]);h.push(l)}h.sort(function(a,b){return a.offset>b.offset?1:-1});for(k=0;k<g;k++)d.appendChild(oc(a,"stop",h[k]));a.Bl.appendChild(d);return"url(#"+c+")"}function vc(a,b,c,d){c={x:c[0],y:c[1],width:c[2],height:c[3]};uc(a,b,c,d);a.addElement("rect",c)}
function tc(a,b,c,d){for(var f=[],g=0;g<c.length;g++){var h=Array.prototype.slice.call(c[g]),k=[h.shift()];if("A"==k[0])k.push(h.shift()+","+h.shift(),h.shift(),h.shift()+","+h.shift(),h.shift()+","+h.shift());else for(;h.length;)k.push(h.shift()+","+h.shift());f.push(k.join(" "))}c={d:f.join(" ")};uc(a,b,c,d);"clipPath"===b?(b="CLIP"+a.mw,a.mw++,d=oc(a,"clipPath",{id:b}),d.appendChild(oc(a,"path",c)),a.Bl.appendChild(d),0<a.vf.length&&a.vf[a.vf.length-1].setAttributeNS(null,"clip-path","url(#"+b+
")")):a.addElement("path",c)}function sc(a,b,c,d,f,g,h){var k=Math.abs(f-g);if(f!=g){var l=b+d*Math.cos(g);g=c+d*Math.sin(g);k>=2*Math.PI?(sc(a,b,c,d,f,f+Math.PI,h),sc(a,b,c,d,f+Math.PI,f+2*Math.PI,h),a.path.push(["M",l,g])):(b+=d*Math.cos(f),c+=d*Math.sin(f),k=180*k/Math.PI,f=h?0:1,h=180<=k==!!h?0:1,0!==a.path.length?a.path.push(["L",b,c]):a.path.push(["M",b,c]),a.path.push(["A",d,d,k,h,f,l,g]))}}
function rc(a,b,c,d,f,g,h){var k=new hc;k.cf=[b,c,d,f,g,h];b={};uc(a,"g",b,k);k=a.addElement("g",b);a.vf.push(k)}
aa.bb=function(){if(0!==this.shadowOffsetX||0!==this.shadowOffsetY||0!==this.shadowBlur){var a="SHADOW"+this.qG;this.qG++;var b=this.addElement("filter",{id:a,x:"-100%",y:"-100%",width:"300%",height:"300%"},null),c,d,f,g,h;c=oc(this,"feGaussianBlur",{"in":"SourceAlpha",result:"blur",QK:this.shadowBlur/2});d=oc(this,"feFlood",{"in":"blur",result:"flood","flood-color":this.shadowColor});f=oc(this,"feComposite",{"in":"flood",in2:"blur",operator:"in",result:"comp"});g=oc(this,"feOffset",{"in":"comp",
result:"offsetBlur",dx:this.shadowOffsetX,dy:this.shadowOffsetY});h=oc(this,"feMerge",{});h.appendChild(oc(this,"feMergeNode",{"in":"offsetBlur"}));h.appendChild(oc(this,"feMergeNode",{"in":"SourceGraphic"}));b.appendChild(c);b.appendChild(d);b.appendChild(f);b.appendChild(g);b.appendChild(h);0<this.vf.length&&this.vf[this.vf.length-1].setAttributeNS(null,"filter","url(#"+a+")")}};
var F={va:4*((Math.sqrt(2)-1)/3),mj:(new w(0,0)).Ja(),gH:(new x(0,0,0,0)).Ja(),fq:(new ab(0,0,0,0)).Ja(),eH:(new ab(2,2,2,2)).Ja(),fH:(new fa(Infinity,Infinity)).Ja(),cH:(new w(-Infinity,-Infinity)).Ja(),bH:(new w(Infinity,Infinity)).Ja(),gq:(new fa(0,0)).Ja(),Ux:(new fa(1,1)).Ja(),dq:(new fa(6,6)).Ja(),Nx:(new fa(8,8)).Ja(),dH:(new w(NaN,NaN)).Ja(),wB:null,sqrt:function(a){if(0>=a)return 0;var b=F.wB;if(null===b){for(var b=[],c=0;2E3>=c;c++)b[c]=Math.sqrt(c);F.wB=b}return 1>a?(c=1/a,2E3>=c?1/b[c|
0]:Math.sqrt(a)):2E3>=a?b[a|0]:Math.sqrt(a)},I:function(a,b){var c=a-b;return 0.5>c&&-0.5<c},mb:function(a,b){var c=a-b;return 5E-8>c&&-5E-8<c},Md:function(a,b,c,d,f,g,h){0>=f&&(f=1E-6);var k,l,m,n;a<c?(l=a,k=c):(l=c,k=a);b<d?(n=b,m=d):(n=d,m=b);if(a===c)return n<=h&&h<=m&&a-f<=g&&g<=a+f;if(b===d)return l<=g&&g<=k&&b-f<=h&&h<=b+f;k+=f;l-=f;if(l<=g&&g<=k&&(m+=f,n-=f,n<=h&&h<=m))if(k-l>m-n)if(a-c>f||c-a>f){if(g=(d-b)/(c-a)*(g-a)+b,g-f<=h&&h<=g+f)return!0}else return!0;else if(b-d>f||d-b>f){if(h=(c-
a)/(d-b)*(h-b)+a,h-f<=g&&g<=h+f)return!0}else return!0;return!1},hw:function(a,b,c,d,f,g,h,k,l,m,n,p){if(F.Md(a,b,h,k,p,c,d)&&F.Md(a,b,h,k,p,f,g))return F.Md(a,b,h,k,p,m,n);var q=(a+c)/2,r=(b+d)/2,s=(c+f)/2,u=(d+g)/2;f=(f+h)/2;g=(g+k)/2;d=(q+s)/2;c=(r+u)/2;var s=(s+f)/2,u=(u+g)/2,v=(d+s)/2,D=(c+u)/2;return F.hw(a,b,q,r,d,c,v,D,l,m,n,p)||F.hw(v,D,s,u,f,g,h,k,l,m,n,p)},aI:function(a,b,c,d,f,g,h,k,l){var m=(c+f)/2,n=(d+g)/2;l.x=(((a+c)/2+m)/2+(m+(f+h)/2)/2)/2;l.y=(((b+d)/2+n)/2+(n+(g+k)/2)/2)/2;return l},
$H:function(a,b,c,d,f,g,h,k){var l=(c+f)/2,m=(d+g)/2;return Ta(((a+c)/2+l)/2,((b+d)/2+m)/2,(l+(f+h)/2)/2,(m+(g+k)/2)/2)},So:function(a,b,c,d,f,g,h,k,l,m){if(F.Md(a,b,h,k,l,c,d)&&F.Md(a,b,h,k,l,f,g))hb(m,a,b,0,0),hb(m,h,k,0,0);else{var n=(a+c)/2,p=(b+d)/2,q=(c+f)/2,r=(d+g)/2;f=(f+h)/2;g=(g+k)/2;d=(n+q)/2;c=(p+r)/2;var q=(q+f)/2,r=(r+g)/2,s=(d+q)/2,u=(c+r)/2;F.So(a,b,n,p,d,c,s,u,l,m);F.So(s,u,q,r,f,g,h,k,l,m)}return m},ze:function(a,b,c,d,f,g,h,k,l,m){if(F.Md(a,b,h,k,l,c,d)&&F.Md(a,b,h,k,l,f,g))0===
m.length&&m.push([a,b]),m.push([h,k]);else{var n=(a+c)/2,p=(b+d)/2,q=(c+f)/2,r=(d+g)/2;f=(f+h)/2;g=(g+k)/2;d=(n+q)/2;c=(p+r)/2;var q=(q+f)/2,r=(r+g)/2,s=(d+q)/2,u=(c+r)/2;F.ze(a,b,n,p,d,c,s,u,l,m);F.ze(s,u,q,r,f,g,h,k,l,m)}return m},OA:function(a,b,c,d,f,g,h,k,l,m){if(F.Md(a,b,f,g,m,c,d))return F.Md(a,b,f,g,m,k,l);var n=(a+c)/2,p=(b+d)/2;c=(c+f)/2;d=(d+g)/2;var q=(n+c)/2,r=(p+d)/2;return F.OA(a,b,n,p,q,r,h,k,l,m)||F.OA(q,r,c,d,f,g,h,k,l,m)},PK:function(a,b,c,d,f,g,h){h.x=((a+c)/2+(c+f)/2)/2;h.y=((b+
d)/2+(d+g)/2)/2;return h},NA:function(a,b,c,d,f,g,h,k){if(F.Md(a,b,f,g,h,c,d))hb(k,a,b,0,0),hb(k,f,g,0,0);else{var l=(a+c)/2,m=(b+d)/2;c=(c+f)/2;d=(d+g)/2;var n=(l+c)/2,p=(m+d)/2;F.NA(a,b,l,m,n,p,h,k);F.NA(n,p,c,d,f,g,h,k)}return k},Jp:function(a,b,c,d,f,g,h,k){if(F.Md(a,b,f,g,h,c,d))0===k.length&&k.push([a,b]),k.push([f,g]);else{var l=(a+c)/2,m=(b+d)/2;c=(c+f)/2;d=(d+g)/2;var n=(l+c)/2,p=(m+d)/2;F.Jp(a,b,l,m,n,p,h,k);F.Jp(n,p,c,d,f,g,h,k)}return k},Ps:function(a,b,c,d,f,g,h,k,l,m,n,p,q,r){0>=q&&
(q=1E-6);if(F.Md(a,b,h,k,q,c,d)&&F.Md(a,b,h,k,q,f,g)){var s=(a-h)*(m-p)-(b-k)*(l-n);if(!s)return!1;q=((a*k-b*h)*(l-n)-(a-h)*(l*p-m*n))/s;s=((a*k-b*h)*(m-p)-(b-k)*(l*p-m*n))/s;if((l>n?l-n:n-l)<(m>p?m-p:p-m)){if(b<k?(l=b,h=k):(l=k,h=b),s<l||s>h)return!1}else if(a<h?l=a:(l=h,h=a),q<l||q>h)return!1;r.x=q;r.y=s;return!0}var s=(a+c)/2,u=(b+d)/2;c=(c+f)/2;d=(d+g)/2;f=(f+h)/2;g=(g+k)/2;var v=(s+c)/2,D=(u+d)/2;c=(c+f)/2;d=(d+g)/2;var A=(v+c)/2,G=(D+d)/2,K=(n-l)*(n-l)+(p-m)*(p-m),N=!1;F.Ps(a,b,s,u,v,D,A,G,
l,m,n,p,q,r)&&(a=(r.x-l)*(r.x-l)+(r.y-m)*(r.y-m),a<K&&(K=a,N=!0));b=r.x;s=r.y;F.Ps(A,G,c,d,f,g,h,k,l,m,n,p,q,r)&&(a=(r.x-l)*(r.x-l)+(r.y-m)*(r.y-m),a<K?N=!0:(r.x=b,r.y=s));return N},Qs:function(a,b,c,d,f,g,h,k,l,m,n,p,q){var r=0;0>=q&&(q=1E-6);if(F.Md(a,b,h,k,q,c,d)&&F.Md(a,b,h,k,q,f,g)){q=(a-h)*(m-p)-(b-k)*(l-n);if(!q)return r;var s=((a*k-b*h)*(l-n)-(a-h)*(l*p-m*n))/q,u=((a*k-b*h)*(m-p)-(b-k)*(l*p-m*n))/q;if(s>=n)return r;if((l>n?l-n:n-l)<(m>p?m-p:p-m)){if(b<k?(l=b,a=k):(l=k,a=b),u<l||u>a)return r}else if(a<
h?(l=a,a=h):l=h,s<l||s>a)return r;0<q?r++:0>q&&r--}else{var s=(a+c)/2,u=(b+d)/2,v=(c+f)/2,D=(d+g)/2;f=(f+h)/2;g=(g+k)/2;d=(s+v)/2;c=(u+D)/2;var v=(v+f)/2,D=(D+g)/2,A=(d+v)/2,G=(c+D)/2,r=r+F.Qs(a,b,s,u,d,c,A,G,l,m,n,p,q),r=r+F.Qs(A,G,v,D,f,g,h,k,l,m,n,p,q)}return r},dn:function(a,b,c,d,f,g,h){if(F.mb(a,c)){var k;b<d?(k=b,c=d):(k=d,c=b);d=g;if(d<k)return h.x=a,h.y=k,!1;if(d>c)return h.x=a,h.y=c,!1;h.x=a;h.y=d;return!0}if(F.mb(b,d)){a<c?k=a:(k=c,c=a);d=f;if(d<k)return h.x=k,h.y=b,!1;if(d>c)return h.x=
c,h.y=b,!1;h.x=d;h.y=b;return!0}k=((a-f)*(a-c)+(b-g)*(b-d))/((c-a)*(c-a)+(d-b)*(d-b));if(-5E-6>k)return h.x=a,h.y=b,!1;if(1.000005<k)return h.x=c,h.y=d,!1;h.x=a+k*(c-a);h.y=b+k*(d-b);return!0},ih:function(a,b,c,d,f,g,h,k,l){if(F.I(a,c)&&F.I(b,d))return l.x=a,l.y=b,!1;if(F.mb(f,h)){if(F.mb(a,c))return F.dn(a,b,c,d,f,g,l),!1;g=(d-b)/(c-a)*(f-a)+b;return F.dn(a,b,c,d,f,g,l)}k=(k-g)/(h-f);if(F.mb(a,c)){g=k*(a-f)+g;b<d?(h=b,c=d):(h=d,c=b);if(g<h)return l.x=a,l.y=h,!1;if(g>c)return l.x=a,l.y=c,!1;l.x=a;
l.y=g;return!0}h=(d-b)/(c-a);if(F.mb(k,h))return F.dn(a,b,c,d,f,g,l),!1;f=(h*a-k*f+g-b)/(h-k);if(F.mb(h,0)){a<c?h=a:(h=c,c=a);if(f<h)return l.x=h,l.y=b,!1;if(f>c)return l.x=c,l.y=b,!1;l.x=f;l.y=b;return!0}g=h*(f-a)+b;return F.dn(a,b,c,d,f,g,l)},CK:function(a,b,c,d,f){return F.ih(c.x,c.y,d.x,d.y,a.x,a.y,b.x,b.y,f)},yK:function(a,b,c,d,f,g,h,k,l,m){function n(c,d){var f=(c-a)*(c-a)+(d-b)*(d-b);f<p&&(p=f,l.x=c,l.y=d)}var p=Infinity;n(l.x,l.y);var q,r,s,u;f<h?(q=f,r=h):(q=h,r=f);g<k?(s=f,u=h):(s=h,u=
f);q=(r-q)/2+m;m=(u-s)/2+m;f=(f+h)/2;g=(g+k)/2;if(0===q||0===m)return l;if(0.5>(c>a?c-a:a-c)){q=1-(c-f)*(c-f)/(q*q);if(0>q)return l;q=Math.sqrt(q);d=-m*q+g;n(c,m*q+g);n(c,d)}else{c=(d-b)/(c-a);d=1/(q*q)+c*c/(m*m);k=2*c*(b-c*a)/(m*m)-2*c*g/(m*m)-2*f/(q*q);q=k*k-4*d*(2*c*a*g/(m*m)-2*b*g/(m*m)+g*g/(m*m)+f*f/(q*q)-1+(b-c*a)*(b-c*a)/(m*m));if(0>q)return l;q=Math.sqrt(q);m=(-k+q)/(2*d);n(m,c*m-c*a+b);q=(-k-q)/(2*d);n(q,c*q-c*a+b)}return l},ll:function(a,b,c,d,f,g,h,k,l){var m=1E21,n=a,p=b;if(F.ih(a,b,a,
d,f,g,h,k,l)){var q=(l.x-f)*(l.x-f)+(l.y-g)*(l.y-g);q<m&&(m=q,n=l.x,p=l.y)}F.ih(c,b,c,d,f,g,h,k,l)&&(q=(l.x-f)*(l.x-f)+(l.y-g)*(l.y-g),q<m&&(m=q,n=l.x,p=l.y));F.ih(a,b,c,b,f,g,h,k,l)&&(q=(l.x-f)*(l.x-f)+(l.y-g)*(l.y-g),q<m&&(m=q,n=l.x,p=l.y));F.ih(a,d,c,d,f,g,h,k,l)&&(q=(l.x-f)*(l.x-f)+(l.y-g)*(l.y-g),q<m&&(m=q,n=l.x,p=l.y));l.x=n;l.y=p;return 1E21>m},Nw:function(a,b,c){var d=b.x,f=b.y,g=c.x,h=c.y,k=a.left,l=a.right,m=a.top,n=a.bottom;return d===g?(f<h?(a=f,g=h):(a=h,g=f),k<=d&&d<=l&&a<=n&&g>=m):
f===h?(d<g?a=d:(a=g,g=d),m<=f&&f<=n&&a<=l&&g>=k):a.Fa(b)||a.Fa(c)||F.Mw(k,m,l,m,d,f,g,h)||F.Mw(l,m,l,n,d,f,g,h)||F.Mw(l,n,k,n,d,f,g,h)||F.Mw(k,n,k,m,d,f,g,h)?!0:!1},Mw:function(a,b,c,d,f,g,h,k){return 0>=F.nw(a,b,c,d,f,g)*F.nw(a,b,c,d,h,k)&&0>=F.nw(f,g,h,k,a,b)*F.nw(f,g,h,k,c,d)},nw:function(a,b,c,d,f,g){c-=a;d-=b;a=f-a;b=g-b;g=a*d-b*c;0===g&&(g=a*c+b*d,0<g&&(g=(a-c)*c+(b-d)*d,0>g&&(g=0)));return 0>g?-1:0<g?1:0},Dt:function(a){0>a&&(a+=360);360<=a&&(a-=360);return a},gE:function(a,b,c,d,f,g){var h=
Math.PI;g||(d*=h/180,f*=h/180);g=d<f?1:-1;var k=[],l=h/2,m=d;for(d=Math.min(2*h+1E-5,Math.abs(f-d));1E-5<d;)f=m+g*Math.min(d,l),k.push(F.jI(c,m,f,a,b)),d-=Math.abs(f-m),m=f;return k},jI:function(a,b,c,d,f){var g=(c-b)/2,h=a*Math.cos(g),k=a*Math.sin(g),l=0.5522847498*Math.tan(g),m=h+l*k,h=-k+l*h,k=-h,l=g+b,g=Math.cos(l),l=Math.sin(l);return{x1:d+a*Math.cos(b),y1:f+a*Math.sin(b),x2:d+m*g-h*l,y2:f+m*l+h*g,ge:d+m*g-k*l,he:f+m*l+k*g,Vb:d+a*Math.cos(c),Wb:f+a*Math.sin(c)}},dt:function(a,b,c,d,f,g,h){c=
Math.floor((a-c)/f)*f+c;d=Math.floor((b-d)/g)*g+d;var k=c;c+f-a<f/2&&(k=c+f);a=d;d+g-b<g/2&&(a=d+g);h.p(k,a);return h},GE:function(a,b){var c=Math.max(a,b),d=Math.min(a,b),f=1,g=1;do f=c%d,c=g=d,d=f;while(0<f);return g},yI:function(a,b,c,d){var f=0>c,g=0>d,h,k;a<b?(h=1,k=0):(h=0,k=1);var l,m;l=0===h?a:b;m=0===h?c:d;if(0===h?f:g)m=-m;h=k;c=0===h?c:d;if(0===h?f:g)c=-c;return F.zI(l,0===h?a:b,m,c,0,0)},zI:function(a,b,c,d,f,g){if(0<d)if(0<c){g=a*a;f=b*b;a*=c;var h=b*d,k=-f+h,l=-f+Math.sqrt(a*a+h*h);
b=k;for(var m=0;9999999999>m;++m){b=0.5*(k+l);if(b===k||b===l)break;var n=a/(b+g),p=h/(b+f),n=n*n+p*p-1;if(0<n)k=b;else if(0>n)l=b;else break}c=g*c/(b+g)-c;d=f*d/(b+f)-d;c=Math.sqrt(c*c+d*d)}else c=Math.abs(d-b);else d=a*a-b*b,f=a*c,f<d?(d=f/d,f=a*d,g=b*Math.sqrt(Math.abs(1-d*d)),c=f-c,c=Math.sqrt(c*c+g*g)):c=Math.abs(c-a);return c}};
function zc(a){1<arguments.length&&t.m("Geometry constructor can take at most one optional argument, the Geometry type.");t.Ac(this);void 0===a?a=Ac:e&&t.Aa(a,zc,zc,"constructor:type");this.da=a;this.tb=this.jb=this.yc=this.oc=0;this.Ck=new B(Bc);this.Iu=this.Ck.V;this.tu=(new x).freeze();this.Va=!0;this.Mi=Eb;this.Ni=Pb;this.Zn=this.$n=NaN;this.Hc=Cc}t.ga("Geometry",zc);t.Xh(zc);
zc.prototype.copy=function(){var a=new zc;a.da=this.da;a.oc=this.oc;a.yc=this.yc;a.jb=this.jb;a.tb=this.tb;for(var b=this.Ck,c=b.length,d=a.Ck,f=0;f<c;f++){var g=b.n[f].copy();d.add(g)}a.Iu=this.Iu;a.tu.assign(this.tu);a.Va=this.Va;a.Mi=this.Mi.Z();a.Ni=this.Ni.Z();a.$n=this.$n;a.Zn=this.Zn;a.Hc=this.Hc;return a};var Dc;zc.Line=Dc=t.w(zc,"Line",0);var Ec;zc.Rectangle=Ec=t.w(zc,"Rectangle",1);var Mc;zc.Ellipse=Mc=t.w(zc,"Ellipse",2);var Ac;zc.Path=Ac=t.w(zc,"Path",3);
zc.prototype.Ja=function(){this.freeze();Object.freeze(this);return this};zc.prototype.freeze=function(){this.nb=!0;var a=this.Bb;a.freeze();for(var b=a.length,c=0;c<b;c++)a.n[c].freeze();return this};zc.prototype.Ma=function(){Object.isFrozen(this)&&t.m("cannot thaw constant: "+this);this.nb=!1;var a=this.Bb;a.Ma();for(var b=a.length,c=0;c<b;c++)a.n[c].Ma();return this};
zc.prototype.equalsApprox=zc.prototype.tf=function(a){if(!(a instanceof zc))return!1;if(this.type!==a.type)return this.type===Dc&&a.type===Ac?Nc(this,a):a.type===Dc&&this.type===Ac?Nc(a,this):!1;if(this.type===Ac){var b=this.Bb;a=a.Bb;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b.n[d].tf(a.n[d]))return!1;return!0}return F.I(this.qa,a.qa)&&F.I(this.ra,a.ra)&&F.I(this.D,a.D)&&F.I(this.F,a.F)};
function Nc(a,b){if(a.type!==Dc||b.type!==Ac)return!1;if(1===b.Bb.count){var c=b.Bb.wa(0);if(1===c.Ea.count&&F.I(a.qa,c.qa)&&F.I(a.ra,c.ra)&&(c=c.Ea.wa(0),c.type===Oc&&F.I(a.D,c.D)&&F.I(a.F,c.F)))return!0}return!1}var Pc;zc.stringify=Pc=function(a){return a.toString()};
zc.prototype.toString=function(a){switch(this.type){case Dc:return void 0===a?"M"+this.qa.toString()+" "+this.ra.toString()+"L"+this.D.toString()+" "+this.F.toString():"M"+this.qa.toFixed(a)+" "+this.ra.toFixed(a)+"L"+this.D.toFixed(a)+" "+this.F.toFixed(a);case Ec:var b=new x(this.qa,this.ra,0,0);b.OG(this.D,this.F,0,0);return void 0===a?"M"+b.x.toString()+" "+b.y.toString()+"H"+b.right.toString()+"V"+b.bottom.toString()+"H"+b.left.toString()+"z":"M"+b.x.toFixed(a)+" "+b.y.toFixed(a)+"H"+b.right.toFixed(a)+
"V"+b.bottom.toFixed(a)+"H"+b.left.toFixed(a)+"z";case Mc:b=new x(this.qa,this.ra,0,0);b.OG(this.D,this.F,0,0);if(void 0===a){var c=b.left.toString()+" "+(b.y+b.height/2).toString(),d=b.right.toString()+" "+(b.y+b.height/2).toString();return"M"+c+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+d+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+c}c=b.left.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);d=b.right.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);return"M"+c+"A"+
(b.width/2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+d+"A"+(b.width/2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+c;case Ac:for(var b="",c=this.Bb,d=c.length,f=0;f<d;f++){var g=c.n[f];0<f&&(b+=" x ");g.pp&&(b+="F ");b+=g.toString(a)}return b;default:return this.type.toString()}};var Qc;
zc.fillPath=Qc=function(a){"string"!==typeof a&&t.bc(a,"string",zc,"fillPath:str");a=a.split(/[Xx]/);for(var b=a.length,c="",d=0;d<b;d++)var f=a[d],c=null!==f.match(/[Ff]/)?0===d?c+f:c+("X"+(" "===f[0]?"":" ")+f):c+((0===d?"":"X ")+"F"+(" "===f[0]?"":" ")+f);return c};var Rc;
zc.parse=Rc=function(a,b){function c(){return n>=v-1?!0:null!==m[n+1].match(/[A-Za-z]/)}function d(){n++;return m[n]}function f(){var a=new w(parseFloat(d()),parseFloat(d()));p===p.toLowerCase()&&(a.x=u.x+a.x,a.y=u.y+a.y);return a}function g(){return u=f()}function h(){return s=f()}function k(){var a=[parseFloat(d()),parseFloat(d()),parseFloat(d()),parseFloat(d()),parseFloat(d())];c()||(a.push(parseFloat(d())),c()||a.push(parseFloat(d())));p===p.toLowerCase()&&(a[2]+=u.x,a[3]+=u.y);return a}function l(){return"c"!==
q.toLowerCase()&&"s"!==q.toLowerCase()?u:new w(2*u.x-s.x,2*u.y-s.y)}void 0===b&&(b=!1);"string"!==typeof a&&t.bc(a,"string",zc,"parse:str");a=a.replace(/,/gm," ");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFf])([^\s])/gm,"$1 $2");a=a.replace(/([^\s])([UuBbMmZzLlHhVvCcSsQqTtAaFf])/gm,"$1 $2");a=a.replace(/([0-9])([+\-])/gm,"$1 $2");
a=a.replace(/(\.[0-9]*)(\.)/gm,"$1 $2");a=a.replace(/([Aa](\s+[0-9]+){3})\s+([01])\s*([01])/gm,"$1 $3 $4 ");a=a.replace(/[\s\r\t\n]+/gm," ");a=a.replace(/^\s+|\s+$/g,"");for(var m=a.split(" "),n=-1,p="",q="",r=new w(0,0),s=new w(0,0),u=new w(0,0),v=m.length,D=t.u(),A,G=!1,K=!1,N=!0;!(n>=v-1);)if(q=p,p=d(),""!==p)switch(p.toUpperCase()){case "X":N=!0;K=G=!1;break;case "M":A=g();null===D.Xb||!0===N?(I(D,A.x,A.y,G,!1,!K),N=!1):D.moveTo(A.x,A.y);for(r=u;!c();)A=g(),D.lineTo(A.x,A.y);break;case "L":for(;!c();)A=
g(),D.lineTo(A.x,A.y);break;case "H":for(;!c();)u=A=new w((p===p.toLowerCase()?u.x:0)+parseFloat(d()),u.y),D.lineTo(u.x,u.y);break;case "V":for(;!c();)u=A=new w(u.x,(p===p.toLowerCase()?u.y:0)+parseFloat(d())),D.lineTo(u.x,u.y);break;case "C":for(;!c();){var O=f(),U=h();A=g();J(D,O.x,O.y,U.x,U.y,A.x,A.y)}break;case "S":for(;!c();)O=l(),U=h(),A=g(),J(D,O.x,O.y,U.x,U.y,A.x,A.y);break;case "Q":for(;!c();)U=h(),A=g(),Sc(D,U.x,U.y,A.x,A.y);break;case "T":for(;!c();)s=U=l(),A=g(),Sc(D,U.x,U.y,A.x,A.y);
break;case "B":for(;!c();)A=k(),D.arcTo(A[0],A[1],A[2],A[3],A[4],A[5],A[6]);break;case "A":for(;!c();){var O=Math.abs(parseFloat(d())),U=Math.abs(parseFloat(d())),X=parseFloat(d()),Z=!!parseFloat(d()),P=!!parseFloat(d());A=g();Tc(D,O,U,X,Z,P,A.x,A.y)}break;case "Z":L(D);u=r;break;case "F":A=null;for(O=1;m[n+O];)if(null!==m[n+O].match(/[Uu]/))O++;else if(null===m[n+O].match(/[A-Za-z]/))O++;else{A=m[n+O];break}A.match(/[Mm]/)?G=!0:Zc(D);break;case "U":A=null;for(O=1;m[n+O];)if(null!==m[n+O].match(/[Ff]/))O++;
else if(null===m[n+O].match(/[A-Za-z]/))O++;else{A=m[n+O];break}A.match(/[Mm]/)?K=!0:D.bb(!1)}r=D.s;t.v(D);if(b)for(D=r.Bb.k;D.next();)D.value.pp=!0;return r};function $c(a,b){for(var c=a.length,d=t.K(),f=0;f<c;f++){var g=a[f];d.x=g.x1;d.y=g.y1;b.Sa(d);g.x1=d.x;g.y1=d.y;d.x=g.x2;d.y=g.y2;b.Sa(d);g.x2=d.x;g.y2=d.y;d.x=g.ge;d.y=g.he;b.Sa(d);g.ge=d.x;g.he=d.y;d.x=g.Vb;d.y=g.Wb;b.Sa(d);g.Vb=d.x;g.Wb=d.y}t.B(d)}
zc.prototype.uA=function(){if(this.Va)return!0;var a=this.Bb;if(this.Iu!==a.V)return!0;for(var b=a.length,c=0;c<b;c++)if(a.n[c].uA())return!0;return!1};zc.prototype.oB=function(){this.Va=!1;var a=this.Bb;this.Iu=a.V;for(var b=a.length,c=0;c<b;c++)a.n[c].oB()};zc.prototype.qf=function(){var a=this.tu;a.Ma();isNaN(this.$n)||isNaN(this.Zn)?a.p(0,0,0,0):a.p(0,0,this.$n,this.Zn);ad(this,a,!1);hb(a,0,0,0,0);a.freeze()};
zc.prototype.computeBoundsWithoutOrigin=zc.prototype.fI=function(){var a=new x;ad(this,a,!0);return a};
function ad(a,b,c){switch(a.type){case Dc:case Ec:case Mc:c?b.p(a.oc,a.yc,0,0):hb(b,a.oc,a.yc,0,0);hb(b,a.jb,a.tb,0,0);break;case Ac:a=a.Bb;for(var d=a.length,f=0;f<d;f++){var g=a.n[f];c&&0===f?b.p(g.qa,g.ra,0,0):hb(b,g.qa,g.ra,0,0);for(var h=g.Ea,k=h.length,l=g.qa,m=g.ra,n=0;n<k;n++){var p=h.n[n];switch(p.type){case Oc:case bd:l=p.D;m=p.F;hb(b,l,m,0,0);break;case cd:F.So(l,m,p.Cb,p.Sb,p.re,p.se,p.D,p.F,0.5,b);l=p.D;m=p.F;break;case dd:F.NA(l,m,p.Cb,p.Sb,p.D,p.F,0.5,b);l=p.D;m=p.F;break;case ed:case fd:for(var p=
p.type===ed?gd(p,g):hd(p,g,l,m),q=p.length,r=null,s=0;s<q;s++)r=p[s],F.So(r.x1,r.y1,r.x2,r.y2,r.ge,r.he,r.Vb,r.Wb,0.5,b);null!==r&&(l=r.Vb,m=r.Wb);break;default:t.m("Unknown Segment type: "+p.type)}}}break;default:t.m("Unknown Geometry type: "+a.type)}}zc.prototype.normalize=zc.prototype.normalize=function(){t.L(this);var a=this.fI();this.offset(-a.x,-a.y);return new w(-a.x,-a.y)};
zc.prototype.offset=zc.prototype.offset=function(a,b){t.L(this);e&&(t.q(a,zc,"offset"),t.q(b,zc,"offset"));this.transform(1,0,0,1,a,b)};zc.prototype.scale=zc.prototype.scale=function(a,b){t.L(this);e&&(t.q(a,zc,"scale:x"),t.q(b,zc,"scale:y"),0>=a&&t.ka(a,"scale must be greater than zero",zc,"scale:x"),0>=b&&t.ka(b,"scale must be greater than zero",zc,"scale:y"));this.transform(a,0,0,b,0,0)};
zc.prototype.rotate=zc.prototype.rotate=function(a,b,c){t.L(this);void 0===b&&(b=0);void 0===c&&(c=0);e&&(t.q(a,zc,"rotate:angle"),t.q(b,zc,"rotate:x"),t.q(c,zc,"rotate:y"));var d=t.oh();d.reset();d.rotate(a,b,c);this.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);t.We(d)};
zc.prototype.transform=zc.prototype.transform=function(a,b,c,d,f,g){var h,k;switch(this.type){case Dc:case Ec:case Mc:h=this.oc;k=this.yc;this.oc=h*a+k*c+f;this.yc=h*b+k*d+g;h=this.jb;k=this.tb;this.jb=h*a+k*c+f;this.tb=h*b+k*d+g;break;case Ac:for(var l=this.Bb,m=l.length,n=0;n<m;n++){var p=l.n[n];h=p.qa;k=p.ra;p.qa=h*a+k*c+f;p.ra=h*b+k*d+g;for(var p=p.Ea,q=p.length,r=0;r<q;r++){var s=p.n[r];switch(s.type){case Oc:case bd:h=s.D;k=s.F;s.D=h*a+k*c+f;s.F=h*b+k*d+g;break;case cd:h=s.Cb;k=s.Sb;s.Cb=h*
a+k*c+f;s.Sb=h*b+k*d+g;h=s.re;k=s.se;s.re=h*a+k*c+f;s.se=h*b+k*d+g;h=s.D;k=s.F;s.D=h*a+k*c+f;s.F=h*b+k*d+g;break;case dd:h=s.Cb;k=s.Sb;s.Cb=h*a+k*c+f;s.Sb=h*b+k*d+g;h=s.D;k=s.F;s.D=h*a+k*c+f;s.F=h*b+k*d+g;break;case ed:h=s.Da;k=s.Oa;s.Da=h*a+k*c+f;s.Oa=h*b+k*d+g;s.radiusX*=Math.sqrt(a*a+c*c);void 0!==s.radiusY&&(s.radiusY*=Math.sqrt(b*b+d*d));break;case fd:h=s.D;k=s.F;s.D=h*a+k*c+f;s.F=h*b+k*d+g;s.radiusX*=Math.sqrt(a*a+c*c);s.radiusY*=Math.sqrt(b*b+d*d);break;default:t.m("Unknown Segment type: "+
s.type)}}}}this.Va=!0};zc.prototype.Ds=function(a,b){var c=this.oc,d=this.yc,f=this.jb,g=this.tb,h=Math.min(c,f),k=Math.min(d,g),c=Math.abs(f-c),d=Math.abs(g-d),g=t.K();g.x=h;g.y=k;b.Sa(g);f=new Bc(g.x,g.y);g.x=h+c;g.y=k;b.Sa(g);f.Ea.add(new id(Oc,g.x,g.y));g.x=h+c;g.y=k+d;b.Sa(g);f.Ea.add(new id(Oc,g.x,g.y));g.x=h;g.y=k+d;b.Sa(g);f.Ea.add((new id(Oc,g.x,g.y)).close());t.B(g);a.type=Ac;a.Bb.add(f);return a};
zc.prototype.Fa=function(a,b,c,d){var f=a.x,g=a.y,h=this.Pb.x-20;a=a.y;for(var k=0,l,m,n,p,q,r,s,u=this.Bb.n,v=u.length,D=0;D<v;D++){var A=u[D];if(A.pp){if(c&&A.Fa(f,g,b))return!0;var G=A.Ea;l=A.qa;m=A.ra;for(var K=l,N=m,O=0;O<=G.length;O++){O!==G.length?(n=G.n[O],p=n.type,r=n.D,s=n.F):(p=Oc,r=K,s=N);switch(p){case bd:q=jd(f,g,h,a,l,m,K,N);if(!0===q)return!0;k+=q;K=r;N=s;break;case Oc:q=jd(f,g,h,a,l,m,r,s);if(!0===q)return!0;k+=q;break;case cd:q=F.Qs(l,m,n.Cb,n.Sb,n.re,n.se,r,s,h,a,f,g,0.5);k+=q;
break;case dd:q=F.Qs(l,m,(l+2*n.Cb)/3,(m+2*n.Sb)/3,(n.Cb+2*r)/3,(n.Cb+2*r)/3,r,s,h,a,f,g,0.5);k+=q;break;case ed:case fd:p=n.type===ed?gd(n,A):hd(n,A,l,m);for(var U=p.length,X=null,Z=0;Z<U;Z++){X=p[Z];if(0===Z){q=jd(f,g,h,a,l,m,X.x1,X.y1);if(!0===q)return!0;k+=q}q=F.Qs(X.x1,X.y1,X.x2,X.y2,X.ge,X.he,X.Vb,X.Wb,h,a,f,g,0.5);k+=q}null!==X&&(r=X.Vb,s=X.Wb);break;default:t.m("Unknown Segment type: "+n.type)}l=r;m=s}if(0!==k)return!0;k=0}else if(A.Fa(f,g,d?b:b+2))return!0}return 0!==k};
function jd(a,b,c,d,f,g,h,k){if(F.Md(f,g,h,k,0.05,a,b))return!0;var l=(a-c)*(g-k);if(0===l)return 0;var m=((a*d-b*c)*(f-h)-(a-c)*(f*k-g*h))/l;b=(a*d-b*c)*(g-k)/l;if(m>=a)return 0;if((f>h?f-h:h-f)<(g>k?g-k:k-g)){if(g<k?(a=g,f=k):(a=k,f=g),b<a||b>f)return 0}else if(f<h?(a=f,f=h):a=h,m<a||m>f)return 0;return 0<l?1:-1}function kd(a,b,c,d){for(var f=a.Bb.length,g=0;g<f;g++)if(a.Bb.n[g].Fa(b,c,d))return!0;return!1}
zc.prototype.getPointAlongPath=function(a){(0>a||1<a)&&t.ka(a,"0 <= fraction <= 1",zc,"getPointAlongPath:fraction");var b=this.Bb.$a(),c=t.Db(),d=[];d.push([b.qa,b.ra]);for(var f=b.qa,g=b.ra,h=f,k=g,l=b.Ea.n,m=l.length,n=0;n<m;n++){var p=l[n];switch(p.da){case bd:c.push(d);d=[];d.push([p.D,p.F]);f=p.D;g=p.F;h=f;k=g;break;case Oc:d.push([p.D,p.F]);f=p.D;g=p.F;break;case cd:F.ze(f,g,p.jd,p.ye,p.ng,p.og,p.jb,p.tb,0.5,d);f=p.D;g=p.F;break;case dd:F.Jp(f,g,p.jd,p.ye,p.jb,p.tb,0.5,d);f=p.D;g=p.F;break;
case ed:for(var q=gd(p,b),r=q.length,s=0;s<r;s++){var u=q[s];F.ze(f,g,u.x2,u.y2,u.ge,u.he,u.Vb,u.Wb,0.5,d);f=u.Vb;g=u.Wb}break;case fd:q=hd(p,b,f,g);r=q.length;for(s=0;s<r;s++)u=q[s],F.ze(f,g,u.x2,u.y2,u.ge,u.he,u.Vb,u.Wb,0.5,d),f=u.Vb,g=u.Wb;break;default:t.m("Segment not of valid type")}p.Gh&&d.push([h,k])}c.push(d);m=0;f=c.length;b=null;for(g=0;g<f;g++)for(h=c[g],k=h.length,n=0;n<k;n++)d=h[n],0!==n&&(l=Math.sqrt(Sa(b[0],b[1],d[0],d[1])),m+=l),b=d;a*=m;for(g=m=0;g<f;g++)for(h=c[g],k=h.length,n=
0;n<k;n++){d=h[n];if(0!==n){l=Math.sqrt(Sa(b[0],b[1],d[0],d[1]));if(m+l>a)return n=(a-m)/l,t.ya(c),new w(b[0]+(d[0]-b[0])*n,b[1]+(d[1]-b[1])*n);m+=l}b=d}t.ya(c);return null};t.g(zc,"type",zc.prototype.type);t.defineProperty(zc,{type:"type"},function(){return this.da},function(a){this.da!==a&&(e&&t.Aa(a,zc,zc,"type"),t.L(this,a),this.da=a,this.Va=!0)});t.g(zc,"startX",zc.prototype.qa);
t.defineProperty(zc,{qa:"startX"},function(){return this.oc},function(a){this.oc!==a&&(e&&t.q(a,zc,"startX"),t.L(this,a),this.oc=a,this.Va=!0)});t.g(zc,"startY",zc.prototype.ra);t.defineProperty(zc,{ra:"startY"},function(){return this.yc},function(a){this.yc!==a&&(e&&t.q(a,zc,"startY"),t.L(this,a),this.yc=a,this.Va=!0)});t.g(zc,"endX",zc.prototype.D);t.defineProperty(zc,{D:"endX"},function(){return this.jb},function(a){this.jb!==a&&(e&&t.q(a,zc,"endX"),t.L(this,a),this.jb=a,this.Va=!0)});
t.g(zc,"endY",zc.prototype.F);t.defineProperty(zc,{F:"endY"},function(){return this.tb},function(a){this.tb!==a&&(e&&t.q(a,zc,"endY"),t.L(this,a),this.tb=a,this.Va=!0)});t.g(zc,"figures",zc.prototype.Bb);t.defineProperty(zc,{Bb:"figures"},function(){return this.Ck},function(a){this.Ck!==a&&(e&&t.l(a,B,zc,"figures"),t.L(this,a),this.Ck=a,this.Va=!0)});t.defineProperty(zc,{G:"spot1"},function(){return this.Mi},function(a){e&&t.l(a,H,zc,"spot1");t.L(this,a);this.Mi=a.Z()});
t.defineProperty(zc,{H:"spot2"},function(){return this.Ni},function(a){e&&t.l(a,H,zc,"spot2");t.L(this,a);this.Ni=a.Z()});t.A(zc,{Pb:"bounds"},function(){this.uA()&&(this.oB(),this.qf());return this.tu});function Bc(a,b,c){t.Ac(this);void 0===c&&(c=!0);this.Wl=c;this.bo=!0;void 0!==a?(e&&t.q(a,Bc,"sx"),this.oc=a):this.oc=0;void 0!==b?(e&&t.q(b,Bc,"sy"),this.yc=b):this.yc=0;this.hs=new B(id);this.Hv=this.hs.V;this.Va=!0}t.ga("PathFigure",Bc);t.Xh(Bc);
Bc.prototype.copy=function(){var a=new Bc;a.Wl=this.Wl;a.bo=this.bo;a.oc=this.oc;a.yc=this.yc;for(var b=this.hs,c=b.length,d=a.hs,f=0;f<c;f++){var g=b.n[f].copy();d.add(g)}a.Hv=this.Hv;a.Va=this.Va;return a};Bc.prototype.equalsApprox=Bc.prototype.tf=function(a){if(!(a instanceof Bc&&F.I(this.qa,a.qa)&&F.I(this.ra,a.ra)))return!1;var b=this.Ea;a=a.Ea;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b.n[d].tf(a.n[d]))return!1;return!0};aa=Bc.prototype;
aa.toString=function(a){for(var b=void 0===a?"M"+this.qa.toString()+" "+this.ra.toString():"M"+this.qa.toFixed(a)+" "+this.ra.toFixed(a),c=this.Ea,d=c.length,f=0;f<d;f++)b+=" "+c.n[f].toString(a);return b};aa.freeze=function(){this.nb=!0;var a=this.Ea;a.freeze();for(var b=a.length,c=0;c<b;c++)a.n[c].freeze();return this};aa.Ma=function(){this.nb=!1;var a=this.Ea;a.Ma();for(var b=a.length,c=0;c<b;c++)a.n[c].Ma();return this};
aa.uA=function(){if(this.Va)return!0;var a=this.Ea;if(this.Hv!==a.V)return!0;for(var b=a.length,c=0;c<b;c++)if(a.n[c].Va)return!0;return!1};aa.oB=function(){this.Va=!1;var a=this.Ea;this.Hv=a.V;for(var b=a.length,c=0;c<b;c++){var d=a.n[c];d.Va=!1;d.nj=null}};t.g(Bc,"isFilled",Bc.prototype.pp);t.defineProperty(Bc,{pp:"isFilled"},function(){return this.Wl},function(a){e&&t.i(a,"boolean",Bc,"isFilled");t.L(this,a);this.Wl=a});t.g(Bc,"isShadowed",Bc.prototype.gj);
t.defineProperty(Bc,{gj:"isShadowed"},function(){return this.bo},function(a){e&&t.i(a,"boolean",Bc,"isShadowed");t.L(this,a);this.bo=a});t.g(Bc,"startX",Bc.prototype.qa);t.defineProperty(Bc,{qa:"startX"},function(){return this.oc},function(a){e&&t.q(a,Bc,"startX");t.L(this,a);this.oc=a;this.Va=!0});t.g(Bc,"startY",Bc.prototype.ra);t.defineProperty(Bc,{ra:"startY"},function(){return this.yc},function(a){e&&t.q(a,Bc,"startY");t.L(this,a);this.yc=a;this.Va=!0});t.g(Bc,"segments",Bc.prototype.Ea);
t.defineProperty(Bc,{Ea:"segments"},function(){return this.hs},function(a){e&&t.l(a,B,Bc,"segments");t.L(this,a);this.hs=a;this.Va=!0});
Bc.prototype.Fa=function(a,b,c){for(var d=this.qa,f=this.ra,g=d,h=f,k=this.Ea.n,l=k.length,m=0;m<l;m++){var n=k[m];switch(n.type){case bd:g=n.D;h=n.F;d=n.D;f=n.F;break;case Oc:if(F.Md(d,f,n.D,n.F,c,a,b))return!0;d=n.D;f=n.F;break;case cd:if(F.hw(d,f,n.Cb,n.Sb,n.re,n.se,n.D,n.F,0.5,a,b,c))return!0;d=n.D;f=n.F;break;case dd:if(F.OA(d,f,n.Cb,n.Sb,n.D,n.F,0.5,a,b,c))return!0;d=n.D;f=n.F;break;case ed:case fd:for(var p=n.type===ed?gd(n,this):hd(n,this,d,f),q=p.length,r=null,s=0;s<q;s++)if(r=p[s],0===s&&
F.Md(d,f,r.x1,r.y1,c,a,b)||F.hw(r.x1,r.y1,r.x2,r.y2,r.ge,r.he,r.Vb,r.Wb,0.5,a,b,c))return!0;null!==r&&(d=r.Vb,f=r.Wb);break;default:t.m("Unknown Segment type: "+n.type)}if(n.ot&&(d!==g||f!==h)&&F.Md(d,f,g,h,c,a,b))return!0}return!1};
function id(a,b,c,d,f,g,h,k){t.Ac(this);void 0===a?a=Oc:e&&t.Aa(a,id,id,"constructor:type");this.da=a;void 0!==b?(e&&t.q(b,id,"ex"),this.jb=b):this.jb=0;void 0!==c?(e&&t.q(c,id,"ey"),this.tb=c):this.tb=0;a===fd?(void 0!==g&&(a=g%360,0>a&&(a+=360),this.jd=a),void 0!==d&&(e&&t.q(d,id,"x1"),this.ng=Math.max(d,0)),void 0!==f&&(e&&t.q(f,id,"y1"),this.og=Math.max(f,0)),this.jo=!!h,this.yn=!!k):(void 0!==d&&(e&&t.q(d,id,"x1"),this.jd=d),void 0!==f&&(e&&t.q(f,id,"y1"),this.ye=f),void 0!==g&&(e&&t.q(g,id,
"x2"),a===ed&&(g=Math.max(g,0)),this.ng=g),void 0!==h&&"number"===typeof h&&(e&&t.q(h,id,"y2"),a===ed&&(h=Math.max(h,0)),this.og=h));this.Gh=!1;this.Va=!0;this.nj=null}t.ga("PathSegment",id);t.Xh(id);
id.prototype.copy=function(){var a=new id;a.da=this.da;a.jb=this.jb;a.tb=this.tb;void 0!==this.jd&&(a.jd=this.jd);void 0!==this.ye&&(a.ye=this.ye);void 0!==this.ng&&(a.ng=this.ng);void 0!==this.og&&(a.og=this.og);void 0!==this.jo&&(a.jo=this.jo);void 0!==this.yn&&(a.yn=this.yn);a.Gh=this.Gh;a.Va=this.Va;return a};
id.prototype.equalsApprox=id.prototype.tf=function(a){if(!(a instanceof id)||this.type!==a.type||this.ot!==a.ot)return!1;switch(this.type){case bd:case Oc:return F.I(this.D,a.D)&&F.I(this.F,a.F);case cd:return F.I(this.D,a.D)&&F.I(this.F,a.F)&&F.I(this.Cb,a.Cb)&&F.I(this.Sb,a.Sb)&&F.I(this.re,a.re)&&F.I(this.se,a.se);case dd:return F.I(this.D,a.D)&&F.I(this.F,a.F)&&F.I(this.Cb,a.Cb)&&F.I(this.Sb,a.Sb);case ed:return F.I(this.mh,a.mh)&&F.I(this.gi,a.gi)&&F.I(this.Da,a.Da)&&F.I(this.Oa,a.Oa)&&F.I(this.radiusX,
a.radiusX)&&F.I(this.radiusY,a.radiusY);case fd:return this.Pw===a.Pw&&this.Sw===a.Sw&&F.I(this.Ex,a.Ex)&&F.I(this.D,a.D)&&F.I(this.F,a.F)&&F.I(this.radiusX,a.radiusX)&&F.I(this.radiusY,a.radiusY);default:return!1}};
id.prototype.toString=function(a){switch(this.type){case bd:a=void 0===a?"M"+this.D.toString()+" "+this.F.toString():"M"+this.D.toFixed(a)+" "+this.F.toFixed(a);break;case Oc:a=void 0===a?"L"+this.D.toString()+" "+this.F.toString():"L"+this.D.toFixed(a)+" "+this.F.toFixed(a);break;case cd:a=void 0===a?"C"+this.Cb.toString()+" "+this.Sb.toString()+" "+this.re.toString()+" "+this.se.toString()+" "+this.D.toString()+" "+this.F.toString():"C"+this.Cb.toFixed(a)+" "+this.Sb.toFixed(a)+" "+this.re.toFixed(a)+
" "+this.se.toFixed(a)+" "+this.D.toFixed(a)+" "+this.F.toFixed(a);break;case dd:a=void 0===a?"Q"+this.Cb.toString()+" "+this.Sb.toString()+" "+this.D.toString()+" "+this.F.toString():"Q"+this.Cb.toFixed(a)+" "+this.Sb.toFixed(a)+" "+this.D.toFixed(a)+" "+this.F.toFixed(a);break;case ed:a=void 0===a?"B"+this.mh.toString()+" "+this.gi.toString()+" "+this.Da.toString()+" "+this.Oa.toString()+" "+this.radiusX:"B"+this.mh.toFixed(a)+" "+this.gi.toFixed(a)+" "+this.Da.toFixed(a)+" "+this.Oa.toFixed(a)+
" "+this.radiusX;break;case fd:a=void 0===a?"A"+this.radiusX.toString()+" "+this.radiusY.toString()+" "+this.Ex.toString()+" "+(this.Sw?1:0)+" "+(this.Pw?1:0)+" "+this.D.toString()+" "+this.F.toString():"A"+this.radiusX.toFixed(a)+" "+this.radiusY.toFixed(a)+" "+this.Ex.toFixed(a)+" "+(this.Sw?1:0)+" "+(this.Pw?1:0)+" "+this.D.toFixed(a)+" "+this.F.toFixed(a);break;default:a=this.type.toString()}return a+(this.Gh?"z":"")};var bd;id.Move=bd=t.w(id,"Move",0);var Oc;id.Line=Oc=t.w(id,"Line",1);var cd;
id.Bezier=cd=t.w(id,"Bezier",2);var dd;id.QuadraticBezier=dd=t.w(id,"QuadraticBezier",3);var ed;id.Arc=ed=t.w(id,"Arc",4);var fd;id.SvgArc=fd=t.w(id,"SvgArc",4);id.prototype.freeze=function(){this.nb=!0;return this};id.prototype.Ma=function(){this.nb=!1;return this};id.prototype.close=id.prototype.close=function(){this.Gh=!0;return this};
function gd(a,b){if(null!==a.nj&&!1===b.Va)return a.nj;var c=a.radiusX,d=a.radiusY;void 0===d&&(d=c);var f=a.jd,g=a.ye,h=F.gE(0,0,c<d?c:d,a.mh,a.mh+a.gi);if(c!==d){var k=t.oh();k.reset();c<d?k.scale(1,d/c):k.scale(c/d,1);for(var c=h.length,d=t.K(),l=0;l<c;l++){var m=h[l];d.x=m.x1;d.y=m.y1;k.Sa(d);m.x1=d.x;m.y1=d.y;d.x=m.x2;d.y=m.y2;k.Sa(d);m.x2=d.x;m.y2=d.y;d.x=m.ge;d.y=m.he;k.Sa(d);m.ge=d.x;m.he=d.y;d.x=m.Vb;d.y=m.Wb;k.Sa(d);m.Vb=d.x;m.Wb=d.y}t.B(d);t.We(k)}k=h.length;for(c=0;c<k;c++)d=h[c],d.x1+=
f,d.y1+=g,d.x2+=f,d.y2+=g,d.ge+=f,d.he+=g,d.Vb+=f,d.Wb+=g;a.nj=h;return a.nj}
function hd(a,b,c,d){function f(a,b){return(a[0]*b[1]<a[1]*b[0]?-1:1)*Math.acos(g(a,b))}function g(a,b){return(a[0]*b[0]+a[1]*b[1])/(Math.sqrt(Math.pow(a[0],2)+Math.pow(a[1],2))*Math.sqrt(Math.pow(b[0],2)+Math.pow(b[1],2)))}if(null!==a.nj&&!1===b.Va)return a.nj;b=a.ng;var h=a.og,k=Math.PI/180*a.jd,l=a.jo,m=a.yn,n=a.jb,p=a.tb,q=Math.cos(k)*(c-n)/2+Math.sin(k)*(d-p)/2,r=-Math.sin(k)*(c-n)/2+Math.cos(k)*(d-p)/2,s=Math.pow(q,2)/Math.pow(b,2)+Math.pow(r,2)/Math.pow(h,2);1<s&&(b*=Math.sqrt(s),h*=Math.sqrt(s));
s=(l===m?-1:1)*Math.sqrt((Math.pow(b,2)*Math.pow(h,2)-Math.pow(b,2)*Math.pow(r,2)-Math.pow(h,2)*Math.pow(q,2))/(Math.pow(b,2)*Math.pow(r,2)+Math.pow(h,2)*Math.pow(q,2)));isNaN(s)&&(s=0);l=s*b*r/h;s=s*-h*q/b;isNaN(l)&&(l=0);isNaN(s)&&(s=0);c=(c+n)/2+Math.cos(k)*l-Math.sin(k)*s;d=(d+p)/2+Math.sin(k)*l+Math.cos(k)*s;k=f([1,0],[(q-l)/b,(r-s)/h]);p=[(q-l)/b,(r-s)/h];r=[(-q-l)/b,(-r-s)/h];q=f(p,r);-1>=g(p,r)&&(q=Math.PI);1<=g(p,r)&&(q=0);!m&&0<q&&(q-=2*Math.PI);m&&0>q&&(q+=2*Math.PI);m=b>h?1:b/h;r=b>h?
h/b:1;b=F.gE(0,0,b>h?b:h,k,k+q,!0);h=t.oh();h.reset();h.translate(c,d);h.rotate(a.jd,0,0);h.scale(m,r);$c(b,h);t.We(h);a.nj=b;return a.nj}t.g(id,"isClosed",id.prototype.ot);t.defineProperty(id,{ot:"isClosed"},function(){return this.Gh},function(a){this.Gh!==a&&(this.Gh=a,this.Va=!0)});t.g(id,"type",id.prototype.type);t.defineProperty(id,{type:"type"},function(){return this.da},function(a){e&&t.Aa(a,id,id,"type");t.L(this,a);this.da=a;this.Va=!0});t.g(id,"endX",id.prototype.D);
t.defineProperty(id,{D:"endX"},function(){return this.jb},function(a){e&&t.q(a,id,"endX");t.L(this,a);this.jb=a;this.Va=!0});t.g(id,"endY",id.prototype.F);t.defineProperty(id,{F:"endY"},function(){return this.tb},function(a){e&&t.q(a,id,"endY");t.L(this,a);this.tb=a;this.Va=!0});t.defineProperty(id,{Cb:"point1X"},function(){return this.jd},function(a){e&&t.q(a,id,"point1X");t.L(this,a);this.jd=a;this.Va=!0});
t.defineProperty(id,{Sb:"point1Y"},function(){return this.ye},function(a){e&&t.q(a,id,"point1Y");t.L(this,a);this.ye=a;this.Va=!0});t.defineProperty(id,{re:"point2X"},function(){return this.ng},function(a){e&&t.q(a,id,"point2X");t.L(this,a);this.ng=a;this.Va=!0});t.defineProperty(id,{se:"point2Y"},function(){return this.og},function(a){e&&t.q(a,id,"point2Y");t.L(this,a);this.og=a;this.Va=!0});
t.defineProperty(id,{Da:"centerX"},function(){return this.jd},function(a){e&&t.q(a,id,"centerX");t.L(this,a);this.jd=a;this.Va=!0});t.defineProperty(id,{Oa:"centerY"},function(){return this.ye},function(a){e&&t.q(a,id,"centerY");t.L(this,a);this.ye=a;this.Va=!0});t.defineProperty(id,{radiusX:"radiusX"},function(){return this.ng},function(a){e&&t.q(a,id,"radiusX");0>a&&t.ka(a,">= zero",id,"radiusX");t.L(this,a);this.ng=a;this.Va=!0});
t.defineProperty(id,{radiusY:"radiusY"},function(){return this.og},function(a){e&&t.q(a,id,"radiusY");0>a&&t.ka(a,">= zero",id,"radiusY");t.L(this,a);this.og=a;this.Va=!0});t.defineProperty(id,{mh:"startAngle"},function(){return this.jb},function(a){this.jb!==a&&(t.L(this,a),e&&t.q(a,id,"startAngle"),a%=360,0>a&&(a+=360),this.jb=a,this.Va=!0)});
t.defineProperty(id,{gi:"sweepAngle"},function(){return this.tb},function(a){e&&t.q(a,id,"sweepAngle");t.L(this,a);360<a&&(a=360);-360>a&&(a=-360);this.tb=a;this.Va=!0});t.defineProperty(id,{Pw:"isClockwiseArc"},function(){return this.yn},function(a){t.L(this,a);this.yn=a;this.Va=!0});t.defineProperty(id,{Sw:"isLargeArc"},function(){return this.jo},function(a){t.L(this,a);this.jo=a;this.Va=!0});
t.defineProperty(id,{Ex:"xAxisRotation"},function(){return this.jd},function(a){e&&t.q(a,id,"xAxisRotation");a%=360;0>a&&(a+=360);t.L(this,a);this.jd=a;this.Va=!0});function ld(){this.U=null;this.Cz=(new w(0,0)).freeze();this.Jy=(new w(0,0)).freeze();this.qu=this.jv=0;this.Yu="";this.Vv=this.Fu=!1;this.Cu=this.su=0;this.oj=this.Nu=!1;this.Mq=null;this.Tv=0;this.pg=this.Rv=null}t.ga("InputEvent",ld);
ld.prototype.copy=function(){var a=new ld;a.U=this.U;a.Cz.assign(this.Ke);a.Jy.assign(this.ea);a.jv=this.jv;a.qu=this.qu;a.Yu=this.Yu;a.Fu=this.Fu;a.Vv=this.Vv;a.su=this.su;a.Cu=this.Cu;a.Nu=this.Nu;a.oj=this.oj;a.Mq=this.Mq;a.Tv=this.Tv;a.Rv=this.Rv;a.pg=this.pg;return a};
ld.prototype.toString=function(){var a="^";this.$c&&(a+="M:"+this.$c);this.button&&(a+="B:"+this.button);this.key&&(a+="K:"+this.key);this.Ae&&(a+="C:"+this.Ae);this.Yj&&(a+="D:"+this.Yj);this.Ee&&(a+="h");this.bubbles&&(a+="b");null!==this.ea&&(a+="@"+this.ea.toString());return a};t.g(ld,"diagram",ld.prototype.h);t.defineProperty(ld,{h:"diagram"},function(){return this.U},function(a){this.U=a});t.g(ld,"viewPoint",ld.prototype.Ke);
t.defineProperty(ld,{Ke:"viewPoint"},function(){return this.Cz},function(a){t.l(a,w,ld,"viewPoint");this.Cz.assign(a)});t.g(ld,"documentPoint",ld.prototype.ea);t.defineProperty(ld,{ea:"documentPoint"},function(){return this.Jy},function(a){t.l(a,w,ld,"documentPoint");this.Jy.assign(a)});t.g(ld,"modifiers",ld.prototype.$c);t.defineProperty(ld,{$c:"modifiers"},function(){return this.jv},function(a){this.jv=a});t.g(ld,"button",ld.prototype.button);
t.defineProperty(ld,{button:"button"},function(){return this.qu},function(a){this.qu=a});t.g(ld,"key",ld.prototype.key);t.defineProperty(ld,{key:"key"},function(){return this.Yu},function(a){this.Yu=a});t.g(ld,"down",ld.prototype.ak);t.defineProperty(ld,{ak:"down"},function(){return this.Fu},function(a){this.Fu=a});t.g(ld,"up",ld.prototype.lj);t.defineProperty(ld,{lj:"up"},function(){return this.Vv},function(a){this.Vv=a});t.g(ld,"clickCount",ld.prototype.Ae);
t.defineProperty(ld,{Ae:"clickCount"},function(){return this.su},function(a){this.su=a});t.g(ld,"delta",ld.prototype.Yj);t.defineProperty(ld,{Yj:"delta"},function(){return this.Cu},function(a){this.Cu=a});t.g(ld,"handled",ld.prototype.Ee);t.defineProperty(ld,{Ee:"handled"},function(){return this.Nu},function(a){this.Nu=a});t.g(ld,"bubbles",ld.prototype.bubbles);t.defineProperty(ld,{bubbles:"bubbles"},function(){return this.oj},function(a){this.oj=a});t.g(ld,"event",ld.prototype.event);
t.defineProperty(ld,{event:"event"},function(){return this.Mq},function(a){this.Mq=a});t.A(ld,{Uw:"isTouchEvent"},function(){var a=window.TouchEvent;return a&&this.event instanceof a});t.g(ld,"timestamp",ld.prototype.timestamp);t.defineProperty(ld,{timestamp:"timestamp"},function(){return this.Tv},function(a){this.Tv=a});t.g(ld,"targetDiagram",ld.prototype.nh);t.defineProperty(ld,{nh:"targetDiagram"},function(){return this.Rv},function(a){this.Rv=a});t.g(ld,"targetObject",ld.prototype.ce);
t.defineProperty(ld,{ce:"targetObject"},function(){return this.pg},function(a){this.pg=a});t.g(ld,"control",ld.prototype.control);t.defineProperty(ld,{control:"control"},function(){return 0!==(this.$c&1)},function(a){this.$c=a?this.$c|1:this.$c&-2});t.g(ld,"shift",ld.prototype.shift);t.defineProperty(ld,{shift:"shift"},function(){return 0!==(this.$c&4)},function(a){this.$c=a?this.$c|4:this.$c&-5});t.g(ld,"alt",ld.prototype.alt);
t.defineProperty(ld,{alt:"alt"},function(){return 0!==(this.$c&2)},function(a){this.$c=a?this.$c|2:this.$c&-3});t.g(ld,"meta",ld.prototype.Bp);t.defineProperty(ld,{Bp:"meta"},function(){return 0!==(this.$c&8)},function(a){this.$c=a?this.$c|8:this.$c&-9});t.g(ld,"left",ld.prototype.left);t.defineProperty(ld,{left:"left"},function(){return 0===this.button},function(a){this.button=a?0:2});t.g(ld,"middle",ld.prototype.pJ);
t.defineProperty(ld,{pJ:"middle"},function(){return 1===this.button},function(a){this.button=a?1:0});t.g(ld,"right",ld.prototype.right);t.defineProperty(ld,{right:"right"},function(){return 2===this.button},function(a){this.button=a?2:0});function rd(){this.U=null;this.Zb="";this.xv=this.Nv=null;this.ru=!1}t.ga("DiagramEvent",rd);rd.prototype.copy=function(){var a=new rd;a.U=this.U;a.Zb=this.Zb;a.Nv=this.Nv;a.xv=this.xv;a.ru=this.ru;return a};
rd.prototype.toString=function(){var a="*"+this.name;this.cancel&&(a+="x");null!==this.Ax&&(a+=":"+this.Ax.toString());null!==this.kx&&(a+="("+this.kx.toString()+")");return a};t.g(rd,"diagram",rd.prototype.h);t.defineProperty(rd,{h:"diagram"},function(){return this.U},function(a){this.U=a});t.g(rd,"name",rd.prototype.name);t.defineProperty(rd,{name:"name"},function(){return this.Zb},function(a){this.Zb=a});t.g(rd,"subject",rd.prototype.Ax);
t.defineProperty(rd,{Ax:"subject"},function(){return this.Nv},function(a){this.Nv=a});t.g(rd,"parameter",rd.prototype.kx);t.defineProperty(rd,{kx:"parameter"},function(){return this.xv},function(a){this.xv=a});t.g(rd,"cancel",rd.prototype.cancel);t.defineProperty(rd,{cancel:"cancel"},function(){return this.ru},function(a){this.ru=a});function td(){this.clear()}t.ga("ChangedEvent",td);var ud;td.Transaction=ud=t.w(td,"Transaction",-1);var vd;td.Property=vd=t.w(td,"Property",0);var wd;
td.Insert=wd=t.w(td,"Insert",1);var xd;td.Remove=xd=t.w(td,"Remove",2);td.prototype.clear=td.prototype.clear=function(){this.sq=vd;this.om=this.iv="";this.mv=this.nv=this.tv=this.to=this.sv=this.U=this.ke=null};
td.prototype.copy=function(){var a=new td;a.ke=this.ke;a.U=this.U;a.sq=this.sq;a.iv=this.iv;a.om=this.om;a.sv=this.sv;var b=this.to;a.to=t.qb(b)&&"function"===typeof b.Z?b.Z():b;b=this.tv;a.tv=t.qb(b)&&"function"===typeof b.Z?b.Z():b;b=this.nv;a.nv=t.qb(b)&&"function"===typeof b.Z?b.Z():b;b=this.mv;a.mv=t.qb(b)&&"function"===typeof b.Z?b.Z():b;return a};
td.prototype.toString=function(){var a="",a=this.td===ud?a+"* ":this.td===vd?a+(null!==this.ba?"!m":"!d"):a+((null!==this.ba?"!m":"!d")+this.td);this.propertyName&&"string"===typeof this.propertyName&&(a+=" "+this.propertyName);this.Af&&this.Af!==this.propertyName&&(a+=" "+this.Af);a+=": ";this.td===ud?null!==this.oldValue&&(a+=" "+this.oldValue):(null!==this.object&&(a+=yd(this.object)),null!==this.oldValue&&(a+="  old: "+yd(this.oldValue)),null!==this.Yf&&(a+=" "+this.Yf),null!==this.newValue&&
(a+="  new: "+yd(this.newValue)),null!==this.Wf&&(a+=" "+this.Wf));return a};td.prototype.getValue=td.prototype.za=function(a){return a?this.oldValue:this.newValue};td.prototype.getParam=function(a){return a?this.Yf:this.Wf};td.prototype.canUndo=td.prototype.canUndo=function(){return null!==this.ba||null!==this.h?!0:!1};td.prototype.undo=td.prototype.undo=function(){this.canUndo()&&(null!==this.ba?this.ba.changeState(this,!0):null!==this.h&&this.h.changeState(this,!0))};
td.prototype.canRedo=td.prototype.canRedo=function(){return null!==this.ba||null!==this.h?!0:!1};td.prototype.redo=td.prototype.redo=function(){this.canRedo()&&(null!==this.ba?this.ba.changeState(this,!1):null!==this.h&&this.h.changeState(this,!1))};t.g(td,"model",td.prototype.ba);t.defineProperty(td,{ba:"model"},function(){return this.ke},function(a){this.ke=a});t.g(td,"diagram",td.prototype.h);t.defineProperty(td,{h:"diagram"},function(){return this.U},function(a){this.U=a});t.g(td,"change",td.prototype.td);
t.defineProperty(td,{td:"change"},function(){return this.sq},function(a){e&&t.Aa(a,td,td,"change");this.sq=a});t.g(td,"modelChange",td.prototype.Af);t.defineProperty(td,{Af:"modelChange"},function(){return this.iv},function(a){e&&t.i(a,"string",td,"modelChange");this.iv=a});t.g(td,"propertyName",td.prototype.propertyName);t.defineProperty(td,{propertyName:"propertyName"},function(){return this.om},function(a){e&&"string"!==typeof a&&t.l(a,Function,td,"propertyName");this.om=a});
t.g(td,"isTransactionFinished",td.prototype.aJ);t.A(td,{aJ:"isTransactionFinished"},function(){return this.sq===ud&&("CommittedTransaction"===this.om||"FinishedUndo"===this.om||"FinishedRedo"===this.om)});t.g(td,"object",td.prototype.object);t.defineProperty(td,{object:"object"},function(){return this.sv},function(a){this.sv=a});t.g(td,"oldValue",td.prototype.oldValue);t.defineProperty(td,{oldValue:"oldValue"},function(){return this.to},function(a){this.to=a});t.g(td,"oldParam",td.prototype.Yf);
t.defineProperty(td,{Yf:"oldParam"},function(){return this.tv},function(a){this.tv=a});t.g(td,"newValue",td.prototype.newValue);t.defineProperty(td,{newValue:"newValue"},function(){return this.nv},function(a){this.nv=a});t.g(td,"newParam",td.prototype.Wf);t.defineProperty(td,{Wf:"newParam"},function(){return this.mv},function(a){this.mv=a});
function E(a){1<arguments.length&&t.m("Model constructor can only take one optional argument, the Array of node data.");t.Ac(this);this.Dy=this.Zb="";this.Jk=this.yC=!1;this.hz={};this.Me=[];this.Bc=new ia(null,Object);this.jm="key";this.wu=this.bv=null;this.vy=this.wy=!1;this.Hr="category";this.xh=new ia(null,ma);this.Bj=null;this.Li=!1;this.Bz=null;this.la=new zd;void 0!==a&&(this.Gg=a)}t.ga("Model",E);
E.prototype.clear=E.prototype.clear=function(){this.Zd&&t.Ym(this.Me,"nodeDataArray",this,!0);this.Me=[];this.Bc.clear();this.xh.clear();this.la.clear()};aa=E.prototype;
aa.$t=function(){var a="";""!==this.name&&(a+=',\n  "name": '+this.quote(this.name));""!==this.Wj&&(a+=',\n  "dataFormat": '+this.quote(this.Wj));this.ab&&(a+=',\n  "isReadOnly": '+this.ab);"key"!==this.ij&&"string"===typeof this.ij&&(a+=',\n  "nodeKeyProperty": '+this.quote(this.ij));this.rw&&(a+=',\n  "copiesArrays": true');this.qw&&(a+=',\n  "copiesArrayObjects": true');"category"!==this.rl&&"string"===typeof this.rl&&(a+=',\n  "nodeCategoryProperty": '+this.quote(this.rl));return a};
aa.rB=function(){var a="",b=this.BA,c=!1,d;for(d in b)if(!Ad(d,b[d])){c=!0;break}c&&(a=',\n  "modelData": '+Bd(this,b));return a+',\n  "nodeDataArray": '+Cd(this,this.Gg,!0)};aa.Ht=function(a){a.name&&(this.name=a.name);a.dataFormat&&(this.Wj=a.dataFormat);a.isReadOnly&&(this.ab=a.isReadOnly);a.nodeKeyProperty&&(this.ij=a.nodeKeyProperty);a.copiesArrays&&(this.rw=a.copiesArrays);a.copiesArrayObjects&&(this.qw=a.copiesArrayObjects);a.nodeCategoryProperty&&(this.rl=a.nodeCategoryProperty)};
aa.PA=function(a){var b=a.modelData;b&&(this.Jt(b),this.BA=b);if(a=a.nodeDataArray)this.Jt(a),this.Gg=a};aa.toString=function(a){void 0===a&&(a=0);if(1<a)return this.iB();var b=(""!==this.name?this.name:"")+" Model";if(0<a){b+="\n node data:";a=this.Gg;var c=t.ub(a),d;for(d=0;d<c;d++)var f=t.lb(a,d),b=b+(" "+this.Hb(f)+":"+yd(f))}return b};
E.prototype.toJson=E.prototype.toJSON=E.prototype.iB=function(a){void 0===a&&(a=this.constructor===E?"go.Model":this.constructor===M?"go.GraphLinksModel":this.constructor===Kd?"go.TreeModel":t.xg(this));return'{ "class": '+this.quote(a)+this.$t()+this.rB()+"}"};
E.fromJson=E.fromJSON=function(a,b){void 0===b&&(b=null);b&&t.l(b,E,E,"fromJson:model");var c=null;if("string"===typeof a)if(window&&window.JSON&&window.JSON.parse)try{c=window.JSON.parse(a)}catch(d){e&&t.trace("JSON.parse error: "+d.toString())}else t.trace("WARNING: no JSON.parse available");else"object"===typeof a?c=a:t.m("Unable to construct a Model from: "+a);if(null===b){var f;f=null;var g=c["class"];if("string"===typeof g)try{var h=null;0===g.indexOf("go.")?(g=g.substr(3),h=ba[g]):(h=ba[g])||
(h=window[g]);"function"===typeof h&&(f=new h)}catch(k){}null===f||f instanceof E?b=f:t.m("Unable to construct a Model of declared class: "+c["class"])}null===b&&(b=new M);b.Ht(c);b.PA(c);return b};
E.prototype.replaceJsonObjects=E.prototype.Jt=function(a){if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++){var d=t.lb(a,c);t.qb(d)&&t.MD(a,c,this.Jt(d))}else if(t.qb(a)){for(c in a)if(d=a[c],t.qb(d)&&(d=this.Jt(d),a[c]=d,"points"===c&&Array.isArray(d))){for(var f=0===d.length%2,g=0;g<d.length;g++)if("number"!==typeof d[g]){f=!1;break}if(f){f=new B(w);for(g=0;g<d.length/2;g++){var h=new w(d[2*g],d[2*g+1]);f.add(h)}f.freeze();a[c]=f}}if("object"===typeof a){c=a;d=a["class"];if("NaN"===d)c=NaN;else if("Date"===
d)c=new Date(a.value);else if("go.Point"===d)c=new w(Ld(a.x),Ld(a.y));else if("go.Size"===d)c=new fa(Ld(a.width),Ld(a.height));else if("go.Rect"===d)c=new x(Ld(a.x),Ld(a.y),Ld(a.width),Ld(a.height));else if("go.Margin"===d)c=new ab(Ld(a.top),Ld(a.right),Ld(a.bottom),Ld(a.left));else if("go.Spot"===d)c="string"===typeof a["enum"]?vb(a["enum"]):new H(Ld(a.x),Ld(a.y),Ld(a.offsetX),Ld(a.offsetY));else if("go.Brush"===d){if(c=new ea,c.type=t.Pm(ea,a.type),a.start instanceof H&&(c.start=a.start),a.end instanceof
H&&(c.end=a.end),"number"===typeof a.startRadius&&(c.Vp=Ld(a.startRadius)),"number"===typeof a.endRadius&&(c.ap=Ld(a.endRadius)),a=a.colorStops)for(b in a)c.addColorStop(parseFloat(b),a[b])}else"go.Geometry"===d&&(c="string"===typeof a.path?Rc(a.path):new zc,c.type=t.Pm(zc,a.type),"number"===typeof a.startX&&(c.qa=Ld(a.startX)),"number"===typeof a.startY&&(c.ra=Ld(a.startY)),"number"===typeof a.endX&&(c.D=Ld(a.endX)),"number"===typeof a.endY&&(c.F=Ld(a.endY)),a.spot1 instanceof H&&(c.G=a.spot1),a.spot2 instanceof
H&&(c.H=a.spot2));a=c}}return a};E.prototype.quote=function(a){for(var b="",c=a.length,d=0;d<c;d++)var f=a[d],b='"'===f||"\\"===f?b+("\\"+f):"\b"===f?b+"\\b":"\f"===f?b+"\\f":"\n"===f?b+"\\n":"\r"===f?b+"\\r":"\t"===f?b+"\\t":b+f;return'"'+b+'"'};
E.prototype.writeJsonValue=E.prototype.au=function(a){return void 0===a?"undefined":null===a?"null":!0===a?"true":!1===a?"false":"string"===typeof a?this.quote(a):"number"===typeof a?Infinity===a?"9e9999":-Infinity===a?"-9e9999":isNaN(a)?'{"class":"NaN"}':a.toString():a instanceof Date?'{"class":"Date", "value":"'+a.toJSON()+'"}':a instanceof Number?this.au(a.valueOf()):t.isArray(a)?Cd(this,a):t.qb(a)?Bd(this,a):"function"===typeof a?"null":a.toString()};
function Cd(a,b,c){var d=t.ub(b),f="[ ";c&&1<d&&(f+="\n");for(var g=0;g<d;g++){var h=t.lb(b,g);void 0!==h&&(0<g&&(f+=",",c&&(f+="\n")),f+=a.au(h))}c&&1<d&&(f+="\n");return f+" ]"}function Ad(a,b){return void 0===b||"__gohashid"===a||"_"===a[0]||"function"===typeof b?!0:!1}function Md(a){return isNaN(a)?"NaN":Infinity===a?"9e9999":-Infinity===a?"-9e9999":a}
function Bd(a,b){var c=b;if(c instanceof w)b={"class":"go.Point",x:Md(c.x),y:Md(c.y)};else if(c instanceof fa)b={"class":"go.Size",width:Md(c.width),height:Md(c.height)};else if(c instanceof x)b={"class":"go.Rect",x:Md(c.x),y:Md(c.y),width:Md(c.width),height:Md(c.height)};else if(c instanceof ab)b={"class":"go.Margin",top:Md(c.top),right:Md(c.right),bottom:Md(c.bottom),left:Md(c.left)};else if(c instanceof H)b=c.xd()?{"class":"go.Spot",x:Md(c.x),y:Md(c.y),offsetX:Md(c.offsetX),offsetY:Md(c.offsetY)}:
{"class":"go.Spot","enum":c.toString()};else if(c instanceof ea){b={"class":"go.Brush",type:c.type.name};if(c.type===Nd)b.color=c.color;else if(c.type===Yd||c.type===Zd)b.start=c.start,b.end=c.end,c.type===Zd&&(0!==c.Vp&&(b.startRadius=Md(c.Vp)),isNaN(c.ap)||(b.endRadius=Md(c.ap)));if(null!==c.Vo){for(var d={},c=c.Vo.k;c.next();)d[c.key.toString()]=c.value;b.colorStops=d}}else if(c instanceof zc)b={"class":"go.Geometry",type:c.type.name},0!==c.qa&&(b.startX=Md(c.qa)),0!==c.ra&&(b.startY=Md(c.ra)),
0!==c.D&&(b.endX=Md(c.D)),0!==c.F&&(b.endY=Md(c.F)),c.G.M(Eb)||(b.spot1=c.G),c.H.M(Pb)||(b.spot2=c.H),c.type===Ac&&(b.path=Pc(c));else if(c instanceof Q||c instanceof z||c instanceof $d||c instanceof E||c instanceof ae||c instanceof oa||c instanceof be||c instanceof ta||c instanceof zd||c instanceof ce)return t.trace("ERROR: trying to convert a GraphObject or Diagram or Model or Tool or Layout or UndoManager into JSON text: "+c.toString()),"{}";var d="{",f=!0,g;for(g in b)if(c=t.ib(b,g),!Ad(g,c))if(f?
f=!1:d+=", ",d+='"'+g+'":',"points"===g&&c instanceof B&&c.da===w){for(var h="[",c=c.k;c.next();){var k=c.value;1<h.length&&(h+=",");h+=a.au(k.x);h+=",";h+=a.au(k.y)}h+="]";d+=h}else d+=a.au(c);return d+"}"}function Ld(a){return"number"===typeof a?a:"NaN"===a?NaN:"9e9999"===a?Infinity:"-9e9999"===a?-Infinity:parseFloat(a)}t.g(E,"name",E.prototype.name);t.defineProperty(E,{name:"name"},function(){return this.Zb},function(a){var b=this.Zb;b!==a&&(t.i(a,"string",E,"name"),this.Zb=a,this.j("name",b,a))});
t.g(E,"dataFormat",E.prototype.Wj);t.defineProperty(E,{Wj:"dataFormat"},function(){return this.Dy},function(a){var b=this.Dy;b!==a&&(t.i(a,"string",E,"dataFormat"),this.Dy=a,this.j("dataFormat",b,a))});t.g(E,"knockoutJS",E.prototype.Zd);t.defineProperty(E,{Zd:"knockoutJS"},function(){return this.yC},function(a){this.yC=a;t.zc||(t.zc="object"===typeof window.ko&&"function"===typeof window.ko.isObservable?window.ko.isObservable:null,a&&null===t.zc&&t.trace("No value for ko.isObservable: is KnockoutJS library not loaded?"))});
t.g(E,"isReadOnly",E.prototype.ab);t.defineProperty(E,{ab:"isReadOnly"},function(){return this.Jk},function(a){var b=this.Jk;b!==a&&(t.i(a,"boolean",E,"isReadOnly"),this.Jk=a,this.j("isReadOnly",b,a))});t.g(E,"modelData",E.prototype.BA);t.defineProperty(E,{BA:"modelData"},function(){return this.hz},function(a){var b=this.hz;b!==a&&(t.i(a,"object",E,"modelData"),this.hz=a,this.j("modelData",b,a))});
E.prototype.addChangedListener=E.prototype.Iz=function(a){t.i(a,"function",E,"addChangedListener:listener");null===this.Bj&&(this.Bj=new B("function"));this.Bj.add(a)};E.prototype.removeChangedListener=E.prototype.TA=function(a){t.i(a,"function",E,"removeChangedListener:listener");null!==this.Bj&&(this.Bj.remove(a),0===this.Bj.count&&(this.Bj=null))};
E.prototype.iw=function(a){this.Wa||this.la.PE(a);if(null!==this.Bj){var b=this.Bj,c=b.length;if(1===c)b=b.wa(0),b(a);else if(0!==c)for(var d=b.Ie(),f=0;f<c;f++)b=d[f],b(a)}};E.prototype.raiseChangedEvent=E.prototype.Ic=function(a,b,c,d,f,g,h){me(this,"",a,b,c,d,f,g,h)};E.prototype.raiseChanged=E.prototype.j=function(a,b,c,d,f){me(this,"",vd,a,this,b,c,d,f)};E.prototype.raiseDataChanged=E.prototype.mx=function(a,b,c,d,f,g){me(this,"",vd,b,a,c,d,f,g)};
function me(a,b,c,d,f,g,h,k,l){void 0===k&&(k=null);void 0===l&&(l=null);var m=new td;m.ba=a;m.td=c;m.Af=b;m.propertyName=d;m.object=f;m.oldValue=g;m.Yf=k;m.newValue=h;m.Wf=l;a.iw(m)}t.g(E,"undoManager",E.prototype.la);t.defineProperty(E,{la:"undoManager"},function(){return this.Bz},function(a){var b=this.Bz;b!==a&&(t.l(a,zd,E,"undoManager"),null!==b&&b.JJ(this),this.Bz=a,null!==a&&a.EH(this))});t.g(E,"skipsUndoManager",E.prototype.Wa);
t.defineProperty(E,{Wa:"skipsUndoManager"},function(){return this.Li},function(a){t.i(a,"boolean",E,"skipsUndoManager");this.Li=a});
E.prototype.changeState=function(a,b){if(null!==a&&a.ba===this)if(a.td===vd){var c=a.object,d=a.propertyName,f=a.za(b);t.Qa(c,d,f)}else a.td===wd?"nodeDataArray"===a.Af?(c=a.newValue,t.qb(c)&&(d=this.Hb(c),void 0!==d&&(b?(this.Zd&&t.yf(c,this,!0),t.Ui(this.Me,a.Wf),this.Bc.remove(d)):(this.Zd&&t.yf(c,this,!1),t.Ti(this.Me,a.Wf,c),this.Bc.add(d,c))))):""===a.Af?(c=a.object,!t.isArray(c)&&a.propertyName&&(c=t.ib(a.object,a.propertyName)),t.isArray(c)&&(d=a.newValue,f=a.Wf,b?t.Ui(c,f):t.Ti(c,f,d))):
t.m("unknown ChangedEvent.Insert object: "+a.toString()):a.td===xd?"nodeDataArray"===a.Af?(c=a.oldValue,t.qb(c)&&(d=this.Hb(c),void 0!==d&&(b?(this.Zd&&t.yf(c,this,!1),t.Ti(this.Me,a.Yf,c),this.Bc.add(d,c)):(this.Zd&&t.yf(c,this,!0),t.Ui(this.Me,a.Yf),this.Bc.remove(d))))):""===a.Af?(c=a.object,!t.isArray(c)&&a.propertyName&&(c=t.ib(a.object,a.propertyName)),t.isArray(c)&&(d=a.oldValue,f=a.Yf,b?t.Ti(c,f,d):t.Ui(c,f))):t.m("unknown ChangedEvent.Remove object: "+a.toString()):a.td!==ud&&t.m("unknown ChangedEvent: "+
a.toString())};E.prototype.startTransaction=E.prototype.uc=function(a){return this.la.uc(a)};E.prototype.commitTransaction=E.prototype.Ce=function(a){return this.la.Ce(a)};E.prototype.rollbackTransaction=E.prototype.Np=function(){return this.la.Np()};E.prototype.updateTargetBindings=E.prototype.Kb=function(a,b){void 0===b&&(b="");me(this,"SourceChanged",ud,b,a,null,null)};t.g(E,"nodeKeyProperty",E.prototype.ij);
t.defineProperty(E,{ij:"nodeKeyProperty"},function(){return this.jm},function(a){var b=this.jm;b!==a&&(ne(a,E,"nodeKeyProperty"),0<this.Bc.count&&t.m("Cannot set Model.nodeKeyProperty when there is existing node data"),this.jm=a,this.j("nodeKeyProperty",b,a))});function ne(a,b,c){"string"!==typeof a&&"function"!==typeof a&&t.bc(a,"string or function",b,c)}
E.prototype.getKeyForNodeData=E.prototype.Hb=function(a){if(null!==a){var b=this.jm;if(""!==b&&(b=t.ib(a,b),void 0!==b)){if(oe(b))return b;t.m("Key value for node data "+a+" is not a number or a string: "+b)}}};
E.prototype.setKeyForNodeData=E.prototype.aB=function(a,b){null===b&&(b=void 0);void 0===b||oe(b)||t.bc(b,"number or string",E,"setKeyForNodeData:key");if(null!==a){var c=this.jm;if(""!==c)if(this.ne(a)){var d=t.ib(a,c);d!==b&&null===this.uf(b)&&(t.Qa(a,c,b),this.Bc.remove(d),this.Bc.add(b,a),me(this,"nodeKey",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c),this.It(d,b))}else t.Qa(a,c,b)}};t.g(E,"makeUniqueKeyFunction",E.prototype.lJ);
t.defineProperty(E,{lJ:"makeUniqueKeyFunction"},function(){return this.bv},function(a){var b=this.bv;b!==a&&(null!==a&&t.i(a,"function",E,"makeUniqueKeyFunction"),this.bv=a,this.j("makeUniqueKeyFunction",b,a))});function oe(a){return"number"===typeof a||"string"===typeof a}E.prototype.containsNodeData=E.prototype.ne=function(a){a=this.Hb(a);return void 0===a?!1:this.Bc.contains(a)};
E.prototype.findNodeDataForKey=E.prototype.uf=function(a){null===a&&t.m("Model.findNodeDataForKey:key must not be null");return void 0!==a&&oe(a)?this.Bc.za(a):null};t.g(E,"nodeDataArray",E.prototype.Gg);
t.defineProperty(E,{Gg:"nodeDataArray"},function(){return this.Me},function(a){var b=this.Me;if(b!==a){t.Rs(a,E,"nodeDataArray");this.Zd&&t.zc&&(null!==b&&t.Ym(b,"nodeDataArray",this,!0),a=t.Ym(a,"nodeDataArray",this,!1));this.Bc.clear();this.mB();for(var c=t.ub(a),d=0;d<c;d++){var f=t.lb(a,d);if(!t.qb(f)){t.m("Model.nodeDataArray must only contain Objects, not: "+f);return}t.jt(f)}this.Me=a;for(var g=new B(Object),d=0;d<c;d++){var f=t.lb(a,d),h=this.Hb(f);void 0===h?g.add(f):null!==this.Bc.za(h)?
g.add(f):this.Bc.add(h,f)}for(d=g.k;d.next();)f=d.value,this.sF(f),g=this.Hb(f),this.Bc.add(g,f);me(this,"nodeDataArray",vd,"nodeDataArray",this,b,a);for(d=0;d<c;d++)f=t.lb(a,d),this.Mp(f),this.Lp(f);this.UD();t.YI(a)||(this.ab=!0)}});
E.prototype.makeNodeDataKeyUnique=E.prototype.sF=function(a){if(null!==a){var b=this.jm;if(""!==b){var c=this.Hb(a);if(void 0===c||this.Bc.contains(c)){var d=this.bv;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.Bc.contains(c))){t.Qa(a,b,c);return}if("string"===typeof c){for(d=2;this.Bc.contains(c+d);)d++;t.Qa(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(d=-this.Bc.count-1;this.Bc.contains(d);)d--;t.Qa(a,b,d)}else t.m("Model.getKeyForNodeData returned something other than a string or a number: "+
c)}}}};E.prototype.addNodeData=E.prototype.Am=function(a){if(null!==a){this.Zd&&t.yf(a,this,!1);t.jt(a);var b=this.Hb(a);if(void 0===b||this.Bc.za(b)!==a)this.sF(a),b=this.Hb(a),this.Bc.add(b,a),b=t.ub(this.Me),t.Ti(this.Me,b,a),me(this,"nodeDataArray",wd,"nodeDataArray",this,null,a,null,b),this.Mp(a),this.Lp(a)}};E.prototype.addNodeDataCollection=function(a){if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++)this.Am(t.lb(a,c));else for(a=a.k;a.next();)this.Am(a.value)};
E.prototype.removeNodeData=E.prototype.nx=function(a){if(null!==a){var b=this.Hb(a);void 0!==b&&this.Bc.contains(b)&&(this.Zd&&t.yf(a,this,!0),this.Bc.remove(b),b=t.Pz(this.Me,a),0>b||(t.Ui(this.Me,b),me(this,"nodeDataArray",xd,"nodeDataArray",this,a,null,b,null),this.Zt(a)))}};E.prototype.removeNodeDataCollection=function(a){if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++)this.nx(t.lb(a,c));else for(a=a.k;a.next();)this.nx(a.value)};aa=E.prototype;
aa.It=function(a,b){var c=pe(this,a);c instanceof ma&&this.xh.add(b,c)};aa.mB=function(){};aa.Mp=function(){};aa.Lp=function(){};aa.Zt=function(){};function qe(a,b,c){if(void 0!==b){var d=a.xh.za(b);null===d&&(d=new ma,a.xh.add(b,d));d.add(c)}}function re(a,b,c){if(void 0!==b){var d=a.xh.za(b);d instanceof ma&&(void 0===c||null===c?a.xh.remove(b):(d.remove(c),0===d.count&&a.xh.remove(b)))}}function pe(a,b){if(void 0===b)return null;var c=a.xh.za(b);return c instanceof ma?c:null}
E.prototype.clearUnresolvedReferences=E.prototype.UD=function(a){void 0===a?this.xh.clear():this.xh.remove(a)};t.g(E,"copyNodeDataFunction",E.prototype.iI);t.defineProperty(E,{iI:"copyNodeDataFunction"},function(){return this.wu},function(a){var b=this.wu;b!==a&&(null!==a&&t.i(a,"function",E,"copyNodeDataFunction"),this.wu=a,this.j("copyNodeDataFunction",b,a))});t.g(E,"copiesArrays",E.prototype.rw);
t.defineProperty(E,{rw:"copiesArrays"},function(){return this.wy},function(a){var b=this.wy;b!==a&&(null!==a&&t.i(a,"boolean",E,"copiesArrays"),this.wy=a,this.j("copiesArrays",b,a))});t.g(E,"copiesArrayObjects",E.prototype.qw);t.defineProperty(E,{qw:"copiesArrayObjects"},function(){return this.vy},function(a){var b=this.vy;b!==a&&(null!==a&&t.i(a,"boolean",E,"copiesArrayObjects"),this.vy=a,this.j("copiesArrayObjects",b,a))});
E.prototype.copyNodeData=function(a){if(null===a)return null;var b=null,b=this.wu;if(null!==b)b=b(a,this);else{var b=new a.constructor,c;for(c in a){var d=t.ib(a,c);if((!e||!ze(a,c,d))&&this.rw&&Array.isArray(d)){for(var f=[],g=0;g<d.length;g++){var h=d[g];if(this.qw&&t.qb(h)){var k=new h.constructor,l;for(l in h){var m=t.ib(h,l);e&&ze(h,l,m);t.Qa(k,l,m)}h=k}f.push(h)}d=f}t.Qa(b,c,d)}this.Zd&&t.yf(b,this,!1)}b&&t.Ac(b);return b};
function ze(a,b,c){return c instanceof Q||c instanceof z||c instanceof $d||c instanceof Ae||c instanceof Be||c instanceof ae||c instanceof oa||c instanceof be||c instanceof ld||c instanceof rd?("_"!==b[0]&&t.trace("Warning: found GraphObject or Diagram reference when copying model data on property '"+b+"' of data object: "+a.toString()+"  \nModel data should not have any references to a Diagram or any part of a diagram, such as: "+c.toString()),!0):c instanceof E||c instanceof zd||c instanceof ce||
c instanceof td?!0:!1}
E.prototype.setDataProperty=function(a,b,c){e&&(t.i(a,"object",E,"setDataProperty:data"),t.i(b,"string",E,"setDataProperty:propname"),""===b&&t.m("Model.setDataProperty: property name must not be an empty string when setting "+a+" to "+c));if(this.ne(a))if(b===this.ij)this.aB(a,c);else{if(b===this.rl){this.sx(a,c);return}}else!t.Zp&&a instanceof Q&&(t.Zp=!0,t.trace('Model.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),t.trace("  Is that really your intent?"));var d=t.ib(a,b);d!==
c&&(t.Qa(a,b,c),this.mx(a,b,d,c))};E.prototype.addArrayItem=function(a,b){this.UI(a,-1,b)};E.prototype.insertArrayItem=E.prototype.UI=function(a,b,c){e&&(t.Rs(a,E,"insertArrayItem:arr"),t.q(b,E,"insertArrayItem:idx"));a===this.Me&&t.m("Model.insertArrayItem or Model.addArrayItem should not be called on the Model.nodeDataArray");0>b&&(b=t.ub(a));t.Ti(a,b,c);me(this,"",wd,"",a,null,c,null,b)};
E.prototype.removeArrayItem=function(a,b){void 0===b&&(b=-1);e&&(t.Rs(a,E,"removeArrayItem:arr"),t.q(b,E,"removeArrayItem:idx"));a===this.Me&&t.m("Model.removeArrayItem should not be called on the Model.nodeDataArray");-1===b&&(b=t.ub(a)-1);var c=t.lb(a,b);t.Ui(a,b);me(this,"",xd,"",a,c,null,b,null)};t.g(E,"nodeCategoryProperty",E.prototype.rl);
t.defineProperty(E,{rl:"nodeCategoryProperty"},function(){return this.Hr},function(a){var b=this.Hr;b!==a&&(ne(a,E,"nodeCategoryProperty"),this.Hr=a,this.j("nodeCategoryProperty",b,a))});E.prototype.getCategoryForNodeData=E.prototype.getCategoryForNodeData=function(a){if(null===a)return"";var b=this.Hr;if(""===b)return"";b=t.ib(a,b);if(void 0===b)return"";if("string"===typeof b)return b;t.m("getCategoryForNodeData found a non-string category for "+a+": "+b);return""};
E.prototype.setCategoryForNodeData=E.prototype.sx=function(a,b){t.i(b,"string",E,"setCategoryForNodeData:cat");if(null!==a){var c=this.Hr;if(""!==c)if(this.ne(a)){var d=t.ib(a,c);void 0===d&&(d="");d!==b&&(t.Qa(a,c,b),me(this,"nodeCategory",vd,c,a,d,b))}else t.Qa(a,c,b)}};
function M(a,b){2<arguments.length&&t.m("GraphLinksModel constructor can only take two optional arguments, the Array of node data and the Array of link data.");E.call(this);this.Xg=[];this.em=new ma(Object);this.xk=this.vu=null;this.xj="from";this.Aj="to";this.pr=this.or="";this.lr="category";this.Jh=this.pv="";this.ov="isGroup";this.Gj="group";this.xy=!1;void 0!==a&&(this.Gg=a);void 0!==b&&(this.hj=b)}t.ga("GraphLinksModel",M);t.Ka(M,E);
M.prototype.clear=M.prototype.clear=function(){E.prototype.clear.call(this);this.Zd&&t.Ym(this.Xg,"linkDataArray",this,!0);this.Xg=[];this.em.clear()};aa=M.prototype;aa.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.iB();var b=(""!==this.name?this.name:"")+" GraphLinksModel";if(0<a){b+="\n node data:";a=this.Gg;var c=t.ub(a),d;for(d=0;d<c;d++)var f=t.lb(a,d),b=b+(" "+this.Hb(f)+":"+yd(f));b+="\n link data:";a=this.hj;c=t.ub(a);for(d=0;d<c;d++)f=t.lb(a,d),b+=" "+this.il(f)+"--\x3e"+this.ml(f)}return b};
aa.$t=function(){var a=E.prototype.$t.call(this),b="";"category"!==this.up&&"string"===typeof this.up&&(b+=',\n  "linkCategoryProperty": '+this.quote(this.up));"from"!==this.Zm&&"string"===typeof this.Zm&&(b+=',\n  "linkFromKeyProperty": '+this.quote(this.Zm));"to"!==this.$m&&"string"===typeof this.$m&&(b+=',\n  "linkToKeyProperty": '+this.quote(this.$m));""!==this.vp&&"string"===typeof this.vp&&(b+=',\n  "linkFromPortIdProperty": '+this.quote(this.vp));""!==this.zp&&"string"===typeof this.zp&&(b+=
',\n  "linkToPortIdProperty": '+this.quote(this.zp));""!==this.Ct&&"string"===typeof this.Ct&&(b+=',\n  "nodeIsLinkLabelProperty": '+this.quote(this.Ct));""!==this.wp&&"string"===typeof this.wp&&(b+=',\n  "linkLabelKeysProperty": '+this.quote(this.wp));"isGroup"!==this.Ip&&"string"===typeof this.Ip&&(b+=',\n  "nodeIsGroupProperty": '+this.quote(this.Ip));"group"!==this.en&&"string"===typeof this.en&&(b+=',\n  "nodeGroupKeyProperty": '+this.quote(this.en));return a+b};
aa.rB=function(){var a=E.prototype.rB.call(this),b=',\n  "linkDataArray": '+Cd(this,this.hj,!0);return a+b};
aa.Ht=function(a){E.prototype.Ht.call(this,a);a.linkFromKeyProperty&&(this.Zm=a.linkFromKeyProperty);a.linkToKeyProperty&&(this.$m=a.linkToKeyProperty);a.linkFromPortIdProperty&&(this.vp=a.linkFromPortIdProperty);a.linkToPortIdProperty&&(this.zp=a.linkToPortIdProperty);a.linkCategoryProperty&&(this.up=a.linkCategoryProperty);a.nodeIsLinkLabelProperty&&(this.Ct=a.nodeIsLinkLabelProperty);a.linkLabelKeysProperty&&(this.wp=a.linkLabelKeysProperty);a.nodeIsGroupProperty&&(this.Ip=a.nodeIsGroupProperty);
a.nodeGroupKeyProperty&&(this.en=a.nodeGroupKeyProperty)};aa.PA=function(a){E.prototype.PA.call(this,a);if(a=a.linkDataArray)this.Jt(a),this.hj=a};
M.prototype.changeState=function(a,b){if(a.td===wd){var c=null;"linkDataArray"===a.Af?c=this.Xg:"linkLabelKeys"===a.Af&&(c=this.kl(a.object));if(t.isArray(c)){b?(this.Zd&&t.qb(a.newValue)&&t.yf(a.newValue,this,!0),t.Ui(c,a.Wf)):(this.Zd&&t.qb(a.newValue)&&t.yf(a.newValue,this,!1),t.Ti(c,a.Wf,a.newValue));return}}else if(a.td===xd&&(c=null,"linkDataArray"===a.Af?c=this.Xg:"linkLabelKeys"===a.Af&&(c=this.kl(a.object)),t.isArray(c))){b?(this.Zd&&t.qb(a.oldValue)&&t.yf(a.oldValue,this,!1),t.Ti(c,a.Yf,
a.oldValue)):(this.Zd&&t.qb(a.oldValue)&&t.yf(a.oldValue,this,!0),t.Ui(c,a.Yf));return}E.prototype.changeState.call(this,a,b)};t.g(M,"archetypeNodeData",M.prototype.Os);t.defineProperty(M,{Os:"archetypeNodeData"},function(){return this.xk},function(a){var b=this.xk;b!==a&&(null!==a&&t.l(a,Object,M,"archetypeNodeData"),this.xk=a,this.j("archetypeNodeData",b,a))});
M.prototype.bn=function(a){if(void 0!==a){var b=this.xk;if(null!==b){var c=this.uf(a);null===c&&(c=this.copyNodeData(b),t.Qa(c,this.jm,a),this.Am(c))}return a}};t.g(M,"linkFromKeyProperty",M.prototype.Zm);t.defineProperty(M,{Zm:"linkFromKeyProperty"},function(){return this.xj},function(a){var b=this.xj;b!==a&&(ne(a,M,"linkFromKeyProperty"),this.xj=a,this.j("linkFromKeyProperty",b,a))});
M.prototype.getFromKeyForLinkData=M.prototype.il=function(a){if(null!==a){var b=this.xj;if(""!==b&&(b=t.ib(a,b),void 0!==b)){if(oe(b))return b;t.m("FromKey value for link data "+a+" is not a number or a string: "+b)}}};
M.prototype.setFromKeyForLinkData=M.prototype.ux=function(a,b){null===b&&(b=void 0);void 0===b||oe(b)||t.bc(b,"number or string",M,"setFromKeyForLinkData:key");if(null!==a){var c=this.xj;if(""!==c)if(b=this.bn(b),this.Yi(a)){var d=t.ib(a,c);d!==b&&(re(this,d,a),t.Qa(a,c,b),null===this.uf(b)&&qe(this,b,a),me(this,"linkFromKey",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};t.g(M,"linkToKeyProperty",M.prototype.$m);
t.defineProperty(M,{$m:"linkToKeyProperty"},function(){return this.Aj},function(a){var b=this.Aj;b!==a&&(ne(a,M,"linkToKeyProperty"),this.Aj=a,this.j("linkToKeyProperty",b,a))});M.prototype.getToKeyForLinkData=M.prototype.ml=function(a){if(null!==a){var b=this.Aj;if(""!==b&&(b=t.ib(a,b),void 0!==b)){if(oe(b))return b;t.m("ToKey value for link data "+a+" is not a number or a string: "+b)}}};
M.prototype.setToKeyForLinkData=M.prototype.wx=function(a,b){null===b&&(b=void 0);void 0===b||oe(b)||t.bc(b,"number or string",M,"setToKeyForLinkData:key");if(null!==a){var c=this.Aj;if(""!==c)if(b=this.bn(b),this.Yi(a)){var d=t.ib(a,c);d!==b&&(re(this,d,a),t.Qa(a,c,b),null===this.uf(b)&&qe(this,b,a),me(this,"linkToKey",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};t.g(M,"linkFromPortIdProperty",M.prototype.vp);
t.defineProperty(M,{vp:"linkFromPortIdProperty"},function(){return this.or},function(a){var b=this.or;b!==a&&(ne(a,M,"linkFromPortIdProperty"),this.or=a,this.j("linkFromPortIdProperty",b,a))});M.prototype.getFromPortIdForLinkData=M.prototype.HI=function(a){if(null===a)return"";var b=this.or;if(""===b)return"";a=t.ib(a,b);return void 0===a?"":a};
M.prototype.setFromPortIdForLinkData=M.prototype.$A=function(a,b){t.i(b,"string",M,"setFromPortIdForLinkData:portname");if(null!==a){var c=this.or;if(""!==c)if(this.Yi(a)){var d=t.ib(a,c);void 0===d&&(d="");d!==b&&(t.Qa(a,c,b),me(this,"linkFromPortId",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};t.g(M,"linkToPortIdProperty",M.prototype.zp);
t.defineProperty(M,{zp:"linkToPortIdProperty"},function(){return this.pr},function(a){var b=this.pr;b!==a&&(ne(a,M,"linkToPortIdProperty"),this.pr=a,this.j("linkToPortIdProperty",b,a))});M.prototype.getToPortIdForLinkData=M.prototype.KI=function(a){if(null===a)return"";var b=this.pr;if(""===b)return"";a=t.ib(a,b);return void 0===a?"":a};
M.prototype.setToPortIdForLinkData=M.prototype.cB=function(a,b){t.i(b,"string",M,"setToPortIdForLinkData:portname");if(null!==a){var c=this.pr;if(""!==c)if(this.Yi(a)){var d=t.ib(a,c);void 0===d&&(d="");d!==b&&(t.Qa(a,c,b),me(this,"linkToPortId",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};t.g(M,"nodeIsLinkLabelProperty",M.prototype.Ct);
t.defineProperty(M,{Ct:"nodeIsLinkLabelProperty"},function(){return this.pv},function(a){var b=this.pv;b!==a&&(ne(a,M,"nodeIsLinkLabelProperty"),this.pv=a,this.j("nodeIsLinkLabelProperty",b,a))});M.prototype.isLinkLabelForNodeData=M.prototype.XI=function(a){if(null===a)return!1;var b=this.pv;return""===b?!1:t.ib(a,b)?!0:!1};t.g(M,"linkLabelKeysProperty",M.prototype.wp);
t.defineProperty(M,{wp:"linkLabelKeysProperty"},function(){return this.Jh},function(a){var b=this.Jh;b!==a&&(ne(a,M,"linkLabelKeysProperty"),this.Jh=a,this.j("linkLabelKeysProperty",b,a))});M.prototype.getLabelKeysForLinkData=M.prototype.kl=function(a){if(null===a)return t.ji;var b=this.Jh;if(""===b)return t.ji;a=t.ib(a,b);return void 0===a?t.ji:a};
M.prototype.setLabelKeysForLinkData=M.prototype.mG=function(a,b){t.Rs(b,M,"setLabelKeysForLinkData:arr");if(null!==a){var c=this.Jh;if(""!==c)if(this.Yi(a)){var d=t.ib(a,c);void 0===d&&(d=t.ji);if(d!==b){for(var f=t.ub(d),g=0;g<f;g++){var h=t.lb(d,g);re(this,h,a)}t.Qa(a,c,b);f=t.ub(b);for(g=0;g<f;g++)h=t.lb(b,g),null===this.uf(h)&&qe(this,h,a);me(this,"linkLabelKeys",vd,c,a,d,b);"string"===typeof c&&this.Kb(a,c)}}else t.Qa(a,c,b)}};
M.prototype.addLabelKeyForLinkData=M.prototype.GD=function(a,b){if(null!==b&&void 0!==b&&(oe(b)||t.bc(b,"number or string",M,"addLabelKeyForLinkData:key"),null!==a)){var c=this.Jh;if(""!==c){var d=t.ib(a,c);void 0===d?this.mG(a,[b]):t.isArray(d)?0<=t.Pz(d,b)||(t.Ti(d,Infinity,b),this.Yi(a)&&(null===this.uf(b)&&qe(this,b,a),me(this,"linkLabelKeys",wd,c,a,null,b))):t.m(c+" property is not an Array; cannot addLabelKeyForLinkData: "+a)}}};
M.prototype.removeLabelKeyForLinkData=M.prototype.IJ=function(a,b){if(null!==b&&void 0!==b&&(oe(b)||t.bc(b,"number or string",M,"removeLabelKeyForLinkData:key"),null!==a)){var c=this.Jh;if(""!==c){var d=t.ib(a,c);if(t.isArray(d)){var f=t.Pz(d,b);0>f||(t.Ui(d,f),this.Yi(a)&&(re(this,b,a),me(this,"linkLabelKeys",xd,c,a,b,null)))}else void 0!==d&&t.m(c+" property is not an Array; cannot removeLabelKeyforLinkData: "+a)}}};t.g(M,"linkDataArray",M.prototype.hj);
t.defineProperty(M,{hj:"linkDataArray"},function(){return this.Xg},function(a){var b=this.Xg;if(b!==a){t.Rs(a,M,"linkDataArray");this.Zd&&t.zc&&(null!==b&&t.Ym(b,"linkDataArray",this,!0),a=t.Ym(a,"linkDataArray",this,!1));for(var c=t.ub(a),d=0;d<c;d++){var f=t.lb(a,d);if(!t.qb(f)){t.m("GraphLinksModel.linkDataArray must only contain Objects, not: "+f);return}t.jt(f)}this.Xg=a;for(var g=new ma(Object),d=0;d<c;d++)f=t.lb(a,d),g.add(f);this.em=g;me(this,"linkDataArray",vd,"linkDataArray",this,b,a);for(d=
0;d<c;d++)f=t.lb(a,d),Ce(this,f)}});M.prototype.containsLinkData=M.prototype.Yi=function(a){return null===a?!1:this.em.contains(a)};M.prototype.addLinkData=M.prototype.aw=function(a){if(null!==a){this.Zd&&t.yf(a,this,!1);if(void 0===t.od(a))t.Ac(a);else if(this.Yi(a))return;this.em.add(a);var b=t.ub(this.Xg);t.Ti(this.Xg,b,a);me(this,"linkDataArray",wd,"linkDataArray",this,null,a,null,b);Ce(this,a)}};
M.prototype.addLinkDataCollection=function(a){if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++)this.aw(t.lb(a,c));else for(a=a.k;a.next();)this.aw(a.value)};
M.prototype.removeLinkData=M.prototype.UA=function(a){if(null!==a){this.Zd&&t.yf(a,this,!0);this.em.remove(a);var b=this.Xg.indexOf(a);if(!(0>b)){t.Ui(this.Xg,b);me(this,"linkDataArray",xd,"linkDataArray",this,a,null,b,null);b=this.il(a);re(this,b,a);b=this.ml(a);re(this,b,a);var c=this.kl(a);if(t.isArray(c))for(var d=t.ub(c),f=0;f<d;f++)b=t.lb(c,f),re(this,b,a)}}};M.prototype.removeLinkDataCollection=function(a){if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++)this.UA(t.lb(a,c));else for(a=a.k;a.next();)this.UA(a.value)};
function Ce(a,b){var c=a.il(b),c=a.bn(c);null===a.uf(c)&&qe(a,c,b);c=a.ml(b);c=a.bn(c);null===a.uf(c)&&qe(a,c,b);var d=a.kl(b);if(t.isArray(d))for(var f=t.ub(d),g=0;g<f;g++)c=t.lb(d,g),null===a.uf(c)&&qe(a,c,b)}t.g(M,"copyLinkDataFunction",M.prototype.hI);t.defineProperty(M,{hI:"copyLinkDataFunction"},function(){return this.vu},function(a){var b=this.vu;b!==a&&(null!==a&&t.i(a,"function",M,"copyLinkDataFunction"),this.vu=a,this.j("copyLinkDataFunction",b,a))});
M.prototype.copyLinkData=M.prototype.eE=function(a){if(null===a)return null;var b=null,b=this.vu;if(null!==b)b=b(a,this);else{var b=new a.constructor,c;for(c in a){var d=t.ib(a,c);e&&ze(a,c,d);t.Qa(b,c,d)}this.Zd&&t.yf(b,this,!1)}b&&(t.Ac(b),""!==this.xj&&t.Qa(b,this.xj,void 0),""!==this.Aj&&t.Qa(b,this.Aj,void 0),""!==this.Jh&&t.Qa(b,this.Jh,[]));return b};t.g(M,"nodeIsGroupProperty",M.prototype.Ip);
t.defineProperty(M,{Ip:"nodeIsGroupProperty"},function(){return this.ov},function(a){var b=this.ov;b!==a&&(ne(a,M,"nodeIsGroupProperty"),this.ov=a,this.j("nodeIsGroupProperty",b,a))});M.prototype.isGroupForNodeData=M.prototype.sA=function(a){if(null===a)return!1;var b=this.ov;return""===b?!1:t.ib(a,b)?!0:!1};t.g(M,"nodeGroupKeyProperty",M.prototype.en);
t.defineProperty(M,{en:"nodeGroupKeyProperty"},function(){return this.Gj},function(a){var b=this.Gj;b!==a&&(ne(a,M,"nodeGroupKeyProperty"),this.Gj=a,this.j("nodeGroupKeyProperty",b,a))});t.g(M,"copiesGroupKeyOfNodeData",M.prototype.bl);t.defineProperty(M,{bl:"copiesGroupKeyOfNodeData"},function(){return this.xy},function(a){this.xy!==a&&(t.i(a,"boolean",M,"copiesGroupKeyOfNodeData"),this.xy=a)});
M.prototype.getGroupKeyForNodeData=M.prototype.Sm=function(a){if(null!==a){var b=this.Gj;if(""!==b&&(b=t.ib(a,b),void 0!==b)){if(oe(b))return b;t.m("GroupKey value for node data "+a+" is not a number or a string: "+b)}}};
M.prototype.setGroupKeyForNodeData=M.prototype.vx=function(a,b){null===b&&(b=void 0);void 0===b||oe(b)||t.bc(b,"number or string",M,"setGroupKeyForNodeData:key");if(null!==a){var c=this.Gj;if(""!==c)if(this.ne(a)){var d=t.ib(a,c);d!==b&&(re(this,d,a),t.Qa(a,c,b),null===this.uf(b)&&qe(this,b,a),me(this,"nodeGroupKey",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};
M.prototype.copyNodeData=function(a){if(null===a)return null;a=E.prototype.copyNodeData.call(this,a);this.bl||""===this.Gj||t.Qa(a,this.Gj,void 0);return a};
M.prototype.setDataProperty=function(a,b,c){e&&(t.i(a,"object",M,"setDataProperty:data"),t.i(b,"string",M,"setDataProperty:propname"),""===b&&t.m("GraphLinksModel.setDataProperty: property name must not be an empty string when setting "+a+" to "+c));if(this.ne(a))if(b===this.ij)this.aB(a,c);else{if(b===this.rl){this.sx(a,c);return}if(b===this.en){this.vx(a,c);return}b===this.Ip&&t.m("GraphLinksModel.setDataProperty: property name must not be the nodeIsGroupProperty: "+b)}else if(this.Yi(a)){if(b===
this.Zm){this.ux(a,c);return}if(b===this.$m){this.wx(a,c);return}if(b===this.vp){this.$A(a,c);return}if(b===this.zp){this.cB(a,c);return}if(b===this.up){this.lG(a,c);return}if(b===this.wp){this.mG(a,c);return}}else!t.Zp&&a instanceof Q&&(t.Zp=!0,t.trace('GraphLinksModel.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),t.trace("  Is that really your intent?"));var d=t.ib(a,b);d!==c&&(t.Qa(a,b,c),this.mx(a,b,d,c))};aa=M.prototype;
aa.It=function(a,b){E.prototype.It.call(this,a,b);for(var c=this.Bc.k;c.next();)this.WA(c.value,a,b);for(c=this.em.k;c.next();){var d=c.value,f=a,g=b;if(this.il(d)===f){var h=this.xj;t.Qa(d,h,g);me(this,"linkFromKey",vd,h,d,f,g);"string"===typeof h&&this.Kb(d,h)}this.ml(d)===f&&(h=this.Aj,t.Qa(d,h,g),me(this,"linkToKey",vd,h,d,f,g),"string"===typeof h&&this.Kb(d,h));var k=this.kl(d);if(t.isArray(k))for(var l=t.ub(k),h=this.Jh,m=0;m<l;m++)t.lb(k,m)===f&&(t.MD(k,m,g),me(this,"linkLabelKeys",wd,h,d,
f,g))}};aa.WA=function(a,b,c){if(this.Sm(a)===b){var d=this.Gj;t.Qa(a,d,c);me(this,"nodeGroupKey",vd,d,a,b,c);"string"===typeof d&&this.Kb(a,d)}};aa.mB=function(){E.prototype.mB.call(this);for(var a=this.hj,b=t.ub(a),c=0;c<b;c++){var d=t.lb(a,c);Ce(this,d)}};
aa.Mp=function(a){E.prototype.Mp.call(this,a);a=this.Hb(a);var b=pe(this,a);if(null!==b){for(var c=new B(Object),b=b.k;b.next();){var d=b.value;if(this.ne(d)){if(this.Sm(d)===a){var f=this.Gj;me(this,"nodeGroupKey",vd,f,d,a,a);"string"===typeof f&&this.Kb(d,f);c.add(d)}}else{this.il(d)===a&&(f=this.xj,me(this,"linkFromKey",vd,f,d,a,a),"string"===typeof f&&this.Kb(d,f),c.add(d));this.ml(d)===a&&(f=this.Aj,me(this,"linkToKey",vd,f,d,a,a),"string"===typeof f&&this.Kb(d,f),c.add(d));var g=this.kl(d);
if(t.isArray(g))for(var h=t.ub(g),f=this.Jh,k=0;k<h;k++)t.lb(g,k)===a&&(me(this,"linkLabelKeys",wd,f,d,a,a),c.add(d))}}for(c=c.k;c.next();)re(this,a,c.value)}};aa.Lp=function(a){E.prototype.Lp.call(this,a);var b=this.Sm(a);null===this.uf(b)&&qe(this,b,a)};aa.Zt=function(a){E.prototype.Zt.call(this,a);var b=this.Sm(a);re(this,b,a)};t.g(M,"linkCategoryProperty",M.prototype.up);
t.defineProperty(M,{up:"linkCategoryProperty"},function(){return this.lr},function(a){var b=this.lr;b!==a&&(ne(a,M,"linkCategoryProperty"),this.lr=a,this.j("linkCategoryProperty",b,a))});M.prototype.getCategoryForLinkData=M.prototype.getCategoryForLinkData=function(a){if(null===a)return"";var b=this.lr;if(""===b)return"";b=t.ib(a,b);if(void 0===b)return"";if("string"===typeof b)return b;t.m("getCategoryForLinkData found a non-string category for "+a+": "+b);return""};
M.prototype.setCategoryForLinkData=M.prototype.lG=function(a,b){t.i(b,"string",M,"setCategoryForLinkData:cat");if(null!==a){var c=this.lr;if(""===c)return"";if(this.Yi(a)){var d=t.ib(a,c);void 0===d&&(d="");d!==b&&(t.Qa(a,c,b),me(this,"linkCategory",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};
function Kd(a){1<arguments.length&&t.m("TreeModel constructor can only take one optional argument, the Array of node data.");E.call(this);this.Hj="parent";this.yy=!1;this.Lr="parentLinkCategory";void 0!==a&&(this.Gg=a)}t.ga("TreeModel",Kd);t.Ka(Kd,E);Kd.prototype.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.iB();var b=(""!==this.name?this.name:"")+" TreeModel";if(0<a){b+="\n node data:";a=this.Gg;var c=t.ub(a),d;for(d=0;d<c;d++)var f=t.lb(a,d),b=b+(" "+this.Hb(f)+":"+yd(f))}return b};
Kd.prototype.$t=function(){var a=E.prototype.$t.call(this),b="";"parent"!==this.fn&&"string"===typeof this.fn&&(b+=',\n  "nodeParentKeyProperty": '+this.quote(this.fn));return a+b};Kd.prototype.Ht=function(a){E.prototype.Ht.call(this,a);a.nodeParentKeyProperty&&(this.fn=a.nodeParentKeyProperty)};Kd.prototype.bn=function(a){return a};t.g(Kd,"nodeParentKeyProperty",Kd.prototype.fn);
t.defineProperty(Kd,{fn:"nodeParentKeyProperty"},function(){return this.Hj},function(a){var b=this.Hj;b!==a&&(ne(a,Kd,"nodeParentKeyProperty"),this.Hj=a,this.j("nodeParentKeyProperty",b,a))});t.g(Kd,"copiesParentKeyOfNodeData",Kd.prototype.cl);t.defineProperty(Kd,{cl:"copiesParentKeyOfNodeData"},function(){return this.yy},function(a){this.yy!==a&&(t.i(a,"boolean",Kd,"copiesParentKeyOfNodeData"),this.yy=a)});
Kd.prototype.getParentKeyForNodeData=Kd.prototype.Tm=function(a){if(null!==a){var b=this.Hj;if(""!==b&&(b=t.ib(a,b),void 0!==b)){if(oe(b))return b;t.m("ParentKey value for node data "+a+" is not a number or a string: "+b)}}};
Kd.prototype.setParentKeyForNodeData=Kd.prototype.lh=function(a,b){null===b&&(b=void 0);void 0===b||oe(b)||t.bc(b,"number or string",Kd,"setParentKeyForNodeData:key");if(null!==a){var c=this.Hj;if(""!==c)if(b=this.bn(b),this.ne(a)){var d=t.ib(a,c);d!==b&&(re(this,d,a),t.Qa(a,c,b),null===this.uf(b)&&qe(this,b,a),me(this,"nodeParentKey",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};t.g(Kd,"parentLinkCategoryProperty",Kd.prototype.CJ);
t.defineProperty(Kd,{CJ:"parentLinkCategoryProperty"},function(){return this.Lr},function(a){var b=this.Lr;b!==a&&(ne(a,Kd,"parentLinkCategoryProperty"),this.Lr=a,this.j("parentLinkCategoryProperty",b,a))});Kd.prototype.getParentLinkCategoryForNodeData=Kd.prototype.JI=function(a){if(null===a)return"";var b=this.Lr;if(""===b)return"";b=t.ib(a,b);if(void 0===b)return"";if("string"===typeof b)return b;t.m("getParentLinkCategoryForNodeData found a non-string category for "+a+": "+b);return""};
Kd.prototype.setParentLinkCategoryForNodeData=Kd.prototype.OJ=function(a,b){t.i(b,"string",Kd,"setParentLinkCategoryForNodeData:cat");if(null!==a){var c=this.Lr;if(""===c)return"";if(this.ne(a)){var d=t.ib(a,c);void 0===d&&(d="");d!==b&&(t.Qa(a,c,b),me(this,"parentLinkCategory",vd,c,a,d,b),"string"===typeof c&&this.Kb(a,c))}else t.Qa(a,c,b)}};Kd.prototype.copyNodeData=function(a){if(null===a)return null;a=E.prototype.copyNodeData.call(this,a);this.cl||""===this.Hj||t.Qa(a,this.Hj,void 0);return a};
Kd.prototype.setDataProperty=function(a,b,c){e&&(t.i(a,"object",Kd,"setDataProperty:data"),t.i(b,"string",Kd,"setDataProperty:propname"),""===b&&t.m("TreeModel.setDataProperty: property name must not be an empty string when setting "+a+" to "+c));if(this.ne(a))if(b===this.ij)this.aB(a,c);else{if(b===this.rl){this.sx(a,c);return}if(b===this.fn){this.lh(a,c);return}}else!t.Zp&&a instanceof Q&&(t.Zp=!0,t.trace('TreeModel.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),t.trace("  Is that really your intent?"));
var d=t.ib(a,b);d!==c&&(t.Qa(a,b,c),this.mx(a,b,d,c))};aa=Kd.prototype;aa.It=function(a,b){E.prototype.It.call(this,a,b);for(var c=this.Bc.k;c.next();)this.WA(c.value,a,b)};aa.WA=function(a,b,c){if(this.Tm(a)===b){var d=this.Hj;t.Qa(a,d,c);me(this,"nodeParentKey",vd,d,a,b,c);"string"===typeof d&&this.Kb(a,d)}};
aa.Mp=function(a){E.prototype.Mp.call(this,a);a=this.Hb(a);var b=pe(this,a);if(null!==b){for(var c=new B(Object),b=b.k;b.next();){var d=b.value;if(this.ne(d)&&this.Tm(d)===a){var f=this.Hj;me(this,"nodeParentKey",vd,f,d,a,a);"string"===typeof f&&this.Kb(d,f);c.add(d)}}for(c=c.k;c.next();)re(this,a,c.value)}};aa.Lp=function(a){E.prototype.Lp.call(this,a);var b=this.Tm(a),b=this.bn(b);null===this.uf(b)&&qe(this,b,a)};aa.Zt=function(a){E.prototype.Zt.call(this,a);var b=this.Tm(a);re(this,b,a)};
function De(a,b,c){t.Ac(this);void 0===a?a="":t.i(a,"string",De,"constructor:targetprop");void 0===b?b=a:t.i(b,"string",De,"constructor:sourceprop");void 0===c?c=null:null!==c&&t.i(c,"function",De,"constructor:conv");this.kD="";this.pg=null;this.Sv=a;this.Qv=this.xz=0;this.fD=null;this.Kv=b;this.uy=c;this.gz=Ee;this.my=null}t.ga("Binding",De);var Ee;De.OneWay=Ee=t.w(De,"OneWay",1);var Fe;De.TwoWay=Fe=t.w(De,"TwoWay",2);
De.parseEnum=function(a,b){t.i(a,"function",De,"parseEnum:ctor");t.Aa(b,a,De,"parseEnum:defval");return function(c){return t.Pm(a,c)||b}};var yd;De.toString=yd=function(a){var b=a;t.qb(a)&&(a.text?b=a.text:a.name?b=a.name:void 0!==a.key?b=a.key:void 0!==a.id?b=a.id:a.constructor===Object&&(a.Text?b=a.Text:a.Name?b=a.Name:void 0!==a.Key?b=a.Key:void 0!==a.Id?b=a.Id:void 0!==a.ID&&(b=a.ID)));return void 0===b?"undefined":null===b?"null":b.toString()};
De.prototype.toString=function(){return"Binding("+this.nn+" "+this.Wt+":"+this.eB+")"};De.prototype.freeze=function(){this.nb=!0;return this};De.prototype.Ma=function(){this.nb=!1;return this};t.g(De,"targetId",De.prototype.nn);t.defineProperty(De,{nn:null},function(){return this.kD},function(a){t.L(this);t.i(a,"string",De,"targetId");this.kD=a});t.g(De,"targetProperty",De.prototype.Wt);
t.defineProperty(De,{Wt:"targetProperty"},function(){return this.Sv},function(a){t.L(this);t.i(a,"string",De,"targetProperty");this.Sv=a});t.g(De,"sourceName",De.prototype.Ut);t.defineProperty(De,{Ut:"sourceName"},function(){return this.fD},function(a){t.L(this);t.i(a,"string",De,"sourceName");this.fD=a});t.g(De,"sourceProperty",De.prototype.eB);t.defineProperty(De,{eB:"sourceProperty"},function(){return this.Kv},function(a){t.L(this);t.i(a,"string",De,"sourceProperty");this.Kv=a});
t.g(De,"converter",De.prototype.bE);t.defineProperty(De,{bE:"converter"},function(){return this.uy},function(a){t.L(this);null!==a&&t.i(a,"function",De,"converter");this.uy=a});t.g(De,"backConverter",De.prototype.QD);t.defineProperty(De,{QD:"backConverter"},function(){return this.my},function(a){t.L(this);null!==a&&t.i(a,"function",De,"backConverter");this.my=a});t.g(De,"mode",De.prototype.mode);
t.defineProperty(De,{mode:"mode"},function(){return this.gz},function(a){t.L(this);t.Aa(a,De,De,"mode");this.gz=a});De.prototype.makeTwoWay=function(a){void 0===a&&(a=null);null!==a&&t.i(a,"function",De,"makeTwoWay");this.mode=Fe;this.QD=a;return this};De.prototype.ofObject=De.prototype.JA=function(a){void 0===a&&(a="");e&&t.i(a,"string",De,"ofObject:srcname");this.Ut=a;return this};
De.prototype.updateTarget=De.prototype.PG=function(a,b,c){var d=this.Kv;if(!c||d===c){c=this.Sv;var f=this.uy;if(null===f&&""===c)t.trace("Binding error: target property is the empty string: "+this.toString());else{e&&"string"===typeof c&&("function"!==typeof a.setAttribute&&0<c.length&&"_"!==c[0]&&!t.Jw(a,c)?t.trace("Binding error: undefined target property: "+c+" on "+a.toString()):"name"===c&&a instanceof Q&&t.trace("Binding error: cannot modify GraphObject.name on "+a.toString()));var g=b;""!==
d&&(g=t.ib(b,d));if(void 0!==g)if(null===f)""!==c&&t.Qa(a,c,g);else try{if(""!==c){var h=f(g,a);e&&void 0===h&&t.trace('Binding warning: conversion function returned undefined when setting target property "'+c+'" on '+a.toString()+", function is: "+f);t.Qa(a,c,h)}else f(g,a)}catch(k){e&&t.trace("Binding error: "+k.toString()+' setting target property "'+c+'" on '+a.toString()+" with conversion function: "+f)}}}};
De.prototype.updateSource=De.prototype.nB=function(a,b,c,d){if(this.gz===Fe){var f=this.Sv;if(!c||f===c){c=this.Kv;var g=this.my;if(null!==g||""!==c){var h=a;""!==f&&(h=t.ib(a,f));if(void 0!==h)if(null===g)d&&d.ba?(e&&d.ba.ij===c&&d.ba.ne(b)&&t.trace("Binding error: cannot have TwoWay Binding on node data key property: "+this.toString()),d.ba.setDataProperty(b,c,h)):t.Qa(b,c,h);else try{if(""!==c){var k=g(h,b);d&&d.ba?(e&&(d.ba.ij===c&&d.ba.ne(b)&&t.trace("Binding error: cannot have TwoWay Binding on node data key property: "+
this.toString()),void 0===k&&t.trace('Binding warning: conversion function returned undefined when setting source property "'+c+'" on '+b.toString()+", function is: "+g)),d.ba.setDataProperty(b,c,k)):t.Qa(b,c,k)}else g(h,b)}catch(l){e&&t.trace("Binding error: "+l.toString()+' setting source property "'+c+'" on '+b.toString()+" with conversion function: "+g)}}}}};function ce(){this.iH=(new B(td)).freeze();this.Zb="";this.nC=!1}t.ga("Transaction",ce);
ce.prototype.toString=function(a){var b="Transaction: "+this.name+" "+this.dh.count.toString()+(this.op?"":", incomplete");if(void 0!==a&&0<a)for(var c=this.dh.count,d=0;d<c;d++){var f=this.dh.wa(d);null!==f&&(b+="\n  "+f.toString(a-1))}return b};ce.prototype.clear=ce.prototype.clear=function(){var a=this.dh;a.Ma();for(var b=a.count-1;0<=b;b--){var c=a.wa(b);null!==c&&c.clear()}a.clear();a.freeze()};ce.prototype.canUndo=ce.prototype.canUndo=function(){return this.op};
ce.prototype.undo=ce.prototype.undo=function(){if(this.canUndo())for(var a=this.dh.count-1;0<=a;a--){var b=this.dh.wa(a);null!==b&&b.undo()}};ce.prototype.canRedo=ce.prototype.canRedo=function(){return this.op};ce.prototype.redo=ce.prototype.redo=function(){if(this.canRedo())for(var a=this.dh.count,b=0;b<a;b++){var c=this.dh.wa(b);null!==c&&c.redo()}};t.A(ce,{dh:"changes"},function(){return this.iH});t.g(ce,"name",ce.prototype.name);
t.defineProperty(ce,{name:"name"},function(){return this.Zb},function(a){this.Zb=a});t.g(ce,"isComplete",ce.prototype.op);t.defineProperty(ce,{op:"isComplete"},function(){return this.nC},function(a){this.nC=a});function zd(){this.iz=new ma(E);this.Le=!1;this.oH=(new B(ce)).freeze();this.Ug=-1;this.CC=999;this.xi=!1;this.Bu=null;this.Vk=0;this.ny=!1;e&&(this.ny=!0);this.$g=(new B("string")).freeze();this.ro=new B("number");this.Ry=!0;this.cz=!1}t.ga("UndoManager",zd);
zd.prototype.toString=function(a){for(var b="UndoManager "+this.cj+"<"+this.history.count+"<="+this.AA,b=b+"[",c=this.EF.count,d=0;d<c;d++)0<d&&(b+=" "),b+=this.EF.wa(d);b+="]";if(void 0!==a&&0<a)for(c=this.history.count,d=0;d<c;d++)b+="\n "+this.history.wa(d).toString(a-1);return b};
zd.prototype.clear=zd.prototype.clear=function(){var a=this.history;a.Ma();for(var b=a.count-1;0<=b;b--){var c=a.wa(b);null!==c&&c.clear()}a.clear();this.Ug=-1;a.freeze();this.xi=!1;this.Bu=null;this.Vk=0;this.$g.Ma();this.$g.clear();this.$g.freeze();this.ro.clear()};zd.prototype.addModel=zd.prototype.EH=function(a){this.iz.add(a)};zd.prototype.removeModel=zd.prototype.JJ=function(a){this.iz.remove(a)};
zd.prototype.startTransaction=zd.prototype.uc=function(a){void 0===a&&(a="");null===a&&(a="");if(this.vb)return!1;!0===this.Ry&&(this.Ry=!1,this.Vk++,this.Xc("StartingFirstTransaction",a,this.Zi),0<this.Vk&&this.Vk--);this.isEnabled&&(this.$g.Ma(),this.$g.add(a),this.$g.freeze(),null===this.Zi?this.ro.add(0):this.ro.add(this.Zi.dh.count));this.Vk++;var b=1===this.Je;b&&this.Xc("StartedTransaction",a,this.Zi);return b};zd.prototype.commitTransaction=zd.prototype.Ce=function(a){return Ge(this,!0,a)};
zd.prototype.rollbackTransaction=zd.prototype.Np=function(){return Ge(this,!1,"")};
function Ge(a,b,c){if(a.vb)return!1;a.Tz&&1>a.Je&&t.trace("Ending transaction without having started a transaction: "+c);var d=1===a.Je;d&&b&&a.isEnabled&&a.Xc("CommittingTransaction",c,a.Zi);var f=0;if(0<a.Je&&(a.Vk--,a.isEnabled)){var g=a.$g.count;0<g&&(""===c&&(c=a.$g.wa(0)),a.$g.Ma(),a.$g.qd(g-1),a.$g.freeze());g=a.ro.count;0<g&&(f=a.ro.wa(g-1),a.ro.qd(g-1))}g=a.Zi;if(d){if(b){a.cz=!1;if(a.isEnabled&&null!==g){b=g;b.op=!0;b.name=c;d=a.history;d.Ma();for(f=d.count-1;f>a.cj;f--)g=d.wa(f),null!==
g&&g.clear(),d.qd(f),a.cz=!0;f=a.AA;0===f&&(f=1);0<f&&d.count>=f&&(g=d.wa(0),null!==g&&g.clear(),d.qd(0),a.Ug--);d.add(b);a.Ug++;d.freeze();g=b}a.Xc("CommittedTransaction",c,g)}else{a.xi=!0;try{a.isEnabled&&null!==g&&(g.op=!0,g.undo())}finally{a.Xc("RolledBackTransaction",c,g),a.xi=!1}null!==g&&g.clear()}a.Bu=null;return!0}if(a.isEnabled&&!b&&null!==g){a=f;c=g.dh;for(b=c.count-1;b>=a;b--)d=c.wa(b),null!==d&&d.undo(),c.Ma(),c.qd(b);c.freeze()}return!1}
zd.prototype.canUndo=zd.prototype.canUndo=function(){if(!this.isEnabled||0<this.Je||this.vb)return!1;var a=this.HG;return null!==a&&a.canUndo()?!0:!1};zd.prototype.undo=zd.prototype.undo=function(){if(this.canUndo()){var a=this.HG;try{this.Xc("StartingUndo","Undo",a),this.xi=!0,this.Ug--,a.undo()}catch(b){t.trace("undo error: "+b.toString())}finally{this.xi=!1,this.Xc("FinishedUndo","Undo",a)}}};
zd.prototype.canRedo=zd.prototype.canRedo=function(){if(!this.isEnabled||0<this.Je||this.vb)return!1;var a=this.GG;return null!==a&&a.canRedo()?!0:!1};zd.prototype.redo=zd.prototype.redo=function(){if(this.canRedo()){var a=this.GG;try{this.Xc("StartingRedo","Redo",a),this.xi=!0,this.Ug++,a.redo()}catch(b){t.trace("redo error: "+b.toString())}finally{this.xi=!1,this.Xc("FinishedRedo","Redo",a)}}};
zd.prototype.Xc=function(a,b,c){void 0===c&&(c=null);var d=new td;d.td=ud;d.propertyName=a;d.object=c;d.oldValue=b;for(a=this.qJ;a.next();)b=a.value,d.ba=b,b.iw(d)};zd.prototype.handleChanged=zd.prototype.PE=function(a){if(this.isEnabled&&!this.vb&&!this.skipsEvent(a)){var b=this.Zi;null===b&&(this.Bu=b=new ce);var c=a.copy(),b=b.dh;b.Ma();b.add(c);b.freeze();this.Tz&&0>=this.Je&&!this.Ry&&(a=a.h,null!==a&&!1===a.lf||t.trace("Change not within a transaction: "+c.toString()))}};
zd.prototype.skipsEvent=function(a){if(null===a||0>a.td.value)return!0;a=a.object;if(a instanceof Q){if(a=a.layer,null!==a&&a.rc)return!0}else if(a instanceof $d&&a.rc)return!0;return!1};t.A(zd,{qJ:"models"},function(){return this.iz.k});t.g(zd,"isEnabled",zd.prototype.isEnabled);t.defineProperty(zd,{isEnabled:"isEnabled"},function(){return this.Le},function(a){this.Le=a});t.A(zd,{HG:"transactionToUndo"},function(){return 0<=this.cj&&this.cj<=this.history.count-1?this.history.wa(this.cj):null});
t.A(zd,{GG:"transactionToRedo"},function(){return this.cj<this.history.count-1?this.history.wa(this.cj+1):null});t.A(zd,{vb:"isUndoingRedoing"},function(){return this.xi});t.A(zd,{history:"history"},function(){return this.oH});t.g(zd,"maxHistoryLength",zd.prototype.AA);t.defineProperty(zd,{AA:"maxHistoryLength"},function(){return this.CC},function(a){this.CC=a});t.A(zd,{cj:"historyIndex"},function(){return this.Ug});t.A(zd,{Zi:"currentTransaction"},function(){return this.Bu});
t.A(zd,{Je:"transactionLevel"},function(){return this.Vk});t.A(zd,{dF:"isInTransaction"},function(){return 0<this.Vk});t.defineProperty(zd,{Tz:"checksTransactionLevel"},function(){return this.ny},function(a){this.ny=a});t.A(zd,{EF:"nestedTransactionNames"},function(){return this.$g});function oa(){0<arguments.length&&t.ad(oa);t.Ac(this);this.U=null;this.MB=this.NB=this.YB=this.OB=!1;this.Ok=this.jy=null;this.CD=1.05;this.WB=1;this.dz=NaN;this.zC=null;this.DD=NaN}t.ga("CommandHandler",oa);
oa.prototype.toString=function(){return"CommandHandler"};t.A(oa,{h:"diagram"},function(){return this.U});oa.prototype.yd=function(a){e&&null!==a&&t.l(a,z,oa,"setDiagram");this.U=a};
oa.prototype.doKeyDown=function(){var a=this.h;if(null!==a){var b=a.R,c=t.Vm?b.Bp:b.control,d=b.shift,f=b.alt,g=b.key;!c||"C"!==g&&"Insert"!==g?c&&"X"===g||d&&"Del"===g?this.canCutSelection()&&this.cutSelection():"Del"===g?this.canDeleteSelection()&&this.deleteSelection():c&&"V"===g||d&&"Insert"===g?this.canPasteSelection()&&this.pasteSelection():c&&"Y"===g||f&&d&&"Backspace"===g?this.canRedo()&&this.redo():c&&"Z"===g||f&&"Backspace"===g?this.canUndo()&&this.undo():c&&"A"===g?this.canSelectAll()&&
this.selectAll():"Esc"===g?this.canStopCommand()&&this.stopCommand():"Up"===g?a.pf&&(c?a.scroll("pixel","up"):a.scroll("line","up")):"Down"===g?a.pf&&(c?a.scroll("pixel","down"):a.scroll("line","down")):"Left"===g?a.of&&(c?a.scroll("pixel","left"):a.scroll("line","left")):"Right"===g?a.of&&(c?a.scroll("pixel","right"):a.scroll("line","right")):"PageUp"===g?d&&a.of?a.scroll("page","left"):a.pf&&a.scroll("page","up"):"PageDown"===g?d&&a.of?a.scroll("page","right"):a.pf&&a.scroll("page","down"):"Home"===
g?(b=a.Gd,c&&a.pf?a.position=new w(a.position.x,b.y):!c&&a.of&&(a.position=new w(b.x,a.position.y))):"End"===g?(b=a.Gd,d=a.xb,c&&a.pf?a.position=new w(d.x,b.bottom-d.height):!c&&a.of&&(a.position=new w(b.right-d.width,d.y))):"Subtract"===g?this.canDecreaseZoom()&&this.decreaseZoom():"Add"===g?this.canIncreaseZoom()&&this.increaseZoom():c&&"0"===g?this.canResetZoom()&&this.resetZoom():d&&"Z"===g?this.canZoomToFit()&&this.zoomToFit():c&&!d&&"G"===g?this.canGroupSelection()&&this.groupSelection():c&&
d&&"G"===g?this.canUngroupSelection()&&this.ungroupSelection():b.event&&113===b.event.which?this.canEditTextBlock()&&this.editTextBlock():b.event&&93===b.event.which?this.canShowContextMenu()&&this.showContextMenu():b.bubbles=!0:this.canCopySelection()&&this.copySelection()}};oa.prototype.doKeyUp=function(){var a=this.h;null!==a&&(a.R.bubbles=!0)};oa.prototype.stopCommand=function(){var a=this.h;if(null!==a){var b=a.Ua;b instanceof He&&a.Pe&&a.kw();null!==b&&b.doCancel()}};
oa.prototype.canStopCommand=function(){return!0};oa.prototype.selectAll=function(){var a=this.h;if(null!==a){a.fa();try{a.$b="wait";a.Ba("ChangingSelection");for(var b=a.sl;b.next();)b.value.eb=!0;for(b=a.jh;b.next();)b.value.eb=!0;for(b=a.links;b.next();)b.value.eb=!0}finally{a.Ba("ChangedSelection"),a.$b=""}}};oa.prototype.canSelectAll=function(){var a=this.h;return null!==a&&a.Pe};
oa.prototype.deleteSelection=function(){var a=this.h;if(null!==a&&!a.Ba("SelectionDeleting",a.selection))try{a.$b="wait";a.uc("Delete");a.Ba("ChangingSelection");for(var b=new ma(C),c=a.selection.k;c.next();)Ie(b,c.value,!0,this.sE?Infinity:0,function(a){return a.canDelete()});a.VA(b,!0);a.Ba("SelectionDeleted",b)}finally{a.Ba("ChangedSelection"),a.Ce("Delete"),a.$b=""}};oa.prototype.canDeleteSelection=function(){var a=this.h;return null===a||a.ab||a.Ze||!a.Zk||0===a.selection.count?!1:!0};
function Ie(a,b,c,d,f){if(!(a.contains(b)||(void 0===f&&(f=null),null!==f&&!f(b)||b instanceof Je)))if(a.add(b),b instanceof R){if(c&&b instanceof S)for(var g=b.Rc;g.next();){var h=g.value;Ie(a,h,c,d,f)}for(h=b.qe;h.next();)if(g=h.value,!a.contains(g)){var k=g.aa,l=g.ca;null!==k&&a.contains(k)&&null!==l&&a.contains(l)?Ie(a,g,c,d,f):null!==k&&null!==l||Ie(a,g,c,d,f)}if(1<d)for(b=b.BE();b.next();)h=b.value,Ie(a,h,c,d-1,f)}else if(b instanceof T)for(h=b.fk;h.next();)Ie(a,h.value,c,d,f)}
oa.prototype.Wo=function(a,b,c){var d=new ia(C,C);for(a=a.k;a.next();){var f=a.value;Ke(this,f,b,d,c)}if(null!==b){c=b.ba;a=!1;null!==b.wb.Hd&&(a=b.wb.Hd.Zh);for(var g=new ma(T),h=new ia(T,T),k=d.k;k.next();)if(f=k.value,f instanceof T)a||null!==f.aa&&null!==f.ca||g.add(f);else if(c instanceof Kd&&f instanceof R&&null!==f.data){var l=f,f=k.key,m=f.dA();null!==m&&((m=d.za(m))?(c.lh(l.data,c.Hb(m.data)),l=b.Qf(l.data),f=f.ft(),null!==f&&null!==l&&h.add(f,l)):c.lh(l.data,void 0))}0<g.count&&b.VA(g,!1);
if(0<h.count)for(b=h.k;b.next();)d.add(b.key,b.value)}for(b=d.k;b.next();)b.value.Kb();return d};
function Ke(a,b,c,d,f){if(null===b||f&&!b.canCopy())return null;if(d.contains(b))return a=d.za(b),a instanceof C?a:null;var g=null,h=b.data;if(null!==h&&null!==c){var k=c.ba;b instanceof T?k instanceof M&&(h=k.eE(h),t.qb(h)&&(k.aw(h),g=c.Qf(h))):(h=k.copyNodeData(h),t.qb(h)&&(k.Am(h),g=c.fh(h)))}else Le(b),g=b.copy(),null!==c&&g instanceof C&&c.add(g);if(!(g instanceof C))return null;g.eb=!1;g.zg=!1;d.add(b,g);if(b instanceof R){for(k=b.qe;k.next();){h=k.value;if(h.aa===b){var l=d.za(h);null!==l&&
(l.aa=g)}h.ca===b&&(l=d.za(h),null!==l&&(l.ca=g))}if(b instanceof S&&g instanceof S)for(b=b.Rc;b.next();)k=Ke(a,b.value,c,d,f),k instanceof T||null===k||(k.hb=g)}else if(b instanceof T)for(k=b.aa,null!==k&&(k=d.za(k),null!==k&&(g.aa=k)),k=b.ca,null!==k&&(k=d.za(k),null!==k&&(g.ca=k)),b=b.fk;b.next();)k=Ke(a,b.value,c,d,f),null!==k&&(k.$d=g);return g}
oa.prototype.copySelection=function(){var a=this.h;if(null!==a){for(var b=new ma(C),a=a.selection.k;a.next();)Ie(b,a.value,!0,this.dE?Infinity:0,function(a){return a.canCopy()});this.copyToClipboard(b)}};oa.prototype.canCopySelection=function(){var a=this.h;return null!==a&&a.Si&&a.ew&&0!==a.selection.count?!0:!1};oa.prototype.cutSelection=function(){this.copySelection();this.deleteSelection()};
oa.prototype.canCutSelection=function(){var a=this.h;return null!==a&&!a.ab&&!a.Ze&&a.Si&&a.Zk&&a.ew&&0!==a.selection.count?!0:!1};oa.prototype.copyToClipboard=function(a){var b=this.h;if(null!==b){var c=null;if(null===a)t.Ss=null,t.lw="";else{var c=b.ba,d=!1,f=!1,g=null;try{c instanceof Kd&&(d=c.cl,c.cl=this.Yz),c instanceof M&&(f=c.bl,c.bl=this.Xz),g=b.Wo(a,null,!0)}finally{c instanceof Kd&&(c.cl=d),c instanceof M&&(c.bl=f),c=new B(C),c.Oe(g),t.Ss=c,t.lw=b.ba.Wj}}b.Ba("ClipboardChanged",c)}};
oa.prototype.pasteFromClipboard=function(){var a=new ma(C),b=t.Ss;if(null===b)return a;var c=this.h;if(null===c||t.lw!==c.ba.Wj)return a;var d=c.ba,f=!1,g=!1,h=null;try{d instanceof Kd&&(f=d.cl,d.cl=this.Yz),d instanceof M&&(g=d.bl,d.bl=this.Xz),h=c.Wo(b,c,!1)}finally{for(d instanceof Kd&&(d.cl=f),d instanceof M&&(d.bl=g),b=h.k;b.next();)c=b.value,d=b.key,c.location.N()||(d.location.N()?c.location=d.location:!c.position.N()&&d.position.N()&&(c.position=d.position)),a.add(c)}return a};
oa.prototype.pasteSelection=function(a){var b=this.h;if(null!==b)try{b.$b="wait";b.uc("Paste");b.Ba("ChangingSelection");var c=this.pasteFromClipboard();0<c.count&&Me(b);for(var d=c.k;d.next();)d.value.eb=!0;b.Ba("ChangedSelection");if(a instanceof w){var f=b.computePartsBounds(b.selection);if(f){var g=b.wb.Hd,h=g.computeEffectiveCollection(b.selection);g.moveParts(h,new w(a.x-f.Da,a.y-f.Oa),!1)}}b.Ba("ClipboardPasted",c)}finally{b.Ce("Paste"),b.$b=""}};
oa.prototype.canPasteSelection=function(){var a=this.h;return null===a||a.ab||a.Ze||!a.Bm||!a.ew||null===t.Ss||t.lw!==a.ba.Wj?!1:!0};oa.prototype.undo=function(){var a=this.h;null!==a&&a.la.undo()};oa.prototype.canUndo=function(){var a=this.h;return null===a||a.ab||a.Ze?!1:a.Lz&&a.la.canUndo()};oa.prototype.redo=function(){var a=this.h;null!==a&&a.la.redo()};oa.prototype.canRedo=function(){var a=this.h;return null===a||a.ab||a.Ze?!1:a.Lz&&a.la.canRedo()};
oa.prototype.decreaseZoom=function(a){void 0===a&&(a=1/this.bu);t.q(a,oa,"decreaseZoom:factor");var b=this.h;null!==b&&b.Fm===Xe&&(a*=b.scale,a<b.Cg||a>b.Bg||(b.scale=a))};oa.prototype.canDecreaseZoom=function(a){void 0===a&&(a=1/this.bu);t.q(a,oa,"canDecreaseZoom:factor");var b=this.h;if(null===b||b.Fm!==Xe)return!1;a*=b.scale;return a<b.Cg||a>b.Bg?!1:b.Ns};
oa.prototype.increaseZoom=function(a){void 0===a&&(a=this.bu);t.q(a,oa,"increaseZoom:factor");var b=this.h;null!==b&&b.Fm===Xe&&(a*=b.scale,a<b.Cg||a>b.Bg||(b.scale=a))};oa.prototype.canIncreaseZoom=function(a){void 0===a&&(a=this.bu);t.q(a,oa,"canIncreaseZoom:factor");var b=this.h;if(null===b||b.Fm!==Xe)return!1;a*=b.scale;return a<b.Cg||a>b.Bg?!1:b.Ns};oa.prototype.resetZoom=function(a){void 0===a&&(a=this.Ys);t.q(a,oa,"resetZoom:newscale");var b=this.h;null===b||a<b.Cg||a>b.Bg||(b.scale=a)};
oa.prototype.canResetZoom=function(a){void 0===a&&(a=this.Ys);t.q(a,oa,"canResetZoom:newscale");var b=this.h;return null===b||a<b.Cg||a>b.Bg?!1:b.Ns};oa.prototype.zoomToFit=function(){var a=this.h;if(null!==a){var b=a.scale,c=a.position;b!==this.DD||isNaN(this.dz)?(this.dz=b,this.zC=c.copy(),a.zoomToFit(),a.Uf(),this.DD=a.scale):(a.scale=this.dz,a.position=this.zC)}};oa.prototype.canZoomToFit=function(){var a=this.h;return null===a?!1:a.Ns};
oa.prototype.collapseTree=function(a){void 0===a&&(a=null);var b=this.h;if(null===b)return!1;b.uc("Collapse Tree");var c=new B(R);if(a instanceof R&&a.Qc)a.collapseTree(),c.add(a);else for(a=b.selection.k;a.next();){var d=a.value;d instanceof R&&d.Qc&&(d.collapseTree(),c.add(d))}b.Ba("TreeCollapsed",c);b.Ce("Collapse Tree")};
oa.prototype.canCollapseTree=function(a){void 0===a&&(a=null);var b=this.h;if(null===b||b.ab)return!1;if(a instanceof R){if(!a.Qc)return!1;if(0<a.Fw().count)return!0}else for(a=b.selection.k;a.next();)if(b=a.value,b instanceof R&&b.Qc&&0<b.Fw().count)return!0;return!1};
oa.prototype.expandTree=function(a){void 0===a&&(a=null);var b=this.h;if(null===b)return!1;b.uc("Expand Tree");var c=new B(R);if(a instanceof R&&!a.Qc)a.expandTree(),c.add(a);else for(a=b.selection.k;a.next();){var d=a.value;d instanceof R&&!d.Qc&&(d.expandTree(),c.add(d))}b.Ba("TreeExpanded",c);b.Ce("Expand Tree")};
oa.prototype.canExpandTree=function(a){void 0===a&&(a=null);var b=this.h;if(null===b||b.ab)return!1;if(a instanceof R){if(a.Qc)return!1;if(0<a.Fw().count)return!0}else for(a=b.selection.k;a.next();)if(b=a.value,b instanceof R&&!b.Qc&&0<b.Fw().count)return!0;return!1};
oa.prototype.groupSelection=function(){var a=this.h;if(null!==a){var b=a.ba;if(null!==b&&b instanceof M){var c=this.Nz;if(null!==c){var d=null;try{a.$b="wait";a.uc("Group");a.Ba("ChangingSelection");for(var f=new B(C),g=a.selection.k;g.next();){var h=g.value;h.Ld()&&h.canGroup()&&f.add(h)}for(var k=new B(C),l=f.k;l.next();){for(var m=l.value,h=!1,g=f.k;g.next();)if(m.nl(g.value)){h=!0;break}h||k.add(m)}if(0<k.count){g=k.k;g.next();var n=g.value.hb;if(null!==n)for(;null!==n;){g=k.k;g.next();for(f=
!1;g.next();)if(m=g.value,!m.nl(n)){f=!0;break}if(f)n=n.hb;else break}if(c instanceof S)Le(c),d=c.copy(),d instanceof S&&a.add(d);else if(b.sA(c)){var p=b.copyNodeData(c);t.qb(p)&&(b.Am(p),d=a.Ew(p))}if(d instanceof S){null!==n&&this.isValidMember(n,d)&&(d.hb=n);for(g=k.k;g.next();)m=g.value,this.isValidMember(d,m)&&(m.hb=d);a.select(d)}}a.Ba("ChangedSelection");a.Ba("SelectionGrouped",d)}finally{a.Ce("Group"),a.$b=""}}}}};
oa.prototype.canGroupSelection=function(){var a=this.h;if(null===a||a.ab||a.Ze||!a.Bm||!a.Mo)return!1;var b=a.ba;if(null===b||!(b instanceof M)||null===this.Nz)return!1;for(a=a.selection.k;a.next();)if(b=a.value,b.Ld()&&b.canGroup())return!0;return!1};function Ye(a){var b=t.Db();for(a=a.k;a.next();){var c=a.value;c instanceof T||b.push(c)}a=new ma(C);for(var c=b.length,d=0;d<c;d++){for(var f=b[d],g=!0,h=0;h<c;h++)if(f.nl(b[h])){g=!1;break}g&&a.add(f)}t.ya(b);return a}
oa.prototype.isValidMember=function(a,b){if(null===b||a===b||b instanceof T)return!1;if(null!==a){if(a===b||a.nl(b))return!1;var c=a.vt;if(null!==c&&!c(a,b)||null===a.data&&null!==b.data||null!==a.data&&null===b.data)return!1}c=this.vt;return null!==c?c(a,b):!0};
oa.prototype.ungroupSelection=function(a){void 0===a&&(a=null);var b=this.h;if(null!==b){var c=b.ba;if(null!==c&&c instanceof M)try{b.$b="wait";b.uc("Ungroup");b.Ba("ChangingSelection");var d=new B(S);if(a instanceof S)d.add(a);else for(var f=b.selection.k;f.next();){var g=f.value;g instanceof S&&g.canUngroup()&&d.add(g)}if(0<d.count)for(b.kw(),f=d.k;f.next();){var h=f.value;h.expandSubGraph();var k=h.data,l=h.hb,m=null!==l&&null!==l.data?c.Hb(l.data):void 0,n=new B(C);n.Oe(h.Rc);for(var p=n.k;p.next();){var q=
p.value;if(!(q instanceof T)){var r=q.data;null!==r?c.vx(r,m):q.hb=l;q.eb=!0}}null!==k?c.nx(k):b.remove(h)}b.Ba("ChangedSelection");b.Ba("SelectionUngrouped",d,n)}finally{b.Ce("Ungroup"),b.$b=""}}};
oa.prototype.canUngroupSelection=function(a){void 0===a&&(a=null);var b=this.h;if(null===b||b.ab||b.Ze||!b.Zk||!b.Ro)return!1;var c=b.ba;if(null===c||!(c instanceof M))return!1;if(a instanceof S){if(a.canUngroup())return!0}else for(a=b.selection.k;a.next();)if(b=a.value,b instanceof S&&b.canUngroup())return!0;return!1};oa.prototype.addTopLevelParts=function(a,b){for(var c=!0,d=Ye(a).k;d.next();){var f=d.value;null!==f.hb&&(!b||this.isValidMember(null,f)?f.hb=null:c=!1)}return c};
oa.prototype.collapseSubGraph=function(a){void 0===a&&(a=null);var b=this.h;if(null===b)return!1;b.uc("Collapse SubGraph");var c=new B(S);if(a instanceof S&&a.Yd)a.collapseSubGraph(),c.add(a);else for(a=b.selection.k;a.next();){var d=a.value;d instanceof S&&d.Yd&&(d.collapseSubGraph(),c.add(d))}b.Ba("SubGraphCollapsed",c);b.Ce("Collapse SubGraph")};
oa.prototype.canCollapseSubGraph=function(a){void 0===a&&(a=null);var b=this.h;if(null===b||b.ab)return!1;if(a instanceof S)return a.Yd?!0:!1;for(a=b.selection.k;a.next();)if(b=a.value,b instanceof S&&b.Yd)return!0;return!1};
oa.prototype.expandSubGraph=function(a){void 0===a&&(a=null);var b=this.h;if(null===b)return!1;b.uc("Expand SubGraph");var c=new B(S);if(a instanceof S&&!a.Yd)a.expandSubGraph(),c.add(a);else for(a=b.selection.k;a.next();){var d=a.value;d instanceof S&&!d.Yd&&(d.expandSubGraph(),c.add(d))}b.Ba("SubGraphExpanded",c);b.Ce("Expand SubGraph")};
oa.prototype.canExpandSubGraph=function(a){void 0===a&&(a=null);var b=this.h;if(null===b||b.ab)return!1;if(a instanceof S)return a.Yd?!1:!0;for(a=b.selection.k;a.next();)if(b=a.value,b instanceof S&&!b.Yd)return!0;return!1};
oa.prototype.editTextBlock=function(a){void 0===a&&(a=null);var b=this.h;if(null!==b){var c=b.wb.Bx;if(null!==c){if(null===a){a=b.selection.k;for(var d=null;a.next();){var f=a.value;if(f.canEdit()){d=f;break}}if(null===d)return;a=d.bt(function(a){return a instanceof na&&a.yw})}null!==a&&a instanceof na&&(c.Ng=a,b.Ua=c)}}};
oa.prototype.canEditTextBlock=function(a){void 0===a&&(a=null);var b=this.h;if(null===b||b.ab||b.Ze||!b.Qo||null===b.wb.Bx)return!1;if(a instanceof na){if(a=a.S,null!==a&&a.canEdit())return!0}else for(b=b.selection.k;b.next();)if(a=b.value,a.canEdit()&&(a=a.bt(function(a){return a instanceof na&&a.yw}),null!==a))return!0;return!1};
oa.prototype.showContextMenu=function(a){var b=this.h;if(null!==b){var c=b.wb.pw;if(null!==c&&(void 0===a&&(a=0<b.selection.count?b.selection.$a():b),a=c.findObjectWithContextMenu(a),null!==a)){var d=new ld,f=null;a instanceof Q?f=a.pb(Hb):b.ao||(f=b.xb,f=new w(f.x+f.width/2,f.y+f.height/2));null!==f&&(d.Ke=b.IG(f),d.ea=f,b.ec=d);b.Ua=c;Ze(c,!1,a)}}};
oa.prototype.canShowContextMenu=function(a){var b=this.h;if(null===b)return!1;var c=b.wb.pw;if(null===c)return!1;void 0===a&&(a=0<b.selection.count?b.selection.$a():b);return null===c.findObjectWithContextMenu(a)?!1:!0};t.g(oa,"copiesTree",oa.prototype.dE);t.defineProperty(oa,{dE:"copiesTree"},function(){return this.OB},function(a){t.i(a,"boolean",oa,"copiesTree");this.OB=a});t.g(oa,"deletesTree",oa.prototype.sE);
t.defineProperty(oa,{sE:"deletesTree"},function(){return this.YB},function(a){t.i(a,"boolean",oa,"deletesTree");this.YB=a});t.g(oa,"copiesParentKey",oa.prototype.Yz);t.defineProperty(oa,{Yz:"copiesParentKey"},function(){return this.NB},function(a){t.i(a,"boolean",oa,"copiesParentKey");this.NB=a});t.g(oa,"copiesGroupKey",oa.prototype.Xz);t.defineProperty(oa,{Xz:"copiesGroupKey"},function(){return this.MB},function(a){t.i(a,"boolean",oa,"copiesGroupKey");this.MB=a});t.g(oa,"archetypeGroupData",oa.prototype.Nz);
t.defineProperty(oa,{Nz:"archetypeGroupData"},function(){return this.jy},function(a){null!==a&&t.l(a,Object,oa,"archetypeGroupData");var b=this.h;null!==b&&(b=b.ba,b instanceof M&&(a instanceof S||b.sA(a)||t.m("CommandHandler.archetypeGroupData must be either a Group or a data object for which GraphLinksModel.isGroupForNodeData is true: "+a)));this.jy=a});t.g(oa,"memberValidation",oa.prototype.vt);
t.defineProperty(oa,{vt:"memberValidation"},function(){return this.Ok},function(a){null!==a&&t.i(a,"function",oa,"memberValidation");this.Ok=a});t.g(oa,"defaultScale",oa.prototype.Ys);t.defineProperty(oa,{Ys:"defaultScale"},function(){return this.WB},function(a){t.q(a,oa,"defaultScale");0<a||t.m("defaultScale must be larger than zero, not: "+a);this.WB=a});t.g(oa,"zoomFactor",oa.prototype.bu);
t.defineProperty(oa,{bu:"zoomFactor"},function(){return this.CD},function(a){t.q(a,oa,"zoomFactor");1<a||t.m("zoomFactor must be larger than 1.0, not: "+a);this.CD=a});function ae(){0<arguments.length&&t.ad(ae);t.Ac(this);this.U=null;this.Zb="";this.Le=!0;this.lC=!1;this.yD=null;this.Zv=-1}t.ga("Tool",ae);ae.prototype.yd=function(a){e&&null!==a&&t.l(a,z,ae,"setDiagram");this.U=a};ae.prototype.toString=function(){return""!==this.name?this.name+" Tool":t.xg(Object.getPrototypeOf(this))};
ae.prototype.updateAdornments=function(){};ae.prototype.canStart=function(){return this.isEnabled};ae.prototype.doStart=function(){};ae.prototype.doActivate=function(){this.ia=!0};ae.prototype.doDeactivate=function(){this.ia=!1};ae.prototype.doStop=function(){};ae.prototype.doCancel=function(){this.stopTool()};ae.prototype.stopTool=function(){var a=this.h;null!==a&&a.Ua===this&&(a.Ua=null,a.$b="")};ae.prototype.doMouseDown=function(){!this.ia&&this.canStart()&&this.doActivate()};
ae.prototype.doMouseMove=function(){};ae.prototype.doMouseUp=function(){this.stopTool()};ae.prototype.doMouseWheel=function(){};ae.prototype.doKeyDown=function(){var a=this.h;null!==a&&"Esc"===a.R.key&&this.doCancel()};ae.prototype.doKeyUp=function(){};ae.prototype.startTransaction=ae.prototype.uc=function(a){this.gf=null;var b=this.h;return null===b?!1:b.uc(a)};ae.prototype.stopTransaction=ae.prototype.hk=function(){var a=this.h;return null===a?!1:null===this.gf?a.Np():a.Ce(this.gf)};
ae.prototype.standardMouseSelect=function(){var a=this.h;if(null!==a&&a.Pe){var b=a.R,c=a.et(b.ea,!1);if(null!==c)if(t.Vm?b.Bp:b.control){a.Ba("ChangingSelection");for(b=c;null!==b&&!b.canSelect();)b=b.hb;null!==b&&(b.eb=!b.eb);a.Ba("ChangedSelection")}else if(b.shift){if(!c.eb){a.Ba("ChangingSelection");for(b=c;null!==b&&!b.canSelect();)b=b.hb;null!==b&&(b.eb=!0);a.Ba("ChangedSelection")}}else{if(!c.eb){for(b=c;null!==b&&!b.canSelect();)b=b.hb;null!==b&&a.select(b)}}else!b.left||(t.Vm?b.Bp:b.control)||
b.shift||a.kw()}};ae.prototype.standardMouseClick=function(a,b){void 0===a&&(a=null);void 0===b&&(b=function(a){return!a.layer.rc});var c=this.h;if(null!==c){var d=c.R,f=c.pe(d.ea,a,b);d.ce=f;$e(f,d,c)}};
function $e(a,b,c){var d=0;b.left?d=1===b.Ae?1:2===b.Ae?2:1:b.right&&1===b.Ae&&(d=3);var f="";if(null!==a){switch(d){case 1:f="ObjectSingleClicked";break;case 2:f="ObjectDoubleClicked";break;case 3:f="ObjectContextClicked"}0!==d&&c.Ba(f,a)}else{switch(d){case 1:f="BackgroundSingleClicked";break;case 2:f="BackgroundDoubleClicked";break;case 3:f="BackgroundContextClicked"}0!==d&&c.Ba(f)}if(null!==a)for(;null!==a;){f=null;switch(d){case 1:f=a.click;break;case 2:f=a.Om?a.Om:a.click;break;case 3:f=a.Ws}if(null!==
f&&(f(b,a),b.Ee))break;a=a.ja}else{f=null;switch(d){case 1:f=c.click;break;case 2:f=c.Om?c.Om:c.click;break;case 3:f=c.Ws}null!==f&&f(b)}}
ae.prototype.standardMouseOver=function(){var a=this.h;if(null!==a){var b=a.R;if(null!==b.h&&!0!==a.Ub.sd){var c=a.Wa;a.Wa=!0;var d=a.pe(b.ea,null,null);b.ce=d;var f=!1;if(d!==a.Bn){var g=a.Bn,h=g;a.Bn=d;for(this.doCurrentObjectChanged(g,d);null!==g;){var k=g.EA;if(null!==k){if(d===g)break;if(null!==d&&d.ej(g))break;k(b,g,d);f=!0;if(b.Ee)break}g=g.ja}for(g=h;null!==d;){k=d.DA;if(null!==k){if(g===d)break;if(null!==g&&g.ej(d))break;k(b,d,g);f=!0;if(b.Ee)break}d=d.ja}d=a.Bn}if(null!==d){g=d;for(k="";null!==
g;){k=g.cursor;if(""!==k)break;g=g.ja}a.$b=k;for(g=d;null!==g;){k=g.zt;if(null!==k&&(k(b,g),f=!0,b.Ee))break;g=g.ja}}else a.$b="",k=a.zt,null!==k&&(k(b),f=!0);f&&a.te();a.Wa=c}}};ae.prototype.doCurrentObjectChanged=function(){};
ae.prototype.standardMouseWheel=function(){var a=this.h;if(null!==a){var b=a.R,c=b.Yj;if(0!==c&&a.Gd.N()){var d=a.Se,f=a.wb.cn;if((f===af&&!b.shift||f===bf&&b.control)&&(0<c?d.canIncreaseZoom():d.canDecreaseZoom()))f=a.$f,a.$f=b.Ke,0<c?d.increaseZoom():d.decreaseZoom(),a.$f=f,b.bubbles=!1;else if(f===af&&b.shift||f===bf&&!b.control){d=a.position.copy();f=0<c?c:-c;if(!b.shift&&a.pf){var g=a.Rp,f=f/40*g;0<c?a.scroll("pixel","up",f):a.scroll("pixel","down",f)}else b.shift&&a.of&&(g=a.Qp,f=f/40*g,0<c?
a.scroll("pixel","left",f):a.scroll("pixel","right",f));a.position.M(d)||(b.bubbles=!1)}}}};ae.prototype.standardWaitAfter=function(a){t.i(a,"number",ae,"standardWaitAfter:delay");this.cancelWaitAfter();var b=this;this.Zv=window.setTimeout(function(){b.doWaitAfter()},a)};ae.prototype.cancelWaitAfter=function(){-1!==this.Zv&&window.clearTimeout(this.Zv);this.Zv=-1};ae.prototype.doWaitAfter=function(){};
ae.prototype.findToolHandleAt=function(a,b){var c=this.h;if(null===c)return null;c=c.pe(a,null,function(a){a=a.S;return null===a?!1:null!==a.pc});if(null===c)return null;var d=c.S;return null===d||d.Zc!==b?null:c};ae.prototype.isBeyondDragSize=function(a,b){var c=this.h;if(null===c)return!1;void 0===a&&(a=c.Nc.Ke);void 0===b&&(b=c.R.Ke);var d=c.wb.uE,c=d.width,d=d.height;t.gB&&(c+=6,d+=6);return Math.abs(b.x-a.x)>c||Math.abs(b.y-a.y)>d};t.A(ae,{h:"diagram"},function(){return this.U});
t.g(ae,"name",ae.prototype.name);t.defineProperty(ae,{name:"name"},function(){return this.Zb},function(a){t.i(a,"string",ae,"name");this.Zb=a});t.g(ae,"isEnabled",ae.prototype.isEnabled);t.defineProperty(ae,{isEnabled:"isEnabled"},function(){return this.Le},function(a){t.i(a,"boolean",ae,"isEnabled");this.Le=a});t.g(ae,"isActive",ae.prototype.ia);t.defineProperty(ae,{ia:"isActive"},function(){return this.lC},function(a){t.i(a,"boolean",ae,"isActive");this.lC=a});t.g(ae,"transactionResult",ae.prototype.gf);
t.defineProperty(ae,{gf:"transactionResult"},function(){return this.yD},function(a){null!==a&&t.i(a,"string",ae,"transactionResult");this.yD=a});
function cf(){0<arguments.length&&t.ad(cf);ae.call(this);this.name="Dragging";this.LB=this.pC=!0;this.Wq=this.cC=!1;this.tC=!0;this.Ty=(new fa(NaN,NaN)).freeze();this.Uy=Eb;this.Vy=(new w(NaN,NaN)).freeze();this.bC=!1;this.$B=this.KB=this.aC=this.RB=this.Ki=null;this.Iq=this.rC=!1;this.Bo=new w(NaN,NaN);this.Lv=new w;this.Ov=!1;this.Jv=this.oC=!0;this.Ln=100;this.Nl=this.zv=null}t.ga("DraggingTool",cf);t.Ka(cf,ae);t.g(cf,"isCopyEnabled",cf.prototype.qA);
t.defineProperty(cf,{qA:"isCopyEnabled"},function(){return this.pC},function(a){t.i(a,"boolean",cf,"isCopyEnabled");this.pC=a});t.g(cf,"copiesEffectiveCollection",cf.prototype.cE);t.defineProperty(cf,{cE:"copiesEffectiveCollection"},function(){return this.LB},function(a){t.i(a,"boolean",cf,"copiesEffectiveCollection");this.LB=a});t.g(cf,"dragsTree",cf.prototype.vE);t.defineProperty(cf,{vE:"dragsTree"},function(){return this.cC},function(a){t.i(a,"boolean",cf,"dragsTree");this.cC=a});
t.g(cf,"isGridSnapEnabled",cf.prototype.qp);t.defineProperty(cf,{qp:"isGridSnapEnabled"},function(){return this.Wq},function(a){t.i(a,"boolean",cf,"isGridSnapEnabled");this.Wq=a});t.g(cf,"isComplexRoutingRealtime",cf.prototype.aF);t.defineProperty(cf,{aF:"isComplexRoutingRealtime"},function(){return this.oC},function(a){t.i(a,"boolean",cf,"isComplexRoutingRealtime");this.oC=a});t.g(cf,"isGridSnapRealtime",cf.prototype.cF);
t.defineProperty(cf,{cF:"isGridSnapRealtime"},function(){return this.tC},function(a){t.i(a,"boolean",cf,"isGridSnapRealtime");this.tC=a});t.g(cf,"gridSnapCellSize",cf.prototype.lA);t.defineProperty(cf,{lA:"gridSnapCellSize"},function(){return this.Ty},function(a){t.l(a,fa,cf,"gridSnapCellSize");this.Ty.M(a)||(this.Ty=a=a.Z())});t.g(cf,"gridSnapCellSpot",cf.prototype.LE);
t.defineProperty(cf,{LE:"gridSnapCellSpot"},function(){return this.Uy},function(a){t.l(a,H,cf,"gridSnapCellSpot");this.Uy.M(a)||(this.Uy=a=a.Z())});t.g(cf,"gridSnapOrigin",cf.prototype.ME);t.defineProperty(cf,{ME:"gridSnapOrigin"},function(){return this.Vy},function(a){t.l(a,w,cf,"gridSnapOrigin");this.Vy.M(a)||(this.Vy=a=a.Z())});t.g(cf,"dragsLink",cf.prototype.Zh);t.defineProperty(cf,{Zh:"dragsLink"},function(){return this.bC},function(a){t.i(a,"boolean",cf,"dragsLink");this.bC=a});
t.g(cf,"currentPart",cf.prototype.Yo);t.defineProperty(cf,{Yo:"currentPart"},function(){return this.RB},function(a){null!==a&&t.l(a,C,cf,"currentPart");this.RB=a});t.g(cf,"copiedParts",cf.prototype.Dc);t.defineProperty(cf,{Dc:"copiedParts"},function(){return this.KB},function(a){this.KB=a});t.g(cf,"draggedParts",cf.prototype.qc);t.defineProperty(cf,{qc:"draggedParts"},function(){return this.aC},function(a){this.aC=a});t.g(cf,"draggedLink",cf.prototype.Mc);
t.defineProperty(cf,{Mc:"draggedLink"},function(){return this.$B},function(a){null!==a&&t.l(a,T,cf,"draggedLink");this.$B=a});t.g(cf,"isDragOutStarted",cf.prototype.pt);t.defineProperty(cf,{pt:"isDragOutStarted"},function(){return this.rC},function(a){this.rC=a});t.g(cf,"startPoint",cf.prototype.jj);t.defineProperty(cf,{jj:"startPoint"},function(){return this.Lv},function(a){t.l(a,w,cf,"startPoint");this.Lv.M(a)||(this.Lv=a=a.Z())});t.g(cf,"delay",cf.prototype.vw);
t.defineProperty(cf,{vw:"delay"},function(){return this.Ln},function(a){t.i(a,"number",cf,"delay");this.Ln=a});cf.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;if(null===a||!a.Rj&&!a.Si&&!a.Ms||!a.Pe)return!1;var b=a.R;return!b.left||a.Ua!==this&&(!this.isBeyondDragSize()||b.Uw&&b.timestamp-a.Nc.timestamp<this.Ln)?!1:null!==this.findDraggablePart()};
cf.prototype.findDraggablePart=function(){var a=this.h;if(null===a)return null;a=a.et(a.Nc.ea,!1);if(null===a)return null;for(;null!==a&&!a.canSelect();)a=a.hb;return null!==a&&(a.canMove()||a.canCopy())?a:null};cf.prototype.standardMouseSelect=function(){var a=this.h;if(null!==a&&a.Pe){var b=a.et(a.Nc.ea,!1);if(null!==b){for(;null!==b&&!b.canSelect();)b=b.hb;this.Yo=b;this.Yo.eb||(a.Ba("ChangingSelection"),b=a.R,(t.Vm?b.Bp:b.control)||b.shift||Me(a),this.Yo.eb=!0,a.Ba("ChangedSelection"))}}};
cf.prototype.doActivate=function(){var a=this.h;if(null!==a){this.standardMouseSelect();var b=this.Yo;null!==b&&(b.canMove()||b.canCopy())&&(this.ia=!0,this.Bo.set(a.position),df(this,a.selection),this.Nl=this.zv=null,this.qc=this.computeEffectiveCollection(a.selection),a.yo=!0,ef(this,this.qc),this.uc("Drag"),this.jj=a.Nc.ea,a.Kd=!0,a.Ms&&(this.pt=!0,this.Iq=!1,ff=this,gf=this.h,this.doSimulatedDragOut()))}};
function df(a,b){if(a.Zh){var c=a.h;null!==c&&c.Sj&&(c.ba instanceof M&&1===b.count&&b.$a()instanceof T?(a.Mc=b.$a(),a.Mc.canRelinkFrom()&&a.Mc.canRelinkTo()&&a.Mc.Wi(),a.Ki=c.wb.SA,null===a.Ki&&(a.Ki=new hf,a.Ki.h=c)):(a.Mc=null,a.Ki=null))}}
cf.prototype.computeEffectiveCollection=function(a){var b=null!==this.h&&this.h.Ua===this,c=new ia(C,Object);if(null===a)return c;for(var d=a.k;d.next();){var f=d.value;jf(this,c,f,b)}if(null!==this.Mc&&this.Zh)return c;for(d=a.k;d.next();)f=d.value,f instanceof T&&(a=f,b=a.aa,null===b||c.contains(b)?(b=a.ca,null===b||c.contains(b)||c.remove(a)):c.remove(a));return c};function kf(a){return void 0===a?{point:F.mj,tz:F.mj}:{point:a.copy(),tz:F.mj}}
function jf(a,b,c,d){if(!b.contains(c)&&(!d||c.canMove()||c.canCopy()))if(c instanceof R){b.add(c,kf(c.location));if(c instanceof S)for(var f=c.Rc;f.next();){var g=f.value;jf(a,b,g,d)}for(g=c.qe;g.next();)if(f=g.value,!b.contains(f)){var h=f.aa,k=f.ca;null!==h&&b.contains(h)&&null!==k&&b.contains(k)&&jf(a,b,f,d)}if(a.vE)for(c=c.BE();c.next();)g=c.value,jf(a,b,g,d)}else if(c instanceof T)for(f=c,b.add(f,kf()),g=f.fk;g.next();)jf(a,b,g.value,d);else c instanceof Je||b.add(c,kf(c.location))}
cf.prototype.doDeactivate=function(){this.ia=!1;var a=this.h;null!==a&&tf(a);uf(this);vf(this,this.qc);this.qc=null;this.Iq=this.pt=!1;if(0<wf.count){for(var b=wf.length,c=0;c<b;c++){var d=wf.n[c];xf(d);yf(d);uf(d);null!==d.h&&tf(d.h)}wf.clear()}xf(this);this.Bo.Sp(NaN,NaN);ff=gf=null;yf(this);a.Kd=!1;a.$b="";a.yo=!1;this.hk()};
function uf(a){var b=a.h;if(null!==b){var c=b.Wa;b.Wa=!0;for(var d=b.R,f=a.Nl;null!==f;){var g=f.CA;if(null!==g&&(g(d,f,null),d.Ee))break;f=f.ja}b.Wa=c}a.zv=null;a.Nl=null}function zf(){var a=ff;yf(a);Af(a);var b=a.h;null!==b&&a.Bo.N()&&(b.position=a.Bo);null!==b&&tf(b)}cf.prototype.doCancel=function(){yf(this);Af(this);var a=this.h;null!==a&&this.Bo.N()&&(a.position=this.Bo);this.stopTool()};function ef(a,b){if(null!==b){a.Ov=!0;for(var c=b.k;c.next();){var d=c.key;d instanceof T&&(d.Wp=!0)}}}
function vf(a,b){if(null!==b){for(var c=b.k;c.next();){var d=c.key;d instanceof T&&(d.Wp=!1,d.dj&&Bf(d)&&d.ac())}a.Ov=!1}}cf.prototype.doKeyDown=function(){var a=this.h;null!==a&&(a=a.R,null!==a&&this.ia&&("Esc"===a.key?this.doCancel():this.doMouseMove()))};cf.prototype.doKeyUp=function(){var a=this.h;null!==a&&null!==a.R&&this.ia&&this.doMouseMove()};
function Cf(a,b){for(var c=Infinity,d=Infinity,f=-Infinity,g=-Infinity,h=a.k;h.next();){var k=h.value;if(k.Ld()&&k.La()){var l=k.location,k=l.x,l=l.y;isNaN(k)||isNaN(l)||(k<c&&(c=k),l<d&&(d=l),k>f&&(f=k),l>g&&(g=l))}}Infinity===c?b.p(0,0,0,0):b.p(c,d,f-c,g-d)}
function Df(a,b){if(null===a.Dc){var c=a.h;if(!(null===c||b&&(c.ab||c.Ze))&&null!==a.qc){var d=c.la;d.isEnabled&&d.dF?null!==d.Zi&&0<d.Zi.dh.count&&(c.la.Np(),c.uc("Drag")):Af(a);c.Wa=!b;c.un=!b;a.jj=c.Nc.ea;d=a.cE?a.qc.Fl():c.selection;c=c.Wo(d,c,!0);for(d=c.k;d.next();)d.value.location=d.key.location;d=t.Gf();Cf(c,d);t.hc(d);for(var d=new ia(C,Object),f=a.qc.k;f.next();){var g=f.key;g.Ld()&&g.canCopy()&&(g=c.za(g),null!==g&&(g.sf(),d.add(g,kf(g.location))))}for(c=c.k;c.next();)f=c.value,f instanceof
T&&f.canCopy()&&d.add(f,kf());a.Dc=d;df(a,d.Fl());null!==a.Mc&&(c=a.Mc,d=c.uj(),c.ql(a.jj.x-(d.x+d.width/2),a.jj.y-(d.y+d.height/2)))}}}function yf(a){var b=a.h;if(null!==b){if(null!==a.Dc&&(b.VA(a.Dc.Fl(),!1),a.Dc=null,null!==a.qc))for(var c=a.qc.k;c.next();)c.key instanceof T&&(c.value.point=new w(0,0));b.Wa=!1;b.un=!1;a.jj=b.Nc.ea}}function xf(a){if(null!==a.Mc){if(a.Zh&&null!==a.Ki){var b=a.Ki;b.h.remove(b.de);b.h.remove(b.ee)}a.Mc=null;a.Ki=null}}
function Ef(a,b,c){var d=a.h;if(null!==d){var f=a.jj,g=t.K();g.assign(d.R.ea);a.moveParts(b,g.Vt(f),c);t.B(g)}}
cf.prototype.moveParts=function(a,b,c){if(null!==a&&(t.l(a,ia,cf,"moveParts:parts"),0!==a.count)){var d=t.K(),f=t.K();f.assign(b);isNaN(f.x)&&(f.x=0);isNaN(f.y)&&(f.y=0);var g=this.Ov;g||ef(this,a);for(var h=new B,k=new B(Ea),l=a.k;l.next();){var m=l.key;if(m.Ld()){var n=Ff(this,m,a);if(null!==n)h.add({Fc:m,info:l.value,LI:n});else if(!c||m.canMove()){n=l.value.point;d.assign(n);var p=t.K();this.computeMove(m,d.add(f),a,p);m.location=p;t.B(p);l.value.tz=p.Vt(n)}}else l.key instanceof T&&k.add(l.yb)}for(c=
h.k;c.next();)h=c.value,n=h.info.point,d.assign(n),h.Fc.location=d.add(h.LI.tz);n=t.K();c=t.K();for(k=k.k;k.next();)if(p=k.value,h=p.key,h instanceof T)if(h.Wp)if(l=h.aa,m=h.ca,null!==this.Mc&&this.Zh)p=p.value.point,a.add(h,kf(f)),l=b.x-p.x,m=b.y-p.y,h.ql(l,m);else{if(null!==l){n.assign(l.location);var q=a.za(l);null!==q&&n.Vt(q.point)}null!==m&&(c.assign(m.location),q=a.za(m),null!==q&&c.Vt(q.point));null!==l&&null!==m?n.tf(c)?(p=p.value.point,l=d,l.assign(n),l.Vt(p),a.add(h,kf(n)),h.ql(l.x,l.y)):
(h.Wp=!1,h.ac()):(p=p.value.point,a.add(h,kf(null!==l?n:null!==m?c:b)),l=f.x-p.x,m=f.y-p.y,h.ql(l,m))}else if(null===h.aa||null===h.ca)p=p.value.point,a.add(h,kf(b)),l=f.x-p.x,m=f.y-p.y,h.ql(l,m);t.B(d);t.B(f);t.B(n);t.B(c);g||vf(this,a)}};function Ff(a,b,c){b=b.hb;if(null!==b){a=Ff(a,b,c);if(null!==a)return a;a=c.za(b);if(null!==a)return a}return null}
function Af(a){if(null!==a.qc){for(var b=a.h,c=a.qc.k;c.next();){var d=c.key;d.Ld()&&(d.location=c.value.point)}for(c=a.qc.k;c.next();)if(d=c.key,d instanceof T&&d.Wp){var f=c.value.point;a.qc.add(d,kf());d.ql(-f.x,-f.y)}b.Uf()}}
cf.prototype.computeMove=function(a,b,c,d){void 0===d&&(d=new w);d.assign(b);if(null===a)return d;void 0===c&&(c=null);var f=b;if(this.qp&&(this.cF||null===c||this.h.R.lj)&&(f=t.K(),c=f,c.assign(b),null!==a)){var g=this.h;if(null!==g){var h=g.lp,k=this.lA,g=k.width,k=k.height,l=this.ME,m=l.x,l=l.y,n=this.LE;if(null!==h){var p=h.ht;isNaN(g)&&(g=p.width);isNaN(k)&&(k=p.height);h=h.kA;isNaN(m)&&(m=h.x);isNaN(l)&&(l=h.y)}h=t.mc(0,0);h.Rt(0,0,g,k,n);F.dt(b.x,b.y,m+h.x,l+h.y,g,k,c);t.B(h)}}c=null!==a.aA?
a.aA(a,b,f):f;k=a.zF;g=k.x;isNaN(g)&&(g=a.location.x);k=k.y;isNaN(k)&&(k=a.location.y);h=a.tF;m=h.x;isNaN(m)&&(m=a.location.x);h=h.y;isNaN(h)&&(h=a.location.y);d.p(Math.max(g,Math.min(c.x,m)),Math.max(k,Math.min(c.y,h)));f!==b&&t.B(f);return d};function Gf(a,b){if(null===b)return!0;var c=b.S;return null===c||c instanceof Je||c.layer.rc||a.qc&&a.qc.contains(c)||a.Dc&&a.Dc.contains(c)?!0:!1}
function Hf(a,b,c,d){var f=a.h;if(null!==f){a.Zh&&(null!==a.Mc&&(a.Mc.aa=null,a.Mc.ca=null),If(a,!1));var g=!1;!1===a.Jv&&(g=f.Wa,f.Wa=!0);var h=!1,k=Jf(f,b,null,function(b){return!Gf(a,b)}),l=f.R;l.ce=k;var m=f.Wa;f.Wa=!0;if(k!==a.Nl){var n=a.Nl;a.zv=n;for(a.Nl=k;null!==n;){var p=n.CA;if(null!==p){if(k===n)break;if(null!==k&&k.ej(n))break;p(l,n,k);h=!0;if(l.Ee)break}n=n.ja}n=a.zv;if(!a.ia&&null===ff)return;for(;null!==k;){p=k.AF;if(null!==p){if(n===k)break;if(null!==n&&n.ej(k))break;p(l,k,n);h=!0;
if(l.Ee)break}k=k.ja}k=a.Nl;if(!a.ia&&null===ff)return}null===k&&(p=f.BF,null!==p&&(p(l),h=!0));if(a.ia||null!==ff)if(a.doDragOver(b,k),a.ia||null!==ff)f.Wa=m,h&&f.Uf(),!1===a.Jv&&(f.Wa=g),(f.of||f.pf)&&(c||d)&&Uf(f,l.Ke)}}function Vf(a,b,c){var d=a.Ki;if(null===d)return null;var f=a.h.Qm(b,d.LA,function(a){return d.findValidLinkablePort(a,c)});a=t.K();for(var g=Infinity,h=null,f=f.k;f.next();){var k=f.value;if(null!==k.S){var l=k.pb(Hb,a),l=b.Zj(l);l<g&&(h=k,g=l)}}t.B(a);return h}
function If(a,b){var c=a.Mc;if(null!==c&&!(2>c.pa)){var d=a.h;if(null!==d&&!d.ab&&(d=a.Ki,null!==d)){var f=null,g=null;null===c.aa&&(f=Vf(a,c.o(0),!1),null!==f&&(g=f.S));var h=null,k=null;null===c.ca&&(h=Vf(a,c.o(c.pa-1),!0),null!==h&&(k=h.S));if((null===g||g instanceof R)&&(null===k||k instanceof R)){var l=d.isValidLink(g,f,k,h);b?(c.Fn=c.o(0).copy(),c.Jn=c.o(c.pa-1).copy(),c.Wp=!1,c.aa=g,null!==f&&(c.Rf=f.Nd),c.ca=k,null!==h&&(c.Og=h.Nd)):l?Wf(d,g,f,k,h):Wf(d,null,null,null,null)}}}}
cf.prototype.doDragOver=function(){};function Xf(a,b){var c=a.h;if(null!==c&&null!==c.ba){a.Zh&&If(a,!0);uf(a);var d=Jf(c,b,null,function(b){return!Gf(a,b)}),f=c.R;f.ce=d;if(null!==d)for(var g=d;null!==g;){var h=g.wt;if(null!==h&&(h(f,g),f.Ee))break;g=g.ja}else h=c.wt,null!==h&&h(f);if(a.ia||null!==ff)if(a.doDropOnto(b,d),a.ia||null!==ff)for(d=c.selection.k;d.next();)f=d.value,f instanceof R&&Yf(c,f.oa)}}cf.prototype.doDropOnto=function(){};
cf.prototype.doMouseMove=function(){if(this.ia){var a=this.h;if(null!==a&&null!==this.Yo&&null!==this.qc){var b=!1,c=!1;this.mayCopy()?(b=!0,a.$b="copy",Df(this,!1),ef(this,this.Dc),Ef(this,this.Dc,!1),vf(this,this.Dc)):this.mayMove()?(c=!0,a.$b="default",yf(this),Ef(this,this.qc,!0)):this.mayDragOut()?(a.$b="no-drop",Df(this,!1),Ef(this,this.Dc,!1)):yf(this);Hf(this,a.R.ea,c,b)}}};
cf.prototype.doMouseUp=function(){if(this.ia){var a=this.h;if(null!==a){var b=!1,c=this.mayCopy();c&&null!==this.Dc?(yf(this),Df(this,!0),ef(this,this.Dc),Ef(this,this.Dc,!1),vf(this,this.Dc),null!==this.Dc&&a.gG(this.Dc.Fl())):(b=!0,yf(this),this.mayMove()&&(Ef(this,this.qc,!0),this.Jv=!1,Hf(this,a.R.ea,!0,!1),this.Jv=!0));this.Iq=!0;Xf(this,a.R.ea);if(this.ia){this.Dc=null;if(b&&null!==this.qc)for(b=this.qc.k;b.next();){var d=b.key;d instanceof R&&(d=d.hb,null===d||null===d.placeholder||this.qc.contains(d)||
d.Vz&&d.ha())}a.Oc();vf(this,this.qc);this.gf=c?"Copy":"Move";a.Ba(c?"SelectionCopied":"SelectionMoved",a.selection)}this.stopTool()}}};cf.prototype.mayCopy=function(){if(!this.qA)return!1;var a=this.h;if(null===a||a.ab||a.Ze||!a.Bm||!a.Si||(t.Vm?!a.R.alt:!a.R.control))return!1;for(a=a.selection.k;a.next();){var b=a.value;if(b.Ld()&&b.canCopy())return!0}return null!==this.Mc&&this.Zh&&this.Mc.canCopy()?!0:!1};
cf.prototype.mayDragOut=function(){if(!this.qA)return!1;var a=this.h;if(null===a||!a.Ms||!a.Si||a.Rj)return!1;for(a=a.selection.k;a.next();){var b=a.value;if(b.Ld()&&b.canCopy())return!0}return null!==this.Mc&&this.Zh&&this.Mc.canCopy()?!0:!1};cf.prototype.mayMove=function(){var a=this.h;if(null===a||a.ab||!a.Rj)return!1;for(a=a.selection.k;a.next();){var b=a.value;if(b.Ld()&&b.canMove())return!0}return null!==this.Mc&&this.Zh&&this.Mc.canMove()?!0:!1};var wf=new B(cf),ff=null,gf=null;
cf.prototype.mayDragIn=function(){var a=this.h;if(null===a||!a.Kz||a.ab||a.Ze||!a.Bm)return!1;var b=ff;return null===b||b.h.ba.Wj!==a.ba.Wj?!1:!0};cf.prototype.doSimulatedDragEnter=function(){if(this.mayDragIn()){var a=this.h;a.Ub.Al();Zf(a);a=ff;null!==a&&(a.h.$b="copy")}};cf.prototype.doSimulatedDragLeave=function(){var a=ff;null!==a&&a.doSimulatedDragOut();this.doCancel()};
cf.prototype.doSimulatedDragOver=function(){var a=this.h;if(null!==a){var b=ff;null!==b&&null!==b.qc&&this.mayDragIn()&&(a.$b="copy",$f(this,b.qc.Fl(),!1),Ef(this,this.Dc,!1),Hf(this,a.R.ea,!1,!0))}};
cf.prototype.doSimulatedDrop=function(){var a=this.h;if(null!==a){var b=ff;null!==b&&(b.Iq=!0,yf(this),this.mayDragIn()&&(this.uc("Drop"),$f(this,b.qc.Fl(),!0),Ef(this,this.Dc,!1),null!==this.Dc&&a.gG(this.Dc.Fl()),this.gf="ExternalCopy",Xf(this,a.R.ea),a.Oc(),this.Dc=null,a.Ba("ExternalObjectsDropped",a.selection),this.hk()))}};
function $f(a,b,c){if(null===a.Dc){var d=a.h;if(null!==d&&!d.ab&&!d.Ze&&null!==d.ba){d.Wa=!c;d.un=!c;a.jj=d.R.ea;d=d.Wo(b,d,!0);c=t.Gf();Cf(b,c);var f=c.x+c.width/2,g=c.y+c.height/2;t.hc(c);var h=a.Lv;c=new ia(C,Object);var k=t.K();for(b=b.k;b.next();){var l=b.value;if(l.Ld()&&l.canCopy()){var m=l.location,l=d.za(l);k.p(h.x-(f-m.x),h.y-(g-m.y));l.location=k;l.sf();c.add(l,kf(k))}}t.B(k);for(d=d.k;d.next();)f=d.value,f instanceof T&&f.canCopy()&&c.add(f,kf());a.Dc=c;df(a,c.Fl());null!==a.Mc&&(c=a.Mc,
d=c.uj(),c.ql(a.jj.x-(d.x+d.width/2),a.jj.y-(d.y+d.height/2)))}}}cf.prototype.doSimulatedDragOut=function(){var a=this.h;null!==a&&(this.mayCopy()||this.mayMove()?a.$b="":a.$b="no-drop")};
function ag(){0<arguments.length&&t.ad(ag);ae.call(this);this.XC=100;this.wC=!1;var a=new T,b=new V;b.fj=!0;b.stroke="blue";a.add(b);b=new V;b.on="Standard";b.fill="blue";b.stroke="blue";a.add(b);a.af="Tool";this.rD=a;a=new R;b=new V;b.Nd="";b.Ab="Rectangle";b.fill=null;b.stroke="magenta";b.fb=2;b.Ca=F.Ux;a.add(b);a.wl=!1;a.af="Tool";this.pD=a;this.qD=b;a=new R;b=new V;b.Nd="";b.Ab="Rectangle";b.fill=null;b.stroke="magenta";b.fb=2;b.Ca=F.Ux;a.add(b);a.wl=!1;a.af="Tool";this.sD=a;this.tD=b;this.VC=
this.UC=this.QC=this.PC=this.RC=null;this.sC=!0;this.xH=new ia(Q,"boolean");this.YC=this.Lk=this.lD=null}t.ga("LinkingBaseTool",ag);t.Ka(ag,ae);ag.prototype.doStop=function(){var a=this.h;null!==a&&tf(a);this.Kg=this.Jg=this.Ig=this.Hg=this.sc=null;this.Cx.clear();this.Ff=null};t.g(ag,"portGravity",ag.prototype.LA);t.defineProperty(ag,{LA:"portGravity"},function(){return this.XC},function(a){t.i(a,"number",ag,"portGravity");0<=a&&(this.XC=a)});t.g(ag,"isUnconnectedLinkValid",ag.prototype.Wm);
t.defineProperty(ag,{Wm:"isUnconnectedLinkValid"},function(){return this.wC},function(a){t.i(a,"boolean",ag,"isUnconnectedLinkValid");this.wC=a});t.g(ag,"temporaryLink",ag.prototype.Zf);t.defineProperty(ag,{Zf:"temporaryLink"},function(){return this.rD},function(a){t.l(a,T,ag,"temporaryLink");this.rD=a});t.g(ag,"temporaryFromNode",ag.prototype.de);t.defineProperty(ag,{de:"temporaryFromNode"},function(){return this.pD},function(a){t.l(a,R,ag,"temporaryFromNode");this.pD=a});
t.g(ag,"temporaryFromPort",ag.prototype.Dl);t.defineProperty(ag,{Dl:"temporaryFromPort"},function(){return this.qD},function(a){t.l(a,Q,ag,"temporaryFromPort");this.qD=a});t.g(ag,"temporaryToNode",ag.prototype.ee);t.defineProperty(ag,{ee:"temporaryToNode"},function(){return this.sD},function(a){t.l(a,R,ag,"temporaryToNode");this.sD=a});t.g(ag,"temporaryToPort",ag.prototype.El);t.defineProperty(ag,{El:"temporaryToPort"},function(){return this.tD},function(a){t.l(a,Q,ag,"temporaryToPort");this.tD=a});
t.g(ag,"originalLink",ag.prototype.sc);t.defineProperty(ag,{sc:"originalLink"},function(){return this.RC},function(a){null!==a&&t.l(a,T,ag,"originalLink");this.RC=a});t.g(ag,"originalFromNode",ag.prototype.Hg);t.defineProperty(ag,{Hg:"originalFromNode"},function(){return this.PC},function(a){null!==a&&t.l(a,R,ag,"originalFromNode");this.PC=a});t.g(ag,"originalFromPort",ag.prototype.Ig);
t.defineProperty(ag,{Ig:"originalFromPort"},function(){return this.QC},function(a){null!==a&&t.l(a,Q,ag,"originalFromPort");this.QC=a});t.g(ag,"originalToNode",ag.prototype.Jg);t.defineProperty(ag,{Jg:"originalToNode"},function(){return this.UC},function(a){null!==a&&t.l(a,R,ag,"originalToNode");this.UC=a});t.g(ag,"originalToPort",ag.prototype.Kg);t.defineProperty(ag,{Kg:"originalToPort"},function(){return this.VC},function(a){null!==a&&t.l(a,Q,ag,"originalToPort");this.VC=a});
t.defineProperty(ag,{Xd:"isForwards"},function(){return this.sC},function(a){t.i(a,"boolean",ag,"isForwards");this.sC=a});t.A(ag,{Cx:"validPortsCache"},function(){return this.xH});t.g(ag,"targetPort",ag.prototype.Ff);t.defineProperty(ag,{Ff:"targetPort"},function(){return this.lD},function(a){null!==a&&t.l(a,Q,ag,"targetPort");this.lD=a});
ag.prototype.copyPortProperties=function(a,b,c,d,f){if(null!==a&&null!==b&&null!==c&&null!==d){d.Ca=b.oa.size;f?(d.rb=b.rb,d.jk=b.jk):(d.ob=b.ob,d.dk=b.dk);c.bf=Hb;var g=t.K();c.location=b.pb(Hb,g);t.B(g);d.angle=b.hl();null!==this.Gt&&this.Gt(a,b,c,d,f)}};ag.prototype.setNoTargetPortProperties=function(a,b,c){null!==b&&(b.Ca=F.Ux,b.ob=wb,b.rb=wb);null!==a&&(a.location=this.h.R.ea);null!==this.Gt&&this.Gt(null,null,a,b,c)};ag.prototype.doMouseDown=function(){this.ia&&this.doMouseMove()};
ag.prototype.doMouseMove=function(){if(this.ia){var a=this.h;if(null!==a){this.Ff=this.findTargetPort(this.Xd);if(null!==this.Ff){var b=this.Ff.S;if(b instanceof R){this.Xd?this.copyPortProperties(b,this.Ff,this.ee,this.El,!0):this.copyPortProperties(b,this.Ff,this.de,this.Dl,!1);return}}this.Xd?this.setNoTargetPortProperties(this.ee,this.El,!0):this.setNoTargetPortProperties(this.de,this.Dl,!1);(a.of||a.pf)&&Uf(a,a.R.Ke)}}};
ag.prototype.findValidLinkablePort=function(a,b){if(null===a)return null;var c=a.S;if(!(c instanceof R))return null;for(;null!==a;){var d=b?a.jB:a.hA;if(!0===d&&(null!==a.Nd||a instanceof R)&&(b?this.isValidTo(c,a):this.isValidFrom(c,a)))return a;if(!1===d)break;a=a.ja}return null};
ag.prototype.findTargetPort=function(a){var b=this.h,c=b.R.ea,d=this.LA;0>=d&&(d=0.1);for(var f=this,g=b.Qm(c,d,function(b){return f.findValidLinkablePort(b,a)},null,!0),d=Infinity,b=null,g=g.k;g.next();){var h=g.value,k=h.S;if(k instanceof R){var l=h.pb(Hb,t.K()),m=c.x-l.x,n=c.y-l.y;t.B(l);l=m*m+n*n;l<d&&(m=this.Cx.za(h),null!==m?m&&(b=h,d=l):a&&this.isValidLink(this.Hg,this.Ig,k,h)||!a&&this.isValidLink(k,h,this.Jg,this.Kg)?(this.Cx.add(h,!0),b=h,d=l):this.Cx.add(h,!1))}}return null!==b&&(c=b.S,
c instanceof R&&(null===c.layer||c.layer.Cm))?b:null};ag.prototype.isValidFrom=function(a,b){if(null===a||null===b)return this.Wm;if(this.h.Ua===this&&(null!==a.layer&&!a.layer.Cm||!0!==b.hA))return!1;var c=b.FE;if(Infinity>c){if(null!==this.sc&&a===this.Hg&&b===this.Ig)return!0;var d=b.Nd;null===d&&(d="");if(a.Cw(d).count>=c)return!1}return!0};
ag.prototype.isValidTo=function(a,b){if(null===a||null===b)return this.Wm;if(this.h.Ua===this&&(null!==a.layer&&!a.layer.Cm||!0!==b.jB))return!1;var c=b.CG;if(Infinity>c){if(null!==this.sc&&a===this.Jg&&b===this.Kg)return!0;var d=b.Nd;null===d&&(d="");if(a.wg(d).count>=c)return!1}return!0};ag.prototype.isInSameNode=function(a,b){if(null===a||null===b)return!1;if(a===b)return!0;var c=a.S,d=b.S;return null!==c&&c===d};
ag.prototype.isLinked=function(a,b){if(null===a||null===b)return!1;var c=a.S;if(!(c instanceof R))return!1;var d=a.Nd;null===d&&(d="");var f=b.S;if(!(f instanceof R))return!1;var g=b.Nd;null===g&&(g="");for(f=f.wg(g);f.next();)if(g=f.value,g.aa===c&&g.Rf===d)return!0;return!1};
ag.prototype.isValidLink=function(a,b,c,d){if(!this.isValidFrom(a,b)||!this.isValidTo(c,d)||!(null===b||null===d||(b.EE&&d.BG||!this.isInSameNode(b,d))&&(b.DE&&d.AG||!this.isLinked(b,d)))||null!==this.sc&&(null!==a&&bg(this,a,this.sc)||null!==c&&bg(this,c,this.sc))||null!==a&&null!==c&&(null===a.data&&null!==c.data||null!==a.data&&null===c.data)||!cg(this,a,c,this.sc))return!1;if(null!==a){var f=a.Ap;if(null!==f&&!f(a,b,c,d,this.sc))return!1}if(null!==c&&(f=c.Ap,null!==f&&!f(a,b,c,d,this.sc)))return!1;
f=this.Ap;return null!==f?f(a,b,c,d,this.sc):!0};function bg(a,b,c){if(null===b)return!1;var d=b.$d;if(null===d)return!1;if(d===c)return!0;var f=new ma(R);f.add(b);return dg(a,d,c,f)}function dg(a,b,c,d){if(b===c)return!0;var f=b.aa;if(null!==f&&f.bi&&(d.add(f),dg(a,f.$d,c,d)))return!0;b=b.ca;return null!==b&&b.bi&&(d.add(b),dg(a,b.$d,c,d))?!0:!1}
function cg(a,b,c,d){if(null===b||null===c)return a.Wm;var f=a.h.QG;if(f!==eg){if(f===fg){if(null!==d&&!d.Ec)return!0;for(f=c.qe;f.next();){var g=f.value;if(g!==d&&g.Ec&&g.ca===c)return!1}return!mg(a,b,c,d,!0)}if(f===ng){if(null!==d&&!d.Ec)return!0;for(f=b.qe;f.next();)if(g=f.value,g!==d&&g.Ec&&g.aa===b)return!1;return!mg(a,b,c,d,!0)}if(f===og)return b===c?a=!0:(f=new ma(R),f.add(c),a=pg(a,f,b,c,d)),!a;if(f===qg)return!mg(a,b,c,d,!1);if(f===rg)return b===c?a=!0:(f=new ma(R),f.add(c),a=sg(a,f,b,c,
d)),!a}return!0}function mg(a,b,c,d,f){if(b===c)return!0;if(null===b||null===c)return!1;for(var g=b.qe;g.next();){var h=g.value;if(h!==d&&(!f||h.Ec)&&h.ca===b&&(h=h.aa,h!==b&&mg(a,h,c,d,f)))return!0}return!1}function pg(a,b,c,d,f){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var g=c.qe;g.next();){var h=g.value;if(h!==f&&h.ca===c&&(h=h.aa,h!==c&&pg(a,b,h,d,f)))return!0}return!1}
function sg(a,b,c,d,f){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var g=c.qe;g.next();){var h=g.value;if(h!==f){var k=h.aa,h=h.ca,k=k===c?h:k;if(k!==c&&sg(a,b,k,d,f))return!0}}return!1}t.g(ag,"linkValidation",ag.prototype.Ap);t.defineProperty(ag,{Ap:"linkValidation"},function(){return this.Lk},function(a){null!==a&&t.i(a,"function",ag,"linkValidation");this.Lk=a});t.g(ag,"portTargeted",ag.prototype.Gt);
t.defineProperty(ag,{Gt:"portTargeted"},function(){return this.YC},function(a){null!==a&&t.i(a,"function",ag,"portTargeted");this.YC=a});function qa(){0<arguments.length&&t.ad(qa);ag.call(this);this.name="Linking";this.ly={};this.ky=null;this.sa=tg;this.uz=this.hD=null}t.ga("LinkingTool",qa);t.Ka(qa,ag);var tg;qa.Either=tg=t.w(qa,"Either",0);var ug;qa.ForwardsOnly=ug=t.w(qa,"ForwardsOnly",0);var vg;qa.BackwardsOnly=vg=t.w(qa,"BackwardsOnly",0);t.g(qa,"archetypeLinkData",qa.prototype.KD);
t.defineProperty(qa,{KD:"archetypeLinkData"},function(){return this.ly},function(a){null!==a&&t.l(a,Object,qa,"archetypeLinkData");a instanceof Q&&t.l(a,T,qa,"archetypeLinkData");this.ly=a});t.g(qa,"archetypeLabelNodeData",qa.prototype.Oz);t.defineProperty(qa,{Oz:"archetypeLabelNodeData"},function(){return this.ky},function(a){null!==a&&t.l(a,Object,qa,"archetypeLabelNodeData");a instanceof Q&&t.l(a,R,qa,"archetypeLabelNodeData");this.ky=a});t.g(qa,"direction",qa.prototype.direction);
t.defineProperty(qa,{direction:"direction"},function(){return this.sa},function(a){t.Aa(a,qa,qa,"direction");this.sa=a});t.g(qa,"startObject",qa.prototype.fB);t.defineProperty(qa,{fB:"startObject"},function(){return this.hD},function(a){null!==a&&t.l(a,Q,qa,"startObject");this.hD=a});t.A(qa,{yx:"startPort"},function(){return this.uz});
qa.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;if(null===a||a.ab||a.Ze||!a.Cm)return!1;var b=a.ba;return null!==b&&(b instanceof M||b instanceof Kd)&&a.R.left&&(a.Ua===this||this.isBeyondDragSize())?null!==this.findLinkablePort():!1};
qa.prototype.findLinkablePort=function(){var a=this.h;if(null===a)return null;var b=this.fB;null===b&&(b=a.pe(a.Nc.ea,null,null));if(null===b||!(b.S instanceof R))return null;var c=this.direction;if(c===tg||c===ug)if(a=this.findValidLinkablePort(b,!1),null!==a)return this.Xd=!0,a;if(c===tg||c===vg)if(a=this.findValidLinkablePort(b,!0),null!==a)return this.Xd=!1,a;return null};
qa.prototype.doActivate=function(){var a=this.h;if(null!==a&&(null===this.yx&&(this.uz=this.findLinkablePort()),null!==this.yx)){this.uc(this.name);a.Kd=!0;a.$b="pointer";if(this.Xd){this.Ig=this.yx;var b=this.Ig.S;b instanceof R&&(this.Hg=b);this.copyPortProperties(this.Hg,this.Ig,this.de,this.Dl,!1)}else this.Kg=this.yx,b=this.Kg.S,b instanceof R&&(this.Jg=b),this.copyPortProperties(this.Jg,this.Kg,this.ee,this.El,!0);a.add(this.de);a.add(this.ee);null!==this.Zf&&(null!==this.de&&(this.Zf.aa=this.de),
null!==this.ee&&(this.Zf.ca=this.ee),this.Zf.ac(),a.add(this.Zf));this.ia=!0}};qa.prototype.doDeactivate=function(){this.ia=!1;var a=this.h;null!==a&&(a.remove(this.Zf),a.remove(this.de),a.remove(this.ee),a.Kd=!1,a.$b="",this.hk())};qa.prototype.doStop=function(){ag.prototype.doStop.call(this);this.fB=this.uz=null};
qa.prototype.doMouseUp=function(){if(this.ia){var a=this.h;if(null===a)return;var b=this.gf=null,c=null,d=null,f=null,g=this.Ff=this.findTargetPort(this.Xd),h=null;null!==g?(h=g.S,h instanceof R&&(this.Xd?(null!==this.Hg&&(b=this.Hg,c=this.Ig),d=h,f=g):(b=h,c=g,null!==this.Jg&&(d=this.Jg,f=this.Kg)))):this.Xd?null!==this.Hg&&this.Wm&&(b=this.Hg,c=this.Ig):null!==this.Jg&&this.Wm&&(d=this.Jg,f=this.Kg);if(null!==b||null!==d)b=this.insertLink(b,c,d,f),null!==b?(null===g&&(this.Xd?b.Jn=a.R.ea.copy():
b.Fn=a.R.ea.copy()),a.Pe&&a.select(b),this.gf=this.name,a.Ba("LinkDrawn",b)):a.ba.UD()}this.stopTool()};
qa.prototype.insertLink=function(a,b,c,d){var f=this.h;if(null===f)return null;var g=f.ba;if(null===g)return null;if(g instanceof Kd){var h=a;b=c;f.pd||(h=c,b=a);if(null!==h&&null!==b)return g.lh(b.data,g.Hb(h.data)),b.ft()}else if(g instanceof M)if(h="",null!==a&&(null===b&&(b=a),h=b.Nd,null===h&&(h="")),b="",null!==c&&(null===d&&(d=c),b=d.Nd,null===b&&(b="")),d=this.KD,d instanceof T){if(Le(d),g=d.copy(),g instanceof T)return g.aa=a,g.Rf=h,g.ca=c,g.Og=b,f.add(g),a=this.Oz,a instanceof R&&(Le(a),
a=a.copy(),a instanceof R&&(a.$d=g,f.add(a))),g}else if(null!==d&&(d=g.eE(d),t.qb(d)))return null!==a&&g.ux(d,g.Hb(a.data)),g.$A(d,h),null!==c&&g.wx(d,g.Hb(c.data)),g.cB(d,b),g.aw(d),a=this.Oz,null===a||a instanceof R||(a=g.copyNodeData(a),t.qb(a)&&(g.Am(a),a=g.Hb(a),void 0!==a&&g.GD(d,a))),g=f.Qf(d);return null};
function hf(){0<arguments.length&&t.ad(hf);ag.call(this);this.name="Relinking";var a=new V;a.Ab="Diamond";a.Ca=F.Nx;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.Cf=0;this.hC=a;a=new V;a.Ab="Diamond";a.Ca=F.Nx;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.Cf=-1;this.uD=a;this.dc=null;this.SC=new x}t.ga("RelinkingTool",hf);t.Ka(hf,ag);
hf.prototype.updateAdornments=function(a){if(null!==a&&a instanceof T){var b="RelinkFrom",c=null;if(a.eb&&!this.h.ab){var d=a.Pt;null!==d&&a.canRelinkFrom()&&a.oa.N()&&a.La()&&d.oa.N()&&d.pl()&&(c=a.bp(b),null===c&&(c=this.makeAdornment(d,!1),null!==c&&(c.Zc=b),a.Wk(b,c)))}null===c&&a.vl(b);b="RelinkTo";c=null;a.eb&&!this.h.ab&&(d=a.Pt,null!==d&&a.canRelinkTo()&&a.oa.N()&&a.La()&&d.oa.N()&&d.pl()&&(c=a.bp(b),null===c&&(c=this.makeAdornment(d,!0),null!==c&&(c.Zc=b),a.Wk(b,c))));null===c&&a.vl(b)}};
hf.prototype.makeAdornment=function(a,b){var c=new Je;c.type=wg;var d=b?this.aK:this.GI;null!==d&&c.add(d.copy());c.pc=a;return c};t.defineProperty(hf,{GI:"fromHandleArchetype"},function(){return this.hC},function(a){null!==a&&t.l(a,Q,hf,"fromHandleArchetype");this.hC=a});t.defineProperty(hf,{aK:"toHandleArchetype"},function(){return this.uD},function(a){null!==a&&t.l(a,Q,hf,"toHandleArchetype");this.uD=a});t.A(hf,{handle:"handle"},function(){return this.dc});
hf.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;if(null===a||a.ab||a.Ze||!a.Sj)return!1;var b=a.ba;if(null===b||!(b instanceof M||b instanceof Kd)||!a.R.left)return!1;b=this.findToolHandleAt(a.Nc.ea,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.Nc.ea,"RelinkTo"));return null!==b};
hf.prototype.doActivate=function(){var a=this.h;if(null!==a){if(null===this.sc){var b=this.findToolHandleAt(a.Nc.ea,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.Nc.ea,"RelinkTo"));if(null===b)return;var c=b.S;if(!(c instanceof Je&&c.Wh instanceof T))return;this.dc=b;this.Xd=null===c||"RelinkTo"===c.Zc;this.sc=c.Wh}this.uc(this.name);a.Kd=!0;a.$b="pointer";this.Ig=this.sc.ud;this.Hg=this.sc.aa;this.Kg=this.sc.fe;this.Jg=this.sc.ca;this.SC.set(this.sc.oa);null!==this.sc&&0<this.sc.pa&&(null===
this.sc.aa&&(null!==this.Dl&&(this.Dl.Ca=F.gq),null!==this.de&&(this.de.location=this.sc.o(0))),null===this.sc.ca&&(null!==this.El&&(this.El.Ca=F.gq),null!==this.ee&&(this.ee.location=this.sc.o(this.sc.pa-1))));this.copyPortProperties(this.Hg,this.Ig,this.de,this.Dl,!1);this.copyPortProperties(this.Jg,this.Kg,this.ee,this.El,!0);a.add(this.de);a.add(this.ee);null!==this.Zf&&(null!==this.de&&(this.Zf.aa=this.de),null!==this.ee&&(this.Zf.ca=this.ee),this.copyLinkProperties(this.sc,this.Zf),this.Zf.ac(),
a.add(this.Zf));this.ia=!0}};hf.prototype.copyLinkProperties=function(a,b){if(null!==a&&null!==b){b.Lo=a.Lo;b.sw=a.sw;var c=a.De;if(c===xg||c===yg)c=zg;b.De=c;b.Xs=a.Xs;b.Lt=a.Lt;b.mn=a.mn;b.ob=a.ob;b.dk=a.dk;b.hp=a.hp;b.ip=a.ip;b.rb=a.rb;b.jk=a.jk;b.Xp=a.Xp;b.Yp=a.Yp}};hf.prototype.doDeactivate=function(){this.ia=!1;var a=this.h;null!==a&&(a.remove(this.Zf),a.remove(this.de),a.remove(this.ee),a.Kd=!1,a.$b="",this.hk())};hf.prototype.doStop=function(){ag.prototype.doStop.call(this);this.dc=null};
hf.prototype.doMouseUp=function(){if(this.ia){var a=this.h;if(null===a)return;this.gf=null;var b=this.Hg,c=this.Ig,d=this.Jg,f=this.Kg,g=this.sc;this.Ff=this.findTargetPort(this.Xd);if(null!==this.Ff){var h=this.Ff.S;h instanceof R&&(this.Xd?(d=h,f=this.Ff):(b=h,c=this.Ff))}else this.Wm?this.Xd?f=d=null:c=b=null:g=null;null!==g&&(this.reconnectLink(g,this.Xd?d:b,this.Xd?f:c,this.Xd),null===this.Ff&&(this.Xd?g.Jn=a.R.ea.copy():g.Fn=a.R.ea.copy(),g.ac()),a.Pe&&(g.eb=!0),this.gf=this.name,a.Ba("LinkRelinked",
g,this.Xd?this.Kg:this.Ig));Ag(this.sc,this.SC)}this.stopTool()};hf.prototype.reconnectLink=function(a,b,c,d){if(null===this.h)return!1;c=null!==c&&null!==c.Nd?c.Nd:"";d?(a.ca=b,a.Og=c):(a.aa=b,a.Rf=c);return!0};function Wf(a,b,c,d,f){null!==b?(a.copyPortProperties(b,c,a.de,a.Dl,!1),a.h.add(a.de)):a.h.remove(a.de);null!==d?(a.copyPortProperties(d,f,a.ee,a.El,!0),a.h.add(a.ee)):a.h.remove(a.ee)}
function Bg(){0<arguments.length&&t.ad(Bg);ae.call(this);this.name="LinkReshaping";var a=new V;a.Ab="Rectangle";a.Ca=F.dq;a.fill="lightblue";a.stroke="dodgerblue";this.Fk=a;a=new V;a.Ab="Diamond";a.Ca=F.dq;a.fill="lightblue";a.stroke="dodgerblue";this.DC=a;this.ZC=3;this.iy=this.dc=null;this.TC=new w;this.mz=null}t.ga("LinkReshapingTool",Bg);t.Ka(Bg,ae);var Jg;Bg.None=Jg=t.w(Bg,"None",0);var Lg;Bg.Horizontal=Lg=t.w(Bg,"Horizontal",1);var Mg;Bg.Vertical=Mg=t.w(Bg,"Vertical",2);var Ng;
Bg.All=Ng=t.w(Bg,"All",3);function Og(a,b){t.l(a,Q,Bg,"setReshapingBehavior:obj");t.Aa(b,Bg,Bg,"setReshapingBehavior:behavior");a.Av=b}Bg.prototype.updateAdornments=function(a){if(null!==a&&a instanceof T){if(a.eb&&!this.h.ab){var b=a.path;if(null!==b&&a.canReshape()&&a.oa.N()&&a.La()&&b.oa.N()&&b.pl()){var c=a.bp(this.name);if(null===c||c.qH!==a.pa||c.zH!==a.Kp)c=this.makeAdornment(b),null!==c&&(c.qH=a.pa,c.zH=a.Kp,a.Wk(this.name,c));if(null!==c){c.location=a.position;return}}}a.vl(this.name)}};
Bg.prototype.makeAdornment=function(a){var b=a.S,c=b.pa,d=b.ic,f=null;if(null!==b.points&&1<c){f=new Je;f.type=wg;var c=b.gt,g=b.Ww,h=d?1:0;if(b.Kp&&b.De!==Pg)for(var k=c+h;k<g-h;k++){var l=this.makeResegmentHandle(a,k);null!==l&&(l.Cf=k,l.Nt=0.5,f.add(l))}for(k=c+1;k<g;k++)if(l=this.makeHandle(a,k),null!==l){l.Cf=k;if(k!==c)if(k===c+1&&d){var h=b.o(c),m=b.o(c+1);F.I(h.x,m.x)&&F.I(h.y,m.y)&&(m=b.o(c-1));F.I(h.x,m.x)?(Og(l,Mg),l.cursor="n-resize"):F.I(h.y,m.y)&&(Og(l,Lg),l.cursor="w-resize")}else k===
g-1&&d?(h=b.o(g-1),m=b.o(g),F.I(h.x,m.x)&&F.I(h.y,m.y)&&(h=b.o(g+1)),F.I(h.x,m.x)?(Og(l,Mg),l.cursor="n-resize"):F.I(h.y,m.y)&&(Og(l,Lg),l.cursor="w-resize")):k!==g&&(Og(l,Ng),l.cursor="move");f.add(l)}f.Zc=this.name;f.pc=a}return f};Bg.prototype.makeHandle=function(){var a=this.it;return null===a?null:a.copy()};t.defineProperty(Bg,{it:"handleArchetype"},function(){return this.Fk},function(a){null!==a&&t.l(a,Q,Bg,"handleArchetype");this.Fk=a});
Bg.prototype.makeResegmentHandle=function(){var a=this.oJ;return null===a?null:a.copy()};t.defineProperty(Bg,{oJ:"midHandleArchetype"},function(){return this.DC},function(a){null!==a&&t.l(a,Q,Bg,"midHandleArchetype");this.DC=a});t.A(Bg,{handle:"handle"},function(){return this.dc});t.A(Bg,{Ls:"adornedLink"},function(){return this.iy});Bg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;return null!==a&&!a.ab&&a.No&&a.R.left?null!==this.findToolHandleAt(a.Nc.ea,this.name):!1};
Bg.prototype.doActivate=function(){var a=this.h;if(null!==a&&(this.dc=this.findToolHandleAt(a.Nc.ea,this.name),null!==this.dc)){var b=this.dc.S.Wh;if(b instanceof T){this.iy=b;a.Kd=!0;this.uc(this.name);if(b.Kp&&0.5===this.dc.Nt){var c=b.points.copy(),d=this.dc.pb(Hb);c.Jd(this.dc.Cf+1,d);b.ic&&c.Jd(this.dc.Cf+1,d);b.points=c;b.Wi();b.updateAdornments();this.dc=this.findToolHandleAt(a.Nc.ea,this.name)}this.TC=b.o(this.dc.Cf);this.mz=b.points.copy();this.ia=!0}}};
Bg.prototype.doDeactivate=function(){this.hk();this.iy=this.dc=null;var a=this.h;null!==a&&(a.Kd=!1);this.ia=!1};Bg.prototype.doCancel=function(){var a=this.Ls;null!==a&&(a.points=this.mz);this.stopTool()};Bg.prototype.doMouseMove=function(){var a=this.h;this.ia&&null!==a&&(a=this.computeReshape(a.R.ea),this.reshape(a))};
Bg.prototype.doMouseUp=function(){var a=this.h;if(this.ia&&null!==a){var b=this.computeReshape(a.R.ea);this.reshape(b);b=this.Ls;if(null!==b&&b.Kp){var c=this.handle.Cf,d=b.o(c-1),f=b.o(c),g=b.o(c+1);if(b.ic){if(c>b.gt+1&&c<b.Ww-1){var h=b.o(c-2);if(Math.abs(d.x-f.x)<this.Lg&&Math.abs(d.y-f.y)<this.Lg&&(Qg(this,h,d,f,g,!0)||Qg(this,h,d,f,g,!1))){var k=b.points.copy();Qg(this,h,d,f,g,!0)?(k.Mg(c-2,new w(h.x,(g.y+h.y)/2)),k.Mg(c+1,new w(g.x,(g.y+h.y)/2))):(k.Mg(c-2,new w((g.x+h.x)/2,h.y)),k.Mg(c+1,
new w((g.x+h.x)/2,g.y)));k.qd(c);k.qd(c-1);b.points=k;b.Wi();b.updateAdornments()}else h=b.o(c+2),Math.abs(f.x-g.x)<this.Lg&&Math.abs(f.y-g.y)<this.Lg&&(Qg(this,d,f,g,h,!0)||Qg(this,d,f,g,h,!1))&&(k=b.points.copy(),Qg(this,d,f,g,h,!0)?(k.Mg(c-1,new w(d.x,(d.y+h.y)/2)),k.Mg(c+2,new w(h.x,(d.y+h.y)/2))):(k.Mg(c-1,new w((d.x+h.x)/2,d.y)),k.Mg(c+2,new w((d.x+h.x)/2,h.y))),k.qd(c+1),k.qd(c),b.points=k,b.Wi(),b.updateAdornments())}}else h=t.K(),F.dn(d.x,d.y,g.x,g.y,f.x,f.y,h)&&h.Zj(f)<this.Lg*this.Lg&&
(k=b.points.copy(),k.qd(c),b.points=k,b.Wi(),b.updateAdornments()),t.B(h)}a.Oc();this.gf=this.name;a.Ba("LinkReshaped",this.Ls)}this.stopTool()};function Qg(a,b,c,d,f,g){return g?Math.abs(b.y-c.y)<a.Lg&&Math.abs(c.y-d.y)<a.Lg&&Math.abs(d.y-f.y)<a.Lg:Math.abs(b.x-c.x)<a.Lg&&Math.abs(c.x-d.x)<a.Lg&&Math.abs(d.x-f.x)<a.Lg}t.g(Bg,"resegmentingDistance",Bg.prototype.Lg);
t.defineProperty(Bg,{Lg:"resegmentingDistance"},function(){return this.ZC},function(a){t.i(a,"number",Bg,"resegmentingDistance");this.ZC=a});
Bg.prototype.reshape=function(a){var b=this.Ls;b.zl();var c=this.handle.Cf,d=this.handle&&this.handle.Av?this.handle.Av:Jg;if(b.ic)if(c===b.gt+1)c=b.gt+1,d===Mg?(b.Y(c,b.o(c-1).x,a.y),b.Y(c+1,b.o(c+2).x,a.y)):d===Lg&&(b.Y(c,a.x,b.o(c-1).y),b.Y(c+1,a.x,b.o(c+2).y));else if(c===b.Ww-1)c=b.Ww-1,d===Mg?(b.Y(c-1,b.o(c-2).x,a.y),b.Y(c,b.o(c+1).x,a.y)):d===Lg&&(b.Y(c-1,a.x,b.o(c-2).y),b.Y(c,a.x,b.o(c+1).y));else{var d=c,f=b.o(d),g=b.o(d-1),h=b.o(d+1);F.I(g.x,f.x)&&F.I(f.y,h.y)?(F.I(g.x,b.o(d-2).x)&&!F.I(g.y,
b.o(d-2).y)?(b.C(d,a.x,g.y),c++,d++):b.Y(d-1,a.x,g.y),F.I(h.y,b.o(d+2).y)&&!F.I(h.x,b.o(d+2).x)?b.C(d+1,h.x,a.y):b.Y(d+1,h.x,a.y)):F.I(g.y,f.y)&&F.I(f.x,h.x)?(F.I(g.y,b.o(d-2).y)&&!F.I(g.x,b.o(d-2).x)?(b.C(d,g.x,a.y),c++,d++):b.Y(d-1,g.x,a.y),F.I(h.x,b.o(d+2).x)&&!F.I(h.y,b.o(d+2).y)?b.C(d+1,a.x,h.y):b.Y(d+1,a.x,h.y)):F.I(g.x,f.x)&&F.I(f.x,h.x)?(F.I(g.x,b.o(d-2).x)&&!F.I(g.y,b.o(d-2).y)?(b.C(d,a.x,g.y),c++,d++):b.Y(d-1,a.x,g.y),F.I(h.x,b.o(d+2).x)&&!F.I(h.y,b.o(d+2).y)?b.C(d+1,a.x,h.y):b.Y(d+1,a.x,
h.y)):F.I(g.y,f.y)&&F.I(f.y,h.y)&&(F.I(g.y,b.o(d-2).y)&&!F.I(g.x,b.o(d-2).x)?(b.C(d,g.x,a.y),c++,d++):b.Y(d-1,g.x,a.y),F.I(h.y,b.o(d+2).y)&&!F.I(h.x,b.o(d+2).x)?b.C(d+1,h.x,a.y):b.Y(d+1,h.x,a.y));b.Y(c,a.x,a.y)}else b.Y(c,a.x,a.y),1===c&&b.computeSpot(!0).Ge()&&(f=b.aa,g=b.ud,null===f||f.La()||(f=f.findVisibleNode(),f!==b.aa&&(g=f.gl(""))),d=g.pb(Hb,t.K()),f=b.getLinkPointFromPoint(f,g,d,a,!0,t.K()),b.Y(0,f.x,f.y),t.B(d),t.B(f)),c===b.pa-2&&b.computeSpot(!1).Ge()&&(c=b.ca,f=b.fe,null===c||c.La()||
(c=c.findVisibleNode(),c!==b.ca&&(f=c.gl(""))),d=f.pb(Hb,t.K()),f=b.getLinkPointFromPoint(c,f,d,a,!1,t.K()),b.Y(b.pa-1,f.x,f.y),t.B(d),t.B(f));b.Xi()};Bg.prototype.computeReshape=function(a){var b=this.Ls,c=this.handle.Cf;switch(this.handle&&this.handle.Av?this.handle.Av:Jg){case Ng:return a;case Mg:return b=b.o(c),new w(b.x,a.y);case Lg:return b=b.o(c),new w(a.x,b.y);default:case Jg:return b.o(c)}};t.g(Bg,"originalPoint",Bg.prototype.yJ);t.A(Bg,{yJ:"originalPoint"},function(){return this.TC});
t.g(Bg,"originalPoints",Bg.prototype.zJ);t.A(Bg,{zJ:"originalPoints"},function(){return this.mz});
function Rg(){0<arguments.length&&t.ad(Rg);ae.call(this);this.name="Resizing";this.Ej=(new fa(1,1)).freeze();this.Dj=(new fa(9999,9999)).freeze();this.pj=(new fa(NaN,NaN)).freeze();this.Wq=!1;this.sb=null;var a=new V;a.Qj=Hb;a.Ab="Rectangle";a.Ca=F.dq;a.fill="lightblue";a.stroke="dodgerblue";a.fb=1;a.cursor="pointer";this.Fk=a;this.dc=null;this.vv=new fa;this.lz=new w;this.Qy=new fa(0,0);this.Py=new fa(Infinity,Infinity);this.Oy=new fa(1,1);this.MC=!0}t.ga("ResizingTool",Rg);t.Ka(Rg,ae);
Rg.prototype.updateAdornments=function(a){if(!(null===a||a instanceof T)){if(a.eb&&!this.h.ab){var b=a.WF;if(null!==b&&a.canResize()&&a.oa.N()&&a.La()&&b.oa.N()&&b.pl()){var c=a.bp(this.name);null===c&&(c=this.makeAdornment(b));if(null!==c){var d=b.hl();c.angle=d;var f=b.pb(c.bf,t.K()),g=b.ek();c.location=f;t.B(f);f=c.placeholder;if(null!==f){var b=b.Pa,h=t.Cl();h.p(b.width*g,b.height*g);f.Ca=h;t.ck(h)}Sg(this,c,d);a.Wk(this.name,c);return}}}a.vl(this.name)}};
Rg.prototype.makeAdornment=function(a){var b=null,b=a.S.UF;if(null===b){b=new Je;b.type=Tg;b.bf=Hb;var c=new Ug;c.fj=!0;b.add(c);b.add(this.makeHandle(a,Eb));b.add(this.makeHandle(a,Gb));b.add(this.makeHandle(a,Pb));b.add(this.makeHandle(a,Lb));b.add(this.makeHandle(a,Qb));b.add(this.makeHandle(a,Wb));b.add(this.makeHandle(a,Xb));b.add(this.makeHandle(a,Vb))}else if(Le(b),b=b.copy(),!(b instanceof Je))return null;b.Zc=this.name;b.pc=a;return b};
Rg.prototype.makeHandle=function(a,b){var c=this.it;if(null===c)return null;c=c.copy();c.alignment=b;return c};
function Sg(a,b,c){if(null!==b)if(!b.alignment.Pc()&&""!==b.cursor)a:{a=b.alignment;a.Ge()&&(a=Hb);if(0>=a.x)c=0>=a.y?c+225:1<=a.y?c+135:c+180;else if(1<=a.x)0>=a.y?c+=315:1<=a.y&&(c+=45);else if(0>=a.y)c+=270;else if(1<=a.y)c+=90;else break a;0>c?c+=360:360<=c&&(c-=360);b.cursor=22.5>c?"e-resize":67.5>c?"se-resize":112.5>c?"s-resize":157.5>c?"sw-resize":202.5>c?"w-resize":247.5>c?"nw-resize":292.5>c?"n-resize":337.5>c?"ne-resize":"e-resize"}else if(b instanceof y)for(b=b.elements;b.next();)Sg(a,
b.value,c)}t.defineProperty(Rg,{it:"handleArchetype"},function(){return this.Fk},function(a){null!==a&&t.l(a,Q,Rg,"handleArchetype");this.Fk=a});t.A(Rg,{handle:"handle"},function(){return this.dc});t.defineProperty(Rg,{pc:"adornedObject"},function(){return this.sb},function(a){null!==a&&t.l(a,Q,Rg,"adornedObject");this.sb=a});Rg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;return null!==a&&!a.ab&&a.Oo&&a.R.left?null!==this.findToolHandleAt(a.Nc.ea,this.name)?!0:!1:!1};
Rg.prototype.doActivate=function(){var a=this.h;null!==a&&(this.dc=this.findToolHandleAt(a.Nc.ea,this.name),null!==this.dc&&(this.sb=this.dc.S.pc,this.lz.set(this.sb.S.location),this.vv.set(this.sb.Ca),this.Oy=this.computeCellSize(),this.Qy=this.computeMinSize(),this.Py=this.computeMaxSize(),a.Kd=!0,this.MC=a.Ub.isEnabled,a.Ub.isEnabled=!1,this.uc(this.name),this.ia=!0))};Rg.prototype.doDeactivate=function(){var a=this.h;null!==a&&(this.hk(),this.sb=this.dc=null,this.ia=a.Kd=!1,a.Ub.isEnabled=this.MC)};
Rg.prototype.doCancel=function(){this.sb.Ca=this.vv;this.sb.S.location=this.lz;this.stopTool()};Rg.prototype.doMouseMove=function(){var a=this.h;if(this.ia&&null!==a){var b=this.Qy,c=this.Py,d=this.Oy,f=this.sb.JE(a.R.ea,t.K()),g=Vg;this.sb instanceof V&&(g=Wg(this.sb));b=this.computeResize(f,this.dc.alignment,b,c,d,!(g===Xg||g===Yg||a.R.shift));this.resize(b);a.Uf();t.B(f)}};
Rg.prototype.doMouseUp=function(){var a=this.h;if(this.ia&&null!==a){var b=this.Qy,c=this.Py,d=this.Oy,f=this.sb.JE(a.R.ea,t.K()),g=Vg;this.sb instanceof V&&(g=Wg(this.sb));b=this.computeResize(f,this.dc.alignment,b,c,d,!(g===Xg||g===Yg||a.R.shift));this.resize(b);t.B(f);a.Oc();this.gf=this.name;a.Ba("PartResized",this.sb,this.vv)}this.stopTool()};
Rg.prototype.resize=function(a){if(null!==this.h){var b=this.pc,c=b.S,d=b.hl(),f=b.ek(),g=Math.PI*d/180,h=Math.cos(g),g=Math.sin(g),k=0<d&&180>d?1:0,l=90<d&&270>d?1:0,d=180<d&&360>d?1:0,m=a.width-b.Pa.width,n=a.height-b.Pa.height,p=c.position.copy();p.x+=f*((a.x+m*l)*h-(a.y+n*k)*g);p.y+=f*((a.x+m*d)*g+(a.y+n*l)*h);b.Ca=a.size;c.sf();c.move(p)}};
Rg.prototype.computeResize=function(a,b,c,d,f,g){b.Ge()&&(b=Hb);var h=this.pc.Pa,k=h.x,l=h.y,m=h.x+h.width,n=h.y+h.height,p=1;if(!g){var p=h.width,q=h.height;0>=p&&(p=1);0>=q&&(q=1);p=q/p}q=t.K();F.dt(a.x,a.y,k,l,f.width,f.height,q);a=h.copy();0>=b.x?0>=b.y?(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=Math.max(m-a.x,c.width),a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),a.height=Math.max(n-a.y,c.height),g||(b=a.height/a.width,p<b?(a.height=p*a.width,a.y=n-a.height):
(a.width=a.height/p,a.x=m-a.width))):1<=b.y?(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=Math.max(m-a.x,c.width),a.height=Math.max(Math.min(q.y-l,d.height),c.height),g||(b=a.height/a.width,p<b?a.height=p*a.width:(a.width=a.height/p,a.x=m-a.width))):(a.x=Math.max(q.x,m-d.width),a.x=Math.min(a.x,m-c.width),a.width=m-a.x,g||(a.height=p*a.width,a.y=l+0.5*(n-l-a.height))):1<=b.x?0>=b.y?(a.width=Math.max(Math.min(q.x-k,d.width),c.width),a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,
n-c.height),a.height=Math.max(n-a.y,c.height),g||(b=a.height/a.width,p<b?(a.height=p*a.width,a.y=n-a.height):a.width=a.height/p)):1<=b.y?(a.width=Math.max(Math.min(q.x-k,d.width),c.width),a.height=Math.max(Math.min(q.y-l,d.height),c.height),g||(b=a.height/a.width,p<b?a.height=p*a.width:a.width=a.height/p)):(a.width=Math.max(Math.min(q.x-k,d.width),c.width),g||(a.height=p*a.width,a.y=l+0.5*(n-l-a.height))):0>=b.y?(a.y=Math.max(q.y,n-d.height),a.y=Math.min(a.y,n-c.height),a.height=n-a.y,g||(a.width=
a.height/p,a.x=k+0.5*(m-k-a.width))):1<=b.y&&(a.height=Math.max(Math.min(q.y-l,d.height),c.height),g||(a.width=a.height/p,a.x=k+0.5*(m-k-a.width)));t.B(q);return a};Rg.prototype.computeMinSize=function(){var a=this.pc.Vf.copy(),b=this.Vf;!isNaN(b.width)&&b.width>a.width&&(a.width=b.width);!isNaN(b.height)&&b.height>a.height&&(a.height=b.height);return a};
Rg.prototype.computeMaxSize=function(){var a=this.pc.He.copy(),b=this.He;!isNaN(b.width)&&b.width<a.width&&(a.width=b.width);!isNaN(b.height)&&b.height<a.height&&(a.height=b.height);return a};
Rg.prototype.computeCellSize=function(){var a=new fa(NaN,NaN),b=this.pc.S;if(b){var c=b.VF;!isNaN(c.width)&&0<c.width&&(a.width=c.width);!isNaN(c.height)&&0<c.height&&(a.height=c.height)}c=this.al;isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width);isNaN(a.height)&&!isNaN(c.height)&&0<c.height&&(a.height=c.height);b=this.h;(isNaN(a.width)||isNaN(a.height))&&b&&(c=b.wb.Hd,null!==c&&c.qp&&(c=c.lA,isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width),isNaN(a.height)&&!isNaN(c.height)&&
0<c.height&&(a.height=c.height)),b=b.lp,null!==b&&b.visible&&this.qp&&(c=b.ht,isNaN(a.width)&&!isNaN(c.width)&&0<c.width&&(a.width=c.width),isNaN(a.height)&&!isNaN(c.height)&&0<c.height&&(a.height=c.height)));if(isNaN(a.width)||0===a.width||Infinity===a.width)a.width=1;if(isNaN(a.height)||0===a.height||Infinity===a.height)a.height=1;return a};t.g(Rg,"minSize",Rg.prototype.Vf);
t.defineProperty(Rg,{Vf:"minSize"},function(){return this.Ej},function(a){t.l(a,fa,Rg,"minSize");if(!this.Ej.M(a)){var b=a.width;isNaN(b)&&(b=0);a=a.height;isNaN(a)&&(a=0);this.Ej.p(b,a)}});t.g(Rg,"maxSize",Rg.prototype.He);t.defineProperty(Rg,{He:"maxSize"},function(){return this.Dj},function(a){t.l(a,fa,Rg,"maxSize");if(!this.Dj.M(a)){var b=a.width;isNaN(b)&&(b=Infinity);a=a.height;isNaN(a)&&(a=Infinity);this.Dj.p(b,a)}});t.g(Rg,"cellSize",Rg.prototype.al);
t.defineProperty(Rg,{al:"cellSize"},function(){return this.pj},function(a){t.l(a,fa,Rg,"cellSize");this.pj.M(a)||this.pj.assign(a)});t.g(Rg,"isGridSnapEnabled",Rg.prototype.qp);t.defineProperty(Rg,{qp:"isGridSnapEnabled"},function(){return this.Wq},function(a){t.i(a,"boolean",Rg,"isGridSnapEnabled");this.Wq=a});t.g(Rg,"originalDesiredSize",Rg.prototype.wJ);t.A(Rg,{wJ:"originalDesiredSize"},function(){return this.vv});t.g(Rg,"originalLocation",Rg.prototype.xJ);t.A(Rg,{xJ:"originalLocation"},function(){return this.lz});
function Zg(){0<arguments.length&&t.ad(Zg);ae.call(this);this.name="Rotating";this.eD=45;this.dD=2;this.sb=null;var a=new V;a.Ab="Ellipse";a.Ca=F.Nx;a.fill="lightblue";a.stroke="dodgerblue";a.fb=1;a.cursor="pointer";this.Fk=a;this.dc=null;this.uv=0;this.$C=new w}t.ga("RotatingTool",Zg);t.Ka(Zg,ae);
Zg.prototype.updateAdornments=function(a){if(!(null===a||a instanceof T)){if(a.eb&&!this.h.ab){var b=a.aG;if(null!==b&&a.canRotate()&&a.oa.N()&&a.La()&&b.oa.N()&&b.pl()){var c=a.bp(this.name);null===c&&(c=this.makeAdornment(b));if(null!==c){c.angle=b.hl();var d=null,f=null;b===a||b===a.kc?(d=a.kc,f=a.bf):(d=b,f=Hb);for(var g=d.Pa,f=t.mc(g.width*f.x+f.offsetX,g.height*f.y+f.offsetY);null!==d&&d!==b;)d.transform.Sa(f),d=d.ja;var d=f.y,g=Math.max(f.x-b.Pa.width,0),h=t.K();c.location=b.pb(new H(1,0,50+
g,d),h);t.B(h);t.B(f);a.Wk(this.name,c);return}}}a.vl(this.name)}};Zg.prototype.makeAdornment=function(a){var b=null,b=a.S.$F;if(null===b){b=new Je;b.type=$g;b.bf=Hb;var c=this.it;null!==c&&b.add(c.copy())}else if(Le(b),b=b.copy(),!(b instanceof Je))return null;b.Zc=this.name;b.pc=a;return b};t.defineProperty(Zg,{it:"handleArchetype"},function(){return this.Fk},function(a){null!==a&&t.l(a,Q,Zg,"handleArchetype");this.Fk=a});t.A(Zg,{handle:"handle"},function(){return this.dc});
t.defineProperty(Zg,{pc:"adornedObject"},function(){return this.sb},function(a){null!==a&&t.l(a,Q,Zg,"adornedObject");this.sb=a});Zg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;return null!==a&&!a.ab&&a.Po&&a.R.left?null!==this.findToolHandleAt(a.Nc.ea,this.name)?!0:!1:!1};
Zg.prototype.doActivate=function(){var a=this.h;if(null!==a&&(this.dc=this.findToolHandleAt(a.Nc.ea,this.name),null!==this.dc)){this.sb=this.dc.S.pc;var b=this.sb.S,c=b.kc;this.$C=this.sb===b||this.sb===c?c.pb(b.bf):this.sb.pb(Hb);this.uv=this.sb.angle;a.Kd=!0;a.ww=!0;this.uc(this.name);this.ia=!0}};Zg.prototype.doDeactivate=function(){var a=this.h;null!==a&&(this.hk(),this.sb=this.dc=null,this.ia=a.Kd=!1)};Zg.prototype.doCancel=function(){var a=this.h;a&&(a.ww=!1);this.rotate(this.uv);this.stopTool()};
Zg.prototype.doMouseMove=function(){var a=this.h;this.ia&&null!==a&&(a=this.computeRotate(a.R.ea),this.rotate(a))};Zg.prototype.doMouseUp=function(){var a=this.h;if(this.ia&&null!==a){a.ww=!1;var b=this.computeRotate(a.R.ea);this.rotate(b);a.Oc();this.gf=this.name;a.Ba("PartRotated",this.sb,this.uv)}this.stopTool()};Zg.prototype.rotate=function(a){e&&t.q(a,Zg,"rotate:newangle");null!==this.sb&&(this.sb.angle=a)};
Zg.prototype.computeRotate=function(a){a=this.$C.aj(a);var b=this.sb.ja;b&&(a-=b.hl(),360<=a?a-=360:0>a&&(a+=360));var b=Math.min(Math.abs(this.uG),180),c=Math.min(Math.abs(this.tG),b/2);!this.h.R.shift&&0<b&&0<c&&(a%b<c?a=Math.floor(a/b)*b:a%b>b-c&&(a=(Math.floor(a/b)+1)*b));360<=a?a-=360:0>a&&(a+=360);return a};t.g(Zg,"snapAngleMultiple",Zg.prototype.uG);t.defineProperty(Zg,{uG:"snapAngleMultiple"},function(){return this.eD},function(a){t.i(a,"number",Zg,"snapAngleMultiple");this.eD=a});
t.g(Zg,"snapAngleEpsilon",Zg.prototype.tG);t.defineProperty(Zg,{tG:"snapAngleEpsilon"},function(){return this.dD},function(a){t.i(a,"number",Zg,"snapAngleEpsilon");this.dD=a});t.g(Zg,"originalAngle",Zg.prototype.vJ);t.A(Zg,{vJ:"originalAngle"},function(){return this.uv});function ah(){0<arguments.length&&t.ad(ah);ae.call(this);this.name="ClickSelecting"}t.ga("ClickSelectingTool",ah);t.Ka(ah,ae);ah.prototype.canStart=function(){return!this.isEnabled||null===this.h||this.isBeyondDragSize()?!1:!0};
ah.prototype.doMouseUp=function(){this.ia&&(this.standardMouseSelect(),this.standardMouseClick());this.stopTool()};function bh(){0<arguments.length&&t.ad(bh);ae.call(this);this.name="Action";this.tn=null}t.ga("ActionTool",bh);t.Ka(bh,ae);bh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;if(null===a)return!1;var b=a.R,c=a.pe(b.ea,function(a){for(;null!==a.ja&&!a.Ow;)a=a.ja;return a},function(a){return a.Ow});return null!==c?(this.tn=c,a.Bn=a.pe(b.ea,null,null),!0):!1};
bh.prototype.doMouseDown=function(){if(this.ia){var a=this.h.R,b=this.tn;null!==b&&(a.ce=b,null!==b.Fz&&b.Fz(a,b))}else this.canStart()&&this.doActivate()};bh.prototype.doMouseMove=function(){if(this.ia){var a=this.h.R,b=this.tn;null!==b&&(a.ce=b,null!==b.Gz&&b.Gz(a,b))}};bh.prototype.doMouseUp=function(){if(this.ia){var a=this.h,b=a.R,c=this.tn;if(null===c)return;b.ce=c;null!==c.Hz&&c.Hz(b,c);this.isBeyondDragSize()||$e(c,b,a)}this.stopTool()};
bh.prototype.doCancel=function(){var a=this.h;if(null!==a){var a=a.R,b=this.tn;if(null===b)return;a.ce=b;null!==b.Ez&&b.Ez(a,b)}this.stopTool()};bh.prototype.doStop=function(){this.tn=null};function ra(){0<arguments.length&&t.ad(ra);ae.call(this);this.name="ClickCreating";this.xk=null;this.qC=!0;this.gC=new w(0,0)}t.ga("ClickCreatingTool",ra);t.Ka(ra,ae);
ra.prototype.canStart=function(){if(!this.isEnabled||null===this.Os)return!1;var a=this.h;if(null===a||a.ab||a.Ze||!a.Bm||!a.R.left||this.isBeyondDragSize())return!1;if(this.bF){if(1===a.R.Ae&&(this.gC=a.R.Ke.copy()),2!==a.R.Ae||this.isBeyondDragSize(this.gC))return!1}else if(1!==a.R.Ae)return!1;return a.Ua!==this&&null!==a.et(a.R.ea,!0)?!1:!0};ra.prototype.doMouseUp=function(){var a=this.h;this.ia&&null!==a&&this.insertPart(a.R.ea);this.stopTool()};
ra.prototype.insertPart=function(a){var b=this.h;if(null===b)return null;var c=this.Os;if(null===c)return null;this.uc(this.name);var d=null;c instanceof C?c.Ld()&&(Le(c),d=c.copy(),d instanceof C&&b.add(d)):null!==c&&(c=b.ba.copyNodeData(c),t.qb(c)&&(b.ba.Am(c),d=b.fh(c)));d instanceof C&&(d.location=a,b.Pe&&b.select(d));b.Oc();this.gf=this.name;b.Ba("PartCreated",d);this.hk();return d instanceof C?d:null};t.g(ra,"archetypeNodeData",ra.prototype.Os);
t.defineProperty(ra,{Os:"archetypeNodeData"},function(){return this.xk},function(a){null!==a&&t.l(a,Object,ra,"archetypeNodeData");this.xk=a});t.g(ra,"isDoubleClick",ra.prototype.bF);t.defineProperty(ra,{bF:"isDoubleClick"},function(){return this.qC},function(a){t.i(a,"boolean",ra,"isDoubleClick");this.qC=a});
function ch(){0<arguments.length&&t.ad(ch);ae.call(this);this.name="ContextMenu";this.Cn=this.PB=null;this.GC=new w;this.Kn=null;if(!0===t.gB){this.Kn=new Je;this.Ey=null;var a=this;this.vD=function(){a.stopTool()};if(!1===t.lE){var b=document.createElement("div"),c=document.createElement("div");b.style.cssText="top: 0px;z-index:300;position: fixed;display: none;text-align: center;left: 25%;width: 50%;background-color: #F5F5F5;padding: 16px;border: 16px solid #444;border-radius: 10px;margin-top: 10px";
c.style.cssText="z-index:299;position: fixed;display: none;top: 0;left: 0;width: 100%;height: 100%;background-color: black;-moz-opacity: 0.8;opacity:.80;filter: alpha(opacity=80);";var d=document.createElement("style");document.getElementsByTagName("head")[0].appendChild(d);d.sheet.insertRule(".defaultCXul { list-style: none; }",0);d.sheet.insertRule(".defaultCXli {font:700 1.5em Helvetica, Arial, sans-serif;position: relative;min-width: 60px; }",0);d.sheet.insertRule(".defaultCXa {color: #444;display: inline-block;padding: 4px;text-decoration: none;margin: 2px;border: 1px solid gray;border-radius: 10px; }",
0);b.addEventListener("contextmenu",function(a){a.preventDefault();return!1},!1);b.addEventListener("selectstart",function(a){a.preventDefault();return!1},!1);c.addEventListener("contextmenu",function(a){a.preventDefault();return!1},!1);document.body&&(document.body.appendChild(b),document.body.appendChild(c));t.Us=b;t.Ts=c;t.lE=!0}}}t.ga("ContextMenuTool",ch);t.Ka(ch,ae);
ch.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;return null===a||this.isBeyondDragSize()||!a.R.right?!1:(null===this.Kn||a.R.event instanceof MouseEvent)&&null===this.findObjectWithContextMenu()?!1:!0};ch.prototype.doStart=function(){var a=this.h;null!==a&&this.GC.set(a.Nc.ea)};ch.prototype.doStop=function(){this.hideDefaultContextMenu();this.hideContextMenu();this.Cn=null};
ch.prototype.findObjectWithContextMenu=function(a){var b=this.h;if(null===b)return null;var c=b.R,d=null,d=void 0!==a?a:b.pe(c.ea,null,function(a){return!a.layer.rc});if(null!==d){for(a=d;null!==a;){c=a.contextMenu;if(null!==c)return a;a=a.ja}if(null!==this.Kn&&!(b.R.event instanceof MouseEvent))return d.S}else if(c=b.contextMenu,null!==c)return b;return null};ch.prototype.doActivate=function(){};
ch.prototype.doMouseDown=function(){if(this.ia){var a=this.h;if(null!==a&&null!==this.rf){var b=a.pe(a.R.ea,null,null);null!==b&&b.ej(this.rf)?(this.standardMouseClick(null,null),this.stopTool()):(this.stopTool(),a.Ua.doMouseDown())}}};ch.prototype.doMouseUp=function(){this.ia||this.canStart()&&Ze(this,!0)};
function Ze(a,b,c){a.ia=!0;b&&a.standardMouseSelect();a.standardMouseClick();a.Cn=null;void 0===c&&(c=a.findObjectWithContextMenu());null!==c?(b=c.contextMenu,null!==b?(a.Cn=c instanceof Q?c:null,a.showContextMenu(b,a.Cn)):a.showDefaultContextMenu()):a.showDefaultContextMenu()}ch.prototype.doMouseMove=function(){this.ia&&this.standardMouseOver()};
ch.prototype.showContextMenu=function(a,b){t.l(a,Je,ch,"showContextMenu:contextmenu");null!==b&&t.l(b,Q,ch,"showContextMenu:obj");var c=this.h;if(null!==c){a!==this.rf&&this.hideContextMenu();a.af="Tool";a.wl=!1;a.scale=1/c.scale;a.Zc=this.name;c.add(a);if(null!==b){var c=null,d=b.ep();null!==d&&(c=d.data);a.pc=b;a.data=c}else a.data=c.ba;a.sf();this.positionContextMenu(a,b);this.rf=a}};
ch.prototype.positionContextMenu=function(a){var b=this.h;if(null!==b){var c=b.R.ea.copy(),d=a.Ga,f=b.xb;b.R.Uw&&(c.x-=d.width);c.x+d.width>f.right&&(c.x-=d.width+5);c.x<f.x&&(c.x=f.x);c.y+d.height>f.bottom&&(c.y-=d.height+5);c.y<f.y&&(c.y=f.y);a.position=c}};ch.prototype.hideContextMenu=function(){var a=this.h;null!==a&&null!==this.rf&&(this.rf.data=null,this.rf.pc=null,a.remove(this.rf),this.rf=null,this.standardMouseOver())};
ch.prototype.initializeDefaultButtons=function(){if(null===this.h)return null;var a=new B,b=this.h.Se;a.add({text:"Copy",eh:function(){b.copySelection()},visible:function(){return b.canCopySelection()}});a.add({text:"Cut",eh:function(){b.cutSelection()},visible:function(){return b.canCutSelection()}});a.add({text:"Delete",eh:function(){b.deleteSelection()},visible:function(){return b.canDeleteSelection()}});a.add({text:"Paste",eh:function(a){b.pasteSelection(a.R.ea)},visible:function(){return b.canPasteSelection()}});
a.add({text:"Select All",eh:function(){b.selectAll()},visible:function(){return b.canSelectAll()}});a.add({text:"Undo",eh:function(){b.undo()},visible:function(){return b.canUndo()}});a.add({text:"Redo",eh:function(){b.redo()},visible:function(){return b.canRedo()}});a.add({text:"Zoom To Fit",eh:function(){b.zoomToFit()},visible:function(){return b.canZoomToFit()}});a.add({text:"Reset Zoom",eh:function(){b.resetZoom()},visible:function(){return b.canResetZoom()}});a.add({text:"Group Selection",eh:function(){b.groupSelection()},
visible:function(){return b.canGroupSelection()}});a.add({text:"Ungroup Selection",eh:function(){b.ungroupSelection()},visible:function(){return b.canUngroupSelection()}});a.add({text:"Edit Text",eh:function(){b.editTextBlock()},visible:function(){return b.canEditTextBlock()}});return a};
ch.prototype.showDefaultContextMenu=function(){var a=this.h;if(null!==a){null===this.Ey&&(this.Ey=this.initializeDefaultButtons());this.Kn!==this.rf&&this.hideContextMenu();t.Us.innerHTML="";t.Ts.addEventListener("click",this.vD,!1);var b=this,c=document.createElement("ul");c.className="defaultCXul";t.Us.appendChild(c);c.innerHTML="";for(var d=this.Ey.k;d.next();){var f=d.value,g=f.text,h=f.visible;if("function"!==typeof h||h()){h=document.createElement("li");h.className="defaultCXli";var k=document.createElement("a");
k.className="defaultCXa";k.href="#";k.jH=f.eh;k.addEventListener("click",function(c){this.jH(a);b.stopTool();c.preventDefault();return!1},!1);k.textContent=g;h.appendChild(k);c.appendChild(h)}}t.Us.style.display="block";t.Ts.style.display="block";this.rf=this.Kn}};ch.prototype.hideDefaultContextMenu=function(){null!==this.rf&&this.rf===this.Kn&&(t.Us.style.display="none",t.Ts.style.display="none",t.Ts.removeEventListener("click",this.vD,!1),this.rf=null)};t.g(ch,"currentContextMenu",ch.prototype.rf);
t.defineProperty(ch,{rf:"currentContextMenu"},function(){return this.PB},function(a){null!==a&&t.l(a,Je,ch,"currentContextMenu");this.PB=a});t.g(ch,"currentObject",ch.prototype.kI);t.defineProperty(ch,{kI:"currentObject"},function(){return this.Cn},function(a){null!==a&&t.l(a,Q,ch,"currentObject");this.Cn=a});t.A(ch,{HK:"mouseDownPoint"},function(){return this.GC});
function dh(){0<arguments.length&&t.ad(dh);ae.call(this);this.name="DragSelecting";this.Ln=175;this.vC=!1;var a=new C;a.af="Tool";a.wl=!1;var b=new V;b.name="SHAPE";b.Ab="Rectangle";b.fill=null;b.stroke="magenta";a.add(b);this.Kl=a}t.ga("DragSelectingTool",dh);t.Ka(dh,ae);
dh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;if(null===a||!a.Pe)return!1;var b=a.R;return!b.left||a.Ua!==this&&(!this.isBeyondDragSize()||b.timestamp-a.Nc.timestamp<this.vw||null!==a.et(b.ea,!0))?!1:!0};dh.prototype.doActivate=function(){var a=this.h;null!==a&&(this.ia=!0,a.Kd=!0,a.Wa=!0,a.add(this.vg),this.doMouseMove())};dh.prototype.doDeactivate=function(){var a=this.h;null!==a&&(a.remove(this.vg),a.Wa=!1,this.ia=a.Kd=!1)};
dh.prototype.doMouseMove=function(){if(null!==this.h&&this.ia&&null!==this.vg){var a=this.computeBoxBounds(),b=this.vg.oe("SHAPE");null===b&&(b=this.vg.Dw());b.Ca=a.size;this.vg.position=a.position}};dh.prototype.doMouseUp=function(){if(this.ia){var a=this.h;a.remove(this.vg);try{a.$b="wait",this.selectInRect(this.computeBoxBounds())}finally{a.$b=""}}this.stopTool()};dh.prototype.computeBoxBounds=function(){var a=this.h;return null===a?new x(0,0,0,0):new x(a.Nc.ea,a.R.ea)};
dh.prototype.selectInRect=function(a){var b=this.h;if(null!==b){var c=b.R;b.Ba("ChangingSelection");a=b.bk(a,null,function(a){return a instanceof C&&a.canSelect()},this.gF);if(t.Vm?c.Bp:c.control)if(c.shift)for(a=a.k;a.next();)c=a.value,c.eb&&(c.eb=!1);else for(a=a.k;a.next();)c=a.value,c.eb=!c.eb;else{if(!c.shift){for(var d=new B(C),f=b.selection.k;f.next();)c=f.value,a.contains(c)||d.add(c);for(d=d.k;d.next();)c=d.value,c.eb=!1}for(a=a.k;a.next();)c=a.value,c.eb||(c.eb=!0)}b.Ba("ChangedSelection")}};
t.g(dh,"delay",dh.prototype.vw);t.defineProperty(dh,{vw:"delay"},function(){return this.Ln},function(a){t.i(a,"number",dh,"delay");this.Ln=a});t.g(dh,"isPartialInclusion",dh.prototype.gF);t.defineProperty(dh,{gF:"isPartialInclusion"},function(){return this.vC},function(a){t.i(a,"boolean",dh,"isPartialInclusion");this.vC=a});t.g(dh,"box",dh.prototype.vg);t.defineProperty(dh,{vg:"box"},function(){return this.Kl},function(a){null!==a&&t.l(a,C,dh,"box");this.Kl=a});
function eh(){0<arguments.length&&t.ad(eh);ae.call(this);this.name="Panning";this.nz=new w;this.oj=!1;var a=this;this.jD=function(){document.removeEventListener("scroll",a.jD,!1);a.stopTool()}}t.ga("PanningTool",eh);t.Ka(eh,ae);eh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;return null===a||!a.of&&!a.pf||!a.R.left||a.Ua!==this&&!this.isBeyondDragSize()?!1:!0};
eh.prototype.doActivate=function(){var a=this.h;null!==a&&(this.oj?(a.R.bubbles=!0,document.addEventListener("scroll",this.jD,!1)):(a.$b="move",a.Kd=!0,this.nz=a.position.copy()),this.ia=!0)};eh.prototype.doDeactivate=function(){var a=this.h;null!==a&&(a.$b="",this.ia=a.Kd=!1)};eh.prototype.doCancel=function(){var a=this.h;null!==a&&(a.position=this.nz,a.Kd=!1);this.stopTool()};eh.prototype.doMouseMove=function(){this.move()};eh.prototype.doMouseUp=function(){this.move();this.stopTool()};
eh.prototype.move=function(){var a=this.h;if(this.ia&&a)if(this.oj)a.R.bubbles=!0;else{var b=a.position,c=a.Nc.ea,d=a.R.ea,f=b.x+c.x-d.x,c=b.y+c.y-d.y;a.of||(f=b.x);a.pf||(c=b.y);a.position=new w(f,c)}};t.g(eh,"bubbles",eh.prototype.bubbles);t.defineProperty(eh,{bubbles:"bubbles"},function(){return this.oj},function(a){t.i(a,"boolean",eh,"bubbles");this.oj=a});t.g(eh,"originalPosition",eh.prototype.AJ);t.A(eh,{AJ:"originalPosition"},function(){return this.nz});
function fh(){0<arguments.length&&t.ad(fh);ae.call(this);this.name="TextEditing";this.nD=null;this.Xy=0;this.SB=this.Az=null;this.iD=gh;this.Tk=null;this.Ya=hh;this.Pk=null;var a=document.createElement("textarea");a.CB=!0;this.XB=a;a.addEventListener("input",function(){var a=this.textEditingTool,c=a.nD;c.text=this.value;ih(c,a.Xy,Infinity);this.rows=c.nF},!1);a.addEventListener("keydown",function(a){var c=a.which,d=this.textEditingTool;if(null!==d)if(13===c)d.acceptText(jh);else{if(9===c)return d.acceptText(kh),
a.preventDefault(),!1;27===c&&(d.doCancel(),d.h&&d.h.focus())}},!1);a.addEventListener("focus",function(){var a=this.textEditingTool;a.Ya===lh?a.Ya=mh:a.Ya===nh?a.Ya=wh:a.Ya===wh&&(a.Ya=mh);"function"===typeof this.select&&this.select()},!1);a.addEventListener("blur",function(){"function"===typeof this.focus&&this.focus();"function"===typeof this.select&&this.select()},!1)}t.ga("TextEditingTool",fh);t.Ka(fh,ae);var xh;fh.LostFocus=xh=t.w(fh,"LostFocus",0);var yh;
fh.MouseDown=yh=t.w(fh,"MouseDown",1);var kh;fh.Tab=kh=t.w(fh,"Tab",2);var jh;fh.Enter=jh=t.w(fh,"Enter",3);fh.SingleClick=t.w(fh,"SingleClick",0);var gh;fh.SingleClickSelected=gh=t.w(fh,"SingleClickSelected",1);var hh=t.w(fh,"StateNone",0),lh=t.w(fh,"StateActive",1),mh=t.w(fh,"StateEditing",2),wh=t.w(fh,"StateEditing2",3),zh=t.w(fh,"StateValidating",4),nh=t.w(fh,"StateValidated",5);t.g(fh,"textBlock",fh.prototype.Ng);
t.defineProperty(fh,{Ng:"textBlock"},function(){return this.Az},function(a){null!==a&&t.l(a,na,fh,"textBlock");this.Az=a});t.g(fh,"currentTextEditor",fh.prototype.Pf);t.defineProperty(fh,{Pf:"currentTextEditor"},function(){return this.SB},function(a){this.SB=a});t.g(fh,"defaultTextEditor",fh.prototype.nE);t.defineProperty(fh,{nE:"defaultTextEditor"},function(){return this.XB},function(a){this.XB=a});t.g(fh,"starting",fh.prototype.vG);
t.defineProperty(fh,{vG:"starting"},function(){return this.iD},function(a){t.Aa(a,fh,fh,"starting");this.iD=a});fh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.h;if(null===a||a.ab||!a.R.left||this.isBeyondDragSize())return!1;a=a.pe(a.R.ea,null,function(a){return a instanceof na});if(!(a instanceof na&&a.yw&&a.S.canEdit()))return!1;a=a.S;return null===a||this.vG===gh&&!a.eb?!1:!0};fh.prototype.doStart=function(){this.ia||null===this.Ng||this.doActivate()};
fh.prototype.doActivate=function(){if(!this.ia){var a=this.h;if(null!==a){var b=this.Ng;null===b&&(b=a.pe(a.R.ea,function(a){return a instanceof na?a:null}),b=b instanceof na?b:null);if(null!==b&&(this.Ng=b,null!==b.S)){this.ia=!0;this.Ya=lh;var c=this.nE,d=!1;null!==b.hB&&(c=b.hB);null===c||c.CB||(d=!0);this.nD=this.Ng.copy();if(c.CB){var f=b.pb(Hb),g=a.position,h=a.scale,k=b.ek()*h;this.Xy=b.Pa.width;var l=this.Xy*k;Ah(b);var m=(f.x-g.x)*h,f=(f.y-g.y)*h;c.value=b.text;a.$j.style.font=b.font;c.style.font=
"inherit";c.style.fontSize=100*k+"%";c.style.lineHeight="normal";l=20+l;c.style.width=l+"px";c.style.height="";c.rows=b.nF;a.$j.appendChild(c);c.style.left=(m-l/2|0)+"px";c.style.top=(f-c.clientHeight/2|0)+"px";c.style.textAlign=b.textAlign}else a.$j.appendChild(c);c.style.position="absolute";c.style.zIndex=100;c.className="start";c.textEditingTool=this;c.SK=k;if(d&&void 0!==c.onActivate)c.onActivate();this.Pf=c;"function"===typeof c.focus&&c.focus();"function"===typeof c.select&&c.select()}}}};
fh.prototype.doCancel=function(){null!==this.Pk&&(this.Pf.style.border=this.Pk,this.Pk=null);this.stopTool()};fh.prototype.doMouseUp=function(){!this.ia&&this.canStart()&&this.doActivate()};fh.prototype.doMouseDown=function(){this.ia&&this.acceptText(yh)};
fh.prototype.acceptText=function(a){switch(a){case yh:if(this.Ya===nh||this.Ya===wh)"function"===typeof this.Pf.focus&&this.Pf.focus();else if(this.Ya===lh||this.Ya===mh)this.Ya=zh,Bh(this);break;case xh:case jh:case kh:if(jh===a&&!0===this.Az.Tw)break;if(this.Ya===lh||this.Ya===mh)this.Ya=zh,Bh(this)}};
function Bh(a){if(null!==a.Ng&&null!==a.Pf){var b=a.Ng,c=a.Ng.text,d=a.Pf.value,f="",f="function"===typeof d?d():d;if(!a.isValidText(a.Ng,c,f)){a.Ya=mh;null!==b.at&&b.at(a,c,f);null===a.Pk&&(a.Pk=a.Pf.style.border,a.Pf.style.border="3px solid red");"function"===typeof a.Pf.focus&&a.Pf.focus();return}a.uc(a.name);a.Ya=nh;c!==f&&(a.Ng.text=f);a.gf=a.name;b=a.h;null!==b&&b.Ba("TextEdited",a.Ng,c);a.hk();a.stopTool();null!==b&&b.focus()}null!==a.Pk&&(a.Pf.style.border=a.Pk,a.Pk=null)}
fh.prototype.doDeactivate=function(){var a=this.h;if(null!==a){this.Ya=hh;this.Ng=null;if(null!==this.Pf){var b=this.Pf;if(void 0!==b.onDeactivate)b.onDeactivate();null!==b&&a.$j.removeChild(b)}this.ia=!1}};fh.prototype.isValidText=function(a,b,c){t.l(a,na,fh,"isValidText:textblock");var d=this.Xt;if(null!==d&&!d(a,b,c))return!1;d=a.Xt;return null===d||d(a,b,c)?!0:!1};t.g(fh,"textValidation",fh.prototype.Xt);
t.defineProperty(fh,{Xt:"textValidation"},function(){return this.Tk},function(a){null!==a&&t.i(a,"function",fh,"textValidation");this.Tk=a});function He(){ae.call(this);this.name="ToolManager";this.HC=new B(ae);this.IC=new B(ae);this.JC=new B(ae);this.jC=this.kC=1E3;this.ZB=(new fa(2,2)).Ja();this.Au=this.Cy=null;this.KC=bf}t.ga("ToolManager",He);t.Ka(He,ae);var bf;He.WheelScroll=bf=t.w(He,"WheelScroll",0);var af;He.WheelZoom=af=t.w(He,"WheelZoom",1);He.WheelNone=t.w(He,"WheelNone",2);
t.g(He,"mouseWheelBehavior",He.prototype.cn);t.defineProperty(He,{cn:"mouseWheelBehavior"},function(){return this.KC},function(a){t.Aa(a,He,He,"mouseWheelBehavior");this.KC=a});He.prototype.initializeStandardTools=function(){this.ED=new bh;this.SA=new hf;this.qF=new Bg;this.YF=new Rg;this.cG=new Zg;this.zA=new qa;this.Hd=new cf;this.tE=new dh;this.JF=new eh;this.pw=new ch;this.Bx=new fh;this.VD=new ra;this.WD=new ah};
He.prototype.doMouseDown=function(){var a=this.h;if(null!==a){var b=a.la;b.Tz&&0!==b.Je&&t.trace("WARNING: In ToolManager.doMouseDown: UndoManager.transactionLevel is not zero");for(var b=this.ef.length,c=0;c<b;c++){var d=this.ef.wa(c);null===d.h&&d.yd(this.h);if(d.canStart()){a.Ua=d;a.Ua===d&&(d.ia||d.doActivate(),d.doMouseDown());return}}1===a.R.button&&(this.cn===bf?this.cn=af:this.cn===af&&(this.cn=bf));this.doActivate();this.standardWaitAfter(this.mA)}};
He.prototype.doMouseMove=function(){var a=this.h;if(null!==a){if(this.ia)for(var b=this.Dg.length,c=0;c<b;c++){var d=this.Dg.wa(c);null===d.h&&d.yd(this.h);if(d.canStart()){a.Ua=d;a.Ua===d&&(d.ia||d.doActivate(),d.doMouseMove());return}}this.standardMouseOver();this.isBeyondDragSize()&&this.standardWaitAfter(this.ia?this.mA:this.TE)}};He.prototype.doCurrentObjectChanged=function(a,b){var c=this.el;null===c||null!==b&&(b===c||b.ej(c))||this.hideToolTip()};
He.prototype.doWaitAfter=function(){this.h&&this.h.gb&&(this.doMouseHover(),this.ia||this.doToolTip())};He.prototype.doMouseHover=function(){var a=this.h;if(null!==a){var b=a.R;null===b.ce&&(b.ce=a.pe(b.ea,null,null));var c=b.ce;if(null!==c)for(;null!==c;){a=this.ia?c.xt:c.yt;if(null!==a&&(a(b,c),b.Ee))break;c=c.ja}else a=this.ia?a.xt:a.yt,null!==a&&a(b)}};
He.prototype.doToolTip=function(){var a=this.h;if(null!==a){var b=a.R;null===b.ce&&(b.ce=a.pe(b.ea,null,null));b=b.ce;if(null!==b){if(b!==this.el&&!b.ej(this.el)){for(;null!==b;){a=b.Yt;if(null!==a){this.showToolTip(a,b);return}b=b.ja}this.hideToolTip()}}else a=a.Yt,null!==a?this.showToolTip(a,null):this.hideToolTip()}};
He.prototype.showToolTip=function(a,b){t.l(a,Je,He,"showToolTip:tooltip");null!==b&&t.l(b,Q,He,"showToolTip:obj");var c=this.h;if(null!==c){a!==this.el&&this.hideToolTip();a.af="Tool";a.wl=!1;a.scale=1/c.scale;c.add(a);if(null!==b&&b!==this.Au){var c=null,d=b.ep();null!==d&&(c=d.data);a.pc=b;a.data=c}else null===b&&(a.data=c.ba);if(null===b||b!==this.Au)a.sf(),this.positionToolTip(a,b);this.Cy=a;this.Au=b}};
He.prototype.positionToolTip=function(a){var b=this.h;if(null!==b){var c=b.R.ea.copy(),d=a.Ga,f=b.xb;b.R.Uw&&(c.x-=d.width);c.x+d.width>f.right&&(c.x-=d.width+5);c.x<f.x&&(c.x=f.x);c.y=c.y+20+d.height>f.bottom?c.y-(d.height+5):c.y+20;c.y<f.y&&(c.y=f.y);a.position=c}};He.prototype.hideToolTip=function(){if(null!==this.el){var a=this.h;null!==a&&(this.el.data=null,this.el.pc=null,a.remove(this.el),this.Au=this.Cy=null)}};t.A(He,{el:"currentToolTip"},function(){return this.Cy});
He.prototype.doMouseUp=function(){this.cancelWaitAfter();if(this.ia){var a=this.h;if(null===a)return;for(var b=this.Eg.length,c=0;c<b;c++){var d=this.Eg.wa(c);null===d.h&&d.yd(this.h);if(d.canStart()){a.Ua=d;a.Ua===d&&(d.ia||d.doActivate(),d.doMouseUp());return}}}this.doDeactivate()};He.prototype.doMouseWheel=function(){this.standardMouseWheel()};He.prototype.doKeyDown=function(){var a=this.h;null!==a&&a.Se.doKeyDown()};He.prototype.doKeyUp=function(){var a=this.h;null!==a&&a.Se.doKeyUp()};
He.prototype.doCancel=function(){null!==ff&&ff.doCancel();ae.prototype.doCancel.call(this)};He.prototype.findTool=function(a){t.i(a,"string",He,"findTool:name");for(var b=this.ef.length,c=0;c<b;c++){var d=this.ef.wa(c);if(d.name===a)return d}b=this.Dg.length;for(c=0;c<b;c++)if(d=this.Dg.wa(c),d.name===a)return d;b=this.Eg.length;for(c=0;c<b;c++)if(d=this.Eg.wa(c),d.name===a)return d;return null};
He.prototype.replaceTool=function(a,b){t.i(a,"string",He,"replaceTool:name");null!==b&&(t.l(b,ae,He,"replaceTool:newtool"),b.h&&b.h!==this.h&&t.m("Cannot share tools between Diagrams: "+b.toString()),b.yd(this.h));for(var c=this.ef.length,d=0;d<c;d++){var f=this.ef.wa(d);if(f.name===a)return null!==b?this.ef.Mg(d,b):this.ef.qd(d),f}c=this.Dg.length;for(d=0;d<c;d++)if(f=this.Dg.wa(d),f.name===a)return null!==b?this.Dg.Mg(d,b):this.Dg.qd(d),f;c=this.Eg.length;for(d=0;d<c;d++)if(f=this.Eg.wa(d),f.name===
a)return null!==b?this.Eg.Mg(d,b):this.Eg.qd(d),f;return null};function Ch(a,b,c,d){t.i(b,"string",He,"replaceStandardTool:name");t.l(d,B,He,"replaceStandardTool:list");null!==c&&(t.l(c,ae,He,"replaceStandardTool:newtool"),c.h&&c.h!==a.h&&t.m("Cannot share tools between Diagrams: "+c.toString()),c.name=b,c.yd(a.h));a.findTool(b)?a.replaceTool(b,c):null!==c&&d.add(c)}t.A(He,{ef:"mouseDownTools"},function(){return this.HC});t.A(He,{Dg:"mouseMoveTools"},function(){return this.IC});
t.A(He,{Eg:"mouseUpTools"},function(){return this.JC});t.g(He,"hoverDelay",He.prototype.TE);t.defineProperty(He,{TE:"hoverDelay"},function(){return this.kC},function(a){t.i(a,"number",He,"hoverDelay");this.kC=a});t.g(He,"holdDelay",He.prototype.mA);t.defineProperty(He,{mA:"holdDelay"},function(){return this.jC},function(a){t.i(a,"number",He,"holdDelay");this.jC=a});t.g(He,"dragSize",He.prototype.uE);
t.defineProperty(He,{uE:"dragSize"},function(){return this.ZB},function(a){t.l(a,fa,He,"dragSize");this.ZB=a.Z()});t.g(He,"actionTool",He.prototype.ED);t.defineProperty(He,{ED:"actionTool"},function(){return this.findTool("Action")},function(a){Ch(this,"Action",a,this.ef)});t.g(He,"relinkingTool",He.prototype.SA);t.defineProperty(He,{SA:"relinkingTool"},function(){return this.findTool("Relinking")},function(a){Ch(this,"Relinking",a,this.ef)});t.g(He,"linkReshapingTool",He.prototype.qF);
t.defineProperty(He,{qF:"linkReshapingTool"},function(){return this.findTool("LinkReshaping")},function(a){Ch(this,"LinkReshaping",a,this.ef)});t.g(He,"resizingTool",He.prototype.YF);t.defineProperty(He,{YF:"resizingTool"},function(){return this.findTool("Resizing")},function(a){Ch(this,"Resizing",a,this.ef)});t.g(He,"rotatingTool",He.prototype.cG);t.defineProperty(He,{cG:"rotatingTool"},function(){return this.findTool("Rotating")},function(a){Ch(this,"Rotating",a,this.ef)});
t.g(He,"linkingTool",He.prototype.zA);t.defineProperty(He,{zA:"linkingTool"},function(){return this.findTool("Linking")},function(a){Ch(this,"Linking",a,this.Dg)});t.g(He,"draggingTool",He.prototype.Hd);t.defineProperty(He,{Hd:"draggingTool"},function(){return this.findTool("Dragging")},function(a){Ch(this,"Dragging",a,this.Dg)});t.g(He,"dragSelectingTool",He.prototype.tE);
t.defineProperty(He,{tE:"dragSelectingTool"},function(){return this.findTool("DragSelecting")},function(a){Ch(this,"DragSelecting",a,this.Dg)});t.g(He,"panningTool",He.prototype.JF);t.defineProperty(He,{JF:"panningTool"},function(){return this.findTool("Panning")},function(a){Ch(this,"Panning",a,this.Dg)});t.g(He,"contextMenuTool",He.prototype.pw);t.defineProperty(He,{pw:"contextMenuTool"},function(){return this.findTool("ContextMenu")},function(a){Ch(this,"ContextMenu",a,this.Eg)});
t.g(He,"textEditingTool",He.prototype.Bx);t.defineProperty(He,{Bx:"textEditingTool"},function(){return this.findTool("TextEditing")},function(a){Ch(this,"TextEditing",a,this.Eg)});t.g(He,"clickCreatingTool",He.prototype.VD);t.defineProperty(He,{VD:"clickCreatingTool"},function(){return this.findTool("ClickCreating")},function(a){Ch(this,"ClickCreating",a,this.Eg)});t.g(He,"clickSelectingTool",He.prototype.WD);
t.defineProperty(He,{WD:"clickSelectingTool"},function(){return this.findTool("ClickSelecting")},function(a){Ch(this,"ClickSelecting",a,this.Eg)});function Be(){this.lH=Dh;this.U=null;new ia;this.Sl=this.Tl=null;this.FB=this.sd=this.cr=this.wj=!1;this.Le=!0;this.zu=this.yu=this.QB=null;this.Ay=0;this.Ly=600;this.rH=new w(0,0);this.HB=this.GB=this.zD=!1;this.so=new ia}function Dh(a,b,c,d){a/=d/2;return 1>a?c/2*a*a+b:-c/2*(--a*(a-2)-1)+b}
Be.prototype.prepareAnimation=Be.prototype.ul=function(){this.Le&&(this.wj&&this.Al(),this.sd=!0,this.FB=!1)};function Eh(a){a.Le&&requestAnimationFrame(function(){!1===a.sd||a.wj||(a.sd=!1,a.U.Ba("AnimationStarting"),Fh(a))})}
function Gh(a,b,c,d){if(a.sd&&(e&&(b instanceof Q||t.m("addToAnimation:object must be a GraphObject.")),!(b instanceof C)||b.oA)){var f=a.so,g;f.contains(b)?(b=f.za(b),a=b[0],g=b[1],void 0===a.position&&(a.position=Hh(c)),g.position=Hh(d)):(a={},g={},a.position=Hh(c),g.position=Hh(d),f.add(b,[a,g]))}}function Hh(a){return a instanceof w?a.copy():a instanceof fa?a.copy():a}
function Fh(a){var b,c=a.U;if(null!==c)if(0===a.so.count)a.wj=!1,c.Uf();else{a.wj=!0;b||(b={});var d=b.xK||a.lH,f=b.JK||null,g=b.KK||null,h=b.duration||a.Ly;b=a.so.k;for(var k=a.rH;b.next();){var l=b.value[0].position;l&&!l.N()&&l.assign(k)}a.QB=d;a.yu=f;a.zu=g;a.Ay=h;var m=a.kH=a.so;Ih(a);Jh(a,c,m,d,0,h,null!==a.Tl&&null!==a.Sl);Zf(a.U);Kh(a);requestAnimationFrame(function(b){var f=b||+new Date,g=f+h,k;(function u(b){!1!==a.wj&&(k=b||+new Date,b=k>g?h:k-f,Ih(a),Jh(a,c,m,d,b,h,null!==a.Tl&&null!==
a.Sl),a.yu&&a.yu(),Zf(c),Kh(a),k>g?Lh(a):requestAnimationFrame(u))})(f)})}}var Mh={opacity:function(a,b,c,d,f,g){a.opacity=d(f,b,c-b,g)},position:function(a,b,c,d,f,g){f!==g?a.oG(d(f,b.x,c.x-b.x,g),d(f,b.y,c.y-b.y,g)):a.position=new w(d(f,b.x,c.x-b.x,g),d(f,b.y,c.y-b.y,g))},scale:function(a,b,c,d,f,g){a.scale=d(f,b,c-b,g)},visible:function(a,b,c,d,f,g){a.visible=f!==g?b:c}};function Ih(a){if(!a.cr){var b=a.U;a.zD=b.Wa;a.GB=b.Tt;a.HB=b.yo;b.Wa=!0;b.Tt=!0;b.yo=!0;a.cr=!0}}
function Kh(a){var b=a.U;b.Wa=a.zD;b.Tt=a.GB;b.yo=a.HB;a.cr=!1}function Jh(a,b,c,d,f,g,h){for(c=c.k;c.next();){var k=c.key,l=c.value,m=l[0],l=l[1],n;for(n in l)if(void 0!==Mh[n])Mh[n](k,m[n],l[n],d,f,g)}h&&(h=a.Tl,a=a.Sl,n=a.y-h.y,a=d(f,h.x,a.x-h.x,g),d=d(f,h.y,n,g),f=b.Yb,b.Yb=!0,b.position=new w(a,d),b.Yb=f)}
Be.prototype.stopAnimation=Be.prototype.Al=function(){!0===this.sd&&(this.sd=!1,this.FB&&this.U.te());this.wj&&this.Le&&(Ih(this),Jh(this,this.U,this.kH,this.QB,this.Ay,this.Ay,null!==this.Tl&&null!==this.Sl),Kh(this),Lh(this))};function Lh(a){a.wj=!1;a.Tl=null;a.Sl=null;a.so=new ia;Ih(a);for(var b=a.U.links;b.next();){var c=b.value;null!==c.xo&&(c.points=c.xo,c.xo=null)}b=a.U;b.Oc();b.Uf();Kh(a);a.zu&&a.zu();a.zu=null;a.yu=null;b.Ba("AnimationFinished")}
function Nh(a,b,c){var d=b.oa,f=c.oa;c instanceof S&&c.placeholder?(c=c.placeholder,d=c.pb(Eb),d.x+=c.padding.left,d.y+=c.padding.top,Gh(a,b,d,b.position)):Gh(a,b,new w(f.x+f.width/2-d.width/2,f.y+f.height/2-d.height/2),b.position)}function Oh(a,b,c){a.sd&&(null===a.Tl&&b.N()&&null===a.Sl&&(a.Tl=b.copy()),a.Sl=c.copy())}t.g(Be,"isEnabled",Be.prototype.isEnabled);t.defineProperty(Be,{isEnabled:"isEnabled"},function(){return this.Le},function(a){t.i(a,"boolean",Be,"isEnabled");this.Le=a});
t.g(Be,"duration",Be.prototype.duration);t.defineProperty(Be,{duration:"duration"},function(){return this.Ly},function(a){t.i(a,"number",Be,"duration");1>a&&t.ka(a,">= 1",Be,"duration");this.Ly=a});t.A(Be,{yg:"isAnimating"},function(){return this.wj});t.A(Be,{AK:"isTicking"},function(){return this.cr});
function $d(){0<arguments.length&&t.ad($d);t.Ac(this);this.U=null;this.zb=new B(C);this.Zb="";this.Lf=1;this.bz=!1;this.Rk=this.Dz=this.uk=this.tk=this.sk=this.rk=this.pk=this.qk=this.ok=this.wk=this.nk=this.vk=this.mk=this.lk=!0;this.Zy=!1;this.wv=[]}t.ga("Layer",$d);$d.prototype.yd=function(a){this.U=a};
$d.prototype.toString=function(a){void 0===a&&(a=0);var b='Layer "'+this.name+'"';if(0>=a)return b;for(var c=0,d=0,f=0,g=0,h=0,k=this.zb.k;k.next();){var l=k.value;l instanceof S?f++:l instanceof R?d++:l instanceof T?g++:l instanceof Je?h++:c++}k="";0<c&&(k+=c+" Parts ");0<d&&(k+=d+" Nodes ");0<f&&(k+=f+" Groups ");0<g&&(k+=g+" Links ");0<h&&(k+=h+" Adornments ");if(1<a)for(c=this.zb.k;c.next();)d=c.value,k+="\n    "+d.toString(a-1),(f=d.data)&&t.od(f)&&(k+=" #"+t.od(f)),d instanceof R?k+=" "+yd(f):
d instanceof T&&(k+=" "+yd(d.aa)+" "+yd(d.ca));return b+" "+this.zb.count+": "+k};
$d.prototype.findObjectAt=$d.prototype.pe=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);if(!1===this.Rk)return null;e&&!a.N()&&t.m("findObjectAt: Point must have a real value.");var d=this.zb,f=!1;this.h.xb.Fa(a)&&(f=!0);for(var g=t.K(),h=d.length;h--;){var k=d.wa(h);if((!0!==f||!1!==Ph(k))&&k.La()&&(g.assign(a),Qa(g,k.me),k=k.pe(g,b,c),null!==k&&(null!==b&&(k=b(k)),k&&(null===c||c(k)))))return t.B(g),k}t.B(g);return null};
$d.prototype.findObjectsAt=$d.prototype.cp=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof B||d instanceof ma||(d=new ma(Q));if(!1===this.Rk)return d;e&&!a.N()&&t.m("findObjectsAt: Point must have a real value.");var f=this.zb,g=!1;this.h.xb.Fa(a)&&(g=!0);for(var h=t.K(),k=f.length;k--;){var l=f.wa(k);!0===g&&!1===Ph(l)||!l.La()||(h.assign(a),Qa(h,l.me),l.cp(h,b,c,d)&&(null!==b&&(l=b(l)),l&&(null===c||c(l))&&d.add(l)))}t.B(h);return d};
$d.prototype.findObjectsIn=$d.prototype.bk=function(a,b,c,d,f){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);f instanceof B||f instanceof ma||(f=new ma(Q));if(!1===this.Rk)return f;e&&!a.N()&&t.m("findObjectsIn: Rect must have a real value.");var g=this.zb,h=!1;this.h.xb.Vj(a)&&(h=!0);for(var k=g.length,g=g.n;k--;){var l=g[k];(!0!==h||!1!==Ph(l))&&l.La()&&l.bk(a,b,c,d,f)&&(null!==b&&(l=b(l)),l&&(null===c||c(l))&&f.add(l))}return f};
$d.prototype.cA=function(a,b,c,d,f,g,h){if(!1===this.Rk)return f;for(var k=this.zb,l=k.length,k=k.n;l--;){var m=k[l];(!0!==h||!1!==Ph(m))&&g(m)&&m.La()&&m.bk(a,b,c,d,f)&&(null!==b&&(m=b(m)),m&&(null===c||c(m))&&f.add(m))}return f};
$d.prototype.findObjectsNear=$d.prototype.Qm=function(a,b,c,d,f,g){void 0===c&&(c=null);void 0===d&&(d=null);void 0===f&&(f=!0);if(!1!==f&&!0!==f){if(f instanceof B||f instanceof ma)g=f;f=!0}g instanceof B||g instanceof ma||(g=new ma(Q));if(!1===this.Rk)return g;e&&!a.N()&&t.m("findObjectsNear: Point must have a real value.");var h=this.zb,k=!1;this.h.xb.Fa(a)&&(k=!0);for(var l=t.K(),m=t.K(),n=h.length;n--;){var p=h.n[n];!0===k&&!1===Ph(p)||!p.La()||(l.assign(a),Qa(l,p.me),m.p(a.x+b,a.y),Qa(m,p.me),
p.Qm(l,m,c,d,f,g)&&(null!==c&&(p=c(p)),p&&(null===d||d(p))&&g.add(p)))}t.B(l);t.B(m);return g};aa=$d.prototype;aa.Df=function(a,b){if(this.visible){var c=this.zb,d;d=b?b:a.xb;for(var f=c.length,g=0;g<f;g++){var h=c.n[g];h.BC=g;if(!(h instanceof T&&!1===h.rg||h instanceof Je&&null!==h.Wh))if(eb(h.oa,d))for(h.Df(!0),Qh(h),h=h.cw;h.next();){var k=h.value;ih(k,Infinity,Infinity);k.Lc();k.Df(!0)}else h.Df(!1),null!==h.cw&&0<h.cw.count&&Qh(h)}}};
aa.Ue=function(a,b,c,d){if(this.visible&&(void 0===d&&(d=!0),d||!this.rc)){1!==this.Lf&&(a.globalAlpha=this.Lf);c=this.zb;d=this.wv;d.length=0;for(var f=b.scale,g=c.length,h=0;h<g;h++){var k=c.n[h];if(Ph(k)){if(k instanceof T&&(k.ic&&d.push(k),!1===k.rg))continue;var l=k.oa;1<l.width*f||1<l.height*f?k.Ue(a,b):Rh(k,a)}}a.globalAlpha=1}};
function Sh(a,b,c,d){if(a.visible){b.globalAlpha=a.Lf;var f=a.zb;a=a.wv;a.length=0;for(var g=c.scale,h=f.length,k=d.length,l=0;l<h;l++){var m=f.n[l];if(Ph(m)){if(m instanceof T&&(m.ic&&a.push(m),!1===m.rg))continue;var n=Th(m,m.oa),p;a:{p=n;for(var q=d,r=k,s=2/g,u=4/g,v=0;v<r;v++){var D=q[v];if(0!==D[2]&&0!==D[3]&&p.$E(D[0]-s,D[1]-s,D[2]+u,D[3]+u)){p=!0;break a}}p=!1}p&&(1<n.width*g||1<n.height*g?m.Ue(b,c):Rh(m,b))}}b.globalAlpha=1}}
aa.j=function(a,b,c,d,f){var g=this.h;null!==g&&g.Ic(vd,a,this,b,c,d,f)};aa.np=function(a,b,c){var d=this.zb;b.lo=this;if(a>=d.count)a=d.count;else if(d.wa(a)===b)return-1;d.Jd(a,b);b.lt(c);d=this.h;null!==d&&(c?d.fa():d.np(b));b instanceof S&&this.xx(b);return a};aa.Xe=function(a,b,c){var d=this.zb;if(0>a||a>=d.length){if(a=d.indexOf(b),0>a)return-1}else if(d.wa(a)!==b&&(a=d.indexOf(b),0>a))return-1;b.mt(c);d.qd(a);d=this.h;null!==d&&(c?d.fa():d.Xe(b));b.lo=null;return a};
aa.xx=function(a){for(;null!==a;){if(a.layer===this){var b=a;if(b.Rc.next()){for(var c=-1,d=-1,f=this.zb.n,g=f.length,h=0;h<g;h++){var k=f[h];if(k===b&&(c=h,0<=d))break;if(0>d&&k.hb===b&&(d=h,0<=c))break}!(0>d)&&d<c&&(f=this.zb,f.qd(c),f.Jd(d,b))}}a=a.hb}};aa.clear=function(){for(var a=this.zb.Ie(),b=a.length,c=0;c<b;c++)a[c].Df(!1),this.Xe(-1,a[c],!1)};t.A($d,{sl:"parts"},function(){return this.zb.k});t.A($d,{NK:"partsBackwards"},function(){return this.zb.Xm});t.A($d,{h:"diagram"},function(){return this.U});
t.g($d,"name",$d.prototype.name);t.defineProperty($d,{name:"name"},function(){return this.Zb},function(a){t.i(a,"string",$d,"name");var b=this.Zb;if(b!==a){var c=this.h;if(null!==c)for(""===b&&t.m("Cannot rename default Layer to: "+a),c=c.Yw;c.next();)c.value.name===a&&t.m("Layer.name is already present in this diagram: "+a);this.Zb=a;this.j("name",b,a);for(a=this.zb.k;a.next();)a.value.af=this.Zb}});t.g($d,"opacity",$d.prototype.opacity);
t.defineProperty($d,{opacity:"opacity"},function(){return this.Lf},function(a){var b=this.Lf;b!==a&&(t.i(a,"number",$d,"opacity"),(0>a||1<a)&&t.ka(a,"0 <= val <= 1",$d,"opacity"),this.Lf=a,this.j("opacity",b,a),a=this.h,null!==a&&a.fa())});t.g($d,"isTemporary",$d.prototype.rc);t.defineProperty($d,{rc:"isTemporary"},function(){return this.bz},function(a){var b=this.bz;b!==a&&(t.i(a,"boolean",$d,"isTemporary"),this.bz=a,this.j("isTemporary",b,a))});t.g($d,"visible",$d.prototype.visible);
t.defineProperty($d,{visible:"visible"},function(){return this.Dz},function(a){var b=this.Dz;if(b!==a){t.i(a,"boolean",$d,"visible");this.Dz=a;this.j("visible",b,a);for(b=this.zb.k;b.next();)b.value.df(a);a=this.h;null!==a&&a.fa()}});t.g($d,"pickable",$d.prototype.Bf);t.defineProperty($d,{Bf:"pickable"},function(){return this.Rk},function(a){var b=this.Rk;b!==a&&(t.i(a,"boolean",$d,"pickable"),this.Rk=a,this.j("pickable",b,a))});t.g($d,"isBoundsIncluded",$d.prototype.pA);
t.defineProperty($d,{pA:"isBoundsIncluded"},function(){return this.Zy},function(a){this.Zy!==a&&(this.Zy=a,null!==this.h&&this.h.Oc())});t.g($d,"allowCopy",$d.prototype.Si);t.defineProperty($d,{Si:"allowCopy"},function(){return this.lk},function(a){var b=this.lk;b!==a&&(t.i(a,"boolean",$d,"allowCopy"),this.lk=a,this.j("allowCopy",b,a))});t.g($d,"allowDelete",$d.prototype.Zk);
t.defineProperty($d,{Zk:"allowDelete"},function(){return this.mk},function(a){var b=this.mk;b!==a&&(t.i(a,"boolean",$d,"allowDelete"),this.mk=a,this.j("allowDelete",b,a))});t.g($d,"allowTextEdit",$d.prototype.Qo);t.defineProperty($d,{Qo:"allowTextEdit"},function(){return this.vk},function(a){var b=this.vk;b!==a&&(t.i(a,"boolean",$d,"allowTextEdit"),this.vk=a,this.j("allowTextEdit",b,a))});t.g($d,"allowGroup",$d.prototype.Mo);
t.defineProperty($d,{Mo:"allowGroup"},function(){return this.nk},function(a){var b=this.nk;b!==a&&(t.i(a,"boolean",$d,"allowGroup"),this.nk=a,this.j("allowGroup",b,a))});t.g($d,"allowUngroup",$d.prototype.Ro);t.defineProperty($d,{Ro:"allowUngroup"},function(){return this.wk},function(a){var b=this.wk;b!==a&&(t.i(a,"boolean",$d,"allowUngroup"),this.wk=a,this.j("allowUngroup",b,a))});t.g($d,"allowLink",$d.prototype.Cm);
t.defineProperty($d,{Cm:"allowLink"},function(){return this.ok},function(a){var b=this.ok;b!==a&&(t.i(a,"boolean",$d,"allowLink"),this.ok=a,this.j("allowLink",b,a))});t.g($d,"allowRelink",$d.prototype.Sj);t.defineProperty($d,{Sj:"allowRelink"},function(){return this.qk},function(a){var b=this.qk;b!==a&&(t.i(a,"boolean",$d,"allowRelink"),this.qk=a,this.j("allowRelink",b,a))});t.g($d,"allowMove",$d.prototype.Rj);
t.defineProperty($d,{Rj:"allowMove"},function(){return this.pk},function(a){var b=this.pk;b!==a&&(t.i(a,"boolean",$d,"allowMove"),this.pk=a,this.j("allowMove",b,a))});t.g($d,"allowReshape",$d.prototype.No);t.defineProperty($d,{No:"allowReshape"},function(){return this.rk},function(a){var b=this.rk;b!==a&&(t.i(a,"boolean",$d,"allowReshape"),this.rk=a,this.j("allowReshape",b,a))});t.g($d,"allowResize",$d.prototype.Oo);
t.defineProperty($d,{Oo:"allowResize"},function(){return this.sk},function(a){var b=this.sk;b!==a&&(t.i(a,"boolean",$d,"allowResize"),this.sk=a,this.j("allowResize",b,a))});t.g($d,"allowRotate",$d.prototype.Po);t.defineProperty($d,{Po:"allowRotate"},function(){return this.tk},function(a){var b=this.tk;b!==a&&(t.i(a,"boolean",$d,"allowRotate"),this.tk=a,this.j("allowRotate",b,a))});t.g($d,"allowSelect",$d.prototype.Pe);
t.defineProperty($d,{Pe:"allowSelect"},function(){return this.uk},function(a){var b=this.uk;b!==a&&(t.i(a,"boolean",$d,"allowSelect"),this.uk=a,this.j("allowSelect",b,a))});
function z(a){function b(){document.removeEventListener("DOMContentLoaded",b,!1);Uh(c)}1<arguments.length&&t.m("Diagram constructor can only take one optional argument, the DIV HTML element or its id.");t.Ac(this);t.$z=[];this.Yb=!0;this.li=new Be;this.li.U=this;this.nf=17;var c=this;null!==document.body?Uh(this):document.addEventListener("DOMContentLoaded",b,!1);this.Rb=new B($d);this.Eb=this.Fb=0;this.Nb=this.Sg=this.gb=null;this.Sk={};this.RF();this.Yy={};this.Yy.twoTouchPan=!0;this.Ra=(new w(NaN,
NaN)).freeze();this.xc=1;this.Su=(new w(NaN,NaN)).freeze();this.Tu=NaN;this.hv=1E-4;this.ev=100;this.md=new ga;this.$v=(new w(NaN,NaN)).freeze();this.Ju=(new x(NaN,NaN,NaN,NaN)).freeze();this.Hl=Xe;this.An=xb;this.Hk=Xe;this.Xn=xb;this.Uu=this.Ru=Eb;this.fg=new ma(Q);this.ve=!0;this.Rl=new ia(T,x);this.Mn=!0;this.hH=250;this.Il=null;this.ou=(new ab(16,16,16,16)).freeze();this.Du=this.Nf=!1;this.Qn=!0;this.Dk=new ld;this.Ed=new ld;this.ec=new ld;this.qj=null;this.Pv=-1;this.wz=!1;this.Iy=new ia("string",
B);this.Hy=new ia("string","string");Vh(this);this.rv=new ma(R);this.Uk=new ma(S);this.av=new ma(T);this.zb=new ma(C);this.Wu=!0;this.mC=!1;this.Wv=eg;this.yj=10;this.By=this.Fy=this.Uv=null;this.zy="";this.Gq="auto";this.qi=this.Oi=this.Bi=this.kv=this.Ci=this.Di=this.Ei=this.pi=this.ti=this.ni=null;this.vi=!0;this.jz=!1;this.py=null;this.Xu=this.un=this.Gy=this.yo=this.cD=this.Li=!1;this.xC=!0;this.Bd=!1;this.ke=null;var d=this;this.EC=function(a){if(a.ba===d.ba&&d.Na){d.Na=!1;try{var b=a.td;""===
a.Af&&b===vd&&Wh(d,a.object,a.propertyName)}finally{d.Na=!0}}};this.FC=function(a){Xh(d,a)};this.BD=!0;this.Ug=-2;this.ri=new ia(Object,C);this.Ak=new ia(Object,T);this.$l=new ia(Object,Array);this.Jk=!1;this.mk=this.lk=this.eu=this.Le=!0;this.gu=this.fu=!1;this.lu=this.ju=this.uk=this.tk=this.sk=this.rk=this.pk=this.qk=this.ok=this.iu=this.wk=this.nk=this.vk=!0;this.ao=this.uC=!1;this.ku=this.hu=this.Pu=this.Ou=!0;this.Fv=this.Ev=16;this.qz=this.Dv=!1;this.sz=this.rz=this.Lj=this.Kj=null;this.Ne=
(new ab(5)).freeze();this.Iv=(new ma(C)).freeze();this.fv=999999999;this.Qu=(new ma(C)).freeze();this.Ik=this.vj=this.wi=!0;this.Vl=this.Ul=!1;this.Pd=null;this.mu=!0;this.lf=!1;this.Sd=null;this.km=1;this.Tn=!1;this.gD=0;this.po=null;this.AD=(new x(NaN,NaN,NaN,NaN)).freeze();this.Eu=(new x(NaN,NaN,NaN,NaN)).freeze();this.lm=new ma;Yh(this);this.$u=this.Lu=this.qv=this.UB=this.TB=this.VB=this.zj=this.Ek=this.Fi=null;Zh(this);this.gd=null;this.Ku=!1;this.Bn=null;this.wb=new He;this.wb.initializeStandardTools();
this.Ua=this.uw=this.wb;this.Se=new oa;this.ba=new M;this.Li=!0;this.jc=new be;this.Li=!1;this.dC=this.Ky=null;this.Yv=!0;t.WI&&(this.Yv=!1);this.pz=!1;this.tj=null;this.mg=1;this.Gk=new $h;void 0!==a&&ai(this,a);this.bm=1;this.cm=0;this.AC=new w;this.xD=500;this.nu=new w;this.ns=null;this.Yb=!1}t.ga("Diagram",z);
function Zh(a){a.Fi=new ia("string",C);var b=new R,c=new na;c.bind(new De("text","",yd));b.add(c);a.VB=b;a.Fi.add("",b);b=new R;c=new na;c.stroke="brown";c.bind(new De("text","",yd));b.add(c);a.Fi.add("Comment",b);b=new R;b.wl=!1;b.Qz=!1;c=new V;c.Ab="Ellipse";c.fill="black";c.stroke=null;c.Ca=(new fa(3,3)).Ja();b.add(c);a.Fi.add("LinkLabel",b);a.Ek=new ia("string",S);b=new S;b.qx="GROUPPANEL";b.type=bi;c=new na;c.font="bold 12pt sans-serif";c.bind(new De("text","",yd));b.add(c);c=new y(ci);c.name=
"GROUPPANEL";var d=new V;d.Ab="Rectangle";d.fill="rgba(128,128,128,0.2)";d.stroke="black";c.add(d);d=new Ug;d.padding=(new ab(5,5,5,5)).Ja();c.add(d);b.add(c);a.TB=b;a.Ek.add("",b);a.zj=new ia("string",T);b=new T;c=new V;c.fj=!0;b.add(c);c=new V;c.on="Standard";c.fill="black";c.stroke=null;c.fb=0;b.add(c);a.UB=b;a.zj.add("",b);b=new T;c=new V;c.fj=!0;c.stroke="brown";b.add(c);a.zj.add("Comment",b);b=new Je;b.type=ci;c=new V;c.fill=null;c.stroke="dodgerblue";c.fb=3;b.add(c);c=new Ug;c.margin=(new ab(1.5,
1.5,1.5,1.5)).Ja();b.add(c);a.qv=b;a.Lu=b;b=new Je;b.type=wg;c=new V;c.fj=!0;c.fill=null;c.stroke="dodgerblue";c.fb=3;b.add(c);a.$u=b}
function Uh(a){var b=document.createElement("p");b.style.width="100%";b.style.height="200px";b.style.boxSizing="content-box";var c=document.createElement("div");c.style.position="absolute";c.style.visibility="hidden";c.style.width="200px";c.style.height="150px";c.style.overflow="hidden";c.style.boxSizing="content-box";c.appendChild(b);document.body.appendChild(c);var d=b.offsetWidth;c.style.overflow="scroll";b=b.offsetWidth;d===b&&(b=c.clientWidth);document.body.removeChild(c);a.nf=d-b}
z.prototype.toString=function(a){void 0===a&&(a=0);var b="";this.id&&(b=this.id);this.$j&&this.$j.id&&(b=this.$j.id);b='Diagram "'+b+'"';if(0>=a)return b;for(var c=this.Rb.k;c.next();)b+="\n  "+c.value.toString(a-1);return b};z.prototype.checkProperties=function(){return t.bI(this)};z.fromDiv=function(a){var b=a;"string"===typeof a&&(b=document.getElementById(a));return b instanceof HTMLDivElement&&b.U instanceof z?b.U:null};t.g(z,"div",z.prototype.$j);
t.defineProperty(z,{$j:"div"},function(){return this.Nb},function(a){null!==a&&t.l(a,HTMLDivElement,z,"div");if(this.Nb!==a){t.$z=[];var b=this.Nb;if(null!==b){b.U=void 0;b.innerHTML="";null!==this.gb&&(this.gb.U=null,this.gb.removeEventListener("touchstart",this.FG,!1),this.gb.removeEventListener("touchmove",this.EG,!1),this.gb.removeEventListener("touchend",this.DG,!1));b=this.Uv;if(null!==b){for(var c=b.HC.n,d=c.length,f=0;f<d;f++)c[f].cancelWaitAfter();c=b.IC.n;d=c.length;for(f=0;f<d;f++)c[f].cancelWaitAfter();
c=b.JC.n;d=c.length;for(f=0;f<d;f++)c[f].cancelWaitAfter()}b.cancelWaitAfter();this.Ua.doCancel();this.Sg=this.gb=null;window.removeEventListener("resize",this.YG,!1);window.removeEventListener("mousemove",this.Dp,!0);window.removeEventListener("mousedown",this.Cp,!0);window.removeEventListener("mouseup",this.Fp,!0);window.removeEventListener("mousewheel",this.hh,!0);window.removeEventListener("DOMMouseScroll",this.hh,!0);window.removeEventListener("mouseout",this.Ep,!0);null!==this.po&&(this.po=
this.po.target=null)}else this.lf=!1;this.Nb=null;if(null!==a){if(b=a.U)b.$j=null;ai(this,a);this.RA()}}});
function di(a){var b=a.gb;b.addEventListener("touchstart",a.FG,!1);b.addEventListener("touchmove",a.EG,!1);b.addEventListener("touchend",a.DG,!1);b.addEventListener("gesturestart",function(){!1!==a.vi&&(a.km=a.scale,a.Ua.doCancel(),a.iC=0)},!1);b.addEventListener("gesturechange",function(b){if(!1!==a.vi){var d=b.scale;a.iC++;if(a.Yy.twoTouchPan){if(1===a.iC)if(1.02<d||0.98>d)b.preventDefault();else{a.km=null;return}}else b.preventDefault();if(null!==a.km){var f=a.Fb,g=a.Eb,h=a.gb.getBoundingClientRect();
b=new w(b.pageX-window.scrollX-f/h.width*h.left,b.pageY-window.scrollY-g/h.height*h.top);d*=a.km;f=a.Se;d!==a.scale&&f.canResetZoom(d)&&(g=a.$f,a.$f=b,f.resetZoom(d),a.$f=g)}}},!1);window.MSGesture&&(a.po=new window.MSGesture,a.po.target=b,b.addEventListener("pointerdown",function(b){a.po.addPointer(b.pointerId)},!1),b.addEventListener("MSGestureStart",function(b){!1!==a.vi&&(a.km=a.scale,a.Tn=!0,b.preventDefault())},!1),b.addEventListener("MSGestureChange",function(b){if(!1!==a.vi){var d=b.scale;
if(1===d)b.gestureObject.stop(),b.preventDefault();else{a.Tn&&(a.Ua.doCancel(),a.Tn=!1);b=a.IG(a.Un(b));var d=a.scale*d,f=a.Se;if(d!==a.scale&&f.canResetZoom(d)){var g=a.$f;a.$f=b;f.resetZoom(d);a.$f=g}}}},!1));b.addEventListener("mousemove",a.Dp,!1);b.addEventListener("mousedown",a.Cp,!1);b.addEventListener("mouseup",a.Fp,!1);b.addEventListener("mousewheel",a.hh,!1);b.addEventListener("DOMMouseScroll",a.hh,!1);b.addEventListener("mouseout",a.Ep,!1);b.addEventListener("keydown",a.fJ,!1);b.addEventListener("keyup",
a.gJ,!1);b.addEventListener("selectstart",function(a){a.preventDefault();return!1},!1);b.addEventListener("contextmenu",function(a){a.preventDefault();return!1},!1);window.addEventListener("resize",a.YG,!1)}function $h(){this.wH="63ad05bbe23a1786468a4c741b6d2";this.wH===this._tk?this.yh=!0:ei(this,!1)}
function ei(a,b){var c="p",d=window[t.Ha("76a715b2f73f148a")][t.Ha("72ba13b5")];if(t.Ha("77bb5bb2f32603de")===window[t.Ha("76a715b2f73f148a")][t.Ha("6aba19a7ec351488")])try{a.yh=!window[t.Ha("4da118b7ec2108")]([t.Ha("5bb806bfea351a904a84515e1b6d38b6")])([t.Ha("49bc19a1e6")])([t.Ha("59bd04a1e6380fa5539b")])([t.Ha("7bb8069ae7")]===t.Ha(t.dw));if(!1===a.yh)return;a.yh=!window[t.Ha("4da118b7ec2108")]([t.Ha("5bb806bfea351a904a84515e1b6d38b6")])([t.Ha("49bc19a1e6")])([t.Ha("59bd04a1e6380fa5539b6c7a197c31bb4cfd3e")])([t.Ha("7bb8069ae7")]===
t.Ha(t.dw));if(!1===a.yh)return}catch(f){}for(var g=d[t.Ha("76ad18b4f73e")],h=d[t.Ha("73a612b6fb191d")](t.Ha("35e7"))+2;h<g;h++)c+=d[h];d=c[t.Ha("73a612b6fb191d")](t.Ha(t.dw));0>=d&&t.Ha(t.dw)!==t.Ha("7da71ca0ad381e90")&&(d=c[t.Ha("73a612b6fb191d")](t.Ha("76a715b2ef3e149757")));a.yh=!(0<d&&d<c[t.Ha("73a612b6fb191d")](t.Ha("35")));a.yh&&(c=document[t.Ha("79ba13b2f7333e8846865a7d00")]("div"),d=t.Ha("34af05bbcb331a804699"),"."===d[0]&&(d=d[t.Ha("69bd14a0f724128a44")](1)),c[t.Ha("79a417a0f0181a8946")]=d,document[t.Ha("78a712aa")]?
(document[t.Ha("78a712aa")][t.Ha("7bb806b6ed32388c4a875b")](c),d=window[t.Ha("7dad0290ec3b0b91578e5b40007031bf")](c)[t.Ha("7dad0283f1390b81519f4645156528bf")](t.Ha("78a704b7e62456904c9b12701b6532a8")),document[t.Ha("78a712aa")][t.Ha('"68ad1bbcf533388c4a875b')](c),d&&-1!==d.indexOf(t.Ha(t.cI))&&-1!==d.indexOf(t.Ha(t.dI))&&(a.yh=!1)):(a.yh=null,b&&(a.yh=!1)))}$h.prototype.Gl=function(a){a.Sg.setTransform(a.mg,0,0,a.mg,0,0);null===this.yh&&ei(this,!0);return 0<this.yh&&this!==this.uH?!0:!1};
$h.prototype.t=function(){this.uH=null};
function ai(a,b){void 0!==b&&null!==b||t.m("Diagram setup requires an argument DIV.");null!==a.Nb&&t.m("Diagram has already completed setup.");"string"===typeof b?a.Nb=document.getElementById(b):b instanceof HTMLDivElement?a.Nb=b:t.m("No DIV or DIV id supplied: "+b);null===a.Nb&&t.m("Invalid DIV id; could not get element with id: "+b);void 0!==a.Nb.U&&t.m("Invalid div id; div already has a Diagram associated with it.");"static"===window.getComputedStyle(a.Nb,null).position&&(a.Nb.style.position="relative");
a.Nb.style["-webkit-tap-highlight-color"]="rgba(255, 255, 255, 0)";a.Nb.style["-ms-touch-action"]="none";a.Nb.innerHTML="";a.Nb.U=a;var c=document.createElement("canvas");if(c.getContext&&c.getContext("2d")){c.innerHTML="This text is displayed if your browser does not support the Canvas HTML element.";void 0!==c.style&&(c.style.position="absolute",c.style.top="0px",c.style.left="0px",c.style.zIndex="2",c.style.TK="none",c.style.webkitUserSelect="none",c.style.MozUserSelect="none");a.Fb=a.Nb.clientWidth||
1;a.Eb=a.Nb.clientHeight||1;a.gb=c;a.Sg=c.getContext("2d");var d=a.Sg;a.mg=a.computePixelRatio();fi(a,a.Fb,a.Eb);c.U=a;a.hy=d[t.Ha("7eba17a4ca3b1a8346")][t.Ha("78a118b7")](d,t.Gl,4,4);a.Nb.insertBefore(c,a.Nb.firstChild);d=document.createElement("canvas");d.width=1;d.height=1;a.Ky=d;a.dC=d.getContext("2d");var d=document.createElement("div"),f=document.createElement("div"),g=document.createElement("div"),h=document.createElement("div");d.style.position="absolute";d.style.overflow="auto";d.style.width=
a.Fb+"px";d.style.height=a.Eb+"px";d.style.zIndex="1";f.style.position="absolute";f.style.overflow="auto";f.style.width=a.Fb+"px";f.style.height=a.Eb+"px";f.style.zIndex="1";g.style.position="absolute";g.style.width="1px";g.style.height="1px";h.style.position="absolute";h.style.width="1px";h.style.height="1px";a.Nb.appendChild(d);a.Nb.appendChild(f);d.appendChild(g);f.appendChild(h);d.onscroll=a.NC;d.onmousedown=a.OC;d.U=a;d.aD=!0;f.onscroll=a.NC;f.onmousedown=a.OC;f.U=a;f.bD=!0;a.Kj=d;a.Lj=f;a.rz=
g;a.sz=h;a.FJ=t.iE(function(){a.fa()},300,!1);a.YG=t.iE(function(){gi(a)},250,!1);a.preventDefault=function(a){a.preventDefault();return!1};a.Dp=function(b){if(a.isEnabled){a.ao=!0;var c=a.Ed;t.Um&&c.Uw?(b.preventDefault(),b.simulated=!0,a.ns=b):(a.Ed=a.ec,a.ec=c,hi(a,a,b,c,!0),a.dB(b,null,b.target.U)||(a.doMouseMove(),a.Ua.isBeyondDragSize()&&(ii(a),a.bm=0)))}};a.Cp=function(b){if(a.isEnabled){a.ao=!0;var c=a.Ed;if(t.Um&&null!==a.ns)a.ns=b,b.preventDefault();else if(t.Um&&400>b.timeStamp-a.cm)b.preventDefault();
else if(a.Ed=a.ec,a.ec=c,hi(a,a,b,c,!0),c.ak=!0,c.Ae=b.detail,a.Dk=c.copy(),!0===c.Mq.simulated)b.preventDefault(),b.simulated=!0;else if(e&&e.FF&&(window.FF=a.cp(c.ea)),ff=null,a.doMouseDown(),1===b.button)return b.preventDefault(),!1}};a.Fp=function(b){if(a.isEnabled){a.ao=!0;var c=a.Ed;if(t.Um){if(400>b.timeStamp-a.cm){b.preventDefault();return}a.cm=b.timeStamp}if(t.Um&&null!==a.ns)a.ns=null,b.preventDefault();else{a.Ed=a.ec;a.ec=c;hi(a,a,b,c,!0);c.lj=!0;c.Ae=b.detail;if(t.Qw||t.tA)b.timeStamp-
a.cm<a.xD&&!a.Ua.isBeyondDragSize()?a.bm++:a.bm=1,a.cm=b.timeStamp,c.Ae=a.bm;c.bubbles=b.bubbles;b.target.U&&(c.nh=b.target.U);a.rG(b,null,new w,c.nh)||(a.doMouseUp(),tf(a),ti(a,c,b))}}};a.hh=function(b){if(a.isEnabled){var c=a.Ed;a.Ed=a.ec;a.ec=c;hi(a,a,b,c,!0);c.bubbles=!0;c.Yj=void 0!==b.wheelDelta?b.wheelDelta:-40*b.detail;a.doMouseWheel();ti(a,c,b)}};a.Ep=function(){if(a.isEnabled){a.ao=!1;var b=a.Ua;b.cancelWaitAfter();b instanceof He&&b.hideToolTip()}};a.FG=function(b){if(a.isEnabled){a.wz=
!1;if(1<b.touches.length){a.Ua.doCancel();if(a.vi){a.Tn=!0;a.km=a.scale;var c=a.Fb,d=a.Eb,f=a.gb.getBoundingClientRect(),g=b.targetTouches[0],h=g.clientX-c/f.width*f.left,r=g.clientY-d/f.height*f.top,g=b.targetTouches[1],c=g.clientX-c/f.width*f.left-h,d=g.clientY-d/f.height*f.top-r,d=Math.sqrt(c*c+d*d);a.gD=d;b.preventDefault();b.cancelBubble=!0;return!1}ii(a);b.cancelBubble=!1;return!0}d=a.Ed;a.Ed=a.ec;a.ec=d;d.h=a;ui(a,b.targetTouches[0],d);d.$c=0;d.button=0;d.ak=!0;d.lj=!1;d.Ae=1;d.Yj=0;d.Ee=!1;
d.bubbles=!1;d.event=b;d.timestamp=Date.now();d.nh=b.target.U?b.target.U:null;d.ce=null;a.Dk=d.copy();ff=null;a.doMouseDown();vi(a,d);ti(a,d,b)}};a.EG=function(b){if(a.isEnabled){if(1<b.touches.length){a.Ua.doCancel();ii(a);if(a.vi&&a.Tn&&1<b.targetTouches.length){var c=a.Fb,d=a.Eb,f=a.gb.getBoundingClientRect(),g=b.targetTouches[0],h=g.clientX-c/f.width*f.left,r=g.clientY-d/f.height*f.top,g=b.targetTouches[1],c=g.clientX-c/f.width*f.left,g=g.clientY-d/f.height*f.top,d=c-h,f=g-r,d=Math.sqrt(d*d+f*
f)/a.gD,g=new w((Math.min(c,h)+Math.max(c,h))/2,(Math.min(g,r)+Math.max(g,r))/2),h=a.km*d,r=a.Se;h!==a.scale&&r.canResetZoom(h)&&(c=a.$f,a.$f=g,r.resetZoom(h),a.$f=c);b.preventDefault();b.cancelBubble=!0;return!1}b.cancelBubble=!1;return!0}h=a.Ed;a.Ed=a.ec;a.ec=h;g=r=null;0<b.changedTouches.length?g=b.changedTouches[0]:0<b.targetTouches.length&&(g=b.targetTouches[0]);h.h=a;g?((r=document.elementFromPoint(g.clientX,g.clientY))&&r.U?(d=g,c=r.U):(d=b.changedTouches[0],c=a),ui(c,d,h)):null!==a.Ed?(h.ea=
a.Ed.ea,h.Ke=a.Ed.Ke,r=a.Ed.nh):null!==a.Dk&&(h.ea=a.Dk.ea,h.Ke=a.Dk.Ke,r=a.Dk.nh);h.$c=0;h.button=0;h.ak=!1;h.lj=!1;h.Ae=1;h.Yj=0;h.Ee=!1;h.bubbles=!1;h.event=b;h.timestamp=Date.now();h.nh=null===r?b.target.U:r.U?r.U:null;h.ce=null;a.Ua.isBeyondDragSize()&&ii(a);a.dB(g?g:b,null,h.nh)||(a.doMouseMove(),ti(a,h,b))}};a.DG=function(b){if(a.isEnabled){ii(a);if(a.wz)return b.preventDefault(),!1;var c=a.Ed;a.Ed=a.ec;a.ec=c;if(1<b.touches.length)a.vi&&(a.Tn=!1);else{var d=null,f=null;0<b.changedTouches.length?
f=b.changedTouches[0]:0<b.targetTouches.length&&(f=b.targetTouches[0]);c.h=a;c.Ae=1;if(f){var d=document.elementFromPoint(f.clientX,f.clientY),g,h;d&&d.U?(h=f,g=d.U):(h=b.changedTouches[0],g=a);ui(g,h,c);g=f.screenX;h=f.screenY;var r=a.AC;b.timeStamp-a.cm<a.xD&&!(25<Math.abs(r.x-g)||25<Math.abs(r.y-h))?a.bm++:a.bm=1;c.Ae=a.bm;a.cm=b.timeStamp;a.AC.p(g,h)}else d=c.nh;c.$c=0;c.button=0;c.ak=!1;c.lj=!0;c.Yj=0;c.Ee=!1;c.bubbles=!1;c.event=b;c.timestamp=Date.now();c.nh=null===d?b.target.U:d.U?d.U:null;
c.ce=null;a.rG(f?f:b,null,new w,c.nh)||(a.doMouseUp(),ti(a,c,b))}}};c.tabIndex=0;wi(a);di(a)}else a.Nb.innerHTML="This text is displayed if your browser does not support the Canvas HTML element."}z.prototype.computePixelRatio=function(){var a=this.Sg;return Math.round((window.devicePixelRatio||1)/(a.webkitBackingStorePixelRatio||a.mozBackingStorePixelRatio||a.msBackingStorePixelRatio||a.oBackingStorePixelRatio||a.backingStorePixelRatio||1))};z.prototype.doMouseMove=function(){this.Ua.doMouseMove()};
z.prototype.doMouseDown=function(){this.Ua.doMouseDown()};z.prototype.doMouseUp=function(){this.Ua.doMouseUp()};z.prototype.doMouseWheel=function(){this.Ua.doMouseWheel()};z.prototype.doKeyDown=function(){this.Ua.doKeyDown()};z.prototype.doKeyUp=function(){this.Ua.doKeyUp()};
function gi(a){if(null!==a.gb){var b=a.Nb;if(0!==b.clientWidth&&0!==b.clientHeight){var c=a.Vl?a.nf:0,d=a.Ul?a.nf:0,f=a.mg;a.mg=a.computePixelRatio();a.mg!==f&&(fi(a,a.Fb,a.Eb),wi(a),a.te());if(b.clientWidth!==a.Fb+c||b.clientHeight!==a.Eb+d)a.vj=!0,a.ve=!0,b=a.jc,null!==b&&b.st&&b.J(),a.Bd||a.te()}}}z.prototype.focus=z.prototype.focus=function(){this.gb&&this.gb.focus()};function wi(a,b,c){void 0===b&&(b=a.Sg);void 0===c&&(c=!0);c&&(b.du="");b.sn="";b.rn=""}
function Yh(a){var b=new $d;b.name="Background";a.Js(b);b=new $d;b.name="";a.Js(b);b=new $d;b.name="Foreground";a.Js(b);b=new $d;b.name="Adornment";b.rc=!0;a.Js(b);b=new $d;b.name="Tool";b.rc=!0;b.pA=!0;a.Js(b);b=new $d;b.name="Grid";b.Pe=!1;b.Bf=!1;b.rc=!0;a.DH(b,a.ct("Background"))}
function xi(a){a.gd=new y(yi);a.gd.name="GRID";var b=new V;b.Ab="LineH";b.stroke="lightgray";b.fb=0.5;b.interval=1;a.gd.add(b);b=new V;b.Ab="LineH";b.stroke="gray";b.fb=0.5;b.interval=5;a.gd.add(b);b=new V;b.Ab="LineH";b.stroke="gray";b.fb=1;b.interval=10;a.gd.add(b);b=new V;b.Ab="LineV";b.stroke="lightgray";b.fb=0.5;b.interval=1;a.gd.add(b);b=new V;b.Ab="LineV";b.stroke="gray";b.fb=0.5;b.interval=5;a.gd.add(b);b=new V;b.Ab="LineV";b.stroke="gray";b.fb=1;b.interval=10;a.gd.add(b);b=new C;b.add(a.gd);
b.af="Grid";b.Rw=!1;b.oA=!1;b.Bf=!1;b.ax="GRID";a.add(b);a.zb.remove(b);a.gd.visible=!1}
z.prototype.NC=function(){if(this.U.isEnabled){var a=this.U;if(a.qz&&null!==a.gb){a.Dv=!0;var b=a.Gd,c=a.xb,d=b.width,f=c.width,g=b.height,h=c.height,k=b.right,l=c.right,m=b.bottom,n=c.bottom,p=b.x,q=c.x,b=b.y,c=c.y,r=a.scale;if(f<d||h<g){var s=t.K();this.aD&&a.of?(s.p(this.scrollLeft/r+p,a.position.y),a.position=s):this.bD&&a.pf&&(s.p(a.position.x,this.scrollTop/r+b),a.position=s);t.B(s);a.Dv=!1;a.vj=!1}else s=t.K(),this.aD&&a.of&&(p<q&&(a.position=new w(this.scrollLeft+p,a.position.y)),k>l&&(a.position=
new w(-(a.Kj.scrollWidth-a.Fb)+this.scrollLeft-a.Fb/r+a.Gd.right,a.position.y))),this.bD&&a.pf&&(b<c&&(a.position=new w(a.position.x,this.scrollTop+b)),m>n&&(a.position=new w(a.position.x,-(a.Lj.scrollHeight-a.Eb)+this.scrollTop-a.Eb/r+a.Gd.bottom))),t.B(s),zi(a),a.Dv=!1,a.vj=!1,b=a.Gd,c=a.xb,k=b.right,l=c.right,m=b.bottom,n=c.bottom,p=b.x,q=c.x,b=b.y,c=c.y,f>=d&&p>=q&&k<=l&&(a.rz.style.width="1px"),h>=g&&b>=c&&m<=n&&(a.sz.style.height="1px")}}else Ai(this.U)};
z.prototype.OC=function(){this.U.isEnabled?this.U.qz=!0:Ai(this.U)};z.prototype.computeBounds=z.prototype.qf=function(){0<this.fg.count&&Bi(this);return Ci(this)};function Ci(a){if(a.eA.N()){var b=a.eA.copy();b.bw(a.padding);return b}for(var c=!0,d=a.Rb.n,f=d.length,g=0;g<f;g++){var h=d[g];if(h.visible&&(!h.rc||h.pA))for(var h=h.zb.n,k=h.length,l=0;l<k;l++){var m=h[l];m.Rw&&m.La()&&(m=m.oa,m.N()&&(c?(c=!1,b=m.copy()):b.ii(m)))}}c&&(b=new x(0,0,0,0));b.bw(a.padding);return b}
z.prototype.computePartsBounds=function(a){var b=null;for(a=a.k;a.next();){var c=a.value;c instanceof T||(c.sf(),null===b?b=c.oa.copy():b.ii(c.oa))}return null===b?new x(NaN,NaN,0,0):b};
function Di(a,b){if(!a.Ub.yg&&(void 0===b&&(b=!1),(b||a.lf)&&!a.Yb&&a.Gd.N()&&null!==a.gb)){a.Yb=!0;var c=a.Hl;b&&a.Hk!==Xe&&(c=a.Hk);var d=c!==Xe?Ei(a,c):a.scale,c=a.xb.copy(),f=a.Fb/d,g=a.Eb/d,h=null,k=a.Ub;k.sd&&(h=a.Ra.copy());a.position.Ma();var l=a.An;b&&a.Xn.xd()&&(l=a.Xn);Fi(a,a.Ra,a.Gd,f,g,l);a.position.freeze();null!==h&&Oh(k,h,a.Ra);a.scale=d;a.Yb=!1;d=a.xb;d.tf(c)||a.Et(c,d)}}
function Ei(a,b){if(null!==a.gb){a.wi&&Gi(a,a.qf());var c=a.Se.Ys,d=a.Gd;if(!d.N())return c;var f=d.width,d=d.height,g=a.Fb,h=a.Eb,k=g/f,l=h/d;return b===Hi?(f=Math.min(l,k),f>c&&(f=c),f<a.Cg&&(f=a.Cg),f>a.Bg&&(f=a.Bg),f):b===Ii?(f=l>k?(h-a.nf)/d:(g-a.nf)/f,f>c&&(f=c),f<a.Cg&&(f=a.Cg),f>a.Bg&&(f=a.Bg),f):a.scale}}z.prototype.zoomToFit=z.prototype.zoomToFit=function(){this.scale=Ei(this,Hi)};
z.prototype.zoomToRect=function(a,b){void 0===b&&(b=Hi);var c=a.width,d=a.height;if(!(0===c||0===d||isNaN(c)&&isNaN(d))){var f=1;if(b===Hi||b===Ii)if(isNaN(c))f=this.xb.height*this.scale/d;else if(isNaN(d))f=this.xb.width*this.scale/c;else var f=this.Fb,g=this.Eb,f=b===Ii?g/d>f/c?(g-(this.Ul?this.nf:0))/d:(f-(this.Vl?this.nf:0))/c:Math.min(g/d,f/c);this.scale=f;this.position=new w(a.x,a.y)}};
z.prototype.alignDocument=function(a,b){this.wi&&Gi(this,this.qf());var c=this.Gd,d=this.xb,f=this.Yb;this.Yb=!0;this.position=new w(c.x+(a.x*c.width+a.offsetX)-(b.x*d.width-b.offsetX),c.y+(a.y*c.height+a.offsetY)-(b.y*d.height-b.offsetY));this.Yb=f;this.fa()};
function Fi(a,b,c,d,f,g){var h;h=b.x;var k=b.y;g.xd()&&(d>c.width&&(h=c.x+(g.x*c.width+g.offsetX)-(g.x*d-g.offsetX)),f>c.height&&(k=c.y+(g.y*c.height+g.offsetY)-(g.y*f-g.offsetY)));d<c.width?(h=Math.min(h+d/2,c.right-d/2),h=Math.max(h,c.left+d/2),h-=d/2):h>c.left?h=c.left:h<c.right-d&&(h=c.right-d);f<c.height?(d=Math.min(k+f/2,c.bottom-f/2),d=Math.max(d,c.top+f/2),k=d-f/2):k>c.top?k=c.top:k<c.bottom-f&&(k=c.bottom-f);b.x=isFinite(h)?h:-a.padding.left;b.y=isFinite(k)?k:-a.padding.top}
z.prototype.findPartAt=z.prototype.et=function(a,b){var c=b?Jf(this,a,function(a){return a.S},function(a){return a.canSelect()}):Jf(this,a,function(a){return a.S});return c instanceof C?c:null};z.prototype.findObjectAt=z.prototype.pe=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);var d=this.Rb.Xm;for(Bi(this);d.next();){var f=d.value;if(f.visible&&(f=f.pe(a,b,c),null!==f))return f}return null};
function Jf(a,b,c,d){void 0===c&&(c=null);void 0===d&&(d=null);var f=a.Rb.Xm;for(Bi(a);f.next();)if(a=f.value,a.visible&&!a.rc&&(a=a.pe(b,c,d),null!==a))return a;return null}z.prototype.findObjectsAt=z.prototype.cp=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof B||d instanceof ma||(d=new ma(Q));Bi(this);for(var f=this.Rb.Xm;f.next();){var g=f.value;g.visible&&g.cp(a,b,c,d)}return d};
z.prototype.findObjectsIn=z.prototype.bk=function(a,b,c,d,f){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);f instanceof B||f instanceof ma||(f=new ma(Q));Bi(this);for(var g=this.Rb.Xm;g.next();){var h=g.value;h.visible&&h.bk(a,b,c,d,f)}return f};z.prototype.cA=function(a,b,c,d,f,g){var h=new ma(Q);Bi(this);for(var k=this.Rb.Xm;k.next();){var l=k.value;l.visible&&l.cA(a,b,c,d,h,f,g)}return h};
z.prototype.findObjectsNear=z.prototype.Qm=function(a,b,c,d,f,g){void 0===c&&(c=null);void 0===d&&(d=null);void 0===f&&(f=!0);if(!1!==f&&!0!==f){if(f instanceof B||f instanceof ma)g=f;f=!0}g instanceof B||g instanceof ma||(g=new ma(Q));Bi(this);for(var h=this.Rb.Xm;h.next();){var k=h.value;k.visible&&k.Qm(a,b,c,d,f,g)}return g};function ii(a){-1!==a.Pv&&(clearTimeout(a.Pv),a.Pv=-1)}function vi(a,b){var c=b.copy();a.Pv=setTimeout(function(){c.button=2;a.R=c;a.wz=!0;a.doMouseUp()},850)}
z.prototype.acceptEvent=function(a){var b=this.Ed;this.Ed=this.ec;this.ec=b;hi(this,this,a,b,a instanceof MouseEvent);return b};function hi(a,b,c,d,f){d.h=b;f?ui(a,c,d):(d.Ke=b.ec.Ke,d.ea=b.ec.ea);a=0;c.ctrlKey&&(a+=1);c.altKey&&(a+=2);c.shiftKey&&(a+=4);c.metaKey&&(a+=8);d.$c=a;d.button=c.button;t.Vm&&0===c.button&&c.ctrlKey&&(d.button=2);d.ak=!1;d.lj=!1;d.Ae=1;d.Yj=0;d.Ee=!1;d.bubbles=!1;d.event=c;d.timestamp=Date.now();d.nh=c.target.U?c.target.U:null;d.ce=null}
function ti(a,b,c){if(b.bubbles)return e&&e.QE&&t.trace("NOT handled "+c.type+" "+b.toString()),!0;e&&e.QE&&t.trace("handled "+c.type+" "+a.Ua.name+" "+b.toString());void 0!==c.stopPropagation&&c.stopPropagation();(void 0===c.touches||2>c.touches.length)&&c.preventDefault();c.cancelBubble=!0;return!1}
z.prototype.fJ=function(a){if(this.U.isEnabled){var b=this.U.ec;hi(this.U,this.U,a,b,!1);b.key=String.fromCharCode(a.which);b.ak=!0;switch(a.which){case 33:b.key="PageUp";break;case 34:b.key="PageDown";break;case 35:b.key="End";break;case 36:b.key="Home";break;case 37:b.key="Left";break;case 38:b.key="Up";break;case 39:b.key="Right";break;case 40:b.key="Down";break;case 45:b.key="Insert";break;case 46:b.key="Del";break;case 48:b.key="0";break;case 187:case 61:case 107:b.key="Add";break;case 189:case 173:case 109:b.key=
"Subtract";break;case 27:b.key="Esc"}this.U.doKeyDown();return 187!==a.which&&189!==a.which&&48!==a.which&&107!==a.which&&109!==a.which&&61!==a.which&&173!==a.which||!0!==a.ctrlKey?ti(this.U,b,a):(a.cancelBubble=!0,a.preventDefault(),a.stopPropagation(),!1)}};
z.prototype.gJ=function(a){if(this.U.isEnabled){var b=this.U.ec;hi(this.U,this.U,a,b,!1);b.key=String.fromCharCode(a.which);b.lj=!0;switch(a.which){case 33:b.key="PageUp";break;case 34:b.key="PageDown";break;case 35:b.key="End";break;case 36:b.key="Home";break;case 37:b.key="Left";break;case 38:b.key="Up";break;case 39:b.key="Right";break;case 40:b.key="Down";break;case 45:b.key="Insert";break;case 46:b.key="Del";break;case 93:a.preventDefault()}this.U.doKeyUp();return ti(this.U,b,a)}};
z.prototype.Un=function(a){var b=this.gb;if(null===b)return new w(0,0);var c=this.Fb,d=this.Eb,b=b.getBoundingClientRect(),c=a.clientX-c/b.width*b.left;a=a.clientY-d/b.height*b.top;return null!==this.md?(a=new w(c,a),Qa(a,this.md),a):new w(c,a)};function ui(a,b,c){var d=a.gb,f=a.Fb,g=a.Eb,h=0,k=0;null!==d&&(d=d.getBoundingClientRect(),h=b.clientX-f/d.width*d.left,k=b.clientY-g/d.height*d.top);c.Ke.p(h,k);null!==a.md?(b=t.mc(h,k),a.md.ai(b),c.ea.assign(b),t.B(b)):c.ea.p(h,k)}
z.prototype.invalidateDocumentBounds=z.prototype.Oc=function(){this.wi||(this.wi=!0,this.te())};function Ji(a){a.Bd||Bi(a);a.wi&&Gi(a,a.qf());Di(a);for(a=a.lm.k;a.next();)Ji(a.value)}z.prototype.redraw=z.prototype.RA=function(){this.Yb||this.Bd||(this.fa(),Ki(this),zi(this),this.Oc(),this.Uf())};z.prototype.isUpdateRequested=function(){return this.Nf};z.prototype.delayInitialization=function(a){var b=this.Ub,c=b.isEnabled;b.isEnabled=!1;Zf(this);this.lf=!1;b.isEnabled=c;a&&setTimeout(a,1)};
z.prototype.requestUpdate=z.prototype.te=function(a){void 0===a&&(a=!1);if(!0!==this.Nf&&!(this.Yb||!1===a&&this.Bd)){this.Nf=!0;var b=this;requestAnimationFrame(function(){b.Nf&&b.Uf()})}};z.prototype.maybeUpdate=z.prototype.Uf=function(){if(!this.Qn||this.Nf)this.Qn&&(this.Qn=!1),Zf(this)};
function Zf(a){if(!a.Bd&&(a.Nf=!1,null!==a.Nb)){a.Bd=!0;var b=a.li,c=!1,d=!1;b.yg?(d=!0,c=a.Wa,a.Wa=!0):b.sd||gi(a);a.Yb||b.yg||!a.vj||Ai(a)||Ai(a);null!==a.gd&&(a.gd.visible&&!a.Ku&&(Li(a),a.Ku=!0),!a.gd.visible&&a.Ku&&(a.Ku=!1));Bi(a);0!==a.Rl.count&&(Mi(a),Bi(a));var f=!1;if(!a.lf||a.mu)a.lf?Ni(a,!a.Du):(a.uc("Initial Layout"),!1===b.isEnabled&&b.Al(),Ni(a,!1)),f=!0;a.Du=!1;Bi(a);a.yo||b.yg||Ji(a);if(f){if(!a.lf){var g=a.Rb.n;a.Df(g,g.length,a);Oi(a);Li(a)}a.Ba("LayoutCompleted")}Bi(a);a.Yb||b.yg||
!a.vj||Ai(a)||(Ai(a),Bi(a));f&&!a.lf&&(a.lf=!0,a.Ce("Initial Layout"),a.Wa||a.la.clear(),setTimeout(function(){a.gh=!1},1));a.Ue();d&&(a.Wa=c);a.Bd=!1}}
function Oi(a){if(a.Hk!==Xe)a.scale=Ei(a,a.Hk);else if(a.Hl!==Xe)a.scale=Ei(a,a.Hl);else{var b=a.XE;isFinite(b)&&0<b&&(a.scale=b)}a.wi&&Gi(a,a.qf());b=a.WE;if(b.N())a.position=b;else{b=t.K();b.Qt(a.Gd,a.VE);var c=a.xb,c=t.ik(0,0,c.width,c.height),d=t.K();d.Qt(c,a.YE);a.position=new w(b.x-d.x,b.y-d.y);t.hc(c);t.B(d);t.B(b);Di(a,!0)}a.Ba("InitialLayoutCompleted")}
function Bi(a){if((a.Bd||!a.Ub.yg)&&0!==a.fg.count)for(var b=0;23>b;b++){var c=a.fg.k;if(null===c||0===a.fg.count)break;a.fg=new ma(Q);var d=a,f=a.fg;for(c.reset();c.next();){var g=c.value;!g.Ld()||g instanceof S||(g.ol()?(ih(g,Infinity,Infinity),g.Lc()):f.add(g))}for(c.reset();c.next();)g=c.value,g instanceof S&&Pi(d,g);for(c.reset();c.next();)d=c.value,d instanceof T&&(d.ol()?(ih(d,Infinity,Infinity),d.Lc(),d.bx()):f.add(d));for(c.reset();c.next();)d=c.value,d instanceof Je&&(d.ol()?(ih(d,Infinity,
Infinity),d.Lc()):f.add(d))}}function Pi(a,b){for(var c=b.Rc,d=t.Db();c.next();){var f=c.value;f.La()&&(f instanceof S?(Qi(f)||Ri(f)||Si(f))&&Pi(a,f):f instanceof T?d.push(f):(ih(f,Infinity,Infinity),f.Lc()))}c=d.length;for(f=0;f<c;f++){var g=d[f];ih(g,Infinity,Infinity);g.Lc()}t.ya(d);ih(b,Infinity,Infinity);b.Lc()}z.prototype.Df=function(a,b,c,d){var f=this.li;if(this.Ik||f.yg)for(f=0;f<b;f++)a[f].Df(c,d)};
z.prototype.Ue=function(a,b){null===this.Nb&&t.m("No div specified");var c=this.gb;null===c&&t.m("No canvas specified");if(this.li.sd)0===this.la.Je&&this.te(!0);else{a||(a=this.Sg);var d=a!==this.Sg,f=this.Rb.n,g=f.length,h=this;this.Df(f,g,h);if(d)wi(this,a),zi(this);else if(!this.ve&&void 0===b)return;var k=this.Ra,g=this.xc,l=Math.round(k.x*g)/g,m=Math.round(k.y*g)/g,n=this.md;n.reset();1!==g&&n.scale(g);0===k.x&&0===k.y||n.translate(-l,-m);var p=this.tj;if(k=null!==p&&0<p.length&&!0===this.pz){m=
t.Qw;a.save();a.beginPath();g=p.length;for(l=0;l<g;l++){var q=p[l];if(0!==q[2]||0!==q[3])q=Ti(this,q),a.rect(Math.floor(q[0])-1,Math.floor(q[1])-1,Math.ceil(q[2])+2,Math.ceil(q[3])+2),m&&a.clearRect(Math.floor(q[0])-1,Math.floor(q[1])-1,Math.ceil(q[2])+2,Math.ceil(q[3])+2)}a.clip()}g=this.mg;t.Um?(c.width=c.width,k=!1,wi(this,a),a.scale(g,g)):(a.setTransform(1,0,0,1,0,0),a.scale(g,g),a.clearRect(0,0,this.Fb,this.Eb));a.miterLimit=9;a.setTransform(1,0,0,1,0,0);a.scale(g,g);a.transform(n.m11,n.m12,
n.m21,n.m22,n.dx,n.dy);e&&e.Rz&&e.AI(this,a);c=b?function(c){var d=a;if(c.visible){d.globalAlpha=c.Lf;var f=c.zb;c=c.wv;c.length=0;for(var g=h.scale,k=f.length,l=0;l<k;l++){var m=f.n[l];if(Ph(m)&&!b.contains(m)){if(m instanceof T&&(m.ic&&c.push(m),!1===m.rg))continue;var n=m.oa;1<n.width*g||1<n.height*g?m.Ue(d,h):Rh(m,d)}}d.globalAlpha=1}}:k?function(b){Sh(b,a,h,p)}:function(b){b.Ue(a,h)};Ui(this,a);g=f.length;for(l=0;l<g;l++)c(f[l]);this.tj=[];this.Gk?this.Gk.Gl(this)&&this.hy():this.Un=function(){};
k&&(a.restore(),wi(this,a),e&&e.wK&&e.vK(p,a,this));d?(wi(this),zi(this)):this.ve=this.Ik=!1}};
function Vi(a,b,c,d,f){null===a.Nb&&t.m("No div specified");var g=a.gb;null===g&&t.m("No canvas specified");var h=a.Sg;if(a.ve){var k=a.mg;t.Um?(g.width=g.width,wi(a,h)):(h.setTransform(1,0,0,1,0,0),h.clearRect(0,0,a.Fb*k,a.Eb*k));h.UE=!1;h.drawImage(a.Ky,0<d?0:Math.round(-d),0<f?0:Math.round(-f));f=a.Ra;var g=a.xc,l=Math.round(f.x*g)/g,m=Math.round(f.y*g)/g;d=a.md;d.reset();1!==g&&d.scale(g);0===f.x&&0===f.y||d.translate(-l,-m);h.save();h.beginPath();f=c.length;for(g=0;g<f;g++)l=c[g],0!==l[2]&&0!==
l[3]&&h.rect(Math.floor(l[0]),Math.floor(l[1]),Math.ceil(l[2]),Math.ceil(l[3]));h.clip();h.setTransform(1,0,0,1,0,0);h.scale(k,k);h.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);e&&e.Rz&&e.AI(a,h);c=a.Rb.n;f=c.length;a.Df(c,f,a);Ui(a,h);for(g=0;g<f;g++)Sh(c[g],h,a,b);h.restore();wi(a);a.Gk?a.Gk.Gl(a)&&a.hy():a.Un=function(){};a.Ik=!1;a.ve=!1;a.FJ()}}
function Wi(a,b,c,d,f,g,h,k,l,m){null===a.Nb&&t.m("No div specified");null===a.gb&&t.m("No canvas specified");wi(a);zi(a);var n=void 0;g&&d&&f&&(n=new x(g.x,g.y,d.width/f,d.height/f));var p=n.copy();p.bw(c);Li(a,p);Bi(a);var p=a.Rb.n,q=p.length;a.Df(p,q,a,n);var r=a.mg;b.setTransform(1,0,0,1,0,0);b.scale(r,r);b.clearRect(0,0,d.width,d.height);k&&(b.fillStyle=k,b.fillRect(0,0,d.width,d.height));d=t.oh();d.reset();d.translate(c.left,c.top);d.scale(f);0===g.x&&0===g.y||d.translate(-g.x,-g.y);b.setTransform(d.m11,
d.m12,d.m21,d.m22,d.dx,d.dy);t.We(d);Ui(a,b);if(h){var s=new ma;c=h.k;for(c.reset();c.next();)f=c.value.S,!1===m&&"Grid"===f.layer.name||null===f||s.add(f);c=function(c){var d=l;if(c.visible&&(void 0===d&&(d=!0),d||!c.rc)){b.globalAlpha=c.Lf;d=c.zb;c=c.wv;c.length=0;for(var f=a.scale,g=d.length,h=0;h<g;h++){var k=d.n[h];if(Ph(k)&&s.contains(k)){if(k instanceof T&&(k.ic&&c.push(k),!1===k.rg))continue;var m=k.oa;1<m.width*f||1<m.height*f?k.Ue(b,a):Rh(k,b)}}b.globalAlpha=1}}}else if(!l&&m){var u=a.lp.S,
v=u.layer;c=function(c){c===v?u.Ue(b,a):c.Ue(b,a,n,l,m)}}else c=function(c){c.Ue(b,a,n,l,m)};for(f=0;f<q;f++)c(p[f]);a.Gk?a.Gk.Gl(a)&&a.hy():a.Un=function(){};wi(a);zi(a);a.Df(p,q,a);Li(a)}z.prototype.getRenderingHint=function(a){return this.Sk[a]};z.prototype.setRenderingHint=z.prototype.PJ=function(a,b){this.Sk[a]=b;this.RA()};z.prototype.resetRenderingHints=z.prototype.RF=function(){this.Sk={drawShadows:!0}};
function Ui(a,b){var c=a.Sk;if(null!==c){if(void 0!==c.imageSmoothingEnabled){var d=!!c.imageSmoothingEnabled;b.UE=d;b.IK=d}c=c.defaultFont;void 0!==c&&null!==c&&(b.font=c,b.du=c)}}z.prototype.setInputOption=function(a,b){this.Yy[a]=b};function Mi(a){if(0===a.la.Je){for(var b=a.Rl.k;b.next();){var c=b.key,d=b.value;c.Ye();Ag(c,d)}a.Rl.clear()}}
z.prototype.fa=function(a,b){if(this.pz&&void 0!==a&&null!==this.tj){var c=this.xb;if(a&&c.Tf(a)||b&&c.Tf(b))null!==this.tj&&(a&&a.N()&&Xi(this,a.x-2,a.y-2,a.width+4,a.height+4),b&&b.N()&&Xi(this,b.x-2,b.y-2,b.width+4,b.height+4)),this.ve=!0,this.te()}else this.tj=null,this.ve=!0,this.te();for(c=this.lm.k;c.next();)c.value.fa(a,b)};t.g(z,"viewportOptimizations",z.prototype.dK);
t.defineProperty(z,{dK:"viewportOptimizations"},function(){return this.Yv},function(a){t.i(a,"boolean",z,"viewportOptimizations");this.tj=null;this.Yv=a});
function Xi(a,b,c,d,f){b=Math.floor(b);c=Math.floor(c);d=Math.ceil(d);f=Math.ceil(f);for(var g=a.tj,h=g.length,k=0;k<h;k++){var l=g[k];if(b>=l[0]&&c>=l[1]&&b+d<=l[0]+l[2]&&c+f<=l[1]+l[3])return!1;b<=l[0]&&c<=l[1]&&b+d>=l[0]+l[2]&&c+f>=l[1]+l[3]?(g[k][2]=0,g[k][3]=0):b>=l[0]&&b<l[0]+l[2]&&c>=l[1]&&c+f<=l[1]+l[3]?(d=b+d-(l[0]+l[2]),b=l[0]+l[2],k=-1):b+d>l[0]&&b+d<=l[0]+l[2]&&c>=l[1]&&c+f<=l[1]+l[3]?(d=l[0]-b,k=-1):b>=l[0]&&b+d<=l[0]+l[2]&&c>=l[1]&&c<l[1]+l[3]?(f=c+f-(l[1]+l[3]),c=l[1]+l[3],k=-1):b>=
l[0]&&b+d<=l[0]+l[2]&&c+f>l[1]&&c+f<=l[1]+l[3]?(f=l[1]-c,k=-1):g[k][0]>=b&&g[k][0]<b+d&&g[k][1]>=c&&g[k][1]+g[k][3]<=c+f?(g[k][2]-=b+d-g[k][0],g[k][0]=b+d,k=-1):g[k][0]+g[k][2]>b&&g[k][0]+g[k][2]<=b+d&&g[k][1]>=c&&g[k][1]+g[k][3]<=c+f?(g[k][2]=b-g[k][0],k=-1):g[k][0]>=b&&g[k][0]+g[k][2]<=b+d&&g[k][1]>=c&&g[k][1]<c+f?(g[k][3]-=c+f-g[k][1],g[k][1]=c+f,k=-1):g[k][0]>=b&&g[k][0]+g[k][2]<=b+d&&g[k][1]+g[k][3]>c&&g[k][1]+g[k][3]<=c+f&&(g[k][3]=c-g[k][1],k=-1)}for(k=0;k<h;k++)if(l=g[k],0<d&&0<f&&0<l[2]&&
0<l[3]&&(Math.max(b+d,l[0]+l[2])-Math.min(b,l[0]))*(Math.max(c+f,l[1]+l[3])-Math.min(c,l[1]))<d*f+l[2]*l[3]+50){var h=Math.min(l[0],b),m=Math.min(l[1],c);b=Math.max(b+d,l[0]+l[2])-Math.min(l[0],b);c=Math.max(c+f,l[1]+l[3])-Math.min(l[1],c);g[k][2]=0;g[k][3]=0;return Xi(a,h,m,b,c)}g.push([b,c,d,f]);return!0}
function Yi(a,b,c){if(!0!==a.ve){a.ve=!0;if(a instanceof Zi)$i(a);else if(!0===a.Yv&&c.width===b.width&&c.height===b.height){var d=a.scale,f=t.Gf(),g=Math.max(b.x,c.x),h=Math.max(b.y,c.y),k=Math.min(b.x+b.width,c.x+c.width),l=Math.min(b.y+b.height,c.y+c.height);f.x=g;f.y=h;f.width=Math.max(0,k-g)*d;f.height=Math.max(0,l-h)*d;if(0<f.width&&0<f.height){if(!a.Bd&&(a.Nf=!1,null!==a.Nb)){a.Bd=!0;Bi(a);0!==a.Rl.count&&(Mi(a),Bi(a));a.Gd.N()||Gi(a,a.qf());var m=a.gb;if(null!==m){k=a.mg;g=a.Fb*k;h=a.Eb*k;
l=a.scale*k;d=Math.round(Math.round(c.x*l)-Math.round(b.x*l));b=Math.round(Math.round(c.y*l)-Math.round(b.y*l));c=a.Ky;var n=a.dC;c.width!==g&&(c.width=g);c.height!==h&&(c.height=h);n.clearRect(0,0,g,h);c=190*a.mg;var l=70*a.mg,p=Math.max(d,0),q=Math.max(b,0),r=g-p,s=h-q;n.UE=!1;n.drawImage(m,p,q,r,s,0,0,r,s);a.Gk.Gl(a)&&n.clearRect(0,0,c,l);var m=t.Db(),n=t.Db(),s=Math.abs(d),r=Math.abs(b),u=0===p?0:g-s,p=t.mc(u,0),s=t.mc(s+u,h);n.push([Math.min(p.x,s.x),Math.min(p.y,s.y),Math.abs(p.x-s.x),Math.abs(p.y-
s.y)]);var v=a.md;v.reset();v.scale(k,k);1!==a.xc&&v.scale(a.xc);k=a.Ra;(0!==k.x||0!==k.y)&&isFinite(k.x)&&isFinite(k.y)&&v.translate(-k.x,-k.y);Qa(p,v);Qa(s,v);m.push([Math.min(p.x,s.x),Math.min(p.y,s.y),Math.abs(p.x-s.x),Math.abs(p.y-s.y)]);u=0===q?0:h-r;p.p(0,u);s.p(g,r+u);n.push([Math.min(p.x,s.x),Math.min(p.y,s.y),Math.abs(p.x-s.x),Math.abs(p.y-s.y)]);Qa(p,v);Qa(s,v);m.push([Math.min(p.x,s.x),Math.min(p.y,s.y),Math.abs(p.x-s.x),Math.abs(p.y-s.y)]);a.Gk.Gl(a)&&(g=0<d?0:-d,h=0<b?0:-b,p.p(g,h),
s.p(c+g,l+h),n.push([Math.min(p.x,s.x),Math.min(p.y,s.y),Math.abs(p.x-s.x),Math.abs(p.y-s.y)]),Qa(p,v),Qa(s,v),m.push([Math.min(p.x,s.x),Math.min(p.y,s.y),Math.abs(p.x-s.x),Math.abs(p.y-s.y)]));t.B(p);t.B(s);!a.Yb&&a.vj&&(Ai(a)||Ai(a));Vi(a,m,n,d,b);t.ya(m);t.ya(n);a.Bd=!1}}}else a.Uf();t.hc(f)}else a.Uf();for(a=a.lm.k;a.next();)Yi(a.value)}}function Ki(a){!1===a.vj&&(a.vj=!0)}function zi(a){!1===a.Ik&&(a.Ik=!0)}
function fi(a,b,c){var d=a.gb,f=a.mg,g=b*f,f=c*f;if(d.width!==g||d.height!==f)d.width=g,d.height=f,d.style.width=b+"px",d.style.height=c+"px",a.ve=!0}
function Ai(a){if(null!==a.gb&&!a.Ub.sd){var b=a.Nb,c=a.Fb,d=a.Eb,f=a.AD.copy(),g=!1,h=a.Vl?a.nf:0,k=a.Ul?a.nf:0,l=b.clientWidth||c+h,b=b.clientHeight||d+k;if(l!==c+h||b!==d+k)a.Vl=!1,a.Ul=!1,a.Fb=l,a.Eb=b,fi(a,l,b),g=!0;a.vj=!1;if(a.Dv)c===a.Fb&&d===a.Eb||a.Uf();else{var m=a.xb,n=a.Gd,h=n.width,k=n.height,p=m.width,q=m.height,r=n.x,s=m.x,u=n.right,v=m.right,D=n.y,A=m.y,G=n.bottom,K=m.bottom,n=m="1px",N=a.scale;l/N>h&&b/N>k||(a.RE&&a.of&&(p+1<h?(m=(h-p)*N+a.Fb+"px",a.Kj.scrollLeft=(a.position.x-r)*
N):r+1<s?(m=(s-r)*N+a.Fb+"px",a.Kj.scrollLeft=a.Kj.scrollWidth-a.Kj.clientWidth):u>v+1&&(m=(u-v)*N+a.Fb+"px",a.Kj.scrollLeft=a.position.x*N)),a.SE&&a.pf&&(q+1<k?(n=(k-q)*N+a.Eb+"px",a.Lj.scrollTop=(a.position.y-D)*N):D+1<A?(n=(A-D)*N+a.Eb+"px",a.Lj.scrollTop=a.Lj.scrollHeight-a.Lj.clientHeight):G>K+1&&(n=(G-K)*N+a.Eb+"px",a.Lj.scrollTop=a.position.y*N)));l="1px"!==m;b="1px"!==n;l!==a.Ul&&(a.Eb="1px"===m?a.Eb+a.nf:Math.max(a.Eb-a.nf,1),g=!0);a.Ul=l;a.rz.style.width=m;b!==a.Vl&&(a.Fb="1px"===n?a.Fb+
a.nf:Math.max(a.Fb-a.nf,1),g=!0);a.Vl=b;a.sz.style.height=n;g&&(fi(a,a.Fb,a.Eb),wi(a));l=a.Fb;b=a.Eb;a.Lj.style.height=b+"px";a.Lj.style.width=l+(a.Vl?a.nf:0)+"px";a.Kj.style.width=l+"px";a.Kj.style.height=b+(a.Ul?a.nf:0)+"px";a.qz=!1;return c!==l||d!==b?(m=a.xb,a.Et(f,m,g?!0:void 0),!1):!0}}}
z.prototype.add=z.prototype.add=function(a){t.l(a,C,z,"add:part");var b=a.h;if(b!==this){null!==b&&t.m("Cannot add part "+a.toString()+" to "+this.toString()+". It is already a part of "+b.toString());this.un&&(a.Kk="Tool");var c=a.af,b=this.ct(c);null===b&&(b=this.ct(""));null===b&&t.m('Cannot add a Part when unable find a Layer named "'+c+'" and there is no default Layer');a.layer!==b&&(c=b.np(99999999,a,a.h===this),0<=c&&this.Ic(wd,"parts",b,null,a,null,c),b.rc||this.Oc(),a.J(aj),c=a.tp,null!==
c&&c(a,null,b))}};
z.prototype.np=function(a){if(a instanceof R){if(this.rv.add(a),a instanceof S){var b=a.hb;null===b?this.Uk.add(a):b.qo.add(a);b=a.jc;null!==b&&(b.h=this)}}else a instanceof T?this.av.add(a):a instanceof Je||this.zb.add(a);a.Jb&&a.ha();if(b=a.data){a instanceof Je||(a instanceof T?this.Ak.add(b,a):this.ri.add(b,a));var c=this;bj(a,function(a){cj(c,a)})}!0!==Ri(a)&&!0!==Si(a)||this.fg.add(a);dj(a,!0,this);ej(a)?(a.oa.N()&&this.fa(Th(a,a.oa)),this.Oc()):a.La()&&a.oa.N()&&this.fa(Th(a,a.oa));this.te()};
z.prototype.Xe=function(a){a.Wi();if(a instanceof R){if(this.rv.remove(a),a instanceof S){var b=a.hb;null===b?this.Uk.remove(a):b.qo.remove(a);b=a.jc;null!==b&&(b.h=null)}}else a instanceof T?this.av.remove(a):a instanceof Je||this.zb.remove(a);if(b=a.data){a instanceof Je||(a instanceof T?this.Ak.remove(b):this.ri.remove(b));var c=this;bj(a,function(a){fj(c,a)})}this.fg.remove(a);ej(a)?(a.oa.N()&&this.fa(Th(a,a.oa)),this.Oc()):a.La()&&a.oa.N()&&this.fa(Th(a,a.oa));this.te()};
z.prototype.remove=z.prototype.remove=function(a){t.l(a,C,z,"remove:part");a.eb=!1;a.zg=!1;var b=a.layer;if(null!==b&&b.h===this){a.J(gj);a.Nm();var c=b.Xe(-1,a,!1);0<=c&&this.Ic(xd,"parts",b,a,null,c,null);c=a.tp;null!==c&&c(a,b,null)}};z.prototype.removeParts=z.prototype.VA=function(a,b){if(a===this.selection){var c=new ma;c.Oe(a);a=c}for(c=a.k;c.next();){var d=c.value;d.h===this&&(b&&!d.canDelete()||this.remove(d))}};z.prototype.copyParts=z.prototype.Wo=function(a,b,c){return this.Se.Wo(a,b,c)};
z.prototype.moveParts=z.prototype.moveParts=function(a,b,c){t.l(b,w,z,"moveParts:offset");var d=this.wb;if(null!==d){d=d.Hd;null===d&&(d=new cf,d.h=this);var f=new ia(C,Object);if(a)a=a.k;else{for(a=this.sl;a.next();)jf(d,f,a.value,c);for(a=this.jh;a.next();)jf(d,f,a.value,c);a=this.links}for(;a.next();)jf(d,f,a.value,c);d.moveParts(f,b,c)}};
function hj(a,b,c){t.l(b,$d,z,"addLayer:layer");null!==b.h&&b.h!==a&&t.m("Cannot share a Layer with another Diagram: "+b+" of "+b.h);null===c?null!==b.h&&t.m("Cannot add an existing Layer to this Diagram again: "+b):(t.l(c,$d,z,"addLayer:existingLayer"),c.h!==a&&t.m("Existing Layer must be in this Diagram: "+c+" not in "+c.h),b===c&&t.m("Cannot move a Layer before or after itself: "+b));if(b.h!==a){b=b.name;a=a.Rb;c=a.count;for(var d=0;d<c;d++)a.wa(d).name===b&&t.m("Cannot add Layer with the name '"+
b+"'; a Layer with the same name is already present in this Diagram.")}}z.prototype.addLayer=z.prototype.Js=function(a){hj(this,a,null);a.yd(this);var b=this.Rb,c=b.count-1;if(!a.rc)for(;0<=c&&b.wa(c).rc;)c--;b.Jd(c+1,a);null!==this.ke&&this.Ic(wd,"layers",this,null,a,null,c+1);this.fa();this.Oc()};
z.prototype.addLayerBefore=z.prototype.DH=function(a,b){hj(this,a,b);a.yd(this);var c=this.Rb,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.ke&&this.Ic(xd,"layers",this,a,null,d,null));for(var f=c.count,g=0;g<f;g++)if(c.wa(g)===b){c.Jd(g,a);break}null!==this.ke&&this.Ic(wd,"layers",this,null,a,null,g);this.fa();0>d&&this.Oc()};
z.prototype.addLayerAfter=function(a,b){hj(this,a,b);a.yd(this);var c=this.Rb,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.ke&&this.Ic(xd,"layers",this,a,null,d,null));for(var f=c.count,g=0;g<f;g++)if(c.wa(g)===b){c.Jd(g+1,a);break}null!==this.ke&&this.Ic(wd,"layers",this,null,a,null,g+1);this.fa();0>d&&this.Oc()};
z.prototype.removeLayer=function(a){t.l(a,$d,z,"removeLayer:layer");a.h!==this&&t.m("Cannot remove a Layer from another Diagram: "+a+" of "+a.h);if(""!==a.name){var b=this.Rb,c=b.indexOf(a);if(b.remove(a)){for(b=a.zb.copy().k;b.next();){var d=b.value,f=d.af;d.af=f!==a.name?f:""}null!==this.ke&&this.Ic(xd,"layers",this,a,null,c,null);this.fa();this.Oc()}}};z.prototype.findLayer=z.prototype.ct=function(a){for(var b=this.Yw;b.next();){var c=b.value;if(c.name===a)return c}return null};
z.prototype.addChangedListener=z.prototype.Iz=function(a){t.i(a,"function",z,"addChangedListener:listener");null===this.qj&&(this.qj=new B("function"));this.qj.add(a)};z.prototype.removeChangedListener=z.prototype.TA=function(a){t.i(a,"function",z,"removeChangedListener:listener");null!==this.qj&&(this.qj.remove(a),0===this.qj.count&&(this.qj=null))};
z.prototype.iw=function(a){this.Wa||this.la.PE(a);a.td!==ud&&(this.gh=!0);if(null!==this.qj){var b=this.qj,c=b.length;if(1===c)b=b.wa(0),b(a);else if(0!==c)for(var d=b.Ie(),f=0;f<c;f++)b=d[f],b(a)}};z.prototype.raiseChangedEvent=z.prototype.Ic=function(a,b,c,d,f,g,h){void 0===g&&(g=null);void 0===h&&(h=null);var k=new td;k.h=this;k.td=a;k.propertyName=b;k.object=c;k.oldValue=d;k.Yf=g;k.newValue=f;k.Wf=h;this.iw(k)};
z.prototype.raiseChanged=z.prototype.j=function(a,b,c,d,f){this.Ic(vd,a,this,b,c,d,f)};t.A(z,{Ub:"animationManager"},function(){return this.li});t.A(z,{la:"undoManager"},function(){return this.ke.la});t.g(z,"skipsUndoManager",z.prototype.Wa);t.defineProperty(z,{Wa:"skipsUndoManager"},function(){return this.Li},function(a){t.i(a,"boolean",z,"skipsUndoManager");this.Li=a;this.ke.Li=a});t.g(z,"delaysLayout",z.prototype.ww);
t.defineProperty(z,{ww:"delaysLayout"},function(){return this.Gy},function(a){this.Gy=a});
z.prototype.changeState=function(a,b){if(null!==a&&a.h===this){var c=a.td;if(c===vd){var d=a.object,c=a.propertyName,f=a.za(b);t.Qa(d,c,f);d instanceof Q&&(c=d.S,null!==c&&ij(c));this.gh=!0}else if(c===wd){f=a.object;c=a.Wf;d=a.newValue;if(f instanceof y)if("number"===typeof c&&d instanceof Q){var g=f;b?g.Xe(c):g.Jd(c,d);c=f.S;null!==c&&ij(c)}else"number"===typeof c&&d instanceof Ae&&(g=f,b?d.xf?g.QF(c):g.NF(c):(c=d.xf?g.wd(d.index):g.vd(d.index),jj(c,d)));else f instanceof $d?(g=!0===a.Yf,"number"===
typeof c&&d instanceof C&&(b?(ij(d),g?f.Xe(c,d,g):this.remove(d)):f.np(c,d,g))):f instanceof z?"number"===typeof c&&d instanceof $d&&(b?this.Rb.qd(c):(d.yd(this),this.Rb.Jd(c,d))):t.m("unknown ChangedEvent.Insert object: "+a.toString());this.gh=!0}else c===xd?(f=a.object,c=a.Yf,d=a.oldValue,f instanceof y?"number"===typeof c&&d instanceof Q?(g=f,b?g.Jd(c,d):g.Xe(c)):"number"===typeof c&&d instanceof Ae&&(g=f,b?(c=d.xf?g.wd(d.index):g.vd(d.index),jj(c,d)):d.xf?g.QF(c):g.NF(c)):f instanceof $d?(g=!0===
a.Wf,"number"===typeof c&&d instanceof C&&(b?f.np(c,d,g):(ij(d),g?f.Xe(c,d,g):this.remove(d)))):f instanceof z?"number"===typeof c&&d instanceof $d&&(b?(d.yd(this),this.Rb.Jd(c,d)):this.Rb.qd(c)):t.m("unknown ChangedEvent.Remove object: "+a.toString()),this.gh=!0):c!==ud&&t.m("unknown ChangedEvent: "+a.toString())}};z.prototype.startTransaction=z.prototype.uc=function(a){return this.la.uc(a)};z.prototype.commitTransaction=z.prototype.Ce=function(a){return this.la.Ce(a)};
z.prototype.rollbackTransaction=z.prototype.Np=function(){return this.la.Np()};z.prototype.updateAllTargetBindings=function(a){void 0===a&&(a="");for(var b=this.sl;b.next();)b.value.Kb(a);for(b=this.jh;b.next();)b.value.Kb(a);for(b=this.links;b.next();)b.value.Kb(a)};z.prototype.updateAllModelDataReferences=function(){for(var a=this.sl;a.next();)a.value.updateModelDataReferences();for(a=this.jh;a.next();)a.value.updateModelDataReferences();for(a=this.links;a.next();)a.value.updateModelDataReferences()};
z.prototype.Et=function(a,b,c){Ki(this);zi(this);var d=this.jc;null===d||!d.st||c||a.width===b.width&&a.height===b.height||d.J();this.Yb||Di(this);Li(this);d=this.Ua;!0===this.ao&&d instanceof He&&(this.R.ea=this.KG(this.R.Ke),d.doMouseMove());Yi(this,a,b);this.Ba("ViewportBoundsChanged",c?{}:null,a)};
function Li(a,b){var c=a.gd;if(null!==c&&c.visible){for(var d=t.Cl(),f=1,g=1,h=c.xa.length,k=0;k<h;k++){var l=c.xa.n[k],m=l.interval;2>m||(kj(l.Ab)?g=g*m/F.GE(g,m):f=f*m/F.GE(f,m))}h=c.ht;d.p(g*h.width,f*h.height);if(b)k=b.width,l=b.height,g=b.x,h=b.y;else{f=t.Gf();g=a.xb;f.p(g.x,g.y,g.width,g.height);for(h=a.lm.k;h.next();)g=h.value.xb,g.N()&&hb(f,g.x,g.y,g.width,g.height);k=f.width;l=f.height;g=f.x;h=f.y;if(!f.N())return}c.width=k+2*d.width;c.height=l+2*d.height;f=t.K();F.dt(g,h,0,0,d.width,d.height,
f);f.offset(-d.width,-d.height);t.ck(d);c.S.location=f;t.B(f)}}z.prototype.clearSelection=z.prototype.kw=function(){var a=0<this.selection.count;a&&this.Ba("ChangingSelection");Me(this);a&&this.Ba("ChangedSelection")};function Me(a){a=a.selection;if(0<a.count){for(var b=a.Ie(),c=b.length,d=0;d<c;d++)b[d].eb=!1;a.Ma();a.clear();a.freeze()}}
z.prototype.select=z.prototype.select=function(a){null!==a&&a.layer.h===this&&(t.l(a,C,z,"select:part"),!a.eb||1<this.selection.count)&&(this.Ba("ChangingSelection"),Me(this),a.eb=!0,this.Ba("ChangedSelection"))};
z.prototype.selectCollection=z.prototype.gG=function(a){this.Ba("ChangingSelection");Me(this);if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++){var d=t.lb(a,c);d instanceof C||t.m("Diagram.selectCollection given something that is not a Part: "+d);d.eb=!0}else for(a=a.k;a.next();)d=a.value,d instanceof C||t.m("Diagram.selectCollection given something that is not a Part: "+d),d.eb=!0;this.Ba("ChangedSelection")};
z.prototype.clearHighlighteds=z.prototype.TD=function(){var a=this.Kw;if(0<a.count){for(var b=a.Ie(),c=b.length,d=0;d<c;d++)b[d].zg=!1;a.Ma();a.clear();a.freeze()}};z.prototype.highlight=function(a){null!==a&&a.layer.h===this&&(t.l(a,C,z,"highlight:part"),!a.zg||1<this.Kw.count)&&(this.TD(),a.zg=!0)};
z.prototype.highlightCollection=function(a){this.TD();if(t.isArray(a))for(var b=t.ub(a),c=0;c<b;c++){var d=t.lb(a,c);d instanceof C||t.m("Diagram.highlightCollection given something that is not a Part: "+d);d.zg=!0}else for(a=a.k;a.next();)d=a.value,d instanceof C||t.m("Diagram.highlightCollection given something that is not a Part: "+d),d.zg=!0};
z.prototype.scroll=function(a,b,c){void 0===c&&(c=1);var d="up"===b||"down"===b,f;"pixel"===a?f=c:"line"===a?f=c*(d?this.Rp:this.Qp):"page"===a?(a=d?this.xb.height:this.xb.width,a*=this.scale,0!==a&&(f=Math.max(a-(d?this.Rp:this.Qp),0),f*=c)):t.m("scrolling unit must be 'pixel', 'line', or 'page', not: "+a);f/=this.scale;c=this.position.copy();"up"===b?c.y=this.position.y-f:"down"===b?c.y=this.position.y+f:"left"===b?c.x=this.position.x-f:"right"===b?c.x=this.position.x+f:t.m("scrolling direction must be 'up', 'down', 'left', or 'right', not: "+
b);this.position=c};z.prototype.scrollToRect=function(a){var b=this.xb;b.Vj(a)||(a=a.Sz,a.x-=b.width/2,a.y-=b.height/2,this.position=a)};z.prototype.centerRect=function(a){var b=this.xb;a=a.Sz;a.x-=b.width/2;a.y-=b.height/2;this.position=a};z.prototype.transformDocToView=z.prototype.IG=function(a){var b=this.md;b.reset();1!==this.xc&&b.scale(this.xc);var c=this.Ra;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return a.copy().transform(this.md)};
function Ti(a,b){var c=a.md,d=b[0],f=b[1],g=d+b[2],h=f+b[3],k=c.m11,l=c.m12,m=c.m21,n=c.m22,p=c.dx,q=c.dy,r=d*k+f*m+p,s=d*l+f*n+q,c=g*k+f*m+p,f=g*l+f*n+q,u=d*k+h*m+p,d=d*l+h*n+q,k=g*k+h*m+p,g=g*l+h*n+q,h=r,l=s,r=Math.min(r,c),h=Math.max(h,c),l=Math.min(l,f),s=Math.max(s,f),r=Math.min(r,u),h=Math.max(h,u),l=Math.min(l,d),s=Math.max(s,d),r=Math.min(r,k),h=Math.max(h,k),l=Math.min(l,g),s=Math.max(s,g);return[r,l,h-r,s-l]}
z.prototype.transformViewToDoc=z.prototype.KG=function(a){var b=this.md;b.reset();1!==this.xc&&b.scale(this.xc);var c=this.Ra;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return Qa(a.copy(),this.md)};var Xe;z.None=Xe=t.w(z,"None",0);var Hi;z.Uniform=Hi=t.w(z,"Uniform",1);var Ii;z.UniformToFill=Ii=t.w(z,"UniformToFill",2);var eg;z.CycleAll=eg=t.w(z,"CycleAll",10);var og;z.CycleNotDirected=og=t.w(z,"CycleNotDirected",11);var qg;
z.CycleNotDirectedFast=qg=t.w(z,"CycleNotDirectedFast",12);var rg;z.CycleNotUndirected=rg=t.w(z,"CycleNotUndirected",13);var fg;z.CycleDestinationTree=fg=t.w(z,"CycleDestinationTree",14);var ng;z.CycleSourceTree=ng=t.w(z,"CycleSourceTree",15);t.g(z,"validCycle",z.prototype.QG);t.defineProperty(z,{QG:"validCycle"},function(){return this.Wv},function(a){var b=this.Wv;b!==a&&(t.Aa(a,z,z,"validCycle"),this.Wv=a,this.j("validCycle",b,a))});t.g(z,"linkSpacing",z.prototype.yp);
t.defineProperty(z,{yp:"linkSpacing"},function(){return this.yj},function(a){var b=this.yj;b!==a&&(t.q(a,z,"linkSpacing"),0>a&&t.ka(a,">= zero",z,"linkSpacing"),this.yj=a,this.j("linkSpacing",b,a))});t.A(z,{Yw:"layers"},function(){return this.Rb.k});t.g(z,"isModelReadOnly",z.prototype.Ze);t.defineProperty(z,{Ze:"isModelReadOnly"},function(){var a=this.ke;return null===a?!1:a.ab},function(a){var b=this.ke;null!==b&&(b.ab=a)});t.g(z,"isReadOnly",z.prototype.ab);
t.defineProperty(z,{ab:"isReadOnly"},function(){return this.Jk},function(a){var b=this.Jk;b!==a&&(t.i(a,"boolean",z,"isReadOnly"),this.Jk=a,this.j("isReadOnly",b,a))});t.g(z,"isEnabled",z.prototype.isEnabled);t.defineProperty(z,{isEnabled:"isEnabled"},function(){return this.Le},function(a){var b=this.Le;b!==a&&(t.i(a,"boolean",z,"isEnabled"),this.Le=a,this.j("isEnabled",b,a))});t.g(z,"allowClipboard",z.prototype.ew);
t.defineProperty(z,{ew:"allowClipboard"},function(){return this.eu},function(a){var b=this.eu;b!==a&&(t.i(a,"boolean",z,"allowClipboard"),this.eu=a,this.j("allowClipboard",b,a))});t.g(z,"allowCopy",z.prototype.Si);t.defineProperty(z,{Si:"allowCopy"},function(){return this.lk},function(a){var b=this.lk;b!==a&&(t.i(a,"boolean",z,"allowCopy"),this.lk=a,this.j("allowCopy",b,a))});t.g(z,"allowDelete",z.prototype.Zk);
t.defineProperty(z,{Zk:"allowDelete"},function(){return this.mk},function(a){var b=this.mk;b!==a&&(t.i(a,"boolean",z,"allowDelete"),this.mk=a,this.j("allowDelete",b,a))});t.g(z,"allowDragOut",z.prototype.Ms);t.defineProperty(z,{Ms:"allowDragOut"},function(){return this.fu},function(a){var b=this.fu;b!==a&&(t.i(a,"boolean",z,"allowDragOut"),this.fu=a,this.j("allowDragOut",b,a))});t.g(z,"allowDrop",z.prototype.Kz);
t.defineProperty(z,{Kz:"allowDrop"},function(){return this.gu},function(a){var b=this.gu;b!==a&&(t.i(a,"boolean",z,"allowDrop"),this.gu=a,this.j("allowDrop",b,a))});t.g(z,"allowTextEdit",z.prototype.Qo);t.defineProperty(z,{Qo:"allowTextEdit"},function(){return this.vk},function(a){var b=this.vk;b!==a&&(t.i(a,"boolean",z,"allowTextEdit"),this.vk=a,this.j("allowTextEdit",b,a))});t.g(z,"allowGroup",z.prototype.Mo);
t.defineProperty(z,{Mo:"allowGroup"},function(){return this.nk},function(a){var b=this.nk;b!==a&&(t.i(a,"boolean",z,"allowGroup"),this.nk=a,this.j("allowGroup",b,a))});t.g(z,"allowUngroup",z.prototype.Ro);t.defineProperty(z,{Ro:"allowUngroup"},function(){return this.wk},function(a){var b=this.wk;b!==a&&(t.i(a,"boolean",z,"allowUngroup"),this.wk=a,this.j("allowUngroup",b,a))});t.g(z,"allowInsert",z.prototype.Bm);
t.defineProperty(z,{Bm:"allowInsert"},function(){return this.iu},function(a){var b=this.iu;b!==a&&(t.i(a,"boolean",z,"allowInsert"),this.iu=a,this.j("allowInsert",b,a))});t.g(z,"allowLink",z.prototype.Cm);t.defineProperty(z,{Cm:"allowLink"},function(){return this.ok},function(a){var b=this.ok;b!==a&&(t.i(a,"boolean",z,"allowLink"),this.ok=a,this.j("allowLink",b,a))});t.g(z,"allowRelink",z.prototype.Sj);
t.defineProperty(z,{Sj:"allowRelink"},function(){return this.qk},function(a){var b=this.qk;b!==a&&(t.i(a,"boolean",z,"allowRelink"),this.qk=a,this.j("allowRelink",b,a))});t.g(z,"allowMove",z.prototype.Rj);t.defineProperty(z,{Rj:"allowMove"},function(){return this.pk},function(a){var b=this.pk;b!==a&&(t.i(a,"boolean",z,"allowMove"),this.pk=a,this.j("allowMove",b,a))});t.g(z,"allowReshape",z.prototype.No);
t.defineProperty(z,{No:"allowReshape"},function(){return this.rk},function(a){var b=this.rk;b!==a&&(t.i(a,"boolean",z,"allowReshape"),this.rk=a,this.j("allowReshape",b,a))});t.g(z,"allowResize",z.prototype.Oo);t.defineProperty(z,{Oo:"allowResize"},function(){return this.sk},function(a){var b=this.sk;b!==a&&(t.i(a,"boolean",z,"allowResize"),this.sk=a,this.j("allowResize",b,a))});t.g(z,"allowRotate",z.prototype.Po);
t.defineProperty(z,{Po:"allowRotate"},function(){return this.tk},function(a){var b=this.tk;b!==a&&(t.i(a,"boolean",z,"allowRotate"),this.tk=a,this.j("allowRotate",b,a))});t.g(z,"allowSelect",z.prototype.Pe);t.defineProperty(z,{Pe:"allowSelect"},function(){return this.uk},function(a){var b=this.uk;b!==a&&(t.i(a,"boolean",z,"allowSelect"),this.uk=a,this.j("allowSelect",b,a))});t.g(z,"allowUndo",z.prototype.Lz);
t.defineProperty(z,{Lz:"allowUndo"},function(){return this.ju},function(a){var b=this.ju;b!==a&&(t.i(a,"boolean",z,"allowUndo"),this.ju=a,this.j("allowUndo",b,a))});t.g(z,"allowZoom",z.prototype.Ns);t.defineProperty(z,{Ns:"allowZoom"},function(){return this.lu},function(a){var b=this.lu;b!==a&&(t.i(a,"boolean",z,"allowZoom"),this.lu=a,this.j("allowZoom",b,a))});t.g(z,"hasVerticalScrollbar",z.prototype.SE);
t.defineProperty(z,{SE:"hasVerticalScrollbar"},function(){return this.Pu},function(a){var b=this.Pu;b!==a&&(t.i(a,"boolean",z,"hasVerticalScrollbar"),this.Pu=a,Ki(this),this.fa(),this.j("hasVerticalScrollbar",b,a),Di(this))});t.g(z,"hasHorizontalScrollbar",z.prototype.RE);t.defineProperty(z,{RE:"hasHorizontalScrollbar"},function(){return this.Ou},function(a){var b=this.Ou;b!==a&&(t.i(a,"boolean",z,"hasHorizontalScrollbar"),this.Ou=a,Ki(this),this.fa(),this.j("hasHorizontalScrollbar",b,a),Di(this))});
t.g(z,"allowHorizontalScroll",z.prototype.of);t.defineProperty(z,{of:"allowHorizontalScroll"},function(){return this.hu},function(a){var b=this.hu;b!==a&&(t.i(a,"boolean",z,"allowHorizontalScroll"),this.hu=a,this.j("allowHorizontalScroll",b,a),Di(this))});t.g(z,"allowVerticalScroll",z.prototype.pf);t.defineProperty(z,{pf:"allowVerticalScroll"},function(){return this.ku},function(a){var b=this.ku;b!==a&&(t.i(a,"boolean",z,"allowVerticalScroll"),this.ku=a,this.j("allowVerticalScroll",b,a),Di(this))});
t.g(z,"scrollHorizontalLineChange",z.prototype.Qp);t.defineProperty(z,{Qp:"scrollHorizontalLineChange"},function(){return this.Ev},function(a){var b=this.Ev;b!==a&&(t.i(a,"number",z,"scrollHorizontalLineChange"),0>a&&t.ka(a,">= 0",z,"scrollHorizontalLineChange"),this.Ev=a,this.j("scrollHorizontalLineChange",b,a))});t.g(z,"scrollVerticalLineChange",z.prototype.Rp);
t.defineProperty(z,{Rp:"scrollVerticalLineChange"},function(){return this.Fv},function(a){var b=this.Fv;b!==a&&(t.i(a,"number",z,"scrollVerticalLineChange"),0>a&&t.ka(a,">= 0",z,"scrollVerticalLineChange"),this.Fv=a,this.j("scrollVerticalLineChange",b,a))});t.g(z,"lastInput",z.prototype.R);t.defineProperty(z,{R:"lastInput"},function(){return this.ec},function(a){e&&t.l(a,ld,z,"lastInput");this.ec=a});t.g(z,"firstInput",z.prototype.Nc);
t.defineProperty(z,{Nc:"firstInput"},function(){return this.Dk},function(a){e&&t.l(a,ld,z,"firstInput");this.Dk=a});t.g(z,"currentCursor",z.prototype.$b);t.defineProperty(z,{$b:"currentCursor"},function(){return this.zy},function(a){""===a&&(a=this.Gq);this.zy!==a&&(t.i(a,"string",z,"currentCursor"),null!==this.gb&&(this.zy=a,this.gb.style.cursor=a,this.Nb.style.cursor=a))});t.g(z,"defaultCursor",z.prototype.oI);
t.defineProperty(z,{oI:"defaultCursor"},function(){return this.Gq},function(a){""===a&&(a="auto");var b=this.Gq;b!==a&&(t.i(a,"string",z,"defaultCursor"),this.Gq=a,this.j("defaultCursor",b,a))});t.g(z,"hasGestureZoom",z.prototype.NI);t.defineProperty(z,{NI:"hasGestureZoom"},function(){return this.vi},function(a){var b=this.vi;b!==a&&(t.i(a,"boolean",z,"hasGestureZoom"),this.vi=a,this.j("hasGestureZoom",b,a))});t.g(z,"click",z.prototype.click);
t.defineProperty(z,{click:"click"},function(){return this.ni},function(a){var b=this.ni;b!==a&&(null!==a&&t.i(a,"function",z,"click"),this.ni=a,this.j("click",b,a))});t.g(z,"doubleClick",z.prototype.Om);t.defineProperty(z,{Om:"doubleClick"},function(){return this.ti},function(a){var b=this.ti;b!==a&&(null!==a&&t.i(a,"function",z,"doubleClick"),this.ti=a,this.j("doubleClick",b,a))});t.g(z,"contextClick",z.prototype.Ws);
t.defineProperty(z,{Ws:"contextClick"},function(){return this.pi},function(a){var b=this.pi;b!==a&&(null!==a&&t.i(a,"function",z,"contextClick"),this.pi=a,this.j("contextClick",b,a))});t.g(z,"mouseOver",z.prototype.zt);t.defineProperty(z,{zt:"mouseOver"},function(){return this.Ei},function(a){var b=this.Ei;b!==a&&(null!==a&&t.i(a,"function",z,"mouseOver"),this.Ei=a,this.j("mouseOver",b,a))});t.g(z,"mouseHover",z.prototype.yt);
t.defineProperty(z,{yt:"mouseHover"},function(){return this.Di},function(a){var b=this.Di;b!==a&&(null!==a&&t.i(a,"function",z,"mouseHover"),this.Di=a,this.j("mouseHover",b,a))});t.g(z,"mouseHold",z.prototype.xt);t.defineProperty(z,{xt:"mouseHold"},function(){return this.Ci},function(a){var b=this.Ci;b!==a&&(null!==a&&t.i(a,"function",z,"mouseHold"),this.Ci=a,this.j("mouseHold",b,a))});t.g(z,"mouseDragOver",z.prototype.BF);
t.defineProperty(z,{BF:"mouseDragOver"},function(){return this.kv},function(a){var b=this.kv;b!==a&&(null!==a&&t.i(a,"function",z,"mouseDragOver"),this.kv=a,this.j("mouseDragOver",b,a))});t.g(z,"mouseDrop",z.prototype.wt);t.defineProperty(z,{wt:"mouseDrop"},function(){return this.Bi},function(a){var b=this.Bi;b!==a&&(null!==a&&t.i(a,"function",z,"mouseDrop"),this.Bi=a,this.j("mouseDrop",b,a))});t.g(z,"toolTip",z.prototype.Yt);
t.defineProperty(z,{Yt:"toolTip"},function(){return this.Oi},function(a){var b=this.Oi;b!==a&&(null!==a&&t.l(a,Je,z,"toolTip"),this.Oi=a,this.j("toolTip",b,a))});t.g(z,"contextMenu",z.prototype.contextMenu);t.defineProperty(z,{contextMenu:"contextMenu"},function(){return this.qi},function(a){var b=this.qi;b!==a&&(null!==a&&t.l(a,Je,z,"contextMenu"),this.qi=a,this.j("contextMenu",b,a))});t.g(z,"commandHandler",z.prototype.Se);
t.defineProperty(z,{Se:"commandHandler"},function(){return this.py},function(a){var b=this.py;b!==a&&(t.l(a,oa,z,"commandHandler"),null!==a.h&&t.m("Cannot share CommandHandlers between Diagrams: "+a.toString()),null!==b&&b.yd(null),this.py=a,a.yd(this))});t.g(z,"toolManager",z.prototype.wb);
t.defineProperty(z,{wb:"toolManager"},function(){return this.Uv},function(a){var b=this.Uv;b!==a&&(t.l(a,He,z,"toolManager"),null!==a.h&&t.m("Cannot share ToolManagers between Diagrams: "+a.toString()),null!==b&&b.yd(null),this.Uv=a,a.yd(this))});t.g(z,"defaultTool",z.prototype.uw);t.defineProperty(z,{uw:"defaultTool"},function(){return this.Fy},function(a){var b=this.Fy;b!==a&&(t.l(a,ae,z,"defaultTool"),this.Fy=a,this.Ua===b&&(this.Ua=a))});t.g(z,"currentTool",z.prototype.Ua);
t.defineProperty(z,{Ua:"currentTool"},function(){return this.By},function(a){var b=this.By;if(null!==b)for(b.ia&&b.doDeactivate(),b.cancelWaitAfter(),b.doStop(),b=this.lm.k;b.next();)b.value.fa();null===a&&(a=this.uw);null!==a&&(t.l(a,ae,z,"currentTool"),this.By=a,a.yd(this),a.doStart())});t.A(z,{selection:"selection"},function(){return this.Iv});t.g(z,"maxSelectionCount",z.prototype.uF);
t.defineProperty(z,{uF:"maxSelectionCount"},function(){return this.fv},function(a){var b=this.fv;if(b!==a)if(t.i(a,"number",z,"maxSelectionCount"),0<=a&&!isNaN(a)){if(this.fv=a,this.j("maxSelectionCount",b,a),!this.la.vb&&(a=this.selection.count-a,0<a)){this.Ba("ChangingSelection");for(var b=this.selection.Ie(),c=0;c<a;c++)b[c].eb=!1;this.Ba("ChangedSelection")}}else t.ka(a,">= 0",z,"maxSelectionCount")});t.g(z,"nodeSelectionAdornmentTemplate",z.prototype.GF);
t.defineProperty(z,{GF:"nodeSelectionAdornmentTemplate"},function(){return this.qv},function(a){var b=this.qv;b!==a&&(t.l(a,Je,z,"nodeSelectionAdornmentTemplate"),this.qv=a,this.j("nodeSelectionAdornmentTemplate",b,a))});t.g(z,"groupSelectionAdornmentTemplate",z.prototype.NE);t.defineProperty(z,{NE:"groupSelectionAdornmentTemplate"},function(){return this.Lu},function(a){var b=this.Lu;b!==a&&(t.l(a,Je,z,"groupSelectionAdornmentTemplate"),this.Lu=a,this.j("groupSelectionAdornmentTemplate",b,a))});
t.g(z,"linkSelectionAdornmentTemplate",z.prototype.rF);t.defineProperty(z,{rF:"linkSelectionAdornmentTemplate"},function(){return this.$u},function(a){var b=this.$u;b!==a&&(t.l(a,Je,z,"linkSelectionAdornmentTemplate"),this.$u=a,this.j("linkSelectionAdornmentTemplate",b,a))});t.A(z,{Kw:"highlighteds"},function(){return this.Qu});t.g(z,"isModified",z.prototype.gh);
t.defineProperty(z,{gh:"isModified"},function(){var a=this.la;return a.isEnabled?null!==a.Zi?!0:this.$y&&this.Ug!==a.cj:this.$y},function(a){if(this.$y!==a){t.i(a,"boolean",z,"isModified");this.$y=a;var b=this.la;!a&&b.isEnabled&&(this.Ug=b.cj);a||lj(this)}});function lj(a){var b=a.gh;a.BD!==b&&(a.BD=b,a.Ba("Modified"))}t.g(z,"model",z.prototype.ba);
t.defineProperty(z,{ba:"model"},function(){return this.ke},function(a){var b=this.ke;if(b!==a){t.l(a,E,z,"model");this.Ua.doCancel();null!==b&&b.la!==a.la&&b.la.dF&&t.m("Do not replace a Diagram.model while a transaction is in progress.");this.Ub.Al();this.kw();this.lf=!1;this.Qn=!0;this.Ug=-2;this.Nf=!1;var c=this.Bd;this.Bd=!0;this.Ub.ul();null!==b&&(b.TA(this.FC),b instanceof M&&mj(this,b.hj),mj(this,b.Gg));this.ke=a;a.Iz(this.EC);nj(this,a.Gg);a instanceof M&&oj(this,a.hj);a.TA(this.EC);a.Iz(this.FC);
this.Bd=c;this.Yb||this.fa();null!==b&&(a.la.isEnabled=b.la.isEnabled)}});t.defineProperty(z,{Na:null},function(){return this.xC},function(a){this.xC=a});
function Xh(a,b){if(b.ba===a.ba){var c=b.td,d=b.propertyName;if(c===ud&&"S"===d[0])if("StartingFirstTransaction"===d){c=a.wb;for(d=c.ef.k;d.next();)d.value.yd(a);for(d=c.Dg.k;d.next();)d.value.yd(a);for(d=c.Eg.k;d.next();)d.value.yd(a);a.Bd||a.lf||(a.Du=!0,a.Qn&&(a.Nf=!0),a.li.ul())}else if("StartingUndo"===d||"StartingRedo"===d){var f=a.Ub;f.yg&&!a.Wa&&f.Al();a.Ba("ChangingSelection")}else"StartedTransaction"===d&&(f=a.Ub,f.yg&&!a.Wa&&f.Al(),a.Xu&&f.ul());else if(a.Na){a.Na=!1;try{var g=b.Af;if(""!==
g)if(c===vd){if("linkFromKey"===g){var h=b.object,k=a.Qf(h);if(null!==k){var l=b.newValue,m=a.Ve(l);k.aa=m}}else if("linkToKey"===g)h=b.object,k=a.Qf(h),null!==k&&(l=b.newValue,m=a.Ve(l),k.ca=m);else if("linkFromPortId"===g){if(h=b.object,k=a.Qf(h),null!==k){var n=b.newValue;"string"===typeof n&&(k.Rf=n)}}else if("linkToPortId"===g)h=b.object,k=a.Qf(h),null!==k&&(n=b.newValue,"string"===typeof n&&(k.Og=n));else if("nodeGroupKey"===g){var h=b.object,p=a.fh(h);if(null!==p){var q=b.newValue;if(void 0!==
q){var r=a.Ve(q);p.hb=r instanceof S?r:null}else p.hb=null}}else if("linkLabelKeys"===g){if(h=b.object,k=a.Qf(h),null!==k){var s=b.oldValue,u=b.newValue;if(t.isArray(s))for(var v=t.ub(s),D=0;D<v;D++){var A=t.lb(s,D),m=a.Ve(A);null!==m&&(m.$d=null)}if(t.isArray(u))for(v=t.ub(u),D=0;D<v;D++)A=t.lb(u,D),m=a.Ve(A),null!==m&&(m.$d=k)}}else if("nodeParentKey"===g){var G=b.object,K=a.Ve(b.newValue),N=a.Ew(G);if(null!==N){var O=N.ft();null!==O?null===K?a.remove(O):a.pd?O.aa=K:O.ca=K:pj(a,K,N)}}else if("parentLinkCategory"===
g){var G=b.object,N=a.Ew(G),U=b.newValue;null!==N&&"string"===typeof U&&(O=N.ft(),null!==O&&(O.Zc=U))}else if("nodeCategory"===g){var h=b.object,X=a.fh(h),U=b.newValue;null!==X&&"string"===typeof U&&(X.Zc=U)}else if("linkCategory"===g){var h=b.object,Z=a.Qf(h),U=b.newValue;null!==Z&&"string"===typeof U&&(Z.Zc=U)}else if("nodeDataArray"===g){a.fa();var P=b.oldValue;mj(a,P);var pa=b.newValue;nj(a,pa)}else"linkDataArray"===g&&(a.fa(),P=b.oldValue,mj(a,P),pa=b.newValue,oj(a,pa));a.gh=!0}else if(c===wd)pa=
b.newValue,"nodeDataArray"===g&&t.qb(pa)?qj(a,pa):"linkDataArray"===g&&t.qb(pa)?rj(a,pa):"linkLabelKeys"===g&&oe(pa)&&(k=a.Qf(b.object),m=a.Ve(pa),null!==k&&null!==m&&(m.$d=k)),a.gh=!0;else if(c===xd)P=b.oldValue,"nodeDataArray"===g&&t.qb(P)?sj(a,P):"linkDataArray"===g&&t.qb(P)?sj(a,P):"linkLabelKeys"===g&&oe(P)&&(m=a.Ve(P),null!==m&&(m.$d=null)),a.gh=!0;else{if(c===ud&&"SourceChanged"===g){var p=a.fh(b.object),Ya=b.propertyName;null!==p&&"string"===typeof Ya&&(p.Kb(Ya),a.ba instanceof Kd&&(k=a.Qf(b.object),
null!==k&&k.Kb(Ya)))}}else if(c===vd){var za=b.propertyName,h=b.object;if(h===a.ba){if("nodeKeyProperty"===za||"nodeCategoryProperty"===za||"linkFromKeyProperty"===za||"linkToKeyProperty"===za||"linkFromPortIdProperty"===za||"linkToPortIdProperty"===za||"linkLabelKeysProperty"===za||"nodeIsGroupProperty"===za||"nodeGroupKeyProperty"===za||"nodeParentKeyProperty"===za||"linkCategoryProperty"===za)a.la.vb||a.hn()}else Wh(a,h,za);a.gh=!0}else if(c===wd||c===xd)tj(a,b),a.gh=!0;else if(c===ud){if("FinishedUndo"===
d||"FinishedRedo"===d)a.la.xi=!0,a.Ba("ChangedSelection"),Bi(a),a.la.xi=!1;a.Du=!0;a.Uf();f=a.li;f.sd&&0===a.la.Je&&Eh(f);"CommittedTransaction"===d&&a.la.cz&&(a.Ug=Math.min(a.Ug,a.la.cj-1));lj(a);a.jz||"CommittedTransaction"!==d||(a.jz=!0,setTimeout(function(){a.Ua.standardMouseOver();a.jz=!1},10))}}finally{a.Na=!0}}}}
function Wh(a,b,c){var d=a.fh(b);if(null!==d&&"string"===typeof c)d.Kb(c),a.ba instanceof Kd&&(b=a.Qf(b),null!==b&&b.Kb(c));else if("string"===typeof c){d=null;for(a=a.$l.k;a.next();){for(var f=a.value,g=0;g<f.length;g++){var h=f[g];null!==h.gg&&(h=h.gg.za(b),null!==h&&(null===d&&(d=t.Db()),d.push(h)))}if(null!==d)break}b=d;if(null!==b){for(d=0;d<b.length;d++)b[d].Kb(c);t.ya(b)}}}t.g(z,"skipsModelSourceBindings",z.prototype.Tt);
t.defineProperty(z,{Tt:"skipsModelSourceBindings"},function(){return this.cD},function(a){this.cD=a});function tj(a,b){var c=b.td===wd,d=c?b.Wf:b.Yf,f=c?b.newValue:b.oldValue,g=a.$l.za(b.object);if(Array.isArray(g)&&"number"===typeof d)for(var h=0;h<g.length;h++){var k=g[h];k.type!==Tg&&k.type!==ci||d++;c?uj(k,f,d):(k.Xe(d),vj(k,d-1))}}function cj(a,b){var c=b.yi;if(t.isArray(c)){var d=a.$l.za(c);if(null===d)a.$l.add(c,[b]);else{for(c=0;c<d.length;c++)if(d[c]===b)return;d.push(b)}}}
function fj(a,b){var c=b.yi;if(t.isArray(c)){var d=a.$l.za(c);if(null!==d)for(var f=0;f<d.length;f++)if(d[f]===b){d.splice(f,1);0===d.length&&a.$l.remove(c);break}}}
z.prototype.clear=z.prototype.clear=function(){var a=null;null!==this.gd&&(a=this.gd.S);this.ba.clear();for(var b=this.Rb.length,c=0;c<b;c++)this.Rb.n[c].clear();this.fg.clear();this.Rl.clear();this.rv.clear();this.Uk.clear();this.av.clear();this.zb.clear();this.ri.clear();this.Ak.clear();this.$l.clear();this.Iv.Ma();this.Iv.clear();this.Iv.freeze();this.Qu.Ma();this.Qu.clear();this.Qu.freeze();this.Bn=null;t.Ss=null;this.Eu=(new x(NaN,NaN,NaN,NaN)).freeze();null!==a&&(this.add(a),this.zb.remove(a));
this.fa()};
z.prototype.reset=z.prototype.reset=function(){this.Yb=!0;this.clear();this.Rb=new B($d);this.Sk={};this.RF();this.Ra=(new w(NaN,NaN)).freeze();this.xc=1;this.Su=(new w(NaN,NaN)).freeze();this.Tu=NaN;this.hv=1E-4;this.ev=100;this.$v=(new w(NaN,NaN)).freeze();this.Ju=(new x(NaN,NaN,NaN,NaN)).freeze();this.Hl=Xe;this.An=xb;this.Hk=Xe;this.Xn=xb;this.Uu=this.Ru=Eb;this.ou=(new ab(16,16,16,16)).freeze();this.Wu=!0;this.Wv=eg;this.Gq="auto";this.qi=this.Oi=this.Bi=this.kv=this.Ci=this.Di=this.Ei=this.pi=
this.ti=this.ni=null;this.Jk=!1;this.mk=this.lk=this.eu=this.Le=!0;this.gu=this.fu=!1;this.ku=this.hu=this.Pu=this.Ou=this.lu=this.ju=this.uk=this.tk=this.sk=this.rk=this.pk=this.qk=this.ok=this.iu=this.wk=this.nk=this.vk=!0;this.Fv=this.Ev=16;this.Ne=(new ab(5)).freeze();this.fv=999999999;this.Pd=null;Zh(this);this.gd=null;this.Li=!0;Yh(this);this.jc=new be;this.Li=!1;this.ba=new M;this.lf=!1;this.Qn=!0;this.Yb=this.Nf=!1;this.fa()};
z.prototype.rebuildParts=z.prototype.hn=function(){for(var a=this.gx.k;a.next();){var b=a.value;(!b.Ld()||b instanceof S)&&t.m('Invalid node template in Diagram.nodeTemplateMap: template for "'+a.key+'" must be a Node or a simple Part, not a Group or Link: '+b)}for(a=this.Iw.k;a.next();)b=a.value,b instanceof S||t.m('Invalid group template in Diagram.groupTemplateMap: template for "'+a.key+'" must be a Group, not a normal Node or Link: '+b);for(a=this.$w.k;a.next();)b=a.value,b instanceof T||t.m('Invalid link template in Diagram.linkTemplateMap: template for "'+
a.key+'" must be a Link, not a normal Node or simple Part: '+b);a=t.Db();for(b=this.selection.k;b.next();){var c=b.value.data;c&&a.push(c)}for(var b=t.Db(),d=this.Kw.k;d.next();)(c=d.value.data)&&b.push(c);for(var c=t.Db(),f=this.jh.k;f.next();)d=f.value,d.data&&(c.push(d.data),c.push(d.location));for(f=this.links.k;f.next();)d=f.value,d.data&&(c.push(d.data),c.push(d.location));for(f=this.sl.k;f.next();)d=f.value,d.data&&(c.push(d.data),c.push(d.location));d=this.ba;d instanceof M&&mj(this,d.hj);
mj(this,d.Gg);nj(this,d.Gg);d instanceof M&&oj(this,d.hj);for(d=0;d<a.length;d++)if(f=this.fh(a[d]))f.eb=!0;for(d=0;d<b.length;d++)if(f=this.fh(b[d]))f.zg=!0;for(d=0;d<c.length;d+=2)if(f=this.fh(c[d]))f.location=c[d+1];t.ya(a);t.ya(b);t.ya(c)};
function nj(a,b){if(null!==b){for(var c=a.ba,d=t.ub(b),f=0;f<d;f++){var g=t.lb(b,f);c.ne(g)?qj(a,g,!1):c instanceof M&&rj(a,g)}if(c instanceof M||c instanceof Kd){for(f=0;f<d;f++)g=t.lb(b,f),c.ne(g)&&yj(a,g);if(c instanceof M)for(c=a.links;c.next();)Lj(c.value)}Mj(a,!1)}}
function qj(a,b,c){if(void 0!==b&&null!==b&&!a.la.vb&&!a.ri.contains(b)){void 0===c&&(c=!0);var d=a.getCategoryForNodeData(b),f=a.findTemplateForNodeData(b,d);f instanceof C&&(Le(f),f=f.copy(),f instanceof C&&(f.rh=d,a.un&&(f.Kk="Tool"),a.add(f),f.data=b,c&&yj(a,b)))}}z.prototype.getCategoryForNodeData=function(a){var b=this.ba,c=b.getCategoryForNodeData(a),d=!1;b instanceof M&&(d=b.XI(a));d&&""===c&&(c="LinkLabel");return c};
z.prototype.findTemplateForNodeData=function(a,b){var c=!1,d=this.ba;d instanceof M&&(c=d.sA(a));c?(c=this.Iw.za(b),null===c&&(c=this.Iw.za(""),null===c&&(t.TG||(t.TG=!0,t.trace('No Group template found for category "'+b+'"'),t.trace("  Using default group template")),c=this.TB))):(c=this.gx.za(b),null===c&&(c=this.gx.za(""),null===c&&(t.WG||(t.WG=!0,t.trace('No Node template found for category "'+b+'"'),t.trace("  Using default node template")),c=this.VB)));return c instanceof C?c:null};
function yj(a,b){var c=a.ba;if(c instanceof M||c instanceof Kd){var d=c.Hb(b);if(void 0!==d){var f=pe(c,d),g=a.fh(b);if(null!==f&&null!==g){for(f=f.k;f.next();){var h=f.value;if(c instanceof M)if(c.ne(h)){if(g instanceof S&&c.Sm(h)===d){var k=a.fh(h);null!==k&&(k.hb=g)}}else{if(k=a.Qf(h),null!==k&&g instanceof R&&(c.il(h)===d&&(k.aa=g),c.ml(h)===d&&(k.ca=g),h=c.kl(h),t.isArray(h)))for(var l=0;l<t.ub(h);l++)if(t.lb(h,l)===d){g.$d=k;break}}else c instanceof Kd&&c.ne(h)&&g instanceof R&&c.Tm(h)===d&&
(k=a.Ew(h),pj(a,g,k))}re(c,d)}c instanceof M?(c=c.Sm(b),void 0!==c&&(c=a.Ve(c),c instanceof S&&(g.hb=c))):c instanceof Kd&&(c=c.Tm(b),void 0!==c&&g instanceof R&&(c=a.Ve(c),pj(a,c,g)))}}}
function pj(a,b,c){if(null!==b&&null!==c){var d=a.wb.zA,f=b,g=c;if(a.pd)for(b=g.qe;b.next();){var h=b.value;if(h.ca===g)return}else for(f=c,g=b,b=f.qe;b.next();)if(h=b.value,h.aa===f)return;null!==d&&mg(d,f,g,null,!0)||(d=a.getCategoryForLinkData(c.data),b=a.findTemplateForLinkData(c.data,d),b instanceof T&&(Le(b),b=b.copy(),b instanceof T&&(b.rh=d,b.aa=f,b.ca=g,a.add(b),b.data=c.data)))}}function oj(a,b){if(null!==b){for(var c=t.ub(b),d=0;d<c;d++){var f=t.lb(b,d);rj(a,f)}Mj(a,!1)}}
function rj(a,b){if(void 0!==b&&null!==b&&!a.la.vb&&!a.Ak.contains(b)){var c=a.getCategoryForLinkData(b),d=a.findTemplateForLinkData(b,c);if(d instanceof T&&(Le(d),d=d.copy(),d instanceof T)){d.rh=c;var c=a.ba,f=c.HI(b);""!==f&&(d.Rf=f);f=c.il(b);void 0!==f&&(f=a.Ve(f),f instanceof R&&(d.aa=f));f=c.KI(b);""!==f&&(d.Og=f);f=c.ml(b);void 0!==f&&(f=a.Ve(f),f instanceof R&&(d.ca=f));c=c.kl(b);if(t.isArray(c))for(var f=t.ub(c),g=0;g<f;g++){var h=t.lb(c,g),h=a.Ve(h);null!==h&&(h.$d=d)}a.add(d);d.data=b}}}
z.prototype.getCategoryForLinkData=function(a){var b=this.ba,c="";b instanceof M?c=b.getCategoryForLinkData(a):b instanceof Kd&&(c=b.JI(a));return c};z.prototype.findTemplateForLinkData=function(a,b){var c=this.$w.za(b);null===c&&(c=this.$w.za(""),null===c&&(t.VG||(t.VG=!0,t.trace('No Link template found for category "'+b+'"'),t.trace("  Using default link template")),c=this.UB));return c instanceof T?c:null};function mj(a,b){for(var c=t.ub(b),d=0;d<c;d++){var f=t.lb(b,d);sj(a,f)}}
function sj(a,b){if(void 0!==b&&null!==b){var c=a.fh(b);if(c instanceof C){c.eb=!1;c.zg=!1;var d=c.layer;if(null!==d&&d.h===a){var f=a.ba;if(f instanceof M&&c instanceof R){var g=f.Hb(c.data);if(void 0!==g){for(var h=c.qe;h.next();){var k=h.value.data;qe(f,g,k)}c.bi&&(h=c.$d,null!==h&&(k=h.data,qe(f,g,k)));if(c instanceof S)for(h=c.Rc;h.next();)k=h.value.data,f.ne(k)&&qe(f,g,k)}}else if(f instanceof Kd&&c instanceof R){h=a.Qf(c.data);if(null!==h&&(h.eb=!1,h.zg=!1,k=h.layer,null!==k)){var l=k.Xe(-1,
h,!1);0<=l&&a.Ic(xd,"parts",k,h,null,l,null);l=h.tp;null!==l&&l(h,k,null)}h=a.pd;for(k=c.qe;k.next();)l=k.value,l=(h?l.ca:l.aa).data,f.ne(l)&&qe(f,g,l)}f=d.Xe(-1,c,!1);0<=f&&a.Ic(xd,"parts",d,c,null,f,null);f=c.tp;null!==f&&f(c,d,null)}}}}z.prototype.findPartForKey=z.prototype.FI=function(a){if(null===a||void 0===a)return null;a=this.ba.uf(a);if(null===a)return null;a=this.ri.za(a);return a instanceof C?a:null};
z.prototype.findNodeForKey=z.prototype.Ve=function(a){if(null===a||void 0===a)return null;a=this.ba.uf(a);if(null===a)return null;a=this.ri.za(a);return a instanceof R?a:null};z.prototype.findPartForData=z.prototype.fh=function(a){if(null===a)return null;var b=this.ri.za(a);if(b instanceof C)return b;b=this.Ak.za(a);return b instanceof C?b:null};z.prototype.findNodeForData=z.prototype.Ew=function(a){if(null===a)return null;a=this.ri.za(a);return a instanceof R?a:null};
z.prototype.findLinkForData=z.prototype.Qf=function(a){if(null===a)return null;a=this.Ak.za(a);return a instanceof T?a:null};t.g(z,"nodeTemplate",z.prototype.sJ);t.defineProperty(z,{sJ:"nodeTemplate"},function(){return this.Fi.za("")},function(a){var b=this.Fi.za("");b!==a&&(t.l(a,C,z,"nodeTemplate"),this.Fi.add("",a),this.j("nodeTemplate",b,a),this.la.vb||this.hn())});t.g(z,"nodeTemplateMap",z.prototype.gx);
t.defineProperty(z,{gx:"nodeTemplateMap"},function(){return this.Fi},function(a){var b=this.Fi;b!==a&&(t.l(a,ia,z,"nodeTemplateMap"),this.Fi=a,this.j("nodeTemplateMap",b,a),this.la.vb||this.hn())});t.g(z,"groupTemplate",z.prototype.MI);t.defineProperty(z,{MI:"groupTemplate"},function(){return this.Ek.za("")},function(a){var b=this.Ek.za("");b!==a&&(t.l(a,S,z,"groupTemplate"),this.Ek.add("",a),this.j("groupTemplate",b,a),this.la.vb||this.hn())});t.g(z,"groupTemplateMap",z.prototype.Iw);
t.defineProperty(z,{Iw:"groupTemplateMap"},function(){return this.Ek},function(a){var b=this.Ek;b!==a&&(t.l(a,ia,z,"groupTemplateMap"),this.Ek=a,this.j("groupTemplateMap",b,a),this.la.vb||this.hn())});t.g(z,"linkTemplate",z.prototype.jJ);t.defineProperty(z,{jJ:"linkTemplate"},function(){return this.zj.za("")},function(a){var b=this.zj.za("");b!==a&&(t.l(a,T,z,"linkTemplate"),this.zj.add("",a),this.j("linkTemplate",b,a),this.la.vb||this.hn())});t.g(z,"linkTemplateMap",z.prototype.$w);
t.defineProperty(z,{$w:"linkTemplateMap"},function(){return this.zj},function(a){var b=this.zj;b!==a&&(t.l(a,ia,z,"linkTemplateMap"),this.zj=a,this.j("linkTemplateMap",b,a),this.la.vb||this.hn())});t.g(z,"isMouseCaptured",z.prototype.Kd);
t.defineProperty(z,{Kd:"isMouseCaptured"},function(){return this.uC},function(a){var b=this.gb;null!==b&&(a?(this.R.bubbles=!1,b.removeEventListener("mousemove",this.Dp,!1),b.removeEventListener("mousedown",this.Cp,!1),b.removeEventListener("mouseup",this.Fp,!1),b.removeEventListener("mousewheel",this.hh,!1),b.removeEventListener("DOMMouseScroll",this.hh,!1),b.removeEventListener("mouseout",this.Ep,!1),window.addEventListener("mousemove",this.Dp,!0),window.addEventListener("mousedown",this.Cp,!0),
window.addEventListener("mouseup",this.Fp,!0),window.addEventListener("mousewheel",this.hh,!0),window.addEventListener("DOMMouseScroll",this.hh,!0),window.addEventListener("mouseout",this.Ep,!0),window.addEventListener("selectstart",this.preventDefault,!1)):(window.removeEventListener("mousemove",this.Dp,!0),window.removeEventListener("mousedown",this.Cp,!0),window.removeEventListener("mouseup",this.Fp,!0),window.removeEventListener("mousewheel",this.hh,!0),window.removeEventListener("DOMMouseScroll",
this.hh,!0),window.removeEventListener("mouseout",this.Ep,!0),window.removeEventListener("selectstart",this.preventDefault,!1),b.addEventListener("mousemove",this.Dp,!1),b.addEventListener("mousedown",this.Cp,!1),b.addEventListener("mouseup",this.Fp,!1),b.addEventListener("mousewheel",this.hh,!1),b.addEventListener("DOMMouseScroll",this.hh,!1),b.addEventListener("mouseout",this.Ep,!1)),this.uC=a)});t.g(z,"position",z.prototype.position);
t.defineProperty(z,{position:"position"},function(){return this.Ra},function(a){var b=this.Ra;if(!b.M(a)){t.l(a,w,z,"position");var c=this.xb.copy();c.x=b.x;c.y=b.y;a=a.copy();if(!this.Yb&&null!==this.gb){this.Yb=!0;var d=this.scale;Fi(this,a,this.Gd,this.Fb/d,this.Eb/d,this.An);this.Yb=!1}this.Ra=a.Z();a=this.Ub;a.sd&&Oh(a,b,this.Ra);this.Yb||this.Et(c,this.xb)}});t.g(z,"initialPosition",z.prototype.WE);
t.defineProperty(z,{WE:"initialPosition"},function(){return this.Su},function(a){this.Su.M(a)||(t.l(a,w,z,"initialPosition"),this.Su=a.Z())});t.g(z,"initialScale",z.prototype.XE);t.defineProperty(z,{XE:"initialScale"},function(){return this.Tu},function(a){this.Tu!==a&&(t.i(a,"number",z,"initialScale"),this.Tu=a)});t.g(z,"grid",z.prototype.lp);
t.defineProperty(z,{lp:"grid"},function(){null===this.gd&&xi(this);return this.gd},function(a){var b=this.gd;if(b!==a){null===b&&(xi(this),b=this.gd);t.l(a,y,z,"grid");a.type!==yi&&t.m("Diagram.grid must be a Panel of type Panel.Grid");var c=b.ja;null!==c&&c.remove(b);this.gd=a;a.name="GRID";null!==c&&c.add(a);Li(this);this.fa();this.j("grid",b,a)}});
t.A(z,{xb:"viewportBounds"},function(){var a=this.AD;if(null===this.gb)return a;var b=this.Ra,c=this.xc;a.p(b.x,b.y,Math.max(this.Fb,0)/c,Math.max(this.Eb,0)/c);return a});t.g(z,"fixedBounds",z.prototype.eA);t.defineProperty(z,{eA:"fixedBounds"},function(){return this.Ju},function(a){var b=this.Ju;b.M(a)||(t.l(a,x,z,"fixedBounds"),this.Ju=a=a.Z(),this.Oc(),this.j("fixedBounds",b,a))});t.A(z,{Gd:"documentBounds"},function(){return this.Eu});
function Gi(a,b){a.wi=!1;var c=a.Eu;c.M(b)||(b=b.Z(),a.Eu=b,a.tj=null,Di(a),a.Ba("DocumentBoundsChanged",null,c.copy()),Ki(a))}t.g(z,"scale",z.prototype.scale);
t.defineProperty(z,{scale:"scale"},function(){return this.xc},function(a){var b=this.xc;t.q(a,z,"scale");a<this.Cg&&(a=this.Cg);a>this.Bg&&(a=this.Bg);if(b!==a){this.xc=a;if(!this.Yb&&!this.Bd&&(this.Yb=!0,null!==this.gb)){var c=this.xb.copy(),d=this.Fb,f=this.Eb;c.width=this.Fb/b;c.height=this.Eb/b;var g=this.$f.copy();if(isNaN(g.x))switch(this.ow){case bc:g.x=0;break;case cc:g.x=d-1;break;case Hb:g.x=d/2;break;case xb:case gc:g.x=d/2}if(isNaN(g.y))switch(this.ow){case ac:g.y=0;break;case dc:g.y=
f-1;break;case Hb:g.y=f/2;break;case xb:case gc:g.y=f/2}this.position=new w(this.Ra.x+g.x/b-g.x/a,this.Ra.y+g.y/b-g.y/a);this.Yb=!1;this.Et(c,this.xb)}this.fa();Ki(this)}});t.g(z,"autoScale",z.prototype.Fm);t.defineProperty(z,{Fm:"autoScale"},function(){return this.Hl},function(a){var b=this.Hl;b!==a&&(t.Aa(a,z,z,"autoScale"),this.Hl=a,this.j("autoScale",b,a),a!==Xe&&Di(this))});t.g(z,"initialAutoScale",z.prototype.SI);
t.defineProperty(z,{SI:"initialAutoScale"},function(){return this.Hk},function(a){var b=this.Hk;b!==a&&(t.Aa(a,z,z,"initialAutoScale"),this.Hk=a,this.j("initialAutoScale",b,a))});t.g(z,"initialViewportSpot",z.prototype.YE);t.defineProperty(z,{YE:"initialViewportSpot"},function(){return this.Uu},function(a){var b=this.Uu;b!==a&&(t.l(a,H,z,"initialViewportSpot"),a.xd()||t.m("initialViewportSpot must be a real Spot: "+a),this.Uu=a,this.j("initialViewportSpot",b,a))});t.g(z,"initialDocumentSpot",z.prototype.VE);
t.defineProperty(z,{VE:"initialDocumentSpot"},function(){return this.Ru},function(a){var b=this.Ru;b!==a&&(t.l(a,H,z,"initialDocumentSpot"),a.xd()||t.m("initialViewportSpot must be a real Spot: "+a),this.Ru=a,this.j("initialDocumentSpot",b,a))});t.g(z,"minScale",z.prototype.Cg);t.defineProperty(z,{Cg:"minScale"},function(){return this.hv},function(a){t.q(a,z,"minScale");var b=this.hv;b!==a&&(0<a?(this.hv=a,this.j("minScale",b,a),a>this.scale&&(this.scale=a)):t.ka(a,"> 0",z,"minScale"))});
t.g(z,"maxScale",z.prototype.Bg);t.defineProperty(z,{Bg:"maxScale"},function(){return this.ev},function(a){t.q(a,z,"maxScale");var b=this.ev;b!==a&&(0<a?(this.ev=a,this.j("maxScale",b,a),a<this.scale&&(this.scale=a)):t.ka(a,"> 0",z,"maxScale"))});t.g(z,"zoomPoint",z.prototype.$f);t.defineProperty(z,{$f:"zoomPoint"},function(){return this.$v},function(a){this.$v.M(a)||(t.l(a,w,z,"zoomPoint"),this.$v=a=a.Z())});t.g(z,"contentAlignment",z.prototype.ow);
t.defineProperty(z,{ow:"contentAlignment"},function(){return this.An},function(a){var b=this.An;b.M(a)||(t.l(a,H,z,"contentAlignment"),this.An=a=a.Z(),this.j("contentAlignment",b,a),Di(this))});t.g(z,"initialContentAlignment",z.prototype.TI);t.defineProperty(z,{TI:"initialContentAlignment"},function(){return this.Xn},function(a){var b=this.Xn;b.M(a)||(t.l(a,H,z,"initialContentAlignment"),this.Xn=a=a.Z(),this.j("initialContentAlignment",b,a))});t.g(z,"padding",z.prototype.padding);
t.defineProperty(z,{padding:"padding"},function(){return this.Ne},function(a){"number"===typeof a?a=new ab(a):t.l(a,ab,z,"padding");var b=this.Ne;b.M(a)||(this.Ne=a=a.Z(),this.Oc(),this.j("padding",b,a))});t.A(z,{jh:"nodes"},function(){return this.rv.k});t.A(z,{links:"links"},function(){return this.av.k});t.A(z,{sl:"parts"},function(){return this.zb.k});z.prototype.findTopLevelGroups=function(){return this.Uk.k};t.g(z,"layout",z.prototype.jc);
t.defineProperty(z,{jc:"layout"},function(){return this.Pd},function(a){var b=this.Pd;b!==a&&(t.l(a,be,z,"layout"),null!==b&&(b.h=null,b.group=null),this.Pd=a,a.h=this,a.group=null,this.mu=!0,this.j("layout",b,a),this.te())});z.prototype.layoutDiagram=function(a){Bi(this);a&&Mj(this,!0);Ni(this,!1)};function Mj(a,b){for(var c=a.Uk.k;c.next();)Nj(a,c.value,b);null!==a.jc&&(b?a.jc.$e=!1:a.jc.J())}
function Nj(a,b,c){if(null!==b){for(var d=b.qo.k;d.next();)Nj(a,d.value,c);null!==b.jc&&(c?b.jc.$e=!1:b.jc.J())}}function Ni(a,b){if(!a.Gy){var c=a.jc,d=a.Xu;a.Xu=!0;var f=a.Na;a.Na=!0;try{for(var g=a.Uk.k;g.next();)Oj(a,g.value,b);c.$e||b&&!c.wA||(c.doLayout(a),Bi(a),c.$e=!0)}finally{a.mu=!c.$e,a.Xu=d,a.Na=f}}}function Oj(a,b,c){if(null!==b){for(var d=b.qo.k;d.next();)Oj(a,d.value,c);d=b.jc;null===d||d.$e||c&&!d.wA||(b.LC=!b.location.N(),d.doLayout(b),b.J(Pj),d.$e=!0,Pi(a,b))}}
t.g(z,"isTreePathToChildren",z.prototype.pd);t.defineProperty(z,{pd:"isTreePathToChildren"},function(){return this.Wu},function(a){var b=this.Wu;if(b!==a&&(t.i(a,"boolean",z,"isTreePathToChildren"),this.Wu=a,this.j("isTreePathToChildren",b,a),!this.la.vb))for(a=this.jh;a.next();)Qj(a.value)});z.prototype.findTreeRoots=function(){for(var a=new B(R),b=this.jh.k;b.next();){var c=b.value;c.sp&&null===c.ft()&&a.add(c)}return a.k};t.g(z,"isCollapsingExpanding",z.prototype.Wd);
t.defineProperty(z,{Wd:null},function(){return this.mC},function(a){this.mC=a});
function Vh(a){function b(a){var b=a.toLowerCase(),h=new B("function");c.add(a,h);c.add(b,h);d.add(a,a);d.add(b,a)}var c=new ia("string",B),d=new ia("string","string");b("AnimationStarting");b("AnimationFinished");b("BackgroundSingleClicked");b("BackgroundDoubleClicked");b("BackgroundContextClicked");b("ClipboardChanged");b("ClipboardPasted");b("DocumentBoundsChanged");b("ExternalObjectsDropped");b("InitialLayoutCompleted");b("LayoutCompleted");b("LinkDrawn");b("LinkRelinked");b("LinkReshaped");b("Modified");
b("ObjectSingleClicked");b("ObjectDoubleClicked");b("ObjectContextClicked");b("PartCreated");b("PartResized");b("PartRotated");b("SelectionMoved");b("SelectionCopied");b("SelectionDeleting");b("SelectionDeleted");b("SelectionGrouped");b("SelectionUngrouped");b("ChangingSelection");b("ChangedSelection");b("SubGraphCollapsed");b("SubGraphExpanded");b("TextEdited");b("TreeCollapsed");b("TreeExpanded");b("ViewportBoundsChanged");a.Iy=c;a.Hy=d}
function ja(a,b){var c=a.Hy.za(b);return null!==c?c:a.Hy.za(b.toLowerCase())}function Rj(a,b){var c=a.Iy.za(b);if(null!==c)return c;c=a.Iy.za(b.toLowerCase());if(null!==c)return c;t.m("Unknown DiagramEvent name: "+b);return null}z.prototype.addDiagramListener=z.prototype.Jz=function(a,b){t.i(a,"string",z,"addDiagramListener:name");t.i(b,"function",z,"addDiagramListener:listener");var c=Rj(this,a);null!==c&&c.add(b)};
z.prototype.removeDiagramListener=z.prototype.OF=function(a,b){t.i(a,"string",z,"removeDiagramListener:name");t.i(b,"function",z,"addDiagramListener:listener");var c=Rj(this,a);null!==c&&c.remove(b)};z.prototype.raiseDiagramEvent=z.prototype.Ba=function(a,b,c){e&&t.i(a,"string",z,"raiseDiagramEvent:name");var d=Rj(this,a),f=new rd;f.h=this;f.name=ja(this,a);void 0!==b&&(f.Ax=b);void 0!==c&&(f.kx=c);a=d.length;if(1===a)d=d.wa(0),d(f);else if(0!==a)for(b=d.Ie(),c=0;c<a;c++)d=b[c],d(f);return f.cancel};
function Yf(a,b){var c=!1;a.xb.Vj(b)&&(c=!0);c=a.cA(b,function(a){return a.S},function(a){return a instanceof T},!0,function(a){return a instanceof T},c);if(0!==c.count)for(c=c.k;c.next();){var d=c.value;d.dj&&d.ac()}}
function Sj(a,b){null===a.Sd&&(a.Sd=new Tj);var c=null!==b?b.hb:null;if(a.Sd.nt||a.Sd.group!==c){if(null===c){var d;d=a.wi?Ci(a):a.Gd.copy();d.Sf(100,100);a.Sd.initialize(d);d=t.Gf();for(var f=a.jh;f.next();){var g=f.value,h=g.layer;null!==h&&h.visible&&!h.rc&&Uj(a,g,null,d)}}else for(c.oa.N()||c.sf(),d=c.oa.copy(),d.Sf(20,20),a.Sd.initialize(d),d=t.Gf(),f=c.Rc;f.next();)g=f.value,g instanceof R&&Uj(a,g,null,d);t.hc(d);a.Sd.group=c;a.Sd.nt=!1}else Vj(a.Sd);return a.Sd}
function Uj(a,b,c,d){if(b!==c)if(b.La()&&b.canAvoid()){c=b.getAvoidableRect(d);d=a.Sd.Hm;b=a.Sd.Gm;for(var f=c.x+c.width,g=c.y+c.height,h=c.x;h<f;h+=d){for(var k=c.y;k<g;k+=b)Wj(a.Sd,h,k);Wj(a.Sd,h,g)}for(k=c.y;k<g;k+=b)Wj(a.Sd,f,k);Wj(a.Sd,f,g)}else if(b instanceof S)for(b=b.Rc;b.next();)f=b.value,f instanceof R&&Uj(a,f,c,d)}function Xj(a,b){null===a.Sd||a.Sd.nt||null!==b&&!b.canAvoid()||(a.Sd.nt=!0)}
z.prototype.simulatedMouseMove=z.prototype.dB=function(a,b,c){if(null!==ff){var d=ff.h;c instanceof z||(c=null);var f=gf;c!==f&&(null!==f&&f!==d&&null!==f.wb.Hd&&(tf(f),ff.pt=!1,f.wb.Hd.doSimulatedDragLeave()),gf=c,null!==c&&c!==d&&null!==c.wb.Hd&&(zf(),f=c.wb.Hd,wf.contains(f)||wf.add(f),c.wb.Hd.doSimulatedDragEnter()));if(null===c||c===d||!c.Kz||c.ab||!c.Bm)return!1;d=c.wb.Hd;null!==d&&(null!==a?b=c.Un(a):null===b&&(b=new w),c.ec.ea=b,c.ec.ak=!1,c.ec.lj=!1,d.doSimulatedDragOver());return!0}return!1};
z.prototype.simulatedMouseUp=z.prototype.rG=function(a,b,c,d){if(null!==ff){null===d&&(d=b);b=gf;var f=ff.h;if(d!==b){if(null!==b&&b!==f&&null!==b.wb.Hd)return tf(b),ff.pt=!1,b.wb.Hd.doSimulatedDragLeave(),!1;gf=d;null!==d&&null!==d.wb.Hd&&(zf(),b=d.wb.Hd,wf.contains(b)||wf.add(b),d.wb.Hd.doSimulatedDragEnter())}if(null===d)return ff.doCancel(),!0;if(d!==this)return null!==a&&(c=d.Un(a)),d.ec.ea=c,d.ec.ak=!1,d.ec.lj=!0,a=d.wb.Hd,null!==a&&a.doSimulatedDrop(),a=ff,null!==a&&(d=a.mayCopy(),a.gf=d?"Copy":
"Move",a.stopTool()),!0}return!1};t.g(z,"autoScrollRegion",z.prototype.OD);t.defineProperty(z,{OD:"autoScrollRegion"},function(){return this.ou},function(a){"number"===typeof a?a=new ab(a):t.l(a,ab,z,"autoScrollRegion");var b=this.ou;b.M(a)||(this.ou=a=a.Z(),this.Oc(),this.j("autoScrollRegion",b,a))});function Uf(a,b){a.nu.assign(b);Yj(a,a.nu).tf(a.position)?tf(a):Zj(a)}
function Zj(a){null===a.Il&&(a.Il={},a.Il.id=setInterval(function(){if(null!==a.Il){tf(a);var b=a.R.event;if(null!==b){var c=Yj(a,a.nu);c.tf(a.position)||(a.position=c,a.R.ea=a.KG(a.nu),a.dB(b,null,b.target.U)||a.doMouseMove(),a.wi=!0,Gi(a,a.qf()),a.ve=!0,a.Uf(),Zj(a))}}},a.hH))}function tf(a){null!==a.Il&&(clearInterval(a.Il.id),a.Il=null)}
function Yj(a,b){var c=a.position,d=a.OD;if(0>=d.top&&0>=d.left&&0>=d.right&&0>=d.bottom)return c;var f=a.xb,g=a.scale,f=t.ik(0,0,f.width*g,f.height*g),h=t.mc(0,0);if(b.x>=f.x&&b.x<f.x+d.left){var k=Math.max(a.Qp,1),k=k|0;h.x-=k;b.x<f.x+d.left/2&&(h.x-=k);b.x<f.x+d.left/4&&(h.x-=4*k)}else b.x<=f.x+f.width&&b.x>f.x+f.width-d.right&&(k=Math.max(a.Qp,1),k|=0,h.x+=k,b.x>f.x+f.width-d.right/2&&(h.x+=k),b.x>f.x+f.width-d.right/4&&(h.x+=4*k));b.y>=f.y&&b.y<f.y+d.top?(k=Math.max(a.Rp,1),k|=0,h.y-=k,b.y<f.y+
d.top/2&&(h.y-=k),b.y<f.y+d.top/4&&(h.y-=4*k)):b.y<=f.y+f.height&&b.y>f.y+f.height-d.bottom&&(k=Math.max(a.Rp,1),k|=0,h.y+=k,b.y>f.y+f.height-d.bottom/2&&(h.y+=k),b.y>f.y+f.height-d.bottom/4&&(h.y+=4*k));h.tf(F.mj)||(c=new w(c.x+h.x/g,c.y+h.y/g));t.hc(f);t.B(h);return c}z.prototype.makeSVG=z.prototype.makeSvg=function(a){void 0===a&&(a={});a.context="svg";a=ak(this,a);return null!==a?a.Bl:null};
z.prototype.makeImage=function(a){void 0===a&&(a={});var b=(a.document||document).createElement("img");b.src=this.kJ(a);return b instanceof HTMLImageElement?b:null};z.prototype.makeImageData=z.prototype.kJ=function(a){void 0===a&&(a={});var b=ak(this,a);return null!==b?b.toDataURL(a.type,a.details):""};
function ak(a,b){a.Ub.Al();a.Uf();if(null===a.gb)return null;"object"!==typeof b&&t.m("properties argument must be an Object.");var c=!1,d=b.size||null,f=b.scale||null;void 0!==b.scale&&isNaN(b.scale)&&(f="NaN");var g=b.maxSize;void 0===b.maxSize&&(c=!0,g="svg"===b.context?new fa(Infinity,Infinity):new fa(2E3,2E3));var h=b.position||null,k=b.parts||null,l=void 0===b.padding?1:b.padding,m=b.background||null,n=b.omitTemporary;void 0===n&&(n=!0);var p=b.document||document,q=b.elementFinished||null,r=
b.showTemporary;void 0===r&&(r=!n);n=b.showGrid;void 0===n&&(n=r);null!==d&&isNaN(d.width)&&isNaN(d.height)&&(d=null);l&&"number"===typeof l&&(l=new ab(l));l||(l=new ab(0));l.left=Math.max(l.left,0);l.right=Math.max(l.right,0);l.top=Math.max(l.top,0);l.bottom=Math.max(l.bottom,0);a.Mn=!1;wi(a);var s=p.createElement("canvas"),u=s.getContext("2d"),v=s;if(!(d||f||k||h))return s.width=a.Fb+Math.ceil(l.left+l.right),s.height=a.Eb+Math.ceil(l.top+l.bottom),"svg"===b.context&&(u=v=new nc(s,p,q),u instanceof
nc&&(a.Mn=!0)),Wi(a,u,l,new fa(s.width,s.height),a.xc,a.Ra,k,m,r,n),a.Mn=!0,v;var D=a.Se.Ys,A,G=new w(0,0);A=a.Gd.copy();A.YJ(a.padding);if(r)for(var K=!0,K=a.Rb.n,N=K.length,O=0;O<N;O++){var U=K[O];if(U.visible&&U.rc)for(var U=U.zb.n,X=U.length,Z=0;Z<X;Z++){var P=U[Z];P.Rw&&P.La()&&(P=P.oa,P.N()&&A.ii(P))}}G.x=A.x;G.y=A.y;if(k){var pa,K=!0,k=k.k;for(k.reset();k.next();)P=k.value,P instanceof C&&(U=P.layer,U&&!U.visible||null!==U&&!r&&U.rc||!P.La()||(P=P.oa,P.N()&&(K?(K=!1,pa=P.copy()):pa.ii(P))));
K&&(pa=new x(0,0,0,0));A.width=pa.width;A.height=pa.height;G.x=pa.x;G.y=pa.y}null!==h&&h.N()&&(G=h,f||(f=D));pa=h=0;l&&(h=l.left+l.right,pa=l.top+l.bottom);K=N=0;d&&(N=d.width,K=d.height,isFinite(N)&&(N=Math.max(0,N-h)),isFinite(K)&&(K=Math.max(0,K-pa)));d&&f?("NaN"===f&&(f=D),d.N()?(d=N,A=K):isNaN(K)?(d=N,A=A.height*f):(d=A.width*f,A=K)):d?d.N()?(f=Math.min(N/A.width,K/A.height),d=N,A=K):isNaN(K)?(f=N/A.width,d=N,A=A.height*f):(f=K/A.height,d=A.width*f,A=K):f?"NaN"===f&&g.N()?(f=Math.min((g.width-
h)/A.width,(g.height-pa)/A.height),f>D?(f=D,d=A.width,A=A.height):(d=g.width,A=g.height)):(d=A.width*f,A=A.height*f):(f=D,d=A.width,A=A.height);l?(d+=h,A+=pa):l=new ab(0);g&&(D=g.width,g=g.height,"svg"!==b.context&&c&&!t.SG&&(d>D||A>g)&&(t.trace("Diagram.makeImage(data): Diagram width or height is larger than the default max size. ("+Math.ceil(d)+"x"+Math.ceil(A)+" vs 2000x2000) Consider increasing the max size."),t.SG=!0),isNaN(D)&&(D=2E3),isNaN(g)&&(g=2E3),isFinite(D)&&(d=Math.min(d,D)),isFinite(g)&&
(A=Math.min(A,g)));s.width=Math.ceil(d);s.height=Math.ceil(A);"svg"===b.context&&(u=v=new nc(s,p,q),u instanceof nc&&(a.Mn=!0));Wi(a,u,l,new fa(Math.ceil(d),Math.ceil(A)),f,G,k,m,r,n);a.Mn=!0;return v}z.inherit=function(a,b){t.i(a,"function",z,"inherit");t.i(b,"function",z,"inherit");b.tH&&t.m("Cannot inherit from "+t.xg(b));t.Ka(a,b)};
function bk(a){1<arguments.length&&t.m("Palette constructor can only take one optional argument, the DIV HTML element or its id.");z.call(this,a);this.Ms=!0;this.Rj=!1;this.ab=!0;this.ow=Fb;this.jc=new ck}t.ga("Palette",bk);t.Ka(bk,z);
function Zi(a){1<arguments.length&&t.m("Overview constructor can only take one optional argument, the DIV HTML element or its id.");z.call(this,a);this.li.isEnabled=!1;this.Yb=!0;this.Gi=null;this.nH=this.Gu=!0;this.PJ("drawShadows",!1);var b=new C,c=new V;c.stroke="magenta";c.fb=2;c.fill="transparent";c.name="BOXSHAPE";b.qx="BOXSHAPE";b.ax="BOXSHAPE";b.cursor="move";b.add(c);this.Kl=b;this.yz=document.createElement("canvas");this.vH=this.yz.getContext("2d");b=new dk;b.yd(this);this.wb.ef.Jd(0,b);
var d=this;this.IF=function(){ek(d)};this.HF=function(){null!==d.Gi&&(d.Oc(),d.fa())};this.ab=!0;this.Pe=!1;this.Fm=Hi;this.wb.cn=af;this.Yb=!1}t.ga("Overview",Zi);t.Ka(Zi,z);
function $i(a){a.Yb||a.Bd||!1!==a.Nf||(a.Nf=!0,requestAnimationFrame(function(){if(a.Nf&&!a.Bd&&(a.Nf=!1,null!==a.Nb)){a.Bd=!0;Bi(a);a.Gd.N()||Gi(a,a.qf());null===a.Nb&&t.m("No div specified");null===a.gb&&t.m("No canvas specified");if(a.ve){var b=a.Gi;if(null!==b&&!b.Ub.yg&&!b.Ub.sd){var b=a.Sg,c=a.yz;b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,a.gb.width,a.gb.height);b.drawImage(c,0,0);c=a.md;c.reset();1!==a.xc&&c.scale(a.scale);0===a.position.x&&0===a.position.y||c.translate(-a.Ra.x,-a.Ra.y);b.setTransform(c.m11,
c.m12,c.m21,c.m22,c.dx,c.dy);for(var c=a.Rb.length,d=0;d<c;d++)a.Rb.n[d].Ue(b,a);a.Ik=!1;a.ve=!1}}a.Bd=!1}}))}Zi.prototype.computePixelRatio=function(){return 1};
Zi.prototype.Ue=function(){null===this.Nb&&t.m("No div specified");null===this.gb&&t.m("No canvas specified");if(this.ve){var a=this.Gi;if(!(null===a||a.Ub.yg||a.Ub.sd||!this.nH&&a.wb.Hd.ia)){var b=a.lp;(b&&b.visible&&isNaN(b.width)||isNaN(b.height))&&Li(a);var c=this.gb,b=this.Sg,d=this.yz,f=this.vH;d.width=c.width;d.height=c.height;b.du="";b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,this.gb.width,this.gb.height);var g=this.md;g.reset();1!==this.xc&&g.scale(this.scale);0===this.position.x&&0===this.position.y||
g.translate(-this.Ra.x,-this.Ra.y);b.setTransform(g.m11,g.m12,g.m21,g.m22,g.dx,g.dy);for(var h=this.Gu,k=this.xb,l=a.Rb.length,g=0;g<l;g++){var m=a.Rb.n[g],n=b,p=k,q=h;void 0===q&&(q=!0);if(q||!m.rc)for(var m=m.zb,q=this.scale,r=m.length,s=0;s<r;s++){var u=m.n[s],v=u.oa;v.Tf(p)&&(1<v.width*q||1<v.height*q?u.Ue(n,this):Rh(u,n))}}f.drawImage(c,0,0);e&&e.Rz&&(f.fillStyle="red",f.fillRect(0,d.height/2,d.width,4));a=this.Rb.n;c=this.Rb.length;for(g=0;g<c;g++)a[g].Ue(b,this);this.ve=this.Ik=!1}}};
t.g(Zi,"observed",Zi.prototype.uJ);t.defineProperty(Zi,{uJ:"observed"},function(){return this.Gi},function(a){var b=this.Gi;null!==a&&t.l(a,z,Zi,"observed");b===a||a instanceof Zi||(null!==b&&(this.remove(this.vg),b.OF("ViewportBoundsChanged",this.IF),b.OF("DocumentBoundsChanged",this.HF),b.lm.remove(this)),this.Gi=a,null!==a&&(a.Jz("ViewportBoundsChanged",this.IF),a.Jz("DocumentBoundsChanged",this.HF),a.lm.add(this),this.add(this.vg),ek(this)),this.Oc(),this.j("observed",b,a))});t.g(Zi,"box",Zi.prototype.vg);
t.defineProperty(Zi,{vg:"box"},function(){return this.Kl},function(a){var b=this.Kl;b!==a&&(this.Kl=a,this.remove(b),this.add(this.Kl),ek(this),this.j("box",b,a))});t.g(Zi,"drawsTemporaryLayers",Zi.prototype.CI);t.defineProperty(Zi,{CI:"drawsTemporaryLayers"},function(){return this.Gu},function(a){this.Gu!==a&&(this.Gu=a,this.RA())});
function ek(a){var b=a.vg;if(null!==b){var c=a.Gi;if(null!==c){a.ve=!0;var c=c.xb,d=b.Pt,f=t.Cl();f.p(c.width,c.height);d.Ca=f;t.ck(f);a=2/a.scale;d instanceof V&&(d.fb=a);b.location=new w(c.x-a/2,c.y-a/2)}}}Zi.prototype.qf=function(){var a=this.Gi;return null===a?F.gH:a.Gd};Zi.prototype.Et=function(a){this.Yb||(zi(this),this.fa(),Ki(this),this.Oc(),ek(this),this.Ba("ViewportBoundsChanged",a))};function dk(){ae.call(this);this.name="MoveBox"}t.Ka(dk,ae);
dk.prototype.doStart=function(){this.ia=this.h.Kd=!0};dk.prototype.doMouseMove=function(){if(this.ia){var a=this.h;if(null!==a&&null!==a.Gi){var b=a.Gi;if(null!==b){var c=b.xb,d=a.R.ea;b.position=new w(d.x-c.width/2,d.y-c.height/2);a.te()}}}};dk.prototype.doMouseUp=function(){this.ia&&this.doMouseMove();this.stopTool()};dk.prototype.doStop=function(){this.ia=!1;var a=this.h;null!==a&&(a.Kd=!1,ek(a))};
function ea(a){1<arguments.length&&t.m("Brush constructor can take at most one optional argument, the Brush type.");t.Ac(this);this.nb=!1;void 0===a?(this.da=Nd,this.zn="black"):"string"===typeof a?(this.da=Nd,e&&t.To("Brush constructor",a),this.zn=a):(e&&t.Aa(a,ea,ea,"constructor:type"),this.da=a,this.zn="black");var b=this.da;b===Yd?(this.Ao=Fb,this.On=Mb):this.On=b===Zd?this.Ao=Hb:this.Ao=wb;this.Mv=0;this.Hu=NaN;this.Rg=this.yv=this.Qg=null;this.sy=this.ty=0}t.ga("Brush",ea);var Nd;
ea.Solid=Nd=t.w(ea,"Solid",0);var Yd;ea.Linear=Yd=t.w(ea,"Linear",1);var Zd;ea.Radial=Zd=t.w(ea,"Radial",2);var fk;ea.Pattern=fk=t.w(ea,"Pattern",4);var da;ea.isValidColor=da=function(a){if("black"===a)return!0;if(""===a)return!1;e&&t.i(a,"string",ea,"isValidColor");var b=t.ln;b.fillStyle="#000000";var c=b.fillStyle;b.fillStyle=a;if(b.fillStyle!==c)return!0;b.fillStyle="#FFFFFF";c=b.fillStyle;b.fillStyle=a;return b.fillStyle!==c};
ea.prototype.copy=function(){var a=new ea;a.da=this.da;a.zn=this.zn;a.Ao=this.Ao.Z();a.On=this.On.Z();a.Mv=this.Mv;a.Hu=this.Hu;null!==this.Qg&&(a.Qg=this.Qg.copy());a.yv=this.yv;return a};ea.prototype.Ja=function(){this.freeze();Object.freeze(this);return this};ea.prototype.freeze=function(){this.nb=!0;null!==this.Qg&&this.Qg.freeze();return this};ea.prototype.Ma=function(){Object.isFrozen(this)&&t.m("cannot thaw constant: "+this);this.nb=!1;null!==this.Qg&&this.Qg.Ma();return this};
ea.prototype.toString=function(){var a="Brush(";if(this.type===Nd)a+=this.color;else if(a=this.type===Yd?a+"Linear ":this.type===Zd?a+"Radial ":this.type===fk?a+"Pattern ":a+"(unknown) ",a+=this.start+" "+this.end,null!==this.Vo)for(var b=this.Vo.k;b.next();)a+=" "+b.key+":"+b.value;return a+")"};
ea.prototype.addColorStop=ea.prototype.addColorStop=function(a,b){t.L(this);("number"!==typeof a||!isFinite(a)||1<a||0>a)&&t.ka(a,"0 <= loc <= 1",ea,"addColorStop:loc");t.i(b,"string",ea,"addColorStop:color");null===this.Qg&&(this.Qg=new ia("number","string"));this.Qg.add(a,b);this.da===Nd&&(this.type=Yd);this.Rg=null};t.g(ea,"type",ea.prototype.type);
t.defineProperty(ea,{type:"type"},function(){return this.da},function(a){t.L(this,a);t.Aa(a,ea,ea,"type");this.da=a;this.start.Ge()&&(a===Yd?this.start=Fb:a===Zd&&(this.start=Hb));this.end.Ge()&&(a===Yd?this.end=Mb:a===Zd&&(this.end=Hb));this.Rg=null});t.g(ea,"color",ea.prototype.color);t.defineProperty(ea,{color:"color"},function(){return this.zn},function(a){t.L(this,a);t.i(a,"string",ea,"color");this.zn=a;this.Rg=null});t.g(ea,"start",ea.prototype.start);
t.defineProperty(ea,{start:"start"},function(){return this.Ao},function(a){t.L(this,a);a instanceof H||t.bc(a,"Spot",ea,"start");this.Ao=a.Z();this.Rg=null});t.g(ea,"end",ea.prototype.end);t.defineProperty(ea,{end:"end"},function(){return this.On},function(a){t.L(this,a);a instanceof H||t.bc(a,"Spot",ea,"end");this.On=a.Z();this.Rg=null});t.g(ea,"startRadius",ea.prototype.Vp);
t.defineProperty(ea,{Vp:"startRadius"},function(){return this.Mv},function(a){t.L(this,a);t.q(a,ea,"startRadius");0>a&&t.ka(a,">= zero",ea,"startRadius");this.Mv=a;this.Rg=null});t.g(ea,"endRadius",ea.prototype.ap);t.defineProperty(ea,{ap:"endRadius"},function(){return this.Hu},function(a){t.L(this,a);t.q(a,ea,"endRadius");0>a&&t.ka(a,">= zero",ea,"endRadius");this.Hu=a;this.Rg=null});t.g(ea,"colorStops",ea.prototype.Vo);
t.defineProperty(ea,{Vo:"colorStops"},function(){return this.Qg},function(a){t.L(this,a);e&&t.l(a,ia,ea,"colorStops");this.Qg=a;this.Rg=null});t.g(ea,"pattern",ea.prototype.pattern);t.defineProperty(ea,{pattern:"pattern"},function(){return this.yv},function(a){t.L(this,a);this.yv=a;this.Rg=null});
ea.randomColor=function(a,b){void 0===a&&(a=128);e&&(t.q(a,ea,"randomColor:min"),(0>a||255<a)&&t.ka(a,"0 <= min <= 255",ea,"randomColor:min"));void 0===b&&(b=Math.max(a,255));e&&(t.q(b,ea,"randomColor:max"),(b<a||255<b)&&t.ka(b,"min <= max <= 255",ea,"randomColor:max"));var c=Math.abs(b-a),d=Math.floor(a+Math.random()*c).toString(16),f=Math.floor(a+Math.random()*c).toString(16),c=Math.floor(a+Math.random()*c).toString(16);2>d.length&&(d="0"+d);2>f.length&&(f="0"+f);2>c.length&&(c="0"+c);return"#"+
d+f+c};
function Q(){t.Ac(this);this.ma=30723;this.Lf=1;this.Ii=null;this.Zb="";this.vc=this.Mb=null;this.Ra=(new w(NaN,NaN)).freeze();this.jf=(new fa(NaN,NaN)).freeze();this.Ej=F.gq;this.Dj=F.fH;this.md=new ga;this.kk=new ga;this.Mk=new ga;this.xc=1;this.wn=0;this.Oh=gk;this.sr=F.fq;this.Wc=(new x(NaN,NaN,NaN,NaN)).freeze();this.cc=(new x(NaN,NaN,NaN,NaN)).freeze();this.hd=(new x(0,0,NaN,NaN)).freeze();this.ys=this.Nq=this.P=this.Nr=this.Pr=null;this.zs=this.Oq=Infinity;this.mq=this.ue=xb;this.as=0;this.Jj=
1;this.tq=0;this.rj=1;this.es=-Infinity;this.ds=0;this.fs=F.mj;this.gs=zg;this.Aq="";this.nm=this.oi=this.Ml=this.Kc=this.Q=null}t.ga("GraphObject",Q);t.Xh(Q);
Q.prototype.cloneProtected=function(a){a.ma=this.ma|6144;a.Lf=this.Lf;a.Zb=this.Zb;a.Mb=this.Mb;a.vc=this.vc;a.Ra.assign(this.Ra);a.jf.assign(this.jf);a.Ej=this.Ej.Z();a.Dj=this.Dj.Z();a.Mk=this.Mk.copy();a.xc=this.xc;a.wn=this.wn;a.Oh=this.Oh;a.sr=this.sr.Z();a.Wc.assign(this.Wc);a.cc.assign(this.cc);a.hd.assign(this.hd);a.Nr=this.Nr;if(null!==this.P){var b=this.P;a.P={Dh:b.Dh.Z(),Th:b.Th.Z(),Ah:b.Ah,Qh:b.Qh,zh:b.zh,Ph:b.Ph,Ch:b.Ch,Sh:b.Sh}}else a.P=null;a.Nq=this.Nq;a.Oq=this.Oq;a.ys=this.ys;a.zs=
this.zs;a.ue=this.ue.Z();a.mq=this.mq.Z();a.as=this.as;a.Jj=this.Jj;a.tq=this.tq;a.rj=this.rj;a.es=this.es;a.ds=this.ds;a.fs=this.fs.Z();a.gs=this.gs;a.Aq=this.Aq;null!==this.Q?(b=this.Q,a.Q={ni:b.ni,ti:b.ti,pi:b.pi,Dr:b.Dr,Er:b.Er,Ei:b.Ei,Di:b.Di,Ci:b.Ci,Br:b.Br,Cr:b.Cr,Bi:b.Bi,iq:b.iq,jq:b.jq,kq:b.kq,hq:b.hq,Oi:b.Oi,qi:b.qi}):a.Q=null;a.Kc=this.Kc;if(Array.isArray(this.Ml))for(a.Ml=this.Ml.slice(0),b=0;b<this.Ml.length;b++){var c=this.Ml[b];a[c]=this[c]}null!==this.oi&&(a.oi=this.oi.copy())};
Q.prototype.Yh=function(a){a.Pr=null;a.nm=null;a.ha()};Q.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};Q.prototype.toString=function(){return t.xg(Object.getPrototypeOf(this))+"#"+t.od(this)};var Vg;Q.None=Vg=t.w(Q,"None",0);var gk;Q.Default=gk=t.w(Q,"Default",0);var hk;Q.Vertical=hk=t.w(Q,"Vertical",4);var ik;Q.Horizontal=ik=t.w(Q,"Horizontal",5);var Cc;Q.Fill=Cc=t.w(Q,"Fill",3);var Xg;Q.Uniform=Xg=t.w(Q,"Uniform",1);var Yg;
Q.UniformToFill=Yg=t.w(Q,"UniformToFill",2);function jk(a){a.Q={ni:null,ti:null,pi:null,Dr:null,Er:null,Ei:null,Di:null,Ci:null,Br:null,Cr:null,Bi:null,iq:null,jq:null,kq:null,hq:null,Oi:null,qi:null}}aa=Q.prototype;aa.Fe=function(){this.P={Dh:wb,Th:wb,Ah:10,Qh:10,zh:kk,Ph:kk,Ch:0,Sh:0}};
function lk(a,b,c,d,f,g,h){var k=0.001,l=g.length;a.moveTo(b,c);d-=b;k=f-c;0===d&&(d=0.001);f=k/d;for(var m=Math.sqrt(d*d+k*k),n=0,p=!0,q=0===h?!1:!0;0.1<=m;){if(q){k=g[n++%l];for(k-=h;0>k;)k+=g[n++%l],p=!p;q=!1}else k=g[n++%l];k>m&&(k=m);var r=Math.sqrt(k*k/(1+f*f));0>d&&(r=-r);b+=r;c+=f*r;p?a.lineTo(b,c):a.moveTo(b,c);m-=k;p=!p}}aa.Ic=function(a,b,c,d,f,g,h){var k=this.S;null!==k&&(k.gn(a,b,c,d,f,g,h),0!==(this.ma&1024)&&c===this&&a===vd&&mk(this,k,b))};
function mk(a,b,c){var d=a.ep();if(null!==d)for(var f=a.Kc.k;f.next();){var g=f.value,h=g.Ut;if(null!==h){var k=d.oe(h);if(null===k)continue;g.nB(a,k,c,null)}else{k=d.data;if(null===k)continue;var l=b.h;null!==l&&l.Tt||g.nB(a,k,c,l)}null!==h&&(k=d,""!==h&&(k=d.oe(h)),null!==k&&(h=g.nn,l=d,""!==h&&(l=d.oe(h)),null!==l&&g.PG(l,k,c)))}}aa.j=function(a,b,c){this.Ic(vd,a,this,b,c)};
function nk(a,b,c,d,f){var g=a.Wc,h=a.Mk;h.reset();ok(a,h,b,c,d,f);a.Mk=h;g.x=b;g.y=c;g.width=d;g.height=f;h.qt()||h.JG(g)}function pk(a,b,c,d){if(!1===a.Bf)return!1;d.multiply(a.transform);return c?a.Tf(b,d):a.Km(b,d)}
aa.AE=function(a,b,c){if(!1===this.Bf)return!1;var d=this.Pa;b=a.Zj(b);var f=!1;c&&(f=Ra(a.x,a.y,0,0,0,d.height)<b||Ra(a.x,a.y,0,d.height,d.width,d.height)<b||Ra(a.x,a.y,d.width,d.height,d.width,0)<b||Ra(a.x,a.y,d.width,0,0,0)<b);c||(f=Ra(a.x,a.y,0,0,0,d.height)<b&&Ra(a.x,a.y,0,d.height,d.width,d.height)<b&&Ra(a.x,a.y,d.width,d.height,d.width,0)<b&&Ra(a.x,a.y,d.width,0,0,0)<b);return f};aa.Hf=function(){return!0};
Q.prototype.containsPoint=Q.prototype.Fa=function(a){e&&t.l(a,w,Q,"containsPoint:p");var b=t.K();b.assign(a);this.transform.Sa(b);var c=this.oa;if(!c.N())return!1;if(t.gB){var d=this.Pa,f=this.ek()*(null!==this.h?this.h.scale:1),g=1/f;if(10>d.width*f&&10>d.height*f)return a=mb(c.x-5*g,c.y-5*g,c.width+10*g,c.height+10*g,b.x,b.y),t.B(b),a}if(void 0!==this.pc||this instanceof V?mb(c.x-5,c.y-5,c.width+10,c.height+10,b.x,b.y):c.Fa(b)){if(this.oi&&!this.oi.Fa(b))return!1;if(null!==this.vc&&c.Fa(b)||null!==
this.Mb&&this.hd.Fa(a))return!0;t.B(b);return this.Uj(a)}t.B(b);return!1};Q.prototype.Uj=function(a){var b=this.Pa;return mb(0,0,b.width,b.height,a.x,a.y)};
Q.prototype.containsRect=Q.prototype.Vj=function(a){e&&t.l(a,x,Q,"containsRect:r");if(0===this.angle)return this.oa.Vj(a);var b=this.Pa,b=t.ik(0,0,b.width,b.height),c=this.transform,d=!1,f=t.mc(a.x,a.y);b.Fa(c.ai(f))&&(f.p(a.x,a.bottom),b.Fa(c.ai(f))&&(f.p(a.right,a.bottom),b.Fa(c.ai(f))&&(f.p(a.right,a.y),b.Fa(c.ai(f))&&(d=!0))));t.B(f);t.hc(b);return d};
Q.prototype.containedInRect=Q.prototype.Km=function(a,b){e&&t.l(a,x,Q,"containedInRect:r");if(void 0===b)return a.Vj(this.oa);var c=this.Pa,d=!1,f=t.mc(0,0);a.Fa(b.Sa(f))&&(f.p(0,c.height),a.Fa(b.Sa(f))&&(f.p(c.width,c.height),a.Fa(b.Sa(f))&&(f.p(c.width,0),a.Fa(b.Sa(f))&&(d=!0))));return d};
Q.prototype.intersectsRect=Q.prototype.Tf=function(a,b){e&&t.l(a,x,Q,"intersectsRect:r");if(void 0===b&&(b=this.transform,0===this.angle))return a.Tf(this.oa);var c=this.Pa,d=b,f=t.mc(0,0),g=t.mc(0,c.height),h=t.mc(c.width,c.height),k=t.mc(c.width,0),l=!1;if(a.Fa(d.Sa(f))||a.Fa(d.Sa(g))||a.Fa(d.Sa(h))||a.Fa(d.Sa(k)))l=!0;else{var c=t.ik(0,0,c.width,c.height),m=t.mc(a.x,a.y);c.Fa(d.ai(m))?l=!0:(m.p(a.x,a.bottom),c.Fa(d.ai(m))?l=!0:(m.p(a.right,a.bottom),c.Fa(d.ai(m))?l=!0:(m.p(a.right,a.y),c.Fa(d.ai(m))&&
(l=!0))));t.B(m);t.hc(c);!l&&(F.Nw(a,f,g)||F.Nw(a,g,h)||F.Nw(a,h,k)||F.Nw(a,k,f))&&(l=!0)}t.B(f);t.B(g);t.B(h);t.B(k);return l};Q.prototype.getDocumentPoint=Q.prototype.pb=function(a,b){void 0===b&&(b=new w);a.Ge()&&t.m("getDocumentPoint:s Spot must be real: "+a.toString());var c=this.Pa;b.p(a.x*c.width+a.offsetX,a.y*c.height+a.offsetY);this.me.Sa(b);return b};Q.prototype.getDocumentAngle=Q.prototype.hl=function(){var a=this.me,a=180/Math.PI*Math.atan2(a.m12,a.m11);0>a&&(a+=360);return a};
Q.prototype.getDocumentScale=Q.prototype.ek=function(){var a=this.xc;return null!==this.ja?a*this.ja.ek():a};Q.prototype.getLocalPoint=Q.prototype.JE=function(a,b){void 0===b&&(b=new w);b.assign(a);this.me.ai(b);return b};Q.prototype.getNearestIntersectionPoint=Q.prototype.ll=function(a,b,c){return this.kp(a.x,a.y,b.x,b.y,c)};aa=Q.prototype;
aa.kp=function(a,b,c,d,f){var g=this.transform,h=1/(g.m11*g.m22-g.m12*g.m21),k=g.m22*h,l=-g.m12*h,m=-g.m21*h,n=g.m11*h,p=h*(g.m21*g.dy-g.m22*g.dx),q=h*(g.m12*g.dx-g.m11*g.dy);if(null!==this.$k)return g=this.oa,F.ll(g.left,g.top,g.right,g.bottom,a,b,c,d,f);h=a*k+b*m+p;a=a*l+b*n+q;b=c*k+d*m+p;c=c*l+d*n+q;f.p(0,0);d=this.Pa;c=F.ll(0,0,d.width,d.height,h,a,b,c,f);f.transform(g);return c};
function ih(a,b,c,d,f){if(!1!==Qi(a)){var g=a.margin,h=g.right+g.left,g=g.top+g.bottom;b=Math.max(b-h,0);c=Math.max(c-g,0);f=f||0;d=Math.max((d||0)-h,0);f=Math.max(f-g,0);h=a.angle;if(90===h||270===h)h=b,b=c,c=h,h=d,d=f,f=h;h=a.Ca;g=0;a.fb&&(g=a.fb);b=isFinite(h.width)?h.width+g:b;c=isFinite(h.height)?h.height+g:c;var h=d||0,g=f||0,k=a instanceof y;switch(qk(a)){case Vg:g=h=0;k&&(c=b=Infinity);break;case Cc:isFinite(b)&&b>d&&(h=b);isFinite(c)&&c>f&&(g=c);break;case ik:isFinite(b)&&b>d&&(h=b);g=0;
k&&(c=Infinity);break;case hk:isFinite(c)&&c>f&&(g=c),h=0,k&&(b=Infinity)}var k=a.He,l=a.Vf;h>k.width&&l.width<k.width&&(h=k.width);g>k.height&&l.height<k.height&&(g=k.height);d=Math.max(h/a.scale,l.width);f=Math.max(g/a.scale,l.height);k.width<d&&(d=Math.min(l.width,d));k.height<f&&(f=Math.min(l.height,f));b=Math.min(k.width,b);c=Math.min(k.height,c);b=Math.max(d,b);c=Math.max(f,c);a.Wc.Ma();a.ut(b,c,d,f);a.Wc.freeze();a.Wc.N()||t.m("Non-real measuredBounds has been set. Object "+a+", measuredBounds: "+
a.Wc.toString());rk(a,!1)}}
aa.Lc=function(a,b,c,d,f){sk(this);var g=t.Gf();g.assign(this.cc);this.cc.Ma();if(!1===Ri(this)){var h=this.cc;h.x=a;h.y=b;h.width=c;h.height=d}else this.Tj(a,b,c,d);this.cc.freeze();this.oi=void 0===f?null:f;c=!1;void 0!==f?c=!0:this.ja&&(f=this.ja.hd,d=this.Ga,null!==this.$k&&(d=this.cc),c=b+d.height,d=a+d.width,c=!(0<=a+0.05&&d<=f.width+0.05&&0<=b+0.05&&c<=f.height+0.05),this instanceof na&&(a=this.hd,this.dv>a.height||this.no.Ag>a.width))&&(c=!0);this.ma=c?this.ma|256:this.ma&-257;this.cc.N()||
t.m("Non-real actualBounds has been set. Object "+this+", actualBounds: "+this.cc.toString());tk(this,g,this.cc);t.hc(g)};aa.Tj=function(){};
function uk(a,b,c,d,f){var g=a.oa;g.x=b;g.y=c;g.width=d;g.height=f;if(!a.Ca.N()){g=a.Wc;c=a.margin;b=c.right+c.left;var h=c.top+c.bottom;c=g.width+b;g=g.height+h;d+=b;f+=h;b=qk(a);c===d&&g===f&&(b=Vg);switch(b){case Vg:if(c>d||g>f)rk(a,!0),ih(a,c>d?d:c,g>f?f:g);break;case Cc:rk(a,!0);ih(a,d,f,0,0);break;case ik:rk(a,!0);ih(a,d,g,0,0);break;case hk:rk(a,!0),ih(a,c,f,0,0)}}}
function tk(a,b,c){vk(a,!1);var d=a.S;if(null!==d){var f=d.h;if(null!==f)if(wk(d),a instanceof C){var g=!1,d=b.N();if(!1===f.wi){var h=f.Gd,k=f.padding,l=h.x+k.left,m=h.y+k.top,n=h.width-2*k.right,h=h.height-2*k.bottom;d&&b.x>l&&b.y>m&&b.right<n&&b.bottom<h&&c.x>l&&c.y>m&&c.right<n&&c.bottom<h&&(g=!0)}0!==(a.W&65536)!==!0&&b.M(c)||dj(a,g,f);f.pz&&null!==f.tj?(g=null,d&&(g=t.Gf(),Th(a,b,g)),f.fa(c.N()?Th(a,c):null,g),null!==g&&t.hc(g)):f.fa()}else xk(a,d),a.fa(),b=a.S,null!==b&&(b.Pt!==a&&b.WF!==a&&
b.aG!==a||yk(b,!0))}}function xk(a,b){null!==a.Nd&&yk(b,!0);if(a instanceof y)for(var c=a.xa,d=c.length,f=0;f<d;f++)xk(c.n[f],b)}
aa.Ue=function(a,b){if(this.visible){var c=this.opacity,d=1;if(1!==c){if(0===c)return;d=a.globalAlpha;a.globalAlpha=d*c}if(a instanceof nc)a:{if(this.visible){var f=null,g=a.Vw;if(this instanceof y&&(this.type===zk||this.type===Ak))Bk(this,a,b);else{var h=this.cc;if(0!==h.width&&0!==h.height&&!isNaN(h.x)&&!isNaN(h.y)){var k=this.transform,l=this.ja,m=this.kk;m.reset();null!==l&&(l.Hf()?m.multiply(l.me):null!==l.ja&&m.multiply(l.ja.me));m.multiply(this.md);var m=0!==(this.ma&256),n=!1;this instanceof
na&&Ck(this,a);if(m){n=l.Hf()?l.Pa:l.oa;if(this.oi)var p=this.oi,q=p.x,r=p.y,s=p.width,p=p.height;else q=Math.max(h.x,n.x),r=Math.max(h.y,n.y),s=Math.min(h.right,n.right)-q,p=Math.min(h.bottom,n.bottom)-r;if(q>h.width+h.x||h.x>n.width+n.x||r>h.height+h.y||h.y>n.height+n.y)break a;n=!0;rc(a,1,0,0,1,0,0);a.save();a.beginPath();a.rect(q,r,s,p);a.clip()}q=!1;if(this instanceof C&&(q=!0,!this.La()))break a;r=!1;s=b.Sk;this.S&&s.drawShadows&&(r=this.S.gj);a.$i.cf=[1,0,0,1,0,0];null!==this.vc&&(Dk(this,
a,this.vc,!0,!0),this.vc instanceof ea&&this.vc.type===Zd?(a.beginPath(),a.rect(h.x,h.y,h.width,h.height),Ek(a,this.vc,!0)):a.fillRect(h.x,h.y,h.width,h.height));q&&this.gj&&s.drawShadows&&(rc(a,1,0,0,1,0,0),h=this.wm,a.shadowOffsetX=h.x,a.shadowOffsetY=h.y,a.shadowColor=this.vm,a.shadowBlur=this.um/b.scale,a.bb());this instanceof y?rc(a,k.m11,k.m12,k.m21,k.m22,k.dx,k.dy):a.$i.cf=[k.m11,k.m12,k.m21,k.m22,k.dx,k.dy];if(null!==this.Mb){var p=this.Pa,h=k=0,s=p.width,p=p.height,u=0;this instanceof V&&
(p=this.Ta.Pb,k=p.x,h=p.y,s=p.width,p=p.height,u=this.bh);Dk(this,a,this.Mb,!0);this.Mb instanceof ea&&this.Mb.type===Zd?(a.beginPath(),a.rect(k-u/2,h-u/2,s+u,p+u),Ek(a,this.Mb,!0)):a.fillRect(k-u/2,h-u/2,s+u,p+u)}if(r&&(null!==this.Mb||null!==this.vc||null!==l&&0!==(l.ma&512)||null!==l&&l.type===ci&&l.Dw()!==this)){Fk(this,!0);var v=[a.shadowOffsetX,a.shadowOffsetY,a.shadowBlur];a.shadowOffsetX=0;a.shadowOffsetY=0;a.shadowBlur=0}else Fk(this,!1);this.fl(a,b);r&&0!==(this.ma&512)===!0&&(a.shadowOffsetX=
v[0],a.shadowOffsetY=v[1],a.shadowBlur=v[2]);q&&r&&(a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0);m&&(a.restore(),n&&a.vf.pop(),wi(b,a));this instanceof y&&(f=a.vf.pop());q&&r&&a.vf.pop();null!==a.wE&&(null===f&&(g===a.Vw?(rc(a,1,0,0,1,0,0),v=a.vf.pop()):v=a.Vw,f=v),a.wE(this,f))}}}}else if(this instanceof y&&(this.type===zk||this.type===Ak))Bk(this,a,b);else if(m=this.cc,0!==m.width&&0!==m.height&&!isNaN(m.x)&&!isNaN(m.y)){v=this.transform;g=this.ja;l=this.kk;l.reset();null!==g&&(g.Hf()?l.multiply(g.me):
null!==g.ja&&l.multiply(g.ja.me));l.multiply(this.md);l=0!==(this.ma&256);this instanceof na&&Ck(this,a);if(l){e&&e.BI&&t.trace("clip"+this.toString());n=g.Hf()?g.Pa:g.oa;this.oi?(r=this.oi,k=r.x,h=r.y,q=r.width,r=r.height):(k=Math.max(m.x,n.x),h=Math.max(m.y,n.y),q=Math.min(m.right,n.right)-k,r=Math.min(m.bottom,n.bottom)-h);if(k>m.width+m.x||m.x>n.width+n.x||h>m.height+m.y||m.y>n.height+n.y){1!==c&&(a.globalAlpha=d);return}a.save();a.beginPath();a.rect(k,h,q,r);a.clip()}h=b.Sk;n=!1;if(this instanceof
C){n=!0;if(!this.La()){1!==c&&(a.globalAlpha=d);return}this.gj&&h.drawShadows&&(k=this.wm,a.shadowOffsetX=k.x*b.scale,a.shadowOffsetY=k.y*b.scale,a.shadowColor=this.vm,a.shadowBlur=this.um)}k=!1;this.S&&h.drawShadows&&(k=this.S.gj);null!==this.vc&&(Dk(this,a,this.vc,!0,!0),this.vc instanceof ea&&this.vc.type===Zd?(a.beginPath(),a.rect(m.x,m.y,m.width,m.height),Ek(a,this.vc,!0)):a.fillRect(m.x,m.y,m.width,m.height));v.qt()||a.transform(v.m11,v.m12,v.m21,v.m22,v.dx,v.dy);null!==this.Mb&&(r=this.Pa,
h=m=0,q=r.width,r=r.height,s=0,this instanceof V&&(r=this.Ta.Pb,m=r.x,h=r.y,q=r.width,r=r.height,s=this.bh),Dk(this,a,this.Mb,!0),this.Mb instanceof ea&&this.Mb.type===Zd?(a.beginPath(),a.rect(m-s/2,h-s/2,q+s,r+s),Ek(a,this.Mb,!0)):a.fillRect(m-s/2,h-s/2,q+s,r+s));k&&(null!==this.Mb||null!==this.vc||null!==g&&0!==(g.ma&512)||null!==g&&(g.type===ci||g.type===Tg)&&g.Dw()!==this)?(Fk(this,!0),f=[a.shadowOffsetX,a.shadowOffsetY,a.shadowBlur],a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0):Fk(this,
!1);this.fl(a,b);k&&0!==(this.ma&512)===!0&&(a.shadowOffsetX=f[0],a.shadowOffsetY=f[1],a.shadowBlur=f[2]);n&&k&&(a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0);l?(a.restore(),this instanceof y?wi(b,a,!0):wi(b,a,!1)):v.qt()||(f=1/(v.m11*v.m22-v.m12*v.m21),a.transform(v.m22*f,-v.m12*f,-v.m21*f,v.m11*f,f*(v.m21*v.dy-v.m22*v.dx),f*(v.m12*v.dx-v.m11*v.dy)))}1!==c&&(a.globalAlpha=d)}};
function Bk(a,b,c){var d=a.cc;0===d.width||0===d.height||isNaN(d.x)||isNaN(d.y)||(null!==a.vc&&(Dk(a,b,a.vc,!0,!0),a.vc instanceof ea&&a.vc.type===Zd?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),Ek(b,a.vc,!0)):b.fillRect(d.x,d.y,d.width,d.height)),null!==a.Mb&&(Dk(a,b,a.Mb,!0),a.Mb instanceof ea&&a.Mb.type===Zd?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),Ek(b,a.Mb,!0)):b.fillRect(d.x,d.y,d.width,d.height)),a.fl(b,c))}aa.fl=function(){};
function Ek(a,b,c){if(c)if(a instanceof CanvasRenderingContext2D&&b instanceof ea&&b.type===Zd){var d=b.ty;b=b.sy;b>d?(a.scale(d/b,1),a.translate((b-d)/2,0),c?a.fill():a.stroke(),a.translate(-(b-d)/2,0),a.scale(1/(d/b),1)):d>b?(a.scale(1,b/d),a.translate(0,(d-b)/2),c?a.fill():a.stroke(),a.translate(0,-(d-b)/2),a.scale(1,1/(b/d))):c?a.fill():a.stroke()}else c?a.fill():a.stroke();else a.stroke()}
function Dk(a,b,c,d,f){if(null!==c){var g=1,h=1;if("string"===typeof c)d?b.rn!==c&&(b.fillStyle=c,b.rn=c):b.sn!==c&&(b.strokeStyle=c,b.sn=c);else if(c.type===Nd)c=c.color,d?b.rn!==c&&(b.fillStyle=c,b.rn=c):b.sn!==c&&(b.strokeStyle=c,b.sn=c);else{var k,h=a.Pa,g=h.width,h=h.height;if(f)var l=a.oa,g=l.width,h=l.height;var m=b instanceof CanvasRenderingContext2D;if(m&&(c.Rg&&c.type===fk||c.ty===g&&c.sy===h))k=c.Rg;else{var n,p,q=p=0;f&&(l=a.oa,g=l.width,h=l.height,p=l.x,q=l.y);c.start instanceof w?(a=
c.start.x,f=c.start.y):c.start instanceof H?(a=c.start.x*g+c.start.offsetX,f=c.start.y*h+c.start.offsetY):(a=Hb.x*g+c.start.offsetX,f=Hb.y*h+c.start.offsetY);c.end instanceof w?(l=c.end.x,n=c.end.y):c.end instanceof H?(l=c.end.x*g+c.end.offsetX,n=c.end.y*h+c.end.offsetY):c.type===Yd?(l=Mb.x*g+c.end.offsetX,n=Mb.y*h+c.end.offsetY):(l=Hb.x*g+c.end.offsetX,n=Hb.y*h+c.end.offsetY);a+=p;l+=p;f+=q;n+=q;c.type===Yd?k=b.createLinearGradient(a,f,l,n):c.type===Zd?(p=isNaN(c.ap)?Math.max(g,h)/2:c.ap,isNaN(c.Vp)?
(k=0,p=Math.max(g,h)/2):k=c.Vp,k=b.createRadialGradient(a,f,k,l,n,p)):c.type===fk?k=b.createPattern(c.pattern,"repeat"):t.bc(c.type,"Brush type");if(c.type!==fk&&(p=c.Vo))for(p=p.k;p.next();)k.addColorStop(p.key,p.value);m&&(c.Rg=k,c.ty=g,c.sy=h)}d?b.rn!==k&&(b.fillStyle=k,b.rn=k):b.sn!==k&&(b.strokeStyle=k,b.sn=k)}}}Q.prototype.isContainedBy=Q.prototype.ej=function(a){if(a instanceof y)a:{if(this!==a&&null!==a)for(var b=this.ja;null!==b;){if(b===a){a=!0;break a}b=b.ja}a=!1}else a=!1;return a};
Q.prototype.isVisibleObject=Q.prototype.pl=function(){if(!this.visible)return!1;var a=this.ja;return null!==a?a.pl():!0};
function Gk(a){if(0!==(a.ma&2048)===!0){var b=a.md;b.reset();if(!a.cc.N()||!a.Wc.N()){Hk(a,!1);return}b.translate(a.cc.x,a.cc.y);b.translate(-a.Ga.x,-a.Ga.y);var c=a.Pa;ok(a,b,c.x,c.y,c.width,c.height);Hk(a,!1);Ik(a,!0)}0!==(a.ma&4096)===!0&&(b=a.ja,null===b?(a.kk.set(a.md),Ik(a,!1)):null!==b.me&&(c=a.kk,c.reset(),b.Hf()?c.multiply(b.kk):null!==b.ja&&c.multiply(b.ja.kk),c.multiply(a.md),Ik(a,!1)))}
function ok(a,b,c,d,f,g){1!==a.scale&&b.scale(a.scale);if(0!==a.angle){var h=Hb;a.bf&&a.bf.xd()&&(h=a.bf);var k=t.K();if(a instanceof C&&a.kc!==a)for(c=a.kc,d=c.Pa,k.Rt(d.x,d.y,d.width,d.height,h),c.Mk.Sa(k),k.offset(-c.Ga.x,-c.Ga.y),h=c.ja;null!==h&&h!==a;)h.Mk.Sa(k),k.offset(-h.Ga.x,-h.Ga.y),h=h.ja;else k.Rt(c,d,f,g,h);b.rotate(a.angle,k.x,k.y);t.B(k)}}
Q.prototype.ha=function(a){if(!0!==Qi(this)){rk(this,!0);vk(this,!0);var b=this.ja;null!==b?a||b.ha():(a=this.h,null!==a&&(this.La()&&a.fg.add(this),this instanceof R&&(a.la.vb||this.wf(),null!==this.$d&&Jk(this.$d)),a.te()));if(this instanceof y){var c=null;a=this.xa;b=a.length;this.da===ci&&(c=Kk(this,a,b))&&c.ha(!0);a=a.n;for(c=0;c<b;c++){var d=a[c];!0!==Qi(d)&&(d.Ca.N()||(d instanceof Ug||d instanceof y||d instanceof na||qk(d)!==Vg)&&d.ha(!0))}}}};
function Lk(a){if(!1===Qi(a)&&(rk(a,!0),vk(a,!0),a instanceof y)){a=a.xa.n;for(var b=a.length,c=0;c<b;c++)Lk(a[c])}}function Jk(a){if(!1===Ri(a)){if(null!==a.ja)a.ja.ha();else{var b=a.h;null!==b&&(a.La()&&b.fg.add(a),a instanceof R&&a.wf(),b.te())}vk(a,!0)}}function sk(a){if(0!==(a.ma&2048)===!1&&(Hk(a,!0),Ik(a,!0),a instanceof y)){a=a.xa.n;for(var b=a.length,c=0;c<b;c++)Mk(a[c])}}function Mk(a){Ik(a,!0);if(a instanceof y){a=a.xa.n;for(var b=a.length,c=0;c<b;c++)Mk(a[c])}}
Q.prototype.fa=function(){if(this instanceof C){var a=this.h;null!==a&&!Ri(this)&&!Si(this)&&this.La()&&this.cc.N()&&a.fa(Th(this,this.cc))}else null!==this.S&&this.S.fa()};function qk(a){var b=a.fi,c=a.ja;if(c&&c.da===Nk)return Ok(a,c.wd(a.lc),c.vd(a.column));if(c&&c.da===ci&&c.Dw()===a)return Cc;if(b===gk){if(c){c.da===Tg&&null===c.Eo&&(b=c.xa,Kk(c,b,b.length));if(c.Eo===a)return Cc;a=c.Zz;return a===gk?Vg:a}return Vg}return b}
function Ok(a,b,c){var d=a.fi;if(d!==gk)return d;var f=d=void 0;switch(b.fi){case hk:f=!0;break;case Cc:f=!0}switch(c.fi){case ik:d=!0;break;case Cc:d=!0}a=a.ja.Zz;d=void 0!==d||a!==ik&&a!==Cc?!1:!0;f=void 0!==f||a!==hk&&a!==Cc?!1:!0;return!0===d&&!0===f?Cc:!0===d?ik:!0===f?hk:Vg}t.g(Q,"segmentOrientation",Q.prototype.Ot);
t.defineProperty(Q,{Ot:"segmentOrientation"},function(){return this.gs},function(a){var b=this.gs;b!==a&&(e&&t.Aa(a,T,Q,"segmentOrientation"),this.gs=a,this.ha(),this.j("segmentOrientation",b,a))});t.g(Q,"segmentIndex",Q.prototype.Cf);t.defineProperty(Q,{Cf:"segmentIndex"},function(){return this.es},function(a){e&&t.i(a,"number",Q,"segmentIndex");a=Math.round(a);var b=this.es;b!==a&&(this.es=a,this.ha(),this.j("segmentIndex",b,a))});t.g(Q,"segmentFraction",Q.prototype.Nt);
t.defineProperty(Q,{Nt:"segmentFraction"},function(){return this.ds},function(a){e&&t.i(a,"number",Q,"segmentFraction");isNaN(a)?a=0:0>a?a=0:1<a&&(a=1);var b=this.ds;b!==a&&(this.ds=a,this.ha(),this.j("segmentFraction",b,a))});t.g(Q,"segmentOffset",Q.prototype.YA);t.defineProperty(Q,{YA:"segmentOffset"},function(){return this.fs},function(a){var b=this.fs;b.M(a)||(e&&t.l(a,w,Q,"segmentOffset"),this.fs=a=a.Z(),this.ha(),this.j("segmentOffset",b,a))});t.g(Q,"stretch",Q.prototype.fi);
t.defineProperty(Q,{fi:"stretch"},function(){return this.Oh},function(a){var b=this.Oh;b!==a&&(e&&t.Aa(a,Q,Q,"stretch"),this.Oh=a,this.ha(),this.j("stretch",b,a))});t.g(Q,"name",Q.prototype.name);t.defineProperty(Q,{name:"name"},function(){return this.Zb},function(a){var b=this.Zb;b!==a&&(e&&t.i(a,"string",Q,"name"),this.Zb=a,this.S&&(this.S.Fj=null),this.j("name",b,a))});t.g(Q,"opacity",Q.prototype.opacity);
t.defineProperty(Q,{opacity:"opacity"},function(){return this.Lf},function(a){var b=this.Lf;b!==a&&(t.i(a,"number",Q,"opacity"),(0>a||1<a)&&t.ka(a,"0 <= val <= 1",Q,"opacity"),this.Lf=a,this.j("opacity",b,a),a=this.h,b=this.S,null!==a&&null!==b&&a.fa(Th(b,b.oa)))});t.g(Q,"visible",Q.prototype.visible);
t.defineProperty(Q,{visible:"visible"},function(){return 0!==(this.ma&1)},function(a){var b=0!==(this.ma&1);b!==a&&(e&&t.i(a,"boolean",Q,"visible"),this.ma^=1,this.j("visible",b,a),b=this.ja,null!==b?b.ha():this instanceof C&&(this.h&&this.h.fa(),wk(this),this.df(a)),this.fa(),Pk(this))});t.g(Q,"pickable",Q.prototype.Bf);t.defineProperty(Q,{Bf:"pickable"},function(){return 0!==(this.ma&2)},function(a){var b=0!==(this.ma&2);b!==a&&(e&&t.i(a,"boolean",Q,"pickable"),this.ma^=2,this.j("pickable",b,a))});
t.g(Q,"fromLinkableDuplicates",Q.prototype.DE);t.defineProperty(Q,{DE:"fromLinkableDuplicates"},function(){return 0!==(this.ma&4)},function(a){var b=0!==(this.ma&4);b!==a&&(e&&t.i(a,"boolean",Q,"fromLinkableDuplicates"),this.ma^=4,this.j("fromLinkableDuplicates",b,a))});t.g(Q,"fromLinkableSelfNode",Q.prototype.EE);
t.defineProperty(Q,{EE:"fromLinkableSelfNode"},function(){return 0!==(this.ma&8)},function(a){var b=0!==(this.ma&8);b!==a&&(e&&t.i(a,"boolean",Q,"fromLinkableSelfNode"),this.ma^=8,this.j("fromLinkableSelfNode",b,a))});t.g(Q,"toLinkableDuplicates",Q.prototype.AG);t.defineProperty(Q,{AG:"toLinkableDuplicates"},function(){return 0!==(this.ma&16)},function(a){var b=0!==(this.ma&16);b!==a&&(e&&t.i(a,"boolean",Q,"toLinkableDuplicates"),this.ma^=16,this.j("toLinkableDuplicates",b,a))});
t.g(Q,"toLinkableSelfNode",Q.prototype.BG);t.defineProperty(Q,{BG:"toLinkableSelfNode"},function(){return 0!==(this.ma&32)},function(a){var b=0!==(this.ma&32);b!==a&&(e&&t.i(a,"boolean",Q,"toLinkableSelfNode"),this.ma^=32,this.j("toLinkableSelfNode",b,a))});t.g(Q,"isPanelMain",Q.prototype.fj);t.defineProperty(Q,{fj:"isPanelMain"},function(){return 0!==(this.ma&64)},function(a){var b=0!==(this.ma&64);b!==a&&(e&&t.i(a,"boolean",Q,"isPanelMain"),this.ma^=64,this.ha(),this.j("isPanelMain",b,a))});
t.g(Q,"isActionable",Q.prototype.Ow);t.defineProperty(Q,{Ow:"isActionable"},function(){return 0!==(this.ma&128)},function(a){var b=0!==(this.ma&128);b!==a&&(e&&t.i(a,"boolean",Q,"isActionable"),this.ma^=128,this.j("isActionable",b,a))});t.g(Q,"areaBackground",Q.prototype.$k);
t.defineProperty(Q,{$k:"areaBackground"},function(){return this.vc},function(a){var b=this.vc;b!==a&&(e&&null!==a&&t.To(a,"GraphObject.areaBackground"),a instanceof ea&&a.freeze(),this.vc=a,this.fa(),this.j("areaBackground",b,a))});t.g(Q,"background",Q.prototype.background);t.defineProperty(Q,{background:"background"},function(){return this.Mb},function(a){var b=this.Mb;b!==a&&(e&&null!==a&&t.To(a,"GraphObject.background"),a instanceof ea&&a.freeze(),this.Mb=a,this.fa(),this.j("background",b,a))});
function Fk(a,b){a.ma=b?a.ma|512:a.ma&-513}function Qk(a,b){a.ma=b?a.ma|1024:a.ma&-1025}function Hk(a,b){a.ma=b?a.ma|2048:a.ma&-2049}function Ik(a,b){a.ma=b?a.ma|4096:a.ma&-4097}function Qi(a){return 0!==(a.ma&8192)}function rk(a,b){a.ma=b?a.ma|8192:a.ma&-8193}function Ri(a){return 0!==(a.ma&16384)}function vk(a,b){a.ma=b?a.ma|16384:a.ma&-16385}t.A(Q,{S:"part"},function(){if(this instanceof C)return this;if(this.nm)return this.nm;var a;for(a=this.ja;a;){if(a instanceof C)return this.nm=a;a=a.ja}return null});
Q.prototype.xl=function(a){this.Ii=a};t.A(Q,{ja:"panel"},function(){return this.Ii});t.A(Q,{layer:"layer"},function(){var a=this.S;return null!==a?a.layer:null});t.A(Q,{h:"diagram"},function(){var a=this.S;return null!==a?a.h:null},{configurable:!0});t.g(Q,"position",Q.prototype.position);t.defineProperty(Q,{position:"position"},function(){return this.Ra},function(a){var b=this.Ra;b.M(a)||(e&&t.l(a,w,Q,"position"),a=a.Z(),this.nG(a,b)&&this.j("position",b,a))});
Q.prototype.nG=function(a){this.Ra=a;Jk(this);sk(this);return!0};Q.prototype.oG=function(a,b){this.Ra.p(a,b);Rk(this,!1);sk(this)};t.A(Q,{oa:"actualBounds"},function(){return this.cc});t.g(Q,"scale",Q.prototype.scale);t.defineProperty(Q,{scale:"scale"},function(){return this.xc},function(a){var b=this.xc;b!==a&&(e&&t.q(a,Q,"scale"),0>=a&&t.m("GraphObject.scale must be greater than zero"),this.xc=a,sk(this),this.ha(),this.j("scale",b,a))});t.g(Q,"angle",Q.prototype.angle);
t.defineProperty(Q,{angle:"angle"},function(){return this.wn},function(a){var b=this.wn;b!==a&&(e&&t.q(a,Q,"angle"),a%=360,0>a&&(a+=360),b!==a&&(this.wn=a,this.ha(),sk(this),this.j("angle",b,a)))});t.g(Q,"desiredSize",Q.prototype.Ca);
t.defineProperty(Q,{Ca:"desiredSize"},function(){return this.jf},function(a){var b=this.jf;b.M(a)||(e&&t.l(a,fa,Q,"desiredSize"),this.jf=a=a.Z(),this.ha(),this instanceof V&&this.Ye(),this.j("desiredSize",b,a),a=this.S,null!==a&&0!==(this.ma&1024)&&(mk(this,a,"width"),mk(this,a,"height")))});t.g(Q,"width",Q.prototype.width);
t.defineProperty(Q,{width:"width"},function(){return this.jf.width},function(a){if(this.jf.width!==a){e&&t.i(a,"number",Q,"width");var b=this.jf;this.jf=a=(new fa(a,this.jf.height)).freeze();this.ha();this instanceof V&&this.Ye();this.j("desiredSize",b,a);b=this.S;null!==b&&0!==(this.ma&1024)&&mk(this,b,"width")}});t.g(Q,"height",Q.prototype.height);
t.defineProperty(Q,{height:"height"},function(){return this.jf.height},function(a){if(this.jf.height!==a){e&&t.i(a,"number",Q,"height");var b=this.jf;this.jf=a=(new fa(this.jf.width,a)).freeze();this.ha();this instanceof V&&this.Ye();this.j("desiredSize",b,a);b=this.S;null!==b&&0!==(this.ma&1024)&&mk(this,b,"height")}});t.g(Q,"minSize",Q.prototype.Vf);
t.defineProperty(Q,{Vf:"minSize"},function(){return this.Ej},function(a){var b=this.Ej;b.M(a)||(e&&t.l(a,fa,Q,"minSize"),a=a.copy(),isNaN(a.width)&&(a.width=0),isNaN(a.height)&&(a.height=0),a.freeze(),this.Ej=a,this.ha(),this.j("minSize",b,a))});t.g(Q,"maxSize",Q.prototype.He);
t.defineProperty(Q,{He:"maxSize"},function(){return this.Dj},function(a){var b=this.Dj;b.M(a)||(e&&t.l(a,fa,Q,"maxSize"),a=a.copy(),isNaN(a.width)&&(a.width=Infinity),isNaN(a.height)&&(a.height=Infinity),a.freeze(),this.Dj=a,this.ha(),this.j("maxSize",b,a))});t.A(Q,{Ga:"measuredBounds"},function(){return this.Wc});t.A(Q,{Pa:"naturalBounds"},function(){return this.hd},{configurable:!0});t.g(Q,"margin",Q.prototype.margin);
t.defineProperty(Q,{margin:"margin"},function(){return this.sr},function(a){"number"===typeof a?a=new ab(a):e&&t.l(a,ab,Q,"margin");var b=this.sr;b.M(a)||(this.sr=a=a.Z(),this.ha(),this.j("margin",b,a))});t.A(Q,{transform:null},function(){0!==(this.ma&2048)===!0&&Gk(this);return this.md});t.A(Q,{me:null},function(){0!==(this.ma&4096)===!0&&Gk(this);return this.kk});t.g(Q,"alignment",Q.prototype.alignment);
t.defineProperty(Q,{alignment:"alignment"},function(){return this.ue},function(a){var b=this.ue;b.M(a)||(e?t.l(a,H,Q,"alignment"):a.Ge()&&!a.Pc()&&t.m("alignment must be a real Spot or Spot.Default"),this.ue=a=a.Z(),Jk(this),this.j("alignment",b,a))});t.g(Q,"column",Q.prototype.column);t.defineProperty(Q,{column:"column"},function(){return this.tq},function(a){e&&t.q(a,Q,"column");a=Math.round(a);var b=this.tq;b!==a&&(0>a&&t.ka(a,">= 0",Q,"column"),this.tq=a,this.ha(),this.j("column",b,a))});
t.g(Q,"columnSpan",Q.prototype.ZD);t.defineProperty(Q,{ZD:"columnSpan"},function(){return this.rj},function(a){e&&t.q(a,Q,"columnSpan");a=Math.round(a);var b=this.rj;b!==a&&(1>a&&t.ka(a,">= 1",Q,"columnSpan"),this.rj=a,this.ha(),this.j("columnSpan",b,a))});t.g(Q,"row",Q.prototype.lc);t.defineProperty(Q,{lc:"row"},function(){return this.as},function(a){e&&t.q(a,Q,"row");a=Math.round(a);var b=this.as;b!==a&&(0>a&&t.ka(a,">= 0",Q,"row"),this.as=a,this.ha(),this.j("row",b,a))});t.g(Q,"rowSpan",Q.prototype.rowSpan);
t.defineProperty(Q,{rowSpan:"rowSpan"},function(){return this.Jj},function(a){e&&t.q(a,Q,"rowSpan");a=Math.round(a);var b=this.Jj;b!==a&&(1>a&&t.ka(a,">= 1",Q,"rowSpan"),this.Jj=a,this.ha(),this.j("rowSpan",b,a))});t.g(Q,"alignmentFocus",Q.prototype.Qj);
t.defineProperty(Q,{Qj:"alignmentFocus"},function(){return this.mq},function(a){var b=this.mq;b.M(a)||(e?t.l(a,H,Q,"alignmentFocus"):a.Ge()&&!a.Pc()&&t.m("alignmentFocus must be a real Spot or Spot.Default"),this.mq=a=a.Z(),this.ha(),this.j("alignmentFocus",b,a))});t.g(Q,"portId",Q.prototype.Nd);
t.defineProperty(Q,{Nd:"portId"},function(){return this.Nr},function(a){var b=this.Nr;if(b!==a){e&&null!==a&&t.i(a,"string",Q,"portId");var c=this.S;null===c||c instanceof R||(t.m("portID being set on a Link: "+a),c=null);null!==b&&c&&Sk(c,this);this.Nr=a;if(null!==a&&c){c.Eh=!0;null===c.Rd&&Tk(c);var d=this.Nd;null!==d&&c.Rd.add(d,this)}this.j("portId",b,a)}});function Uk(a){var b={value:null};Vk(a,b);return b.value}
function Vk(a,b){var c=a.ja;return null===c||!Vk(c,b)&&a.visible?(b.value=a,!1):!0}function Pk(a){var b=a.S;b instanceof R&&(a=a.h)&&!a.la.vb&&b.wf()}t.g(Q,"toSpot",Q.prototype.rb);t.defineProperty(Q,{rb:"toSpot"},function(){return null!==this.P?this.P.Th:wb},function(a){null===this.P&&this.Fe();var b=this.P.Th;b.M(a)||(e&&t.l(a,H,Q,"toSpot"),a=a.Z(),this.P.Th=a,this.j("toSpot",b,a),Pk(this))});t.g(Q,"toEndSegmentLength",Q.prototype.jk);
t.defineProperty(Q,{jk:"toEndSegmentLength"},function(){return null!==this.P?this.P.Qh:10},function(a){null===this.P&&this.Fe();var b=this.P.Qh;b!==a&&(e&&t.i(a,"number",Q,"toEndSegmentLength"),0>a&&t.ka(a,">= 0",Q,"toEndSegmentLength"),this.P.Qh=a,this.j("toEndSegmentLength",b,a),Pk(this))});t.g(Q,"toEndSegmentDirection",Q.prototype.Xp);
t.defineProperty(Q,{Xp:"toEndSegmentDirection"},function(){return null!==this.P?this.P.Ph:kk},function(a){null===this.P&&this.Fe();var b=this.P.Ph;b!==a&&(e&&t.Aa(a,R,Q,"toEndSegmentDirection"),this.P.Ph=a,this.j("toEndSegmentDirection",b,a),Pk(this))});t.g(Q,"toShortLength",Q.prototype.Yp);
t.defineProperty(Q,{Yp:"toShortLength"},function(){return null!==this.P?this.P.Sh:0},function(a){null===this.P&&this.Fe();var b=this.P.Sh;b!==a&&(e&&t.i(a,"number",Q,"toShortLength"),this.P.Sh=a,this.j("toShortLength",b,a),Pk(this))});t.g(Q,"toLinkable",Q.prototype.jB);t.defineProperty(Q,{jB:"toLinkable"},function(){return this.ys},function(a){var b=this.ys;b!==a&&(e&&null!==a&&t.i(a,"boolean",Q,"toLinkable"),this.ys=a,this.j("toLinkable",b,a))});t.g(Q,"toMaxLinks",Q.prototype.CG);
t.defineProperty(Q,{CG:"toMaxLinks"},function(){return this.zs},function(a){var b=this.zs;b!==a&&(e&&t.q(a,Q,"toMaxLinks"),0>a&&t.ka(a,">= 0",Q,"toMaxLinks"),this.zs=a,this.j("toMaxLinks",b,a))});t.g(Q,"fromSpot",Q.prototype.ob);t.defineProperty(Q,{ob:"fromSpot"},function(){return null!==this.P?this.P.Dh:wb},function(a){null===this.P&&this.Fe();var b=this.P.Dh;b.M(a)||(e&&t.l(a,H,Q,"fromSpot"),a=a.Z(),this.P.Dh=a,this.j("fromSpot",b,a),Pk(this))});t.g(Q,"fromEndSegmentLength",Q.prototype.dk);
t.defineProperty(Q,{dk:"fromEndSegmentLength"},function(){return null!==this.P?this.P.Ah:10},function(a){null===this.P&&this.Fe();var b=this.P.Ah;b!==a&&(e&&t.i(a,"number",Q,"fromEndSegmentLength"),0>a&&t.ka(a,">= 0",Q,"fromEndSegmentLength"),this.P.Ah=a,this.j("fromEndSegmentLength",b,a),Pk(this))});t.g(Q,"fromEndSegmentDirection",Q.prototype.hp);
t.defineProperty(Q,{hp:"fromEndSegmentDirection"},function(){return null!==this.P?this.P.zh:kk},function(a){null===this.P&&this.Fe();var b=this.P.zh;b!==a&&(e&&t.Aa(a,R,Q,"fromEndSegmentDirection"),this.P.zh=a,this.j("fromEndSegmentDirection",b,a),Pk(this))});t.g(Q,"fromShortLength",Q.prototype.ip);
t.defineProperty(Q,{ip:"fromShortLength"},function(){return null!==this.P?this.P.Ch:0},function(a){null===this.P&&this.Fe();var b=this.P.Ch;b!==a&&(e&&t.i(a,"number",Q,"fromShortLength"),this.P.Ch=a,this.j("fromShortLength",b,a),Pk(this))});t.g(Q,"fromLinkable",Q.prototype.hA);t.defineProperty(Q,{hA:"fromLinkable"},function(){return this.Nq},function(a){var b=this.Nq;b!==a&&(e&&null!==a&&t.i(a,"boolean",Q,"fromLinkable"),this.Nq=a,this.j("fromLinkable",b,a))});t.g(Q,"fromMaxLinks",Q.prototype.FE);
t.defineProperty(Q,{FE:"fromMaxLinks"},function(){return this.Oq},function(a){var b=this.Oq;b!==a&&(e&&t.q(a,Q,"fromMaxLinks"),0>a&&t.ka(a,">= 0",Q,"fromMaxLinks"),this.Oq=a,this.j("fromMaxLinks",b,a))});t.g(Q,"cursor",Q.prototype.cursor);t.defineProperty(Q,{cursor:"cursor"},function(){return this.Aq},function(a){var b=this.Aq;b!==a&&(t.i(a,"string",Q,"cursor"),this.Aq=a,this.j("cursor",b,a))});t.g(Q,"click",Q.prototype.click);
t.defineProperty(Q,{click:"click"},function(){return null!==this.Q?this.Q.ni:null},function(a){null===this.Q&&jk(this);var b=this.Q.ni;b!==a&&(null!==a&&t.i(a,"function",Q,"click"),this.Q.ni=a,this.j("click",b,a))});t.g(Q,"doubleClick",Q.prototype.Om);t.defineProperty(Q,{Om:"doubleClick"},function(){return null!==this.Q?this.Q.ti:null},function(a){null===this.Q&&jk(this);var b=this.Q.ti;b!==a&&(null!==a&&t.i(a,"function",Q,"doubleClick"),this.Q.ti=a,this.j("doubleClick",b,a))});
t.g(Q,"contextClick",Q.prototype.Ws);t.defineProperty(Q,{Ws:"contextClick"},function(){return null!==this.Q?this.Q.pi:null},function(a){null===this.Q&&jk(this);var b=this.Q.pi;b!==a&&(null!==a&&t.i(a,"function",Q,"contextClick"),this.Q.pi=a,this.j("contextClick",b,a))});t.g(Q,"mouseEnter",Q.prototype.DA);
t.defineProperty(Q,{DA:"mouseEnter"},function(){return null!==this.Q?this.Q.Dr:null},function(a){null===this.Q&&jk(this);var b=this.Q.Dr;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseEnter"),this.Q.Dr=a,this.j("mouseEnter",b,a))});t.g(Q,"mouseLeave",Q.prototype.EA);t.defineProperty(Q,{EA:"mouseLeave"},function(){return null!==this.Q?this.Q.Er:null},function(a){null===this.Q&&jk(this);var b=this.Q.Er;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseLeave"),this.Q.Er=a,this.j("mouseLeave",b,a))});
t.g(Q,"mouseOver",Q.prototype.zt);t.defineProperty(Q,{zt:"mouseOver"},function(){return null!==this.Q?this.Q.Ei:null},function(a){null===this.Q&&jk(this);var b=this.Q.Ei;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseOver"),this.Q.Ei=a,this.j("mouseOver",b,a))});t.g(Q,"mouseHover",Q.prototype.yt);
t.defineProperty(Q,{yt:"mouseHover"},function(){return null!==this.Q?this.Q.Di:null},function(a){null===this.Q&&jk(this);var b=this.Q.Di;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseHover"),this.Q.Di=a,this.j("mouseHover",b,a))});t.g(Q,"mouseHold",Q.prototype.xt);t.defineProperty(Q,{xt:"mouseHold"},function(){return null!==this.Q?this.Q.Ci:null},function(a){null===this.Q&&jk(this);var b=this.Q.Ci;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseHold"),this.Q.Ci=a,this.j("mouseHold",b,a))});
t.g(Q,"mouseDragEnter",Q.prototype.AF);t.defineProperty(Q,{AF:"mouseDragEnter"},function(){return null!==this.Q?this.Q.Br:null},function(a){null===this.Q&&jk(this);var b=this.Q.Br;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseDragEnter"),this.Q.Br=a,this.j("mouseDragEnter",b,a))});t.g(Q,"mouseDragLeave",Q.prototype.CA);
t.defineProperty(Q,{CA:"mouseDragLeave"},function(){return null!==this.Q?this.Q.Cr:null},function(a){null===this.Q&&jk(this);var b=this.Q.Cr;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseDragLeave"),this.Q.Cr=a,this.j("mouseDragLeave",b,a))});t.g(Q,"mouseDrop",Q.prototype.wt);t.defineProperty(Q,{wt:"mouseDrop"},function(){return null!==this.Q?this.Q.Bi:null},function(a){null===this.Q&&jk(this);var b=this.Q.Bi;b!==a&&(null!==a&&t.i(a,"function",Q,"mouseDrop"),this.Q.Bi=a,this.j("mouseDrop",b,a))});
t.g(Q,"actionDown",Q.prototype.Fz);t.defineProperty(Q,{Fz:"actionDown"},function(){return null!==this.Q?this.Q.iq:null},function(a){null===this.Q&&jk(this);var b=this.Q.iq;b!==a&&(null!==a&&t.i(a,"function",Q,"actionDown"),this.Q.iq=a,this.j("actionDown",b,a))});t.g(Q,"actionUp",Q.prototype.Hz);
t.defineProperty(Q,{Hz:"actionUp"},function(){return null!==this.Q?this.Q.kq:null},function(a){null===this.Q&&jk(this);var b=this.Q.kq;b!==a&&(null!==a&&t.i(a,"function",Q,"actionUp"),this.Q.kq=a,this.j("actionUp",b,a))});t.g(Q,"actionMove",Q.prototype.Gz);t.defineProperty(Q,{Gz:"actionMove"},function(){return null!==this.Q?this.Q.jq:null},function(a){null===this.Q&&jk(this);var b=this.Q.jq;b!==a&&(null!==a&&t.i(a,"function",Q,"actionMove"),this.Q.jq=a,this.j("actionMove",b,a))});
t.g(Q,"actionCancel",Q.prototype.Ez);t.defineProperty(Q,{Ez:"actionCancel"},function(){return null!==this.Q?this.Q.hq:null},function(a){null===this.Q&&jk(this);var b=this.Q.hq;b!==a&&(null!==a&&t.i(a,"function",Q,"actionCancel"),this.Q.hq=a,this.j("actionCancel",b,a))});t.g(Q,"toolTip",Q.prototype.Yt);
t.defineProperty(Q,{Yt:"toolTip"},function(){return null!==this.Q?this.Q.Oi:null},function(a){null===this.Q&&jk(this);var b=this.Q.Oi;b!==a&&(null!==a&&t.l(a,Je,Q,"toolTip"),this.Q.Oi=a,this.j("toolTip",b,a))});t.g(Q,"contextMenu",Q.prototype.contextMenu);t.defineProperty(Q,{contextMenu:"contextMenu"},function(){return null!==this.Q?this.Q.qi:null},function(a){null===this.Q&&jk(this);var b=this.Q.qi;b!==a&&(null!==a&&t.l(a,Je,Q,"contextMenu"),this.Q.qi=a,this.j("contextMenu",b,a))});
Q.prototype.bind=Q.prototype.bind=function(a){a.pg=this;var b=this.ep();null!==b&&Wk(b)&&t.m("Cannot add a Binding to a template that has already been copied: "+a);null===this.Kc&&(this.Kc=new B(De));this.Kc.add(a)};Q.prototype.findTemplateBinder=Q.prototype.ep=function(){for(var a=this instanceof y?this:this.ja;null!==a;){if(null!==a.Jl&&a instanceof y)return a;a=a.ja}return null};Q.fromSvg=Q.fromSVG=function(a){return Xk(a)};Q.prototype.setProperties=function(a){t.tx(this,a)};var Yk;
Q.make=Yk=function(a,b){var c=arguments,d=null,f=null;if("function"===typeof a)f=a;else if("string"===typeof a){var g=Zk.za(a);"function"===typeof g?(c=Array.prototype.slice.call(arguments),d=g(c)):f=ba[a]}null===d&&(void 0===f&&(d=window.$,void 0!==d&&void 0!==d.noop&&t.m("GraphObject.make failed to complete. Is it conflicting with another $ var? (such as jQuery)"),t.m("GraphObject.make failed to complete, it may be conflicting with another var.")),null!==f&&f.constructor||t.m("GraphObject.make requires a class function or class name, not: "+
a),d=new f);g=1;d instanceof z&&1<c.length&&(f=c[1],"string"===typeof f||f instanceof HTMLDivElement)&&(ai(d,f),g++);for(;g<c.length;g++)f=c[g],void 0===f?t.m("Undefined value at argument "+g+" for object being constructed by GraphObject.make: "+d):$k(d,f);return d};
function $k(a,b){if("string"===typeof b){var c=a;if(c instanceof na)c.text=b;else if(c instanceof V)c.Ab=b;else if(c instanceof al)c.source=b;else if(c instanceof y){var d=t.Pm(y,b);null!==d?c.type=d:t.m("Unknown Panel type as an argument to GraphObject.make: "+b)}else c instanceof ea?(d=t.Pm(ea,b),null!==d?c.type=d:t.m("Unknown Brush type as an argument to GraphObject.make: "+b)):c instanceof zc?(d=t.Pm(zc,b),null!==d?c.type=d:t.m("Unknown Geometry type as an argument to GraphObject.make: "+b)):
c instanceof id?(d=t.Pm(id,b),null!==d?c.type=d:t.m("Unknown PathSegment type as an argument to GraphObject.make: "+b)):t.m("Unable to use a string as an argument to GraphObject.make: "+b)}else if(b instanceof Q)a instanceof y||t.m("A GraphObject can only be added to a Panel, not to: "+a),a.add(b);else if(b instanceof Ae)a instanceof y||t.m("A RowColumnDefinition can only be added to a Panel, not to: "+a),jj(b.xf?a.wd(b.index):a.vd(b.index),b);else if(b instanceof ca)c=a,c instanceof T&&b.Re===T?
2===(b.value&2)?c.Lt=b:b===Pg||b===yg||b===xg?c.De=b:b===bl||b===cl||b===dl?c.Lo=b:b!==el&&b!==zg&&t.m("Unknown Link enum value for a Link property: "+b):c instanceof y&&b.Re===y?c.type=b:c instanceof na&&b.Re===na?c.pB=b:c instanceof Q&&b.Re===T?0===b.name.indexOf("Orient")?c.Ot=b:t.m("Unknown Link enum value for GraphObject.segmentOrientation property: "+b):c instanceof Q&&b.Re===Q?c.fi=b:c instanceof z&&b.Re===z?c.Fm=b:c instanceof ea&&b.Re===ea?c.type=b:c instanceof zc&&b.Re===zc?c.type=b:c instanceof
id&&b.Re===id?c.type=b:c instanceof De&&b.Re===De?c.mode=b:c instanceof td&&b.Re===td?c.td=b:c instanceof W&&b.Re===W?0===b.name.indexOf("Alignment")?c.alignment=b:0===b.name.indexOf("Arrangement")?c.Qe=b:0===b.name.indexOf("Compaction")?c.compaction=b:0===b.name.indexOf("Path")?c.path=b:0===b.name.indexOf("Sorting")?c.sorting=b:0===b.name.indexOf("Style")?c.MG=b:t.m("Unknown enum value: "+b):c instanceof Y&&b.Re===Y?0===b.name.indexOf("Aggressive")?c.JD=b:0===b.name.indexOf("Cycle")?c.hE=b:0===b.name.indexOf("Init")?
c.ZE=b:0===b.name.indexOf("Layer")?c.lF=b:t.m("Unknown enum value: "+b):c instanceof ck&&b.Re===ck?b===fl||b===gl||b===hl||b===il?c.sorting=b:b===jl||b===kl?c.Qe=b:b===ll||b===ml?c.alignment=b:t.m("Unknown enum value: "+b):c instanceof nl&&b.Re===nl?b===ol||b===pl||b===ql||b===rl||b===sl?c.sorting=b:b===tl||b===ul||b===vl||b===wl?c.direction=b:b===xl||b===Dl||b===El||b===Fl?c.Qe=b:b===Gl||b===Hl?c.Bt=b:t.m("Unknown enum value: "+b):t.m("No property to set for this enum value: "+b);else if(b instanceof
De)if(a instanceof Q||a instanceof Ae){var d=b.Wt,f=d.indexOf(".");0<f&&a instanceof y&&(c=d.substr(f+1),d=a.oe(d.substring(0,f)),null!==d&&(a=d,b.Wt=c));a.bind(b)}else t.m("A Binding can only be applied to a GraphObject or RowColumnDefinition, not to: "+a);else if(b instanceof Bc)a instanceof zc?a.Bb.add(b):t.m("A PathFigure can only be added to a Geometry, not to: "+a);else if(b instanceof id)a instanceof Bc?a.Ea.add(b):t.m("A PathSegment can only be added to a PathFigure, not to: "+a);else if(Array.isArray(b))for(c=
0;c<b.length;c++)$k(a,b[c]);else if("object"===typeof b&&null!==b)if(c=a,c instanceof ea){d={};for(f in b){var g=parseFloat(f);isNaN(g)?d[f]=b[f]:c.addColorStop(g,b[f])}t.tx(c,d)}else c instanceof Ae?(void 0!==b.row?(d=b.row,(void 0===d||null===d||Infinity===d||isNaN(d)||0>d)&&t.m("Must specify non-negative integer row for RowColumnDefinition "+b),c.Xl=!0,c.Vc=d):void 0!==b.column?(d=b.column,(void 0===d||null===d||Infinity===d||isNaN(d)||0>d)&&t.m("Must specify non-negative integer column for RowColumnDefinition "+
b),c.Xl=!1,c.Vc=d):t.m("Must specify row or column value in a RowColumnDefinition "+b),d=t.gI(b,"row","column"),t.tx(c,d)):t.tx(c,b);else t.m('Unknown initializer "'+b+'" for object being constructed by GraphObject.make: '+a)}var Zk;Q.Builders=Zk=new ia("string","function");
Zk.add("Button",function(){var a=new ea(Yd);a.addColorStop(0,"white");a.addColorStop(1,"lightgray");var b=new ea(Yd);b.addColorStop(0,"white");b.addColorStop(1,"dodgerblue");var c=Yk(y,ci,{Ow:!0},Yk(V,{name:"ButtonBorder",Ab:"RoundedRectangle",fill:a,stroke:"gray"}));c.DA=function(a,c){var g=c.wa(0),h=c._buttonFillOver;void 0===h&&(h=b);c._buttonFillNormal=g.fill;g.fill=h;h=c._buttonStrokeOver;void 0===h&&(h="blue");c._buttonStrokeNormal=g.stroke;g.stroke=h};c.EA=function(b,c){var g=c.wa(0),h=c._buttonFillNormal;
void 0===h&&(h=a);g.fill=h;h=c._buttonStrokeNormal;void 0===h&&(h="gray");g.stroke=h};return c});
Zk.add("TreeExpanderButton",function(){var a=Yk("Button",Yk(V,{name:"ButtonIcon",Ab:"MinusLine",Ca:F.dq},(new De("figure","isTreeExpanded",function(a,c){var d=null,f=c.ja;f&&(d=a?f._treeExpandedFigure:f._treeCollapsedFigure);d||(d=a?"MinusLine":"PlusLine");return d})).JA("")),{visible:!1},(new De("visible","isTreeLeaf",function(a){return!a})).JA(""));a.click=function(a,c){var d=c.S;d instanceof Je&&(d=d.Wh);if(d instanceof R){var f=d.h;if(null!==f){f=f.Se;if(d.Qc){if(!f.canCollapseTree(d))return}else if(!f.canExpandTree(d))return;
a.Ee=!0;d.Qc?f.collapseTree(d):f.expandTree(d)}}};return a});
Zk.add("SubGraphExpanderButton",function(){var a=Yk("Button",Yk(V,{name:"ButtonIcon",Ab:"MinusLine",Ca:F.dq},(new De("figure","isSubGraphExpanded",function(a,c){var d=null,f=c.ja;f&&(d=a?f._subGraphExpandedFigure:f._subGraphCollapsedFigure);d||(d=a?"MinusLine":"PlusLine");return d})).JA("")));a.click=function(a,c){var d=c.S;d instanceof Je&&(d=d.Wh);if(d instanceof S){var f=d.h;if(null!==f){f=f.Se;if(d.Yd){if(!f.canCollapseSubGraph(d))return}else if(!f.canExpandSubGraph(d))return;a.Ee=!0;d.Yd?f.collapseSubGraph(d):
f.expandSubGraph(d)}}};return a});Zk.add("ContextMenuButton",function(){var a=Yk("Button");a.fi=ik;var b=a.oe("ButtonBorder");b instanceof V&&(b.Ab="Rectangle",b.G=new H(0,0,2,2),b.H=new H(1,1,-2,-2));return a});Q.defineBuilder=function(a,b){t.i(a,"string",Q,"defineBuilder:name");t.i(b,"function",Q,"defineBuilder:func");var c=a.toLowerCase();""!==a&&"none"!==c&&a!==c||t.m("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: "+a);Q.Builders.add(a,b)};
function y(a){Q.call(this);void 0===a?0===arguments.length?this.da=$g:t.m("invalid argument to Panel constructor: undefined"):(t.Aa(a,y,y,"type"),this.da=a);this.xa=new B(Q);this.Ne=F.fq;this.Vg=!1;this.da===yi&&(this.Vg=!0);this.Dq=xb;this.Hc=gk;this.da===Nk&&Il(this);this.Io=Xg;this.Sq=(new fa(10,10)).freeze();this.Tq=F.mj;this.Jl=this.Ol=null;this.hr=NaN;this.hg=this.yi=null;this.eo="category";this.gg=null;this.Qi=new x(NaN,NaN,NaN,NaN);this.Eo=null;this.Eh=!1;this.bs=null}t.ga("Panel",y);t.Xh(y);
t.Ka(y,Q);function Il(a){a.sj=F.fq;a.wh=1;a.si=null;a.Ql=null;a.vh=1;a.uh=null;a.Pl=null;a.kd=[];a.dd=[];a.sm=Jl;a.Ll=Jl;a.Pi=0;a.zi=0}
y.prototype.cloneProtected=function(a){Q.prototype.cloneProtected.call(this,a);a.da=this.da;a.Ne=this.Ne.Z();a.Vg=this.Vg;a.Dq=this.Dq.Z();a.Hc=this.Hc;if(a.da===Nk){a.sj=this.sj.Z();a.wh=this.wh;a.si=this.si;a.Ql=this.Ql;a.vh=this.vh;a.uh=this.uh;a.Pl=this.Pl;var b=[];if(0<this.kd.length)for(var c=this.kd,d=c.length,f=0;f<d;f++)if(void 0!==c[f]){var g=c[f].copy();g.xl(a);b[f]=g}a.kd=b;b=[];if(0<this.dd.length)for(c=this.dd,d=c.length,f=0;f<d;f++)void 0!==c[f]&&(g=c[f].copy(),g.xl(a),b[f]=g);a.dd=
b;a.sm=this.sm;a.Ll=this.Ll;a.Pi=this.Pi;a.zi=this.zi}a.Io=this.Io;a.Sq.assign(this.Sq);a.Tq=this.Tq.Z();a.Ol=this.Ol;a.Jl=this.Jl;a.hr=this.hr;a.yi=this.yi;a.hg=this.hg;a.eo=this.eo;a.Qi.assign(this.Qi);a.Eh=this.Eh;this.bs&&(a.bs=this.bs);this._buttonFillNormal&&(a._buttonFillNormal=this._buttonFillNormal);this._buttonFillOver&&(a._buttonFillOver=this._buttonFillOver);this._buttonStrokeNormal&&(a._buttonStrokeNormal=this._buttonStrokeNormal);this._buttonStrokeOver&&(a._buttonStrokeOver=this._buttonStrokeOver);
this._treeExpandedFigure&&(a._treeExpandedFigure=this._treeExpandedFigure);this._treeCollapsedFigure&&(a._treeCollapsedFigure=this._treeCollapsedFigure);this._subGraphExpandedFigure&&(a._subGraphExpandedFigure=this._subGraphExpandedFigure);this._subGraphCollapsedFigure&&(a._subGraphCollapsedFigure=this._subGraphCollapsedFigure)};y.prototype.Yh=function(a){Q.prototype.Yh.call(this,a);a.xa=this.xa;for(var b=a.xa.k;b.next();)b.value.Ii=a};
y.prototype.copy=function(){var a=Q.prototype.copy.call(this);if(a instanceof y){var b=this.xa;if(0<b.length)for(var c=b.length,d=0;d<c;d++){var f=b.n[d].copy(),g=a;f.xl(g);f.nm=null;var h=g.xa,k=h.count;h.Jd(k,f);h=g.S;if(null!==h){h.Fj=null;null!==f.Nd&&h instanceof R&&(h.Eh=!0);var l=g.h;null!==l&&l.la.vb||h.Ic(wd,"elements",g,null,f,null,k)}}return a}return null};y.prototype.toString=function(){return"Panel("+this.type+")#"+t.od(this)};var $g;y.Position=$g=t.w(y,"Position",0);
y.Horizontal=t.w(y,"Horizontal",1);var bi;y.Vertical=bi=t.w(y,"Vertical",2);var Tg;y.Spot=Tg=t.w(y,"Spot",3);var ci;y.Auto=ci=t.w(y,"Auto",4);var Nk;y.Table=Nk=t.w(y,"Table",5);y.Viewbox=t.w(y,"Viewbox",6);var zk;y.TableRow=zk=t.w(y,"TableRow",7);var Ak;y.TableColumn=Ak=t.w(y,"TableColumn",8);var wg;y.Link=wg=t.w(y,"Link",9);var yi;y.Grid=yi=t.w(y,"Grid",10);t.g(y,"type",y.prototype.type);
t.defineProperty(y,{type:"type"},function(){return this.da},function(a){var b=this.da;b!==a&&(e&&t.Aa(a,y,y,"type"),b!==zk&&b!==Ak||t.m("Cannot change Panel.type when it is already a TableRow or a TableColumn: "+a),this.da=a,this.da===yi?this.Vg=!0:this.da===Nk&&Il(this),this.ha(),this.j("type",b,a))});t.A(y,{elements:"elements"},function(){return this.xa.k});t.A(y,{Pa:"naturalBounds"},function(){return this.hd});t.g(y,"padding",y.prototype.padding);
t.defineProperty(y,{padding:"padding"},function(){return this.Ne},function(a){"number"===typeof a?(0>a&&t.ka(a,">= 0",y,"padding"),a=new ab(a)):(t.l(a,ab,y,"padding"),0>a.left&&t.ka(a.left,">= 0",y,"padding:val.left"),0>a.right&&t.ka(a.right,">= 0",y,"padding:val.right"),0>a.top&&t.ka(a.top,">= 0",y,"padding:val.top"),0>a.bottom&&t.ka(a.bottom,">= 0",y,"padding:val.bottom"));var b=this.Ne;b.M(a)||(this.Ne=a=a.Z(),this.ha(),this.j("padding",b,a))});t.g(y,"defaultAlignment",y.prototype.Xj);
t.defineProperty(y,{Xj:"defaultAlignment"},function(){return this.Dq},function(a){var b=this.Dq;b.M(a)||(e&&t.l(a,H,y,"defaultAlignment"),this.Dq=a=a.Z(),this.ha(),this.j("defaultAlignment",b,a))});t.g(y,"defaultStretch",y.prototype.Zz);t.defineProperty(y,{Zz:"defaultStretch"},function(){return this.Hc},function(a){var b=this.Hc;b!==a&&(t.Aa(a,Q,y,"defaultStretch"),this.Hc=a,this.ha(),this.j("defaultStretch",b,a))});t.g(y,"defaultSeparatorPadding",y.prototype.uI);
t.defineProperty(y,{uI:"defaultSeparatorPadding"},function(){return void 0===this.sj?F.fq:this.sj},function(a){if(void 0!==this.sj){"number"===typeof a?a=new ab(a):e&&t.l(a,ab,y,"defaultSeparatorPadding");var b=this.sj;b.M(a)||(this.sj=a=a.Z(),this.j("defaultSeparatorPadding",b,a))}});t.g(y,"defaultRowSeparatorStroke",y.prototype.sI);
t.defineProperty(y,{sI:"defaultRowSeparatorStroke"},function(){return void 0===this.si?null:this.si},function(a){var b=this.si;b!==a&&(null===a||"string"===typeof a||a instanceof ea)&&(a instanceof ea&&a.freeze(),this.si=a,this.j("defaultRowSeparatorStroke",b,a))});t.g(y,"defaultRowSeparatorStrokeWidth",y.prototype.tI);
t.defineProperty(y,{tI:"defaultRowSeparatorStrokeWidth"},function(){return void 0===this.wh?1:this.wh},function(a){if(void 0!==this.wh){var b=this.wh;b!==a&&(this.wh=a,this.j("defaultRowSeparatorStrokeWidth",b,a))}});t.g(y,"defaultRowSeparatorDashArray",y.prototype.rI);
t.defineProperty(y,{rI:"defaultRowSeparatorDashArray"},function(){return void 0===this.Ql?null:this.Ql},function(a){if(void 0!==this.Ql){var b=this.Ql;if(b!==a){null===a||Array.isArray(a)||t.bc(a,"Array",y,"defaultRowSeparatorDashArray:val");if(null!==a){for(var c=a.length,d=0,f=0;f<c;f++){var g=a[f];"number"===typeof g&&0<=g&&isFinite(g)||t.m("defaultRowSeparatorDashArray:val "+g+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.Ql=a;this.fa();this.j("defaultRowSeparatorDashArray",
b,a)}}});t.g(y,"defaultColumnSeparatorStroke",y.prototype.mI);t.defineProperty(y,{mI:"defaultColumnSeparatorStroke"},function(){return void 0===this.uh?null:this.uh},function(a){if(void 0!==this.uh){var b=this.uh;b!==a&&(null===a||"string"===typeof a||a instanceof ea)&&(a instanceof ea&&a.freeze(),this.uh=a,this.j("defaultColumnSeparatorStroke",b,a))}});t.g(y,"defaultColumnSeparatorStrokeWidth",y.prototype.nI);
t.defineProperty(y,{nI:"defaultColumnSeparatorStrokeWidth"},function(){return void 0===this.vh?1:this.vh},function(a){if(void 0!==this.vh){var b=this.vh;b!==a&&(this.vh=a,this.j("defaultColumnSeparatorStrokeWidth",b,a))}});t.g(y,"defaultColumnSeparatorDashArray",y.prototype.lI);
t.defineProperty(y,{lI:"defaultColumnSeparatorDashArray"},function(){return void 0===this.Pl?null:this.Pl},function(a){if(void 0!==this.Pl){var b=this.Pl;if(b!==a){null===a||Array.isArray(a)||t.bc(a,"Array",y,"defaultColumnSeparatorDashArray:val");if(null!==a){for(var c=a.length,d=0,f=0;f<c;f++){var g=a[f];"number"===typeof g&&0<=g&&isFinite(g)||t.m("defaultColumnSeparatorDashArray:val "+g+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.Pl=a;this.fa();this.j("defaultColumnSeparatorDashArray",
b,a)}}});t.g(y,"viewboxStretch",y.prototype.cK);t.defineProperty(y,{cK:"viewboxStretch"},function(){return this.Io},function(a){var b=this.Io;b!==a&&(t.Aa(a,Q,y,"viewboxStretch"),this.Io=a,this.j("viewboxStretch",b,a))});t.g(y,"gridCellSize",y.prototype.ht);
t.defineProperty(y,{ht:"gridCellSize"},function(){return this.Sq},function(a){var b=this.Sq;b.M(a)||(t.l(a,fa,y,"gridCellSize"),a.N()&&0!==a.width&&0!==a.height||t.m("Invalid Panel.gridCellSize: "+a),this.Sq=a.Z(),null!==this.h&&this===this.h.lp&&Li(this.h),this.fa(),this.j("gridCellSize",b,a))});t.g(y,"gridOrigin",y.prototype.kA);
t.defineProperty(y,{kA:"gridOrigin"},function(){return this.Tq},function(a){var b=this.Tq;b.M(a)||(t.l(a,w,y,"gridOrigin"),a.N()||t.m("Invalid Panel.gridOrigin: "+a),this.Tq=a.Z(),this.h&&Li(this.h),this.fa(),this.j("gridOrigin",b,a))});
y.prototype.fl=function(a,b){if(this.da===yi){var c=this.ek()*b.scale;0>=c&&(c=1);var d=this.ht,f=d.width,d=d.height,g=this.Pa,h=g.width,g=g.height,k=Math.ceil(h/f),l=Math.ceil(g/d),m=this.kA;a.save();a.beginPath();a.rect(0,0,h,g);a.clip();for(var n=[],p=this.xa.n,q=this.xa.length,r=0;r<q;r++){var s=[];n.push(s);var u=p[r];if(u.visible)for(var u=kj(u.Ab),v=r+1;v<q;v++){var D=p[v];D.visible&&kj(D.Ab)===u&&(D=D.interval,2<=D&&s.push(D))}}p=this.xa.length;for(q=0;q<p;q++){var A=this.xa.n[q];if(A.visible){var r=
n[q],s=A.interval,u=!1,G=A.zx;if(null!==G){var K=u=!0;void 0!==a.setLineDash?(a.setLineDash(G),a.lineDashOffset=A.ld):void 0!==a.webkitLineDash?(a.webkitLineDash=G,a.webkitLineDashOffset=A.ld):void 0!==a.mozDash?(a.mozDash=G,a.mozDashOffset=A.ld):K=!1}if("LineV"===A.Ab){a.lineWidth=A.fb;Dk(this,a,A.stroke,!1);a.beginPath();for(D=v=Math.floor(-m.x/f);D<=v+k;D++){var N=D*f+m.x;if(0<=N&&N<h&&Kl(D,s,r)&&(u&&!K?lk(a,N,0,N,g,G,A.ld):(a.moveTo(N,0),a.lineTo(N,g)),2>f*s*c))break}a.stroke()}else if("LineH"===
A.Ab){a.lineWidth=A.fb;Dk(this,a,A.stroke,!1);a.beginPath();for(D=v=Math.floor(-m.y/d);D<=v+l&&!(N=D*d+m.y,0<=N&&N<=g&&Kl(D,s,r)&&(u&&!K?lk(a,0,N,h,N,G,A.ld):(a.moveTo(0,N),a.lineTo(h,N)),2>d*s*c));D++);a.stroke()}else if("BarV"===A.Ab)for(Dk(this,a,A.fill,!0),A=A.width,isNaN(A)&&(A=f),D=v=Math.floor(-m.x/f);D<=v+k&&!(N=D*f+m.x,0<=N&&N<h&&Kl(D,s,r)&&(a.fillRect(N,0,A,g),2>f*s*c));D++);else if("BarH"===A.Ab)for(Dk(this,a,A.fill,!0),A=A.height,isNaN(A)&&(A=d),D=v=Math.floor(-m.y/d);D<=v+l&&!(N=D*d+
m.y,0<=N&&N<=g&&Kl(D,s,r)&&(a.fillRect(0,N,h,A),2>d*s*c));D++);u&&(void 0!==a.setLineDash?(a.setLineDash(t.ji),a.lineDashOffset=0):void 0!==a.webkitLineDash?(a.webkitLineDash=t.ji,a.webkitLineDashOffset=0):void 0!==a.mozDash&&(a.mozDash=null,a.mozDashOffset=0))}}a.restore();wi(b,a,!1)}else{this.da===Nk&&(a.lineCap="butt",Ll(this,a,!0,this.kd,!0),Ll(this,a,!1,this.dd,!0),Ml(this,a,!0,this.kd),Ml(this,a,!1,this.dd),Ll(this,a,!0,this.kd,!1),Ll(this,a,!1,this.dd,!1));c=this.xa.length;for(f=0;f<c;f++)this.xa.n[f].Ue(a,
b);e&&e.tK&&this instanceof T&&e.sK(a,b,this)}};
function Ml(a,b,c,d){for(var f=d.length,g,h=a.oa,k=c?a.wd(0):a.vd(0),l=0;l<f;l++)if(g=d[l],void 0!==g&&g!==k&&0!==g.fc){var m=g.kn;isNaN(m)&&(m=c?a.wh:a.vh);var n=g.jn;null===n&&(n=c?a.si:a.uh);if(0!==m&&null!==n){Dk(a,b,n,!1);var n=!1,p=g.kG;if(null!==p){var q=n=!0;void 0!==b.setLineDash?(b.setLineDash(p),b.lineDashOffset=a.ld):void 0!==b.webkitLineDash?(b.webkitLineDash=p,b.webkitLineDashOffset=a.ld):void 0!==b.mozDash?(b.mozDash=p,b.mozDashOffset=a.ld):q=!1}b.beginPath();var r=g.position+m;c?r>
h.height&&(m-=r-h.height):r>h.width&&(m-=r-h.width);g=g.position+m/2;b.lineWidth=m;r=a.padding;c?(g+=r.top,m=r.left,r=h.width-r.right,n&&!q?lk(b,m,g,r,g,p,0):(b.moveTo(m,g),b.lineTo(r,g))):(g+=r.left,m=r.top,r=h.height-r.bottom,n&&!q?lk(b,g,m,g,r,p,0):(b.moveTo(g,m),b.lineTo(g,r)));b.stroke();n&&(void 0!==b.setLineDash?(b.setLineDash(t.ji),b.lineDashOffset=0):void 0!==b.webkitLineDash?(b.webkitLineDash=t.ji,b.webkitLineDashOffset=0):void 0!==b.mozDash&&(b.mozDash=null,b.mozDashOffset=0))}}}
function Ll(a,b,c,d,f){for(var g=d.length,h,k=a.oa,l=0;l<g;l++)if(h=d[l],void 0!==h&&null!==h.background&&h.tw!==f&&0!==h.fc){var m=c?k.height:k.width;if(!(h.position>m)){var n=Nl(h),p=h.kn;isNaN(p)&&(p=c?a.wh:a.vh);var q=h.jn;null===q&&(q=c?a.si:a.uh);null===q&&(p=0);n-=p;p=h.position+p;n+=h.fc;p+n>m&&(n=m-p);0>=n||(m=a.padding,Dk(a,b,h.background,!0),c?b.fillRect(m.left,p+m.top,k.width-(m.left+m.right),n):b.fillRect(p+m.left,m.top,n,k.height-(m.top+m.bottom)))}}}
function Kl(a,b,c){if(0!==a%b)return!1;b=c.length;for(var d=0;d<b;d++)if(0===a%c[d])return!1;return!0}function kj(a){return"LineV"===a||"BarV"===a}
y.prototype.kp=function(a,b,c,d,f){var g=this.Hf(),h=this.transform,k=1/(h.m11*h.m22-h.m12*h.m21),l=h.m22*k,m=-h.m12*k,n=-h.m21*k,p=h.m11*k,q=k*(h.m21*h.dy-h.m22*h.dx),r=k*(h.m12*h.dx-h.m11*h.dy);if(null!==this.$k)return h=this.oa,F.ll(h.left,h.top,h.right,h.bottom,a,b,c,d,f);if(null!==this.background)g=a*l+b*n+q,k=a*m+b*p+r,a=c*l+d*n+q,c=c*m+d*p+r,f.p(0,0),d=this.Pa,c=F.ll(0,0,d.width,d.height,g,k,a,c,f),f.transform(h);else{g||(l=1,n=m=0,p=1,r=q=0);k=a*l+b*n+q;a=a*m+b*p+r;l=c*l+d*n+q;d=c*m+d*p+r;
f.p(l,d);m=(l-k)*(l-k)+(d-a)*(d-a);c=!1;p=this.xa.n;r=p.length;n=t.K();for(q=0;q<r;q++)b=p[q],b.visible&&b.kp(k,a,l,d,n)&&(c=!0,b=(k-n.x)*(k-n.x)+(a-n.y)*(a-n.y),b<m&&(m=b,f.set(n)));t.B(n);g&&f.transform(h)}return c};
y.prototype.ut=function(a,b,c,d){var f=this.Qi;f.width=0;f.height=0;var g=this.Ca,h=this.Vf;void 0===c&&(c=h.width,d=h.height);c=Math.max(c,h.width);d=Math.max(d,h.height);var k=this.He;isNaN(g.width)||(a=Math.min(g.width,k.width));isNaN(g.height)||(b=Math.min(g.height,k.height));a=Math.max(c,a);b=Math.max(d,b);var l=this.padding;a=Math.max(a-l.left-l.right,0);b=Math.max(b-l.top-l.bottom,0);var m=this.xa,n=m.length;if(0!==n){var p=this.da.Zb;switch(p){case "Position":var q=a,r=b,s=c,u=d;f.x=0;f.y=
0;f.width=0;for(var v=f.height=0;v<n;v++){var D=m.n[v];if(D.visible||D===this.kc){var A=D.margin,G=A.right+A.left,K=A.top+A.bottom;ih(D,q,r,s,u);var N=D.Ga,O=Math.max(N.width+G,0),U=Math.max(N.height+K,0),X=D.position.x,Z=D.position.y;isFinite(X)||(X=0);isFinite(Z)||(Z=0);if(D instanceof V&&D.rA)var P=D.fb/2,X=X-P,Z=Z-P;hb(f,X,Z,O,U)}}break;case "Vertical":for(var pa=a,Ya=c,za=t.Db(),Na=0;Na<n;Na++){var wa=m.n[Na];if(wa.visible||wa===this.kc){var Pa=qk(wa);if(Pa!==Vg&&Pa!==hk)za.push(wa);else{var Ga=
wa.margin,Kf=Ga.right+Ga.left,lf=Ga.top+Ga.bottom;ih(wa,pa,Infinity,Ya,0);var $b=wa.Ga,gg=Math.max($b.width+Kf,0),ic=Math.max($b.height+lf,0);f.width=Math.max(f.width,gg);f.height+=ic}}}var kb=za.length;if(0!==kb){this.Ca.width?pa=Math.min(this.Ca.width,this.He.width):0!==f.width&&(pa=Math.min(f.width,this.He.width));for(Na=0;Na<kb;Na++)if(wa=za[Na],wa.visible||wa===this.kc)Ga=wa.margin,Kf=Ga.right+Ga.left,lf=Ga.top+Ga.bottom,ih(wa,pa,Infinity,Ya,0),$b=wa.Ga,gg=Math.max($b.width+Kf,0),ic=Math.max($b.height+
lf,0),f.width=Math.max(f.width,gg),f.height+=ic;t.ya(za)}break;case "Horizontal":for(var fb=b,Ia=d,Ja=t.Db(),Xa=0;Xa<n;Xa++){var db=m.n[Xa];if(db.visible||db===this.kc){var Ab=qk(db);if(Ab!==Vg&&Ab!==ik)Ja.push(db);else{var md=db.margin,mf=md.right+md.left,Dd=md.top+md.bottom;ih(db,Infinity,fb,0,Ia);var nd=db.Ga,Ne=Math.max(nd.width+mf,0),Ed=Math.max(nd.height+Dd,0);f.width+=Ne;f.height=Math.max(f.height,Ed)}}}var se=Ja.length;if(0!==se){this.Ca.height?fb=Math.min(this.Ca.height,this.He.height):0!==
f.height&&(fb=Math.min(f.height,this.He.height));for(Xa=0;Xa<se;Xa++)if(db=Ja[Xa],db.visible||db===this.kc)md=db.margin,mf=md.right+md.left,Dd=md.top+md.bottom,ih(db,Infinity,fb,0,Ia),nd=db.Ga,Ne=Math.max(nd.width+mf,0),Ed=Math.max(nd.height+Dd,0),f.width+=Ne,f.height=Math.max(f.height,Ed);t.ya(Ja)}break;case "Spot":a:{var Lf=a,Bb=b,Cg=c,Ib=d,rb=Kk(this,m,n),Za=rb.margin,yb,jc,Mf=Za.right+Za.left,Nf=Za.top+Za.bottom;ih(rb,Lf,Bb,Cg,Ib);var kc=rb.Ga,Uc=kc.width,od=kc.height,ec=Math.max(Uc+Mf,0),pd=
Math.max(od+Nf,0);f.x=-Za.left;f.y=-Za.top;f.width=ec;f.height=pd;for(var $a=0;$a<n;$a++){var Ua=m.n[$a];if(Ua!==rb&&(Ua.visible||Ua===this.kc)){Za=Ua.margin;yb=Za.right+Za.left;jc=Za.top+Za.bottom;ih(Ua,Lf,Bb,0,0);var kc=Ua.Ga,ec=Math.max(kc.width+yb,0),pd=Math.max(kc.height+jc,0),Rb=Ua.alignment;Rb.Pc()&&(Rb=this.Xj);Rb.xd()||(Rb=Hb);var Jb=Ua.Qj;Jb.Pc()&&(Jb=Hb);hb(f,Rb.x*Uc+Rb.offsetX-(Jb.x*kc.width-Jb.offsetX)-Za.left,Rb.y*od+Rb.offsetY-(Jb.y*kc.height-Jb.offsetY)-Za.top,ec,pd)}}var Oe=rb.fi;
Oe===gk&&(Oe=qk(rb));switch(Oe){case Vg:break a;case Cc:if(!isFinite(Lf)&&!isFinite(Bb))break a;break;case ik:if(!isFinite(Lf))break a;break;case hk:if(!isFinite(Bb))break a}kc=rb.Ga;Uc=kc.width;od=kc.height;ec=Math.max(Uc+Mf,0);pd=Math.max(od+Nf,0);Za=rb.margin;f.x=-Za.left;f.y=-Za.top;f.width=ec;f.height=pd;for($a=0;$a<n;$a++)Ua=m.n[$a],Ua===rb||!Ua.visible&&Ua!==this.kc||(Za=Ua.margin,yb=Za.right+Za.left,jc=Za.top+Za.bottom,kc=Ua.Ga,ec=Math.max(kc.width+yb,0),pd=Math.max(kc.height+jc,0),Rb=Ua.alignment,
Rb.Pc()&&(Rb=this.Xj),Rb.xd()||(Rb=Hb),Jb=Ua.Qj,Jb.Pc()&&(Jb=Hb),hb(f,Rb.x*Uc+Rb.offsetX-(Jb.x*kc.width-Jb.offsetX)-Za.left,Rb.y*od+Rb.offsetY-(Jb.y*kc.height-Jb.offsetY)-Za.top,ec,pd))}break;case "Auto":var Od=a,pc=b,Kb=c,de=d,sb=Kk(this,m,n),Fd=sb.margin,Dg=Fd.right+Fd.left,Pe=Fd.top+Fd.bottom;ih(sb,Od,pc,Kb,de);var Gd=sb.Ga,gb=Math.max(Gd.width+Dg,0),nf=Math.max(Gd.height+Pe,0),ib=Ol(sb),tb=ib.x*gb+ib.offsetX,Vc=ib.y*nf+ib.offsetY,ib=Pl(sb),Nb=ib.x*gb+ib.offsetX,Pd=ib.y*nf+ib.offsetY,Qd=Od,ee=
pc;isFinite(Od)&&(Qd=Math.abs(tb-Nb));isFinite(pc)&&(ee=Math.abs(Vc-Pd));var Fc=t.Cl();Fc.p(0,0);for(var te=0;te<n;te++){var Gc=m.n[te];if(Gc!==sb&&(Gc.visible||Gc===this.kc)){var Fd=Gc.margin,Cb=Fd.right+Fd.left,Qe=Fd.top+Fd.bottom;ih(Gc,Qd,ee,0,0);Gd=Gc.Ga;gb=Math.max(Gd.width+Cb,0);nf=Math.max(Gd.height+Qe,0);Fc.p(Math.max(gb,Fc.width),Math.max(nf,Fc.height))}}if(1===n)f.width=gb,f.height=nf,t.ck(Fc);else{var qd=Ol(sb),lb=Pl(sb),Hd,Db;lb.x!==qd.x&&lb.y!==qd.y&&(Hd=Fc.width/Math.abs(lb.x-qd.x),
Db=Fc.height/Math.abs(lb.y-qd.y));t.ck(Fc);var Rd=0;sb instanceof V&&(Rd=sb.fb*sb.scale,Wg(sb)===Xg&&(Hd=Math.max(Hd,Db),Db=Math.max(Hd,Db)));Hd+=Math.abs(qd.offsetX)+Math.abs(lb.offsetX)+Rd;Db+=Math.abs(qd.offsetY)+Math.abs(lb.offsetY)+Rd;var Id=sb.fi;Id===gk&&(Id=qk(sb));switch(Id){case Vg:de=Kb=0;break;case Cc:isFinite(Od)&&(Hd=Od);isFinite(pc)&&(Db=pc);break;case ik:isFinite(Od)&&(Hd=Od);de=0;break;case hk:Kb=0,isFinite(pc)&&(Db=pc)}sb instanceof V&&!sb.Ca.N()&&(sb.ah?sb.yk=null:sb.Ta=null);Lk(sb);
ih(sb,Hd,Db,Kb,de);f.width=sb.Ga.width+Dg;f.height=sb.Ga.height+Pe}break;case "Table":for(var lc=a,Ob=b,nb=n,of=c,bb=d,Jd=t.Db(),Wc=t.Db(),la=0;la<nb;la++){var sa=m.n[la];if(sa instanceof y&&(sa.type===zk||sa.type===Ak)&&sa.visible){Wc.push(sa);for(var wj=sa.xa,oh=wj.length,xc=0;xc<oh;xc++){var ph=wj.n[xc];sa.type===zk?ph.lc=sa.lc:sa.type===Ak&&(ph.column=sa.column);Jd.push(ph)}}else Jd.push(sa)}nb=Jd.length;0===nb&&(this.wd(0),this.vd(0));for(var fc=[],la=0;la<nb;la++)sa=Jd[la],rk(sa,!0),vk(sa,!0),
fc[sa.lc]||(fc[sa.lc]=[]),fc[sa.lc][sa.column]||(fc[sa.lc][sa.column]=[]),fc[sa.lc][sa.column].push(sa);t.ya(Jd);for(var Eg=t.Db(),hg=t.Db(),Hc=t.Db(),Ic={count:0},Jc={count:0},yc=lc,Kc=Ob,Of=this.kd,nb=Of.length,la=0;la<nb;la++){var ka=Of[la];void 0!==ka&&Ql(ka,0)}Of=this.dd;nb=Of.length;for(la=0;la<nb;la++)ka=Of[la],void 0!==ka&&Ql(ka,0);for(var fe=fc.length,Sd=0,la=0;la<fe;la++)fc[la]&&(Sd=Math.max(Sd,fc[la].length));for(var ig=Math.min(this.Pi,fe-1),xj=Math.min(this.zi,Sd-1),Xc,fe=fc.length,la=
ig;la<fe;la++)if(fc[la]){var Sd=fc[la].length,jb=this.wd(la);Ql(jb,0);for(xc=xj;xc<Sd;xc++)if(fc[la][xc]){var ob=this.vd(xc);void 0===Eg[xc]&&(Ql(ob,0),Eg[xc]=!0);for(var ji=fc[la][xc],yl=ji.length,jg=0;jg<yl;jg++)if(sa=ji[jg],sa.visible||sa===this.kc){var kg=1<sa.Jj||1<sa.rj;kg&&hg.push(sa);var Sb=sa.margin,Pf=Sb.right+Sb.left,Qf=Sb.top+Sb.bottom,Td=Ok(sa,jb,ob),ge=sa.Ca,qh=!isNaN(ge.width),Rf=!isNaN(ge.height),Re=qh&&Rf;kg||Td===Vg||Re||(void 0===Ic[xc]&&(Ic[xc]=-1,Ic.count++),void 0===Jc[la]&&
(Jc[la]=-1,Jc.count++),Hc.push(sa));ih(sa,Infinity,Infinity,0,0);var ue=sa.Ga,Se=Math.max(ue.width+Pf,0),Te=Math.max(ue.height+Qf,0);1!==sa.Jj||!Rf&&Td!==Vg&&Td!==ik||(ka=this.wd(la),Xc=Math.max(Te-ka.fc,0),Xc>Kc&&(Xc=Kc),Ql(ka,ka.fc+Xc),Kc=Math.max(Kc-Xc,0));1!==sa.rj||!qh&&Td!==Vg&&Td!==hk||(ka=this.vd(xc),Xc=Math.max(Se-ka.fc,0),Xc>yc&&(Xc=yc),Ql(ka,ka.fc+Xc),yc=Math.max(yc-Xc,0));kg&&Lk(sa)}}}t.ya(Eg);for(var he=0,ie=0,nb=this.Vs,la=0;la<nb;la++)void 0!==this.dd[la]&&(he+=this.vd(la).Lb);nb=this.Mt;
for(la=0;la<nb;la++)void 0!==this.kd[la]&&(ie+=this.wd(la).Lb);for(var yc=Math.max(lc-he,0),sd=Kc=Math.max(Ob-ie,0),rh=yc,nb=Hc.length,la=0;la<nb;la++){var sa=Hc[la],jb=this.wd(sa.lc),ob=this.vd(sa.column),Fg=sa.Ga,Sb=sa.margin,Pf=Sb.right+Sb.left,Qf=Sb.top+Sb.bottom;Ic[sa.column]=0===ob.fc?Math.max(Fg.width+Pf,Ic[sa.column]):null;Jc[sa.lc]=0===jb.fc?Math.max(Fg.height+Qf,Jc[sa.lc]):null}var Ud=0,Gg=0;for(la in Jc)"count"!==la&&(Ud+=Jc[la]);for(la in Ic)"count"!==la&&(Gg+=Ic[la]);for(var zb=t.Cl(),
la=0;la<nb;la++)if(sa=Hc[la],sa.visible||sa===this.kc){var jb=this.wd(sa.lc),ob=this.vd(sa.column),je;isFinite(ob.width)?je=ob.width:(je=isFinite(yc)&&null!==Ic[sa.column]?0===Gg?ob.fc+yc:Ic[sa.column]/Gg*rh:null!==Ic[sa.column]?yc:ob.fc||yc,je=Math.max(0,je-Nl(ob)));var pf;isFinite(jb.height)?pf=jb.height:(pf=isFinite(Kc)&&null!==Jc[sa.lc]?0===Ud?jb.fc+Kc:Jc[sa.lc]/Ud*sd:null!==Jc[sa.lc]?Kc:jb.fc||Kc,pf=Math.max(0,pf-Nl(jb)));zb.p(Math.max(ob.di,Math.min(je,ob.zf)),Math.max(jb.di,Math.min(pf,jb.zf)));
Td=Ok(sa,jb,ob);switch(Td){case ik:zb.height=Infinity;break;case hk:zb.width=Infinity}Sb=sa.margin;Pf=Sb.right+Sb.left;Qf=Sb.top+Sb.bottom;Lk(sa);ih(sa,zb.width,zb.height,ob.di,jb.di);ue=sa.Ga;Se=Math.max(ue.width+Pf,0);Te=Math.max(ue.height+Qf,0);isFinite(yc)&&(Se=Math.min(Se,zb.width));isFinite(Kc)&&(Te=Math.min(Te,zb.height));var qf;qf=jb.fc;Ql(jb,Math.max(jb.fc,Te));Xc=jb.fc-qf;Kc=Math.max(Kc-Xc,0);qf=ob.fc;Ql(ob,Math.max(ob.fc,Se));Xc=ob.fc-qf;yc=Math.max(yc-Xc,0)}t.ya(Hc);for(var Vd=t.Cl(),
nb=hg.length,la=0;la<nb;la++)if(sa=hg[la],sa.visible||sa===this.kc){jb=this.wd(sa.lc);ob=this.vd(sa.column);zb.p(Math.max(ob.di,Math.min(lc,ob.zf)),Math.max(jb.di,Math.min(Ob,jb.zf)));Td=Ok(sa,jb,ob);switch(Td){case Cc:0!==ob.fc&&(zb.width=Math.min(zb.width,ob.fc));0!==jb.fc&&(zb.height=Math.min(zb.height,jb.fc));break;case ik:0!==ob.fc&&(zb.width=Math.min(zb.width,ob.fc));break;case hk:0!==jb.fc&&(zb.height=Math.min(zb.height,jb.fc))}isFinite(ob.width)&&(zb.width=ob.width);isFinite(jb.height)&&(zb.height=
jb.height);Vd.p(0,0);for(var Tb=1;Tb<sa.Jj&&!(sa.lc+Tb>=this.Mt);Tb++)ka=this.wd(sa.lc+Tb),Vd.height+=Math.max(ka.di,isNaN(ka.Mf)?ka.zf:Math.min(ka.Mf,ka.zf));for(Tb=1;Tb<sa.rj&&!(sa.column+Tb>=this.Vs);Tb++)ka=this.vd(sa.column+Tb),Vd.width+=Math.max(ka.di,isNaN(ka.Mf)?ka.zf:Math.min(ka.Mf,ka.zf));zb.width+=Vd.width;zb.height+=Vd.height;Sb=sa.margin;Pf=Sb.right+Sb.left;Qf=Sb.top+Sb.bottom;ih(sa,zb.width,zb.height,of,bb);for(var ue=sa.Ga,Se=Math.max(ue.width+Pf,0),Te=Math.max(ue.height+Qf,0),Hg=0,
Tb=0;Tb<sa.Jj&&!(sa.lc+Tb>=this.Mt);Tb++)ka=this.wd(sa.lc+Tb),Hg+=ka.total||0;if(Hg<Te)for(var Wd=Te-Hg;0<Wd;){var qc=ka.Lb||0;isNaN(ka.height)&&ka.zf>qc&&(Ql(ka,Math.min(ka.zf,qc+Wd)),ka.Lb!==qc&&(Wd-=ka.Lb-qc));if(-1===ka.index-1)break;ka=this.wd(ka.index-1)}for(var Ue=0,Tb=0;Tb<sa.rj&&!(sa.column+Tb>=this.Vs);Tb++)ka=this.vd(sa.column+Tb),Ue+=ka.total||0;if(Ue<Se)for(Wd=Se-Ue;0<Wd;){qc=ka.Lb||0;isNaN(ka.width)&&ka.zf>qc&&(Ql(ka,Math.min(ka.zf,qc+Wd)),ka.Lb!==qc&&(Wd-=ka.Lb-qc));if(-1===ka.index-
1)break;ka=this.vd(ka.index-1)}}t.ya(hg);t.ck(Vd);t.ck(zb);for(var rf=0,ke=0,Td=qk(this),Ig=this.Ca,lg=this.He,Lc=ie=he=0,le=0,nb=this.Vs,la=0;la<nb;la++)void 0!==this.dd[la]&&(ka=this.vd(la),isFinite(ka.width)?(Lc+=ka.width,Lc+=Nl(ka)):Rl(ka)===Sl?(Lc+=ka.Lb,Lc+=Nl(ka)):0!==ka.Lb&&(he+=ka.Lb,he+=Nl(ka)));var rf=isFinite(Ig.width)?Math.min(Ig.width,lg.width):Td!==Vg&&isFinite(lc)?lc:he,rf=Math.max(rf,this.Vf.width),rf=Math.max(rf-Lc,0),Kg=Math.max(rf/he,1);isFinite(Kg)||(Kg=1);for(la=0;la<nb;la++)void 0!==
this.dd[la]&&(ka=this.vd(la),isFinite(ka.width)||Rl(ka)===Sl||Ql(ka,ka.Lb*Kg),ka.Ra=f.width,0!==ka.Lb&&(f.width+=ka.Lb,f.width+=Nl(ka)));nb=this.Mt;for(la=0;la<nb;la++)void 0!==this.kd[la]&&(ka=this.wd(la),isFinite(ka.height)?(le+=ka.height,le+=Nl(ka)):Rl(ka)===Sl?(le+=ka.Lb,le+=Nl(ka)):0!==ka.Lb&&(ie+=ka.Lb,ie+=Nl(ka)));var ke=isFinite(Ig.height)?Math.min(Ig.height,lg.height):Td!==Vg&&isFinite(Ob)?Ob:ie,ke=Math.max(ke,this.Vf.height),ke=Math.max(ke-le,0),Ve=Math.max(ke/ie,1);isFinite(Ve)||(Ve=1);
for(la=0;la<nb;la++)void 0!==this.kd[la]&&(ka=this.wd(la),isFinite(ka.height)||Rl(ka)===Sl||Ql(ka,ka.Lb*Ve),ka.Ra=f.height,0!==ka.Lb&&(f.height+=ka.Lb,f.height+=Nl(ka)));nb=Wc.length;for(la=0;la<nb;la++){var Yc=Wc[la];Yc.type===zk?(je=f.width,ka=this.wd(Yc.lc),pf=ka.fc):(ka=this.vd(Yc.column),je=ka.fc,pf=f.height);Yc.Wc.p(0,0,je,pf);rk(Yc,!1);fc[Yc.lc]||(fc[Yc.lc]=[]);fc[Yc.lc][Yc.column]||(fc[Yc.lc][Yc.column]=[]);fc[Yc.lc][Yc.column].push(Yc)}t.ya(Wc);this.bs=fc;break;case "Viewbox":var zj=a,Aj=
b,Ho=c,Io=d;1<n&&t.m("Viewbox Panel cannot contain more than one GraphObject.");var ve=m.n[0];ve.xc=1;Lk(ve);ih(ve,Infinity,Infinity,Ho,Io);var ki=ve.Ga,Bj=ve.margin,Jo=Bj.right+Bj.left,Ko=Bj.top+Bj.bottom;if(isFinite(zj)||isFinite(Aj)){var Nq=ve.scale,Cj=ki.width,Dj=ki.height,Lo=Math.max(zj-Jo,0),Mo=Math.max(Aj-Ko,0),li=1;this.Io===Xg?0!==Cj&&0!==Dj&&(li=Math.min(Lo/Cj,Mo/Dj)):0!==Cj&&0!==Dj&&(li=Math.max(Lo/Cj,Mo/Dj));0===li&&(li=1E-4);ve.xc*=li;Nq!==ve.scale&&(rk(ve,!0),ih(ve,Infinity,Infinity,
Ho,Io))}ki=ve.Ga;f.width=isFinite(zj)?zj:Math.max(ki.width+Jo,0);f.height=isFinite(Aj)?Aj:Math.max(ki.height+Ko,0);break;case "Link":var Ej=this instanceof Je?this.Wh:this;if(0===n){var Fj=this.hd;Va(Fj,0,0);var Xd=this.Ga;Xd.p(0,0,0,0)}else{var Gj=this.Rq(),We=this.uj(),we=this.Qi;we.assign(We);we.x=0;we.y=0;var sh,mi=this.Sy();sh=void 0!==this.pa?this.pa:mi.count;this.Yg.p(We.x,We.y);this.mi.clear();null!==Gj&&(Tl(Gj,We.width,We.height),Xd=Gj.Ga,we.ii(Xd),this.mi.add(Xd));for(var th=t.oh(),ni=t.K(),
Sf=t.K(),zl=0;zl<n;zl++){var Ub=m.n[zl];if(Ub!==Gj)if(Ub.fj&&Ub instanceof V)Tl(Ub,We.width,We.height),Xd=Ub.Ga,we.ii(Xd),this.mi.add(Xd);else if(2>sh)ih(Ub,Infinity,Infinity),Xd=Ub.Ga,we.ii(Xd),this.mi.add(Xd);else{var Tf=Ub.Cf,Oo=Ub.Nt,Al=Ub.Qj;Al.Ge()&&(Al=Hb);var oi=Ub.Ot,Oq=Ub.YA,pi,qi,Hj=0;if(Tf<-sh||Tf>=sh){var Po=Ej.yF,ri=Ej.xF;oi!==zg&&(Hj=Ej.computeAngle(Ub,oi,ri),Ub.angle=Hj);pi=Po.x-We.x;qi=Po.y-We.y}else{var sf,uh;if(0<=Tf)sf=mi.n[Tf],uh=Tf<sh-1?mi.n[Tf+1]:sf;else{var Bl=sh+Tf;sf=mi.n[Bl];
uh=0<Bl?mi.n[Bl-1]:sf}ri=0<=Tf?sf.aj(uh):uh.aj(sf);oi!==zg&&(Hj=Ej.computeAngle(Ub,oi,ri),Ub.wn=Hj);pi=sf.x+(uh.x-sf.x)*Oo-We.x;qi=sf.y+(uh.y-sf.y)*Oo-We.y}ih(Ub,Infinity,Infinity);var Xd=Ub.Ga,Fj=Ub.Pa,si=0;Ub instanceof V&&(si=Ub.fb);var Ij=Fj.width+si,Cl=Fj.height+si;th.reset();th.translate(-Xd.x,-Xd.y);th.scale(Ub.scale,Ub.scale);th.rotate(oi===zg?Ub.angle:ri,Ij/2,Cl/2);var vh=new x(0,0,Ij,Cl);ni.Qt(vh,Al);th.Sa(ni);var Pq=-ni.x+si/2,Qq=-ni.y+si/2;Sf.assign(Oq);isNaN(Sf.x)&&(Sf.x=0<=Tf?Ij/2+3:
-(Ij/2+3));isNaN(Sf.y)&&(Sf.y=-(Cl/2+3));Sf.rotate(ri);pi+=Sf.x;qi+=Sf.y;vh.set(Xd);vh.x=pi+Pq;vh.y=qi+Qq;this.mi.add(vh);we.ii(vh)}}if(this.we)for(var Qo=this.fk;Qo.next();)ih(Qo.value,Infinity,Infinity);this.Qi=we;var Jj=this.Yg;Jj&&Jj.p(Jj.x+we.x,Jj.y+we.y);Va(f,we.width||0,we.height||0);t.We(th);t.B(ni);t.B(Sf)}break;case "Grid":break;case "TableRow":case "TableColumn":t.m(this.toString()+" is not an element of a Table Panel. TableRow and TableColumn Panels can only be elements of a Table Panel.");
break;default:t.m("Unknown panel type: "+p)}}var xe=f.width,ye=f.height,Kj=this.padding,Rq=Kj.top+Kj.bottom,xe=xe+(Kj.left+Kj.right),ye=ye+Rq;isFinite(g.width)&&(xe=g.width);isFinite(g.height)&&(ye=g.height);xe=Math.min(k.width,xe);ye=Math.min(k.height,ye);xe=Math.max(h.width,xe);ye=Math.max(h.height,ye);xe=Math.max(c,xe);ye=Math.max(d,ye);f.width=xe;f.height=ye;Va(this.hd,xe,ye);nk(this,0,0,xe,ye)};
y.prototype.findMainElement=y.prototype.Dw=function(){null===this.Eo&&Kk(this,this.xa,this.xa.length);return this.Eo};function Kk(a,b,c){for(var d=0;d<c;d++){var f=b.n[d];if(!0===f.fj)return a.Eo=f}b=b.n[0];return a.Eo=b}
y.prototype.Tj=function(a,b,c,d){var f=this.Qi,g=this.xa,h=g.length,k=t.ik(0,0,0,0);if(0===h){var l=this.oa;l.x=a;l.y=b;l.width=c;l.height=d}else{if(!this.Ca.N()){var m=qk(this),n=this.Wc,p=n.width,q=n.height,r=this.margin,s=r.left+r.right,u=r.top+r.bottom;p===c&&q===d&&(m=Vg);switch(m){case Vg:if(p>c||q>d)this.ha(),ih(this,p>c?c:p,q>d?d:q);break;case Cc:rk(this,!0);ih(this,c+s,d+u,0,0);break;case ik:rk(this,!0);ih(this,c+s,q+u,0,0);break;case hk:rk(this,!0),ih(this,p+s,d+u,0,0)}}l=this.oa;l.x=a;
l.y=b;l.width=c;l.height=d;var v=this.da.Zb;switch(v){case "Position":for(var D=f.x-this.padding.left,A=f.y-this.padding.top,G=0;G<h;G++){var K=g.n[G],N=K.Ga,O=K.margin,U=K.position.x,X=K.position.y;k.x=isNaN(U)?-D:U-D;k.y=isNaN(X)?-A:X-A;if(K instanceof V&&K.rA){var Z=K.fb/2;k.x-=Z;k.y-=Z}k.x+=O.left;k.y+=O.top;k.width=N.width;k.height=N.height;K.visible&&K.Lc(k.x,k.y,k.width,k.height)}break;case "Vertical":for(var P=this.padding.left,pa=this.padding.top,Ya=0;Ya<h;Ya++){var za=P,Na=g.n[Ya];if(Na.visible){var wa=
Na.Ga,Pa=Na.margin,Ga=Pa.left+Pa.right,Kf=P+this.padding.right,lf=wa.width,$b=qk(Na);if(isNaN(Na.Ca.width)&&$b===Cc||$b===ik)lf=Math.max(f.width-Ga-Kf,0);var gg=lf+Ga+Kf,ic=Na.alignment;ic.Pc()&&(ic=this.Xj);ic.xd()||(ic=Hb);Na.Lc(za+ic.offsetX+Pa.left+(f.width*ic.x-gg*ic.x),pa+ic.offsetY+Pa.top,lf,wa.height);pa+=wa.height+Pa.bottom+Pa.top}}break;case "Horizontal":for(var kb=this.padding.top,fb=this.padding.left,Ia=0;Ia<h;Ia++){var Ja=kb,Xa=g.n[Ia];if(Xa.visible){var db=Xa.Ga,Ab=Xa.margin,md=Ab.top+
Ab.bottom,mf=kb+this.padding.bottom,Dd=db.height,nd=qk(Xa);if(isNaN(Xa.Ca.height)&&nd===Cc||nd===hk)Dd=Math.max(f.height-md-mf,0);var Ne=Dd+md+mf,Ed=Xa.alignment;Ed.Pc()&&(Ed=this.Xj);Ed.xd()||(Ed=Hb);Xa.Lc(fb+Ed.offsetX+Ab.left,Ja+Ed.offsetY+Ab.top+(f.height*Ed.y-Ne*Ed.y),db.width,Dd);fb+=db.width+Ab.left+Ab.right}}break;case "Spot":var se=Kk(this,g,h),Lf=se.Ga,Bb=Lf.width,Cg=Lf.height,Ib=this.padding,rb=Ib.left,Za=Ib.top;k.x=rb-f.x;k.y=Za-f.y;se.Lc(k.x,k.y,Bb,Cg);for(var yb=0;yb<h;yb++){var jc=
g.n[yb];if(jc!==se){var Mf=jc.Ga,Nf=Mf.width,kc=Mf.height,Uc=jc.alignment;Uc.Pc()&&(Uc=this.Xj);Uc.xd()||(Uc=Hb);var od=jc.Qj;od.Pc()&&(od=Hb);k.x=Uc.x*Bb+Uc.offsetX-(od.x*Nf-od.offsetX);k.y=Uc.y*Cg+Uc.offsetY-(od.y*kc-od.offsetY);k.x-=f.x;k.y-=f.y;jc.visible&&jc.Lc(rb+k.x,Za+k.y,Nf,kc)}}break;case "Auto":var ec=Kk(this,g,h),pd=ec.Ga,$a=t.Gf();$a.p(0,0,1,1);var Ua=ec.margin,Rb=Ua.left,Jb=Ua.top,Oe=this.padding,Od=Oe.left,pc=Oe.top;k.x=Rb;k.y=Jb;k.width=pd.width;k.height=pd.height;ec.Lc(Od+k.x,pc+
k.y,k.width,k.height);var Kb=Ol(ec),de=Pl(ec),sb=0+Kb.y*pd.height+Kb.offsetY,Fd=0+de.x*pd.width+de.offsetX,Dg=0+de.y*pd.height+de.offsetY;$a.x=0+Kb.x*pd.width+Kb.offsetX;$a.y=sb;hb($a,Fd,Dg,0,0);$a.x+=Rb+Od;$a.y+=Jb+pc;for(var Pe=0;Pe<h;Pe++){var Gd=g.n[Pe];if(Gd!==ec){var gb=Gd.Ga,Ua=Gd.margin,nf=Math.max(gb.width+Ua.right+Ua.left,0),ib=Math.max(gb.height+Ua.top+Ua.bottom,0),tb=Gd.alignment;tb.Pc()&&(tb=this.Xj);tb.xd()||(tb=Hb);k.x=$a.width*tb.x+tb.offsetX-nf*tb.x+Ua.left+$a.x;k.y=$a.height*tb.y+
tb.offsetY-ib*tb.y+Ua.top+$a.y;k.width=$a.width;k.height=$a.height;Gd.visible&&(mb($a.x,$a.y,$a.width,$a.height,k.x,k.y,gb.width,gb.height)?Gd.Lc(k.x,k.y,gb.width,gb.height):Gd.Lc(k.x,k.y,gb.width,gb.height,new x($a.x,$a.y,$a.width,$a.height)))}}t.hc($a);break;case "Table":for(var Vc=this.padding,Nb=Vc.left,Pd=Vc.top,Qd=this.bs,ee,Fc,te=Qd.length,Gc=0,Cb=0;Cb<te;Cb++)Qd[Cb]&&(Gc=Math.max(Gc,Qd[Cb].length));for(var Qe=Math.min(this.Pi,te-1);Qe!==te&&(void 0===this.kd[Qe]||0===this.kd[Qe].Lb);)Qe++;
for(var Qe=Math.min(Qe,te-1),qd=-this.kd[Qe].Ra,lb=Math.min(this.zi,Gc-1);lb!==Gc&&(void 0===this.dd[lb]||0===this.dd[lb].Lb);)lb++;for(var lb=Math.min(lb,Gc-1),Hd=-this.dd[lb].Ra,Db=t.Cl(),Cb=0;Cb<te;Cb++)if(Qd[Cb]){var Gc=Qd[Cb].length,Rd=this.wd(Cb);Fc=Rd.Ra+qd+Pd+Ul(Rd);for(var Id=0;Id<Gc;Id++)if(Qd[Cb][Id]){var lc=this.vd(Id);ee=lc.Ra+Hd+Nb+Ul(lc);for(var Ob=Qd[Cb][Id],nb=Ob.length,of=0;of<nb;of++){var bb=Ob[of],Jd=bb.Ga;if(bb instanceof y&&(bb.type===zk||bb.type===Ak)){sk(bb);bb.cc.Ma();var Wc=
bb.cc;Wc.x=bb.type===zk?Nb:ee;Wc.y=bb.type===Ak?Pd:Fc;Wc.width=Jd.width;Wc.height=Jd.height;bb.cc.freeze();vk(bb,!1)}else{Db.p(0,0);for(var la=1;la<bb.rowSpan&&!(Cb+la>=this.Mt);la++){var sa=this.wd(Cb+la);Db.height+=sa.total}for(la=1;la<bb.ZD&&!(Id+la>=this.Vs);la++){var wj=this.vd(Id+la);Db.width+=wj.total}var oh=lc.Lb+Db.width,xc=Rd.Lb+Db.height;k.x=ee;k.y=Fc;k.width=oh;k.height=xc;var ph=ee,fc=Fc,Eg=oh,hg=xc;ee+oh>f.width&&(Eg=Math.max(f.width-ee,0));Fc+xc>f.height&&(hg=Math.max(f.height-Fc,0));
var Hc=bb.alignment,Ic,Jc,yc,Kc;if(Hc.Pc()){Hc=this.Xj;Hc.xd()||(Hc=Hb);Ic=Hc.x;Jc=Hc.y;yc=Hc.offsetX;Kc=Hc.offsetY;var Of=lc.alignment,ka=Rd.alignment;Of.xd()&&(Ic=Of.x,yc=Of.offsetX);ka.xd()&&(Jc=ka.y,Kc=ka.offsetY)}else Ic=Hc.x,Jc=Hc.y,yc=Hc.offsetX,Kc=Hc.offsetY;if(isNaN(Ic)||isNaN(Jc))Jc=Ic=0.5,Kc=yc=0;var fe=Jd.width,Sd=Jd.height,ig=bb.margin,xj=ig.left+ig.right,Xc=ig.top+ig.bottom,jb=Ok(bb,Rd,lc);!isNaN(bb.Ca.width)||jb!==Cc&&jb!==ik||(fe=Math.max(oh-xj,0));!isNaN(bb.Ca.height)||jb!==Cc&&jb!==
hk||(Sd=Math.max(xc-Xc,0));var ob=bb.He,ji=bb.Vf,fe=Math.min(ob.width,fe),Sd=Math.min(ob.height,Sd),fe=Math.max(ji.width,fe),Sd=Math.max(ji.height,Sd),yl=Sd+Xc;k.x+=k.width*Ic-(fe+xj)*Ic+yc+ig.left;k.y+=k.height*Jc-yl*Jc+Kc+ig.top;bb.visible&&(mb(ph,fc,Eg,hg,k.x,k.y,Jd.width,Jd.height)?bb.Lc(k.x,k.y,fe,Sd):bb.Lc(k.x,k.y,fe,Sd,new x(ph,fc,Eg,hg)))}}}}t.ck(Db);for(Cb=0;Cb<h;Cb++)bb=g.n[Cb],bb instanceof y&&(bb.type===zk||bb.type===Ak)&&(Wc=bb.cc,bb.hd.Ma(),bb.hd.Sp(0,0,Wc.width,Wc.height),bb.hd.freeze());
break;case "Viewbox":var jg=g.n[0],kg=jg.Ga,Sb=jg.margin,Pf=Sb.top+Sb.bottom,Qf=Math.max(kg.width+(Sb.right+Sb.left),0),Td=Math.max(kg.height+Pf,0),ge=jg.alignment;ge.Pc()&&(ge=this.Xj);ge.xd()||(ge=Hb);k.x=f.width*ge.x-Qf*ge.x+ge.offsetX;k.y=f.height*ge.y-Td*ge.y+ge.offsetY;k.width=kg.width;k.height=kg.height;jg.Lc(k.x,k.y,k.width,k.height);break;case "Link":var qh=this.Rq(),Rf=0;if(null!==qh&&Rf<this.mi.count){var Re=this.mi.n[Rf];Rf++;qh.Lc(Re.x-this.Qi.x,Re.y-this.Qi.y,Re.width,Re.height)}for(var ue=
0;ue<h;ue++){var Se=g.n[ue];Se!==qh&&Rf<this.mi.count&&(Re=this.mi.n[Rf],Rf++,Se.Lc(Re.x-this.Qi.x,Re.y-this.Qi.y,Re.width,Re.height))}var Te=this.Sy(),he=Te.count;if(2<=he&&this.we)for(var ie=this.fk;ie.next();){var sd=ie.value,rh=he,Fg=Te,Ud=sd.Cf,Gg=sd.Nt,zb=sd.Qj;zb.Ge()&&(zb=Hb);var je=sd.Ot,pf=sd.YA,qf=void 0,Vd=void 0,Tb=0;if(Ud<-rh||Ud>=rh){var Hg=this.yF,Wd=this.xF;je!==zg&&(Tb=this.computeAngle(sd,je,Wd),sd.angle=Tb);qf=Hg.x;Vd=Hg.y}else{var qc=void 0,Ue=void 0;if(0<=Ud)qc=Fg.n[Ud],Ue=Ud<
rh-1?Fg.n[Ud+1]:qc;else var rf=rh+Ud,qc=Fg.n[rf],Ue=0<rf?Fg.n[rf-1]:qc;Wd=0<=Ud?qc.aj(Ue):Ue.aj(qc);je!==zg&&(Tb=this.computeAngle(sd,je,Wd),sd.angle=Tb);qf=qc.x+(Ue.x-qc.x)*Gg;Vd=qc.y+(Ue.y-qc.y)*Gg}var ke=t.oh();ke.reset();ke.scale(sd.scale,sd.scale);ke.rotate(sd.angle,0,0);var Ig=sd.Pa,lg=t.ik(0,0,Ig.width,Ig.height),Lc=t.K();Lc.Qt(lg,zb);ke.Sa(Lc);var le=-Lc.x,Kg=-Lc.y,Ve=pf.copy();isNaN(Ve.x)&&(Ve.x=0<=Ud?Lc.x+3:-(Lc.x+3));isNaN(Ve.y)&&(Ve.y=-(Lc.y+3));Ve.rotate(Wd);qf+=Ve.x;Vd+=Ve.y;ke.JG(lg);
var le=le+lg.x,Kg=Kg+lg.y,Yc=t.mc(qf+le,Vd+Kg);sd.move(Yc);t.B(Yc);t.B(Lc);t.hc(lg);t.We(ke)}this.bx();break;case "Grid":break;case "TableRow":case "TableColumn":t.m(this.toString()+" is not an element of a Table Panel.TableRow and TableColumn panels can only be elements of a Table Panel.");break;default:t.m("Unknown panel type: "+v)}t.hc(k)}};
y.prototype.Uj=function(a){var b=this.Pa;if(mb(0,0,b.width,b.height,a.x,a.y)){for(var c=this.xa.length,b=t.mc(0,0);c--;){var d=this.xa.n[0];if(d.visible||d===this.kc)if(Qa(b.set(a),d.transform),d.Fa(b))return t.B(b),!0}t.B(b);return null===this.Mb&&null===this.vc?!1:!0}return!1};function Vl(a,b,c){c(a,b);if(b instanceof y){b=b.xa.n;for(var d=b.length,f=0;f<d;f++)Vl(a,b[f],c)}}function bj(a,b){Wl(a,a,b)}
function Wl(a,b,c){c(b);b=b.xa.n;for(var d=b.length,f=0;f<d;f++){var g=b[f];g instanceof y&&Wl(a,g,c)}}y.prototype.walkVisualTree=function(a){Xl(this,this,a)};function Xl(a,b,c){c(b);if(b instanceof y){b=b.xa.n;for(var d=b.length,f=0;f<d;f++)Xl(a,b[f],c)}}y.prototype.findInVisualTree=y.prototype.bt=function(a){return Yl(this,this,a)};function Yl(a,b,c){if(c(b))return b;if(b instanceof y){b=b.xa.n;for(var d=b.length,f=0;f<d;f++){var g=Yl(a,b[f],c);if(null!==g)return g}}return null}
y.prototype.findObject=y.prototype.oe=function(a){if(this.name===a)return this;for(var b=this.xa.n,c=b.length,d=0;d<c;d++){var f=b[d];if(f.name===a)return f;if(f instanceof y)if(null===f.yi&&null===f.hg){if(f=f.oe(a),null!==f)return f}else if(Zl(f)&&(f=f.xa.$a(),null!==f&&(f=f.oe(a),null!==f)))return f}return null};
function $l(a){a=a.xa.n;for(var b=a.length,c=0,d=0;d<b;d++){var f=a[d];if(f instanceof y)c=Math.max(c,$l(f));else if(f instanceof V){a:{if(!f.ah)switch(f.Pn){case "None":case "Square":case "Ellipse":case "Circle":case "LineH":case "LineV":case "FramedRectangle":case "RoundedRectangle":case "Line1":case "Line2":case "Border":case "Cube1":case "Cube2":case "Junction":case "Cylinder1":case "Cylinder2":case "Cylinder3":case "Cylinder4":case "PlusLine":case "XLine":case "ThinCross":case "ThickCross":f=0;
break a}f=f.bh/2*f.ym*f.ek()}c=Math.max(c,f)}}return c}aa=y.prototype;aa.Hf=function(){return!(this.type===zk||this.type===Ak)};
aa.pe=function(a,b,c){if(!1===this.Bf)return null;void 0===b&&(b=null);void 0===c&&(c=null);if(Ri(this))return null;var d=this.Pa,f=1/this.ek(),g=this.Hf(),h=g?a:Qa(t.mc(a.x,a.y),this.transform);if(mb(-(5*f),-(5*f),d.width+10*f,d.height+10*f,h.x,h.y)){if(!this.Vg){for(var k=this.xa.length,f=t.K();k--;){var l=this.xa.n[k];if(l.visible||l===this.kc){l.Hf()?Qa(f.set(a),l.transform):f.set(a);var m=null;l instanceof y?m=l.pe(f,b,c):!0===l.Bf&&l.Fa(f)&&(m=l);if(null!==m&&(null!==b&&(m=b(m)),m&&(null===
c||c(m))))return t.B(f),g||t.B(h),m}}t.B(f)}if(null===this.background&&null===this.$k)return g||t.B(h),null;a=mb(0,0,d.width,d.height,h.x,h.y)?this:null;g||t.B(h);return a}g||t.B(h);return null};
aa.cp=function(a,b,c,d){if(!1===this.Bf)return!1;void 0===b&&(b=null);void 0===c&&(c=null);d instanceof B||d instanceof ma||(d=new B(Q));var f=this.Pa,g=this.Hf(),h=g?a:Qa(t.mc(a.x,a.y),this.transform);if(mb(0,0,f.width,f.height,h.x,h.y)){if(!this.Vg){for(var k=this.xa.length,f=t.K();k--;){var l=this.xa.n[k];if(l.visible||l===this.kc)l.Hf()?Qa(f.set(a),l.transform):f.set(a),(l instanceof y?l.cp(f,b,c,d):l.Fa(f))&&!1!==l.Bf&&(null!==b&&(l=b(l)),l&&(null===c||c(l))&&d.add(l))}t.B(f)}g||t.B(h);return null!==
this.background||null!==this.$k}g||t.B(h);return!1};
aa.bk=function(a,b,c,d,f,g){if(!1===this.Bf)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var h=g;void 0===g&&(h=t.oh(),h.reset());h.multiply(this.transform);if(this.Km(a,h))return am(this,b,c,f),void 0===g&&t.We(h),!0;if(this.Tf(a,h)){if(!this.Vg)for(var k=this.xa.length;k--;){var l=this.xa.n[k];if(l.visible||l===this.kc){var m=l.oa,n=this.Pa;if(!(m.x>n.width||m.y>n.height||0>m.x+m.width||0>m.y+m.height)){m=t.oh();m.set(h);if(l instanceof y?l.bk(a,b,c,d,f,m):pk(l,a,d,m))null!==b&&(l=b(l)),l&&
(null===c||c(l))&&f.add(l);t.We(m)}}}void 0===g&&t.We(h);return d}void 0===g&&t.We(h);return!1};function am(a,b,c,d){for(var f=a.xa.length;f--;){var g=a.xa.n[f];if(g.visible){var h=g.oa,k=a.Pa;h.x>k.width||h.y>k.height||0>h.x+h.width||0>h.y+h.height||(g instanceof y&&am(g,b,c,d),null!==b&&(g=b(g)),g&&(null===c||c(g))&&d.add(g))}}}
aa.Qm=function(a,b,c,d,f,g){if(!1===this.Bf)return!1;void 0===c&&(c=null);void 0===d&&(d=null);var h=this.Pa,k=this.Hf(),l=k?a:Qa(t.mc(a.x,a.y),this.transform),m=k?b:Qa(t.mc(b.x,b.y),this.transform),n=l.Zj(m),p=0<l.x&&l.x<h.width&&0<l.y&&l.y<h.height||Ra(l.x,l.y,0,0,0,h.height)<n||Ra(l.x,l.y,0,h.height,h.width,h.height)<n||Ra(l.x,l.y,h.width,h.height,h.width,0)<n||Ra(l.x,l.y,h.width,0,0,0)<n,h=0<l.x&&l.x<h.width&&0<l.y&&l.y<h.height&&Ra(l.x,l.y,0,0,0,h.height)<n&&Ra(l.x,l.y,0,h.height,h.width,h.height)<
n&&Ra(l.x,l.y,h.width,h.height,h.width,0)<n&&Ra(l.x,l.y,h.width,0,0,0)<n;k||(t.B(l),t.B(m));if(p){if(!this.Vg){k=t.K();l=t.K();for(m=this.xa.length;m--;)if(n=this.xa.n[m],n.visible||n===this.kc){var q=n.oa,r=this.Pa;q.x>r.width||q.y>r.height||0>q.x+q.width||0>q.y+q.height||(n.Hf()?(q=n.transform,Qa(k.set(a),q),Qa(l.set(b),q)):(k.set(a),l.set(b)),n instanceof y?!n.Qm(k,l,c,d,f,g):!n.AE(k,l,f))||(null!==c&&(n=c(n)),n&&(null===d||d(n))&&g.add(n))}t.B(k);t.B(l)}return f?p:h}return!1};
function Ol(a){var b=a.G;if(void 0===b||b===xb)b=null;null===b&&a instanceof V&&(a=a.Ta,null!==a&&(b=a.G));null===b&&(b=Eb);return b}function Pl(a){var b=a.H;if(void 0===b||b===xb)b=null;null===b&&a instanceof V&&(a=a.Ta,null!==a&&(b=a.H));null===b&&(b=Pb);return b}y.prototype.add=y.prototype.add=function(a){t.l(a,Q,y,"add:element");this.Jd(this.xa.count,a)};y.prototype.elt=y.prototype.wa=function(a){return this.xa.wa(a)};
y.prototype.insertAt=y.prototype.Jd=function(a,b){b instanceof C&&t.m("Cannot add a Part to a Panel: "+b);if(this===b||this.ej(b))this===b&&t.m("Cannot make a Panel contain itself: "+this.toString()),t.m("Cannot make a Panel indirectly contain itself: "+this.toString()+" already contains "+b.toString());var c=b.ja;null!==c&&c!==this&&t.m("Cannot add a GraphObject that already belongs to another Panel to this Panel: "+b.toString()+", already contained by "+c.toString()+", cannot be shared by this Panel: "+
this.toString());this.da!==yi||b instanceof V||t.m("Can only add Shapes to a Grid Panel, not: "+b);b.xl(this);b.nm=null;if(null!==this.xA){var d=b.data;null!==d&&"object"===typeof d&&(null===this.gg&&(this.gg=new ia(Object,y)),this.gg.add(d,b))}var f=this.xa,d=-1;if(c===this){for(var g=-1,h=f.count,k=0;k<h;k++)if(f.n[k]===b){g=k;break}if(-1!==g){if(g===a||g+1>=f.count&&a>=f.count)return;f.qd(g);d=g}else t.m("element "+b.toString()+" has panel "+c.toString()+" but is not contained by it.")}if(0>a||
a>f.count)a=f.count;f.Jd(a,b);this.ha();b.ha();null!==b.Nd?this.Eh=!0:b instanceof y&&!0===b.Eh&&(this.Eh=!0);c=this.S;null!==c&&(c.Fj=null,c.Cj=NaN,this.Eh&&c instanceof R&&(c.Eh=!0),c.Eh&&(c.Rd=null),f=this.h,null!==f&&f.la.vb||(-1!==d&&c.Ic(xd,"elements",this,b,null,d,null),c.Ic(wd,"elements",this,null,b,null,a)))};y.prototype.remove=y.prototype.remove=function(a){t.l(a,Q,y,"remove:element");for(var b=this.xa,c=b.count,d=-1,f=0;f<c;f++)if(b.n[f]===a){d=f;break}-1!==d&&this.Xe(d)};
y.prototype.removeAt=y.prototype.qd=function(a){e&&t.q(a,y,"removeAt:idx");0<=a&&this.Xe(a)};y.prototype.Xe=function(a){var b=this.xa,c=b.wa(a);c.nm=null;c.xl(null);if(null!==this.gg){var d=c.data;"object"===typeof d&&this.gg.remove(d)}b.qd(a);rk(this,!1);this.ha();b=this.S;null!==b&&(b.Fj=null,b.Cj=NaN,d=this.h,null!==d&&d.la.vb||b.Ic(xd,"elements",this,c,null,a,null))};t.A(y,{Mt:"rowCount"},function(){return void 0===this.kd?0:this.kd.length});
y.prototype.getRowDefinition=y.prototype.wd=function(a){if(void 0===this.kd)return null;e&&t.q(a,y,"getRowDefinition:idx");0>a&&t.ka(a,">= 0",y,"getRowDefinition:idx");a=Math.round(a);var b=this.kd;if(void 0===b[a]){var c=new Ae;c.xl(this);c.Xl=!0;c.Vc=a;b[a]=c}return b[a]};
y.prototype.removeRowDefinition=y.prototype.QF=function(a){if(void 0!==this.kd){e&&t.q(a,y,"removeRowDefinition:idx");0>a&&t.ka(a,">= 0",y,"removeRowDefinition:idx");a=Math.round(a);var b=this.kd;this.Ic(xd,"coldefs",this,b[a],null,a,null);b[a]&&(b[a]=void 0);this.ha()}};t.A(y,{Vs:"columnCount"},function(){return void 0===this.dd?0:this.dd.length});
y.prototype.getColumnDefinition=y.prototype.vd=function(a){if(void 0===this.dd)return null;e&&t.q(a,y,"getColumnDefinition:idx");0>a&&t.ka(a,">= 0",y,"getColumnDefinition:idx");a=Math.round(a);var b=this.dd;if(void 0===b[a]){var c=new Ae;c.xl(this);c.Xl=!1;c.Vc=a;b[a]=c}return b[a]};
y.prototype.removeColumnDefinition=y.prototype.NF=function(a){if(void 0!==this.dd){e&&t.q(a,y,"removeColumnDefinition:idx");0>a&&t.ka(a,">= 0",y,"removeColumnDefinition:idx");a=Math.round(a);var b=this.dd;this.Ic(xd,"coldefs",this,b[a],null,a,null);b[a]&&(b[a]=void 0);this.ha()}};t.g(y,"rowSizing",y.prototype.dG);
t.defineProperty(y,{dG:"rowSizing"},function(){return void 0===this.sm?Jl:this.sm},function(a){if(void 0!==this.sm){var b=this.sm;b!==a&&(a!==Jl&&a!==Sl&&t.m("rowSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None"),this.sm=a,this.ha(),this.j("rowSizing",b,a))}});t.g(y,"columnSizing",y.prototype.YD);
t.defineProperty(y,{YD:"columnSizing"},function(){return void 0===this.Ll?Jl:this.Ll},function(a){if(void 0!==this.Ll){var b=this.Ll;b!==a&&(a!==Jl&&a!==Sl&&t.m("columnSizing must be RowColumnDefinition.ProportionalExtra or RowColumnDefinition.None"),this.Ll=a,this.ha(),this.j("columnSizing",b,a))}});t.g(y,"topIndex",y.prototype.bK);
t.defineProperty(y,{bK:"topIndex"},function(){return void 0===this.Pi?0:this.Pi},function(a){if(void 0!==this.Pi){var b=this.Pi;b!==a&&((!isFinite(a)||0>a)&&t.m("topIndex must be greater than zero and a real number. Was "+a),this.Pi=a,this.ha(),this.j("topIndex",b,a))}});t.g(y,"leftIndex",y.prototype.iJ);
t.defineProperty(y,{iJ:"leftIndex"},function(){return void 0===this.zi?0:this.zi},function(a){if(void 0!==this.zi){var b=this.zi;b!==a&&((!isFinite(a)||0>a)&&t.m("leftIndex must be greater than zero and a real number. Was "+a),this.zi=a,this.ha(),this.j("leftIndex",b,a))}});y.prototype.findRowForLocalY=function(a){if(0>a)return-1;if(this.type!==Nk)return NaN;for(var b=0,c=this.kd,d=c.length,f=this.Pi;f<d;f++){var g=c[f];if(void 0!==g&&(b+=g.total,a<b))return f}return-1};
y.prototype.findColumnForLocalX=function(a){if(0>a)return-1;if(this.type!==Nk)return NaN;for(var b=0,c=this.dd,d=c.length,f=this.zi;f<d;f++){var g=c[f];if(void 0!==g&&(b+=g.total,a<b))return f}return-1};t.g(y,"data",y.prototype.data);
t.defineProperty(y,{data:"data"},function(){return this.Ol},function(a){var b=this.Ol;if(b!==a){var c=this instanceof C&&!(this instanceof Je);c&&t.i(a,"object",y,"data");Le(this);this.Ol=a;var d=this.h;null!==d&&(c?this instanceof T?(null!==b&&d.Ak.remove(b),null!==a&&d.Ak.add(a,this)):(null!==b&&d.ri.remove(b),null!==a&&d.ri.add(a,this)):(c=this.ja,null!==c&&null!==c.gg&&(null!==b&&c.gg.remove(b),null!==a&&c.gg.add(a,this))));this.j("data",b,a);null!==d&&d.la.vb||null!==a&&this.Kb()}});
t.g(y,"itemIndex",y.prototype.hF);t.defineProperty(y,{hF:"itemIndex"},function(){return this.hr},function(a){var b=this.hr;b!==a&&(this.hr=a,this.j("itemIndex",b,a))});function Wk(a){a=a.Jl;return null!==a&&a.nb}
function Le(a){var b=a.Jl;if(null===b)null!==a.data&&t.m("Template cannot have .data be non-null: "+a),a.Jl=b=new B(De);else if(b.nb)return;var c=new B(Q);Vl(a,a,function(a,d){var f=d.Kc;if(null!==f){Qk(d,!1);for(var g=f.k;g.next();){f=g.value;f.mode===Fe&&Qk(d,!0);var h=f.Ut;if(null!==h){var q=a;""!==h&&(q=a.oe(h));null!==q&&(c.add(q),void 0===q.rs&&(q.rs=new B(De)),q.rs.add(f))}b.add(f)}}if(d instanceof y&&d.type===Nk){if(0<d.kd.length)for(g=d.kd,h=g.length,q=0;q<h;q++){var r=g[q];if(void 0!==r&&
null!==r.Kc)for(var s=r.Kc.k;s.next();)f=s.value,f.pg=r,f.xz=2,f.Qv=r.index,b.add(f)}if(0<d.dd.length)for(g=d.dd,h=g.length,q=0;q<h;q++)if(r=g[q],void 0!==r&&null!==r.Kc)for(s=r.Kc.k;s.next();)f=s.value,f.pg=r,f.xz=1,f.Qv=r.index,b.add(f)}});for(var d=c.k;d.next();){var f=d.value;if(void 0!==f.rs){Qk(f,!0);for(var g=f.rs.k;g.next();){var h=g.value;null===f.Kc&&(f.Kc=new B(De));f.Kc.add(h)}}delete f.rs}for(d=b.k;d.next();)f=d.value,g=f.pg,null!==g&&(f.pg=null,g===a?f.nn="":(g instanceof Ae&&(g=g.ja),
f.nn=""!==g.name?g.name:g.name="_"+t.od(g)));b.freeze();a instanceof C&&a.Ld()&&(ih(a,Infinity,Infinity),a.Lc())}
y.prototype.updateTargetBindings=y.prototype.Kb=function(a){var b=this.Jl;if(null!==b)for(void 0===a&&(a=""),b=b.k;b.next();){var c=b.value,d=c.eB;if(""===a||""===d||d===a)if(d=c.Wt,null!==c.bE||""!==d){var d=this.data,f=c.Ut;null!==f&&(d=this.oe(f));if(null===d)e&&t.trace("Binding error: missing GraphObject named "+f+" in "+this.toString());else{var f=this,g=c.nn;if(""!==g){if(f=this.oe(g),null===f)continue}else null!==c.pg&&(f=c.pg);g=c.xz;if(0!==g){if(!(f instanceof y))continue;1===g?f=f.vd(c.Qv):
2===g&&(f=f.wd(c.Qv))}void 0!==f&&c.PG(f,d)}}}};t.g(y,"itemArray",y.prototype.xA);t.defineProperty(y,{xA:"itemArray"},function(){return this.yi},function(a){var b=this.yi;if(b!==a){e&&null!==a&&!t.isArray(a)&&t.m("Panel.itemArray must be an Array-like object or null, not: "+a);var c=this.h;null!==c&&null!==b&&fj(c,this);this.yi=a;null!==c&&null!==a&&cj(c,this);this.j("itemArray",b,a);null!==c&&c.la.vb||this.QA()}});function Zl(a){return a.type===Tg||a.type===ci||a.type===wg}
y.prototype.rebuildItemElements=y.prototype.QA=function(){var a=0;for(Zl(this)&&(a=1);this.xa.length>a;)this.Xe(a);a=this.xA;if(null!==a)for(var b=t.ub(a),c=0;c<b;c++)uj(this,t.lb(a,c),c)};function uj(a,b,c){if(void 0!==b&&null!==b){var d=a.getCategoryForItemData(b,c),d=a.findTemplateForItemData(b,c,d);d instanceof y&&(Le(d),d=d.copy(),"object"===typeof b&&(null===a.gg&&(a.gg=new ia(Object,y)),a.gg.add(b,d)),a.type!==Tg&&a.type!==ci||c++,a.Jd(c,d),bm(d,c),vj(a,c),d.data=b)}}
function vj(a,b){for(var c=a.xa,d=b+1;d<c.length;){var f=c.wa(d);f instanceof y&&bm(f,d);d++}}function bm(a,b){a.type===zk?a.lc=b:a.type===Ak&&(a.column=b);a.hF=b}t.g(y,"itemTemplate",y.prototype.dJ);
t.defineProperty(y,{dJ:"itemTemplate"},function(){return null===this.hg?null:this.hg.za("")},function(a){if(null===this.hg){if(null===a)return;this.hg=new ia("string",y)}var b=this.hg.za("");b!==a&&(t.l(a,y,y,"itemTemplate"),a instanceof C&&t.m("itemTemplate must not be a Part: "+a),this.hg.add("",a),this.j("itemTemplate",b,a),a=this.h,null!==a&&a.la.vb||this.QA())});t.g(y,"itemTemplateMap",y.prototype.iF);
t.defineProperty(y,{iF:"itemTemplateMap"},function(){return this.hg},function(a){var b=this.hg;if(b!==a){t.l(a,ia,y,"itemTemplateMap");for(var c=a.k;c.next();){var d=c.value;d instanceof C&&t.m("Template in itemTemplateMap must not be a Part: "+d)}this.hg=a;this.j("itemTemplateMap",b,a);a=this.h;null!==a&&a.la.vb||this.QA()}});t.g(y,"itemCategoryProperty",y.prototype.cJ);
t.defineProperty(y,{cJ:"itemCategoryProperty"},function(){return this.eo},function(a){var b=this.eo;b!==a&&("string"!==typeof a&&"function"!==typeof a&&t.bc(a,"string or function",y,"itemCategoryProperty"),this.eo=a,this.j("itemCategoryProperty",b,a))});
y.prototype.getCategoryForItemData=function(a){if(null===a)return"";var b=this.eo;if("function"===typeof b)b=b(a);else if("string"===typeof b&&"object"===typeof a){if(""===b)return"";b=t.ib(a,b)}else return"";if(void 0===b)return"";if("string"===typeof b)return b;t.m("Panel.getCategoryForItemData found a non-string category for "+a+": "+b);return""};
y.prototype.findTemplateForItemData=function(a,b,c){a=this.iF;b=null;null!==a&&(b=a.za(c));null===b&&(t.UG||(t.UG=!0,t.trace('No item template Panel found for category "'+c+'" on '+this),t.trace("  Using default item template."),c=new y,a=new na,a.bind(new De("text","",yd)),c.add(a),t.mE=c),b=t.mE);return b instanceof y?b:null};t.g(y,"isAtomic",y.prototype.VI);
t.defineProperty(y,{VI:"isAtomic"},function(){return this.Vg},function(a){var b=this.Vg;b!==a&&(t.i(a,"boolean",y,"isAtomic"),this.Vg=a,this.j("isAtomic",b,a))});function Ae(){t.Ac(this);this.Ii=null;this.Xl=!0;this.Vc=0;this.Mf=NaN;this.hm=0;this.gm=Infinity;this.ue=xb;this.Ra=this.Lb=0;this.Kc=null;this.os=cm;this.Oh=gk;this.ls=this.Mj=null;this.ms=NaN;this.Mb=this.Kh=null;this.zq=!1}t.ga("RowColumnDefinition",Ae);
Ae.prototype.copy=function(){var a=new Ae;a.Xl=this.Xl;a.Vc=this.Vc;a.Mf=this.Mf;a.hm=this.hm;a.gm=this.gm;a.ue=this.ue;a.Lb=this.Lb;a.Ra=this.Ra;a.Oh=this.Oh;a.os=this.os;a.Mj=null===this.Mj?null:this.Mj.Z();a.ls=this.ls;a.ms=this.ms;a.Kh=null;this.Kh&&(a.Kh=this.Kh.slice(0));a.Mb=this.Mb;a.zq=this.zq;a.Kc=this.Kc;return a};
function jj(a,b){t.l(b,Ae,Ae,"copyFrom:pd");b.xf?a.height=b.height:a.width=b.width;a.di=b.di;a.zf=b.zf;a.alignment=b.alignment;a.St=b.St;a.Mj=null===b.Mj?null:b.Mj.Z();a.jn=b.jn;a.kn=b.kn;a.Kh=null;b.Kh&&(a.Kh=b.Kh.slice(0));a.background=b.background;a.tw=b.tw;a.Kc=b.Kc}Ae.prototype.toString=function(){return"RowColumnDefinition "+(this.xf?"(Row ":"(Column ")+this.index+") #"+t.od(this)};var cm;Ae.Default=cm=t.w(Ae,"Default",0);var Sl;Ae.None=Sl=t.w(Ae,"None",1);var Jl;
Ae.ProportionalExtra=Jl=t.w(Ae,"ProportionalExtra",2);Ae.prototype.xl=function(a){this.Ii=a};function Ql(a,b){a.Lb=isNaN(a.Mf)?Math.max(Math.min(a.gm,b),a.hm):Math.max(Math.min(a.gm,a.Mf),a.hm)}function Ul(a){var b=a.Ii,c=a.jn;null===c&&(c=a.xf?b.si:b.uh);var d=a.kn;isNaN(d)&&(d=a.xf?b.wh:b.vh);d=null!==c?d:0;0===a.index&&(d=0);c=a.ZA;null===c&&(c=b.sj);return d+(a.xf?c.top:c.left)}
function Nl(a){var b=a.Ii,c=a.jn;null===c&&(c=a.xf?b.si:b.uh);var d=a.kn;isNaN(d)&&(d=a.xf?b.wh:b.vh);d=null!==c?d:0;0===a.index&&(d=0);c=a.ZA;null===c&&(c=b.sj);return d+(a.xf?c.top+c.bottom:c.left+c.right)}
Ae.prototype.Xc=function(a,b,c,d,f){var g=this.Ii;if(null!==g){g.ha();for(var h=this.xf,k=g.xa,l=k.length,m=0;m<l;m++){var n=k.n[m];(h?n.lc:n.column)===this.index&&n.ha()}g.Ic(vd,a,this,b,c,d,f);if(null!==this.Kc&&(b=g.ep(),null!==b&&(b=b.data,null!==b)))for(c=this.Kc.k;c.next();)c.value.nB(this,b,a,null)}};t.A(Ae,{ja:"panel"},function(){return this.Ii});t.A(Ae,{xf:"isRow"},function(){return this.Xl});t.A(Ae,{index:"index"},function(){return this.Vc});t.g(Ae,"height",Ae.prototype.height);
t.defineProperty(Ae,{height:"height"},function(){return this.Mf},function(a){var b=this.Mf;b!==a&&(e&&t.i(a,"number",Ae,"height"),0>a&&t.ka(a,">= 0",Ae,"height"),this.Mf=a,Ql(this,this.Lb),this.Xc("height",b,a))});t.g(Ae,"width",Ae.prototype.width);t.defineProperty(Ae,{width:"width"},function(){return this.Mf},function(a){var b=this.Mf;b!==a&&(e&&t.i(a,"number",Ae,"width"),0>a&&t.ka(a,">= 0",Ae,"width"),this.Mf=a,Ql(this,this.Lb),this.Xc("width",b,a))});t.g(Ae,"minimum",Ae.prototype.di);
t.defineProperty(Ae,{di:"minimum"},function(){return this.hm},function(a){var b=this.hm;b!==a&&(e&&t.i(a,"number",Ae,"minimum"),(0>a||!isFinite(a))&&t.ka(a,">= 0",Ae,"minimum"),this.hm=a,Ql(this,this.Lb),this.Xc("minimum",b,a))});t.g(Ae,"maximum",Ae.prototype.zf);t.defineProperty(Ae,{zf:"maximum"},function(){return this.gm},function(a){var b=this.gm;b!==a&&(e&&t.i(a,"number",Ae,"maximum"),0>a&&t.ka(a,">= 0",Ae,"maximum"),this.gm=a,Ql(this,this.Lb),this.Xc("maximum",b,a))});t.g(Ae,"alignment",Ae.prototype.alignment);
t.defineProperty(Ae,{alignment:"alignment"},function(){return this.ue},function(a){var b=this.ue;b.M(a)||(e&&t.l(a,H,Ae,"alignment"),this.ue=a.Z(),this.Xc("alignment",b,a))});t.g(Ae,"stretch",Ae.prototype.fi);t.defineProperty(Ae,{fi:"stretch"},function(){return this.Oh},function(a){var b=this.Oh;b!==a&&(e&&t.Aa(a,Q,Ae,"stretch"),this.Oh=a,this.Xc("stretch",b,a))});t.g(Ae,"separatorPadding",Ae.prototype.ZA);
t.defineProperty(Ae,{ZA:"separatorPadding"},function(){return this.Mj},function(a){"number"===typeof a?a=new ab(a):null!==a&&e&&t.l(a,ab,Ae,"separatorPadding");var b=this.Mj;null!==a&&null!==b&&b.M(a)||(null!==a&&(a=a.Z()),this.Mj=a,this.Xc("separatorPadding",b,a))});t.g(Ae,"separatorStroke",Ae.prototype.jn);
t.defineProperty(Ae,{jn:"separatorStroke"},function(){return this.ls},function(a){var b=this.ls;b!==a&&(null===a||"string"===typeof a||a instanceof ea)&&(a instanceof ea&&a.freeze(),this.ls=a,this.ja&&this.ja.fa(),this.Xc("separatorStroke",b,a))});t.g(Ae,"separatorStrokeWidth",Ae.prototype.kn);t.defineProperty(Ae,{kn:"separatorStrokeWidth"},function(){return this.ms},function(a){var b=this.ms;b!==a&&(this.ms=a,this.ja&&this.ja.fa(),this.Xc("separatorStrokeWidth",b,a))});
t.g(Ae,"separatorDashArray",Ae.prototype.kG);
t.defineProperty(Ae,{kG:"separatorDashArray"},function(){return this.Kh},function(a){var b=this.Kh;if(b!==a){null===a||Array.isArray(a)||t.bc(a,"Array",Ae,"separatorDashArray:val");if(null!==a){for(var c=a.length,d=0,f=0;f<c;f++){var g=a[f];"number"===typeof g&&0<=g&&isFinite(g)||t.m("separatorDashArray:val "+g+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.Kh=a;null!==this.ja&&this.ja.fa();this.Xc("separatorDashArray",b,a)}});t.g(Ae,"background",Ae.prototype.background);
t.defineProperty(Ae,{background:"background"},function(){return this.Mb},function(a){var b=this.Mb;b!==a&&(null===a||"string"===typeof a||a instanceof ea)&&(a instanceof ea&&a.freeze(),this.Mb=a,this.ja&&this.ja.fa(),this.Xc("background",b,a))});t.g(Ae,"coversSeparators",Ae.prototype.tw);t.defineProperty(Ae,{tw:"coversSeparators"},function(){return this.zq},function(a){var b=this.zq;b!==a&&(t.i(a,"boolean",Ae,"coversSeparators"),this.zq=a,this.Xc("coversSeparators",b,a))});t.g(Ae,"sizing",Ae.prototype.St);
t.defineProperty(Ae,{St:"sizing"},function(){return this.os},function(a){var b=this.os;b!==a&&(e&&t.Aa(a,Ae,Ae,"sizing"),this.os=a,this.Xc("sizing",b,a))});function Rl(a){if(a.St===cm){var b=a.Ii;return a.xf?b.dG:b.YD}return a.St}t.A(Ae,{fc:"actual"},function(){return this.Lb});t.A(Ae,{total:"total"},function(){return this.Lb+Nl(this)});t.A(Ae,{position:"position"},function(){return this.Ra});
Ae.prototype.bind=Ae.prototype.bind=function(a){a.pg=this;var b=this.ja;null!==b&&(b=b.ep(),null!==b&&Wk(b)&&t.m("Cannot add a Binding to a RowColumnDefinition that is already frozen: "+a));null===this.Kc&&(this.Kc=new B(De));this.Kc.add(a)};
function V(){Q.call(this);this.Ta=null;this.Pn="None";this.ah=!1;this.Qq=gk;this.yk=null;this.Gb=this.Uc="black";this.bh=1;this.Co="butt";this.Do="miter";this.ym=10;this.xm=null;this.ld=0;this.Ni=this.Mi=xb;this.Kr=this.Jr=0;this.Vq=!1;this.ar=!0;this.Mr=null;this.Sn=this.Fo="None";this.Uq=1}t.ga("Shape",V);t.Ka(V,Q);
V.prototype.cloneProtected=function(a){Q.prototype.cloneProtected.call(this,a);a.Ta=this.Ta;a.Pn=this.Pn;a.ah=this.ah;a.Qq=this.Qq;a.yk=this.yk;a.Uc=this.Uc;a.Gb=this.Gb;a.bh=this.bh;a.Co=this.Co;a.Do=this.Do;a.ym=this.ym;a.xm=null;this.xm&&(a.xm=this.xm.slice(0));a.ld=this.ld;a.Mi=this.Mi.Z();a.Ni=this.Ni.Z();a.Jr=this.Jr;a.Kr=this.Kr;a.Vq=this.Vq;a.ar=this.ar;a.Mr=this.Mr;a.Fo=this.Fo;a.Sn=this.Sn;a.Uq=this.Uq};
V.prototype.toString=function(){return"Shape("+("None"!==this.Ab?this.Ab:"None"!==this.on?this.on:this.Hw)+")#"+t.od(this)};
function dm(a,b,c,d){var f=0.001,g=d.Ga,h=g.width,g=g.height,k,l,m,n=c.length;if(!(2>n)){k=c[0][0];l=c[0][1];for(var p,q,r,s,u=0,v=t.Db(),D=1;D<n;D++)p=c[D],f=p[0],m=p[1],p=f-k,k=m-l,0===p&&(p=0.001),q=k/p,s=Math.atan2(k,p),k=Math.sqrt(p*p+k*k),v.push([p,s,q,k]),u+=k,k=f,l=m;k=c[0][0];l=c[0][1];c=0;f=h;n=h/2;D=0===n?!1:!0;m=0;r=v[m];p=r[0];s=r[1];q=r[2];r=r[3];for(var A=0;0.1<=u;){0===A&&(D?(f=h,c++,f-=n,u-=n,D=!1):(f=h,c++),0===f&&(f=1));if(f>u){t.ya(v);return}f>r?(A=f-r,f=r):A=0;var G=Math.sqrt(f*
f/(1+q*q));0>p&&(G=-G);k+=G;l+=q*G;a.translate(k,l);a.rotate(s);a.translate(-(h/2),-(g/2));0===A&&d.fl(a,b);a.translate(h/2,g/2);a.rotate(-s);a.translate(-k,-l);u-=f;r-=f;if(0!==A){m++;if(m===v.length){t.ya(v);return}r=v[m];p=r[0];s=r[1];q=r[2];r=r[3];f=A}}t.ya(v)}}
V.prototype.fl=function(a,b){if(null!==this.Gb||null!==this.Uc){null!==this.Uc&&Dk(this,a,this.Uc,!0);null!==this.Gb&&Dk(this,a,this.Gb,!1);var c=this.bh;if(0===c){var d=this.S;d instanceof Je&&d.type===wg&&d.pc instanceof V&&(c=d.pc.fb)}a.lineWidth=c;a.lineJoin=this.Do;a.lineCap=this.Co;a.miterLimit=this.ym;var f=!1;this.S&&b.Sk.drawShadows&&(f=this.S.gj);var g=!0;null!==this.Gb&&null===this.Uc&&(g=!1);var d=!1,h=this.zx;if(null!==h){var k=d=!0;void 0!==a.setLineDash?(a.setLineDash(h),a.lineDashOffset=
this.ld):void 0!==a.webkitLineDash?(a.webkitLineDash=h,a.webkitLineDashOffset=this.ld):void 0!==a.mozDash?(a.mozDash=h,a.mozDashOffset=this.ld):k=!1}var l=this.Ta;if(null!==l){if(l.da===Dc)a.beginPath(),d&&!k?lk(a,l.oc,l.yc,l.jb,l.tb,h,this.ld):(a.moveTo(l.oc,l.yc),a.lineTo(l.jb,l.tb)),null!==this.Uc&&Ek(a,this.Uc,!0),0!==c&&null!==this.Gb&&Ek(a,this.Gb,!1);else if(l.da===Ec){var m=l.oc,n=l.yc,p=l.jb,l=l.tb,q=Math.min(m,p),r=Math.min(n,l),m=Math.abs(p-m),n=Math.abs(l-n);null!==this.Uc&&(this.Uc instanceof
ea&&this.Uc.type===Zd?(a.beginPath(),a.rect(q,r,m,n),Ek(a,this.Uc,!0)):a.fillRect(q,r,m,n));if(null!==this.Gb){if(g&&f){var s=[a.shadowOffsetX,a.shadowOffsetY,a.shadowBlur];a.shadowOffsetX=0;a.shadowOffsetY=0;a.shadowBlur=0}d&&!k?(k=[[q,r],[q+m,r],[q+m,r+n],[q,r+n],[q,r]],a.beginPath(),em(a,k,h,this.ld),Ek(a,this.Gb,!1)):0!==c&&(this.Gb instanceof ea&&this.Gb.type===Zd?(a.beginPath(),a.rect(q,r,m,n),Ek(a,this.Gb,!1)):a.strokeRect(q,r,m,n));g&&f&&(a.shadowOffsetX=s[0],a.shadowOffsetY=s[1],a.shadowBlur=
s[2])}}else if(l.da===Mc)m=l.oc,n=l.yc,p=l.jb,l=l.tb,q=Math.abs(p-m)/2,r=Math.abs(l-n)/2,m=Math.min(m,p)+q,n=Math.min(n,l)+r,a.beginPath(),a.moveTo(m,n-r),a.bezierCurveTo(m+F.va*q,n-r,m+q,n-F.va*r,m+q,n),a.bezierCurveTo(m+q,n+F.va*r,m+F.va*q,n+r,m,n+r),a.bezierCurveTo(m-F.va*q,n+r,m-q,n+F.va*r,m-q,n),a.bezierCurveTo(m-q,n-F.va*r,m-F.va*q,n-r,m,n-r),a.closePath(),null!==this.Uc&&Ek(a,this.Uc,!0),d&&!k&&(k=t.Db(),F.ze(m,n-r,m+F.va*q,n-r,m+q,n-F.va*r,m+q,n,0.5,k),F.ze(m+q,n,m+q,n+F.va*r,m+F.va*q,n+r,
m,n+r,0.5,k),F.ze(m,n+r,m-F.va*q,n+r,m-q,n+F.va*r,m-q,n,0.5,k),F.ze(m-q,n,m-q,n-F.va*r,m-F.va*q,n-r,m,n-r,0.5,k),a.beginPath(),em(a,k,h,this.ld),t.ya(k)),0!==c&&null!==this.Gb&&(g&&f&&(s=[a.shadowOffsetX,a.shadowOffsetY,a.shadowBlur],a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),Ek(a,this.Gb,!1),g&&f&&(a.shadowOffsetX=s[0],a.shadowOffsetY=s[1],a.shadowBlur=s[2]));else if(l.da===Ac){for(var q=l.Ck,r=q.length,u=0;u<r;u++){var v=q.n[u];a.beginPath();a.moveTo(v.qa,v.ra);for(var D=v.Ea.n,A=D.length,
G=null,K=0;K<A;K++){var N=D[K];switch(N.da){case bd:a.moveTo(N.D,N.F);break;case Oc:a.lineTo(N.D,N.F);break;case cd:a.bezierCurveTo(N.jd,N.ye,N.ng,N.og,N.jb,N.tb);break;case dd:a.quadraticCurveTo(N.jd,N.ye,N.jb,N.tb);break;case ed:if(N.radiusX===N.radiusY)G=Math.PI/180,a.arc(N.jd,N.ye,N.radiusX,N.mh*G,(N.mh+N.gi)*G,0>N.gi);else for(var G=gd(N,v),O=G.length,U=0;U<O;U++){var X=G[U];0===U&&a.lineTo(X.x1,X.y1);a.bezierCurveTo(X.x2,X.y2,X.ge,X.he,X.Vb,X.Wb)}break;case fd:G&&G.type===ed?(G=gd(G,v),G=G[G.length-
1]||null,null!==G&&(m=G.Vb,p=G.Wb)):(m=G?G.D:v.qa,p=G?G.F:v.ra);G=hd(N,v,m,p);O=G.length;for(U=0;U<O;U++)X=G[U],a.bezierCurveTo(X.x2,X.y2,X.ge,X.he,X.Vb,X.Wb);break;default:t.m("Segment not of valid type")}N.Gh&&a.closePath();G=N}f?v.bo?(!0===v.Wl&&null!==this.Uc?(Ek(a,this.Uc,!0),g=!0):g=!1,0!==c&&null!==this.Gb&&(g&&(s=[a.shadowOffsetX,a.shadowOffsetY,a.shadowBlur],a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),d&&!k||Ek(a,this.Gb,!1),g&&(a.shadowOffsetX=s[0],a.shadowOffsetY=s[1],a.shadowBlur=
s[2]))):(g&&(s=[a.shadowOffsetX,a.shadowOffsetY,a.shadowBlur],a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),!0===v.Wl&&null!==this.Uc&&Ek(a,this.Uc,!0),0!==c&&null!==this.Gb&&(d&&!k||Ek(a,this.Gb,!1)),g&&(a.shadowOffsetX=s[0],a.shadowOffsetY=s[1],a.shadowBlur=s[2])):(!0===v.Wl&&null!==this.Uc&&Ek(a,this.Uc,!0),0===c||null===this.Gb||d&&!k||Ek(a,this.Gb,!1))}if(d&&!k)for(c=g,s=l.Ck,g=s.length,k=0;k<g;k++){l=s.n[k];a.beginPath();m=t.Db();m.push([l.qa,l.ra]);p=l.qa;q=l.ra;r=p;u=q;v=l.Ea.n;D=v.length;
for(A=0;A<D;A++){K=v[A];switch(K.da){case bd:em(a,m,h,this.ld);m.length=0;m.push([K.D,K.F]);p=K.D;q=K.F;r=p;u=q;break;case Oc:m.push([K.D,K.F]);p=K.D;q=K.F;break;case cd:F.ze(p,q,K.jd,K.ye,K.ng,K.og,K.jb,K.tb,0.5,m);p=K.D;q=K.F;break;case dd:F.Jp(p,q,K.jd,K.ye,K.jb,K.tb,0.5,m);p=K.D;q=K.F;break;case ed:N=gd(K,l);G=N.length;for(O=0;O<G;O++)U=N[O],F.ze(p,q,U.x2,U.y2,U.ge,U.he,U.Vb,U.Wb,0.5,m),p=U.Vb,q=U.Wb;break;case fd:N=hd(K,l,p,q);G=N.length;for(O=0;O<G;O++)U=N[O],F.ze(p,q,U.x2,U.y2,U.ge,U.he,U.Vb,
U.Wb,0.5,m),p=U.Vb,q=U.Wb;break;default:t.m("Segment not of valid type")}K.Gh&&(m.push([r,u]),em(a,m,h,this.ld))}em(a,m,h,this.ld);t.ya(m);null!==this.Gb&&(c&&f&&(n=[a.shadowOffsetX,a.shadowOffsetY,a.shadowBlur],a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),Ek(a,this.Gb,!1),c&&f&&(a.shadowOffsetX=n[0],a.shadowOffsetY=n[1],a.shadowBlur=n[2]))}}d&&(void 0!==a.setLineDash?(a.setLineDash(t.ji),a.lineDashOffset=0):void 0!==a.webkitLineDash?(a.webkitLineDash=t.ji,a.webkitLineDashOffset=0):void 0!==
a.mozDash&&(a.mozDash=null,a.mozDashOffset=0));if(this.KA){d=this.KA;ih(d,Infinity,Infinity);h=d.Ga;d.Lc(0,0,h.width,h.height);a.save();h=this.nd.Bb.$a();a.beginPath();n=t.Db();n.push([h.qa,h.ra]);c=h.qa;f=h.ra;s=c;g=f;k=h.Ea.n;l=k.length;for(m=0;m<l;m++){p=k[m];switch(p.da){case bd:dm(a,b,n,d);n.length=0;n.push([p.D,p.F]);c=p.D;f=p.F;s=c;g=f;break;case Oc:n.push([p.D,p.F]);c=p.D;f=p.F;break;case cd:F.ze(c,f,p.jd,p.ye,p.ng,p.og,p.jb,p.tb,0.5,n);c=p.D;f=p.F;break;case dd:F.Jp(c,f,p.jd,p.ye,p.jb,p.tb,
0.5,n);c=p.D;f=p.F;break;case ed:q=gd(p,h);r=q.length;for(u=0;u<r;u++)v=q[u],F.ze(c,f,v.x2,v.y2,v.ge,v.he,v.Vb,v.Wb,0.5,n),c=v.Vb,f=v.Wb;break;case fd:q=hd(p,h,c,f);r=q.length;for(u=0;u<r;u++)v=q[u],F.ze(c,f,v.x2,v.y2,v.ge,v.he,v.Vb,v.Wb,0.5,n),c=v.Vb,f=v.Wb;break;default:t.m("Segment not of valid type")}p.Gh&&(n.push([s,g]),dm(a,b,n,d))}dm(a,b,n,d);t.ya(n);a.restore()}}}};
function em(a,b,c,d){var f=0.001,g=c.length,h,k,l,m=b.length;if(!(2>m))if(h=b[0][0],k=b[0][1],2===m)lk(a,h,k,b[1][0],b[1][1],c,d);else{a.moveTo(h,k);for(var n,p,q,r=0,s=t.Db(),u=1;u<m;u++)n=b[u],f=n[0],l=n[1],n=f-h,h=l-k,0===n&&(n=0.001),p=h/n,h=Math.sqrt(n*n+h*h),s.push([n,p,h]),r+=h,h=f,k=l;h=b[0][0];k=b[0][1];b=0;m=!0;f=c[b%g];u=0!==d;l=0;q=s[l];n=q[0];p=q[1];q=q[2];for(var v=0;0.1<=r;){0===v&&(f=c[b%g],b++,u&&(d%=f,f-=d,u=!1));f>r&&(f=r);f>q?(v=f-q,f=q):v=0;var D=Math.sqrt(f*f/(1+p*p));0>n&&(D=
-D);h+=D;k+=p*D;m?a.lineTo(h,k):a.moveTo(h,k);r-=f;q-=f;if(0!==v){l++;if(l===s.length){t.ya(s);return}q=s[l];n=q[0];p=q[1];q=q[2];f=v}else m=!m}t.ya(s)}}V.prototype.getDocumentPoint=V.prototype.pb=function(a,b){void 0===b&&(b=new w);a.Ge()&&t.m("getDocumentPoint:s Spot must be real: "+a.toString());var c=this.Pa,d=this.fb;b.p(a.x*(c.width+d)-d/2+c.x+a.offsetX,a.y*(c.height+d)-d/2+c.y+a.offsetY);this.me.Sa(b);return b};
V.prototype.Uj=function(a,b){var c=this.Ta;if(null===c||null===this.fill&&null===this.stroke)return!1;var d=c.Pb,f=this.fb/2;c.type!==Dc||b||(f+=2);var g=t.Gf();g.assign(d);g.Sf(f+2,f+2);if(!g.Fa(a))return t.hc(g),!1;d=f+1E-4;if(c.type===Dc){if(null===this.stroke)return!1;d=(c.D-c.qa)*(a.x-c.qa)+(c.F-c.ra)*(a.y-c.ra);if(0>(c.qa-c.D)*(a.x-c.D)+(c.ra-c.F)*(a.y-c.F)||0>d)return!1;t.hc(g);return F.Md(c.qa,c.ra,c.D,c.F,f,a.x,a.y)}if(c.type===Ec){var h=c.qa,k=c.ra,l=c.D,m=c.F,c=Math.min(h,l),n=Math.min(k,
m),h=Math.abs(l-h),k=Math.abs(m-k);g.x=c;g.y=n;g.width=h;g.height=k;if(null===this.fill){g.Sf(-d,-d);if(g.Fa(a))return t.hc(g),!1;g.Sf(d,d)}null!==this.stroke&&g.Sf(f,f);f=g.Fa(a);t.hc(g);return f}if(c.type===Mc){h=c.qa;k=c.ra;l=c.D;m=c.F;c=Math.min(h,l);n=Math.min(k,m);h=Math.abs(l-h);k=Math.abs(m-k);h/=2;k/=2;c=a.x-(c+h);n=a.y-(n+k);if(null===this.fill){h-=d;k-=d;if(0>=h||0>=k||1>=c*c/(h*h)+n*n/(k*k))return t.hc(g),!1;h+=d;k+=d}null!==this.stroke&&(h+=f,k+=f);t.hc(g);return 0>=h||0>=k?!1:1>=c*c/
(h*h)+n*n/(k*k)}if(c.type===Ac)return t.hc(g),null===this.fill?kd(c,a.x,a.y,f):c.Fa(a,f,1<this.fb,b);t.m("Unknown Geometry type");return!1};
V.prototype.ut=function(a,b,c,d){var f=this.Ca,g=this.bh;a=Math.max(a,0);b=Math.max(b,0);var h;if(this.ah)h=this.Ta.Pb;else{var k=this.Ab,l=F.MA[k];if(void 0===l){var m=F.bj[k];"string"===typeof m&&(m=F.bj[m]);"function"===typeof m?(l=m(null,100,100),F.MA[k]=l):t.m("Unsupported Figure:"+k)}h=l.Pb}var k=h.width,l=h.height,m=h.width,n=h.height;switch(qk(this)){case Vg:d=c=0;break;case Cc:m=Math.max(a-g,0);n=Math.max(b-g,0);break;case ik:m=Math.max(a-g,0);d=0;break;case hk:c=0,n=Math.max(b-g,0)}isFinite(f.width)&&
(m=f.width);isFinite(f.height)&&(n=f.height);f=this.He;h=this.Vf;c=Math.max(c,h.width)-g;d=Math.max(d,h.height)-g;m=Math.min(f.width,m);n=Math.min(f.height,n);m=isFinite(m)?Math.max(c,m):Math.max(k,c);n=isFinite(n)?Math.max(d,n):Math.max(l,d);c=Wg(this);switch(c){case Vg:break;case Cc:k=m;l=n;break;case Xg:c=Math.min(m/k,n/l);isFinite(c)||(c=1);k*=c;l*=c;break;default:t.m(c+" is not a valid geometryStretch.")}if(this.ah){h=this.nd;d=k;f=l;c=h.copy();h=h.Pb;d/=h.width;f/=h.height;isFinite(d)||(d=1);
isFinite(f)||(f=1);if(1!==d||1!==f)switch(c.type){case Dc:case Ec:case Mc:c.qa*=d;c.ra*=f;c.D*=d;c.F*=f;break;case Ac:h=c.Bb;for(var p=h.length,q=0;q<p;q++){var r=h.n[q];r.qa*=d;r.ra*=f;for(var r=r.Ea,s=r.length,u=0;u<s;u++){var v=r.n[u];switch(v.type){case Oc:case bd:v.D*=d;v.F*=f;break;case cd:v.Cb*=d;v.Sb*=f;v.re*=d;v.se*=f;v.D*=d;v.F*=f;break;case dd:v.Cb*=d;v.Sb*=f;v.D*=d;v.F*=f;break;case ed:v.Da*=d;v.Oa*=f;v.radiusX*=d;void 0!==v.radiusY&&(v.radiusY*=f);break;case fd:v.D*=d;v.F*=f;v.radiusX*=
d;v.radiusY*=f;break;default:t.m("Unknown Segment type: "+v.type)}}}}this.Ta=c}else if(null===this.Ta||this.Ta.$n!==a-g||this.Ta.Zn!==b-g)this.Ta=F.makeGeometry(this,k,l);h=this.Ta.Pb;Infinity===a||Infinity===b?nk(this,h.x-g/2,h.y-g/2,0===a&&0===k?0:h.width+g,0===b&&0===l?0:h.height+g):nk(this,-(g/2),-(g/2),m+g,n+g)};
function Tl(a,b,c){if(!1!==Qi(a)){a.Wc.Ma();var d=a.bh;if(0===d){var f=a.S;f instanceof Je&&f.type===wg&&f.pc instanceof V&&(d=f.pc.fb)}d*=a.xc;nk(a,-(d/2),-(d/2),b+d,c+d);b=a.Wc;c=a.He;d=a.Vf;b.width=Math.min(c.width,b.width);b.height=Math.min(c.height,b.height);b.width=Math.max(d.width,b.width);b.height=Math.max(d.height,b.height);a.Wc.freeze();a.Wc.N()||t.m("Non-real measuredBounds has been set. Object "+a+", measuredBounds: "+a.Wc.toString());rk(a,!1)}}
function Wg(a){var b=a.HE;return a.ah?b===gk?Cc:b:b===gk?F.MA[a.Ab].Hc:b}V.prototype.Tj=function(a,b,c,d){uk(this,a,b,c,d)};V.prototype.getNearestIntersectionPoint=V.prototype.ll=function(a,b,c){return this.kp(a.x,a.y,b.x,b.y,c)};
V.prototype.kp=function(a,b,c,d,f){var g=this.transform,h=1/(g.m11*g.m22-g.m12*g.m21),k=g.m22*h,l=-g.m12*h,m=-g.m21*h,n=g.m11*h,p=h*(g.m21*g.dy-g.m22*g.dx),q=h*(g.m12*g.dx-g.m11*g.dy),g=a*k+b*m+p,h=a*l+b*n+q,k=c*k+d*m+p,l=c*l+d*n+q,m=this.fb/2,p=this.Ta;null===p&&(ih(this,Infinity,Infinity),p=this.Ta);q=p.Pb;n=!1;if(p.type===Dc)if(1.5>=this.fb)n=F.ih(p.oc,p.yc,p.jb,p.tb,g,h,k,l,f);else{var r,s;p.oc===p.jb?(r=m,s=0):(b=(p.tb-p.yc)/(p.jb-p.oc),s=m/Math.sqrt(1+b*b),r=s*b);d=t.Db();b=new w;F.ih(p.oc+
r,p.yc+s,p.jb+r,p.tb+s,g,h,k,l,b)&&d.push(b);b=new w;F.ih(p.oc-r,p.yc-s,p.jb-r,p.tb-s,g,h,k,l,b)&&d.push(b);b=new w;F.ih(p.oc+r,p.yc+s,p.oc-r,p.yc-s,g,h,k,l,b)&&d.push(b);b=new w;F.ih(p.jb+r,p.tb+s,p.jb-r,p.tb-s,g,h,k,l,b)&&d.push(b);b=d.length;if(0===b)return t.ya(d),!1;n=!0;s=Infinity;for(r=0;r<b;r++){var k=d[r],u=(k.x-g)*(k.x-g)+(k.y-h)*(k.y-h);u<s&&(s=u,f.x=k.x,f.y=k.y)}t.ya(d)}else if(p.type===Ec)b=q.x-m,n=F.ll(b,q.y-m,q.x+q.width+m,q.y+q.height+m,g,h,k,l,f);else if(p.type===Mc)a:if(b=q.copy().Sf(m,
m),0===b.width)n=F.ih(b.x,b.y,b.x,b.y+b.height,g,h,k,l,f);else if(0===b.height)n=F.ih(b.x,b.y,b.x+b.width,b.y,g,h,k,l,f);else{a=b.width/2;var v=b.height/2;d=b.x+a;b=b.y+v;c=9999;g!==k&&(c=(h-l)/(g-k));if(9999>Math.abs(c)){n=h-b-c*(g-d);if(0>a*a*c*c+v*v-n*n){f.x=NaN;f.y=NaN;n=!1;break a}m=Math.sqrt(a*a*c*c+v*v-n*n);k=(-(a*a*c*n)+a*v*m)/(v*v+a*a*c*c)+d;a=(-(a*a*c*n)-a*v*m)/(v*v+a*a*c*c)+d;l=c*(k-d)+n+b;b=c*(a-d)+n+b;d=Math.abs((g-k)*(g-k))+Math.abs((h-l)*(h-l));h=Math.abs((g-a)*(g-a))+Math.abs((h-b)*
(h-b));d<h?(f.x=k,f.y=l):(f.x=a,f.y=b)}else{k=v*v;l=g-d;k-=k/(a*a)*l*l;if(0>k){f.x=NaN;f.y=NaN;n=!1;break a}m=Math.sqrt(k);l=b+m;b-=m;d=Math.abs(l-h);h=Math.abs(b-h);d<h?(f.x=g,f.y=l):(f.x=g,f.y=b)}n=!0}else if(p.type===Ac){var D,A,G,q=t.K();r=k-g;s=l-h;s=r*r+s*s;f.x=k;f.y=l;for(r=0;r<p.Bb.count;r++){var K=p.Bb.n[r],N=K.Ea;D=K.qa;A=K.ra;for(var O=D,U=A,X,Z,P=0;P<N.count;P++){X=N.n[P];Z=X.type;u=X.D;G=X.F;var pa=!1;switch(Z){case bd:O=u;U=G;break;case Oc:pa=fm(D,A,u,G,g,h,k,l,q);break;case cd:pa=X.Cb;
Z=X.Sb;var Ya=X.re,za=X.se,pa=F.Ps(D,A,pa,Z,Ya,za,u,G,g,h,k,l,0.5,q);break;case dd:pa=(D+2*X.Cb)/3;Z=(A+2*X.Sb)/3;Ya=(2*X.Cb+u)/3;za=(2*X.Cb+u)/3;pa=F.Ps(D,A,pa,Z,Ya,za,u,G,g,h,k,l,0.5,q);break;case ed:case fd:G=X.type===ed?gd(X,K):hd(X,K,D,A);Z=G.length;for(Ya=0;Ya<Z;Ya++)v=G[Ya],0===Ya&&fm(D,A,v.x1,v.y1,g,h,k,l,q)&&(u=gm(g,h,q,s,f),u<s&&(s=u,n=!0)),F.Ps(v.x1,v.y1,v.x2,v.y2,v.ge,v.he,v.Vb,v.Wb,g,h,k,l,0.5,q)&&(u=gm(g,h,q,s,f),u<s&&(s=u,n=!0));u=v.Vb;G=v.Wb;break;default:t.m("Unknown Segment type: "+
X.type)}D=u;A=G;pa&&(u=gm(g,h,q,s,f),u<s&&(s=u,n=!0));X.ot&&(u=O,G=U,fm(D,A,u,G,g,h,k,l,q)&&(u=gm(g,h,q,s,f),u<s&&(s=u,n=!0)))}}g=c-a;h=d-b;b=Math.sqrt(g*g+h*h);0!==b&&(g/=b,h/=b);f.x-=g*m;f.y-=h*m;t.B(q)}else t.m("Invalid Geometry type");if(!n)return!1;this.transform.Sa(f);return!0};function gm(a,b,c,d,f){a=c.x-a;b=c.y-b;b=a*a+b*b;return b<d?(f.x=c.x,f.y=c.y,b):d}
function fm(a,b,c,d,f,g,h,k,l){var m=!1,n=(f-h)*(b-d)-(g-k)*(a-c);if(0===n)return!1;l.x=((f*k-g*h)*(a-c)-(f-h)*(a*d-b*c))/n;l.y=((f*k-g*h)*(b-d)-(g-k)*(a*d-b*c))/n;(a>c?a-c:c-a)<(b>d?b-d:d-b)?(f=b<d?b:d,a=b<d?d:b,(l.y>f||F.mb(l.y,f))&&(l.y<a||F.mb(l.y,a))&&(m=!0)):(f=a<c?a:c,a=a<c?c:a,(l.x>f||F.mb(l.x,f))&&(l.x<a||F.mb(l.x,a))&&(m=!0));return m}
V.prototype.containedInRect=V.prototype.Km=function(a,b){if(void 0===b)return a.Vj(this.oa);var c=this.Ta;null===c&&(ih(this,Infinity,Infinity),c=this.Ta);var c=c.Pb,d=this.fb/2,f=!1,g=t.K();g.p(c.x-d,c.y-d);a.Fa(b.Sa(g))&&(g.p(c.x-d,c.bottom+d),a.Fa(b.Sa(g))&&(g.p(c.right+d,c.bottom+d),a.Fa(b.Sa(g))&&(g.p(c.right+d,c.y-d),a.Fa(b.Sa(g))&&(f=!0))));t.B(g);return f};
V.prototype.intersectsRect=V.prototype.Tf=function(a,b){if(this.Km(a,b)||void 0===b&&(b=this.transform,a.Vj(this.oa)))return!0;var c=t.oh();c.set(b);c.nA();var d=a.left,f=a.right,g=a.top,h=a.bottom,k=t.K();k.p(d,g);c.Sa(k);if(this.Uj(k,!0))return t.B(k),!0;k.p(f,g);c.Sa(k);if(this.Uj(k,!0))return t.B(k),!0;k.p(d,h);c.Sa(k);if(this.Uj(k,!0))return t.B(k),!0;k.p(f,h);c.Sa(k);if(this.Uj(k,!0))return t.B(k),!0;var l=t.K(),m=t.K();c.set(b);c.DF(this.transform);c.nA();l.x=f;l.y=g;l.transform(c);k.x=d;k.y=
g;k.transform(c);var n=!1;hm(this,k,l,m)?n=!0:(k.x=f,k.y=h,k.transform(c),hm(this,k,l,m)?n=!0:(l.x=d,l.y=h,l.transform(c),hm(this,k,l,m)?n=!0:(k.x=d,k.y=g,k.transform(c),hm(this,k,l,m)&&(n=!0))));t.B(k);t.We(c);t.B(l);t.B(m);return n};function hm(a,b,c,d){if(!a.ll(b,c,d))return!1;a=b.x;b=b.y;var f=c.x,g=c.y;c=d.x;d=d.y;if(a===f){var h;b<g?(h=b,a=g):(h=g,a=b);return d>=h&&d<=a}a<f?(h=a,a=f):h=f;return c>=h&&c<=a}
V.prototype.AE=function(a,b,c){function d(a,b){for(var c=a.length,d=0;d<c;d++){var g=a[d];if(b.Zs(g[0],g[1])>f)return!0}return!1}if(c&&null!==this.fill&&this.Uj(a,!0))return!0;var f=a.Zj(b);b=f;1.5<this.fb&&(f=this.fb/2+Math.sqrt(f),f*=f);var g=this.Ta;null===g&&(ih(this,Infinity,Infinity),g=this.Ta);if(!c){var h=g.Pb,k=h.x,l=h.y,m=h.x+h.width,h=h.y+h.height;if(Sa(a.x,a.y,k,l)<=f&&Sa(a.x,a.y,m,l)<=f&&Sa(a.x,a.y,k,h)<=f&&Sa(a.x,a.y,m,h)<=f)return!0}k=g.oc;l=g.yc;m=g.jb;h=g.tb;if(g.type===Dc){if(c=
Ra(a.x,a.y,k,l,m,h),g=(k-m)*(a.x-m)+(l-h)*(a.y-h),c<=(0<=(m-k)*(a.x-k)+(h-l)*(a.y-l)&&0<=g?f:b))return!0}else{if(g.type===Ec)return b=!1,c&&(b=Ra(a.x,a.y,k,l,k,h)<=f||Ra(a.x,a.y,k,l,m,l)<=f||Ra(a.x,a.y,m,l,m,h)<=f||Ra(a.x,a.y,k,h,m,h)<=f),b;if(g.type===Mc){b=a.x-(k+m)/2;var g=a.y-(l+h)/2,n=Math.abs(m-k)/2,p=Math.abs(h-l)/2;if(0===n||0===p)return c=Ra(a.x,a.y,k,l,m,h),c<=f?!0:!1;if(c){if(a=F.yI(n,p,b,g),a*a<=f)return!0}else return Sa(b,g,-n,0)>=f||Sa(b,g,0,-p)>=f||Sa(b,g,0,p)>=f||Sa(b,g,n,0)>=f?!1:
!0}else if(g.type===Ac){h=g.Pb;k=h.x;l=h.y;m=h.x+h.width;h=h.y+h.height;if(a.x>m&&a.x<k&&a.y>h&&a.y<l&&Ra(a.x,a.y,k,l,k,h)>f&&Ra(a.x,a.y,k,l,m,l)>f&&Ra(a.x,a.y,m,h,k,h)>f&&Ra(a.x,a.y,m,h,m,l)>f)return!1;b=Math.sqrt(f);if(c){if(null===this.fill?kd(g,a.x,a.y,b):g.Fa(a,b,!0))return!0}else{c=g.Bb;for(b=0;b<c.count;b++){k=c.n[b];n=k.qa;p=k.ra;if(a.Zs(n,p)>f)return!1;l=k.Ea.n;m=l.length;for(h=0;h<m;h++){var q=l[h];switch(q.type){case bd:case Oc:n=q.D;p=q.F;if(a.Zs(n,p)>f)return!1;break;case cd:g=t.Db();
F.ze(n,p,q.Cb,q.Sb,q.re,q.se,q.D,q.F,0.8,g);n=d(g,a);t.ya(g);if(n)return!1;n=q.D;p=q.F;if(a.Zs(n,p)>f)return!1;break;case dd:g=t.Db();F.Jp(n,p,q.Cb,q.Sb,q.D,q.F,0.8,g);n=d(g,a);t.ya(g);if(n)return!1;n=q.D;p=q.F;if(a.Zs(n,p)>f)return!1;break;case ed:case fd:var q=q.type===ed?gd(q,k):hd(q,k,n,p),r=q.length,s=null,g=t.Db();for(b=0;b<r;b++)if(s=q[b],g.length=0,F.ze(s.x1,s.y1,s.x2,s.y2,s.ge,s.he,s.Vb,s.Wb,0.8,g),d(g,a))return t.ya(g),!1;t.ya(g);null!==s&&(n=s.Vb,p=s.Wb);break;default:t.m("Unknown Segment type: "+
q.type)}}}return!0}}}return!1};t.g(V,"geometry",V.prototype.nd);t.defineProperty(V,{nd:"geometry"},function(){return this.yk?this.yk:this.Ta},function(a){var b=this.Ta;if(b!==a){null!==a?(e&&t.l(a,zc,V,"geometry"),this.yk=this.Ta=a.freeze()):this.yk=this.Ta=null;var c=this.S;c&&(c.Cj=NaN);this.ah=!0;this.ha();this.j("geometry",b,a)}});t.g(V,"geometryString",V.prototype.IE);
t.defineProperty(V,{IE:"geometryString"},function(){return this.nd.toString()},function(a){var b=Rc(a);a=b.normalize();this.nd=b;var b=t.K(),c=this.position;c.N()?b.p(c.x-a.x,c.y-a.y):b.p(-a.x,-a.y);this.position=b;t.B(b)});t.defineProperty(V,{rA:"isGeometryPositioned"},function(){return this.Vq},function(a){e&&t.i(a,"boolean",V,"isGeometryPositioned");var b=this.Vq;b!==a&&(this.Vq=a,this.ha(),this.j("isGeometryPositioned",b,a))});V.prototype.Ye=function(){this.ah?this.yk=null:this.Ta=null;this.ha()};
t.g(V,"fill",V.prototype.fill);t.defineProperty(V,{fill:"fill"},function(){return this.Uc},function(a){var b=this.Uc;b!==a&&(e&&null!==a&&t.To(a,"Shape.fill"),a instanceof ea&&a.freeze(),this.Uc=a,this.fa(),this.j("fill",b,a))});t.g(V,"stroke",V.prototype.stroke);t.defineProperty(V,{stroke:"stroke"},function(){return this.Gb},function(a){var b=this.Gb;b!==a&&(e&&null!==a&&t.To(a,"Shape.stroke"),a instanceof ea&&a.freeze(),this.Gb=a,this.fa(),this.j("stroke",b,a))});t.g(V,"strokeWidth",V.prototype.fb);
t.defineProperty(V,{fb:"strokeWidth"},function(){return this.bh},function(a){var b=this.bh;if(b!==a)if(e&&t.q(a,V,"strokeWidth"),0<=a){this.bh=a;this.ha();var c=this.S;c&&(c.Cj=NaN);this.j("strokeWidth",b,a)}else t.ka(a,"val >= 0",V,"strokeWidth:val")});t.g(V,"strokeCap",V.prototype.wG);
t.defineProperty(V,{wG:"strokeCap"},function(){return this.Co},function(a){var b=this.Co;b!==a&&("string"!==typeof a||"butt"!==a&&"round"!==a&&"square"!==a?t.ka(a,'"butt", "round", or "square"',V,"strokeCap"):(this.Co=a,this.fa(),this.j("strokeCap",b,a)))});t.g(V,"strokeJoin",V.prototype.WJ);
t.defineProperty(V,{WJ:"strokeJoin"},function(){return this.Do},function(a){var b=this.Do;b!==a&&("string"!==typeof a||"miter"!==a&&"bevel"!==a&&"round"!==a?t.ka(a,'"miter", "bevel", or "round"',V,"strokeJoin"):(this.Do=a,this.fa(),this.j("strokeJoin",b,a)))});t.g(V,"strokeMiterLimit",V.prototype.XJ);
t.defineProperty(V,{XJ:"strokeMiterLimit"},function(){return this.ym},function(a){var b=this.ym;if(b!==a)if(e&&t.q(a,V,"strokeMiterLimit"),0<a){this.ym=a;this.fa();var c=this.S;c&&(c.Cj=NaN);this.j("strokeMiterLimit",b,a)}else t.ka(a,"val > 0",V,"strokeWidth:val")});t.g(V,"strokeDashArray",V.prototype.zx);
t.defineProperty(V,{zx:"strokeDashArray"},function(){return this.xm},function(a){var b=this.xm;if(b!==a){null===a||Array.isArray(a)||t.bc(a,"Array",V,"strokeDashArray:val");if(null!==a){for(var c=a.length,d=0,f=0;f<c;f++){var g=a[f];"number"===typeof g&&0<=g&&isFinite(g)||t.m("strokeDashArray:val "+g+" must be a positive number or zero.");d+=g}if(0===d){if(null===b)return;a=null}}this.xm=a;this.fa();this.j("strokeDashArray",b,a)}});t.g(V,"strokeDashOffset",V.prototype.xG);
t.defineProperty(V,{xG:"strokeDashOffset"},function(){return this.ld},function(a){var b=this.ld;b!==a&&(e&&t.q(a,V,"strokeDashOffset"),0<=a&&(this.ld=a,this.fa(),this.j("strokeDashOffset",b,a)))});t.g(V,"figure",V.prototype.Ab);
t.defineProperty(V,{Ab:"figure"},function(){return this.Pn},function(a){var b=this.Pn;if(b!==a){e&&t.i(a,"string",V,"figure");var c=F.bj[a];"function"===typeof c?c=a:(c=F.bj[a.toLowerCase()])||t.m("Unknown Shape.figure: "+a);if(b!==c){if(a=this.S)a.Cj=NaN;this.Pn=c;this.ah=!1;this.Ye();this.j("figure",b,c)}}});t.g(V,"toArrow",V.prototype.on);
t.defineProperty(V,{on:"toArrow"},function(){return this.Fo},function(a){var b=this.Fo;!0===a?a="Standard":!1===a&&(a="");if(b!==a){e&&t.i(a,"string",V,"toArrow");var c=F.RD(a);null===c?t.m("Unknown Shape.toArrow: "+a):b!==c&&(this.Fo=c,this.ah=!1,this.Ye(),im(this),this.j("toArrow",b,c))}});t.g(V,"fromArrow",V.prototype.Hw);
t.defineProperty(V,{Hw:"fromArrow"},function(){return this.Sn},function(a){var b=this.Sn;!0===a?a="Standard":!1===a&&(a="");if(b!==a){e&&t.i(a,"string",V,"fromArrow");var c=F.RD(a);null===c?t.m("Unknown Shape.fromArrow: "+a):b!==c&&(this.Sn=c,this.ah=!1,this.Ye(),im(this),this.j("fromArrow",b,c))}});function im(a){var b=a.h;null!==b&&b.la.vb||(a.Ot=jm,"None"!==a.Fo?(a.Cf=-1,a.Qj=Wb):"None"!==a.Sn&&(a.Cf=0,a.Qj=new H(1-Wb.x,Wb.y)))}
t.defineProperty(V,{G:"spot1"},function(){return this.Mi},function(a){t.l(a,H,V,"spot1");var b=this.Mi;b.M(a)||(this.Mi=a=a.Z(),this.ha(),this.j("spot1",b,a))});t.defineProperty(V,{H:"spot2"},function(){return this.Ni},function(a){t.l(a,H,V,"spot2");var b=this.Ni;b.M(a)||(this.Ni=a=a.Z(),this.ha(),this.j("spot2",b,a))});t.defineProperty(V,{Gc:"parameter1"},function(){return this.Jr},function(a){var b=this.Jr;b!==a&&(this.Jr=a,this.ha(),this.j("parameter1",b,a))});
t.defineProperty(V,{Ft:"parameter2"},function(){return this.Kr},function(a){var b=this.Kr;b!==a&&(this.Kr=a,this.ha(),this.j("parameter2",b,a))});t.A(V,{Pa:"naturalBounds"},function(){if(null!==this.Ta)return this.hd.assign(this.Ta.Pb),this.hd;var a=this.Ca;return new x(0,0,a.width,a.height)});t.g(V,"isRectangular",V.prototype.ZI);
t.defineProperty(V,{ZI:"isRectangular"},function(){return this.ar},function(a){var b=this.ar;b!==a&&(e&&t.i(a,"boolean",V,"isRectangular"),this.ar=a,this.ha(),this.j("isRectangular",b,a))});t.g(V,"pathObject",V.prototype.KA);t.defineProperty(V,{KA:"pathObject"},function(){return this.Mr},function(a){var b=this.Mr;b!==a&&(e&&t.l(a,Q,V,"pathObject"),this.Mr=a,this.fa(),this.j("pathObject",b,a))});t.g(V,"geometryStretch",V.prototype.HE);
t.defineProperty(V,{HE:"geometryStretch"},function(){return this.Qq},function(a){var b=this.Qq;b!==a&&(t.Aa(a,Q,V,"geometryStretch"),this.Qq=a,this.j("geometryStretch",b,a))});t.g(V,"interval",V.prototype.interval);t.defineProperty(V,{interval:"interval"},function(){return this.Uq},function(a){var b=this.Uq;e&&t.q(a,V,"interval");a=Math.floor(a);b!==a&&0<=a&&(this.Uq=a,this.h&&Li(this.h),this.ha(),this.j("interval",b,a))});
V.defineFigureGenerator=function(a,b){t.i(a,"string",V,"defineFigureGenerator:name");t.i(b,"function",V,"defineFigureGenerator:func");var c=a.toLowerCase();""!==a&&"none"!==c&&a!==c||t.m("Shape.defineFigureGenerator name must not be empty or None or all-lower-case: "+a);var d=V.FigureGenerators;d[a]=b;d[c]=a};
V.defineArrowheadGeometry=function(a,b){t.i(a,"string",V,"defineArrowheadGeometry:name");t.i(b,"string",V,"defineArrowheadGeometry:pathstr");var c=a.toLowerCase();""!==a&&"none"!==c&&a!==c||t.m("Shape.defineArrowheadGeometry name must not be empty or None or all-lower-case: "+a);var d=V.ArrowheadGeometries;d[a]=b;d[c]=a};
function na(){Q.call(this);this.le="";this.Gb="black";this.Tg="13px sans-serif";this.Td="start";this.Yq=!0;this.Yl=this.Zl=!1;this.Qk=km;this.zm=lm;this.dv=this.mf=0;this.Rn=this.ez=this.fz=null;this.no={};this.Kq=!1;this.kf=this.Tk=this.xs=null;this.Pj=this.Oj=0;this.Ai=Infinity}t.ga("TextBlock",na);t.Ka(na,Q);na.getEllipsis=function(){return t.$s};na.setEllipsis=function(a){t.$s=a;t.Gw={};t.fA=0};
na.prototype.cloneProtected=function(a){Q.prototype.cloneProtected.call(this,a);a.le=this.le;a.Gb=this.Gb;a.Tg=this.Tg;a.Td=this.Td;a.Yq=this.Yq;a.Zl=this.Zl;a.Yl=this.Yl;a.zm=this.zm;a.Qk=this.Qk;a.mf=this.mf;a.dv=this.dv;a.fz=this.fz;a.ez=this.ez;a.Rn=this.Rn;a.no=this.no;a.Kq=this.Kq;a.xs=this.xs;a.Tk=this.Tk;a.kf=this.kf;a.Oj=this.Oj;a.Pj=this.Pj;a.Ai=this.Ai};na.prototype.toString=function(){return 22<this.le.length?'TextBlock("'+this.le.substring(0,20)+'"...)':'TextBlock("'+this.le+'")'};var mm;
na.None=mm=t.w(na,"None",0);var nm;na.WrapFit=nm=t.w(na,"WrapFit",1);var lm;na.WrapDesiredSize=lm=t.w(na,"WrapDesiredSize",2);var km;na.OverflowClip=km=t.w(na,"OverflowClip",0);var om;na.OverflowEllipsis=om=t.w(na,"OverflowEllipsis",1);na.prototype.ha=function(){Q.prototype.ha.call(this);this.ez=this.fz=null};t.g(na,"font",na.prototype.font);
t.defineProperty(na,{font:"font"},function(){return this.Tg},function(a){var b=this.Tg;b!==a&&(e&&t.i(a,"string",na,"font"),this.Tg=a,this.Rn=null,this.ha(),this.j("font",b,a))});t.g(na,"text",na.prototype.text);t.defineProperty(na,{text:"text"},function(){return this.le},function(a){var b=this.le;a=null!==a&&void 0!==a?a.toString():"";b!==a&&(this.le=a,this.ha(),this.j("text",b,a))});t.g(na,"textAlign",na.prototype.textAlign);
t.defineProperty(na,{textAlign:"textAlign"},function(){return this.Td},function(a){var b=this.Td;b!==a&&(e&&t.i(a,"string",na,"textAlign"),"start"===a||"end"===a||"left"===a||"right"===a||"center"===a?(this.Td=a,this.fa(),this.j("textAlign",b,a)):t.ka(a,'"start", "end", "left", "right", or "center"',na,"textAlign"))});
t.A(na,{Pa:"naturalBounds"},function(){if(!this.hd.N()){var a=pm(this,this.le,{},999999).width,b=qm(this,a,{}),c=this.Ca;isNaN(c.width)||(a=c.width);isNaN(c.height)||(b=c.height);Va(this.hd,a,b)}return this.hd});t.g(na,"isMultiline",na.prototype.Tw);t.defineProperty(na,{Tw:"isMultiline"},function(){return this.Yq},function(a){var b=this.Yq;b!==a&&(e&&t.i(a,"boolean",na,"isMultiline"),this.Yq=a,this.ha(),this.j("isMultiline",b,a))});t.g(na,"isUnderline",na.prototype.bJ);
t.defineProperty(na,{bJ:"isUnderline"},function(){return this.Zl},function(a){var b=this.Zl;b!==a&&(e&&t.i(a,"boolean",na,"isUnderline"),this.Zl=a,this.fa(),this.j("isUnderline",b,a))});t.g(na,"isStrikethrough",na.prototype.$I);t.defineProperty(na,{$I:"isStrikethrough"},function(){return this.Yl},function(a){var b=this.Yl;b!==a&&(e&&t.i(a,"boolean",na,"isStrikethrough"),this.Yl=a,this.fa(),this.j("isStrikethrough",b,a))});t.g(na,"wrap",na.prototype.pB);
t.defineProperty(na,{pB:"wrap"},function(){return this.zm},function(a){var b=this.zm;b!==a&&(e&&t.Aa(a,na,na,"wrap"),this.zm=a,this.ha(),this.j("wrap",b,a))});t.g(na,"overflow",na.prototype.overflow);t.defineProperty(na,{overflow:"overflow"},function(){return this.Qk},function(a){var b=this.Qk;b!==a&&(e&&t.Aa(a,na,na,"overflow"),this.Qk=a,this.ha(),this.j("overflow",b,a))});t.g(na,"stroke",na.prototype.stroke);
t.defineProperty(na,{stroke:"stroke"},function(){return this.Gb},function(a){var b=this.Gb;b!==a&&(e&&null!==a&&t.To(a,"TextBlock.stroke"),a instanceof ea&&a.freeze(),this.Gb=a,this.fa(),this.j("stroke",b,a))});t.A(na,{nF:"lineCount"},function(){return this.mf});t.g(na,"editable",na.prototype.yw);t.defineProperty(na,{yw:"editable"},function(){return this.Kq},function(a){var b=this.Kq;b!==a&&(e&&t.i(a,"boolean",na,"editable"),this.Kq=a,this.j("editable",b,a))});t.g(na,"textEditor",na.prototype.hB);
t.defineProperty(na,{hB:"textEditor"},function(){return this.xs},function(a){var b=this.xs;b!==a&&(a instanceof HTMLElement||t.m("textEditor must be an HTMLElement"),this.xs=a,this.j("textEditor",b,a))});t.g(na,"errorFunction",na.prototype.at);t.defineProperty(na,{at:"errorFunction"},function(){return this.kf},function(a){var b=this.kf;b!==a&&(null!==a&&t.i(a,"function",na,"errorFunction"),this.kf=a,this.j("errorFunction",b,a))});function Ck(a,b){var c=a.Tg;null!==c&&b.du!==c&&(b.font=c,b.du=c)}
na.prototype.fl=function(a,b){if(null!==this.Gb&&0!==this.le.length&&null!==this.Tg){var c=this.Pa.width,d=Ah(this);a.textAlign=this.Td;Dk(this,a,this.Gb,!0);(this.Zl||this.Yl)&&Dk(this,a,this.Gb,!1);var f=this.no,g=0,h=!1,k=t.mc(0,0);this.me.Sa(k);var l=t.mc(0,d);this.me.Sa(l);var m=k.Zj(l);t.B(k);t.B(l);k=b.scale;8>m*k*k&&(h=!0);b.Sg!==a&&(h=!1);for(var m=this.Oj,k=this.Pj,l=f.ug.length,n=0;n<l;n++){var p=f.ug[n],q=f.Of[n];p>c&&(p=c);var g=g+m,r=q,q=a,s=g,u=c,v=d,D=0;h?("start"===this.Td||"left"===
this.Td?D=0:"end"===this.Td||"right"===this.Td?D=u-p:"center"===this.Td?D=(u-p)/2:t.m("textAlign must be start, end, left, right, or center"),q.fillRect(0+D,s+0.25*v,p,1)):("start"===this.Td||"left"===this.Td?D=0:"end"===this.Td||"right"===this.Td?D=u:"center"===this.Td?D=u/2:t.m("textAlign must be start, end, left, right, or center"),q.fillText(r,0+D,s+v-0.25*v),r=v/20|0,this.Zl&&("end"===this.Td||"right"===this.Td?D-=p:"center"===this.Td&&(D-=p/2),q.beginPath(),q.lineWidth=r,q.moveTo(0+D,s+v-0.2*
v),q.lineTo(0+D+p,s+v-0.2*v),q.stroke()),this.Yl&&(q.beginPath(),q.lineWidth=r,s=s+v-v/2.2|0,0!==r%2&&(s+=0.5),q.moveTo(0,s),q.lineTo(0+p,s),q.stroke()));g+=d+k}}};
na.prototype.ut=function(a,b,c,d){var f={},g=0,h=0;if(isNaN(this.Ca.width)){g=this.le;if(0===g.length)g=0;else if(this.Tw){for(var k=h=0,l=!1,m={ph:0};!l;){var n=rm(g,k,m);-1===n&&(n=g.length,l=!0);k=sm(g.substr(k,n-k).replace(/^\s+|\s+$/g,""),this.Tg);k>h&&(h=k);k=m.ph}g=h}else h=rm(g,0,{}),0<=h&&(g=g.substr(0,h)),g=k=sm(g,this.Tg);g=Math.min(g,a/this.scale);g=Math.max(8,g)}else g=this.Ca.width;this.ja&&(g=Math.min(g,this.ja.He.width),g=Math.max(g,this.ja.Vf.width));h=qm(this,g,f);m=h=isNaN(this.Ca.height)?
Math.min(h,b/this.scale):this.Ca.height;if(0!==f.Ag&&1!==f.Of.length&&this.Qk===om&&(b=this.Tg,l=this.Qk===om?tm(b):0,k=this.Oj+this.Pj,k=Math.max(0,Ah(this)+k),m=Math.max(Math.floor(m/k)-1,0),!(m+1>=f.Of.length))){k=f.Of[m];for(a=Math.max(1,a-l);sm(k,b)>a&&1<k.length;)k=k.substr(0,k.length-1);k+=t.$s;a=sm(k,b);f.Of[m]=k;f.Of=f.Of.slice(0,m+1);f.ug[m]=a;f.ug=f.ug.slice(0,m+1);f.ph=f.Of.length;f.Ag=Math.max(f.Ag,a)}if(this.pB===nm||isNaN(this.Ca.width))g=f.Ag,isNaN(this.Ca.width)&&(g=Math.max(8,g));
g=Math.max(c,g);h=Math.max(d,h);Va(this.hd,g,h);nk(this,0,0,g,h);this.no=f};na.prototype.Tj=function(a,b,c,d){uk(this,a,b,c,d)};
function pm(a,b,c,d){b=b.replace(/^\s+|\s+$/g,"");void 0===c.Ag&&(c.Ag=0);void 0===c.ug&&(c.ug=[]);void 0===c.Of&&(c.Of=[]);var f=0,g,h,k=a.Tg;g=a.Oj+a.Pj;var l=Math.max(0,Ah(a)+g),m=a.Qk===om?tm(k):0;if(!(a.mf>=a.Ai)){if(a.zm===mm){c.ph=1;g=sm(b,k);if(0===m||g<=d)return c.Ag=g,c.ug.push(c.Ag),c.Of.push(b),new fa(g,l);var n=um(b);b=b.substr(n.length);h=um(b);for(g=sm(n+h,k);0<h.length&&g<=d;)n+=h,b=b.substr(h.length),h=um(b),g=sm((n+h).replace(/^\s+|\s+$/g,""),k);n+=h.replace(/^\s+|\s+$/g,"");for(d=
Math.max(1,d-m);sm(n,k)>d&&1<n.length;)n=n.substr(0,n.length-1);n+=t.$s;h=sm(n,k);c.ug.push(h);c.Ag=h;c.Of.push(n);return new fa(h,l)}m=0;0===b.length&&(m=1,c.ug.push(0),c.Of.push(b));for(;0<b.length;){n=um(b);for(b=b.substr(n.length);sm(n,k)>d;){var p=1;g=sm(n.substr(0,p),k);for(h=0;g<=d;)p++,h=g,g=sm(n.substr(0,p),k);1===p?(c.ug[a.mf+m]=g,f=Math.max(f,g)):(c.ug[a.mf+m]=h,f=Math.max(f,h));p--;1>p&&(p=1);c.Of[a.mf+m]=n.substr(0,p);m++;n=n.substr(p);if(a.mf+m>a.Ai)break}h=um(b);for(g=sm(n+h,k);0<h.length&&
g<=d;)n+=h,b=b.substr(h.length),h=um(b),g=sm((n+h).replace(/^\s+|\s+$/g,""),k);n=n.replace(/^\s+|\s+$/g,"");if(""!==n&&(0===h.length?(c.ug.push(g),f=Math.max(f,g)):(h=sm(n,k),c.ug.push(h),f=Math.max(f,h)),c.Of.push(n),m++,a.mf+m>a.Ai))break}c.ph=Math.min(a.Ai,m);c.Ag=Math.max(c.Ag,f);return new fa(c.Ag,l*c.ph)}}function um(a){for(var b=a.length,c=0;c<b&&" "!==a.charAt(c);)c++;for(;c<b&&" "===a.charAt(c);)c++;return c>=b?a:a.substr(0,c)}
function sm(a,b){t.Tp!==b&&(t.ln.font=b,t.Tp=b);return t.ln.measureText(a).width}function Ah(a){if(null!==a.Rn)return a.Rn;var b=a.Tg;t.Tp!==b&&(t.ln.font=b,t.Tp=b);var c=0;void 0!==t.gA[b]&&5E3>t.CE?c=t.gA[b]:(c=1.3*t.ln.measureText("M").width,t.gA[b]=c,t.CE++);return a.Rn=c}function tm(a){t.Tp!==a&&(t.ln.font=a,t.Tp=a);var b=0;void 0!==t.Gw[a]&&5E3>t.fA?b=t.Gw[a]:(b=t.ln.measureText(t.$s).width,t.Gw[a]=b,t.fA++);return b}
function rm(a,b,c){void 0===c.ph&&(c.ph=0);var d=a.indexOf("\r",b);-1===d&&(d=a.indexOf("\n",b));0<=d&&(c.ph="\r"===a[d]&&d+1<a.length&&"\n"===a[d+1]?d+2:d+1);return d}
function qm(a,b,c){var d=a.le,f=a.Oj+a.Pj,f=Math.max(0,Ah(a)+f);if(0===d.length)return c.Ag=0,a.mf=1,f;if(!a.Tw){var g=rm(d,0,{});0<=g&&(d=d.substr(0,g))}for(var g=0,h=a.mf=0,k=-1,l={ph:0},m=!1;!m;)k=rm(d,h,l),-1===k&&(k=d.length,m=!0),h<=k&&(h=d.substr(h,k-h),a.zm!==mm?(c.ph=0,h=pm(a,h,c,b),g+=h.height,a.mf+=c.ph):(pm(a,h,c,b),g+=f,a.mf++),a.mf===a.Ai&&(m=!0)),h=l.ph;return a.dv=g}t.g(na,"textValidation",na.prototype.Xt);
t.defineProperty(na,{Xt:"textValidation"},function(){return this.Tk},function(a){var b=this.Tk;b!==a&&(null!==a&&t.i(a,"function",na,"textValidation"),this.Tk=a,this.j("textValidation",b,a))});t.g(na,"spacingAbove",na.prototype.TJ);t.defineProperty(na,{TJ:"spacingAbove"},function(){return this.Oj},function(a){var b=this.Oj;b!==a&&(this.Oj=a,this.j("spacingAbove",b,a))});t.g(na,"spacingBelow",na.prototype.UJ);
t.defineProperty(na,{UJ:"spacingBelow"},function(){return this.Pj},function(a){var b=this.Pj;b!==a&&(this.Pj=a,this.j("spacingBelow",b,a))});t.g(na,"maxLines",na.prototype.nJ);t.defineProperty(na,{nJ:"maxLines"},function(){return this.Ai},function(a){var b=this.Ai;b!==a&&(this.Ai=a,this.j("maxLines",b,a))});function al(){Q.call(this);this.dg=null;this.qs="";this.Nj=(new x(NaN,NaN,NaN,NaN)).freeze();this.Wn=Cc;this.vs=this.kf=null;this.Vu=this.Mu=this.fm=!1;this.fC=!0;this.zo=null}t.ga("Picture",al);
t.Ka(al,Q);var vm;al.clearCache=vm=function(a){void 0===a&&(a="");t.i(a,"string",al,"clearCache:url");""!==a?t.kt[a]&&(delete t.kt[a],t.Lw--):(t.kt={},t.Lw=0)};al.prototype.cloneProtected=function(a){Q.prototype.cloneProtected.call(this,a);a.element=this.dg;a.qs=this.qs;a.Nj.assign(this.Nj);a.Wn=this.Wn;a.kf=this.kf;a.zo=this.zo};al.prototype.toString=function(){return"Picture("+this.source+")#"+t.od(this)};t.g(al,"element",al.prototype.element);
t.defineProperty(al,{element:"element"},function(){return this.dg},function(a){var b=this.dg;if(b!==a){a instanceof HTMLImageElement||a instanceof HTMLVideoElement||a instanceof HTMLCanvasElement||t.m("Picture.element must be an instance of Image, Canvas, or Video.");this.Mu=!1;var c=this;a instanceof HTMLCanvasElement?this.Vu=!0:(this.Vu=!1,a.onerror=function(a){c.Mu=!0;null!==c.kf&&c.kf(c,a)});this.dg=a;!0===a.complete||void 0===a.complete?(this.fm=!0,this.Ca.N()||(rk(this,!1),this.ha())):(t.lB.push(this),
null===t.lx&&(t.lx=setInterval(function(){for(var a=[],b=t.lB,c=b.length,h=0;h<c;h++){var k=b[h],l;l=k;var m=l.dg;if(m)if(m.complete){l.fm=!0;l.Ca.N()||(rk(l,!1),l.ha());if(l.h)l.Ca.N()||l.h.Oc(),l.h.fa();else{l=t.$z;var n=l.length;if(0===n)for(var n=document.getElementsByTagName("canvas"),p=n.length,m=0;m<p;m++){var q=n[m];q.parentElement&&q.parentElement.U&&l.push(q.parentElement.U)}n=l.length;for(m=0;m<n;m++)l[m].fa()}l=!0}else l=!1;else l=!0;l||a.push(k)}0===a.length&&(clearInterval(t.lx),t.lx=
null);t.lB=a},200)));this.j("element",b,a);this.fa()}});t.g(al,"source",al.prototype.source);t.defineProperty(al,{source:"source"},function(){return this.qs},function(a){var b=this.qs;if(b!==a){t.i(a,"string",al,"source");this.qs=a;this.Mu=!1;var c=t.kt;if(void 0!==c[a])this.element=c[a];else{10<t.Lw&&(vm(),c=t.kt);var d=document.createElement("img");d.src=a;var f=this.zo;null!==f&&(d.crossOrigin=f(this));c[a]=d;t.Lw++;d instanceof HTMLImageElement&&(this.element=d)}this.fa();this.j("source",b,a)}});
t.g(al,"sourceCrossOrigin",al.prototype.RJ);t.defineProperty(al,{RJ:"sourceCrossOrigin"},function(){return this.zo},function(a){if(this.zo!==a&&(null!==a&&t.i(a,"function",al,"sourceCrossOrigin"),this.zo=a,null!==this.element)){var b=this.element.src;null===a&&"string"===typeof b?this.element.crossOrigin=null:null!==a&&(this.element.crossOrigin=a(this))}});t.g(al,"sourceRect",al.prototype.ei);
t.defineProperty(al,{ei:"sourceRect"},function(){return this.Nj},function(a){var b=this.Nj;b.M(a)||(t.l(a,x,al,"sourceRect"),this.Nj=a=a.Z(),this.fa(),this.j("sourceRect",b,a))});t.g(al,"imageStretch",al.prototype.QI);t.defineProperty(al,{QI:"imageStretch"},function(){return this.Wn},function(a){var b=this.Wn;b!==a&&(t.Aa(a,Q,al,"imageStretch"),this.Wn=a,this.fa(),this.j("imageStretch",b,a))});t.g(al,"errorFunction",al.prototype.at);
t.defineProperty(al,{at:"errorFunction"},function(){return this.kf},function(a){var b=this.kf;if(b!==a){null!==a&&t.i(a,"function",al,"errorFunction");this.kf=a;if(this.dg){var c=this;this.dg.onerror=function(a){null!==c.kf&&c.kf(c,a)}}this.j("errorFunction",b,a)}});
al.prototype.fl=function(a,b){if(!b.Mn){if(null===this.vs)if(null===this.dg)this.vs=!1;else{var c=document.createElement("canvas").getContext("2d");0!==this.dg.width&&0!==this.dg.height&&c.drawImage(this.dg,0,0);try{c.getImageData(0,0,1,1),this.vs=!1}catch(d){this.vs=!0}}if(this.vs)return}if(this.fm&&!this.Mu&&(c=this.dg,null!==c)){var f=c.src;null!==f&&""!==f||t.m("Element has no source attribute: "+c);var f=this.Pa,g=0,h=0,k=this.Vu,l=k?+c.width:c.naturalWidth,k=k?+c.height:c.naturalHeight;void 0===
l&&c.videoWidth&&(l=c.videoWidth);void 0===k&&c.videoHeight&&(k=c.videoHeight);l=l||f.width;k=k||f.height;if(0!==l&&0!==k){this.ei.N()&&(g=this.Nj.x,h=this.Nj.y,l=this.Nj.width,k=this.Nj.height);var m=l,n=k,p=this.Wn;switch(p){case Vg:if(this.ei.N())break;g+=Math.max((m-f.width)/2,0);h+=Math.max((n-f.height)/2,0);l=Math.min(f.width,m);k=Math.min(f.height,n);break;case Cc:m=f.width;n=f.height;break;case Xg:case Yg:p===Xg?(p=Math.min(f.height/n,f.width/m),m*=p,n*=p):p===Yg&&(p=Math.max(f.height/n,f.width/
m),m*=p,n*=p,g+=(m-f.width)/2,h+=(n-f.height)/2,l*=1/(m/f.width),k*=1/(n/f.height),m=f.width,n=f.height)}try{a.drawImage(c,g,h,l,k,Math.max((f.width-m)/2,0),Math.max((f.height-n)/2,0),Math.min(f.width,m),Math.min(f.height,n))}catch(q){e&&this.fC&&t.trace(q.toString()),this.fC=!1}}}};t.A(al,{Pa:"naturalBounds"},function(){return this.hd});
al.prototype.ut=function(a,b,c,d){var f=this.Ca,g=qk(this),h=this.dg,k=this.Vu;if(k||!this.fm&&h&&h.complete)this.fm=!0;null===h&&(isFinite(a)||(a=0),isFinite(b)||(b=0));isFinite(f.width)||g===Cc||g===ik?(isFinite(a)||(a=this.ei.N()?this.ei.width:k?+h.width:h.naturalWidth),c=0):null!==h&&!1!==this.fm&&(a=this.ei.N()?this.ei.width:k?+h.width:h.naturalWidth);isFinite(f.height)||g===Cc||g===hk?(isFinite(b)||(b=this.ei.N()?this.ei.height:k?+h.height:h.naturalHeight),d=0):null!==h&&!1!==this.fm&&(b=this.ei.N()?
this.ei.height:k?+h.height:h.naturalHeight);isFinite(f.width)&&(a=f.width);isFinite(f.height)&&(b=f.height);f=this.He;g=this.Vf;c=Math.max(c,g.width);d=Math.max(d,g.height);a=Math.min(f.width,a);b=Math.min(f.height,b);a=Math.max(c,a);b=Math.max(d,b);null===h||h.complete||(isFinite(a)||(a=0),isFinite(b)||(b=0));Va(this.hd,a,b);nk(this,0,0,a,b)};al.prototype.Tj=function(a,b,c,d){uk(this,a,b,c,d)};function ha(){this.s=new zc;this.Xb=null}aa=ha.prototype;aa.reset=function(){this.s=new zc;this.Xb=null};
function I(a,b,c,d,f,g){null===a.s&&t.m("StreamGeometryContext has been closed");void 0!==f&&!0===f?(null===a.Xb&&t.m("Need to call beginFigure first"),d=new id(bd),d.D=b,d.F=c,a.Xb.Ea.add(d)):(a.Xb=new Bc,a.Xb.qa=b,a.Xb.ra=c,a.Xb.pp=d,a.s.Bb.add(a.Xb));void 0!==g&&(a.Xb.bo=g)}function L(a){null===a.s&&t.m("StreamGeometryContext has been closed");null===a.Xb&&t.m("Need to call beginFigure first");var b=a.Xb.Ea.length;0<b&&a.Xb.Ea.n[b-1].close()}
function Zc(a){null===a.s&&t.m("StreamGeometryContext has been closed");null===a.Xb&&t.m("Need to call beginFigure first");0<a.Xb.Ea.length&&(a.Xb.pp=!0)}aa.bb=function(a){null===this.s&&t.m("StreamGeometryContext has been closed");null===this.Xb&&t.m("Need to call beginFigure first");this.Xb.gj=a};aa.moveTo=function(a,b,c){null===this.s&&t.m("StreamGeometryContext has been closed");null===this.Xb&&t.m("Need to call beginFigure first");var d=new id(bd);d.D=a;d.F=b;c&&d.close();this.Xb.Ea.add(d)};
aa.lineTo=function(a,b,c){null===this.s&&t.m("StreamGeometryContext has been closed");null===this.Xb&&t.m("Need to call beginFigure first");var d=new id(Oc);d.D=a;d.F=b;c&&d.close();this.Xb.Ea.add(d)};function J(a,b,c,d,f,g,h,k){null===a.s&&t.m("StreamGeometryContext has been closed");null===a.Xb&&t.m("Need to call beginFigure first");var l=new id(cd);l.Cb=b;l.Sb=c;l.re=d;l.se=f;l.D=g;l.F=h;k&&l.close();a.Xb.Ea.add(l)}
function Sc(a,b,c,d,f){null===a.s&&t.m("StreamGeometryContext has been closed");null===a.Xb&&t.m("Need to call beginFigure first");var g=new id(dd);g.Cb=b;g.Sb=c;g.D=d;g.F=f;a.Xb.Ea.add(g)}aa.arcTo=function(a,b,c,d,f,g,h){null===this.s&&t.m("StreamGeometryContext has been closed");null===this.Xb&&t.m("Need to call beginFigure first");var k=new id(ed);k.mh=a;k.gi=b;k.Da=c;k.Oa=d;k.radiusX=f;k.radiusY=g?g:f;h&&k.close();this.Xb.Ea.add(k)};
function Tc(a,b,c,d,f,g,h,k){null===a.s&&t.m("StreamGeometryContext has been closed");null===a.Xb&&t.m("Need to call beginFigure first");b=new id(fd,h,k,b,c,d,f,g);a.Xb.Ea.add(b)}
F.makeGeometry=function(a,b,c){var d=a.Ca,f=d.width,d=d.height;void 0!==b&&!isNaN(b)&&isFinite(b)&&(f=b);void 0!==c&&!isNaN(c)&&isFinite(c)&&(d=c);isFinite(f)||(f=100);isFinite(d)||(d=100);b=void 0;"None"!==a.on?b=F.Dm[a.on]:"None"!==a.Hw?b=F.Dm[a.Hw]:(c=F.bj[a.Ab],"string"===typeof c&&(c=F.bj[c]),void 0===c&&t.m("Unknown Shape.figure: "+a.Ab),b=c(a,f,d),b.$n=f,b.Zn=d);void 0===b&&(c=F.bj.Rectangle)&&(b=c(a,f,d));return b};
F.Vi=function(a,b,c,d,f,g,h,k,l,m,n,p,q,r){var s=1-l;a=a*s+c*l;b=b*s+d*l;c=c*s+f*l;d=d*s+g*l;f=f*s+h*l;g=g*s+k*l;k=a*s+c*l;h=b*s+d*l;c=c*s+f*l;d=d*s+g*l;m.x=a;m.y=b;n.x=k;n.y=h;p.x=k*s+c*l;p.y=h*s+d*l;q.x=c;q.y=d;r.x=f;r.y=g};F.Xo=function(a){a=F.Mm(a);var b=t.Db();b[0]=a[0];for(var c=1,d=1;c<a.length;c+=2,d+=3)b[d]=a[c],b[d+1]=a[c],b[d+2]=a[c+1];t.ya(a);return b};
F.Mm=function(a){var b=F.dl(a),c=t.Db(),d=Math.floor(b.length/2),f=b.length-1;a=0===a%2?2:1;for(var g=0;g<f;g++){var h=b[g],k=b[g+1],l=b[(d+g-1)%f],m=b[(d+g+a)%f];c[2*g]=h;c[2*g+1]=F.jl(h.x,h.y,l.x,l.y,k.x,k.y,m.x,m.y,new w)}c[c.length]=c[0];t.ya(b);return c};F.jl=function(a,b,c,d,f,g,h,k,l){c=a-c;h=f-h;0===c||0===h?0===c?(h=(g-k)/h,k=a,f=h*k+(g-h*f)):(d=(b-d)/c,k=f,f=d*k+(b-d*a)):(d=(b-d)/c,h=(g-k)/h,a=b-d*a,k=(g-h*f-a)/(d-h),f=d*k+a);l.p(k,f);return l};
F.dl=function(a){for(var b=t.Db(),c=1.5*Math.PI,d=0,f=0;f<a;f++)d=2*Math.PI/a*f+c,b[f]=new w(0.5+0.5*Math.cos(d),0.5+0.5*Math.sin(d));b.push(b[0]);return b};F.sB=(new H(0.156,0.156)).Ja();F.tB=(new H(0.844,0.844)).Ja();
F.bj={None:"Rectangle",Rectangle:function(a,b,c){a=new zc;a.type=Ec;a.qa=0;a.ra=0;a.D=b;a.F=c;return a},Square:function(a,b,c){a=new zc;a.Hc=Xg;a.type=Ec;a.qa=0;a.ra=0;a.D=b;a.F=c;return a},Ellipse:function(a,b,c){a=new zc;a.type=Mc;a.qa=0;a.ra=0;a.D=b;a.F=c;a.G=F.sB;a.H=F.tB;return a},Circle:function(a,b,c){a=new zc;a.Hc=Xg;a.type=Mc;a.qa=0;a.ra=0;a.D=b;a.F=c;a.G=F.sB;a.H=F.tB;return a},Connector:"Ellipse",TriangleRight:function(a,b,c){a=new zc;var d=new Bc,f=new id;f.D=b;f.F=0.5*c;d.Ea.add(f);b=
new id;b.D=0;b.F=c;d.Ea.add(b.close());a.Bb.add(d);a.G=new H(0,0.25);a.H=new H(0.5,0.75);return a},TriangleDown:function(a,b,c){a=new zc;var d=new Bc,f=new id;f.D=b;f.F=0;d.Ea.add(f);f=new id;f.D=0.5*b;f.F=c;d.Ea.add(f.close());a.Bb.add(d);a.G=new H(0.25,0);a.H=new H(0.75,0.5);return a},TriangleLeft:function(a,b,c){a=new zc;var d=new Bc;d.qa=b;d.ra=c;var f=new id;f.D=0;f.F=0.5*c;d.Ea.add(f);c=new id;c.D=b;c.F=0;d.Ea.add(c.close());a.Bb.add(d);a.G=new H(0.5,0.25);a.H=new H(1,0.75);return a},TriangleUp:function(a,
b,c){a=new zc;var d=new Bc;d.qa=b;d.ra=c;var f=new id;f.D=0;f.F=c;d.Ea.add(f);c=new id;c.D=0.5*b;c.F=0;d.Ea.add(c.close());a.Bb.add(d);a.G=new H(0.25,0.5);a.H=new H(0.75,1);return a},Line1:function(a,b,c){a=new zc;a.type=Dc;a.qa=0;a.ra=0;a.D=b;a.F=c;return a},Line2:function(a,b,c){a=new zc;a.type=Dc;a.qa=b;a.ra=0;a.D=0;a.F=c;return a},MinusLine:"LineH",LineH:function(a,b,c){a=new zc;a.type=Dc;a.qa=0;a.ra=c/2;a.D=b;a.F=c/2;return a},LineV:function(a,b,c){a=new zc;a.type=Dc;a.qa=b/2;a.ra=0;a.D=b/2;
a.F=c;return a},Curve1:function(a,b,c){var d=F.va;a=t.u();I(a,0,0,!1);J(a,d*b,0,1*b,(1-d)*c,b,c);b=a.s;t.v(a);return b},Curve2:function(a,b,c){var d=F.va;a=t.u();I(a,0,0,!1);J(a,0,d*c,(1-d)*b,c,b,c);b=a.s;t.v(a);return b},Curve3:function(a,b,c){var d=F.va;a=t.u();I(a,1*b,0,!1);J(a,1*b,d*c,d*b,1*c,0,1*c);b=a.s;t.v(a);return b},Curve4:function(a,b,c){var d=F.va;a=t.u();I(a,1*b,0,!1);J(a,(1-d)*b,0,0,(1-d)*c,0,1*c);b=a.s;t.v(a);return b},Alternative:"Triangle",Merge:"Triangle",Triangle:function(a,b,c){a=
t.u();I(a,0.5*b,0*c,!0);a.lineTo(0*b,1*c);a.lineTo(1*b,1*c,!0);b=a.s;b.G=new H(0.25,0.5);b.H=new H(0.75,1);t.v(a);return b},Decision:"Diamond",Diamond:function(a,b,c){a=t.u();I(a,0.5*b,0,!0);a.lineTo(0,0.5*c);a.lineTo(0.5*b,1*c);a.lineTo(1*b,0.5*c,!0);b=a.s;b.G=new H(0.25,0.25);b.H=new H(0.75,0.75);t.v(a);return b},Pentagon:function(a,b,c){var d=F.dl(5);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;5>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.2,0.22);b.H=new H(0.8,0.9);t.v(a);
return b},DataTransmission:"Hexagon",Hexagon:function(a,b,c){var d=F.dl(6);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;6>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.07,0.25);b.H=new H(0.93,0.75);t.v(a);return b},Heptagon:function(a,b,c){var d=F.dl(7);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;7>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.2,0.15);b.H=new H(0.8,0.85);t.v(a);return b},Octagon:function(a,b,c){var d=F.dl(8);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);
for(var f=1;8>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.15,0.15);b.H=new H(0.85,0.85);t.v(a);return b},Nonagon:function(a,b,c){var d=F.dl(9);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;9>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.17,0.13);b.H=new H(0.82,0.82);t.v(a);return b},Decagon:function(a,b,c){var d=F.dl(10);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;10>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.16,0.16);b.H=new H(0.84,
0.84);t.v(a);return b},Dodecagon:function(a,b,c){var d=F.dl(12);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;12>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.16,0.16);b.H=new H(0.84,0.84);t.v(a);return b},FivePointedStar:function(a,b,c){var d=F.Mm(5);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;10>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.312,0.383);b.H=new H(0.693,0.765);t.v(a);return b},SixPointedStar:function(a,b,c){var d=F.Mm(6);a=t.u();I(a,d[0].x*b,
d[0].y*c,!0);for(var f=1;12>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.17,0.251);b.H=new H(0.833,0.755);t.v(a);return b},SevenPointedStar:function(a,b,c){var d=F.Mm(7);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;14>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.363,0.361);b.H=new H(0.641,0.709);t.v(a);return b},EightPointedStar:function(a,b,c){var d=F.Mm(8);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;16>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;
b.G=new H(0.252,0.255);b.H=new H(0.75,0.75);t.v(a);return b},NinePointedStar:function(a,b,c){var d=F.Mm(9);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;18>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.355,0.361);b.H=new H(0.645,0.651);t.v(a);return b},TenPointedStar:function(a,b,c){var d=F.Mm(10);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;20>f;f++)a.lineTo(d[f].x*b,d[f].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.281,0.261);b.H=new H(0.723,0.748);t.v(a);return b},FivePointedBurst:function(a,
b,c){var d=F.Xo(5);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;f<d.length;f+=3)J(a,d[f].x*b,d[f].y*c,d[f+1].x*b,d[f+1].y*c,d[f+2].x*b,d[f+2].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.312,0.383);b.H=new H(0.693,0.765);t.v(a);return b},SixPointedBurst:function(a,b,c){var d=F.Xo(6);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;f<d.length;f+=3)J(a,d[f].x*b,d[f].y*c,d[f+1].x*b,d[f+1].y*c,d[f+2].x*b,d[f+2].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.17,0.251);b.H=new H(0.833,0.755);t.v(a);return b},SevenPointedBurst:function(a,
b,c){var d=F.Xo(7);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;f<d.length;f+=3)J(a,d[f].x*b,d[f].y*c,d[f+1].x*b,d[f+1].y*c,d[f+2].x*b,d[f+2].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.363,0.361);b.H=new H(0.641,0.709);t.v(a);return b},EightPointedBurst:function(a,b,c){var d=F.Xo(8);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;f<d.length;f+=3)J(a,d[f].x*b,d[f].y*c,d[f+1].x*b,d[f+1].y*c,d[f+2].x*b,d[f+2].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.252,0.255);b.H=new H(0.75,0.75);t.v(a);return b},NinePointedBurst:function(a,
b,c){var d=F.Xo(9);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;f<d.length;f+=3)J(a,d[f].x*b,d[f].y*c,d[f+1].x*b,d[f+1].y*c,d[f+2].x*b,d[f+2].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.355,0.361);b.H=new H(0.645,0.651);t.v(a);return b},TenPointedBurst:function(a,b,c){var d=F.Xo(10);a=t.u();I(a,d[0].x*b,d[0].y*c,!0);for(var f=1;f<d.length;f+=3)J(a,d[f].x*b,d[f].y*c,d[f+1].x*b,d[f+1].y*c,d[f+2].x*b,d[f+2].y*c);t.ya(d);L(a);b=a.s;b.G=new H(0.281,0.261);b.H=new H(0.723,0.748);t.v(a);return b},Cloud:function(a,
b,c){a=t.u();I(a,0.08034461*b,0.1944299*c,!0);J(a,-0.09239631*b,0.07836421*c,0.1406031*b,-0.0542823*c,0.2008615*b,0.05349299*c);J(a,0.2450511*b,-0.00697547*c,0.3776197*b,-0.01112067*c,0.4338609*b,0.074219*c);J(a,0.4539471*b,0,0.6066018*b,-0.02526587*c,0.6558228*b,0.07004196*c);J(a,0.6914277*b,-0.01904177*c,0.8921095*b,-0.01220843*c,0.8921095*b,0.08370865*c);J(a,1.036446*b,0.04105738*c,1.020377*b,0.3022052*c,0.9147671*b,0.3194596*c);J(a,1.04448*b,0.360238*c,0.992256*b,0.5219009*c,0.9082935*b,0.562044*
c);J(a,1.032337*b,0.5771781*c,1.018411*b,0.8120651*c,0.9212406*b,0.8217117*c);J(a,1.028411*b,0.9571472*c,0.8556702*b,1.052487*c,0.7592566*b,0.9156953*c);J(a,0.7431877*b,1.009325*c,0.5624123*b,1.021761*c,0.5101666*b,0.9310455*c);J(a,0.4820677*b,1.031761*c,0.3030112*b,1.002796*c,0.2609328*b,0.9344623*c);J(a,0.2329994*b,1.01518*c,0.03213784*b,1.01518*c,0.08034461*b,0.870098*c);J(a,-0.02812061*b,0.9032597*c,-0.01205169*b,0.6835638*c,0.06829292*b,0.6545475*c);J(a,-0.01812061*b,0.6089503*c,-0.00606892*
b,0.4555777*c,0.06427569*b,0.4265613*c);J(a,-0.01606892*b,0.3892545*c,-0.01205169*b,0.1944299*c,0.08034461*b,0.1944299*c);L(a);b=a.s;b.G=new H(0.1,0.1);b.H=new H(0.9,0.9);t.v(a);return b},Gate:"Crescent",Crescent:function(a,b,c){a=t.u();I(a,0,0,!0);J(a,1*b,0,1*b,1*c,0,1*c);J(a,0.5*b,0.75*c,0.5*b,0.25*c,0,0);L(a);b=a.s;b.G=new H(0.511,0.19);b.H=new H(0.776,0.76);t.v(a);return b},FramedRectangle:function(a,b,c){var d=t.u(),f=a?a.Gc:0;a=a?a.Ft:0;0===f&&(f=0.1);0===a&&(a=0.1);I(d,0,0,!0);d.lineTo(1*b,
0);d.lineTo(1*b,1*c);d.lineTo(0,1*c,!0);I(d,f*b,a*c,!1,!0);d.lineTo(f*b,(1-a)*c);d.lineTo((1-f)*b,(1-a)*c);d.lineTo((1-f)*b,a*c,!0);b=d.s;b.G=new H(f,a);b.H=new H(1-f,1-a);t.v(d);return b},Delay:"HalfEllipse",HalfEllipse:function(a,b,c){var d=F.va;a=t.u();I(a,0,0,!0);J(a,d*b,0,1*b,(0.5-d/2)*c,1*b,0.5*c);J(a,1*b,(0.5+d/2)*c,d*b,1*c,0,1*c);L(a);b=a.s;b.G=new H(0,0.2);b.H=new H(0.75,0.8);t.v(a);return b},Heart:function(a,b,c){a=t.u();I(a,0.5*b,1*c,!0);J(a,0.1*b,0.8*c,0,0.5*c,0*b,0.3*c);J(a,0*b,0,0.45*
b,0,0.5*b,0.3*c);J(a,0.55*b,0,1*b,0,1*b,0.3*c);J(a,b,0.5*c,0.9*b,0.8*c,0.5*b,1*c);L(a);b=a.s;b.G=new H(0.15,0.29);b.H=new H(0.86,0.68);t.v(a);return b},Spade:function(a,b,c){a=t.u();I(a,0.5*b,0,!0);a.lineTo(0.51*b,0.01*c);J(a,0.6*b,0.2*c,b,0.25*c,b,0.5*c);J(a,b,0.8*c,0.6*b,0.8*c,0.55*b,0.7*c);J(a,0.5*b,0.75*c,0.55*b,0.95*c,0.75*b,c);a.lineTo(0.25*b,c);J(a,0.45*b,0.95*c,0.5*b,0.75*c,0.45*b,0.7*c);J(a,0.4*b,0.8*c,0,0.8*c,0,0.5*c);J(a,0,0.25*c,0.4*b,0.2*c,0.49*b,0.01*c);L(a);b=a.s;b.G=new H(0.19,0.26);
b.H=new H(0.8,0.68);t.v(a);return b},Club:function(a,b,c){a=t.u();I(a,0.4*b,0.6*c,!0);J(a,0.5*b,0.75*c,0.45*b,0.95*c,0.15*b,1*c);a.lineTo(0.85*b,c);J(a,0.55*b,0.95*c,0.5*b,0.75*c,0.6*b,0.6*c);var d=0.2,f=0.3,g=0,h=4*(Math.SQRT2-1)/3*d;J(a,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-h+f)*b,(0.5+d+g)*c,(0.5+f)*b,(0.5+d+g)*c);J(a,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+d+f)*b,(0.5+h+g)*c,(0.5+d+f)*b,(0.5+g)*c);J(a,(0.5+d+f)*b,(0.5-h+g)*c,(0.5+h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);J(a,(0.5-h+f)*b,(0.5-d+g)*c,(0.5-d+f+0.05)*
b,(0.5-h+g-0.02)*c,0.65*b,0.36771243*c);d=0.2;f=0;g=-0.3;h=4*(Math.SQRT2-1)/3*d;J(a,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+d+f)*b,(0.5+h+g)*c,(0.5+d+f)*b,(0.5+g)*c);J(a,(0.5+d+f)*b,(0.5-h+g)*c,(0.5+h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);J(a,(0.5-h+f)*b,(0.5-d+g)*c,(0.5-d+f)*b,(0.5-h+g)*c,(0.5-d+f)*b,(0.5+g)*c);J(a,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-h+f)*b,(0.5+d+g)*c,0.35*b,0.36771243*c);d=0.2;f=-0.3;g=0;h=4*(Math.SQRT2-1)/3*d;J(a,(0.5+d+f-0.05)*b,(0.5-h+g-0.02)*c,(0.5+h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);
J(a,(0.5-h+f)*b,(0.5-d+g)*c,(0.5-d+f)*b,(0.5-h+g)*c,(0.5-d+f)*b,(0.5+g)*c);J(a,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-h+f)*b,(0.5+d+g)*c,(0.5+f)*b,(0.5+d+g)*c);J(a,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+d+f)*b,(0.5+h+g)*c,0.4*b,0.6*c);L(a);b=a.s;b.G=new H(0.06,0.39);b.H=new H(0.93,0.58);t.v(a);return b},Ring:function(a,b,c){a=t.u();var d=4*(Math.SQRT2-1)/3*0.5;I(a,b,0.5*c,!0);J(a,b,(0.5-d)*c,(0.5+d)*b,0,0.5*b,0);J(a,(0.5-d)*b,0,0,(0.5-d)*c,0,0.5*c);J(a,0,(0.5+d)*c,(0.5-d)*b,c,0.5*b,c);J(a,(0.5+d)*b,c,b,(0.5+d)*c,b,0.5*
c);d=4*(Math.SQRT2-1)/3*0.4;I(a,0.5*b,0.1*c,!0,!0);J(a,(0.5+d)*b,0.1*c,0.9*b,(0.5-d)*c,0.9*b,0.5*c);J(a,0.9*b,(0.5+d)*c,(0.5+d)*b,0.9*c,0.5*b,0.9*c);J(a,(0.5-d)*b,0.9*c,0.1*b,(0.5+d)*c,0.1*b,0.5*c);J(a,0.1*b,(0.5-d)*c,(0.5-d)*b,0.1*c,0.5*b,0.1*c);b=a.s;b.G=new H(0.146,0.146);b.H=new H(0.853,0.853);b.Hc=Xg;t.v(a);return b},YinYang:function(a,b,c){var d=0.5;a=t.u();d=0.5;I(a,0.5*b,0,!0);a.arcTo(270,180,0.5*b,0.5*b,0.5*b);J(a,1*b,d*c,0,d*c,d*b,0,!0);var d=0.1,f=0.25;I(a,(0.5+d)*b,f*c,!0,!0);a.arcTo(0,
-360,0.5*b,c*f,d*b);L(a);I(a,0.5*b,0,!1);a.arcTo(270,-180,0.5*b,0.5*b,0.5*b);a.bb(!1);f=0.75;I(a,(0.5+d)*b,f*c,!0);a.arcTo(0,360,0.5*b,c*f,d*b);L(a);b=a.s;b.Hc=Xg;t.v(a);return b},Peace:function(a,b,c){a=t.u();var d=4*(Math.SQRT2-1)/3*0.5;I(a,b,0.5*c,!0);J(a,b,(0.5-d)*c,(0.5+d)*b,0,0.5*b,0);J(a,(0.5-d)*b,0,0,(0.5-d)*c,0,0.5*c);J(a,0,(0.5+d)*c,(0.5-d)*b,c,0.5*b,c);J(a,(0.5+d)*b,c,b,(0.5+d)*c,b,0.5*c);d=4*(Math.SQRT2-1)/3*0.4;I(a,0.5*b,0.1*c,!0,!0);J(a,(0.5+d)*b,0.1*c,0.9*b,(0.5-d)*c,0.9*b,0.5*c);J(a,
0.9*b,(0.5+d)*c,(0.5+d)*b,0.9*c,0.5*b,0.9*c);J(a,(0.5-d)*b,0.9*c,0.1*b,(0.5+d)*c,0.1*b,0.5*c);J(a,0.1*b,(0.5-d)*c,(0.5-d)*b,0.1*c,0.5*b,0.1*c);var d=0.07,f=0,g=-0.707*0.11,h=4*(Math.SQRT2-1)/3*d;I(a,(0.5+d+f)*b,(0.5+g)*c,!0);J(a,(0.5+d+f)*b,(0.5-h+g)*c,(0.5+h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);J(a,(0.5-h+f)*b,(0.5-d+g)*c,(0.5-d+f)*b,(0.5-h+g)*c,(0.5-d+f)*b,(0.5+g)*c);J(a,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-h+f)*b,(0.5+d+g)*c,(0.5+f)*b,(0.5+d+g)*c);J(a,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+d+f)*b,(0.5+h+g)*c,
(0.5+d+f)*b,(0.5+g)*c);d=0.07;f=-0.707*0.11;g=0.707*0.11;h=4*(Math.SQRT2-1)/3*d;I(a,(0.5+d+f)*b,(0.5+g)*c,!0);J(a,(0.5+d+f)*b,(0.5-h+g)*c,(0.5+h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);J(a,(0.5-h+f)*b,(0.5-d+g)*c,(0.5-d+f)*b,(0.5-h+g)*c,(0.5-d+f)*b,(0.5+g)*c);J(a,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-h+f)*b,(0.5+d+g)*c,(0.5+f)*b,(0.5+d+g)*c);J(a,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+d+f)*b,(0.5+h+g)*c,(0.5+d+f)*b,(0.5+g)*c);d=0.07;f=0.707*0.11;g=0.707*0.11;h=4*(Math.SQRT2-1)/3*d;I(a,(0.5+d+f)*b,(0.5+g)*c,!0);J(a,(0.5+
d+f)*b,(0.5-h+g)*c,(0.5+h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);J(a,(0.5-h+f)*b,(0.5-d+g)*c,(0.5-d+f)*b,(0.5-h+g)*c,(0.5-d+f)*b,(0.5+g)*c);J(a,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-h+f)*b,(0.5+d+g)*c,(0.5+f)*b,(0.5+d+g)*c);J(a,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+d+f)*b,(0.5+h+g)*c,(0.5+d+f)*b,(0.5+g)*c);b=a.s;b.G=new H(0.146,0.146);b.H=new H(0.853,0.853);b.Hc=Xg;t.v(a);return b},NotAllowed:function(a,b,c){var d=F.va,f=0.5*d,g=0.5;a=t.u();I(a,0.5*b,(0.5-g)*c,!0);J(a,(0.5-f)*b,(0.5-g)*c,(0.5-g)*b,(0.5-f)*c,(0.5-
g)*b,0.5*c);J(a,(0.5-g)*b,(0.5+f)*c,(0.5-f)*b,(0.5+g)*c,0.5*b,(0.5+g)*c);J(a,(0.5+f)*b,(0.5+g)*c,(0.5+g)*b,(0.5+f)*c,(0.5+g)*b,0.5*c);J(a,(0.5+g)*b,(0.5-f)*c,(0.5+f)*b,(0.5-g)*c,0.5*b,(0.5-g)*c);var g=0.4,f=0.4*d,d=t.K(),h=t.K(),k=t.K(),l=t.K();F.Vi(0.5,0.5-g,0.5+f,0.5-g,0.5+g,0.5-f,0.5+g,0.5,0.42,d,h,k,l,l);var m=t.K(),n=t.K(),p=t.K();F.Vi(0.5,0.5-g,0.5+f,0.5-g,0.5+g,0.5-f,0.5+g,0.5,0.58,l,l,p,m,n);var q=t.K(),r=t.K(),s=t.K();F.Vi(0.5,0.5+g,0.5-f,0.5+g,0.5-g,0.5+f,0.5-g,0.5,0.42,q,r,s,l,l);var u=
t.K(),v=t.K(),D=t.K();F.Vi(0.5,0.5+g,0.5-f,0.5+g,0.5-g,0.5+f,0.5-g,0.5,0.58,l,l,D,u,v);I(a,D.x*b,D.y*c,!0,!0);J(a,u.x*b,u.y*c,v.x*b,v.y*c,(0.5-g)*b,0.5*c);J(a,(0.5-g)*b,(0.5-f)*c,(0.5-f)*b,(0.5-g)*c,0.5*b,(0.5-g)*c);J(a,d.x*b,d.y*c,h.x*b,h.y*c,k.x*b,k.y*c);a.lineTo(D.x*b,D.y*c);L(a);I(a,s.x*b,s.y*c,!0,!0);a.lineTo(p.x*b,p.y*c);J(a,m.x*b,m.y*c,n.x*b,n.y*c,(0.5+g)*b,0.5*c);J(a,(0.5+g)*b,(0.5+f)*c,(0.5+f)*b,(0.5+g)*c,0.5*b,(0.5+g)*c);J(a,q.x*b,q.y*c,r.x*b,r.y*c,s.x*b,s.y*c);L(a);t.B(d);t.B(h);t.B(k);
t.B(l);t.B(m);t.B(n);t.B(p);t.B(q);t.B(r);t.B(s);t.B(u);t.B(v);t.B(D);b=a.s;t.v(a);b.Hc=Xg;return b},Fragile:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(0.25*b,0);a.lineTo(0.2*b,0.15*c);a.lineTo(0.3*b,0.25*c);a.lineTo(0.29*b,0.33*c);a.lineTo(0.35*b,0.25*c);a.lineTo(0.3*b,0.15*c);a.lineTo(0.4*b,0);a.lineTo(1*b,0);J(a,1*b,0.25*c,0.75*b,0.5*c,0.55*b,0.5*c);a.lineTo(0.55*b,0.9*c);a.lineTo(0.7*b,0.9*c);a.lineTo(0.7*b,1*c);a.lineTo(0.3*b,1*c);a.lineTo(0.3*b,0.9*c);a.lineTo(0.45*b,0.9*c);a.lineTo(0.45*
b,0.5*c);J(a,0.25*b,0.5*c,0,0.25*c,0,0);L(a);b=a.s;b.G=new H(0.25,0);b.H=new H(0.75,0.4);t.v(a);return b},HourGlass:function(a,b,c){a=t.u();I(a,0.65*b,0.5*c,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0.35*b,0.5*c);a.lineTo(0,0);a.lineTo(1*b,0);L(a);b=a.s;t.v(a);return b},Lightning:function(a,b,c){a=t.u();I(a,0*b,0.55*c,!0);a.lineTo(0.75*b,0);a.lineTo(0.25*b,0.45*c);a.lineTo(0.9*b,0.48*c);a.lineTo(0.4*b,1*c);a.lineTo(0.65*b,0.55*c);L(a);b=a.s;t.v(a);return b},Parallelogram1:function(a,b,c){a=a?
a.Gc:0;0===a&&(a=0.1);var d=t.u();I(d,a*b,0,!0);d.lineTo(1*b,0);d.lineTo((1-a)*b,1*c);d.lineTo(0,1*c);L(d);b=d.s;b.G=new H(a,0);b.H=new H(1-a,1);t.v(d);return b},Input:"Output",Output:function(a,b,c){a=t.u();I(a,0,1*c,!0);a.lineTo(0.1*b,0);a.lineTo(1*b,0);a.lineTo(0.9*b,1*c);L(a);b=a.s;b.G=new H(0.1,0);b.H=new H(0.9,1);t.v(a);return b},Parallelogram2:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.25);var d=t.u();I(d,a*b,0,!0);d.lineTo(1*b,0);d.lineTo((1-a)*b,1*c);d.lineTo(0,1*c);L(d);b=d.s;b.G=new H(a,0);
b.H=new H(1-a,1);t.v(d);return b},ThickCross:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.25);var d=t.u();I(d,(0.5-a/2)*b,0,!0);d.lineTo((0.5+a/2)*b,0);d.lineTo((0.5+a/2)*b,(0.5-a/2)*c);d.lineTo(1*b,(0.5-a/2)*c);d.lineTo(1*b,(0.5+a/2)*c);d.lineTo((0.5+a/2)*b,(0.5+a/2)*c);d.lineTo((0.5+a/2)*b,1*c);d.lineTo((0.5-a/2)*b,1*c);d.lineTo((0.5-a/2)*b,(0.5+a/2)*c);d.lineTo(0,(0.5+a/2)*c);d.lineTo(0,(0.5-a/2)*c);d.lineTo((0.5-a/2)*b,(0.5-a/2)*c);L(d);b=d.s;b.G=new H(0.5-a/2,0.5-a/2);b.H=new H(0.5+a/2,0.5+a/2);t.v(d);
return b},ThickX:function(a,b,c){a=0.25/Math.SQRT2;var d=t.u();I(d,0.3*b,0,!0);d.lineTo(0.5*b,0.2*c);d.lineTo(0.7*b,0);d.lineTo(1*b,0.3*c);d.lineTo(0.8*b,0.5*c);d.lineTo(1*b,0.7*c);d.lineTo(0.7*b,1*c);d.lineTo(0.5*b,0.8*c);d.lineTo(0.3*b,1*c);d.lineTo(0,0.7*c);d.lineTo(0.2*b,0.5*c);d.lineTo(0,0.3*c);L(d);b=d.s;b.G=new H(0.5-a,0.5-a);b.H=new H(0.5+a,0.5+a);t.v(d);return b},ThinCross:function(a,b,c){var d=a?a.Gc:0;0===d&&(d=0.1);a=t.u();I(a,(0.5-d/2)*b,0,!0);a.lineTo((0.5+d/2)*b,0);a.lineTo((0.5+d/
2)*b,(0.5-d/2)*c);a.lineTo(1*b,(0.5-d/2)*c);a.lineTo(1*b,(0.5+d/2)*c);a.lineTo((0.5+d/2)*b,(0.5+d/2)*c);a.lineTo((0.5+d/2)*b,1*c);a.lineTo((0.5-d/2)*b,1*c);a.lineTo((0.5-d/2)*b,(0.5+d/2)*c);a.lineTo(0,(0.5+d/2)*c);a.lineTo(0,(0.5-d/2)*c);a.lineTo((0.5-d/2)*b,(0.5-d/2)*c);L(a);b=a.s;t.v(a);return b},ThinX:function(a,b,c){a=t.u();I(a,0.1*b,0,!0);a.lineTo(0.5*b,0.4*c);a.lineTo(0.9*b,0);a.lineTo(1*b,0.1*c);a.lineTo(0.6*b,0.5*c);a.lineTo(1*b,0.9*c);a.lineTo(0.9*b,1*c);a.lineTo(0.5*b,0.6*c);a.lineTo(0.1*
b,1*c);a.lineTo(0,0.9*c);a.lineTo(0.4*b,0.5*c);a.lineTo(0,0.1*c);L(a);return a.s},RightTriangle:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);L(a);b=a.s;b.G=new H(0,0.5);b.H=new H(0.5,1);t.v(a);return b},RoundedIBeam:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(1*b,0);J(a,0.5*b,0.25*c,0.5*b,0.75*c,1*b,1*c);a.lineTo(0,1*c);J(a,0.5*b,0.75*c,0.5*b,0.25*c,0,0);L(a);b=a.s;t.v(a);return b},RoundedRectangle:function(a,b,c){var d=a?a.Gc:0;0>=d&&(d=5);d=Math.min(d,b/3);d=Math.min(d,
c/3);a=d*F.va;var f=t.u();I(f,d,0,!0);f.lineTo(b-d,0);J(f,b-a,0,b,a,b,d);f.lineTo(b,c-d);J(f,b,c-a,b-a,c,b-d,c);f.lineTo(d,c);J(f,a,c,0,c-a,0,c-d);f.lineTo(0,d);J(f,0,a,a,0,d,0);L(f);b=f.s;1<a?(b.G=new H(0,0,a,a),b.H=new H(1,1,-a,-a)):(b.G=Eb,b.H=Pb);t.v(f);return b},Border:function(a,b,c){var d=a?a.Gc:0;0>=d&&(d=5);d=Math.min(d,b/3);d=Math.min(d,c/3);a=t.u();I(a,d,0,!0);a.lineTo(b-d,0);J(a,b-0,0,b,0,b,d);a.lineTo(b,c-d);J(a,b,c-0,b-0,c,b-d,c);a.lineTo(d,c);J(a,0,c,0,c-0,0,c-d);a.lineTo(0,d);J(a,
0,0,0,0,d,0);L(a);b=a.s;b.G=Eb;b.H=Pb;t.v(a);return b},SquareIBeam:function(a,b,c){var d=a?a.Gc:0;0===d&&(d=0.2);a=t.u();I(a,0,0,!0);a.lineTo(1*b,0);a.lineTo(1*b,d*c);a.lineTo((0.5+d/2)*b,d*c);a.lineTo((0.5+d/2)*b,(1-d)*c);a.lineTo(1*b,(1-d)*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,(1-d)*c);a.lineTo((0.5-d/2)*b,(1-d)*c);a.lineTo((0.5-d/2)*b,d*c);a.lineTo(0,d*c);L(a);b=a.s;t.v(a);return b},Trapezoid:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.2);var d=t.u();I(d,a*b,0,!0);d.lineTo((1-a)*b,0);d.lineTo(1*
b,1*c);d.lineTo(0,1*c);L(d);b=d.s;b.G=new H(a,0);b.H=new H(1-a,1);t.v(d);return b},ManualLoop:"ManualOperation",ManualOperation:function(a,b,c){var d=a?a.Gc:0;a=t.u();I(a,d,0,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(0.9*b,1*c);a.lineTo(0.1*b,1*c);L(a);b=a.s;b.G=new H(0.1,0);b.H=new H(0.9,1);t.v(a);return b},GenderMale:function(a,b,c){a=t.u();var d=F.va,f=0.4*d,g=0.4,h=t.K(),k=t.K(),l=t.K(),m=t.K();I(a,(0.5-g)*b,0.5*c,!0);J(a,(0.5-g)*b,(0.5-f)*c,(0.5-f)*b,(0.5-g)*c,0.5*b,(0.5-g)*c);F.Vi(0.5,0.5-
g,0.5+f,0.5-g,0.5+g,0.5-f,0.5+g,0.5,0.44,l,m,k,h,h);J(a,l.x*b,l.y*c,m.x*b,m.y*c,k.x*b,k.y*c);var n=t.mc(k.x,k.y);F.Vi(0.5,0.5-g,0.5+f,0.5-g,0.5+g,0.5-f,0.5+g,0.5,0.56,h,h,k,l,m);var p=t.mc(k.x,k.y);a.lineTo((0.1*n.x+0.855)*b,0.1*n.y*c);a.lineTo(0.85*b,0.1*n.y*c);a.lineTo(0.85*b,0);a.lineTo(1*b,0);a.lineTo(1*b,0.15*c);a.lineTo((0.1*p.x+0.9)*b,0.15*c);a.lineTo((0.1*p.x+0.9)*b,(0.1*p.y+0.05*0.9)*c);a.lineTo(p.x*b,p.y*c);J(a,l.x*b,l.y*c,m.x*b,m.y*c,(0.5+g)*b,0.5*c);J(a,(0.5+g)*b,(0.5+f)*c,(0.5+f)*b,(0.5+
g)*c,0.5*b,(0.5+g)*c);J(a,(0.5-f)*b,(0.5+g)*c,(0.5-g)*b,(0.5+f)*c,(0.5-g)*b,0.5*c);g=0.35;f=0.35*d;I(a,0.5*b,(0.5-g)*c,!0,!0);J(a,(0.5-f)*b,(0.5-g)*c,(0.5-g)*b,(0.5-f)*c,(0.5-g)*b,0.5*c);J(a,(0.5-g)*b,(0.5+f)*c,(0.5-f)*b,(0.5+g)*c,0.5*b,(0.5+g)*c);J(a,(0.5+f)*b,(0.5+g)*c,(0.5+g)*b,(0.5+f)*c,(0.5+g)*b,0.5*c);J(a,(0.5+g)*b,(0.5-f)*c,(0.5+f)*b,(0.5-g)*c,0.5*b,(0.5-g)*c);I(a,(0.5-g)*b,0.5*c,!0);t.B(h);t.B(k);t.B(l);t.B(m);t.B(n);t.B(p);b=a.s;b.G=new H(0.202,0.257);b.H=new H(0.692,0.839);b.Hc=Xg;t.v(a);
return b},GenderFemale:function(a,b,c){a=t.u();var d=0.375,f=0,g=-0.125,h=4*(Math.SQRT2-1)/3*d;I(a,(0.525+f)*b,(0.5+d+g)*c,!0);J(a,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+d+f)*b,(0.5+h+g)*c,(0.5+d+f)*b,(0.5+g)*c);J(a,(0.5+d+f)*b,(0.5-h+g)*c,(0.5+h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);J(a,(0.5-h+f)*b,(0.5-d+g)*c,(0.5-d+f)*b,(0.5-h+g)*c,(0.5-d+f)*b,(0.5+g)*c);J(a,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-h+f)*b,(0.5+d+g)*c,(0.475+f)*b,(0.5+d+g)*c);a.lineTo(0.475*b,0.85*c);a.lineTo(0.425*b,0.85*c);a.lineTo(0.425*b,0.9*c);
a.lineTo(0.475*b,0.9*c);a.lineTo(0.475*b,1*c);a.lineTo(0.525*b,1*c);a.lineTo(0.525*b,0.9*c);a.lineTo(0.575*b,0.9*c);a.lineTo(0.575*b,0.85*c);a.lineTo(0.525*b,0.85*c);L(a);d=0.325;f=0;g=-0.125;h=4*(Math.SQRT2-1)/3*d;I(a,(0.5+d+f)*b,(0.5+g)*c,!0,!0);J(a,(0.5+d+f)*b,(0.5+h+g)*c,(0.5+h+f)*b,(0.5+d+g)*c,(0.5+f)*b,(0.5+d+g)*c);J(a,(0.5-h+f)*b,(0.5+d+g)*c,(0.5-d+f)*b,(0.5+h+g)*c,(0.5-d+f)*b,(0.5+g)*c);J(a,(0.5-d+f)*b,(0.5-h+g)*c,(0.5-h+f)*b,(0.5-d+g)*c,(0.5+f)*b,(0.5-d+g)*c);J(a,(0.5+h+f)*b,(0.5-d+g)*c,
(0.5+d+f)*b,(0.5-h+g)*c,(0.5+d+f)*b,(0.5+g)*c);I(a,(0.525+f)*b,(0.5+d+g)*c,!0);b=a.s;b.G=new H(0.232,0.136);b.H=new H(0.782,0.611);b.Hc=Xg;t.v(a);return b},PlusLine:function(a,b,c){a=t.u();I(a,0,0.5*c,!1);a.lineTo(1*b,0.5*c);a.moveTo(0.5*b,0);a.lineTo(0.5*b,1*c);b=a.s;t.v(a);return b},XLine:function(a,b,c){a=t.u();I(a,0,1*c,!1);a.lineTo(1*b,0);a.moveTo(0,0);a.lineTo(1*b,1*c);b=a.s;t.v(a);return b},AsteriskLine:function(a,b,c){a=t.u();var d=0.2/Math.SQRT2;I(a,d*b,(1-d)*c,!1);a.lineTo((1-d)*b,d*c);
a.moveTo(d*b,d*c);a.lineTo((1-d)*b,(1-d)*c);a.moveTo(0*b,0.5*c);a.lineTo(1*b,0.5*c);a.moveTo(0.5*b,0*c);a.lineTo(0.5*b,1*c);b=a.s;t.v(a);return b},CircleLine:function(a,b,c){var d=0.5*F.va;a=t.u();I(a,1*b,0.5*c,!1);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0,(0.5+d)*c,0,0.5*c);J(a,0,(0.5-d)*c,(0.5-d)*b,0,0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);b=a.s;b.G=new H(0.146,0.146);b.H=new H(0.853,0.853);b.Hc=Xg;t.v(a);return b},Pie:function(a,b,c){a=t.u();var d=4*(Math.SQRT2-1)/
3*0.5;I(a,(0.5*Math.SQRT2/2+0.5)*b,(0.5-0.5*Math.SQRT2/2)*c,!0);J(a,0.7*b,0*c,0.5*b,0*c,0.5*b,0*c);J(a,(0.5-d+0)*b,0*c,0*b,(0.5-d+0)*c,0*b,0.5*c);J(a,0*b,(0.5+d+0)*c,(0.5-d+0)*b,1*c,0.5*b,1*c);J(a,(0.5+d+0)*b,1*c,1*b,(0.5+d+0)*c,1*b,0.5*c);a.lineTo(0.5*b,0.5*c);L(a);b=a.s;t.v(a);return b},PiePiece:function(a,b,c){var d=F.va/Math.SQRT2*0.5,f=Math.SQRT2/2,g=1-Math.SQRT2/2;a=t.u();I(a,b,c,!0);J(a,b,(1-d)*c,(f+d)*b,(g+d)*c,f*b,g*c);a.lineTo(0,c);L(a);b=a.s;t.v(a);return b},StopSign:function(a,b,c){a=
1/(Math.SQRT2+2);var d=t.u();I(d,a*b,0,!0);d.lineTo((1-a)*b,0);d.lineTo(1*b,a*c);d.lineTo(1*b,(1-a)*c);d.lineTo((1-a)*b,1*c);d.lineTo(a*b,1*c);d.lineTo(0,(1-a)*c);d.lineTo(0,a*c);L(d);b=d.s;b.G=new H(a/2,a/2);b.H=new H(1-a/2,1-a/2);t.v(d);return b},LogicImplies:function(a,b,c){var d=a?a.Gc:0;0===d&&(d=0.2);a=t.u();I(a,(1-d)*b,0*c,!1);a.lineTo(1*b,0.5*c);a.lineTo((1-d)*b,c);a.moveTo(0,0.5*c);a.lineTo(b,0.5*c);b=a.s;b.G=Eb;b.H=new H(0.8,0.5);t.v(a);return b},LogicIff:function(a,b,c){var d=a?a.Gc:0;
0===d&&(d=0.2);a=t.u();I(a,(1-d)*b,0*c,!1);a.lineTo(1*b,0.5*c);a.lineTo((1-d)*b,c);a.moveTo(0,0.5*c);a.lineTo(b,0.5*c);a.moveTo(d*b,0);a.lineTo(0,0.5*c);a.lineTo(d*b,c);b=a.s;b.G=new H(0.2,0);b.H=new H(0.8,0.5);t.v(a);return b},LogicNot:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(1*b,0);a.lineTo(1*b,1*c);b=a.s;t.v(a);return b},LogicAnd:function(a,b,c){a=t.u();I(a,0,1*c,!1);a.lineTo(0.5*b,0);a.lineTo(1*b,1*c);b=a.s;b.G=new H(0.25,0.5);b.H=new H(0.75,1);t.v(a);return b},LogicOr:function(a,b,c){a=t.u();
I(a,0,0,!1);a.lineTo(0.5*b,1*c);a.lineTo(1*b,0);b=a.s;b.G=new H(0.219,0);b.H=new H(0.78,0.409);t.v(a);return b},LogicXor:function(a,b,c){a=t.u();I(a,0.5*b,0,!1);a.lineTo(0.5*b,1*c);a.moveTo(0,0.5*c);a.lineTo(1*b,0.5*c);var d=0.5*F.va;J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0,(0.5+d)*c,0,0.5*c);J(a,0,(0.5-d)*c,(0.5-d)*b,0,0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);b=a.s;b.Hc=Xg;t.v(a);return b},LogicTruth:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(1*b,0);a.moveTo(0.5*
b,0);a.lineTo(0.5*b,1*c);b=a.s;t.v(a);return b},LogicFalsity:function(a,b,c){a=t.u();I(a,0,1*c,!1);a.lineTo(1*b,1*c);a.moveTo(0.5*b,1*c);a.lineTo(0.5*b,0);b=a.s;t.v(a);return b},LogicThereExists:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(1*b,0);a.lineTo(1*b,0.5*c);a.lineTo(0,0.5*c);a.moveTo(1*b,0.5*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);b=a.s;t.v(a);return b},LogicForAll:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(0.5*b,1*c);a.lineTo(1*b,0);a.moveTo(0.25*b,0.5*c);a.lineTo(0.75*b,0.5*c);b=a.s;b.G=
new H(0.25,0);b.H=new H(0.75,0.5);t.v(a);return b},LogicIsDefinedAs:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(b,0);a.moveTo(0,0.5*c);a.lineTo(b,0.5*c);a.moveTo(0,c);a.lineTo(b,c);b=a.s;b.G=new H(0.01,0.01);b.H=new H(0.99,0.49);t.v(a);return b},LogicIntersect:function(a,b,c){var d=0.5*F.va;a=t.u();I(a,0,1*c,!1);a.lineTo(0,0.5*c);J(a,0,(0.5-d)*c,(0.5-d)*b,0,0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);a.lineTo(1*b,1*c);b=a.s;b.G=new H(0,0.5);b.H=Pb;t.v(a);return b},LogicUnion:function(a,b,c){var d=
0.5*F.va;a=t.u();I(a,1*b,0,!1);a.lineTo(1*b,0.5*c);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0,(0.5+d)*c,0,0.5*c);a.lineTo(0,0);b=a.s;b.G=Eb;b.H=new H(1,0.5);t.v(a);return b},Arrow:function(a,b,c){var d=a?a.Gc:0,f=a?a.Ft:0;0===d&&(d=0.3);0===f&&(f=0.3);a=t.u();I(a,0,(0.5-f/2)*c,!0);a.lineTo((1-d)*b,(0.5-f/2)*c);a.lineTo((1-d)*b,0);a.lineTo(1*b,0.5*c);a.lineTo((1-d)*b,1*c);a.lineTo((1-d)*b,(0.5+f/2)*c);a.lineTo(0,(0.5+f/2)*c);L(a);b=a.s;b.G=new H(0,0.5-f/2);d=F.jl(0,0.5+f/2,1,0.5+
f/2,1-d,1,1,0.5,t.K());b.H=new H(d.x,d.y);t.B(d);t.v(a);return b},ISOProcess:"Chevron",Chevron:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(0.5*b,0);a.lineTo(1*b,0.5*c);a.lineTo(0.5*b,1*c);a.lineTo(0,1*c);a.lineTo(0.5*b,0.5*c);L(a);b=a.s;t.v(a);return b},DoubleArrow:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(0.3*b,0.214*c);a.lineTo(0.3*b,0);a.lineTo(1*b,0.5*c);a.lineTo(0.3*b,1*c);a.lineTo(0.3*b,0.786*c);a.lineTo(0,1*c);L(a);I(a,0.3*b,0.214*c,!1);a.lineTo(0.3*b,0.786*c);a.bb(!1);b=a.s;t.v(a);return b},
DoubleEndArrow:function(a,b,c){a=t.u();I(a,1*b,0.5*c,!0);a.lineTo(0.7*b,1*c);a.lineTo(0.7*b,0.7*c);a.lineTo(0.3*b,0.7*c);a.lineTo(0.3*b,1*c);a.lineTo(0,0.5*c);a.lineTo(0.3*b,0);a.lineTo(0.3*b,0.3*c);a.lineTo(0.7*b,0.3*c);a.lineTo(0.7*b,0);L(a);b=a.s;c=F.jl(0,0.5,0.3,0,0,0.3,0.3,0.3,t.K());b.G=new H(c.x,c.y);c=F.jl(0.7,1,1,0.5,0.7,0.7,1,0.7,c);b.H=new H(c.x,c.y);t.B(c);t.v(a);return b},IBeamArrow:function(a,b,c){a=t.u();I(a,1*b,0.5*c,!0);a.lineTo(0.7*b,1*c);a.lineTo(0.7*b,0.7*c);a.lineTo(0.2*b,0.7*
c);a.lineTo(0.2*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(0.2*b,0);a.lineTo(0.2*b,0.3*c);a.lineTo(0.7*b,0.3*c);a.lineTo(0.7*b,0);L(a);b=a.s;b.G=new H(0,0.3);c=F.jl(0.7,1,1,0.5,0.7,0.7,1,0.7,t.K());b.H=new H(c.x,c.y);t.B(c);t.v(a);return b},Pointer:function(a,b,c){a=t.u();I(a,1*b,0.5*c,!0);a.lineTo(0,1*c);a.lineTo(0.2*b,0.5*c);a.lineTo(0,0);L(a);b=a.s;b.G=new H(0.2,0.35);c=F.jl(0.2,0.65,1,0.65,0,1,1,0.5,t.K());b.H=new H(c.x,c.y);t.B(c);t.v(a);return b},RoundedPointer:function(a,b,c){a=t.u();I(a,
1*b,0.5*c,!0);a.lineTo(0,1*c);J(a,0.5*b,0.75*c,0.5*b,0.25*c,0,0);L(a);b=a.s;b.G=new H(0.4,0.35);c=F.jl(0.2,0.65,1,0.65,0,1,1,0.5,t.K());b.H=new H(c.x,c.y);t.B(c);t.v(a);return b},SplitEndArrow:function(a,b,c){a=t.u();I(a,1*b,0.5*c,!0);a.lineTo(0.7*b,1*c);a.lineTo(0.7*b,0.7*c);a.lineTo(0,0.7*c);a.lineTo(0.2*b,0.5*c);a.lineTo(0,0.3*c);a.lineTo(0.7*b,0.3*c);a.lineTo(0.7*b,0);L(a);b=a.s;b.G=new H(0.2,0.3);c=F.jl(0.7,1,1,0.5,0.7,0.7,1,0.7,t.K());b.H=new H(c.x,c.y);t.B(c);t.v(a);return b},MessageToUser:"SquareArrow",
SquareArrow:function(a,b,c){a=t.u();I(a,1*b,0.5*c,!0);a.lineTo(0.7*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(0.7*b,0);L(a);b=a.s;b.G=Eb;b.H=new H(0.7,1);t.v(a);return b},Cone1:function(a,b,c){var d=F.va;a=0.5*d;var f=0.1*d,d=t.u();I(d,0,0.9*c,!0);d.lineTo(0.5*b,0);d.lineTo(1*b,0.9*c);J(d,1*b,(0.9+f)*c,(0.5+a)*b,1*c,0.5*b,1*c);J(d,(0.5-a)*b,1*c,0,(0.9+f)*c,0,0.9*c);L(d);b=d.s;b.G=new H(0.25,0.5);b.H=new H(0.75,0.97);t.v(d);return b},Cone2:function(a,b,c){a=t.u();I(a,0,0.9*c,!0);J(a,(1-0.85/0.9)*
b,1*c,0.85/0.9*b,1*c,1*b,0.9*c);a.lineTo(0.5*b,0);a.lineTo(0,0.9*c);L(a);I(a,0,0.9*c,!1);J(a,(1-0.85/0.9)*b,0.8*c,0.85/0.9*b,0.8*c,1*b,0.9*c);a.bb(!1);b=a.s;b.G=new H(0.25,0.5);b.H=new H(0.75,0.82);t.v(a);return b},Cube1:function(a,b,c){a=t.u();I(a,0.5*b,1*c,!0);a.lineTo(1*b,0.85*c);a.lineTo(1*b,0.15*c);a.lineTo(0.5*b,0*c);a.lineTo(0*b,0.15*c);a.lineTo(0*b,0.85*c);L(a);I(a,0.5*b,1*c,!1);a.lineTo(0.5*b,0.3*c);a.lineTo(0,0.15*c);a.moveTo(0.5*b,0.3*c);a.lineTo(1*b,0.15*c);a.bb(!1);b=a.s;b.G=new H(0,
0.3);b.H=new H(0.5,0.85);t.v(a);return b},Cube2:function(a,b,c){a=t.u();I(a,0,0.3*c,!0);a.lineTo(0*b,1*c);a.lineTo(0.7*b,c);a.lineTo(1*b,0.7*c);a.lineTo(1*b,0*c);a.lineTo(0.3*b,0*c);L(a);I(a,0,0.3*c,!1);a.lineTo(0.7*b,0.3*c);a.lineTo(1*b,0*c);a.moveTo(0.7*b,0.3*c);a.lineTo(0.7*b,1*c);a.bb(!1);b=a.s;b.G=new H(0,0.3);b.H=new H(0.7,1);t.v(a);return b},MagneticData:"Cylinder1",Cylinder1:function(a,b,c){var d=F.va;a=0.5*d;var f=0.1*d,d=t.u();I(d,0,0.1*c,!0);J(d,0,(0.1-f)*c,(0.5-a)*b,0,0.5*b,0);J(d,(0.5+
a)*b,0,1*b,(0.1-f)*c,1*b,0.1*c);d.lineTo(b,0.9*c);J(d,1*b,(0.9+f)*c,(0.5+a)*b,1*c,0.5*b,1*c);J(d,(0.5-a)*b,1*c,0,(0.9+f)*c,0,0.9*c);d.lineTo(0,0.1*c);I(d,0,0.1*c,!1);J(d,0,(0.1+f)*c,(0.5-a)*b,0.2*c,0.5*b,0.2*c);J(d,(0.5+a)*b,0.2*c,1*b,(0.1+f)*c,1*b,0.1*c);d.bb(!1);b=d.s;b.G=new H(0,0.2);b.H=new H(1,0.9);t.v(d);return b},Cylinder2:function(a,b,c){var d=F.va;a=0.5*d;var f=0.1*d,d=t.u();I(d,0,0.9*c,!0);d.lineTo(0,0.1*c);J(d,0,(0.1-f)*c,(0.5-a)*b,0,0.5*b,0);J(d,(0.5+a)*b,0,1*b,(0.1-f)*c,1*b,0.1*c);d.lineTo(1*
b,0.9*c);J(d,1*b,(0.9+f)*c,(0.5+a)*b,1*c,0.5*b,1*c);J(d,(0.5-a)*b,1*c,0,(0.9+f)*c,0,0.9*c);I(d,0,0.9*c,!1);J(d,0,(0.9-f)*c,(0.5-a)*b,0.8*c,0.5*b,0.8*c);J(d,(0.5+a)*b,0.8*c,1*b,(0.9-f)*c,1*b,0.9*c);d.bb(!1);b=d.s;b.G=new H(0,0.1);b.H=new H(1,0.8);t.v(d);return b},Cylinder3:function(a,b,c){var d=F.va;a=0.1*d;var f=0.5*d,d=t.u();I(d,0.1*b,0,!0);d.lineTo(0.9*b,0);J(d,(0.9+a)*b,0,1*b,(0.5-f)*c,1*b,0.5*c);J(d,1*b,(0.5+f)*c,(0.9+a)*b,1*c,0.9*b,1*c);d.lineTo(0.1*b,1*c);J(d,(0.1-a)*b,1*c,0,(0.5+f)*c,0,0.5*
c);J(d,0,(0.5-f)*c,(0.1-a)*b,0,0.1*b,0);I(d,0.1*b,0,!1);J(d,(0.1+a)*b,0,0.2*b,(0.5-f)*c,0.2*b,0.5*c);J(d,0.2*b,(0.5+f)*c,(0.1+a)*b,1*c,0.1*b,1*c);d.bb(!1);b=d.s;b.G=new H(0.2,0);b.H=new H(0.9,1);t.v(d);return b},DirectData:"Cylinder4",Cylinder4:function(a,b,c){var d=F.va;a=0.1*d;var f=0.5*d,d=t.u();I(d,0.9*b,0,!0);J(d,(0.9+a)*b,0,1*b,(0.5-f)*c,1*b,0.5*c);J(d,1*b,(0.5+f)*c,(0.9+a)*b,1*c,0.9*b,1*c);d.lineTo(0.1*b,1*c);J(d,(0.1-a)*b,1*c,0,(0.5+f)*c,0,0.5*c);J(d,0,(0.5-f)*c,(0.1-a)*b,0,0.1*b,0);d.lineTo(0.9*
b,0);I(d,0.9*b,0,!1);J(d,(0.9-a)*b,0,0.8*b,(0.5-f)*c,0.8*b,0.5*c);J(d,0.8*b,(0.5+f)*c,(0.9-a)*b,1*c,0.9*b,1*c);d.bb(!1);b=d.s;b.G=new H(0.1,0);b.H=new H(0.8,1);t.v(d);return b},Prism1:function(a,b,c){a=t.u();I(a,0.25*b,0.25*c,!0);a.lineTo(0.75*b,0);a.lineTo(b,0.5*c);a.lineTo(0.5*b,c);a.lineTo(0,c);L(a);I(a,0.25*b,0.25*c,!1);a.lineTo(0.5*b,c);a.bb(!1);b=a.s;b.G=new H(0.408,0.172);b.H=new H(0.833,0.662);t.v(a);return b},Prism2:function(a,b,c){a=t.u();I(a,0,0.25*c,!0);a.lineTo(0.75*b,0);a.lineTo(1*b,
0.25*c);a.lineTo(0.75*b,0.75*c);a.lineTo(0,1*c);L(a);I(a,0,c,!1);a.lineTo(0.25*b,0.5*c);a.lineTo(b,0.25*c);a.moveTo(0,0.25*c);a.lineTo(0.25*b,0.5*c);a.bb(!1);b=a.s;b.G=new H(0.25,0.5);b.H=new H(0.75,0.75);t.v(a);return b},Pyramid1:function(a,b,c){a=t.u();I(a,0.5*b,0,!0);a.lineTo(b,0.75*c);a.lineTo(0.5*b,1*c);a.lineTo(0,0.75*c);L(a);I(a,0.5*b,0,!1);a.lineTo(0.5*b,1*c);a.bb(!1);b=a.s;b.G=new H(0.25,0.367);b.H=new H(0.75,0.875);t.v(a);return b},Pyramid2:function(a,b,c){a=t.u();I(a,0.5*b,0,!0);a.lineTo(b,
0.85*c);a.lineTo(0.5*b,1*c);a.lineTo(0,0.85*c);L(a);I(a,0.5*b,0,!1);a.lineTo(0.5*b,0.7*c);a.lineTo(0,0.85*c);a.moveTo(0.5*b,0.7*c);a.lineTo(1*b,0.85*c);a.bb(!1);b=a.s;b.G=new H(0.25,0.367);b.H=new H(0.75,0.875);t.v(a);return b},Actor:function(a,b,c){var d=F.va,f=0.2*d,g=0.1*d,h=0.5,k=0.1;a=t.u();I(a,h*b,(k+0.1)*c,!0);J(a,(h-f)*b,(k+0.1)*c,(h-0.2)*b,(k+g)*c,(h-0.2)*b,k*c);J(a,(h-0.2)*b,(k-g)*c,(h-f)*b,(k-0.1)*c,h*b,(k-0.1)*c);J(a,(h+f)*b,(k-0.1)*c,(h+0.2)*b,(k-g)*c,(h+0.2)*b,k*c);J(a,(h+0.2)*b,(k+
g)*c,(h+f)*b,(k+0.1)*c,h*b,(k+0.1)*c);f=0.05;g=d*f;I(a,0.5*b,0.2*c,!0);a.lineTo(0.95*b,0.2*c);h=0.95;k=0.25;J(a,(h+g)*b,(k-f)*c,(h+f)*b,(k-g)*c,(h+f)*b,k*c);a.lineTo(1*b,0.6*c);a.lineTo(0.85*b,0.6*c);a.lineTo(0.85*b,0.35*c);f=0.025;g=d*f;h=0.825;k=0.35;J(a,(h+f)*b,(k-g)*c,(h+g)*b,(k-f)*c,h*b,(k-f)*c);J(a,(h-g)*b,(k-f)*c,(h-f)*b,(k-g)*c,(h-f)*b,k*c);a.lineTo(0.8*b,1*c);a.lineTo(0.55*b,1*c);a.lineTo(0.55*b,0.7*c);f=0.05;g=d*f;h=0.5;k=0.7;J(a,(h+f)*b,(k-g)*c,(h+g)*b,(k-f)*c,h*b,(k-f)*c);J(a,(h-g)*b,
(k-f)*c,(h-f)*b,(k-g)*c,(h-f)*b,k*c);a.lineTo(0.45*b,1*c);a.lineTo(0.2*b,1*c);a.lineTo(0.2*b,0.35*c);f=0.025;g=d*f;h=0.175;k=0.35;J(a,(h+f)*b,(k-g)*c,(h+g)*b,(k-f)*c,h*b,(k-f)*c);J(a,(h-g)*b,(k-f)*c,(h-f)*b,(k-g)*c,(h-f)*b,k*c);a.lineTo(0.15*b,0.6*c);a.lineTo(0*b,0.6*c);a.lineTo(0*b,0.25*c);f=0.05;g=d*f;h=0.05;k=0.25;J(a,(h-f)*b,(k-g)*c,(h-g)*b,(k-f)*c,h*b,(k-f)*c);a.lineTo(0.5*b,0.2*c);b=a.s;b.G=new H(0.2,0.2);b.H=new H(0.8,0.65);t.v(a);return b},Card:function(a,b,c){a=t.u();I(a,1*b,0*c,!0);a.lineTo(1*
b,1*c);a.lineTo(0*b,1*c);a.lineTo(0*b,0.2*c);a.lineTo(0.2*b,0*c);L(a);b=a.s;b.G=new H(0,0.2);b.H=Pb;t.v(a);return b},Collate:function(a,b,c){a=t.u();I(a,0.5*b,0.5*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(0.5*b,0.5*c);I(a,0.5*b,0.5*c,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0.5*b,0.5*c);b=a.s;b.G=new H(0.25,0);b.H=new H(0.75,0.25);t.v(a);return b},CreateRequest:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.1);var d=t.u();I(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);L(d);I(d,0,a*
c,!1);d.lineTo(1*b,a*c);d.moveTo(0,(1-a)*c);d.lineTo(1*b,(1-a)*c);d.bb(!1);b=d.s;b.G=new H(0,a);b.H=new H(1,1-a);t.v(d);return b},Database:function(a,b,c){a=t.u();var d=F.va,f=0.5*d,d=0.1*d;I(a,1*b,0.1*c,!0);a.lineTo(1*b,0.9*c);J(a,1*b,(0.9+d)*c,(0.5+f)*b,1*c,0.5*b,1*c);J(a,(0.5-f)*b,1*c,0,(0.9+d)*c,0,0.9*c);a.lineTo(0,0.1*c);J(a,0,(0.1-d)*c,(0.5-f)*b,0,0.5*b,0);J(a,(0.5+f)*b,0,1*b,(0.1-d)*c,1*b,0.1*c);I(a,1*b,0.1*c,!1);J(a,1*b,(0.1+d)*c,(0.5+f)*b,0.2*c,0.5*b,0.2*c);J(a,(0.5-f)*b,0.2*c,0,(0.1+d)*
c,0,0.1*c);a.moveTo(1*b,0.2*c);J(a,1*b,(0.2+d)*c,(0.5+f)*b,0.3*c,0.5*b,0.3*c);J(a,(0.5-f)*b,0.3*c,0,(0.2+d)*c,0,0.2*c);a.moveTo(1*b,0.3*c);J(a,1*b,(0.3+d)*c,(0.5+f)*b,0.4*c,0.5*b,0.4*c);J(a,(0.5-f)*b,0.4*c,0,(0.3+d)*c,0,0.3*c);a.bb(!1);b=a.s;b.G=new H(0,0.4);b.H=new H(1,0.9);t.v(a);return b},StoredData:"DataStorage",DataStorage:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(0.75*b,0);J(a,1*b,0,1*b,1*c,0.75*b,1*c);a.lineTo(0,1*c);J(a,0.25*b,0.9*c,0.25*b,0.1*c,0,0);L(a);b=a.s;b.G=new H(0.226,0);b.H=new H(0.81,
1);t.v(a);return b},DiskStorage:function(a,b,c){a=t.u();var d=F.va,f=0.5*d,d=0.1*d;I(a,1*b,0.1*c,!0);a.lineTo(1*b,0.9*c);J(a,1*b,(0.9+d)*c,(0.5+f)*b,1*c,0.5*b,1*c);J(a,(0.5-f)*b,1*c,0,(0.9+d)*c,0,0.9*c);a.lineTo(0,0.1*c);J(a,0,(0.1-d)*c,(0.5-f)*b,0,0.5*b,0);J(a,(0.5+f)*b,0,1*b,(0.1-d)*c,1*b,0.1*c);I(a,1*b,0.1*c,!1);J(a,1*b,(0.1+d)*c,(0.5+f)*b,0.2*c,0.5*b,0.2*c);J(a,(0.5-f)*b,0.2*c,0,(0.1+d)*c,0,0.1*c);a.moveTo(1*b,0.2*c);J(a,1*b,(0.2+d)*c,(0.5+f)*b,0.3*c,0.5*b,0.3*c);J(a,(0.5-f)*b,0.3*c,0,(0.2+d)*
c,0,0.2*c);a.bb(!1);b=a.s;b.G=new H(0,0.3);b.H=new H(1,0.9);t.v(a);return b},Display:function(a,b,c){a=t.u();I(a,0.25*b,0,!0);a.lineTo(0.75*b,0);J(a,1*b,0,1*b,1*c,0.75*b,1*c);a.lineTo(0.25*b,1*c);a.lineTo(0,0.5*c);L(a);b=a.s;b.G=new H(0.25,0);b.H=new H(0.75,1);t.v(a);return b},DividedEvent:function(a,b,c){a=a?a.Gc:0;0===a?a=0.2:0.15>a&&(a=0.15);var d=t.u(),f=0.2*F.va;I(d,0,0.2*c,!0);J(d,0,(0.2-f)*c,(0.2-f)*b,0,0.2*b,0);d.lineTo(0.8*b,0);J(d,(0.8+f)*b,0,1*b,(0.2-f)*c,1*b,0.2*c);d.lineTo(1*b,0.8*c);
J(d,1*b,(0.8+f)*c,(0.8+f)*b,1*c,0.8*b,1*c);d.lineTo(0.2*b,1*c);J(d,(0.2-f)*b,1*c,0,(0.8+f)*c,0,0.8*c);d.lineTo(0,0.2*c);I(d,0,a*c,!1);d.lineTo(1*b,a*c);d.bb(!1);b=d.s;b.G=new H(0,a);b.H=new H(1,1-a);t.v(d);return b},DividedProcess:function(a,b,c){a=a?a.Gc:0;0.1>a&&(a=0.1);var d=t.u();I(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);L(d);I(d,0,a*c,!1);d.lineTo(1*b,a*c);d.bb(!1);b=d.s;b.G=new H(0,a);b.H=Pb;t.v(d);return b},Document:function(a,b,c){c/=0.8;a=t.u();I(a,0,0.7*c,!0);a.lineTo(0,
0);a.lineTo(1*b,0);a.lineTo(1*b,0.7*c);J(a,0.5*b,0.4*c,0.5*b,1*c,0,0.7*c);L(a);b=a.s;b.G=Eb;b.H=new H(1,0.6);t.v(a);return b},ExternalOrganization:function(a,b,c){a=a?a.Gc:0;0.2>a&&(a=0.2);var d=t.u();I(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);L(d);I(d,a*b,0,!1);d.lineTo(0,a*c);d.moveTo(1*b,a*c);d.lineTo((1-a)*b,0);d.moveTo(0,(1-a)*c);d.lineTo(a*b,1*c);d.moveTo((1-a)*b,1*c);d.lineTo(1*b,(1-a)*c);d.bb(!1);b=d.s;b.G=new H(a/2,a/2);b.H=new H(1-a/2,1-a/2);t.v(d);return b},ExternalProcess:function(a,
b,c){a=t.u();I(a,0.5*b,0,!0);a.lineTo(1*b,0.5*c);a.lineTo(0.5*b,1*c);a.lineTo(0,0.5*c);L(a);I(a,0.1*b,0.4*c,!1);a.lineTo(0.1*b,0.6*c);a.moveTo(0.9*b,0.6*c);a.lineTo(0.9*b,0.4*c);a.moveTo(0.6*b,0.1*c);a.lineTo(0.4*b,0.1*c);a.moveTo(0.4*b,0.9*c);a.lineTo(0.6*b,0.9*c);a.bb(!1);b=a.s;b.G=new H(0.25,0.25);b.H=new H(0.75,0.75);t.v(a);return b},File:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(0.75*b,0);a.lineTo(1*b,0.25*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);L(a);I(a,0.75*b,0,!1);a.lineTo(0.75*b,0.25*c);
a.lineTo(1*b,0.25*c);a.bb(!1);b=a.s;b.G=new H(0,0.25);b.H=Pb;t.v(a);return b},Interrupt:function(a,b,c){a=t.u();I(a,1*b,0.5*c,!0);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(1*b,0.5*c);I(a,1*b,0.5*c,!1);a.lineTo(1*b,1*c);I(a,1*b,0.5*c,!1);a.lineTo(1*b,0);b=a.s;b.G=new H(0,0.25);b.H=new H(0.5,0.75);t.v(a);return b},InternalStorage:function(a,b,c){var d=a?a.Gc:0;a=a?a.Ft:0;0===d&&(d=0.1);0===a&&(a=0.1);var f=t.u();I(f,0,0,!0);f.lineTo(1*b,0);f.lineTo(1*b,1*c);f.lineTo(0,1*c);L(f);I(f,d*b,0,!1);f.lineTo(d*
b,1*c);f.moveTo(0,a*c);f.lineTo(1*b,a*c);f.bb(!1);b=f.s;b.G=new H(d,a);b.H=Pb;t.v(f);return b},Junction:function(a,b,c){a=t.u();var d=1/Math.SQRT2,f=(1-1/Math.SQRT2)/2,g=0.5*F.va;I(a,1*b,0.5*c,!0);J(a,1*b,(0.5+g)*c,(0.5+g)*b,1*c,0.5*b,1*c);J(a,(0.5-g)*b,1*c,0,(0.5+g)*c,0,0.5*c);J(a,0,(0.5-g)*c,(0.5-g)*b,0,0.5*b,0);J(a,(0.5+g)*b,0,1*b,(0.5-g)*c,1*b,0.5*c);I(a,(f+d)*b,(f+d)*c,!1);a.lineTo(f*b,f*c);a.moveTo(f*b,(f+d)*c);a.lineTo((f+d)*b,f*c);a.bb(!1);b=a.s;b.Hc=Xg;t.v(a);return b},LinedDocument:function(a,
b,c){c/=0.8;a=t.u();I(a,0,0.7*c,!0);a.lineTo(0,0);a.lineTo(1*b,0);a.lineTo(1*b,0.7*c);J(a,0.5*b,0.4*c,0.5*b,1*c,0,0.7*c);L(a);I(a,0.1*b,0,!1);a.lineTo(0.1*b,0.75*c);a.bb(!1);b=a.s;b.G=new H(0.1,0);b.H=new H(1,0.6);t.v(a);return b},LoopLimit:function(a,b,c){a=t.u();I(a,0,1*c,!0);a.lineTo(0,0.25*c);a.lineTo(0.25*b,0);a.lineTo(0.75*b,0);a.lineTo(1*b,0.25*c);a.lineTo(1*b,1*c);L(a);b=a.s;b.G=new H(0,0.25);b.H=Pb;t.v(a);return b},SequentialData:"MagneticTape",MagneticTape:function(a,b,c){a=t.u();var d=
0.5*F.va;I(a,0.5*b,1*c,!0);J(a,(0.5-d)*b,1*c,0,(0.5+d)*c,0,0.5*c);J(a,0,(0.5-d)*c,(0.5-d)*b,0,0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);J(a,1*b,(0.5+d)*c,(0.5+d)*b,0.9*c,0.6*b,0.9*c);a.lineTo(1*b,0.9*c);a.lineTo(1*b,1*c);a.lineTo(0.5*b,1*c);b=a.s;b.G=new H(0.15,0.15);b.H=new H(0.85,0.8);t.v(a);return b},ManualInput:function(a,b,c){a=t.u();I(a,1*b,0,!0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0.25*c);L(a);b=a.s;b.G=new H(0,0.25);b.H=Pb;t.v(a);return b},MessageFromUser:function(a,b,c){a=
a?a.Gc:0;0===a&&(a=0.7);var d=t.u();I(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(a*b,0.5*c);d.lineTo(1*b,1*c);d.lineTo(0,1*c);L(d);b=d.s;b.G=Eb;b.H=new H(a,1);t.v(d);return b},MicroformProcessing:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.25);var d=t.u();I(d,0,0,!0);d.lineTo(0.5*b,a*c);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0.5*b,(1-a)*c);d.lineTo(0,1*c);L(d);b=d.s;b.G=new H(0,a);b.H=new H(1,1-a);t.v(d);return b},MicroformRecording:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(0.75*b,0.25*c);a.lineTo(1*b,
0.15*c);a.lineTo(1*b,0.85*c);a.lineTo(0.75*b,0.75*c);a.lineTo(0,1*c);L(a);b=a.s;b.G=new H(0,0.25);b.H=new H(1,0.75);t.v(a);return b},MultiDocument:function(a,b,c){c/=0.8;a=t.u();I(a,b,0,!0);a.lineTo(b,0.5*c);J(a,0.96*b,0.47*c,0.93*b,0.45*c,0.9*b,0.44*c);a.lineTo(0.9*b,0.6*c);J(a,0.86*b,0.57*c,0.83*b,0.55*c,0.8*b,0.54*c);a.lineTo(0.8*b,0.7*c);J(a,0.4*b,0.4*c,0.4*b,1*c,0,0.7*c);a.lineTo(0,0.2*c);a.lineTo(0.1*b,0.2*c);a.lineTo(0.1*b,0.1*c);a.lineTo(0.2*b,0.1*c);a.lineTo(0.2*b,0);L(a);I(a,0.1*b,0.2*c,
!1);a.lineTo(0.8*b,0.2*c);a.lineTo(0.8*b,0.54*c);a.moveTo(0.2*b,0.1*c);a.lineTo(0.9*b,0.1*c);a.lineTo(0.9*b,0.44*c);a.bb(!1);b=a.s;b.G=new H(0,0.25);b.H=new H(0.8,0.77);t.v(a);return b},MultiProcess:function(a,b,c){a=t.u();I(a,0.1*b,0.1*c,!0);a.lineTo(0.2*b,0.1*c);a.lineTo(0.2*b,0);a.lineTo(1*b,0);a.lineTo(1*b,0.8*c);a.lineTo(0.9*b,0.8*c);a.lineTo(0.9*b,0.9*c);a.lineTo(0.8*b,0.9*c);a.lineTo(0.8*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0.2*c);a.lineTo(0.1*b,0.2*c);L(a);I(a,0.2*b,0.1*c,!1);a.lineTo(0.9*b,
0.1*c);a.lineTo(0.9*b,0.8*c);a.moveTo(0.1*b,0.2*c);a.lineTo(0.8*b,0.2*c);a.lineTo(0.8*b,0.9*c);a.bb(!1);b=a.s;b.G=new H(0,0.2);b.H=new H(0.8,1);t.v(a);return b},OfflineStorage:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.1);var d=1-a,f=t.u();I(f,0,0,!0);f.lineTo(1*b,0);f.lineTo(0.5*b,1*c);L(f);I(f,0.5*a*b,a*c,!1);f.lineTo((1-0.5*a)*b,a*c);f.bb(!1);b=f.s;b.G=new H(d/4+0.5*a,a);b.H=new H(3*d/4+0.5*a,a+0.5*d);t.v(f);return b},OffPageConnector:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(0.75*b,0);a.lineTo(1*
b,0.5*c);a.lineTo(0.75*b,1*c);a.lineTo(0,1*c);L(a);b=a.s;b.G=Eb;b.H=new H(0.75,1);t.v(a);return b},Or:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,1*b,0.5*c,!0);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0,(0.5+d)*c,0,0.5*c);J(a,0,(0.5-d)*c,(0.5-d)*b,0,0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);I(a,1*b,0.5*c,!1);a.lineTo(0,0.5*c);a.moveTo(0.5*b,1*c);a.lineTo(0.5*b,0);a.bb(!1);b=a.s;b.Hc=Xg;t.v(a);return b},PaperTape:function(a,b,c){c/=0.8;a=t.u();I(a,0,0.7*c,!0);a.lineTo(0,
0.3*c);J(a,0.5*b,0.6*c,0.5*b,0,1*b,0.3);a.lineTo(1*b,0.7*c);J(a,0.5*b,0.4*c,0.5*b,1*c,0,0.7*c);L(a);b=a.s;b.G=new H(0,0.49);b.H=new H(1,0.75);t.v(a);return b},PrimitiveFromCall:function(a,b,c){var d=a?a.Gc:0;a=a?a.Ft:0;0===d&&(d=0.1);0===a&&(a=0.3);var f=t.u();I(f,0,0,!0);f.lineTo(1*b,0);f.lineTo((1-a)*b,0.5*c);f.lineTo(1*b,1*c);f.lineTo(0,1*c);L(f);b=f.s;b.G=new H(d,0);b.H=new H(1-a,1);t.v(f);return b},PrimitiveToCall:function(a,b,c){var d=a?a.Gc:0;a=a?a.Ft:0;0===d&&(d=0.1);0===a&&(a=0.3);var f=
t.u();I(f,0,0,!0);f.lineTo((1-a)*b,0);f.lineTo(1*b,0.5*c);f.lineTo((1-a)*b,1*c);f.lineTo(0,1*c);L(f);b=f.s;b.G=new H(d,0);b.H=new H(1-a,1);t.v(f);return b},Subroutine:"Procedure",Procedure:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.1);var d=t.u();I(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);L(d);I(d,(1-a)*b,0,!1);d.lineTo((1-a)*b,1*c);d.moveTo(a*b,0);d.lineTo(a*b,1*c);d.bb(!1);b=d.s;b.G=new H(a,0);b.H=new H(1-a,1);t.v(d);return b},Process:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.1);var d=
t.u();I(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0,1*c);L(d);I(d,a*b,0,!1);d.lineTo(a*b,1*c);d.bb(!1);b=d.s;b.G=new H(a,0);b.H=Pb;t.v(d);return b},Sort:function(a,b,c){a=t.u();I(a,0.5*b,0,!0);a.lineTo(1*b,0.5*c);a.lineTo(0.5*b,1*c);a.lineTo(0,0.5*c);L(a);I(a,0,0.5*c,!1);a.lineTo(1*b,0.5*c);a.bb(!1);b=a.s;b.G=new H(0.25,0.25);b.H=new H(0.75,0.5);t.v(a);return b},Start:function(a,b,c){a=t.u();I(a,0.25*b,0,!0);I(a,0.25*b,0,!0);a.arcTo(270,180,0.75*b,0.5*c,0.25*b,0.5*c);a.arcTo(90,180,0.25*
b,0.5*c,0.25*b,0.5*c);I(a,0.25*b,0,!1);a.lineTo(0.25*b,1*c);a.moveTo(0.75*b,0);a.lineTo(0.75*b,1*c);a.bb(!1);b=a.s;b.G=new H(0.25,0);b.H=new H(0.75,1);t.v(a);return b},Terminator:function(a,b,c){a=t.u();I(a,0.25*b,0,!0);a.arcTo(270,180,0.75*b,0.5*c,0.25*b,0.5*c);a.arcTo(90,180,0.25*b,0.5*c,0.25*b,0.5*c);b=a.s;b.G=new H(0.23,0);b.H=new H(0.77,1);t.v(a);return b},TransmittalTape:function(a,b,c){a=a?a.Gc:0;0===a&&(a=0.1);var d=t.u();I(d,0,0,!0);d.lineTo(1*b,0);d.lineTo(1*b,1*c);d.lineTo(0.75*b,(1-a)*
c);d.lineTo(0,(1-a)*c);L(d);b=d.s;b.G=Eb;b.H=new H(1,1-a);t.v(d);return b},AndGate:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,0,0,!0);a.lineTo(0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);a.lineTo(0,1*c);L(a);b=a.s;b.G=Eb;b.H=new H(0.55,1);t.v(a);return b},Buffer:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(1*b,0.5*c);a.lineTo(0,1*c);L(a);b=a.s;b.G=new H(0,0.25);b.H=new H(0.5,0.75);t.v(a);return b},Clock:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,1*
b,0.5*c,!0);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0,(0.5+d)*c,0,0.5*c);J(a,0,(0.5-d)*c,(0.5-d)*b,0,0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);I(a,1*b,0.5*c,!1);a.lineTo(1*b,0.5*c);I(a,0.8*b,0.75*c,!1);a.lineTo(0.8*b,0.25*c);a.lineTo(0.6*b,0.25*c);a.lineTo(0.6*b,0.75*c);a.lineTo(0.4*b,0.75*c);a.lineTo(0.4*b,0.25*c);a.lineTo(0.2*b,0.25*c);a.lineTo(0.2*b,0.75*c);a.bb(!1);b=a.s;b.Hc=Xg;t.v(a);return b},Ground:function(a,b,c){a=t.u();I(a,0.5*b,0,!1);a.lineTo(0.5*b,0.4*c);
a.moveTo(0.2*b,0.6*c);a.lineTo(0.8*b,0.6*c);a.moveTo(0.3*b,0.8*c);a.lineTo(0.7*b,0.8*c);a.moveTo(0.4*b,1*c);a.lineTo(0.6*b,1*c);b=a.s;t.v(a);return b},Inverter:function(a,b,c){a=t.u();var d=0.1*F.va;I(a,0.8*b,0.5*c,!0);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(0.8*b,0.5*c);I(a,1*b,0.5*c,!0);J(a,1*b,(0.5+d)*c,(0.9+d)*b,0.6*c,0.9*b,0.6*c);J(a,(0.9-d)*b,0.6*c,0.8*b,(0.5+d)*c,0.8*b,0.5*c);J(a,0.8*b,(0.5-d)*c,(0.9-d)*b,0.4*c,0.9*b,0.4*c);J(a,(0.9+d)*b,0.4*c,1*b,(0.5-d)*c,1*b,0.5*c);b=a.s;b.G=new H(0,0.25);
b.H=new H(0.4,0.75);t.v(a);return b},NandGate:function(a,b,c){a=t.u();var d=F.va,f=0.5*d,g=0.4*d,d=0.1*d;I(a,0.8*b,0.5*c,!0);J(a,0.8*b,(0.5+g)*c,(0.4+f)*b,1*c,0.4*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(0.4*b,0);J(a,(0.4+f)*b,0,0.8*b,(0.5-g)*c,0.8*b,0.5*c);I(a,1*b,0.5*c,!0);J(a,1*b,(0.5+d)*c,(0.9+d)*b,0.6*c,0.9*b,0.6*c);J(a,(0.9-d)*b,0.6*c,0.8*b,(0.5+d)*c,0.8*b,0.5*c);J(a,0.8*b,(0.5-d)*c,(0.9-d)*b,0.4*c,0.9*b,0.4*c);J(a,(0.9+d)*b,0.4*c,1*b,(0.5-d)*c,1*b,0.5*c);b=a.s;b.G=new H(0,0.05);b.H=new H(0.55,
0.95);t.v(a);return b},NorGate:function(a,b,c){a=t.u();var d=F.va,f=0.5,g=d*f,h=0,k=0.5;I(a,0.8*b,0.5*c,!0);J(a,0.7*b,(k+g)*c,(h+g)*b,(k+f)*c,0,1*c);J(a,0.25*b,0.75*c,0.25*b,0.25*c,0,0);J(a,(h+g)*b,(k-f)*c,0.7*b,(k-g)*c,0.8*b,0.5*c);f=0.1;g=0.1*d;h=0.9;k=0.5;I(a,(h-f)*b,k*c,!0);J(a,(h-f)*b,(k-g)*c,(h-g)*b,(k-f)*c,h*b,(k-f)*c);J(a,(h+g)*b,(k-f)*c,(h+f)*b,(k-g)*c,(h+f)*b,k*c);J(a,(h+f)*b,(k+g)*c,(h+g)*b,(k+f)*c,h*b,(k+f)*c);J(a,(h-g)*b,(k+f)*c,(h-f)*b,(k+g)*c,(h-f)*b,k*c);b=a.s;b.G=new H(0.2,0.25);
b.H=new H(0.6,0.75);t.v(a);return b},OrGate:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,0,0,!0);J(a,(0+d+d)*b,0*c,0.8*b,(0.5-d)*c,1*b,0.5*c);J(a,0.8*b,(0.5+d)*c,(0+d+d)*b,1*c,0,1*c);J(a,0.25*b,0.75*c,0.25*b,0.25*c,0,0);L(a);b=a.s;b.G=new H(0.2,0.25);b.H=new H(0.75,0.75);t.v(a);return b},XnorGate:function(a,b,c){a=t.u();var d=F.va,f=0.5,g=d*f,h=0.2,k=0.5;I(a,0.1*b,0,!1);J(a,0.35*b,0.25*c,0.35*b,0.75*c,0.1*b,1*c);I(a,0.8*b,0.5*c,!0);J(a,0.7*b,(k+g)*c,(h+g)*b,(k+f)*c,0.2*b,1*c);J(a,0.45*b,0.75*c,0.45*
b,0.25*c,0.2*b,0);J(a,(h+g)*b,(k-f)*c,0.7*b,(k-g)*c,0.8*b,0.5*c);f=0.1;g=0.1*d;h=0.9;k=0.5;I(a,(h-f)*b,k*c,!0);J(a,(h-f)*b,(k-g)*c,(h-g)*b,(k-f)*c,h*b,(k-f)*c);J(a,(h+g)*b,(k-f)*c,(h+f)*b,(k-g)*c,(h+f)*b,k*c);J(a,(h+f)*b,(k+g)*c,(h+g)*b,(k+f)*c,h*b,(k+f)*c);J(a,(h-g)*b,(k+f)*c,(h-f)*b,(k+g)*c,(h-f)*b,k*c);b=a.s;b.G=new H(0.4,0.25);b.H=new H(0.65,0.75);t.v(a);return b},XorGate:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,0.1*b,0,!1);J(a,0.35*b,0.25*c,0.35*b,0.75*c,0.1*b,1*c);I(a,0.2*b,0,!0);J(a,(0.2+
d)*b,0*c,0.9*b,(0.5-d)*c,1*b,0.5*c);J(a,0.9*b,(0.5+d)*c,(0.2+d)*b,1*c,0.2*b,1*c);J(a,0.45*b,0.75*c,0.45*b,0.25*c,0.2*b,0);L(a);b=a.s;b.G=new H(0.4,0.25);b.H=new H(0.8,0.75);t.v(a);return b},Capacitor:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(0,1*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.s;t.v(a);return b},Resistor:function(a,b,c){a=t.u();I(a,0,0.5*c,!1);a.lineTo(0.1*b,0);a.lineTo(0.2*b,1*c);a.lineTo(0.3*b,0);a.lineTo(0.4*b,1*c);a.lineTo(0.5*b,0);a.lineTo(0.6*b,1*c);a.lineTo(0.7*b,0.5*c);b=a.s;t.v(a);
return b},Inductor:function(a,b,c){a=t.u();var d=0.1*F.va,f=0.1;I(a,(f-0.5*d)*b,c,!1);J(a,(f-d)*b,c,(f-0.1)*b,0,(f+0.1)*b,0);f=0.3;J(a,(f+0.1)*b,0,(f+d)*b,c,f*b,c);J(a,(f-d)*b,c,(f-0.1)*b,0,(f+0.1)*b,0);f=0.5;J(a,(f+0.1)*b,0,(f+d)*b,c,f*b,c);J(a,(f-d)*b,c,(f-0.1)*b,0,(f+0.1)*b,0);f=0.7;J(a,(f+0.1)*b,0,(f+d)*b,c,f*b,c);J(a,(f-d)*b,c,(f-0.1)*b,0,(f+0.1)*b,0);f=0.9;J(a,(f+0.1)*b,0,(f+d)*b,c,(f+0.5*d)*b,c);b=a.s;t.v(a);return b},ACvoltageSource:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,0*b,0.5*c,!1);
J(a,0*b,(0.5-d)*c,(0.5-d)*b,0*c,0.5*b,0*c);J(a,(0.5+d)*b,0*c,1*b,(0.5-d)*c,1*b,0.5*c);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0*b,(0.5+d)*c,0*b,0.5*c);a.moveTo(0.1*b,0.5*c);J(a,0.5*b,0*c,0.5*b,1*c,0.9*b,0.5*c);b=a.s;b.Hc=Xg;t.v(a);return b},DCvoltageSource:function(a,b,c){a=t.u();I(a,0,0.75*c,!1);a.lineTo(0,0.25*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.s;t.v(a);return b},Diode:function(a,b,c){a=t.u();I(a,1*b,0,!1);a.lineTo(1*b,0.5*c);a.lineTo(0,1*c);a.lineTo(0,0);a.lineTo(1*b,
0.5*c);a.lineTo(1*b,1*c);b=a.s;b.G=new H(0,0.25);b.H=new H(0.5,0.75);t.v(a);return b},Wifi:function(a,b,c){var d=b,f=c;b*=0.38;c*=0.6;a=t.u();var g=F.va,h=0.8*g,k=0.8,l=0,m=0.5,d=(d-b)/2,f=(f-c)/2;I(a,l*b+d,(m+k)*c+f,!0);J(a,(l-h)*b+d,(m+k)*c+f,(l-k)*b+d,(m+h)*c+f,(l-k)*b+d,m*c+f);J(a,(l-k)*b+d,(m-h)*c+f,(l-h)*b+d,(m-k)*c+f,l*b+d,(m-k)*c+f);J(a,l*b+d,(m-k)*c+f,(l-k+0.5*h)*b+d,(m-h)*c+f,(l-k+0.5*h)*b+d,m*c+f);J(a,(l-k+0.5*h)*b+d,(m+h)*c+f,l*b+d,(m+k)*c+f,l*b+d,(m+k)*c+f);L(a);h=0.4*g;k=0.4;l=0.2;m=
0.5;I(a,l*b+d,(m+k)*c+f,!0);J(a,(l-h)*b+d,(m+k)*c+f,(l-k)*b+d,(m+h)*c+f,(l-k)*b+d,m*c+f);J(a,(l-k)*b+d,(m-h)*c+f,(l-h)*b+d,(m-k)*c+f,l*b+d,(m-k)*c+f);J(a,l*b+d,(m-k)*c+f,(l-k+0.5*h)*b+d,(m-h)*c+f,(l-k+0.5*h)*b+d,m*c+f);J(a,(l-k+0.5*h)*b+d,(m+h)*c+f,l*b+d,(m+k)*c+f,l*b+d,(m+k)*c+f);L(a);h=0.2*g;k=0.2;m=l=0.5;I(a,(l-k)*b+d,m*c+f,!0);J(a,(l-k)*b+d,(m-h)*c+f,(l-h)*b+d,(m-k)*c+f,l*b+d,(m-k)*c+f);J(a,(l+h)*b+d,(m-k)*c+f,(l+k)*b+d,(m-h)*c+f,(l+k)*b+d,m*c+f);J(a,(l+k)*b+d,(m+h)*c+f,(l+h)*b+d,(m+k)*c+f,l*
b+d,(m+k)*c+f);J(a,(l-h)*b+d,(m+k)*c+f,(l-k)*b+d,(m+h)*c+f,(l-k)*b+d,m*c+f);h=0.4*g;k=0.4;l=0.8;m=0.5;I(a,l*b+d,(m-k)*c+f,!0);J(a,(l+h)*b+d,(m-k)*c+f,(l+k)*b+d,(m-h)*c+f,(l+k)*b+d,m*c+f);J(a,(l+k)*b+d,(m+h)*c+f,(l+h)*b+d,(m+k)*c+f,l*b+d,(m+k)*c+f);J(a,l*b+d,(m+k)*c+f,(l+k-0.5*h)*b+d,(m+h)*c+f,(l+k-0.5*h)*b+d,m*c+f);J(a,(l+k-0.5*h)*b+d,(m-h)*c+f,l*b+d,(m-k)*c+f,l*b+d,(m-k)*c+f);L(a);h=0.8*g;k=0.8;l=1;m=0.5;I(a,l*b+d,(m-k)*c+f,!0);J(a,(l+h)*b+d,(m-k)*c+f,(l+k)*b+d,(m-h)*c+f,(l+k)*b+d,m*c+f);J(a,(l+
k)*b+d,(m+h)*c+f,(l+h)*b+d,(m+k)*c+f,l*b+d,(m+k)*c+f);J(a,l*b+d,(m+k)*c+f,(l+k-0.5*h)*b+d,(m+h)*c+f,(l+k-0.5*h)*b+d,m*c+f);J(a,(l+k-0.5*h)*b+d,(m-h)*c+f,l*b+d,(m-k)*c+f,l*b+d,(m-k)*c+f);L(a);b=a.s;t.v(a);return b},Email:function(a,b,c){a=t.u();I(a,0,0,!0);a.lineTo(1*b,0);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0);L(a);I(a,0,0,!1);a.lineTo(0.5*b,0.6*c);a.lineTo(1*b,0);a.moveTo(0,1*c);a.lineTo(0.45*b,0.54*c);a.moveTo(1*b,1*c);a.lineTo(0.55*b,0.54*c);a.bb(!1);b=a.s;t.v(a);return b},Ethernet:function(a,
b,c){a=t.u();I(a,0.35*b,0,!0);a.lineTo(0.65*b,0);a.lineTo(0.65*b,0.4*c);a.lineTo(0.35*b,0.4*c);a.lineTo(0.35*b,0);L(a);I(a,0.1*b,1*c,!0,!0);a.lineTo(0.4*b,1*c);a.lineTo(0.4*b,0.6*c);a.lineTo(0.1*b,0.6*c);a.lineTo(0.1*b,1*c);L(a);I(a,0.6*b,1*c,!0,!0);a.lineTo(0.9*b,1*c);a.lineTo(0.9*b,0.6*c);a.lineTo(0.6*b,0.6*c);a.lineTo(0.6*b,1*c);L(a);I(a,0,0.5*c,!1);a.lineTo(1*b,0.5*c);a.moveTo(0.5*b,0.5*c);a.lineTo(0.5*b,0.4*c);a.moveTo(0.75*b,0.5*c);a.lineTo(0.75*b,0.6*c);a.moveTo(0.25*b,0.5*c);a.lineTo(0.25*
b,0.6*c);a.bb(!1);b=a.s;t.v(a);return b},Power:function(a,b,c){a=t.u();var d=F.va,f=0.4*d,g=0.4,h=t.K(),k=t.K(),l=t.K(),m=t.K();F.Vi(0.5,0.5-g,0.5+f,0.5-g,0.5+g,0.5-f,0.5+g,0.5,0.5,h,h,k,l,m);var n=t.mc(k.x,k.y);I(a,k.x*b,k.y*c,!0);J(a,l.x*b,l.y*c,m.x*b,m.y*c,(0.5+g)*b,0.5*c);J(a,(0.5+g)*b,(0.5+f)*c,(0.5+f)*b,(0.5+g)*c,0.5*b,(0.5+g)*c);J(a,(0.5-f)*b,(0.5+g)*c,(0.5-g)*b,(0.5+f)*c,(0.5-g)*b,0.5*c);F.Vi(0.5-g,0.5,0.5-g,0.5-f,0.5-f,0.5-g,0.5,0.5-g,0.5,l,m,k,h,h);J(a,l.x*b,l.y*c,m.x*b,m.y*c,k.x*b,k.y*
c);f=0.3*d;g=0.3;F.Vi(0.5-g,0.5,0.5-g,0.5-f,0.5-f,0.5-g,0.5,0.5-g,0.5,l,m,k,h,h);a.lineTo(k.x*b,k.y*c);J(a,m.x*b,m.y*c,l.x*b,l.y*c,(0.5-g)*b,0.5*c);J(a,(0.5-g)*b,(0.5+f)*c,(0.5-f)*b,(0.5+g)*c,0.5*b,(0.5+g)*c);J(a,(0.5+f)*b,(0.5+g)*c,(0.5+g)*b,(0.5+f)*c,(0.5+g)*b,0.5*c);F.Vi(0.5,0.5-g,0.5+f,0.5-g,0.5+g,0.5-f,0.5+g,0.5,0.5,h,h,k,l,m);J(a,m.x*b,m.y*c,l.x*b,l.y*c,k.x*b,k.y*c);L(a);I(a,0.45*b,0,!0);a.lineTo(0.45*b,0.5*c);a.lineTo(0.55*b,0.5*c);a.lineTo(0.55*b,0);L(a);t.B(h);t.B(k);t.B(l);t.B(m);t.B(n);
b=a.s;b.G=new H(0.25,0.55);b.H=new H(0.75,0.8);t.v(a);return b},Fallout:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,0*b,0.5*c,!0);J(a,0*b,(0.5-d)*c,(0.5-d)*b,0*c,0.5*b,0*c);J(a,(0.5+d)*b,0*c,1*b,(0.5-d)*c,1*b,0.5*c);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0*b,(0.5+d)*c,0*b,0.5*c);var f=d=0;I(a,(0.3+d)*b,(0.8+f)*c,!0,!0);a.lineTo((0.5+d)*b,(0.5+f)*c);a.lineTo((0.1+d)*b,(0.5+f)*c);a.lineTo((0.3+d)*b,(0.8+f)*c);d=0.4;f=0;L(a);I(a,(0.3+d)*b,(0.8+f)*c,!0,!0);a.lineTo((0.5+d)*b,(0.5+
f)*c);a.lineTo((0.1+d)*b,(0.5+f)*c);a.lineTo((0.3+d)*b,(0.8+f)*c);d=0.2;f=-0.3;L(a);I(a,(0.3+d)*b,(0.8+f)*c,!0,!0);a.lineTo((0.5+d)*b,(0.5+f)*c);a.lineTo((0.1+d)*b,(0.5+f)*c);a.lineTo((0.3+d)*b,(0.8+f)*c);L(a);b=a.s;b.Hc=Xg;t.v(a);return b},IrritationHazard:function(a,b,c){a=t.u();I(a,0.2*b,0*c,!0);a.lineTo(0.5*b,0.3*c);a.lineTo(0.8*b,0*c);a.lineTo(1*b,0.2*c);a.lineTo(0.7*b,0.5*c);a.lineTo(1*b,0.8*c);a.lineTo(0.8*b,1*c);a.lineTo(0.5*b,0.7*c);a.lineTo(0.2*b,1*c);a.lineTo(0*b,0.8*c);a.lineTo(0.3*b,
0.5*c);a.lineTo(0*b,0.2*c);L(a);b=a.s;b.G=new H(0.3,0.3);b.H=new H(0.7,0.7);t.v(a);return b},ElectricalHazard:function(a,b,c){a=t.u();I(a,0.37,0*c,!0);a.lineTo(0.5*b,0.11*c);a.lineTo(0.77*b,0.04*c);a.lineTo(0.33*b,0.49*c);a.lineTo(1*b,0.37*c);a.lineTo(0.63*b,0.86*c);a.lineTo(0.77*b,0.91*c);a.lineTo(0.34*b,1*c);a.lineTo(0.34*b,0.78*c);a.lineTo(0.44*b,0.8*c);a.lineTo(0.65*b,0.56*c);a.lineTo(0*b,0.68*c);L(a);b=a.s;t.v(a);return b},FireHazard:function(a,b,c){a=t.u();I(a,0.1*b,1*c,!0);J(a,-0.25*b,0.63*
c,0.45*b,0.44*c,0.29*b,0*c);J(a,0.48*b,0.17*c,0.54*b,0.35*c,0.51*b,0.42*c);J(a,0.59*b,0.29*c,0.58*b,0.28*c,0.59*b,0.18*c);J(a,0.8*b,0.34*c,0.88*b,0.43*c,0.75*b,0.6*c);J(a,0.87*b,0.48*c,0.88*b,0.43*c,0.88*b,0.31*c);J(a,1.17*b,0.76*c,0.82*b,0.8*c,0.9*b,1*c);L(a);b=a.s;b.G=new H(0.05,0.645);b.H=new H(0.884,0.908);t.v(a);return b},BpmnActivityLoop:function(a,b,c){a=t.u();var d=4*(Math.SQRT2-1)/3*0.5;I(a,(0.5*Math.SQRT2/2+0.5)*b,(1-(0.5-0.5*Math.SQRT2/2))*c,!1);J(a,1*b,0.7*c,1*b,0.5*c,1*b,0.5*c);J(a,1*
b,(0.5-d+0)*c,(0.5+d+0)*b,0*c,0.5*b,0*c);J(a,(0.5-d+0)*b,0*c,0*b,(0.5-d+0)*c,0*b,0.5*c);J(a,0*b,(0.5+d+0)*c,(0.5-d+0)*b,1*c,0.35*b,0.98*c);a.moveTo(0.35*b,0.8*c);a.lineTo(0.35*b,1*c);a.lineTo(0.15*b,1*c);b=a.s;t.v(a);return b},BpmnActivityParallel:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(0,1*c);a.moveTo(0.5*b,0);a.lineTo(0.5*b,1*c);a.moveTo(1*b,0);a.lineTo(1*b,1*c);b=a.s;t.v(a);return b},BpmnActivitySequential:function(a,b,c){a=t.u();I(a,0,0,!1);a.lineTo(1*b,0);a.moveTo(0,0.5*c);a.lineTo(1*b,
0.5*c);a.moveTo(0,1*c);a.lineTo(1*b,1*c);b=a.s;t.v(a);return b},BpmnActivityAdHoc:function(a,b,c){a=t.u();I(a,0,0,!1);I(a,1*b,1*c,!1);I(a,0,0.5*c,!1);J(a,0.2*b,0.35*c,0.3*b,0.35*c,0.5*b,0.5*c);J(a,0.7*b,0.65*c,0.8*b,0.65*c,1*b,0.5*c);b=a.s;t.v(a);return b},BpmnActivityCompensation:function(a,b,c){a=t.u();I(a,0,0.5*c,!0);a.lineTo(0.5*b,0);a.lineTo(0.5*b,0.5*c);a.lineTo(1*b,1*c);a.lineTo(1*b,0);a.lineTo(0.5*b,0.5*c);a.lineTo(0.5*b,1*c);L(a);b=a.s;t.v(a);return b},BpmnTaskMessage:function(a,b,c){a=t.u();
I(a,0,0.2*c,!0);a.lineTo(1*b,0.2*c);a.lineTo(1*b,0.8*c);a.lineTo(0,0.8*c);a.lineTo(0,0.8*c);L(a);I(a,0,0.2*c,!1);a.lineTo(0.5*b,0.5*c);a.lineTo(1*b,0.2*c);a.bb(!1);b=a.s;t.v(a);return b},BpmnTaskScript:function(a,b,c){a=t.u();I(a,0.7*b,1*c,!0);a.lineTo(0.3*b,1*c);J(a,0.6*b,0.5*c,0,0.5*c,0.3*b,0);a.lineTo(0.7*b,0);J(a,0.4*b,0.5*c,1*b,0.5*c,0.7*b,1*c);L(a);I(a,0.45*b,0.73*c,!1);a.lineTo(0.7*b,0.73*c);a.moveTo(0.38*b,0.5*c);a.lineTo(0.63*b,0.5*c);a.moveTo(0.31*b,0.27*c);a.lineTo(0.56*b,0.27*c);a.bb(!1);
b=a.s;t.v(a);return b},BpmnTaskUser:function(a,b,c){a=t.u();I(a,0,0,!1);I(a,0.335*b,(1-0.555)*c,!0);a.lineTo(0.335*b,0.595*c);a.lineTo(0.665*b,0.595*c);a.lineTo(0.665*b,(1-0.555)*c);J(a,0.88*b,0.46*c,0.98*b,0.54*c,1*b,0.68*c);a.lineTo(1*b,1*c);a.lineTo(0,1*c);a.lineTo(0,0.68*c);J(a,0.02*b,0.54*c,0.12*b,0.46*c,0.335*b,(1-0.555)*c);a.lineTo(0.365*b,0.405*c);var d=0.5-0.285,f=Math.PI/4,g=4*(1-Math.cos(f))/(3*Math.sin(f)),f=g*d,g=g*d;J(a,(0.5-(f+d)/2)*b,(d+(d+g)/2)*c,(0.5-d)*b,(d+g)*c,(0.5-d)*b,d*c);
J(a,(0.5-d)*b,(d-g)*c,(0.5-f)*b,(d-d)*c,0.5*b,(d-d)*c);J(a,(0.5+f)*b,(d-d)*c,(0.5+d)*b,(d-g)*c,(0.5+d)*b,d*c);J(a,(0.5+d)*b,(d+g)*c,(0.5+(f+d)/2)*b,(d+(d+g)/2)*c,0.635*b,0.405*c);a.lineTo(0.635*b,0.405*c);a.lineTo(0.665*b,(1-0.555)*c);a.lineTo(0.665*b,0.595*c);a.lineTo(0.335*b,0.595*c);I(a,0.2*b,1*c,!1);a.lineTo(0.2*b,0.8*c);I(a,0.8*b,1*c,!1);a.lineTo(0.8*b,0.8*c);b=a.s;t.v(a);return b},BpmnEventConditional:function(a,b,c){a=t.u();I(a,0.1*b,0,!0);a.lineTo(0.9*b,0);a.lineTo(0.9*b,1*c);a.lineTo(0.1*
b,1*c);L(a);I(a,0.2*b,0.2*c,!1);a.lineTo(0.8*b,0.2*c);a.moveTo(0.2*b,0.4*c);a.lineTo(0.8*b,0.4*c);a.moveTo(0.2*b,0.6*c);a.lineTo(0.8*b,0.6*c);a.moveTo(0.2*b,0.8*c);a.lineTo(0.8*b,0.8*c);a.bb(!1);b=a.s;t.v(a);return b},BpmnEventError:function(a,b,c){a=t.u();I(a,0,1*c,!0);a.lineTo(0.33*b,0);a.lineTo(0.66*b,0.5*c);a.lineTo(1*b,0);a.lineTo(0.66*b,1*c);a.lineTo(0.33*b,0.5*c);L(a);b=a.s;t.v(a);return b},BpmnEventEscalation:function(a,b,c){a=t.u();I(a,0,0,!1);I(a,1*b,1*c,!1);I(a,0.1*b,1*c,!0);a.lineTo(0.5*
b,0);a.lineTo(0.9*b,1*c);a.lineTo(0.5*b,0.5*c);L(a);b=a.s;t.v(a);return b},BpmnEventTimer:function(a,b,c){a=t.u();var d=0.5*F.va;I(a,1*b,0.5*c,!0);J(a,1*b,(0.5+d)*c,(0.5+d)*b,1*c,0.5*b,1*c);J(a,(0.5-d)*b,1*c,0,(0.5+d)*c,0,0.5*c);J(a,0,(0.5-d)*c,(0.5-d)*b,0,0.5*b,0);J(a,(0.5+d)*b,0,1*b,(0.5-d)*c,1*b,0.5*c);I(a,0.5*b,0,!1);a.lineTo(0.5*b,0.15*c);a.moveTo(0.5*b,1*c);a.lineTo(0.5*b,0.85*c);a.moveTo(0,0.5*c);a.lineTo(0.15*b,0.5*c);a.moveTo(1*b,0.5*c);a.lineTo(0.85*b,0.5*c);a.moveTo(0.5*b,0.5*c);a.lineTo(0.58*
b,0.1*c);a.moveTo(0.5*b,0.5*c);a.lineTo(0.78*b,0.54*c);a.bb(!1);b=a.s;b.Hc=Xg;t.v(a);return b}};F.MA={};for(var wm in F.bj)F.bj[wm.toLowerCase()]=wm;
F.gw={"":"",Standard:"F1 m 0,0 l 8,4 -8,4 2,-4 z",Backward:"F1 m 8,0 l -2,4 2,4 -8,-4 z",Triangle:"F1 m 0,0 l 8,4.62 -8,4.62 z",BackwardTriangle:"F1 m 8,4 l 0,4 -8,-4 8,-4 0,4 z",Boomerang:"F1 m 0,0 l 8,4 -8,4 4,-4 -4,-4 z",BackwardBoomerang:"F1 m 8,0 l -8,4 8,4 -4,-4 4,-4 z",SidewaysV:"m 0,0 l 8,4 -8,4 0,-1 6,-3 -6,-3 0,-1 z",BackwardV:"m 8,0 l -8,4 8,4 0,-1 -6,-3 6,-3 0,-1 z",OpenTriangle:"m 0,0 l 8,4 -8,4",BackwardOpenTriangle:"m 8,0 l -8,4 8,4",OpenTriangleLine:"m 0,0 l 8,4 -8,4 m 8.5,0 l 0,-8",
BackwardOpenTriangleLine:"m 8,0 l  -8,4 8,4 m -8.5,0 l 0,-8",OpenTriangleTop:"m 0,0 l 8,4 m 0,4",BackwardOpenTriangleTop:"m 8,0 l -8,4 m 0,4",OpenTriangleBottom:"m 0,8 l 8,-4",BackwardOpenTriangleBottom:"m 0,4 l 8,4",HalfTriangleTop:"F1 m 0,0 l 0,4 8,0 z m 0,8",BackwardHalfTriangleTop:"F1 m 8,0 l 0,4 -8,0 z m 0,8",HalfTriangleBottom:"F1 m 0,4 l 0,4 8,-4 z",BackwardHalfTriangleBottom:"F1 m 8,4 l 0,4 -8,-4 z",ForwardSemiCircle:"m 4,0 b 270 180 0 4 4",BackwardSemiCircle:"m 4,8 b 90 180 0 -4 4",Feather:"m 0,0 l 3,4 -3,4",
BackwardFeather:"m 3,0 l -3,4 3,4",DoubleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardDoubleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4",TripleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardTripleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4 m 3,-8 l -3,4 3,4",ForwardSlash:"m 0,8 l 5,-8",BackSlash:"m 0,0 l 5,8",DoubleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8",DoubleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8",TripleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8 m -2,8 l 4,-8",
TripleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8 m -2,-8 l 4,8",Fork:"m 0,4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4",LineFork:"m 0,0 l 0,8 m 0,-4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardLineFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4 m 8,-8 l 0,8",CircleFork:"F1 m 6,4 b 0 360 -3 0 3 z m 0,0 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",BackwardCircleFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 6,0 b 0 360 -3 0 3",CircleLineFork:"F1 m 6,4 b 0 360 -3 0 3 z m 1,-4 l 0,8 m 0,-4 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",
BackwardCircleLineFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 0,-4 l 0,8 m 7,-4 b 0 360 -3 0 3",Circle:"F1 m 8,4 b 0 360 -4 0 4 z",Block:"F1 m 0,0 l 0,8 8,0 0,-8 z",StretchedDiamond:"F1 m 0,3 l 5,-3 5,3 -5,3 -5,-3 z",Diamond:"F1 m 0,4 l 4,-4 4,4 -4,4 -4,-4 z",Chevron:"F1 m 0,0 l 5,0 3,4 -3,4 -5,0 3,-4 -3,-4 z",StretchedChevron:"F1 m 0,0 l 8,0 3,4 -3,4 -8,0 3,-4 -3,-4 z",NormalArrow:"F1 m 0,2 l 4,0 0,-2 4,4 -4,4 0,-2 -4,0 z",X:"m 0,0 l 8,8 m 0,-8 l -8,8",TailedNormalArrow:"F1 m 0,0 l 2,0 1,2 3,0 0,-2 2,4 -2,4 0,-2 -3,0 -1,2 -2,0 1,-4 -1,-4 z",
DoubleTriangle:"F1 m 0,0 l 4,4 -4,4 0,-8 z  m 4,0 l 4,4 -4,4 0,-8 z",BigEndArrow:"F1 m 0,0 l 5,2 0,-2 3,4 -3,4 0,-2 -5,2 0,-8 z",ConcaveTailArrow:"F1 m 0,2 h 4 v -2 l 4,4 -4,4 v -2 h -4 l 2,-2 -2,-2 z",RoundedTriangle:"F1 m 0,1 a 1,1 0 0 1 1,-1 l 7,3 a 0.5,1 0 0 1 0,2 l -7,3 a 1,1 0 0 1 -1,-1 l 0,-6 z",SimpleArrow:"F1 m 1,2 l -1,-2 2,0 1,2 -1,2 -2,0 1,-2 5,0 0,-2 2,2 -2,2 0,-2 z",AccelerationArrow:"F1 m 0,0 l 0,8 0.2,0 0,-8 -0.2,0 z m 2,0 l 0,8 1,0 0,-8 -1,0 z m 3,0 l 2,0 2,4 -2,4 -2,0 0,-8 z",BoxArrow:"F1 m 0,0 l 4,0 0,2 2,0 0,-2 2,4 -2,4 0,-2 -2,0 0,2 -4,0 0,-8 z",
TriangleLine:"F1 m 8,4 l -8,-4 0,8 8,-4 z m 0.5,4 l 0,-8",CircleEndedArrow:"F1 m 10,4 l -2,-3 0,2 -2,0 0,2 2,0 0,2 2,-3 z m -4,0 b 0 360 -3 0 3 z",DynamicWidthArrow:"F1 m 0,3 l 2,0 2,-1 2,-2 2,4 -2,4 -2,-2 -2,-1 -2,0 0,-2 z",EquilibriumArrow:"m 0,3 l 8,0 -3,-3 m 3,5 l -8,0 3,3",FastForward:"F1 m 0,0 l 3.5,4 0,-4 3.5,4 0,-4 1,0 0,8 -1,0 0,-4 -3.5,4 0,-4 -3.5,4 0,-8 z",Kite:"F1 m 0,4 l 2,-4 6,4 -6,4 -2,-4 z",HalfArrowTop:"F1 m 0,0 l 4,4 4,0 -8,-4 z m 0,8",HalfArrowBottom:"F1 m 0,8 l 4,-4 4,0 -8,4 z",
OpposingDirectionDoubleArrow:"F1 m 0,4 l 2,-4 0,2 4,0 0,-2 2,4 -2,4 0,-2 -4,0 0,2 -2,-4 z",PartialDoubleTriangle:"F1 m 0,0 4,3 0,-3 4,4 -4,4 0,-3 -4,3 0,-8 z",LineCircle:"F1 m 0,0 l 0,8 m 7 -4 b 0 360 -3 0 3 z",DoubleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",TripleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",CircleLine:"F1 m 6 4 b 0 360 -3 0 3 z m 1,-4 l 0,8",DiamondCircle:"F1 m 8,4 l -4,4 -4,-4 4,-4 4,4 m 8,0 b 0 360 -4 0 4 z",PlusCircle:"F1 m 8,4 b 0 360 -4 0 4 l -8 0 z m -4 -4 l 0 8",
OpenRightTriangleTop:"m 8,0 l 0,4 -8,0 m 0,4",OpenRightTriangleBottom:"m 8,8 l 0,-4 -8,0",Line:"m 0,0 l 0,8",DoubleLine:"m 0,0 l 0,8 m 2,0 l 0,-8",TripleLine:"m 0,0 l 0,8 m 2,0 l 0,-8 m 2,0 l 0,8",PentagonArrow:"F1 m 8,4 l -4,-4 -4,0 0,8 4,0 4,-4 z"};F.Dm={};F.QJ=function(){if(F.gw){for(var a in F.gw){var b=Rc(F.gw[a],!1);F.Dm[a]=b;a.toLowerCase()!==a&&(F.Dm[a.toLowerCase()]=a)}F.gw=void 0}};
F.RD=function(a){F.QJ();var b=F.Dm[a];if(void 0===b){b=a.toLowerCase();if("none"===b)return"None";b=F.Dm[b]}return"string"===typeof b?b:b instanceof zc?a:null};V.FigureGenerators=F.bj;V.ArrowheadGeometries=F.Dm;
function C(a){0===arguments.length?y.call(this):y.call(this,a);this.W=311807;this.Kk=this.rh="";this.Zr=this.Wr=this.is=this.jr=null;this.ks="";this.ki=this.js=this.tm=null;this.Yr="";this.vo=null;this.Xr=(new fa(NaN,NaN)).freeze();this.$r="";this.wo=null;this.le="";this.lo=this.xq=this.zk=null;this.Yg=(new w(NaN,NaN)).freeze();this.qr="";this.Nk=null;this.rr=Eb;this.Ar=F.cH;this.tr=F.bH;this.Hq=null;this.kr=xm;this.wm=(new w(6,6)).freeze();this.vm="gray";this.um=4;this.BC=-1;this.mH=new x;this.Fj=
null;this.Cj=NaN}t.ga("Part",C);t.Ka(C,y);C.prototype.cloneProtected=function(a){y.prototype.cloneProtected.call(this,a);a.W=this.W&-4097|49152;a.rh=this.rh;a.Kk=this.Kk;a.jr=this.jr;a.is=this.is;a.Wr=this.Wr;a.Zr=this.Zr;a.ks=this.ks;a.js=this.js;a.ki=null;a.Yr=this.Yr;a.Xr.assign(this.Xr);a.$r=this.$r;a.le=this.le;a.xq=this.xq;a.Yg.assign(this.Yg);a.qr=this.qr;a.rr=this.rr.Z();a.Ar=this.Ar.Z();a.tr=this.tr.Z();a.Hq=this.Hq;a.kr=this.kr;a.wm.assign(this.wm);a.vm=this.vm;a.um=this.um};
C.prototype.Yh=function(a){y.prototype.Yh.call(this,a);wk(a);a.tm=null;a.vo=null;a.wo=null;a.Nk=null;a.Fj=null};C.prototype.toString=function(){var a=t.xg(Object.getPrototypeOf(this))+"#"+t.od(this);this.data&&(a+="("+yd(this.data)+")");return a};C.LayoutNone=0;var aj;C.LayoutAdded=aj=1;var gj;C.LayoutRemoved=gj=2;C.LayoutShown=4;C.LayoutHidden=8;C.LayoutNodeSized=16;var Pj;C.LayoutGroupLayout=Pj=32;C.LayoutNodeReplaced=64;var xm;C.LayoutStandard=xm=aj|gj|28|Pj|64;
C.prototype.gn=function(a,b,c,d,f,g,h){var k=this.h;null!==k&&(a===wd&&"elements"===b?f instanceof y&&bj(f,function(a){cj(k,a)}):a===xd&&"elements"===b&&f instanceof y&&bj(f,function(a){fj(k,a)}),k.Ic(a,b,c,d,f,g,h))};C.prototype.updateTargetBindings=C.prototype.Kb=function(a){y.prototype.Kb.call(this,a);if(null!==this.data){a=this.xa.n;for(var b=a.length,c=0;c<b;c++){var d=a[c];d instanceof y&&bj(d,function(a){null!==a.data&&a.Kb()})}}};
C.prototype.updateModelDataReferences=function(){var a=this.data;if(null!==a&&!(this instanceof Je)){var b=this.h;if(null!==b){var c=b.ba;if(c instanceof M){var d=c.Sm(a),b=b.FI(d),f=this.hb;b!==f&&(b=null!==f?c.Hb(f.data):void 0,f=c.en,"function"===typeof f?f(a,b):a[f]=b,c.vx(a,d))}}}};t.A(C,{cw:"adornments"},function(){return null===this.ki?t.ag:this.ki.jF});C.prototype.findAdornment=C.prototype.bp=function(a){e&&t.i(a,"string",C,"findAdornment:category");var b=this.ki;return null===b?null:b.za(a)};
C.prototype.addAdornment=C.prototype.Wk=function(a,b){if(null!==b){e&&(t.i(a,"string",C,"addAdornment:category"),t.l(b,Je,C,"addAdornment:ad"));var c=null,d=this.ki;null!==d&&(c=d.za(a));if(c!==b){if(null!==c){var f=c.h;null!==f&&f.remove(c)}null===d&&(this.ki=d=new ia("string",Je));b.rh!==a&&(b.Zc=a);d.add(a,b);c=this.h;null!==c&&(c.add(b),b.data=this.data)}}};
C.prototype.removeAdornment=C.prototype.vl=function(a){e&&t.i(a,"string",C,"removeAdornment:category");var b=this.ki;if(null!==b){var c=b.za(a);if(null!==c){var d=c.h;null!==d&&d.remove(c)}b.remove(a);0===b.count&&(this.ki=null)}};C.prototype.clearAdornments=C.prototype.Wi=function(){var a=this.ki;if(null!==a){for(var b=t.Db(),a=a.k;a.next();)b.push(a.key);for(var a=b.length,c=0;c<a;c++)this.vl(b[c]);t.ya(b)}};
C.prototype.updateAdornments=function(){var a=this.h;if(null!==a){ym(this,a);for(var a=a.wb.ef.n,b=a.length,c=0;c<b;c++){var d=a[c];d.isEnabled&&d.updateAdornments(this)}for(a=this.cw;a.next();)b=a.value,b.Kb(),b.ha()}};
function ym(a,b){if(a.eb&&a.hG){var c=a.Pt;if(null===c||!(a.oa.N()&&a.La()&&c.pl()&&c.oa.N()))return;var d=a.bp("Selection");if(null===d){d=a.iG;null===d&&(a instanceof T?d=b.rF:a instanceof C&&(d=a instanceof S?b.NE:b.GF));if(!(d instanceof Je))return;Le(d);d=d.copy();if(!(d instanceof Je))return;d.Zc="Selection";d.pc=c}if(null!==d){var f=d.placeholder;if(null!==f){var g=c.ek(),h=0;c instanceof V&&(h=c.fb);var k=t.Cl();k.p((c.Pa.width+h)*g,(c.Pa.height+h)*g);f.Ca=k;t.ck(k)}d.angle=c.hl();d.type!==
wg&&(f=t.K(),d.location=c.pb(Eb,f),t.B(f));a.Wk("Selection",d);return}}a.vl("Selection")}t.A(C,{layer:"layer"},function(){return this.lo});t.A(C,{h:"diagram"},function(){var a=this.lo;return null!==a?a.h:null});t.g(C,"layerName",C.prototype.af);
t.defineProperty(C,{af:"layerName"},function(){return this.Kk},function(a){var b=this.Kk;if(b!==a){t.i(a,"string",C,"layerName");var c=this.h;if(null===c||null!==c.ct(a)&&!c.un)if(this.Kk=a,this.j("layerName",b,a),b=this.layer,null!==b&&b.name!==a&&(c=b.h,null!==c&&(a=c.ct(a),null!==a&&a!==b))){var d=b.Xe(-1,this,!0);0<=d&&c.Ic(xd,"parts",b,this,null,d,!0);d=a.np(99999999,this,!0);0<=d&&c.Ic(wd,"parts",a,null,this,!0,d);d=this.tp;if(null!==d){var f=c.Na;c.Na=!0;d(this,b,a);c.Na=f}}}});
t.g(C,"layerChanged",C.prototype.tp);t.defineProperty(C,{tp:"layerChanged"},function(){return this.jr},function(a){var b=this.jr;b!==a&&(null!==a&&t.i(a,"function",C,"layerChanged"),this.jr=a,this.j("layerChanged",b,a))});t.A(C,{rc:"isTemporary"},function(){return null!==this.lo?this.lo.rc:!1});function ij(a){var b=a.h;null!==b&&(zi(b),0!==(a.W&16384)!==!0&&(a.W|=16384,b.te()))}function Qh(a){0!==(a.W&16384)!==!1&&(a.updateAdornments(),a.W&=-16385,a=a.h,null!==a&&(a.ve=!0))}t.g(C,"location",C.prototype.location);
t.defineProperty(C,{location:"location"},function(){return this.Yg},function(a){var b=this.Yg;if(!(b.M(a)||this instanceof T)){e&&t.l(a,w,C,"location");a=a.Z();var c=this.h;this.Yg=a;if(!1===Ri(this)){var d=this.Ra,f=a.x-b.x,g=a.y-b.y,h=d.copy();d.p(h.x+f,h.y+g);Rk(this,!0);!d.M(h)&&c&&this.j("position",h,d)}this.j("location",b,a)}});function Rk(a,b){if(!1===Si(a)){var c=a.h;null!==c&&(a.La()&&c.fg.add(a),a instanceof R&&!c.la.vb&&a.wf(),b||c.te());zm(a,!0)}}
function Am(a){if(!1!==Si(a)){var b=a.position,c=a.location;c.N()&&b.N()||(Bm(a,b,c),sk(a));var b=a.Ra,c=a.cc,d=c.copy();c.Ma();c.x=b.x;c.y=b.y;c.freeze();tk(a,d,c);zm(a,!1)}}t.A(C,{kc:"locationObject"},function(){if(null===this.Nk){var a=this.ax;void 0!==a&&null!==a&&""!==a?(a=this.oe(a),this.Nk=null!==a?a:this):this.Nk=this instanceof Je&&this.da!==wg&&null!==this.Jb?this.Jb:this}return this.Nk.visible?this.Nk:this});t.g(C,"minLocation",C.prototype.zF);
t.defineProperty(C,{zF:"minLocation"},function(){return this.Ar},function(a){var b=this.Ar;b.M(a)||(e&&t.l(a,w,C,"minLocation"),this.Ar=a=a.Z(),this.j("minLocation",b,a))});t.g(C,"maxLocation",C.prototype.tF);t.defineProperty(C,{tF:"maxLocation"},function(){return this.tr},function(a){var b=this.tr;b.M(a)||(e&&t.l(a,w,C,"maxLocation"),this.tr=a=a.Z(),this.j("maxLocation",b,a))});t.g(C,"locationObjectName",C.prototype.ax);
t.defineProperty(C,{ax:"locationObjectName"},function(){return this.qr},function(a){var b=this.qr;b!==a&&(e&&t.i(a,"string",C,"locationObjectName"),this.qr=a,this.Nk=null,Jk(this),this.j("locationObjectName",b,a))});t.g(C,"locationSpot",C.prototype.bf);
t.defineProperty(C,{bf:"locationSpot"},function(){return this.rr},function(a){var b=this.rr;b.M(a)||(e&&(t.l(a,H,C,"locationSpot"),a.xd()||t.m("Part.locationSpot must be a specific Spot value, not: "+a)),this.rr=a=a.Z(),Jk(this),this.j("locationSpot",b,a))});C.prototype.move=C.prototype.move=function(a){this.position=a};C.prototype.moveTo=C.prototype.moveTo=function(a,b){var c=t.mc(a,b);this.move(c);t.B(c)};
C.prototype.isVisible=C.prototype.La=function(){if(!this.visible)return!1;var a=this.layer;if(null!==a&&!a.visible)return!1;a=this.hb;if(!(null===a||a.Yd&&a.La()))return!1;if(this instanceof R){a=this.dA();if(null!==a&&!a.Qc)return!1;a=this.$d;if(null!==a)return a.La()}else if(this instanceof T){var b=!0,c=this.h;null!==c&&(b=c.pd);c=this.aa;if(null!==c){if(this.Ec&&b&&!c.Qc)return!1;c=c.findVisibleNode();if(null===c||c===a)return!1}c=this.ca;if(null!==c){if(this.Ec&&!b&&!c.Qc)return!1;b=c.findVisibleNode();
if(null===b||b===a)return!1}}return!0};C.prototype.df=function(a){a?(this.J(4),ij(this),a=this.h,null!==a&&a.fg.add(this)):(this.J(8),this.Wi());this.fa()};
C.prototype.findObject=C.prototype.oe=function(a){if(this.name===a)return this;null===this.Fj&&(this.Fj={});var b=this.Fj;if(void 0!==b[a])return b[a];for(var c=this.xa.n,d=c.length,f=0;f<d;f++){var g=c[f];if(g.name===a)return b[a]=g;if(g instanceof y)if(null===g.yi&&null===g.hg){if(g=g.oe(a),null!==g)return b[a]=g}else if(Zl(g)&&(g=g.xa.$a(),null!==g&&g.name===a))return b[a]=g}return b[a]=null};
function Cm(a,b,c,d){void 0===d&&(d=new w);c.Ge()&&(c=Hb);var f=b.Pa;d.p(f.width*c.x+c.offsetX,f.height*c.y+c.offsetY);if(null===b||b===a)return d;b.transform.Sa(d);for(b=b.ja;null!==b&&b!==a;)b.transform.Sa(d),b=b.ja;a.Mk.Sa(d);d.offset(-a.Wc.x,-a.Wc.y);return d}C.prototype.ensureBounds=C.prototype.sf=function(){ih(this,Infinity,Infinity);this.Lc()};
function Th(a,b,c){c=void 0===c?a.mH:c;var d;isNaN(a.Cj)&&(a.Cj=$l(a));d=a.Cj;var f=2*d;if(!a.gj)return c.p(b.x-1-d,b.y-1-d,b.width+2+f,b.height+2+f),c;d=b.x;var f=b.y,g=b.width;b=b.height;var h=a.shadowBlur;a=a.pG;g+=h;b+=h;d-=h/2;f-=h/2;0<a.x?g+=a.x:(d+=a.x,g-=a.x);0<a.y?b+=a.y:(f+=a.y,b-=a.y);c.p(d-1,f-1,g+2,b+2);return c}aa=C.prototype;
aa.Lc=function(){sk(this);if(!1===Ri(this))Am(this);else{var a=t.Gf();a.assign(this.cc);Gk(this);this.cc.Ma();var b=Ph(this);this.Tj(0,0,this.Wc.width,this.Wc.height);var c=this.position;Bm(this,c,this.location);var d=this.cc;d.x=c.x;d.y=c.y;d.freeze();sk(this);tk(this,a,d);a.M(d)?this.Df(b):!this.Ld()||F.I(a.width,d.width)&&F.I(a.height,d.height)||0<=this.BC&&this.J(16);t.hc(a);zm(this,!1)}};
aa.nG=function(a,b){if(!a.N()||this instanceof T)return!1;if(this.h&&!(this instanceof Je)){var c=this.h.li;c.sd&&Gh(c,this,b.copy(),a.copy())}var c=this.Yg,d=c.copy();!1===Si(this)&&!1===Ri(this)?(c.p(c.x+(a.x-b.x),c.y+(a.y-b.y)),this.Ra=a,Rk(this,!0),sk(this),Am(this),c.M(d)||this.j("location",d,c)):(this.Ra=a,c.p(NaN,NaN));return!0};
aa.oG=function(a,b){var c=this.Yg;!1===Si(this)&&!1===Ri(this)?(this.Yg.p(c.x+a-this.Ra.x,c.y+b-this.Ra.y),this.Ra.p(a,b),Rk(this,!1),sk(this)):(c.p(NaN,NaN),this.Ra.p(a,b))};
function Bm(a,b,c){var d=NaN,f=NaN,g=t.K(),h=a.bf,k=a.kc;h.Ge()&&t.m("determineOffset: Part's locationSpot must be real: "+h.toString());var l=k.Pa,d=0;k.fb&&(d=k.bh);g.Rt(0,0,l.width+d,l.height+d,h);if(k!==a)for(k.fb&&g.offset(-d/2,-d/2),k.transform.Sa(g),h=k.ja;null!==h&&h!==a;)h.transform.Sa(g),h=h.ja;a.Mk.Sa(g);g.offset(-a.Wc.x,-a.Wc.y);h=a.h;c.N()?(k=b.x,l=b.y,d=c.x-g.x,f=c.y-g.y,b.p(d,f),c=!1,null!==h&&(d=h.li,d.wj?c=!0:!d.sd||a instanceof Je||Gh(d,a,new w(k,l),b),c||b.x===k&&b.y===l||(d=h.Wa,
h.Wa=!0,a.j("position",new w(k,l),b),h.Wa=d))):b.N()&&(d=b.x,f=b.y,b=c.copy(),c.p(d+g.x,f+g.y),c.M(b)||null===h||(d=h.Wa,h.Wa=!0,a.j("location",b,c),h.Wa=d));t.B(g)}function dj(a,b,c){yk(a,!1);a instanceof R&&Xj(c,a);a.layer.rc||b||c.Oc();b=a.cc;var d=c.xb;d.N()?(Ph(a)?eb(b,d)||(a.Df(!1),ij(a)):b.Tf(d)&&a.Df(!0),a.updateAdornments()):c.Ik=!0}aa.ol=function(){return!0};
function Rh(a,b){if(a.visible){var c=a.cc;if(0!==c.width&&0!==c.height&&!isNaN(c.x)&&!isNaN(c.y)){var d=a.transform,f=a.ja,g=a.kk;g.reset();null!==f&&(f.Hf()?g.multiply(f.me):null!==f.ja&&g.multiply(f.ja.me));g.multiply(a.md);null!==a.vc&&(Dk(a,b,a.vc,!0,!0),b.fillRect(c.x,c.y,c.width,c.height));null===a.vc&&null===a.Mb&&(b.fillStyle="rgba(0,0,0,0.4)",b.fillRect(c.x,c.y,c.width,c.height));null!==a.Mb&&(d.qt()||b.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy),f=a.Pa,c=f.width,f=f.height,Dk(a,b,a.Mb,
!0),b.fillRect(0,0,c+0,f+0),d.qt()||(c=1/(d.m11*d.m22-d.m12*d.m21),b.transform(d.m22*c,-d.m12*c,-d.m21*c,d.m11*c,c*(d.m21*d.dy-d.m22*d.dx),c*(d.m12*d.dx-d.m11*d.dy))))}}}aa.Ld=function(){return!0};t.g(C,"category",C.prototype.Zc);
t.defineProperty(C,{Zc:"category"},function(){return this.rh},function(a){var b=this.rh;if(b!==a){t.i(a,"string",C,"category");this.rh=a;this.j("category",b,a);var c=this.h,d=this.data;if(null!==c&&null!==d){var f=c.ba;this instanceof T?(f instanceof M?f.lG(d,a):f instanceof Kd&&f.OJ(d,a),c=c.findTemplateForLinkData(d,a),c instanceof T&&(Le(c),c=c.copy(),c instanceof T&&Dm(this,c,b,a))):this instanceof Je||(null!==f&&f.sx(d,a),c=c.findTemplateForNodeData(d,a),c instanceof C&&(Le(c),c=c.copy(),c instanceof
C&&!(c instanceof T)&&(c.location=this.location,Dm(this,c,b,a))))}this instanceof Je&&(a=this.Wh,null!==a&&(c=a.ki,null!==c&&c.remove(b),a.Wk(this.rh,this)))}});
function Dm(a,b,c,d){b.constructor===a.constructor||t.RG||(t.RG=!0,t.trace('Should not change the class of the Part when changing category from "'+c+'" to "'+d+'"'),t.trace("  Old class: "+t.xg(a)+", new class: "+t.xg(b)+", part: "+a.toString()));a.Wi();var f=a.data;c=a.af;var g=a.eb,h=a.zg,k=!0,l=!0,m=!1;a instanceof R&&(k=a.ci,l=a.Qc,m=a.pn);b.Yh(a);b.cloneProtected(a);a.rh=d;a.ha();a.fa();b=a.h;d=!0;null!==b&&(d=b.Wa,b.Wa=!0);a.Ol=f;null!==f&&a.Kb();null!==b&&(b.Wa=d);f=a.af;f!==c&&(a.Kk=c,a.af=
f);a instanceof R&&(a.ci=k,a.Qc=l,a.pn=m,a.Ld()&&a.J(64));a.eb=g;a.zg=h}C.prototype.canCopy=function(){if(!this.fE)return!1;var a=this.layer;if(null===a)return!0;if(!a.Si)return!1;a=a.h;return null===a?!0:a.Si?!0:!1};C.prototype.canDelete=function(){if(!this.oE)return!1;var a=this.layer;if(null===a)return!0;if(!a.Zk)return!1;a=a.h;return null===a?!0:a.Zk?!0:!1};
C.prototype.canEdit=function(){if(!this.zG)return!1;var a=this.layer;if(null===a)return!0;if(!a.Qo)return!1;a=a.h;return null===a?!0:a.Qo?!0:!1};C.prototype.canGroup=function(){if(!this.OE)return!1;var a=this.layer;if(null===a)return!0;if(!a.Mo)return!1;a=a.h;return null===a?!0:a.Mo?!0:!1};C.prototype.canMove=function(){if(!this.CF)return!1;var a=this.layer;if(null===a)return!0;if(!a.Rj)return!1;a=a.h;return null===a?!0:a.Rj?!0:!1};
C.prototype.canReshape=function(){if(!this.SF)return!1;var a=this.layer;if(null===a)return!0;if(!a.No)return!1;a=a.h;return null===a?!0:a.No?!0:!1};C.prototype.canResize=function(){if(!this.TF)return!1;var a=this.layer;if(null===a)return!0;if(!a.Oo)return!1;a=a.h;return null===a?!0:a.Oo?!0:!1};C.prototype.canRotate=function(){if(!this.ZF)return!1;var a=this.layer;if(null===a)return!0;if(!a.Po)return!1;a=a.h;return null===a?!0:a.Po?!0:!1};
C.prototype.canSelect=function(){if(!this.wl)return!1;var a=this.layer;if(null===a)return!0;if(!a.Pe)return!1;a=a.h;return null===a?!0:a.Pe?!0:!1};t.g(C,"copyable",C.prototype.fE);t.defineProperty(C,{fE:"copyable"},function(){return 0!==(this.W&1)},function(a){var b=0!==(this.W&1);b!==a&&(e&&t.i(a,"boolean",C,"copyable"),this.W^=1,this.j("copyable",b,a))});t.g(C,"deletable",C.prototype.oE);
t.defineProperty(C,{oE:"deletable"},function(){return 0!==(this.W&2)},function(a){var b=0!==(this.W&2);b!==a&&(e&&t.i(a,"boolean",C,"deletable"),this.W^=2,this.j("deletable",b,a))});t.g(C,"textEditable",C.prototype.zG);t.defineProperty(C,{zG:"textEditable"},function(){return 0!==(this.W&4)},function(a){var b=0!==(this.W&4);b!==a&&(e&&t.i(a,"boolean",C,"textEditable"),this.W^=4,this.j("textEditable",b,a),this.updateAdornments())});t.g(C,"groupable",C.prototype.OE);
t.defineProperty(C,{OE:"groupable"},function(){return 0!==(this.W&8)},function(a){var b=0!==(this.W&8);b!==a&&(e&&t.i(a,"boolean",C,"groupable"),this.W^=8,this.j("groupable",b,a))});t.g(C,"movable",C.prototype.CF);t.defineProperty(C,{CF:"movable"},function(){return 0!==(this.W&16)},function(a){var b=0!==(this.W&16);b!==a&&(e&&t.i(a,"boolean",C,"movable"),this.W^=16,this.j("movable",b,a))});t.g(C,"selectionAdorned",C.prototype.hG);
t.defineProperty(C,{hG:"selectionAdorned"},function(){return 0!==(this.W&32)},function(a){var b=0!==(this.W&32);b!==a&&(e&&t.i(a,"boolean",C,"selectionAdorned"),this.W^=32,this.j("selectionAdorned",b,a),this.updateAdornments())});t.g(C,"isInDocumentBounds",C.prototype.Rw);t.defineProperty(C,{Rw:"isInDocumentBounds"},function(){return 0!==(this.W&64)},function(a){var b=0!==(this.W&64);b!==a&&(e&&t.i(a,"boolean",C,"isInDocumentBounds"),this.W^=64,this.j("isInDocumentBounds",b,a))});
t.g(C,"isLayoutPositioned",C.prototype.vA);t.defineProperty(C,{vA:"isLayoutPositioned"},function(){return 0!==(this.W&128)},function(a){var b=0!==(this.W&128);b!==a&&(e&&t.i(a,"boolean",C,"isLayoutPositioned"),this.W^=128,this.j("isLayoutPositioned",b,a),this.J(a?4:8))});t.g(C,"selectable",C.prototype.wl);t.defineProperty(C,{wl:"selectable"},function(){return 0!==(this.W&256)},function(a){var b=0!==(this.W&256);b!==a&&(e&&t.i(a,"boolean",C,"selectable"),this.W^=256,this.j("selectable",b,a),this.updateAdornments())});
t.g(C,"reshapable",C.prototype.SF);t.defineProperty(C,{SF:"reshapable"},function(){return 0!==(this.W&512)},function(a){var b=0!==(this.W&512);b!==a&&(e&&t.i(a,"boolean",C,"reshapable"),this.W^=512,this.j("reshapable",b,a),this.updateAdornments())});t.g(C,"resizable",C.prototype.TF);t.defineProperty(C,{TF:"resizable"},function(){return 0!==(this.W&1024)},function(a){var b=0!==(this.W&1024);b!==a&&(e&&t.i(a,"boolean",C,"resizable"),this.W^=1024,this.j("resizable",b,a),this.updateAdornments())});
t.g(C,"rotatable",C.prototype.ZF);t.defineProperty(C,{ZF:"rotatable"},function(){return 0!==(this.W&2048)},function(a){var b=0!==(this.W&2048);b!==a&&(e&&t.i(a,"boolean",C,"rotatable"),this.W^=2048,this.j("rotatable",b,a),this.updateAdornments())});t.g(C,"isSelected",C.prototype.eb);
t.defineProperty(C,{eb:"isSelected"},function(){return 0!==(this.W&4096)},function(a){var b=0!==(this.W&4096);if(b!==a){e&&t.i(a,"boolean",C,"isSelected");var c=this.h;if(!a||this.canSelect()&&!(null!==c&&c.selection.count>=c.uF)){this.W^=4096;var d=!1;if(null!==c){d=c.Wa;c.Wa=!0;var f=c.selection;f.Ma();a?f.add(this):f.remove(this);f.freeze()}this.j("isSelected",b,a);ij(this);a=this.jG;null!==a&&a(this);null!==c&&(c.te(),c.Wa=d)}}});t.g(C,"isHighlighted",C.prototype.zg);
t.defineProperty(C,{zg:"isHighlighted"},function(){return 0!==(this.W&524288)},function(a){var b=0!==(this.W&524288);if(b!==a){e&&t.i(a,"boolean",C,"isHighlighted");this.W^=524288;var c=this.h;null!==c&&(c=c.Kw,c.Ma(),a?c.add(this):c.remove(this),c.freeze());this.j("isHighlighted",b,a);this.fa()}});t.g(C,"isShadowed",C.prototype.gj);
t.defineProperty(C,{gj:"isShadowed"},function(){return 0!==(this.W&8192)},function(a){var b=0!==(this.W&8192);b!==a&&(e&&t.i(a,"boolean",C,"isShadowed"),this.W^=8192,this.j("isShadowed",b,a),this.fa())});function Si(a){return 0!==(a.W&32768)}function zm(a,b){a.W=b?a.W|32768:a.W&-32769}function yk(a,b){a.W=b?a.W|65536:a.W&-65537}function Ph(a){return 0!==(a.W&131072)}C.prototype.Df=function(a){this.W=a?this.W|131072:this.W&-131073};t.g(C,"isAnimated",C.prototype.oA);
t.defineProperty(C,{oA:"isAnimated"},function(){return 0!==(this.W&262144)},function(a){var b=0!==(this.W&262144);b!==a&&(e&&t.i(a,"boolean",C,"isAnimated"),this.W^=262144,this.j("isAnimated",b,a))});t.g(C,"selectionObjectName",C.prototype.qx);t.defineProperty(C,{qx:"selectionObjectName"},function(){return this.ks},function(a){var b=this.ks;b!==a&&(e&&t.i(a,"string",C,"selectionObjectName"),this.ks=a,this.tm=null,this.j("selectionObjectName",b,a))});t.g(C,"selectionAdornmentTemplate",C.prototype.iG);
t.defineProperty(C,{iG:"selectionAdornmentTemplate"},function(){return this.is},function(a){var b=this.is;b!==a&&(e&&t.l(a,Je,C,"selectionAdornmentTemplate"),this instanceof T&&(a.type=wg),this.is=a,this.j("selectionAdornmentTemplate",b,a))});t.A(C,{Pt:"selectionObject"},function(){if(null===this.tm){var a=this.qx;null!==a&&""!==a?(a=this.oe(a),this.tm=null!==a?a:this):this instanceof T?(a=this.path,this.tm=null!==a?a:this):this.tm=this}return this.tm});t.g(C,"selectionChanged",C.prototype.jG);
t.defineProperty(C,{jG:"selectionChanged"},function(){return this.js},function(a){var b=this.js;b!==a&&(null!==a&&t.i(a,"function",C,"selectionChanged"),this.js=a,this.j("selectionChanged",b,a))});t.g(C,"resizeAdornmentTemplate",C.prototype.UF);t.defineProperty(C,{UF:"resizeAdornmentTemplate"},function(){return this.Wr},function(a){var b=this.Wr;b!==a&&(e&&t.l(a,Je,C,"resizeAdornmentTemplate"),this.Wr=a,this.j("resizeAdornmentTemplate",b,a))});t.g(C,"resizeObjectName",C.prototype.XF);
t.defineProperty(C,{XF:"resizeObjectName"},function(){return this.Yr},function(a){var b=this.Yr;b!==a&&(e&&t.i(a,"string",C,"resizeObjectName"),this.Yr=a,this.vo=null,this.j("resizeObjectName",b,a))});t.A(C,{WF:"resizeObject"},function(){if(null===this.vo){var a=this.XF;null!==a&&""!==a?(a=this.oe(a),this.vo=null!==a?a:this):this.vo=this}return this.vo});t.g(C,"resizeCellSize",C.prototype.VF);
t.defineProperty(C,{VF:"resizeCellSize"},function(){return this.Xr},function(a){var b=this.Xr;b.M(a)||(e&&t.l(a,fa,C,"resizeCellSize"),this.Xr=a=a.Z(),this.j("resizeCellSize",b,a))});t.g(C,"rotateAdornmentTemplate",C.prototype.$F);t.defineProperty(C,{$F:"rotateAdornmentTemplate"},function(){return this.Zr},function(a){var b=this.Zr;b!==a&&(e&&t.l(a,Je,C,"rotateAdornmentTemplate"),this.Zr=a,this.j("rotateAdornmentTemplate",b,a))});t.g(C,"rotateObjectName",C.prototype.bG);
t.defineProperty(C,{bG:"rotateObjectName"},function(){return this.$r},function(a){var b=this.$r;b!==a&&(e&&t.i(a,"string",C,"rotateObjectName"),this.$r=a,this.wo=null,this.j("rotateObjectName",b,a))});t.A(C,{aG:"rotateObject"},function(){if(null===this.wo){var a=this.bG;null!==a&&""!==a?(a=this.oe(a),this.wo=null!==a?a:this):this.wo=this}return this.wo});t.g(C,"text",C.prototype.text);
t.defineProperty(C,{text:"text"},function(){return this.le},function(a){var b=this.le;b!==a&&(e&&t.i(a,"string",C,"text"),this.le=a,this.j("text",b,a))});t.g(C,"containingGroup",C.prototype.hb);
t.defineProperty(C,{hb:"containingGroup"},function(){return this.zk},function(a){if(this.Ld()){var b=this.zk;if(b!==a){e&&null!==a&&t.l(a,S,C,"containingGroup");null===a||this!==a&&!a.nl(this)||(this===a&&t.m("Cannot make a Group a member of itself: "+this.toString()),t.m("Cannot make a Group indirectly contain itself: "+this.toString()+" already contains "+a.toString()));this.J(gj);var c=this.h;null!==b?Em(b,this):this instanceof S&&null!==c&&c.Uk.remove(this);this.zk=a;null!==a?Fm(a,this):this instanceof
S&&null!==c&&c.Uk.add(this);this.J(aj);if(null!==c){var d=this.data,f=c.ba;null!==d&&f instanceof M&&f.vx(d,f.Hb(null!==a?a.data:null))}d=this.Wz;null!==d&&(f=!0,null!==c&&(f=c.Na,c.Na=!0),d(this,b,a),null!==c&&(c.Na=f));if(this instanceof S)for(c=new ma(C),Ie(c,this,!0,0),c=c.k;c.next();)if(d=c.value,d instanceof R)for(d=d.qe;d.next();)f=d.value,Lj(f);if(this instanceof R)for(d=this.qe;d.next();)f=d.value,Lj(f);this.j("containingGroup",b,a);null!==a&&a.xx()}}else t.m("cannot set the Part.containingGroup of a Link or Adornment")});
function wk(a){a=a.hb;null!==a&&(a.ha(),null!==a.Jb&&a.Jb.ha(),a.wf())}C.prototype.lt=function(a){var b=this.zk;null===b||a||Fm(b,this)};C.prototype.mt=function(a){var b=this.zk;null===b||a||Em(b,this)};C.prototype.Nm=function(){var a=this.data;if(null!==a){var b=this.h;null!==b&&(b=b.ba,null!==b&&b.nx(a))}};t.g(C,"containingGroupChanged",C.prototype.Wz);
t.defineProperty(C,{Wz:"containingGroupChanged"},function(){return this.xq},function(a){var b=this.xq;b!==a&&(null!==a&&t.i(a,"function",C,"containingGroupChanged"),this.xq=a,this.j("containingGroupChanged",b,a))});C.prototype.findTopLevelPart=function(){return Gm(this,this)};function Gm(a,b){var c=b.hb;return null!==c?Gm(a,c):b instanceof R&&(c=b.$d,null!==c)?Gm(a,c):b}t.A(C,{sp:"isTopLevel"},function(){return null!==this.hb||this instanceof R&&this.bi?!1:!0});
C.prototype.isMemberOf=C.prototype.nl=function(a){return a instanceof S?Hm(this,this,a):!1};function Hm(a,b,c){if(b===c||null===c)return!1;var d=b.hb;return null===d||d!==c&&!Hm(a,d,c)?b instanceof R&&(b=b.$d,null!==b)?Hm(a,b,c):!1:!0}C.prototype.findCommonContainingGroup=C.prototype.EI=function(a){return Im(this,this,a)};
function Im(a,b,c){if(null===b||null===c)return null;var d=b.hb;if(null===d)return null;if(b===c)return d;var f=c.hb;return null===f?null:d===f?f:Hm(a,c,d)?d:Hm(a,b,f)?f:Im(a,d,f)}t.g(C,"layoutConditions",C.prototype.mF);t.defineProperty(C,{mF:"layoutConditions"},function(){return this.kr},function(a){var b=this.kr;b!==a&&(e&&t.i(a,"number",C,"layoutConditions"),this.kr=a,this.j("layoutConditions",b,a))});
C.prototype.canLayout=function(){if(!this.vA||!this.La())return!1;var a=this.layer;return null!==a&&a.rc||this instanceof R&&this.bi?!1:!0};C.prototype.invalidateLayout=C.prototype.J=function(a){void 0===a&&(a=16777215);var b;this.vA&&0!==(a&this.mF)?(b=this.layer,null!==b&&b.rc||this instanceof R&&this.bi?b=!1:(b=this.h,b=null!==b&&b.la.vb?!1:!0)):b=!1;if(b)if(b=this.zk,null!==b){var c=b.jc;null!==c?c.J():b.J(a)}else a=this.h,null!==a&&(c=a.jc,null!==c&&c.J())};
function ej(a){if(!a.La())return!1;a=a.layer;return null!==a&&a.rc?!1:!0}t.g(C,"dragComputation",C.prototype.aA);t.defineProperty(C,{aA:"dragComputation"},function(){return this.Hq},function(a){var b=this.Hq;b!==a&&(null!==a&&t.i(a,"function",C,"dragComputation"),this.Hq=a,this.j("dragComputation",b,a))});t.g(C,"shadowOffset",C.prototype.pG);
t.defineProperty(C,{pG:"shadowOffset"},function(){return this.wm},function(a){var b=this.wm;b.M(a)||(e&&t.l(a,w,C,"shadowOffset"),this.wm=a=a.Z(),this.fa(),this.j("shadowOffset",b,a))});t.g(C,"shadowColor",C.prototype.shadowColor);t.defineProperty(C,{shadowColor:"shadowColor"},function(){return this.vm},function(a){var b=this.vm;b!==a&&(e&&t.i(a,"string",C,"shadowColor"),this.vm=a,this.fa(),this.j("shadowColor",b,a))});t.g(C,"shadowBlur",C.prototype.shadowBlur);
t.defineProperty(C,{shadowBlur:"shadowBlur"},function(){return this.um},function(a){var b=this.um;b!==a&&(e&&t.i(a,"number",C,"shadowBlur"),this.um=a,this.fa(),this.j("shadowBlur",b,a))});function Je(a){0===arguments.length?C.call(this,$g):C.call(this,a);this.af="Adornment";this.sb=null;this.W&=-257;this.Yg=new w(NaN,NaN);this.mi=new B(x);this.Jb=null}t.ga("Adornment",Je);t.Ka(Je,C);aa=Je.prototype;aa.toString=function(){var a=this.Wh;return"Adornment("+this.Zc+")"+(null!==a?a.toString():"")};
aa.uj=function(){return this.sb&&this.sb.S instanceof T?this.sb.S.uj():null};aa.Rq=function(){return null};aa.bx=function(){var a=this.pc.S,b=this.pc;if(a instanceof T&&b instanceof V){var c=a.path,b=c.Ta;a.bx();for(var b=c.Ta,a=this.xa,c=a.length,d=0;d<c;d++){var f=a.n[d];f.fj&&f instanceof V&&(f.Ta=b)}}};aa.Sy=function(){var a=this.Wh;return a instanceof T?a.Cc:null};t.A(Je,{placeholder:"placeholder"},function(){return this.Jb});t.g(Je,"adornedObject",Je.prototype.pc);
t.defineProperty(Je,{pc:"adornedObject"},function(){return this.sb},function(a){e&&null!==a&&t.l(a,Q,C,"adornedObject:val");var b=this.Wh,c=null;null!==a&&(c=a.S);null===b||null!==a&&b===c||b.vl(this.Zc);this.sb=a;c&&c.Wk(this.Zc,this)});t.A(Je,{Wh:"adornedPart"},function(){var a=this.sb;return null!==a?a.S:null});Je.prototype.ol=function(){var a=this.sb;if(null===a)return!0;a=a.S;return null===a||!Ri(a)};Je.prototype.Ld=function(){return!1};t.A(Je,{hb:"containingGroup"},function(){return null});
Je.prototype.gn=function(a,b,c,d,f,g,h){if(a===wd&&"elements"===b)if(f instanceof Ug)null===this.Jb?this.Jb=f:this.Jb!==f&&t.m("Cannot insert a second Placeholder into the visual tree of an Adornment.");else{if(f instanceof y){var k=f.bt(function(a){return a instanceof Ug});k instanceof Ug&&(null===this.Jb?this.Jb=k:this.Jb!==k&&t.m("Cannot insert a second Placeholder into the visual tree of an Adornment."))}}else a===xd&&"elements"===b&&null!==this.Jb&&(d===this.Jb?this.Jb=null:d instanceof y&&this.Jb.ej(d)&&
(this.Jb=null));C.prototype.gn.call(this,a,b,c,d,f,g,h)};Je.prototype.updateAdornments=function(){};Je.prototype.Nm=function(){};function R(a){0===arguments.length?C.call(this,$g):C.call(this,a);this.nc=new B(T);this.io=this.Lk=this.nr=this.mr=null;this.dr=!0;this.Gs=!1;this.Bs=null;this.qq=this.er=!0;this.rq=F.eH;this.Rd=this.qh=null;this.Qr=Jm;this.Uh=!1}t.ga("Node",R);t.Ka(R,C);
R.prototype.cloneProtected=function(a){C.prototype.cloneProtected.call(this,a);a.mr=this.mr;a.nr=this.nr;a.Lk=this.Lk;a.dr=this.dr;a.Gs=this.Gs;a.Bs=this.Bs;a.er=this.er;a.qq=this.qq;a.rq=this.rq.Z();a.Qr=this.Qr};R.prototype.Yh=function(a){C.prototype.Yh.call(this,a);a.wf();a.qh=this.qh;a.Rd=null};var Km;R.DirectionDefault=Km=t.w(R,"DirectionDefault",0);R.DirectionAbsolute=t.w(R,"DirectionAbsolute",1);var Lm;R.DirectionRotatedNode=Lm=t.w(R,"DirectionRotatedNode",2);var kk;
R.DirectionRotatedNodeOrthogonal=kk=t.w(R,"DirectionRotatedNodeOrthogonal",3);R.SpreadingNone=t.w(R,"SpreadingNone",10);var Jm;R.SpreadingEvenly=Jm=t.w(R,"SpreadingEvenly",11);var Mm;R.SpreadingPacked=Mm=t.w(R,"SpreadingPacked",12);function Nm(a,b){null!==b&&(null===a.qh&&(a.qh=new ma(Om)),a.qh.add(b))}
R.prototype.gn=function(a,b,c,d,f,g,h){a===wd&&"elements"===b?this.Rd=null:a===xd&&"elements"===b&&(null===this.h?this.Rd=null:d instanceof Q&&Vl(this,d,function(a,b){Sk(a,b,!0)}));C.prototype.gn.call(this,a,b,c,d,f,g,h)};R.prototype.invalidateConnectedLinks=R.prototype.wf=function(a){void 0===a&&(a=null);for(var b=this.qe;b.next();){var c=b.value;null!==a&&a.contains(c)||(Pm(this,c.ud),Pm(this,c.fe),c.ac())}};function Pm(a,b){if(null!==b){b.Pr=null;var c=a.hb;null===c||c.Yd||Pm(c,c.gl(""))}}
R.prototype.ol=function(){return!0};t.defineProperty(R,{DJ:"portSpreading"},function(){return this.Qr},function(a){var b=this.Qr;b!==a&&(e&&t.Aa(a,R,R,"portSpreading"),this.Qr=a,this.j("portSpreading",b,a),a=this.h,null!==a&&a.la.vb||this.wf())});t.g(R,"avoidable",R.prototype.Qz);t.defineProperty(R,{Qz:"avoidable"},function(){return this.qq},function(a){var b=this.qq;if(b!==a){e&&t.i(a,"boolean",R,"avoidable");this.qq=a;var c=this.h;null!==c&&Xj(c,this);this.j("avoidable",b,a)}});
t.g(R,"avoidableMargin",R.prototype.PD);t.defineProperty(R,{PD:"avoidableMargin"},function(){return this.rq},function(a){"number"===typeof a?a=new ab(a):t.l(a,ab,R,"avoidableMargin");var b=this.rq;if(!b.M(a)){this.rq=a=a.Z();var c=this.h;null!==c&&Xj(c,this);this.j("avoidableMargin",b,a)}});R.prototype.canAvoid=function(){return this.Qz&&!this.bi};R.prototype.getAvoidableRect=function(a){a.set(this.oa);a.bw(this.PD);return a};
R.prototype.findVisibleNode=function(){for(var a=this;null!==a&&!a.La();)a=a.hb;return a};R.prototype.df=function(a){C.prototype.df.call(this,a);for(var b=this.qe;b.next();)b.value.df(a)};t.A(R,{qe:"linksConnected"},function(){return this.nc.k});R.prototype.findLinksConnected=R.prototype.yE=function(a){void 0===a&&(a=null);if(null===a)return this.nc.k;e&&t.i(a,"string",R,"findLinksConnected:pid");var b=new Ba(this.nc),c=this;b.tl=function(b){return b.aa===c&&b.Rf===a||b.ca===c&&b.Og===a};return b};
R.prototype.findLinksOutOf=R.prototype.Cw=function(a){void 0===a&&(a=null);e&&null!==a&&t.i(a,"string",R,"findLinksOutOf:pid");var b=new Ba(this.nc),c=this;b.tl=function(b){return b.aa!==c?!1:null===a?!0:b.Rf===a};return b};R.prototype.findLinksInto=R.prototype.wg=function(a){void 0===a&&(a=null);e&&null!==a&&t.i(a,"string",R,"findLinksInto:pid");var b=new Ba(this.nc),c=this;b.tl=function(b){return b.ca!==c?!1:null===a?!0:b.Og===a};return b};
R.prototype.findNodesConnected=R.prototype.zE=function(a){void 0===a&&(a=null);e&&null!==a&&t.i(a,"string",R,"findNodesConnected:pid");for(var b=null,c=null,d=this.nc.k;d.next();){var f=d.value;if(f.aa===this){if(null===a||f.Rf===a)f=f.ca,null!==b?b.add(f):null!==c&&c!==f?(b=new ma(R),b.add(c),b.add(f)):c=f}else f.ca!==this||null!==a&&f.Og!==a||(f=f.aa,null!==b?b.add(f):null!==c&&c!==f?(b=new ma(R),b.add(c),b.add(f)):c=f)}return null!==b?b.k:null!==c?new Aa(c):t.ag};
R.prototype.findNodesOutOf=function(a){void 0===a&&(a=null);e&&null!==a&&t.i(a,"string",R,"findNodesOutOf:pid");for(var b=null,c=null,d=this.nc.k;d.next();){var f=d.value;f.aa!==this||null!==a&&f.Rf!==a||(f=f.ca,null!==b?b.add(f):null!==c&&c!==f?(b=new ma(R),b.add(c),b.add(f)):c=f)}return null!==b?b.k:null!==c?new Aa(c):t.ag};
R.prototype.findNodesInto=function(a){void 0===a&&(a=null);e&&null!==a&&t.i(a,"string",R,"findNodesInto:pid");for(var b=null,c=null,d=this.nc.k;d.next();){var f=d.value;f.ca!==this||null!==a&&f.Og!==a||(f=f.aa,null!==b?b.add(f):null!==c&&c!==f?(b=new ma(R),b.add(c),b.add(f)):c=f)}return null!==b?b.k:null!==c?new Aa(c):t.ag};
R.prototype.findLinksBetween=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);e&&(t.l(a,R,R,"findLinksBetween:othernode"),null!==b&&t.i(b,"string",R,"findLinksBetween:pid"),null!==c&&t.i(c,"string",R,"findLinksBetween:otherpid"));var d=new Ba(this.nc),f=this;d.tl=function(d){return(d.aa!==f||d.ca!==a||null!==b&&d.Rf!==b||null!==c&&d.Og!==c)&&(d.aa!==a||d.ca!==f||null!==c&&d.Rf!==c||null!==b&&d.Og!==b)?!1:!0};return d};
R.prototype.findLinksTo=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);e&&(t.l(a,R,R,"findLinksTo:othernode"),null!==b&&t.i(b,"string",R,"findLinksTo:pid"),null!==c&&t.i(c,"string",R,"findLinksTo:otherpid"));var d=new Ba(this.nc),f=this;d.tl=function(d){return d.aa!==f||d.ca!==a||null!==b&&d.Rf!==b||null!==c&&d.Og===c?!1:!0};return d};t.g(R,"linkConnected",R.prototype.oF);
t.defineProperty(R,{oF:"linkConnected"},function(){return this.mr},function(a){var b=this.mr;b!==a&&(null!==a&&t.i(a,"function",R,"linkConnected"),this.mr=a,this.j("linkConnected",b,a))});t.g(R,"linkDisconnected",R.prototype.pF);t.defineProperty(R,{pF:"linkDisconnected"},function(){return this.nr},function(a){var b=this.nr;b!==a&&(null!==a&&t.i(a,"function",R,"linkDisconnected"),this.nr=a,this.j("linkDisconnected",b,a))});t.g(R,"linkValidation",R.prototype.Ap);
t.defineProperty(R,{Ap:"linkValidation"},function(){return this.Lk},function(a){var b=this.Lk;b!==a&&(null!==a&&t.i(a,"function",R,"linkValidation"),this.Lk=a,this.j("linkValidation",b,a))});
function Qm(a,b,c){Pm(a,c);if(!a.nc.contains(b)){a.nc.add(b);var d=a.oF;if(null!==d){var f=!0,g=a.h;null!==g&&(f=g.Na,g.Na=!0);d(a,b,c);null!==g&&(g.Na=f)}b.Ec&&(c=b.aa,b=b.ca,null!==c&&null!==b&&c!==b&&(d=!0,g=a.h,null!==g&&(d=g.pd),a=d?b:c,f=d?c:b,a.Uh||(a.Uh=f),!f.ci||null!==g&&g.la.vb||(d?c===f&&(f.ci=!1):b===f&&(f.ci=!1))))}}
function Rm(a,b,c){Pm(a,c);if(a.nc.remove(b)){var d=a.pF,f=a.h;if(null!==d){var g=!0;null!==f&&(g=f.Na,f.Na=!0);d(a,b,c);null!==f&&(f.Na=g)}b.Ec&&(c=!0,null!==f&&(c=f.pd),a=c?b.ca:b.aa,b=c?b.aa:b.ca,null!==a&&(a.Uh=!1),null===b||b.ci||(0===b.nc.count?(b.Uh=null,null!==f&&f.la.vb||(b.ci=!0)):Qj(b)))}}
function Qj(a){a.Uh=!1;if(0!==a.nc.count){var b=!0,c=a.h;if(null===c||!c.la.vb){null!==c&&(b=c.pd);for(c=a.nc.k;c.next();){var d=c.value;if(d.Ec)if(b){if(d.aa===a){a.ci=!1;return}}else if(d.ca===a){a.ci=!1;return}}a.ci=!0}}}
R.prototype.updateModelDataReferences=function(){C.prototype.updateModelDataReferences.call(this);var a=this.data;if(null!==a){var b=this.h;if(null!==b){var c=b.ba;if(c instanceof Kd){var d=c.Tm(a),b=b.Ve(d),f=this.dA();b!==f&&(b=null!==f?c.Hb(f.data):void 0,f=c.fn,"function"===typeof f?f(a,b):a[f]=b,c.lh(a,d))}}}};R.prototype.lt=function(a){C.prototype.lt.call(this,a);a||Qj(this);var b=this.io;null===b||a||Sm(b,this)};
R.prototype.mt=function(a){C.prototype.mt.call(this,a);var b=this.io;null===b||a||null===b.we||(b.we.remove(this),b.ha())};R.prototype.Nm=function(){if(0<this.nc.count){var a=this.h;if(null===a)return;for(var b=this.nc.copy().k;b.next();)a.remove(b.value)}this.$d=null;C.prototype.Nm.call(this)};t.A(R,{bi:"isLinkLabel"},function(){return null!==this.io});t.g(R,"labeledLink",R.prototype.$d);
t.defineProperty(R,{$d:"labeledLink"},function(){return this.io},function(a){var b=this.io;if(b!==a){e&&null!==a&&t.l(a,T,R,"labeledLink");var c=this.h,d=this.data;if(null!==b&&(null!==b.we&&(b.we.remove(this),b.ha()),null!==c&&null!==d&&!c.la.vb)){var f=b.data,g=c.ba;if(null!==f&&g instanceof M){var h=g.Hb(d);void 0!==h&&g.IJ(f,h)}}this.io=a;null!==a&&(Sm(a,this),null===c||null===d||c.la.vb||(f=a.data,g=c.ba,null!==f&&g instanceof M&&(h=g.Hb(d),void 0!==h&&g.GD(f,h))));Jk(this);this.j("labeledLink",
b,a)}});R.prototype.findPort=R.prototype.gl=function(a){e&&t.i(a,"string",R,"findPort:pid");if(null===this.Rd){if(""===a&&!1===this.Eh)return this;Tk(this)}var b=this.Rd.za(a);return null!==b||""!==a&&(b=this.Rd.za(""),null!==b)?b:this};t.A(R,{port:"port"},function(){return this.gl("")});t.A(R,{ports:"ports"},function(){null===this.Rd&&Tk(this);return this.Rd.jF});
function Tk(a){null===a.Rd?a.Rd=new ia("string",Q):a.Rd.clear();Vl(a,a,function(a,c){var d=c.Nd;null!==d&&a.Rd.add(d,c)});0===a.Rd.count&&a.Rd.add("",a)}function Sk(a,b,c){var d=b.Nd;if(null!==d&&(null!==a.Rd&&a.Rd.remove(d),b=a.h,null!==b&&c)){c=null;for(a=a.yE(d);a.next();)null===c&&(c=t.Db()),c.push(a.value);if(null!==c){for(a=0;a<c.length;a++)b.remove(c[a]);t.ya(c)}}}
R.prototype.isInTreeOf=function(a){if(null===a||a===this)return!1;var b=!0,c=this.h;null!==c&&(b=c.pd);c=this;if(b)for(;c!==a;){for(var b=null,d=c.nc.k;d.next();){var f=d.value;if(f.Ec&&(b=f.aa,b!==c&&b!==this))break}if(b===this||null===b||b===c)return!1;c=b}else for(;c!==a;){b=null;for(d=c.nc.k;d.next()&&(f=d.value,!f.Ec||(b=f.ca,b===c||b===this)););if(b===this||null===b||b===c)return!1;c=b}return!0};
R.prototype.findTreeRoot=function(){var a=!0,b=this.h;null!==b&&(a=b.pd);b=this;if(a)for(;;){for(var a=null,c=b.nc.k;c.next();){var d=c.value;if(d.Ec&&(a=d.aa,a!==b&&a!==this))break}if(a===this)return this;if(null===a||a===b)return b;b=a}else for(;;){a=null;for(c=b.nc.k;c.next()&&(d=c.value,!d.Ec||(a=d.ca,a===b||a===this)););if(a===this)return this;if(null===a||a===b)return b;b=a}};
R.prototype.findTreeParentLink=R.prototype.ft=function(){var a=!0,b=this.h;null!==b&&(a=b.pd);b=this.nc.k;if(a)for(;b.next();){if(a=b.value,a.Ec&&a.aa!==this)return a}else for(;b.next();)if(a=b.value,a.Ec&&a.ca!==this)return a;return null};
R.prototype.findTreeParentNode=R.prototype.dA=function(){if(null===this.Uh||this.Uh instanceof R)return this.Uh;var a=!0,b=this.h;null!==b&&(a=b.pd);var b=this.nc.n,c=b.length;if(a)for(a=0;a<c;a++){var d=b[a];if(d.Ec&&(d=d.aa,d!==this))return this.Uh=d}else for(a=0;a<c;a++)if(d=b[a],d.Ec&&(d=d.ca,d!==this))return this.Uh=d;return this.Uh=null};
R.prototype.findTreeChildrenLinks=R.prototype.Fw=function(){var a=!0,b=this.h;null!==b&&(a=b.pd);if(a){var a=new Ba(this.nc),c=this;a.tl=function(a){return a.Ec&&a.aa===c?!0:!1}}else a=new Ba(this.nc),c=this,a.tl=function(a){return a.Ec&&a.ca===c?!0:!1};return a};
R.prototype.findTreeChildrenNodes=R.prototype.BE=function(){var a=!0,b=this.h;null!==b&&(a=b.pd);var c=b=null,d=this.nc.k;if(a)for(;d.next();)a=d.value,a.Ec&&a.aa===this&&(a=a.ca,null!==b?b.add(a):null!==c&&c!==a?(b=new B(R),b.add(c),b.add(a)):c=a);else for(;d.next();)a=d.value,a.Ec&&a.ca===this&&(a=a.aa,null!==b?b.add(a):null!==c&&c!==a?(b=new B(R),b.add(c),b.add(a)):c=a);return null!==b?b.k:null!==c?new Aa(c):t.ag};
R.prototype.findTreeParts=function(a){void 0===a&&(a=Infinity);t.i(a,"number",R,"collapseTree:level");var b=new ma(C);Ie(b,this,!1,a);return b};R.prototype.collapseTree=R.prototype.collapseTree=function(a){void 0===a&&(a=1);t.q(a,R,"collapseTree:level");1>a&&(a=1);var b=this.h;if(null!==b&&!b.Wd){var c=b.Ub;0!==b.la.Je&&c.ul();b.Wd=!0;var c=b.pd,d=new ma(R);d.add(this);Tm(this,d,c,a,this.Qc);b.Wd=!1;b.fa()}};
function Tm(a,b,c,d,f){if(1<d)for(f=c?a.Cw():a.wg();f.next();){var g=f.value;g.Ec&&(g=g.jA(a),null===g||g===a||b.contains(g)||(b.add(g),Tm(g,b,c,d-1,g.Qc)))}else Um(a,b,c,f)}function Um(a,b,c,d){for(var f=c?a.Cw():a.wg();f.next();){var g=f.value;d&&g.Wi();g.Ec&&(g=g.jA(a),null===g||g===a||b.contains(g)||(b.add(g),d&&(g.fa(),wk(g),g.df(!1)),g.Qc&&(g.pn=g.Qc,Um(g,b,c,g.pn))))}a.Qc=!1}
R.prototype.expandTree=R.prototype.expandTree=function(a){void 0===a&&(a=2);t.q(a,R,"collapseTree:level");2>a&&(a=2);var b=this.h;if(null!==b&&!b.Wd){var c=b.Ub;0!==b.la.Je&&c.ul();b.Wd=!0;var d=b.pd,f=new ma(R);f.add(this);Vm(this,f,d,a,this.Qc,c,this);b.Wd=!1}};
function Vm(a,b,c,d,f,g,h){for(var k=c?a.Cw():a.wg();k.next();){var l=k.value;l.Ec&&(f||(l.rg||l.ac(),l.updateAdornments()),l=l.jA(a),null!==l&&l!==a&&!b.contains(l)&&(b.add(l),f||(l.df(!0),wk(l),Nh(g,l,h)),2<d||l.pn))&&(l.pn=!1,Vm(l,b,c,d-1,l.Qc,g,h))}a.Qc=!0}t.g(R,"isTreeExpanded",R.prototype.Qc);
t.defineProperty(R,{Qc:"isTreeExpanded"},function(){return this.dr},function(a){var b=this.dr;if(b!==a){e&&t.i(a,"boolean",R,"isTreeExpanded");this.dr=a;var c=this.h;this.j("isTreeExpanded",b,a);b=this.LG;if(null!==b){var d=!0;null!==c&&(d=c.Na,c.Na=!0);b(this);null!==c&&(c.Na=d)}a?null===c||c.Wd||(0!==c.la.Je&&c.Ub.ul(),c.Wd=!0,a=c.pd,b=new ma(R),b.add(this),Vm(this,b,a,2,!1,c.Ub,this),c.Wd=!1):null===c||c.Wd||(0!==c.la.Je&&c.Ub.ul(),c.Wd=!0,a=c.pd,b=new ma(R),b.add(this),Um(this,b,a,!0),c.Wd=!1)}});
t.g(R,"wasTreeExpanded",R.prototype.pn);t.defineProperty(R,{pn:"wasTreeExpanded"},function(){return this.Gs},function(a){var b=this.Gs;b!==a&&(e&&t.i(a,"boolean",R,"wasTreeExpanded"),this.Gs=a,this.j("wasTreeExpanded",b,a))});t.g(R,"treeExpandedChanged",R.prototype.LG);t.defineProperty(R,{LG:"treeExpandedChanged"},function(){return this.Bs},function(a){var b=this.Bs;b!==a&&(null!==a&&t.i(a,"function",R,"treeExpandedChanged"),this.Bs=a,this.j("treeExpandedChanged",b,a))});t.g(R,"isTreeLeaf",R.prototype.ci);
t.defineProperty(R,{ci:"isTreeLeaf"},function(){return this.er},function(a){var b=this.er;b!==a&&(e&&t.i(a,"boolean",R,"isTreeLeaf"),this.er=a,this.j("isTreeLeaf",b,a))});
function T(){C.call(this,wg);this.eg=null;this.Bh="";this.qg=this.Pq=null;this.Rh="";this.As=null;this.Vr=this.Ur=this.Tr=!1;this.fr=!0;this.lq=zg;this.yq=0;this.Bq=zg;this.Cq=NaN;this.pm=el;this.ps=0.5;this.Ji=this.we=null;this.Cc=(new B(w)).freeze();this.xo=this.xe=null;this.rg=!1;this.oz=null;this.vz=!1;this.xn=this.ui=this.Ta=null;this.hf=0;this.Jn=this.Fn=null;this.mi=new B(x);this.zz=new w;this.oD=this.mD=null;this.qy=!1;this.P=null}t.ga("Link",T);t.Ka(T,C);
T.prototype.cloneProtected=function(a){C.prototype.cloneProtected.call(this,a);a.Bh=this.Bh;a.Pq=this.Pq;a.Rh=this.Rh;a.As=this.As;a.Tr=this.Tr;a.Ur=this.Ur;a.Vr=this.Vr;a.fr=this.fr;a.lq=this.lq;a.yq=this.yq;a.Bq=this.Bq;a.Cq=this.Cq;a.pm=this.pm;a.ps=this.ps;if(null!==this.P){var b=this.P;a.P={Dh:b.Dh.Z(),Th:b.Th.Z(),Ah:b.Ah,Qh:b.Qh,zh:b.zh,Ph:b.Ph,Ch:b.Ch,Sh:b.Sh}}else a.P=null};
T.prototype.Yh=function(a){C.prototype.Yh.call(this,a);this.Bh=a.Bh;this.Rh=a.Rh;a.Ji=null;a.xe=null;a.ac();a.xn=this.xn;a.hf=this.hf};var el;T.Normal=el=t.w(T,"Normal",1);T.Orthogonal=t.w(T,"Orthogonal",2);T.AvoidsNodes=t.w(T,"AvoidsNodes",6);var Wm;T.AvoidsNodesStraight=Wm=t.w(T,"AvoidsNodesStraight",7);var zg;T.None=zg=t.w(T,"None",0);var Pg;T.Bezier=Pg=t.w(T,"Bezier",9);var yg;T.JumpGap=yg=t.w(T,"JumpGap",10);var xg;T.JumpOver=xg=t.w(T,"JumpOver",11);var bl;T.End=bl=t.w(T,"End",17);var cl;
T.Scale=cl=t.w(T,"Scale",18);var dl;T.Stretch=dl=t.w(T,"Stretch",19);var jm;T.OrientAlong=jm=t.w(T,"OrientAlong",21);var Xm;T.OrientPlus90=Xm=t.w(T,"OrientPlus90",22);var Ym;T.OrientMinus90=Ym=t.w(T,"OrientMinus90",23);var Zm;T.OrientOpposite=Zm=t.w(T,"OrientOpposite",24);var $m;T.OrientUpright=$m=t.w(T,"OrientUpright",25);var an;T.OrientPlus90Upright=an=t.w(T,"OrientPlus90Upright",26);var bn;T.OrientMinus90Upright=bn=t.w(T,"OrientMinus90Upright",27);var cn;
T.OrientUpright45=cn=t.w(T,"OrientUpright45",28);T.prototype.Fe=function(){this.P={Dh:xb,Th:xb,Ah:NaN,Qh:NaN,zh:Km,Ph:Km,Ch:NaN,Sh:NaN}};T.prototype.ol=function(){var a=this.aa;if(null!==a){var b=a.findVisibleNode();null!==b&&(a=b);if(Ri(a)||Si(a))return!1}a=this.ca;return null!==a&&(b=a.findVisibleNode(),null!==b&&(a=b),Ri(a)||Si(a))?!1:!0};T.prototype.Ld=function(){return!1};
T.prototype.computeAngle=function(a,b,c){switch(b){default:case zg:a=0;break;case jm:a=c;break;case Xm:a=c+90;break;case Ym:a=c-90;break;case Zm:a=c+180;break;case $m:a=F.Dt(c);90<a&&270>a&&(a-=180);break;case an:a=F.Dt(c+90);90<a&&270>a&&(a-=180);break;case bn:a=F.Dt(c-90);90<a&&270>a&&(a-=180);break;case cn:a=F.Dt(c);if(45<a&&135>a||225<a&&315>a)return 0;90<a&&270>a&&(a-=180)}return F.Dt(a)};t.g(T,"fromNode",T.prototype.aa);
t.defineProperty(T,{aa:"fromNode"},function(){return this.eg},function(a){var b=this.eg;if(b!==a){e&&null!==a&&t.l(a,R,T,"fromNode");var c=this.ud;null!==b&&(this.qg!==b&&Rm(b,this,c),dn(this),this.J(gj));this.eg=a;this.ui=null;this.ac();var d=this.h;if(null!==d){var f=this.data,g=d.ba;if(null!==f)if(g instanceof M){var h=null!==a?a.data:null;g.ux(f,g.Hb(h))}else g instanceof Kd&&(h=null!==a?a.data:null,d.pd?g.lh(f,g.Hb(h)):(null!==b&&g.lh(b.data,void 0),g.lh(h,g.Hb(null!==this.qg?this.qg.data:null))))}f=
this.ud;g=this.iA;null!==g&&(h=!0,null!==d&&(h=d.Na,d.Na=!0),g(this,c,f),null!==d&&(d.Na=h));null!==a&&(this.qg!==a&&Qm(a,this,f),en(this),this.J(aj));this.j("fromNode",b,a);Lj(this)}});t.g(T,"fromPortId",T.prototype.Rf);
t.defineProperty(T,{Rf:"fromPortId"},function(){return this.Bh},function(a){var b=this.Bh;if(b!==a){e&&t.i(a,"string",T,"fromPortId");var c=this.ud;null!==c&&Pm(this.aa,c);dn(this);this.Bh=a;var d=this.ud;null!==d&&Pm(this.aa,d);var f=this.h;if(null!==f){var g=this.data,h=f.ba;null!==g&&h instanceof M&&h.$A(g,a)}c!==d&&(this.ui=null,this.ac(),g=this.iA,null!==g&&(h=!0,null!==f&&(h=f.Na,f.Na=!0),g(this,c,d),null!==f&&(f.Na=h)));en(this);this.j("fromPortId",b,a)}});
t.A(T,{ud:"fromPort"},function(){var a=this.eg;return null===a?null:a.gl(this.Bh)});t.g(T,"fromPortChanged",T.prototype.iA);t.defineProperty(T,{iA:"fromPortChanged"},function(){return this.Pq},function(a){var b=this.Pq;b!==a&&(null!==a&&t.i(a,"function",T,"fromPortChanged"),this.Pq=a,this.j("fromPortChanged",b,a))});t.g(T,"toNode",T.prototype.ca);
t.defineProperty(T,{ca:"toNode"},function(){return this.qg},function(a){var b=this.qg;if(b!==a){e&&null!==a&&t.l(a,R,T,"toNode");var c=this.fe;null!==b&&(this.eg!==b&&Rm(b,this,c),dn(this),this.J(gj));this.qg=a;this.ui=null;this.ac();var d=this.h;if(null!==d){var f=this.data,g=d.ba;if(null!==f)if(g instanceof M){var h=null!==a?a.data:null;g.wx(f,g.Hb(h))}else g instanceof Kd&&(h=null!==a?a.data:null,d.pd?(null!==b&&g.lh(b.data,void 0),g.lh(h,g.Hb(null!==this.eg?this.eg.data:null))):g.lh(f,g.Hb(h)))}f=
this.fe;g=this.kB;null!==g&&(h=!0,null!==d&&(h=d.Na,d.Na=!0),g(this,c,f),null!==d&&(d.Na=h));null!==a&&(this.eg!==a&&Qm(a,this,f),en(this),this.J(aj));this.j("toNode",b,a);Lj(this)}});t.g(T,"toPortId",T.prototype.Og);
t.defineProperty(T,{Og:"toPortId"},function(){return this.Rh},function(a){var b=this.Rh;if(b!==a){e&&t.i(a,"string",T,"toPortId");var c=this.fe;null!==c&&Pm(this.ca,c);dn(this);this.Rh=a;var d=this.fe;null!==d&&Pm(this.ca,d);var f=this.h;if(null!==f){var g=this.data,h=f.ba;null!==g&&h instanceof M&&h.cB(g,a)}c!==d&&(this.ui=null,this.ac(),g=this.kB,null!==g&&(h=!0,null!==f&&(h=f.Na,f.Na=!0),g(this,c,d),null!==f&&(f.Na=h)));en(this);this.j("toPortId",b,a)}});
t.A(T,{fe:"toPort"},function(){var a=this.qg;return null===a?null:a.gl(this.Rh)});t.g(T,"toPortChanged",T.prototype.kB);t.defineProperty(T,{kB:"toPortChanged"},function(){return this.As},function(a){var b=this.As;b!==a&&(null!==a&&t.i(a,"function",T,"toPortChanged"),this.As=a,this.j("toPortChanged",b,a))});
t.defineProperty(T,{ob:"fromSpot"},function(){return null!==this.P?this.P.Dh:xb},function(a){null===this.P&&this.Fe();var b=this.P.Dh;b.M(a)||(e&&t.l(a,H,T,"fromSpot"),a=a.Z(),this.P.Dh=a,this.j("fromSpot",b,a),this.ac())});
t.defineProperty(T,{dk:"fromEndSegmentLength"},function(){return null!==this.P?this.P.Ah:NaN},function(a){null===this.P&&this.Fe();var b=this.P.Ah;b!==a&&(e&&t.i(a,"number",T,"fromEndSegmentLength"),0>a&&t.ka(a,">= 0",T,"fromEndSegmentLength"),this.P.Ah=a,this.j("fromEndSegmentLength",b,a),this.ac())});
t.defineProperty(T,{hp:"fromEndSegmentDirection"},function(){return null!==this.P?this.P.zh:Km},function(a){null===this.P&&this.Fe();var b=this.P.zh;b!==a&&(e&&t.Aa(a,R,T,"fromEndSegmentDirection"),this.P.zh=a,this.j("fromEndSegmentDirection",b,a),this.ac())});t.defineProperty(T,{ip:"fromShortLength"},function(){return null!==this.P?this.P.Ch:NaN},function(a){null===this.P&&this.Fe();var b=this.P.Ch;b!==a&&(e&&t.i(a,"number",T,"fromShortLength"),this.P.Ch=a,this.j("fromShortLength",b,a),this.ac())});
t.defineProperty(T,{rb:"toSpot"},function(){return null!==this.P?this.P.Th:xb},function(a){null===this.P&&this.Fe();var b=this.P.Th;b.M(a)||(e&&t.l(a,H,T,"toSpot"),a=a.Z(),this.P.Th=a,this.j("toSpot",b,a),this.ac())});
t.defineProperty(T,{jk:"toEndSegmentLength"},function(){return null!==this.P?this.P.Qh:NaN},function(a){null===this.P&&this.Fe();var b=this.P.Qh;b!==a&&(e&&t.i(a,"number",T,"toEndSegmentLength"),0>a&&t.ka(a,">= 0",T,"toEndSegmentLength"),this.P.Qh=a,this.j("toEndSegmentLength",b,a),this.ac())});
t.defineProperty(T,{Xp:"toEndSegmentDirection"},function(){return null!==this.P?this.P.Ph:Km},function(a){null===this.P&&this.Fe();var b=this.P.Ph;b!==a&&(e&&t.Aa(a,R,T,"toEndSegmentDirection"),this.P.Ph=a,this.j("toEndSegmentDirection",b,a),this.ac())});t.defineProperty(T,{Yp:"toShortLength"},function(){return null!==this.P?this.P.Sh:NaN},function(a){null===this.P&&this.Fe();var b=this.P.Sh;b!==a&&(e&&t.i(a,"number",T,"toShortLength"),this.P.Sh=a,this.j("toShortLength",b,a),this.ac())});
function Lj(a){var b=a.aa,c=a.ca;null!==b&&null!==c?fn(a,b.EI(c)):fn(a,null)}function fn(a,b){var c=a.zk;if(c!==b){null!==c&&Em(c,a);a.zk=b;null!==b&&Fm(b,a);var d=a.Wz;if(null!==d){var f=!0,g=a.h;null!==g&&(f=g.Na,g.Na=!0);d(a,c,b);null!==g&&(g.Na=f)}!a.rg||a.mD!==c&&a.oD!==c||a.ac()}}T.prototype.getOtherNode=T.prototype.jA=function(a){e&&t.l(a,R,T,"getOtherNode:node");var b=this.aa;return a===b?this.ca:b};
T.prototype.getOtherPort=function(a){e&&t.l(a,Q,T,"getOtherPort:port");var b=this.ud;return a===b?this.fe:b};t.A(T,{zK:"isLabeledLink"},function(){return null===this.we?!1:0<this.we.count});t.A(T,{fk:"labelNodes"},function(){return null===this.we?t.ag:this.we.k});function Sm(a,b){null===a.we&&(a.we=new ma(R));a.we.add(b);a.ha()}
T.prototype.lt=function(a){C.prototype.lt.call(this,a);gn(this)&&Ag(this,this.oa);if(!a){a=this.eg;var b=this.qg;null!==a&&(Qm(a,this,this.ud),en(this));null!==b&&(Qm(b,this,this.fe),en(this))}};T.prototype.mt=function(a){C.prototype.mt.call(this,a);gn(this)&&Ag(this,this.oa);if(!a){a=this.eg;var b=this.qg;null!==a&&(Rm(a,this,this.ud),dn(this));null!==b&&(Rm(b,this,this.fe),dn(this))}};
T.prototype.Nm=function(){this.rg=!0;if(null!==this.we){var a=this.h;if(null===a)return;for(var b=this.we.copy().k;b.next();)a.remove(b.value)}b=this.data;null!==b&&(a=this.h,null!==a&&(a=a.ba,a instanceof M?a.UA(b):a instanceof Kd&&a.lh(b,void 0)))};
T.prototype.updateModelDataReferences=function(){var a=this.data;if(null!==a){var b=this.h;if(null!==b){var c=b.ba;if(c instanceof M){var d=c.il(a),f=b.Ve(d),g=this.aa;f!==g&&(f=null!==g?c.Hb(g.data):void 0,g=c.Zm,"function"===typeof g?g(a,f):a[g]=f,c.ux(a,d));d=c.ml(a);f=b.Ve(d);g=this.ca;f!==g&&(f=null!==g?c.Hb(g.data):void 0,g=c.$m,"function"===typeof g?g(a,f):a[g]=f,c.wx(a,d))}}}};
T.prototype.move=T.prototype.move=function(a){var b=this.position,c=b.x;isNaN(c)&&(c=0);b=b.y;isNaN(b)&&(b=0);c=a.x-c;b=a.y-b;C.prototype.move.call(this,a);this.ql(c,b);for(a=this.fk;a.next();){var d=a.value,f=d.position;d.moveTo(f.x+c,f.y+b)}};t.g(T,"relinkableFrom",T.prototype.LF);t.defineProperty(T,{LF:"relinkableFrom"},function(){return this.Tr},function(a){var b=this.Tr;b!==a&&(e&&t.i(a,"boolean",T,"relinkableFrom"),this.Tr=a,this.j("relinkableFrom",b,a),this.updateAdornments())});
t.g(T,"relinkableTo",T.prototype.MF);t.defineProperty(T,{MF:"relinkableTo"},function(){return this.Ur},function(a){var b=this.Ur;b!==a&&(e&&t.i(a,"boolean",T,"relinkableTo"),this.Ur=a,this.j("relinkableTo",b,a),this.updateAdornments())});T.prototype.canRelinkFrom=function(){if(!this.LF)return!1;var a=this.layer;if(null===a)return!0;if(!a.Sj)return!1;a=a.h;return null===a||a.Sj?!0:!1};
T.prototype.canRelinkTo=function(){if(!this.MF)return!1;var a=this.layer;if(null===a)return!0;if(!a.Sj)return!1;a=a.h;return null===a||a.Sj?!0:!1};t.g(T,"resegmentable",T.prototype.Kp);t.defineProperty(T,{Kp:"resegmentable"},function(){return this.Vr},function(a){var b=this.Vr;b!==a&&(e&&t.i(a,"boolean",T,"resegmentable"),this.Vr=a,this.j("resegmentable",b,a),this.updateAdornments())});t.g(T,"isTreeLink",T.prototype.Ec);
t.defineProperty(T,{Ec:"isTreeLink"},function(){return this.fr},function(a){var b=this.fr;b!==a&&(e&&t.i(a,"boolean",T,"isTreeLink"),this.fr=a,this.j("isTreeLink",b,a),null!==this.aa&&Qj(this.aa),null!==this.ca&&Qj(this.ca))});t.A(T,{path:"path"},function(){var a=this.Ji;if(null!==a)return a;a=this.xa;a=Kk(this,a,a.length);return a instanceof V?this.Ji=a:null});T.prototype.Rq=function(){return this.path};T.prototype.Sy=function(){return this.Cc};
T.prototype.uj=function(){this.updateRoute();var a=new x;this.qf(a);return this.oz=a};
T.prototype.qf=function(a){var b=Infinity,c=Infinity,d=this.pa;if(0===d)return a.p(NaN,NaN,0,0),a;if(1===d)d=this.o(0),b=Math.min(d.x,b),c=Math.min(d.y,c),a.p(d.x,d.y,0,0);else if(2===d){var f=this.o(0),g=this.o(1),b=Math.min(f.x,g.x),c=Math.min(f.y,g.y);a.p(f.x,f.y,0,0);a.kj(g)}else if(this.computeCurve()===Pg&&3<=d&&!this.ic)if(f=this.o(0),b=f.x,c=f.y,a.p(b,c,0,0),3===d)d=this.o(1),b=Math.min(d.x,b),c=Math.min(d.y,c),g=this.o(2),b=Math.min(g.x,b),c=Math.min(g.y,c),F.So(f.x,f.y,d.x,d.y,d.x,d.y,g.x,
g.y,0.5,a);else for(var h,k,l=3;l<d;l+=3)h=this.o(l-2),l+3>=d&&(l=d-1),k=this.o(l-1),g=this.o(l),F.So(f.x,f.y,h.x,h.y,k.x,k.y,g.x,g.y,0.5,a),b=Math.min(g.x,b),c=Math.min(g.y,c),f=g;else for(f=this.o(0),g=this.o(1),b=Math.min(f.x,g.x),c=Math.min(f.y,g.y),a.p(f.x,f.y,0,0),a.kj(g),l=2;l<d;l++)f=this.o(l),b=Math.min(f.x,b),c=Math.min(f.y,c),a.kj(f);this.zz.p(b-a.x,c-a.y);return a};t.A(T,{yF:"midPoint"},function(){this.updateRoute();return this.computeMidPoint(new w)});
T.prototype.computeMidPoint=function(a){var b=this.pa;if(0===b)return a.assign(F.dH),a;if(1===b)return a.assign(this.o(0)),a;if(2===b){var c=this.o(0),d=this.o(1);a.p((c.x+d.x)/2,(c.y+d.y)/2);return a}if(this.computeCurve()===Pg&&3<=b&&!this.ic){if(3===b)return this.o(1);var b=(b-1)/3|0,f=3*(b/2|0);1===b%2?(c=this.o(f),d=this.o(f+1),b=this.o(f+2),f=this.o(f+3),F.aI(c.x,c.y,d.x,d.y,b.x,b.y,f.x,f.y,a)):a.assign(this.o(f));return a}for(var f=0,g=t.Db(),h=0;h<b-1;h++)c=this.o(h),d=this.o(h+1),F.mb(c.x,
d.x)?(c=d.y-c.y,0>c&&(c=-c)):F.mb(c.y,d.y)?(c=d.x-c.x,0>c&&(c=-c)):c=Math.sqrt(c.Zj(d)),g.push(c),f+=c;for(d=h=c=0;c<f/2&&h<b;){d=g[h];if(c+d>f/2)break;c+=d;h++}t.ya(g);b=this.o(h);g=this.o(h+1);b.x===g.x?b.y>g.y?a.p(b.x,b.y-(f/2-c)):a.p(b.x,b.y+(f/2-c)):b.y===g.y?b.x>g.x?a.p(b.x-(f/2-c),b.y):a.p(b.x+(f/2-c),b.y):(f=(f/2-c)/d,a.p(b.x+f*(g.x-b.x),b.y+f*(g.y-b.y)));return a};t.A(T,{xF:"midAngle"},function(){this.updateRoute();return this.computeMidAngle()});
T.prototype.computeMidAngle=function(){var a=this.pa;if(2>a)return NaN;if(this.computeCurve()===Pg&&4<=a&&!this.ic){var b=(a-1)/3|0,c=3*(b/2|0);if(1===b%2){var c=Math.floor(c),a=this.o(c),b=this.o(c+1),d=this.o(c+2),c=this.o(c+3);return F.$H(a.x,a.y,b.x,b.y,d.x,d.y,c.x,c.y)}if(0<c&&c+1<a)return a=this.o(c-1),b=this.o(c+1),a.aj(b)}d=a/2|0;if(0===a%2)return a=this.o(d-1),b=this.o(d),a.aj(b);var a=this.o(d-1),b=this.o(d),d=this.o(d+1),c=a.Zj(b),f=b.Zj(d);return c>f?a.aj(b):b.aj(d)};t.g(T,"points",T.prototype.points);
t.defineProperty(T,{points:"points"},function(){return this.Cc},function(a){var b=this.Cc;if(b!==a){e&&(Array.isArray(a)||a instanceof B||t.m("Link.points value is not an instance of List or Array"));var c=a;if(Array.isArray(a)){var d=0===a.length%2;if(d)for(var f=0;f<a.length;f++)if("number"!==typeof a[f]){d=!1;break}if(d)for(c=new B(w),d=0;d<a.length/2;d++)f=(new w(a[2*d],a[2*d+1])).freeze(),c.add(f);else t.m("Link.points array must contain only numbers")}else for(c=a.copy(),a=c.k;a.next();)a.value.freeze();
c.freeze();this.Cc=c;this.Ye();hn(this);this.h&&this.h.Ub.sd&&(this.xo=c);this.j("points",b,c)}});t.A(T,{pa:"pointsCount"},function(){return this.Cc.count});T.prototype.getPoint=T.prototype.o=function(a){return this.Cc.n[a]};T.prototype.setPoint=T.prototype.Ef=function(a,b){e&&(t.l(b,w,T,"setPoint"),b.N()||t.m("Link.setPoint called with a Point that does not have real numbers: "+b.toString()));null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.Mg(a,b)};
T.prototype.setPointAt=T.prototype.Y=function(a,b,c){e&&(t.q(b,T,"setPointAt:x"),t.q(c,T,"setPointAt:y"));null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.Mg(a,new w(b,c))};T.prototype.insertPoint=function(a,b){e&&(t.l(b,w,T,"insertPoint"),b.N()||t.m("Link.insertPoint called with a Point that does not have real numbers: "+b.toString()));null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.Jd(a,b)};
T.prototype.insertPointAt=T.prototype.C=function(a,b,c){e&&(t.q(b,T,"insertPointAt:x"),t.q(c,T,"insertPointAt:y"));null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.Jd(a,new w(b,c))};T.prototype.addPoint=T.prototype.Vh=function(a){e&&(t.l(a,w,T,"addPoint"),a.N()||t.m("Link.addPoint called with a Point that does not have real numbers: "+a.toString()));null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.add(a)};
T.prototype.addPointAt=T.prototype.Xk=function(a,b){e&&(t.q(a,T,"insertPointAt:x"),t.q(b,T,"insertPointAt:y"));null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.add(new w(a,b))};T.prototype.removePoint=T.prototype.PF=function(a){null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.qd(a)};
T.prototype.clearPoints=T.prototype.Uo=function(){null===this.xe&&t.m("Call Link.startRoute before modifying the points of the route.");this.Cc.clear()};T.prototype.movePoints=T.prototype.ql=function(a,b){for(var c=new B(w),d=this.Cc.k;d.next();){var f=d.value;c.add((new w(f.x+a,f.y+b)).freeze())}c.freeze();d=this.Cc;this.Cc=c;this.Ye();hn(this);this.h&&this.h.Ub.sd&&(this.xo=c);this.j("points",d,c)};T.prototype.startRoute=T.prototype.zl=function(){null===this.xe&&(this.xe=this.Cc,this.Cc=this.Cc.copy())};
T.prototype.commitRoute=T.prototype.Xi=function(){if(null!==this.xe){for(var a=this.xe,b=this.Cc,c=Infinity,d=Infinity,f=a.count,g=0;g<f;g++)var h=a.n[g],c=Math.min(h.x,c),d=Math.min(h.y,d);for(var k=Infinity,l=Infinity,m=b.count,g=0;g<m;g++)h=b.n[g],k=Math.min(h.x,k),l=Math.min(h.y,l),h.freeze();b.freeze();if(m===f)for(g=0;g<m;g++){if(f=a.n[g],h=b.n[g],f.x-c!==h.x-k||f.y-d!==h.y-l){this.Ye();break}}else this.Ye();this.xe=null;this.h&&this.h.Ub.sd&&(this.xo=b);hn(this);this.j("points",a,b)}};
T.prototype.rollbackRoute=T.prototype.KJ=function(){null!==this.xe&&(this.Cc=this.xe,this.xe=null)};function hn(a){0===a.Cc.count?a.rg=!1:(a.rg=!0,a.Fn=a.o(0).copy(),a.Jn=a.o(a.pa-1).copy(),jn(a,!1))}T.prototype.invalidateRoute=T.prototype.ac=function(){if(!(this.vz||this.h&&this.h.la.vb)){var a=this.Rq();null!==a&&(this.rg=!1,this.Ye(),a.ha())}};t.defineProperty(T,{Wp:null},function(){return this.vz},function(a){this.vz=a});
T.prototype.updateRoute=function(){if(!this.rg&&!this.qy){var a=!0;try{this.qy=!0,this.zl(),a=this.computePoints()}finally{this.qy=!1,a?this.Xi():this.KJ()}}};
T.prototype.computePoints=function(){var a=this.h;if(null===a)return!1;var b=this.aa,c=null;null===b?(a.qm||(a.Bv=new V,a.Bv.Ca=F.gq,a.Bv.fb=0,a.qm=new R,a.qm.add(a.Bv),a.qm.sf()),this.Fn&&(a.qm.position=a.qm.location=this.Fn,a.qm.sf(),b=a.qm,c=a.Bv)):c=this.ud;if(null!==c){var d=Uk(c);d!==b&&b.La()?c=d:(d=b.findVisibleNode(),null!==d&&d!==b?(b=d,c=d.gl("")):b=d)}this.mD=b;if(null===b||null===c||!b.location.N())return!1;var d=this.ca,f=null;null===d?(a.rm||(a.Cv=new V,a.Cv.Ca=F.gq,a.Cv.fb=0,a.rm=
new R,a.rm.add(a.Cv),a.rm.sf()),this.Jn&&(a.rm.position=a.rm.location=this.Jn,a.rm.sf(),d=a.rm,f=a.Cv)):f=this.fe;null!==f&&(a=Uk(f),a!==d&&d.La()?f=a:(a=d.findVisibleNode(),null!==a&&a!==d?(d=a,f=a.gl("")):d=a));this.oD=d;if(null===d||null===f||!d.location.N())return!1;var a=this.pa,g=kn(this,c),h=ln(this,f),k=c===f&&null!==c,l=this.ic,m=this.De===Pg;this.ui=k&&!l?m=!0:!1;var n=this.Lo===zg||k;if(l||g!==wb||h!==wb||k){m=this.dj;n&&(l&&m||k)&&this.Uo();var p=k?this.computeCurviness():0,q=this.getLinkPoint(b,
c,g,!0,l,d,f),r=0,s=0,u=0;if(l||g!==wb||k){var v=this.computeEndSegmentLength(b,c,g,!0),u=this.getLinkDirection(b,c,q,g,!0,l,d,f);k&&(u-=l?90:30,0>p&&(u-=180));0>u?u+=360:360<=u&&(u-=360);k&&(v+=Math.abs(p));0===u?r=v:90===u?s=v:180===u?r=-v:270===u?s=-v:(r=v*Math.cos(u*Math.PI/180),s=v*Math.sin(u*Math.PI/180));if(g.Ge()&&k){var D=c.pb(Hb,t.K()),A=t.mc(D.x+1E3*r,D.y+1E3*s);this.getLinkPointFromPoint(b,c,D,A,!0,q);t.B(D);t.B(A)}}var v=this.getLinkPoint(d,f,h,!1,l,b,c),G=0,K=0,N=0;if(l||h!==wb||k)D=
this.computeEndSegmentLength(d,f,h,!1),N=this.getLinkDirection(d,f,v,h,!1,l,b,c),k&&(N+=l?0:30,0>p&&(N+=180)),0>N?N+=360:360<=N&&(N-=360),k&&(D+=Math.abs(p)),0===N?G=D:90===N?K=D:180===N?G=-D:270===N?K=-D:(G=D*Math.cos(N*Math.PI/180),K=D*Math.sin(N*Math.PI/180)),h.Ge()&&k&&(D=f.pb(Hb,t.K()),A=t.mc(D.x+1E3*G,D.y+1E3*K),this.getLinkPointFromPoint(d,f,D,A,!1,v),t.B(D),t.B(A));f=q;if(l||g!==wb||k)f=new w(q.x+r,q.y+s);c=v;if(l||h!==wb||k)c=new w(v.x+G,v.y+K);!n&&!l&&g===wb&&3<a&&this.adjustPoints(0,q,
a-2,c)?this.Ef(a-1,v):!n&&!l&&h===wb&&3<a&&this.adjustPoints(1,f,a-1,v)?this.Ef(0,q):!n&&!l&&4<a&&this.adjustPoints(1,f,a-2,c)?(this.Ef(0,q),this.Ef(a-1,v)):!n&&l&&6<=a&&!m&&this.adjustPoints(1,f,a-2,c)?(this.Ef(0,q),this.Ef(a-1,v)):(this.Uo(),this.Vh(q),(l||g!==wb||k)&&this.Vh(f),l&&this.addOrthoPoints(f,u,c,N,b,d),(l||h!==wb||k)&&this.Vh(c),this.Vh(v))}else g=!1,!n&&3<=a&&(n=this.getLinkPoint(b,c,wb,!0,!1,d,f),h=this.getLinkPoint(d,f,wb,!1,!1,b,c),g=this.adjustPoints(0,n,a-1,h))&&(n=this.getLinkPoint(b,
c,wb,!0,!1,d,f),h=this.getLinkPoint(d,f,wb,!1,!1,b,c),this.adjustPoints(0,n,a-1,h)),g||(this.Uo(),m?(a=this.getLinkPoint(b,c,wb,!0,!1,d,f),n=this.getLinkPoint(d,f,wb,!1,!1,b,c),g=n.x-a.x,h=n.y-a.y,k=this.computeCurviness(),m=l=0,q=a.x+g/3,u=a.y+h/3,r=q,s=u,F.I(h,0)?s=0<g?s-k:s+k:(l=-g/h,m=Math.sqrt(k*k/(l*l+1)),0>k&&(m=-m),r=(0>h?-1:1)*m+q,s=l*(r-q)+u),q=a.x+2*g/3,u=a.y+2*h/3,v=q,G=u,F.I(h,0)?G=0<g?G-k:G+k:(v=(0>h?-1:1)*m+q,G=l*(v-q)+u),this.Uo(),this.Vh(a),this.Xk(r,s),this.Xk(v,G),this.Vh(n),this.Ef(0,
this.getLinkPoint(b,c,wb,!0,!1,d,f)),this.Ef(3,this.getLinkPoint(d,f,wb,!1,!1,b,c))):(a=d,d=this.getLinkPoint(b,c,wb,!0,!1,a,f),f=this.getLinkPoint(a,f,wb,!1,!1,b,c),this.hasCurviness()?(h=f.x-d.x,b=f.y-d.y,c=this.computeCurviness(),a=d.x+h/2,n=d.y+b/2,g=a,k=n,F.I(b,0)?k=0<h?k-c:k+c:(h=-h/b,g=Math.sqrt(c*c/(h*h+1)),0>c&&(g=-g),g=(0>b?-1:1)*g+a,k=h*(g-a)+n),this.Vh(d),this.Xk(g,k)):this.Vh(d),this.Vh(f)));return!0};
function mn(a,b){Math.abs(b.x-a.x)>Math.abs(b.y-a.y)?(b.x=b.x>=a.x?a.x+9E9:a.x-9E9,b.y=a.y):(b.y=b.y>=a.y?a.y+9E9:a.y-9E9,b.x=a.x);return b}
T.prototype.getLinkPointFromPoint=function(a,b,c,d,f,g){void 0===g&&(g=new w);if(null===a||null===b)return g.assign(c),g;a.La()||(f=a.findVisibleNode(),null!==f&&f!==a&&(b=f.port));var h;a=null;f=b.ja;null===f||f.Hf()||(f=f.ja);if(null===f)f=d.x,d=d.y,h=c.x,c=c.y;else{a=f.me;f=1/(a.m11*a.m22-a.m12*a.m21);h=a.m22*f;var k=-a.m12*f,l=-a.m21*f,m=a.m11*f,n=f*(a.m21*a.dy-a.m22*a.dx),p=f*(a.m12*a.dx-a.m11*a.dy);f=d.x*h+d.y*l+n;d=d.x*k+d.y*m+p;h=c.x*h+c.y*l+n;c=c.x*k+c.y*m+p}b.kp(f,d,h,c,g);a&&g.transform(a);
return g};function nn(a,b){var c=b.Pr;null===c&&(c=new on,c.port=b,c.Fc=b.S,b.Pr=c);return pn(c,a)}
T.prototype.getLinkPoint=function(a,b,c,d,f,g,h,k){void 0===k&&(k=new w);if(c.xd())return b.pb(c,k),k;if(c.rp()&&(c=nn(this,b),null!==c)){k.assign(c.xp);if(f&&this.Lt===Wm){var l=nn(this,h);if(c.Lm<l.Lm){c=t.K();var l=t.K(),m=new x(b.pb(Eb,c),b.pb(Pb,l)),n=this.computeSpot(!d);a=this.getLinkPoint(g,h,n,!d,f,a,b,l);a.x>=m.x&&a.x<=m.x+m.width?k.x=a.x:a.y>=m.y&&a.y<=m.y+m.height&&(k.y=a.y);t.B(c);t.B(l)}}return k}g=b.pb(Hb,t.K());c=null;this.pa>(f?6:2)?(h=d?this.o(1):this.o(this.pa-2),f&&(h=mn(g,h.copy()))):
(c=t.K(),h=h.pb(Hb,c),f&&(h=mn(g,h)));this.getLinkPointFromPoint(a,b,g,h,d,k);t.B(g);null!==c&&t.B(c);return k};
T.prototype.getLinkDirection=function(a,b,c,d,f,g,h,k){a:if(d.xd())c=d.x>d.y?d.x>1-d.y?0:d.x<1-d.y?270:315:d.x<d.y?d.x>1-d.y?90:d.x<1-d.y?180:135:0.5>d.x?225:0.5<d.x?45:0;else{if(d.rp()&&(a=nn(this,b),null!==a))switch(a.be){case t.bd:c=270;break a;case t.Jc:c=180;break a;default:case t.Tc:c=0;break a;case t.Sc:c=90;break a}a=b.pb(Hb,t.K());d=null;this.pa>(g?6:2)?(k=f?this.o(1):this.o(this.pa-2),k=g?mn(a,k.copy()):c):(d=t.K(),k=k.pb(Hb,d));c=Math.abs(k.x-a.x)>Math.abs(k.y-a.y)?k.x>=a.x?0:180:k.y>=
a.y?90:270;t.B(a);null!==d&&t.B(d)}g=Km;g=f?this.hp:this.Xp;g===Km&&(g=f?b.hp:b.Xp);switch(g){case Lm:b=b.hl();c+=b;360<=c&&(c-=360);break;case Km:case kk:b=b.hl();if(0===b)break;45<=b&&135>b?c+=90:135<=b&&225>b?c+=180:225<=b&&315>b&&(c+=270);360<=c&&(c-=360)}return c};T.prototype.computeEndSegmentLength=function(a,b,c,d){if(null!==b&&c.rp()&&(a=nn(this,b),null!==a))return a.Aw;a=NaN;a=d?this.dk:this.jk;null!==b&&isNaN(a)&&(a=d?b.dk:b.jk);isNaN(a)&&(a=10);return a};
T.prototype.computeSpot=function(a){return a?kn(this,this.ud):ln(this,this.fe)};function kn(a,b){if(null===b)return Hb;var c=a.ob;c.Pc()&&(void 0===b&&(b=a.ud),null!==b&&(c=b.ob));return c===xb?wb:c}function ln(a,b){if(null===b)return Hb;var c=a.rb;c.Pc()&&(void 0===b&&(b=a.fe),null!==b&&(c=b.rb));return c===xb?wb:c}T.prototype.computeOtherPoint=function(a,b){var c=b.pb(Hb),d;d=b.Pr;d=null!==d?pn(d,this):null;null!==d&&(c=d.xp);return c};
T.prototype.computeShortLength=function(a){return a?qn(this):rn(this)};function qn(a){var b=a.ip;isNaN(b)&&(a=a.ud,null!==a&&(b=a.ip));return isNaN(b)?0:b}function rn(a){var b=a.Yp;isNaN(b)&&(a=a.fe,null!==a&&(b=a.Yp));return isNaN(b)?0:b}
T.prototype.bk=function(a,b,c,d,f,g){if(!1===this.Bf)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var h=g;void 0===g&&(h=t.oh(),h.reset());h.multiply(this.transform);if(this.Km(a,h))return am(this,b,c,f),void 0===g&&t.We(h),!0;if(this.Tf(a,h)){var k=!1;if(!this.Vg)for(var l=this.xa.length;l--;){var m=this.xa.n[l];if(m.visible||m===this.kc){var n=m.oa,p=this.Pa;if(!(n.x>p.width||n.y>p.height||0>n.x+n.width||0>n.y+n.height)){n=t.oh();n.set(h);if(m instanceof y)k=m.bk(a,b,c,d,f,n);else if(this.path===
m){var k=m,q=a,r=d,p=n;if(!1===k.Bf)k=!1;else if(p.multiply(k.transform),r)b:{var s=q,u=p;if(k.Km(s,u))k=!0;else{if(void 0===u&&(u=k.transform,s.Vj(k.oa))){k=!0;break b}var p=s.left,q=s.right,r=s.top,s=s.bottom,v=t.K(),D=t.K(),A=t.K(),G=t.oh();G.set(u);G.DF(k.transform);G.nA();D.x=q;D.y=r;D.transform(G);v.x=p;v.y=r;v.transform(G);u=!1;hm(k,v,D,A)?u=!0:(v.x=q,v.y=s,v.transform(G),hm(k,v,D,A)?u=!0:(D.x=p,D.y=s,D.transform(G),hm(k,v,D,A)?u=!0:(v.x=p,v.y=r,v.transform(G),hm(k,v,D,A)&&(u=!0))));t.We(G);
t.B(v);t.B(D);t.B(A);k=u}}else k=k.Km(q,p)}else k=pk(m,a,d,n);k&&(null!==b&&(m=b(m)),m&&(null===c||c(m))&&f.add(m));t.We(n)}}}void 0===g&&t.We(h);return k||null!==this.background||null!==this.$k}void 0===g&&t.We(h);return!1};t.A(T,{ic:"isOrthogonal"},function(){return 2===(this.pm.value&2)});t.A(T,{dj:"isAvoiding"},function(){return 4===(this.pm.value&4)});T.prototype.computeCurve=function(){if(null===this.ui){var a=this.ud,b=this.ic;this.ui=null!==a&&a===this.fe&&!b}return this.ui?Pg:this.De};
T.prototype.computeCorner=function(){if(this.De===Pg)return 0;var a=this.sw;if(isNaN(a)||0>a)a=10;return a};T.prototype.computeCurviness=function(){var a=this.Xs;if(isNaN(a)){var b=this.hf;if(0!==b){var a=10,c=this.h;null!==c&&(a=c.yp);c=Math.abs(b);a=a/2+((c-1)/2|0)*a;0===c%2&&(a=-a);0>b&&(a=-a)}else a=10}return a};T.prototype.computeThickness=function(){var a=this.path;return null!==a?Math.max(a.fb,1):1};T.prototype.hasCurviness=function(){return!isNaN(this.Xs)||0!==this.hf&&!this.ic};
T.prototype.adjustPoints=function(a,b,c,d){var f=this.Lo;if(this.ic){if(f===cl)return!1;f===dl&&(f=bl)}switch(f){case cl:var g=this.o(a),h=this.o(c);if(!g.M(b)||!h.M(d)){var f=g.x,g=g.y,k=h.x-f,l=h.y-g,m=Math.sqrt(k*k+l*l);if(!F.I(m,0)){var n;F.I(k,0)?n=0>l?-Math.PI/2:Math.PI/2:(n=Math.atan(l/Math.abs(k)),0>k&&(n=Math.PI-n));var h=b.x,p=b.y,k=d.x-h,q=d.y-p,l=Math.sqrt(k*k+q*q);F.I(k,0)?q=0>q?-Math.PI/2:Math.PI/2:(q=Math.atan(q/Math.abs(k)),0>k&&(q=Math.PI-q));m=l/m;n=q-n;this.Ef(a,b);for(a+=1;a<c;a++)b=
this.o(a),k=b.x-f,l=b.y-g,b=Math.sqrt(k*k+l*l),F.I(b,0)||(F.I(k,0)?l=0>l?-Math.PI/2:Math.PI/2:(l=Math.atan(l/Math.abs(k)),0>k&&(l=Math.PI-l)),k=l+n,b*=m,this.Y(a,h+b*Math.cos(k),p+b*Math.sin(k)));this.Ef(c,d)}}return!0;case dl:g=this.o(a);p=this.o(c);if(!g.M(b)||!p.M(d)){f=g.x;g=g.y;h=p.x;p=p.y;m=(h-f)*(h-f)+(p-g)*(p-g);k=b.x;n=b.y;var l=d.x,q=d.y,r=0,s=1,r=0!==l-k?(q-n)/(l-k):9E9;0!==r&&(s=Math.sqrt(1+1/(r*r)));this.Ef(a,b);for(a+=1;a<c;a++){b=this.o(a);var u=b.x,v=b.y,D=0.5;0!==m&&(D=((f-u)*(f-
h)+(g-v)*(g-p))/m);var A=f+D*(h-f),G=g+D*(p-g);b=Math.sqrt((u-A)*(u-A)+(v-G)*(v-G));v<r*(u-A)+G&&(b=-b);0<r&&(b=-b);u=k+D*(l-k);D=n+D*(q-n);0!==r?(b=u+b/s,this.Y(a,b,D-(b-u)/r)):this.Y(a,u,D+b)}this.Ef(c,d)}return!0;case bl:return this.ic&&(f=this.o(a),g=this.o(a+1),h=this.o(a+2),F.I(f.y,g.y)?F.I(g.x,h.x)?this.Y(a+1,g.x,b.y):F.I(g.y,h.y)&&this.Y(a+1,b.x,g.y):F.I(f.x,g.x)&&(F.I(g.y,h.y)?this.Y(a+1,b.x,g.y):F.I(g.x,h.x)&&this.Y(a+1,g.x,b.y)),f=this.o(c),g=this.o(c-1),h=this.o(c-2),F.I(f.y,g.y)?F.I(g.x,
h.x)?this.Y(c-1,g.x,d.y):F.I(g.y,h.y)&&this.Y(c-1,d.x,g.y):F.I(f.x,g.x)&&(F.I(g.y,h.y)?this.Y(c-1,d.x,g.y):F.I(g.x,h.x)&&this.Y(c-1,g.x,d.y))),this.Ef(a,b),this.Ef(c,d),!0;default:return!1}};
T.prototype.addOrthoPoints=function(a,b,c,d,f,g){b=-45<=b&&45>b?0:45<=b&&135>b?90:135<=b&&225>b?180:270;d=-45<=d&&45>d?0:45<=d&&135>d?90:135<=d&&225>d?180:270;var h=f.oa.copy(),k=g.oa.copy();if(h.N()&&k.N()){h.Sf(8,8);k.Sf(8,8);h.kj(a);k.kj(c);var l,m;if(0===b)if(c.x>a.x||270===d&&c.y<a.y&&k.right>a.x||90===d&&c.y>a.y&&k.right>a.x)l=new w(c.x,a.y),m=new w(c.x,(a.y+c.y)/2),180===d?(l.x=this.computeMidOrthoPosition(a.x,c.x,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x<k.left?this.computeMidOrthoPosition(a.x,
k.left,!1):a.x<k.right&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,c.x,!1):k.right,m.x=l.x,m.y=c.y):0===d&&a.x<k.left&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l=new w(a.x,c.y);m=new w((a.x+c.x)/2,c.y);if(180===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)180===d&&(k.Fa(a)||h.Fa(c))?l.y=this.computeMidOrthoPosition(a.y,c.y,!0):c.y<a.y&&(180===d||90===d)?l.y=this.computeMidOrthoPosition(h.top,Math.max(c.y,
k.bottom),!0):c.y>a.y&&(180===d||270===d)&&(l.y=this.computeMidOrthoPosition(h.bottom,Math.min(c.y,k.top),!0)),m.x=c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x>=h.left&&c.x<=a.x||a.x<=k.right&&a.x>=c.x){if(90===d||270===d)l=new w(Math.max((a.x+c.x)/2,a.x),a.y),m=new w(l.x,c.y)}else l.y=270===d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,0===d?h.top:Math.min(h.top,k.top)):Math.max(c.y,0===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(180===b)if(c.x<a.x||270===d&&c.y<a.y&&k.left<a.x||
90===d&&c.y>a.y&&k.left<a.x)l=new w(c.x,a.y),m=new w(c.x,(a.y+c.y)/2),0===d?(l.x=this.computeMidOrthoPosition(a.x,c.x,!1),m.x=l.x,m.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(l.x=a.x>k.right?this.computeMidOrthoPosition(a.x,k.right,!1):a.x>k.left&&(270===d&&a.y<k.top||90===d&&a.y>k.bottom)?this.computeMidOrthoPosition(a.x,c.x,!1):k.left,m.x=l.x,m.y=c.y):180===d&&a.x>k.right&&a.y>k.top&&a.y<k.bottom&&(l.x=a.x,l.y=a.y<c.y?Math.min(c.y,k.top):Math.max(c.y,k.bottom),m.y=l.y);else{l=new w(a.x,c.y);m=new w((a.x+
c.x)/2,c.y);if(0===d||90===d&&c.y<h.top||270===d&&c.y>h.bottom)0===d&&(k.Fa(a)||h.Fa(c))?l.y=this.computeMidOrthoPosition(a.y,c.y,!0):c.y<a.y&&(0===d||90===d)?l.y=this.computeMidOrthoPosition(h.top,Math.max(c.y,k.bottom),!0):c.y>a.y&&(0===d||270===d)&&(l.y=this.computeMidOrthoPosition(h.bottom,Math.min(c.y,k.top),!0)),m.x=c.x,m.y=l.y;if(l.y>h.top&&l.y<h.bottom)if(c.x<=h.right&&c.x>=a.x||a.x>=k.left&&a.x<=c.x){if(90===d||270===d)l=new w(Math.min((a.x+c.x)/2,a.x),a.y),m=new w(l.x,c.y)}else l.y=270===
d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,180===d?h.top:Math.min(h.top,k.top)):Math.max(c.y,180===d?h.bottom:Math.max(h.bottom,k.bottom)),m.x=c.x,m.y=l.y}else if(90===b)if(c.y>a.y||180===d&&c.x<a.x&&k.bottom>a.y||0===d&&c.x>a.x&&k.bottom>a.y)l=new w(a.x,c.y),m=new w((a.x+c.x)/2,c.y),270===d?(l.y=this.computeMidOrthoPosition(a.y,c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>a.x?(l.y=a.y<k.top?this.computeMidOrthoPosition(a.y,k.top,!0):a.y<k.bottom&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?
this.computeMidOrthoPosition(a.y,c.y,!0):k.bottom,m.x=c.x,m.y=l.y):90===d&&a.y<k.top&&a.x>k.left&&a.x<k.right&&(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l=new w(c.x,a.y);m=new w(c.x,(a.y+c.y)/2);if(270===d||0===d&&c.x<h.left||180===d&&c.x>h.right)270===d&&(k.Fa(a)||h.Fa(c))?l.x=this.computeMidOrthoPosition(a.x,c.x,!1):c.x<a.x&&(270===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,Math.max(c.x,k.right),!1):c.x>a.x&&(270===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,
Math.min(c.x,k.left),!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y>=h.top&&c.y<=a.y||a.y<=k.bottom&&a.y>=c.y){if(0===d||180===d)l=new w(a.x,Math.max((a.y+c.y)/2,a.y)),m=new w(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,90===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,90===d?h.right:Math.max(h.right,k.right)),m.x=l.x,m.y=c.y}else if(c.y<a.y||180===d&&c.x<a.x&&k.top<a.y||0===d&&c.x>a.x&&k.top<a.y)l=new w(a.x,c.y),m=new w((a.x+c.x)/2,c.y),90===d?(l.y=this.computeMidOrthoPosition(a.y,
c.y,!0),m.x=c.x,m.y=l.y):180===d&&c.x<a.x||0===d&&c.x>=a.x?(l.y=a.y>k.bottom?this.computeMidOrthoPosition(a.y,k.bottom,!0):a.y>k.top&&(180===d&&a.x<k.left||0===d&&a.x>k.right)?this.computeMidOrthoPosition(a.y,c.y,!0):k.top,m.x=c.x,m.y=l.y):270===d&&a.y>k.bottom&&a.x>k.left&&a.x<k.right&&(l.x=a.x<c.x?Math.min(c.x,k.left):Math.max(c.x,k.right),l.y=a.y,m.x=l.x);else{l=new w(c.x,a.y);m=new w(c.x,(a.y+c.y)/2);if(90===d||0===d&&c.x<h.left||180===d&&c.x>h.right)90===d&&(k.Fa(a)||h.Fa(c))?l.x=this.computeMidOrthoPosition(a.x,
c.x,!1):c.x<a.x&&(90===d||0===d)?l.x=this.computeMidOrthoPosition(h.left,Math.max(c.x,k.right),!1):c.x>a.x&&(90===d||180===d)&&(l.x=this.computeMidOrthoPosition(h.right,Math.min(c.x,k.left),!1)),m.x=l.x,m.y=c.y;if(l.x>h.left&&l.x<h.right)if(c.y<=h.bottom&&c.y>=a.y||a.y>=k.top&&a.y<=c.y){if(0===d||180===d)l=new w(a.x,Math.min((a.y+c.y)/2,a.y)),m=new w(c.x,l.y)}else l.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,270===d?h.left:Math.min(h.left,k.left)):Math.max(c.x,270===d?h.right:Math.max(h.right,
k.right)),m.x=l.x,m.y=c.y}var n=l,p=m;if(this.dj){var q=this.h,r;(r=null===q)||(q.Ub.yg?r=!1:(r=q.Ua,r=r instanceof cf?!r.Ov||r.aF:!0),r=!r);if(r||h.Fa(c)||k.Fa(a)||f===g||this.layer.rc)a=!1;else if(f=Sj(q,this),sn(f,Math.min(a.x,n.x),Math.min(a.y,n.y),Math.abs(a.x-n.x),Math.abs(a.y-n.y))&&sn(f,Math.min(n.x,p.x),Math.min(n.y,p.y),Math.abs(n.x-p.x),Math.abs(n.y-p.y))&&sn(f,Math.min(p.x,c.x),Math.min(p.y,c.y),Math.abs(p.x-c.x),Math.abs(p.y-c.y)))a=!1;else if(h=h.copy().ii(k),h.Sf(2*f.Hm,2*f.Gm),tn(f,
a,b,c,d,h),k=un(f,c.x,c.y),f.abort||k!==vn||(Vj(f),k=f.sG,h.Sf(f.Hm*k,f.Gm*k),tn(f,a,b,c,d,h),k=un(f,c.x,c.y)),f.abort||k!==vn||(Vj(f),k=f.kF,h.Sf(f.Hm*k,f.Gm*k),tn(f,a,b,c,d,h),k=un(f,c.x,c.y)),!f.abort&&k===vn&&f.XG&&(Vj(f),tn(f,a,b,c,d,f.Pb),k=un(f,c.x,c.y)),!f.abort&&k<vn&&0!==un(f,c.x,c.y)){wn(this,f,c.x,c.y,d,!0);d=this.o(2);if(4>this.pa)0===b||180===b?(d.x=a.x,d.y=c.y):(d.x=c.x,d.y=a.y),this.Y(2,d.x,d.y),this.C(3,d.x,d.y);else if(c=this.o(3),0===b||180===b)F.I(d.x,c.x)?(b=0===b?Math.max(d.x,
a.x):Math.min(d.x,a.x),this.Y(2,b,a.y),this.Y(3,b,c.y)):F.I(d.y,c.y)?(Math.abs(a.y-d.y)<=f.Gm/2&&(this.Y(2,d.x,a.y),this.Y(3,c.x,a.y)),this.C(2,d.x,a.y)):this.Y(2,a.x,d.y);else if(90===b||270===b)F.I(d.y,c.y)?(b=90===b?Math.max(d.y,a.y):Math.min(d.y,a.y),this.Y(2,a.x,b),this.Y(3,c.x,b)):F.I(d.x,c.x)?(Math.abs(a.x-d.x)<=f.Hm/2&&(this.Y(2,a.x,d.y),this.Y(3,a.x,c.y)),this.C(2,a.x,d.y)):this.Y(2,d.x,a.y);a=!0}else a=!1}else a=!1;a||(this.Vh(l),this.Vh(m))}};
T.prototype.computeMidOrthoPosition=function(a,b){if(this.hasCurviness()){var c=this.computeCurviness();return(a+b)/2+c}return(a+b)/2};function Bf(a){if(!a.dj)return!1;var b=a.points.n,c=b.length;if(4>c)return!1;a=Sj(a.h,a);for(var d=1;d<c-2;d++){var f=b[d],g=b[d+1];if(!sn(a,Math.min(f.x,g.x),Math.min(f.y,g.y),Math.abs(f.x-g.x),Math.abs(f.y-g.y)))return!0}return!1}
function wn(a,b,c,d,f,g){var h=b.Hm,k=b.Gm,l=un(b,c,d),m=c,n=d;for(0===f?m+=h:90===f?n+=k:180===f?m-=h:n-=k;l>xn&&un(b,m,n)===l-yn;)c=m,d=n,0===f?m+=h:90===f?n+=k:180===f?m-=h:n-=k,l-=yn;if(g){if(l>xn)if(180===f||0===f)c=Math.floor(c/h)*h+h/2;else if(90===f||270===f)d=Math.floor(d/k)*k+k/2}else c=Math.floor(c/h)*h+h/2,d=Math.floor(d/k)*k+k/2;l>xn&&(g=f,m=c,n=d,0===f?(g=90,n+=k):90===f?(g=180,m-=h):180===f?(g=270,n-=k):270===f&&(g=0,m+=h),un(b,m,n)===l-yn?wn(a,b,m,n,g,!1):(m=c,n=d,0===f?(g=270,n-=
k):90===f?(g=0,m+=h):180===f?(g=90,n+=k):270===f&&(g=180,m-=h),un(b,m,n)===l-yn&&wn(a,b,m,n,g,!1)));a.Xk(c,d)}T.prototype.findClosestSegment=function(a){e&&t.l(a,w,T,"findClosestSegment:p");var b=a.x;a=a.y;for(var c=this.o(0),d=this.o(1),f=Ra(b,a,c.x,c.y,d.x,d.y),g=0,h=1;h<this.pa-1;h++){var c=this.o(h+1),k=Ra(b,a,d.x,d.y,c.x,c.y),d=c;k<f&&(g=h,f=k)}return g};T.prototype.invalidateGeometry=T.prototype.Ye=function(){this.Ji=this.Ta=null;this.ha()};
t.A(T,{nd:"geometry"},function(){null===this.Ta&&(this.updateRoute(),this.Ta=this.makeGeometry());return this.Ta});T.prototype.bx=function(){if(null===this.Ta&&!1!==this.rg){this.Ta=this.makeGeometry();var a=this.Rq();if(null!==a){a.Ta=this.Ta;for(var b=this.xa,c=b.length,d=0;d<c;d++){var f=b.n[d];f!==a&&f.fj&&f instanceof V&&(f.Ta=this.Ta)}}}};
T.prototype.makeGeometry=function(){var a=this.pa;if(2>a){var b=new zc(Ac),c=new Bc(0,0);b.Bb.add(c);return b}var d=!1,b=this.h;null!==b&&0!==b.la.Je&&gn(this)&&(d=!0);var f=this.o(0).copy(),g=f.copy(),h=this.computeCurve();if(h===Pg&&3<=a&&!F.mb(this.mn,0))if(3===a)var k=this.o(1),b=Math.min(f.x,k.x),c=Math.min(f.y,k.y),k=this.o(2),b=Math.min(b,k.x),c=Math.min(c,k.y);else{if(this.ic)for(k=0;k<a;k++)b=this.Cc.n[k],g.x=Math.min(b.x,g.x),g.y=Math.min(b.y,g.y);else for(var l,k=3;k<a;k+=3)k+3>=a&&(k=
a-1),l=this.o(k),g.x=Math.min(l.x,g.x),g.y=Math.min(l.y,g.y);b=g.x;c=g.y}else{for(k=0;k<a;k++)b=this.Cc.n[k],g.x=Math.min(b.x,g.x),g.y=Math.min(b.y,g.y);b=g.x;c=g.y}b-=this.zz.x;c-=this.zz.y;f.x-=b;f.y-=c;if(2===a){var m=this.o(1).copy();m.x-=b;m.y-=c;0!==qn(this)&&zn(this,f,!0,g);0!==rn(this)&&zn(this,m,!1,g);b=new zc(Dc);b.qa=f.x;b.ra=f.y;b.D=m.x;b.F=m.y}else{m=t.u();0!==qn(this)&&zn(this,f,!0,g);I(m,f.x,f.y,!1,!1);if(h===Pg&&3<=a&&!F.mb(this.mn,0))if(3===a)k=this.o(1),a=k.x-b,d=k.y-c,k=this.o(2).copy(),
k.x-=b,k.y-=c,0!==rn(this)&&zn(this,k,!1,g),J(m,a,d,a,d,k.x,k.y);else if(this.ic){g=new w(b,c);f=this.o(1).copy();h=new w(b,c);a=new w(b,c);d=this.o(0);l=null;for(var n=this.mn/3,k=1;k<this.pa-1;k++){l=this.o(k);var p=d,q=l,r=this.o(An(this,l,k,!1));if(!F.mb(p.x,q.x)||!F.mb(q.x,r.x))if(!F.mb(p.y,q.y)||!F.mb(q.y,r.y)){var s=n,u=h,v=a;isNaN(s)&&(s=this.mn/3);var D=p.x,p=p.y,A=q.x,q=q.y,G=r.x,r=r.y,K=s*Bn(D,p,A,q),s=s*Bn(A,q,G,r);F.mb(p,q)&&F.mb(A,G)&&(A>D?r>q?(u.x=A-K,u.y=q-K,v.x=A+s,v.y=q+s):(u.x=
A-K,u.y=q+K,v.x=A+s,v.y=q-s):r>q?(u.x=A+K,u.y=q-K,v.x=A-s,v.y=q+s):(u.x=A+K,u.y=q+K,v.x=A-s,v.y=q-s));F.mb(D,A)&&F.mb(q,r)&&(q>p?(G>A?(u.x=A-K,u.y=q-K,v.x=A+s):(u.x=A+K,u.y=q-K,v.x=A-s),v.y=q+s):(G>A?(u.x=A-K,u.y=q+K,v.x=A+s):(u.x=A+K,u.y=q+K,v.x=A-s),v.y=q-s));if(F.mb(D,A)&&F.mb(A,G)||F.mb(p,q)&&F.mb(q,r))D=0.5*(D+G),p=0.5*(p+r),u.x=D,u.y=p,v.x=D,v.y=p;1===k?(f.x=0.5*(d.x+l.x),f.y=0.5*(d.y+l.y)):2===k&&F.mb(d.x,this.o(0).x)&&F.mb(d.y,this.o(0).y)&&(f.x=0.5*(d.x+l.x),f.y=0.5*(d.y+l.y));J(m,f.x-b,
f.y-c,h.x-b,h.y-c,l.x-b,l.y-c);g.set(h);f.set(a);d=l}}k=d.x;d=d.y;g=this.o(this.pa-1);k=0.5*(k+g.x);d=0.5*(d+g.y);J(m,a.x-b,a.y-c,k-b,d-c,g.x-b,g.y-c)}else for(k=3;k<a;k+=3)d=this.o(k-2),k+3>=a&&(k=a-1),g=this.o(k-1),l=this.o(k),k===a-1&&0!==rn(this)&&(l=l.copy(),zn(this,l,!1,F.mj)),J(m,d.x-b,d.y-c,g.x-b,g.y-c,l.x-b,l.y-c);else{g=t.K();g.assign(this.o(0));for(k=1;k<a;){k=An(this,g,k,1<k);u=this.o(k);if(k>=a-1){g!==u&&(0!==rn(this)&&(u=u.copy(),zn(this,u,!1,F.mj)),Cn(this,m,-b,-c,g,u,d));break}k=An(this,
u,k+1,k<a-3);f=m;h=-b;l=-c;n=g;v=this.o(k);D=g;p=d;F.I(n.y,u.y)&&F.I(u.x,v.x)?(s=this.computeCorner(),s=Math.min(s,Math.abs(u.x-n.x)/2),s=A=Math.min(s,Math.abs(v.y-u.y)/2),F.I(s,0)?(Cn(this,f,h,l,n,u,p),D.assign(u)):(q=u.x,G=u.y,r=q,K=G,q=u.x>n.x?u.x-s:u.x+s,K=v.y>u.y?u.y+A:u.y-A,Cn(this,f,h,l,n,new w(q,G),p),Sc(f,u.x+h,u.y+l,r+h,K+l),D.p(r,K))):F.I(n.x,u.x)&&F.I(u.y,v.y)?(s=this.computeCorner(),A=Math.min(s,Math.abs(u.y-n.y)/2),A=s=Math.min(A,Math.abs(v.x-u.x)/2),F.I(s,0)?(Cn(this,f,h,l,n,u,p),D.assign(u)):
(q=u.x,K=G=u.y,G=u.y>n.y?u.y-A:u.y+A,r=v.x>u.x?u.x+s:u.x-s,Cn(this,f,h,l,n,new w(q,G),p),Sc(f,u.x+h,u.y+l,r+h,K+l),D.p(r,K))):(Cn(this,f,h,l,n,u,p),D.assign(u))}t.B(g)}b=m.s;t.v(m)}return b};function Bn(a,b,c,d){a=c-a;if(isNaN(a)||Infinity===a||-Infinity===a)return NaN;0>a&&(a=-a);b=d-b;if(isNaN(b)||Infinity===b||-Infinity===b)return NaN;0>b&&(b=-b);return F.mb(a,0)?b:F.mb(b,0)?a:Math.sqrt(a*a+b*b)}
function zn(a,b,c,d){var f=a.pa;if(!(2>f))if(c){var g=a.o(1);c=g.x-d.x;d=g.y-d.y;g=Bn(b.x,b.y,c,d);0!==g&&(f=2===f?0.5*g:g,a=qn(a),a>f&&(a=f),c=a*(c-b.x)/g,a=a*(d-b.y)/g,b.x+=c,b.y+=a)}else g=a.o(f-2),c=g.x-d.x,d=g.y-d.y,g=Bn(b.x,b.y,c,d),0!==g&&(f=2===f?0.5*g:g,a=rn(a),a>f&&(a=f),c=a*(b.x-c)/g,a=a*(b.y-d)/g,b.x-=c,b.y-=a)}
function An(a,b,c,d){for(var f=a.pa,g=b;F.mb(b.x,g.x)&&F.mb(b.y,g.y);){if(c>=f)return f-1;g=a.o(c++)}if(!F.mb(b.x,g.x)&&!F.mb(b.y,g.y))return c-1;for(var h=g;F.mb(b.x,g.x)&&F.mb(g.x,h.x)&&(!d||(b.y>=g.y?g.y>=h.y:g.y<=h.y))||F.mb(b.y,g.y)&&F.mb(g.y,h.y)&&(!d||(b.x>=g.x?g.x>=h.x:g.x<=h.x));){if(c>=f)return f-1;h=a.o(c++)}return c-2}
function Cn(a,b,c,d,f,g,h){if(!h&&gn(a)){h=[];var k=0;a.La()&&(k=Dn(a,f,g,h));var l=f.x,l=f.y;if(0<k)if(F.I(f.y,g.y))if(f.x<g.x)for(var m=0;m<k;){var n=Math.max(f.x,Math.min(h[m++]-5,g.x-10));b.lineTo(n+c,g.y+d);for(var l=n+c,p=Math.min(n+10,g.x);m<k;){var q=h[m];if(q<p+10)m++,p=Math.min(q+5,g.x);else break}q=(n+p)/2+c;q=g.y-10+d;n=p+c;p=g.y+d;a.De===yg?I(b,n,p,!1,!1):J(b,l,q,n,q,n,p)}else for(m=k-1;0<=m;){n=Math.min(f.x,Math.max(h[m--]+5,g.x+10));b.lineTo(n+c,g.y+d);l=n+c;for(p=Math.max(n-10,g.x);0<=
m;)if(q=h[m],q>p-10)m--,p=Math.max(q-5,g.x);else break;q=g.y-10+d;n=p+c;p=g.y+d;a.De===yg?I(b,n,p,!1,!1):J(b,l,q,n,q,n,p)}else if(F.I(f.x,g.x))if(f.y<g.y)for(m=0;m<k;){n=Math.max(f.y,Math.min(h[m++]-5,g.y-10));b.lineTo(g.x+c,n+d);l=n+d;for(p=Math.min(n+10,g.y);m<k;)if(q=h[m],q<p+10)m++,p=Math.min(q+5,g.y);else break;q=g.x-10+c;n=g.x+c;p+=d;a.De===yg?I(b,n,p,!1,!1):J(b,q,l,q,p,n,p)}else for(m=k-1;0<=m;){n=Math.min(f.y,Math.max(h[m--]+5,g.y+10));b.lineTo(g.x+c,n+d);l=n+d;for(p=Math.max(n-10,g.y);0<=
m;)if(q=h[m],q>p-10)m--,p=Math.max(q-5,g.y);else break;q=g.x-10+c;n=g.x+c;p+=d;a.De===yg?I(b,n,p,!1,!1):J(b,q,l,q,p,n,p)}}b.lineTo(g.x+c,g.y+d)}
function Dn(a,b,c,d){var f=a.h;if(null===f||b.M(c))return 0;for(f=f.Yw;f.next();){var g=f.value;if(null!==g&&g.visible)for(var g=g.zb.n,h=g.length,k=0;k<h;k++){var l=g[k];if(l===a)return 0<d.length&&d.sort(function(a,b){return a-b}),d.length;if(l instanceof T&&l.La()&&gn(l)){var m=l.uj();m.N()&&a.uj().Tf(m)&&!a.usesSamePort(l)&&(m=l.path,null!==m&&m.pl()&&En(b,c,d,l))}}}0<d.length&&d.sort(function(a,b){return a-b});return d.length}
function En(a,b,c,d){for(var f=F.I(a.y,b.y),g=d.pa,h=d.o(0),k=t.K(),l=1;l<g;l++){var m=d.o(l);if(l<g-1){var n=d.o(l+1);if(h.y===m.y&&m.y===n.y){if(m.x>h.x&&n.x>m.x||m.x<h.x&&n.x<m.x)m=n,l++}else h.x===m.x&&m.x===n.x&&(m.y>h.y&&n.y>m.y||m.y<h.y&&n.y<m.y)&&(m=n,l++)}a:{var n=k,p=a.x,q=a.y,r=b.x,s=b.y,u=h.x,h=h.y,v=m.x,D=m.y;if(!F.I(p,r)){if(F.I(q,s)&&F.I(u,v)&&Math.min(p,r)<u&&Math.max(p,r)>u&&Math.min(h,D)<q&&Math.max(h,D)>q&&!F.I(h,D)){n.x=u;n.y=q;n=!0;break a}}else if(!F.I(q,s)&&F.I(h,D)&&Math.min(q,
s)<h&&Math.max(q,s)>h&&Math.min(u,v)<p&&Math.max(u,v)>p&&!F.I(u,v)){n.x=p;n.y=h;n=!0;break a}n.x=0;n.y=0;n=!1}n&&(f?c.push(k.x):c.push(k.y));h=m}t.B(k)}t.A(T,{gt:"firstPickIndex"},function(){return 2>=this.pa?0:this.ic||kn(this)!==wb?1:0});t.A(T,{Ww:"lastPickIndex"},function(){var a=this.pa;return 0===a?0:2>=a?a-1:this.ic||ln(this)!==wb?a-2:a-1});function gn(a){a=a.De;return a===xg||a===yg}function jn(a,b){if(b||gn(a)){var c=a.h;null===c||c.Rl.contains(a)||null===a.oz||c.Rl.add(a,a.oz)}}
function Ag(a,b){var c=a.layer;if(null!==c&&c.visible&&!c.rc){var d=c.h;if(null!==d)for(var f=!1,d=d.Yw;d.next();){var g=d.value;if(g.visible)if(g===c)for(var f=!0,h=!1,g=g.zb.n,k=g.length,l=0;l<k;l++){var m=g[l];m instanceof T&&(m===a?h=!0:h&&Fn(a,m,b))}else if(f)for(g=g.zb.n,k=g.length,l=0;l<k;l++)m=g[l],m instanceof T&&Fn(a,m,b)}}}function Fn(a,b,c){if(null!==b&&gn(b)&&null!==b.Ta){var d=b.uj();d.N()&&(a.uj().Tf(d)||c.Tf(d))&&(a.usesSamePort(b)||b.Ye())}}
T.prototype.usesSamePort=function(a){var b=this.pa,c=a.pa;if(0<b&&0<c){if(this.o(0).tf(a.o(0))||this.o(b-1).tf(a.o(c-1)))return!0}else if(this.aa===a.aa||this.ca===a.ca)return!0;return!1};T.prototype.df=function(a){C.prototype.df.call(this,a);if(null!==this.we)for(var b=this.we.k;b.next();)b.value.df(a)};t.g(T,"adjusting",T.prototype.Lo);t.defineProperty(T,{Lo:"adjusting"},function(){return this.lq},function(a){var b=this.lq;b!==a&&(e&&t.Aa(a,T,T,"adjusting"),this.lq=a,this.j("adjusting",b,a))});
t.g(T,"corner",T.prototype.sw);t.defineProperty(T,{sw:"corner"},function(){return this.yq},function(a){var b=this.yq;b!==a&&(e&&t.i(a,"number",T,"corner"),this.yq=a,this.Ye(),this.j("corner",b,a))});t.g(T,"curve",T.prototype.De);t.defineProperty(T,{De:"curve"},function(){return this.Bq},function(a){var b=this.Bq;b!==a&&(e&&t.Aa(a,T,T,"curve"),this.Bq=a,this.ac(),jn(this,b===yg||b===xg||a===yg||a===xg),this.j("curve",b,a))});t.g(T,"curviness",T.prototype.Xs);
t.defineProperty(T,{Xs:"curviness"},function(){return this.Cq},function(a){var b=this.Cq;b!==a&&(e&&t.i(a,"number",T,"curviness"),this.Cq=a,this.ac(),this.j("curviness",b,a))});t.g(T,"routing",T.prototype.Lt);t.defineProperty(T,{Lt:"routing"},function(){return this.pm},function(a){var b=this.pm;b!==a&&(e&&t.Aa(a,T,T,"routing"),this.pm=a,this.ui=null,this.ac(),jn(this,2===(b.value&2)||2===(a.value&2)),this.j("routing",b,a))});t.g(T,"smoothness",T.prototype.mn);
t.defineProperty(T,{mn:"smoothness"},function(){return this.ps},function(a){var b=this.ps;b!==a&&(e&&t.i(a,"number",T,"smoothness"),this.ps=a,this.Ye(),this.j("smoothness",b,a))});
function en(a){var b=a.eg;if(null!==b){var c=a.qg;if(null!==c){var d=a.Bh,f=a.Rh;a:{if(null!==c&&null!==b.qh)for(a=b.qh.k;a.next();){var g=a.value;if(g.Hp===b&&g.At===c&&g.ix===d&&g.jx===f||g.Hp===c&&g.At===b&&g.ix===f&&g.jx===d){a=g;break a}}a=null}for(var h=g=null,k=b.nc.n,l=k.length,m=0;m<l;m++){var n=k[m];if(n.eg===b&&n.Bh===d&&n.qg===c&&n.Rh===f||n.eg===c&&n.Bh===f&&n.qg===b&&n.Rh===d)null===h?h=n:(null===g&&(g=new B(T),g.add(h)),g.add(n))}if(null!==g)for(null===a&&(a=new Om,a.Hp=b,a.ix=d,a.At=
c,a.jx=f,Nm(b,a),Nm(c,a)),a.links=g,m=0;m<g.count;m++)if(n=g.n[m],0===n.hf){b=1;for(c=0;c<g.count;c++)Math.abs(g.n[c].hf)===b&&(b++,c=-1);n.xn=a;n.hf=n.aa===a.Hp?b:-b;b=n.h;(null===b||b.lf)&&n.ac()}}}}
function dn(a){var b=a.xn;if(null!==b){var c=a.hf;a.xn=null;a.hf=0;b.links.remove(a);if(2>b.links.count)1===b.links.count&&(c=b.links.n[0],c.xn=null,c.hf=0,c.ac()),c=b.Hp,null!==b&&null!==c.qh&&c.qh.remove(b),c=b.At,null!==b&&null!==c.qh&&c.qh.remove(b);else for(c=Math.abs(c),a=0===c%2,b=b.links.k;b.next();){var d=b.value,f=Math.abs(d.hf),g=0===f%2;f>c&&a===g&&(d.hf=0<d.hf?d.hf-2:d.hf+2,d.ac())}}}function Om(){t.Ac(this);this.links=this.jx=this.At=this.ix=this.Hp=null}
t.Vd(Om,{Hp:!0,ix:!0,At:!0,jx:!0,links:!0,spacing:!0});function Tj(){t.Ac(this);this.group=null;this.nt=!0;this.abort=!1;this.kg=this.jg=1;this.vr=this.ur=-1;this.je=this.ie=8;this.wc=null;this.XG=!1;this.sG=22;this.kF=111}t.Vd(Tj,{group:!0,nt:!0,abort:!0,XG:!0,sG:!0,kF:!0});var xn=1,yn=1,vn=999999999,Gn=vn;
Tj.prototype.initialize=function(a){if(!(0>=a.width||0>=a.height)){var b=a.y,c=a.x+a.width,d=a.y+a.height;this.jg=Math.floor((a.x-this.ie)/this.ie)*this.ie;this.kg=Math.floor((b-this.je)/this.je)*this.je;this.ur=Math.ceil((c+2*this.ie)/this.ie)*this.ie;this.vr=Math.ceil((d+2*this.je)/this.je)*this.je;a=1+(Math.ceil((this.ur-this.jg)/this.ie)|0);b=1+(Math.ceil((this.vr-this.kg)/this.je)|0);if(null===this.wc||this.Go<a-1||this.Ho<b-1){c=[];for(d=0;d<=a;d++){for(var f=[],g=0;g<=b;g++)f[g]=0;c[d]=f}this.wc=
c;this.Go=a-1;this.Ho=b-1}if(null!==this.wc)for(a=0;a<=this.Go;a++)for(b=0;b<=this.Ho;b++)this.wc[a][b]=Gn}};t.A(Tj,{Pb:null},function(){return new x(this.jg,this.kg,this.ur-this.jg,this.vr-this.kg)});t.g(Tj,"cellWidth",Tj.prototype.Hm);t.defineProperty(Tj,{Hm:null},function(){return this.ie},function(a){0<a&&a!==this.ie&&(this.ie=a,this.initialize(this.Pb))});t.g(Tj,"cellHeight",Tj.prototype.Gm);t.defineProperty(Tj,{Gm:null},function(){return this.je},function(a){0<a&&a!==this.je&&(this.je=a,this.initialize(this.Pb))});
function Hn(a,b,c){return a.jg<=b&&b<=a.ur&&a.kg<=c&&c<=a.vr}function un(a,b,c){if(!Hn(a,b,c))return 0;b-=a.jg;b/=a.ie;c-=a.kg;c/=a.je;return a.wc[b|0][c|0]}function Wj(a,b,c){Hn(a,b,c)&&(b-=a.jg,b/=a.ie,c-=a.kg,c/=a.je,a.wc[b|0][c|0]=0)}function Vj(a){if(null!==a.wc)for(var b=0;b<=a.Go;b++)for(var c=0;c<=a.Ho;c++)a.wc[b][c]>=xn&&(a.wc[b][c]|=vn)}
function sn(a,b,c,d,f){if(b>a.ur||b+d<a.jg||c>a.vr||c+f<a.kg)return!0;b=(b-a.jg)/a.ie|0;c=(c-a.kg)/a.je|0;d=Math.max(0,d)/a.ie+1|0;var g=Math.max(0,f)/a.je+1|0;0>b&&(d+=b,b=0);0>c&&(g+=c,c=0);if(0>d||0>g)return!0;f=Math.min(b+d-1,a.Go)|0;for(d=Math.min(c+g-1,a.Ho)|0;b<=f;b++)for(g=c;g<=d;g++)if(0===a.wc[b][g])return!1;return!0}
function In(a,b,c,d,f,g,h,k,l){if(!(b<g||b>h||c<k||c>l)){var m,n;m=b|0;n=c|0;var p=a.wc[m][n];if(p>=xn&&p<vn)for(f?n+=d:m+=d,p+=yn;g<=m&&m<=h&&k<=n&&n<=l&&!(p>=a.wc[m][n]);)a.wc[m][n]=p,p+=yn,f?n+=d:m+=d;m=f?n:m;if(f)if(0<d)for(c+=d;c<m;c+=d)In(a,b,c,1,!f,g,h,k,l),In(a,b,c,-1,!f,g,h,k,l);else for(c+=d;c>m;c+=d)In(a,b,c,1,!f,g,h,k,l),In(a,b,c,-1,!f,g,h,k,l);else if(0<d)for(b+=d;b<m;b+=d)In(a,b,c,1,!f,g,h,k,l),In(a,b,c,-1,!f,g,h,k,l);else for(b+=d;b>m;b+=d)In(a,b,c,1,!f,g,h,k,l),In(a,b,c,-1,!f,g,h,
k,l)}}function Jn(a,b,c,d,f,g,h,k,l,m,n){for(var p=b|0,q=c|0,r=a.wc[p][q];0===r&&p>k&&p<l&&q>m&&q<n;)if(h?q+=g:p+=g,r=a.wc[p][q],1>=Math.abs(p-d)&&1>=Math.abs(q-f))return a.abort=!0,0;p=b|0;q=c|0;r=a.wc[p][q];b=xn;for(a.wc[p][q]=b;0===r&&p>k&&p<l&&q>m&&q<n;)h?q+=g:p+=g,r=a.wc[p][q],a.wc[p][q]=b,b+=yn;return h?q:p}
function tn(a,b,c,d,f,g){if(null!==a.wc){a.abort=!1;var h=b.x,k=b.y;if(Hn(a,h,k)){var h=h-a.jg,h=h/a.ie,k=k-a.kg,k=k/a.je,l=d.x,m=d.y;if(Hn(a,l,m))if(l-=a.jg,l/=a.ie,m-=a.kg,m/=a.je,1>=Math.abs(h-l)&&1>=Math.abs(k-m))a.abort=!0;else{var n=g.x;b=g.y;d=g.x+g.width;var p=g.y+g.height,n=n-a.jg,n=n/a.ie;b-=a.kg;b/=a.je;d-=a.jg;d/=a.ie;p-=a.kg;p/=a.je;g=Math.max(0,Math.min(a.Go,n|0));d=Math.min(a.Go,Math.max(0,d|0));b=Math.max(0,Math.min(a.Ho,b|0));var p=Math.min(a.Ho,Math.max(0,p|0)),h=h|0,k=k|0,l=l|0,
m=m|0,n=h,q=k,r=0===c||90===c?1:-1;(c=90===c||270===c)?q=Jn(a,h,k,l,m,r,c,g,d,b,p):n=Jn(a,h,k,l,m,r,c,g,d,b,p);if(!a.abort){a:{c=0===f||90===f?1:-1;f=90===f||270===f;for(var r=l|0,s=m|0,u=a.wc[r][s];0===u&&r>g&&r<d&&s>b&&s<p;)if(f?s+=c:r+=c,u=a.wc[r][s],1>=Math.abs(r-h)&&1>=Math.abs(s-k)){a.abort=!0;break a}r=l|0;s=m|0;u=a.wc[r][s];for(a.wc[r][s]=Gn;0===u&&r>g&&r<d&&s>b&&s<p;)f?s+=c:r+=c,u=a.wc[r][s],a.wc[r][s]=Gn}a.abort||(In(a,n,q,1,!1,g,d,b,p),In(a,n,q,-1,!1,g,d,b,p),In(a,n,q,1,!0,g,d,b,p),In(a,
n,q,-1,!0,g,d,b,p))}}}}}function on(){t.Ac(this);this.port=this.Fc=null;this.Fg=[];this.Gp=!1}t.Vd(on,{Fc:!0,port:!0,Fg:!0,Gp:!0});on.prototype.toString=function(){for(var a=this.Fg,b=this.Fc.toString()+" "+a.length.toString()+":",c=0;c<a.length;c++){var d=a[c];null!==d&&(b+="\n  "+d.toString())}return b};
function Kn(a,b,c,d){b=b.offsetY;switch(b){case t.Sc:return 90;case t.Jc:return 180;case t.bd:return 270;case t.Tc:return 0}switch(b){case t.Sc|t.bd:return 180<c?270:90;case t.Jc|t.Tc:return 90<c&&270>=c?180:0}a=180*Math.atan2(a.height,a.width)/Math.PI;switch(b){case t.Jc|t.bd:return c>a&&c<=180+a?180:270;case t.bd|t.Tc:return c>180-a&&c<=360-a?270:0;case t.Tc|t.Sc:return c>a&&c<=180+a?90:0;case t.Sc|t.Jc:return c>180-a&&c<=360-a?180:90;case t.Jc|t.bd|t.Tc:return 90<c&&c<=180+a?180:c>180+a&&c<=360-
a?270:0;case t.bd|t.Tc|t.Sc:return 180<c&&c<=360-a?270:c>a&&180>=c?90:0;case t.Tc|t.Sc|t.Jc:return c>a&&c<=180-a?90:c>180-a&&270>=c?180:0;case t.Sc|t.Jc|t.bd:return c>180-a&&c<=180+a?180:c>180+a?270:90}d&&b!==(t.Jc|t.bd|t.Tc|t.Sc)&&(c-=15,0>c&&(c+=360));return c>a&&c<180-a?90:c>=180-a&&c<=180+a?180:c>180+a&&c<360-a?270:0}
function pn(a,b){var c=a.Fg;if(0===c.length){a:if(!a.Gp){c=a.Gp;a.Gp=!0;var d,f=null,g=a.Fc;if(g instanceof S&&!g.Yd){if(!g.oa.N()){a.Gp=c;break a}f=g;d=f.xE()}else d=a.Fc.yE();var h=a.Fg.length=0,k=a.port.pb(Eb,t.K()),l=a.port.pb(Pb,t.K()),g=t.ik(k.x,k.y,0,0);g.kj(l);t.B(k);t.B(l);k=t.mc(g.x+g.width/2,g.y+g.height/2);for(d=d.k;d.next();)if(l=d.value,l.La()){var m=wb,n=l.ud===a.port||l.aa.nl(f),m=n?kn(l,a.port):ln(l,a.port);if(m.rp()&&(n=n?l.fe:l.ud,null!==n)){var p=n.S;if(null!==p){var n=l.computeOtherPoint(p,
n),p=k.aj(n),m=Kn(g,m,p,l.ic),q;0===m?(q=t.Tc,180<p&&(p-=360)):q=90===m?t.Sc:180===m?t.Jc:t.bd;(m=a.Fg[h])?(m.link=l,m.angle=p,m.be=q):(m=new Ln(l,p,q),a.Fg[h]=m);m.hx.set(n);h++}}}t.B(k);a.Fg.sort(on.prototype.rJ);f=a.Fg.length;k=-1;for(h=d=0;h<f;h++)m=a.Fg[h],null!==m&&(m.be!==k&&(k=m.be,d=0),m.mp=d,d++);k=-1;d=0;for(h=f-1;0<=h;h--)m=a.Fg[h],null!==m&&(m.be!==k&&(k=m.be,d=m.mp+1),m.Lm=d);h=a.Fg;n=a.port;f=a.Fc.DJ;k=t.K();d=t.K();l=t.K();m=t.K();n.pb(Eb,k);n.pb(Gb,d);n.pb(Pb,l);n.pb(Lb,m);var r=
q=p=n=0;if(f===Mm)for(var s=0;s<h.length;s++){var u=h[s];if(null!==u){var v=u.link.computeThickness();switch(u.be){case t.Sc:q+=v;break;case t.Jc:r+=v;break;case t.bd:n+=v;break;default:case t.Tc:p+=v}}}for(var D=0,A,G,K=0,N=G=0,O=1,s=0;s<h.length;s++)if(u=h[s],null!==u){if(D!=u.be){D=u.be;switch(D){case t.Sc:A=l;G=m;break;case t.Jc:A=m;G=k;break;case t.bd:A=k;G=d;break;default:case t.Tc:A=d,G=l}K=G.x-A.x;G=G.y-A.y;switch(D){case t.Sc:q>Math.abs(K)?(O=Math.abs(K)/q,q=Math.abs(K)):O=1;break;case t.Jc:r>
Math.abs(G)?(O=Math.abs(G)/r,r=Math.abs(G)):O=1;break;case t.bd:n>Math.abs(K)?(O=Math.abs(K)/n,n=Math.abs(K)):O=1;break;default:case t.Tc:p>Math.abs(G)?(O=Math.abs(G)/p,p=Math.abs(G)):O=1}N=0}var U=u.xp;if(f===Mm){v=u.link.computeThickness();v*=O;U.set(A);switch(D){case t.Sc:U.x=A.x+K/2+q/2-N-v/2;break;case t.Jc:U.y=A.y+G/2+r/2-N-v/2;break;case t.bd:U.x=A.x+K/2-n/2+N+v/2;break;default:case t.Tc:U.y=A.y+G/2-p/2+N+v/2}N+=v}else v=0.5,f===Jm&&(v=(u.mp+1)/(u.Lm+1)),U.x=A.x+K*v,U.y=A.y+G*v}t.B(k);t.B(d);
t.B(l);t.B(m);A=a.Fg;for(h=0;h<A.length;h++)f=A[h],null!==f&&(f.Aw=a.computeEndSegmentLength(f));a.Gp=c;t.hc(g)}c=a.Fg}for(g=0;g<c.length;g++)if(A=c[g],null!==A&&A.link===b)return A;return null}on.prototype.rJ=function(a,b){return a===b?0:null===a?-1:null===b?1:a.be<b.be?-1:a.be>b.be?1:a.angle<b.angle?-1:a.angle>b.angle?1:0};
on.prototype.computeEndSegmentLength=function(a){var b=a.link,c=b.computeEndSegmentLength(this.Fc,this.port,wb,b.ud===this.port),d=a.mp;if(0>d)return c;var f=a.Lm;if(1>=f||!b.ic)return c;var b=a.hx,g=a.xp;if(a.be===t.Jc||a.be===t.Sc)d=f-1-d;return((a=a.be===t.Jc||a.be===t.Tc)?b.y<g.y:b.x<g.x)?c+8*d:(a?b.y===g.y:b.x===g.x)?c:c+8*(f-1-d)};function Ln(a,b,c){this.link=a;this.angle=b;this.be=c;this.hx=new w;this.Lm=this.mp=0;this.xp=new w;this.Aw=0}
t.Vd(Ln,{link:!0,angle:!0,be:!0,hx:!0,mp:!0,Lm:!0,xp:!0,Aw:!0});Ln.prototype.toString=function(){return this.link.toString()+" "+this.angle.toString()+" "+this.be.toString()+":"+this.mp.toString()+"/"+this.Lm.toString()+" "+this.xp.toString()+" "+this.Aw.toString()+" "+this.hx.toString()};
function S(a){0===arguments.length?R.call(this,$g):R.call(this,a);this.xr=new ma(C);this.qo=new ma(S);this.Ok=this.yr=this.wr=null;this.Es=!1;this.br=!0;this.Fs=!1;this.Jb=this.ts=null;this.uq=!1;this.vq=!0;this.wq=!1;this.Pd=new be;this.Pd.group=this}t.ga("Group",S);t.Ka(S,R);
S.prototype.cloneProtected=function(a){R.prototype.cloneProtected.call(this,a);a.wr=this.wr;a.yr=this.yr;a.Ok=this.Ok;a.Es=this.Es;a.br=this.br;a.Fs=this.Fs;a.ts=this.ts;var b=a.bt(function(a){return a instanceof Ug});a.Jb=b instanceof Ug?b:null;a.uq=this.uq;a.vq=this.vq;a.wq=this.wq;null!==this.Pd?(a.Pd=this.Pd.copy(),a instanceof S&&(a.Pd.group=a)):(null!==a.Pd&&(a.Pd.group=null),a.Pd=null)};
S.prototype.Yh=function(a){R.prototype.Yh.call(this,a);var b=a.dp();for(a=a.Rc;a.next();){var c=a.value;c.ha();c.J(8);c.Wi();if(c instanceof R)c.wf(b);else if(c instanceof T)for(c=c.fk;c.next();)c.value.wf(b)}};
S.prototype.gn=function(a,b,c,d,f,g,h){if(a===wd&&"elements"===b)if(f instanceof Ug)null===this.Jb?this.Jb=f:this.Jb!==f&&t.m("Cannot insert a second Placeholder into the visual tree of a Group.");else{if(f instanceof y){var k=f.bt(function(a){return a instanceof Ug});k instanceof Ug&&(null===this.Jb?this.Jb=k:this.Jb!==k&&t.m("Cannot insert a second Placeholder into the visual tree of a Group."))}}else a===xd&&"elements"===b&&null!==this.Jb&&(d===this.Jb?this.Jb=null:d instanceof y&&this.Jb.ej(d)&&
(this.Jb=null));R.prototype.gn.call(this,a,b,c,d,f,g,h)};S.prototype.Tj=function(a,b,c,d){this.Nk=this.Jb;y.prototype.Tj.call(this,a,b,c,d)};S.prototype.ol=function(){if(!R.prototype.ol.call(this))return!1;for(var a=this.Rc;a.next();){var b=a.value;if(b instanceof R){if(b.La()&&Ri(b))return!1}else if(b instanceof T&&b.La()&&Ri(b)&&b.aa!==this&&b.ca!==this)return!1}return!0};t.A(S,{placeholder:"placeholder"},function(){return this.Jb});t.g(S,"computesBoundsAfterDrag",S.prototype.Vz);
t.defineProperty(S,{Vz:"computesBoundsAfterDrag"},function(){return this.uq},function(a){var b=this.uq;b!==a&&(t.i(a,"boolean",S,"computesBoundsAfterDrag"),this.uq=a,this.j("computesBoundsAfterDrag",b,a))});t.g(S,"computesBoundsIncludingLinks",S.prototype.$D);t.defineProperty(S,{$D:"computesBoundsIncludingLinks"},function(){return this.vq},function(a){t.i(a,"boolean",S,"computesBoundsIncludingLinks");var b=this.vq;b!==a&&(this.vq=a,this.j("computesBoundsIncludingLinks",b,a))});
t.g(S,"computesBoundsIncludingLocation",S.prototype.aE);t.defineProperty(S,{aE:"computesBoundsIncludingLocation"},function(){return this.wq},function(a){t.i(a,"boolean",S,"computesBoundsIncludingLocation");var b=this.wq;b!==a&&(this.wq=a,this.j("computesBoundsIncludingLocation",b,a))});t.A(S,{Rc:"memberParts"},function(){return this.xr.k});
function Fm(a,b){if(a.xr.add(b)){b instanceof S&&a.qo.add(b);var c=a.vF;if(null!==c){var d=!0,f=a.h;null!==f&&(d=f.Na,f.Na=!0);c(a,b);null!==f&&(f.Na=d)}a.La()&&a.Yd||b.df(!1)}c=a.Jb;null===c&&(c=a);c.ha()}function Em(a,b){if(a.xr.remove(b)){b instanceof S&&a.qo.remove(b);var c=a.wF;if(null!==c){var d=!0,f=a.h;null!==f&&(d=f.Na,f.Na=!0);c(a,b);null!==f&&(f.Na=d)}}c=a.Jb;null===c&&(c=a);c.ha()}S.prototype.Nm=function(){if(0<this.xr.count){var a=this.h;if(null===a)return;for(var b=this.xr.copy().k;b.next();)a.remove(b.value)}R.prototype.Nm.call(this)};
S.prototype.xx=function(){var a=this.layer;null!==a&&a.xx(this)};t.g(S,"layout",S.prototype.jc);t.defineProperty(S,{jc:"layout"},function(){return this.Pd},function(a){var b=this.Pd;b!==a&&(null!==a&&t.l(a,be,S,"layout"),null!==b&&(b.h=null,b.group=null),this.Pd=a,null!==a&&(a.h=this.h,a.group=this),this.j("layout",b,a))});t.g(S,"memberAdded",S.prototype.vF);
t.defineProperty(S,{vF:"memberAdded"},function(){return this.wr},function(a){var b=this.wr;b!==a&&(null!==a&&t.i(a,"function",S,"memberAdded"),this.wr=a,this.j("memberAdded",b,a))});t.g(S,"memberRemoved",S.prototype.wF);t.defineProperty(S,{wF:"memberRemoved"},function(){return this.yr},function(a){var b=this.yr;b!==a&&(null!==a&&t.i(a,"function",S,"memberRemoved"),this.yr=a,this.j("memberRemoved",b,a))});t.g(S,"memberValidation",S.prototype.vt);
t.defineProperty(S,{vt:"memberValidation"},function(){return this.Ok},function(a){var b=this.Ok;b!==a&&(null!==a&&t.i(a,"function",S,"memberValidation"),this.Ok=a,this.j("memberValidation",b,a))});S.prototype.canAddMembers=function(a){var b=this.h;if(null===b)return!1;b=b.Se;for(a=Ye(a).k;a.next();)if(!b.isValidMember(this,a.value))return!1;return!0};
S.prototype.addMembers=function(a,b){var c=this.h;if(null===c)return!1;for(var c=c.Se,d=!0,f=Ye(a).k;f.next();){var g=f.value;!b||c.isValidMember(this,g)?g.hb=this:d=!1}return d};t.g(S,"ungroupable",S.prototype.NG);t.defineProperty(S,{NG:"ungroupable"},function(){return this.Es},function(a){var b=this.Es;b!==a&&(t.i(a,"boolean",S,"ungroupable"),this.Es=a,this.j("ungroupable",b,a))});
S.prototype.canUngroup=function(){if(!this.NG)return!1;var a=this.layer;if(null!==a&&!a.Ro)return!1;a=a.h;return null===a||a.Ro?!0:!1};S.prototype.invalidateConnectedLinks=S.prototype.wf=function(a){void 0===a&&(a=null);R.prototype.wf.call(this,a);for(var b=this.xE();b.next();){var c=b.value;if(null===a||!a.contains(c)){var d=c.aa;null!==d&&d.nl(this)&&!d.La()?c.ac():(d=c.ca,null!==d&&d.nl(this)&&!d.La()&&c.ac())}}};
S.prototype.findExternalLinksConnected=S.prototype.xE=function(){var a=this.dp();a.add(this);for(var b=new ma(T),c=a.k;c.next();){var d=c.value;if(d instanceof R)for(d=d.qe;d.next();){var f=d.value;a.contains(f)||b.add(f)}}return b.k};S.prototype.findExternalNodesConnected=function(){var a=this.dp();a.add(this);for(var b=new ma(R),c=a.k;c.next();){var d=c.value;if(d instanceof R)for(d=d.qe;d.next();){var f=d.value,g=f.aa;a.contains(g)&&g!==this||b.add(g);f=f.ca;a.contains(f)&&f!==this||b.add(f)}}return b.k};
S.prototype.findSubGraphParts=S.prototype.dp=function(){var a=new ma(C);Ie(a,this,!0,0);a.remove(this);return a};S.prototype.df=function(a){R.prototype.df.call(this,a);for(var b=this.Rc;b.next();)b.value.df(a)};S.prototype.collapseSubGraph=S.prototype.collapseSubGraph=function(){var a=this.h;if(null!==a&&!a.Wd){a.Wd=!0;var b=this.dp();Mn(this,b);a.Wd=!1}};
function Mn(a,b){for(var c=a.Rc;c.next();){var d=c.value;d.df(!1);if(d instanceof S){var f=d;f.Yd&&(f.Dx=f.Yd,Mn(f,b))}if(d instanceof R)d.wf(b);else if(d instanceof T)for(d=d.fk;d.next();)d.value.wf(b)}a.Yd=!1}S.prototype.expandSubGraph=S.prototype.expandSubGraph=function(){var a=this.h;if(null!==a&&!a.Wd){var b=a.Ub;0!==a.la.Je&&b.ul();a.Wd=!0;var c=this.dp();Nn(this,c,b,this);a.Wd=!1}};
function Nn(a,b,c,d){for(var f=a.Rc;f.next();){var g=f.value;g.df(!0);if(g instanceof S){var h=g;h.Dx&&(h.Dx=!1,Nn(h,b,c,d))}if(g instanceof R)g.wf(b),Nh(c,g,d);else if(g instanceof T)for(g=g.fk;g.next();)g.value.wf(b)}a.Yd=!0}t.g(S,"isSubGraphExpanded",S.prototype.Yd);
t.defineProperty(S,{Yd:"isSubGraphExpanded"},function(){return this.br},function(a){var b=this.br;if(b!==a){t.i(a,"boolean",S,"isSubGraphExpanded");this.br=a;var c=this.h;this.j("isSubGraphExpanded",b,a);b=this.yG;if(null!==b){var d=!0;null!==c&&(d=c.Na,c.Na=!0);b(this);null!==c&&(c.Na=d)}null!==c&&c.la.vb||(a?this.expandSubGraph():this.collapseSubGraph())}});t.g(S,"wasSubGraphExpanded",S.prototype.Dx);
t.defineProperty(S,{Dx:"wasSubGraphExpanded"},function(){return this.Fs},function(a){var b=this.Fs;b!==a&&(t.i(a,"boolean",S,"wasSubGraphExpanded"),this.Fs=a,this.j("wasSubGraphExpanded",b,a))});t.g(S,"subGraphExpandedChanged",S.prototype.yG);t.defineProperty(S,{yG:"subGraphExpandedChanged"},function(){return this.ts},function(a){var b=this.ts;b!==a&&(null!==a&&t.i(a,"function",S,"subGraphExpandedChanged"),this.ts=a,this.j("subGraphExpandedChanged",b,a))});
S.prototype.move=S.prototype.move=function(a){var b=this.position,c=b.x;isNaN(c)&&(c=0);b=b.y;isNaN(b)&&(b=0);var c=a.x-c,b=a.y-b,d=t.mc(c,b);R.prototype.move.call(this,a);for(a=this.dp().k;a.next();){var f=a.value;if(!(f instanceof T||f.bi)){var g=f.position,h=f.location;g.N()?(d.x=g.x+c,d.y=g.y+b,f.position=d):h.N()&&(d.x=h.x+c,d.y=h.y+b,f.location=d)}}for(a.reset();a.next();)f=a.value,f instanceof T&&(g=f.position,d.x=g.x+c,d.y=g.y+b,f.move(d));t.B(d)};
function Ug(){Q.call(this);this.Ne=F.fq;this.cs=new x(NaN,NaN,NaN,NaN)}t.ga("Placeholder",Ug);t.Ka(Ug,Q);Ug.prototype.cloneProtected=function(a){Q.prototype.cloneProtected.call(this,a);a.Ne=this.Ne.Z();a.cs=this.cs.copy()};Ug.prototype.Uj=function(a){if(null===this.background&&null===this.$k)return!1;var b=this.Pa;return mb(0,0,b.width,b.height,a.x,a.y)};
Ug.prototype.ut=function(){var a=this.S;null!==a&&(a instanceof S||a instanceof Je)||t.m("Placeholder is not inside a Group or Adornment.");if(a instanceof S){var b=this.computeBorder(this.cs),c=this.hd;Va(c,b.width||0,b.height||0);nk(this,0,0,c.width,c.height);for(var c=a.Rc,d=!1;c.next();)if(c.value.La()){d=!0;break}!d||isNaN(b.x)||isNaN(b.y)||(c=new w,c.Qt(b,a.bf),a.location=new w(c.x,c.y))}else{var b=this.Ca,c=this.hd,a=this.padding,d=a.left+a.right,f=a.top+a.bottom;if(b.N())Va(c,b.width+d||0,
b.height+f||0),nk(this,-a.left,-a.top,c.width,c.height);else{var g=this.S,h=g.pc,k=h.pb(Eb,t.K()),b=t.ik(k.x,k.y,0,0);b.kj(h.pb(Pb,k));b.kj(h.pb(Gb,k));b.kj(h.pb(Lb,k));g.Yg.p(b.x,b.y);Va(c,b.width+d||0,b.height+f||0);nk(this,-a.left,-a.top,c.width,c.height);t.B(k);t.hc(b)}}};Ug.prototype.Tj=function(a,b,c,d){var f=this.oa;f.x=a;f.y=b;f.width=c;f.height=d};
Ug.prototype.computeBorder=function(a){var b=this.S;if(b instanceof S&&b.Vz&&this.cs.N()){var c=b.h;if(null!==c&&(c=c.Ua,c instanceof cf&&!c.Iq&&null!==c.qc&&!c.qc.contains(b)))return a.assign(this.cs),a}var c=t.Gf(),d=this.computeMemberBounds(c),f=this.padding;a.p(d.x-f.left,d.y-f.top,d.width+f.left+f.right,d.height+f.top+f.bottom);t.hc(c);b instanceof S&&b.aE&&b.location.N()&&a.kj(b.location);return a};
Ug.prototype.computeMemberBounds=function(a){var b=this.S;if(!(b instanceof S))return a.p(0,0,0,0),a;for(var c=Infinity,d=Infinity,f=-Infinity,g=-Infinity,h=b.Rc;h.next();){var k=h.value;if(k.La()){if(k instanceof T){if(!b.$D)continue;if(Qi(k))continue;if(k.aa===b||k.ca===b)continue}k=k.oa;k.left<c&&(c=k.left);k.top<d&&(d=k.top);k.right>f&&(f=k.right);k.bottom>g&&(g=k.bottom)}}isFinite(c)&&isFinite(d)?a.p(c,d,f-c,g-d):(b=b.location,c=this.padding,a.p(b.x+c.left,b.y+c.top,0,0));return a};
t.g(Ug,"padding",Ug.prototype.padding);t.defineProperty(Ug,{padding:"padding"},function(){return this.Ne},function(a){"number"===typeof a?(0>a&&t.ka(a,">= 0",Ug,"padding"),a=new ab(a)):(t.l(a,ab,Ug,"padding"),0>a.left&&t.ka(a.left,">= 0",Ug,"padding:val.left"),0>a.right&&t.ka(a.right,">= 0",Ug,"padding:val.right"),0>a.top&&t.ka(a.top,">= 0",Ug,"padding:val.top"),0>a.bottom&&t.ka(a.bottom,">= 0",Ug,"padding:val.bottom"));var b=this.Ne;b.M(a)||(this.Ne=a=a.Z(),this.j("padding",b,a))});
function be(){0<arguments.length&&t.ad(be);t.Ac(this);this.Wy=this.U=null;this.Xq=this.Zq=!0;this.gr=this.co=!1;this.nq=(new w(0,0)).freeze();this.az=!0;this.kz=null;this.$q=!0}t.ga("Layout",be);be.prototype.cloneProtected=function(a){a.Zq=this.Zq;a.Xq=this.Xq;this.Xq||(a.co=!0);a.gr=this.gr;a.nq.assign(this.nq);a.$q=this.$q};be.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};
be.prototype.toString=function(){var a=t.xg(Object.getPrototypeOf(this)),a=a+"(";null!==this.group&&(a+=" in "+this.group);null!==this.h&&(a+=" for "+this.h);return a+")"};t.g(be,"diagram",be.prototype.h);t.defineProperty(be,{h:"diagram"},function(){return this.U},function(a){this.U!==a&&(null!==a&&t.l(a,z,be,"diagram"),this.U=a)});t.g(be,"group",be.prototype.group);
t.defineProperty(be,{group:"group"},function(){return this.Wy},function(a){this.Wy!==a&&(null!==a&&t.l(a,S,be,"group"),this.Wy=a,null!==a&&(this.U=a.h))});t.g(be,"isOngoing",be.prototype.fF);t.defineProperty(be,{fF:"isOngoing"},function(){return this.Zq},function(a){this.Zq!==a&&(t.i(a,"boolean",be,"isOngoing"),this.Zq=a)});t.g(be,"isInitial",be.prototype.eF);t.defineProperty(be,{eF:"isInitial"},function(){return this.Xq},function(a){t.i(a,"boolean",be,"isInitial");this.Xq=a;a||(this.co=!0)});
t.g(be,"isValidLayout",be.prototype.$e);t.defineProperty(be,{$e:"isValidLayout"},function(){return this.co},function(a){this.co!==a&&(t.i(a,"boolean",be,"isValidLayout"),this.co=a,a||(a=this.h,null!==a&&(a.mu=!0)))});be.prototype.invalidateLayout=be.prototype.J=function(){if(this.co){var a=this.h;if(null!==a&&!a.la.vb){var b=a.Ub;!b.cr&&(b.yg&&b.Al(),this.fF&&a.lf||this.eF&&!a.lf)&&(this.$e=!1,a.te())}}};t.g(be,"isRealtime",be.prototype.wA);
t.defineProperty(be,{wA:"isRealtime"},function(){return this.$q},function(a){this.$q!==a&&(t.i(a,"boolean",be,"isRealtime"),this.$q=a)});t.g(be,"isViewportSized",be.prototype.st);t.defineProperty(be,{st:"isViewportSized"},function(){return this.gr},function(a){this.gr!==a&&(t.i(a,"boolean",be,"isViewportSized"),(this.gr=a)&&this.J())});t.g(be,"isRouting",be.prototype.rt);
t.defineProperty(be,{rt:"isRouting"},function(){return this.az},function(a){this.az!==a&&(t.i(a,"boolean",be,"isRouting"),this.az=a)});t.g(be,"network",be.prototype.network);t.defineProperty(be,{network:"network"},function(){return this.kz},function(a){var b=this.kz;b!==a&&(null!==a&&t.l(a,ta,be,"network"),null!==b&&On(b,null),this.kz=a,null!==a&&On(a,this))});be.prototype.createNetwork=function(){var a=new ta;On(a,this);return a};
be.prototype.makeNetwork=function(a){var b=this.createNetwork();a instanceof z?(b.Ks(a.jh,!0),b.Ks(a.links,!0)):a instanceof S?b.Ks(a.Rc):b.Ks(a.k);return b};be.prototype.updateParts=function(){var a=this.h;if(null===a&&null!==this.network)for(var b=this.network.vertexes.k;b.next();){var c=b.value.Fc;if(null!==c&&(a=c.h,null!==a))break}this.$e=!0;null!==a&&a.uc("Layout");this.commitLayout();null!==a&&a.Ce("Layout")};be.prototype.commitLayout=function(){};
be.prototype.doLayout=function(a){a||t.m("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");var b=new ma(C);a===this.h?(Pn(this,b,a.jh,!0,this.FA,!0,!1,!0),Pn(this,b,a.sl,!0,this.FA,!0,!1,!0)):a===this.group?Pn(this,b,a.Rc,!1,this.FA,!0,!1,!0):b.Oe(a.k);var c=b.count;if(0<c){a=this.h;null!==a&&a.uc("Layout");for(var c=Math.ceil(Math.sqrt(c)),d=this.Fd.x,f=d,g=this.Fd.y,h=0,k=0,b=b.k;b.next();){var l=b.value;l.sf();var m=l.Ga,n=m.width,m=m.height;
l.moveTo(f,g);delete l.LC;f+=Math.max(n,50)+20;k=Math.max(k,Math.max(m,50));h>=c-1?(h=0,f=d,g+=k+20,k=0):h++}null!==a&&a.Ce("Layout")}this.$e=!0};be.prototype.FA=function(a){return!a.location.N()||a instanceof S&&a.LC?!0:!1};function Pn(a,b,c,d,f,g,h,k){for(c=c.k;c.next();){var l=c.value;d&&!l.sp||f&&!f(l)||l.canLayout()&&(g&&l instanceof R?l.bi||(l instanceof S&&null===l.jc?Pn(a,b,l.Rc,!1,f,g,h,k):b.add(l)):h&&l instanceof T?b.add(l):!k||!l.Ld()||l instanceof R||b.add(l))}}
t.g(be,"arrangementOrigin",be.prototype.Fd);t.defineProperty(be,{Fd:"arrangementOrigin"},function(){return this.nq},function(a){t.l(a,w,be,"arrangementOrigin");this.nq.M(a)||(this.nq.assign(a),this.J())});be.prototype.initialOrigin=function(a){var b=this.group;if(null!==b){var c=b.location;if(isNaN(c.x)||isNaN(c.y))return a;a=b.placeholder;null!==a?(c=a.pb(Eb),c.x+=a.padding.left,c.y+=a.padding.top):c=b.position.copy();return c}return a};function ta(){t.Ac(this);this.Pd=null;this.clear()}
t.ga("LayoutNetwork",ta);ta.prototype.clear=function(){if(this.vertexes)for(var a=this.vertexes.k;a.next();){var b=a.value;b.clear();b.network=null}if(this.edges)for(a=this.edges.k;a.next();)b=a.value,b.clear(),b.network=null;this.vertexes=new ma(ua);this.edges=new ma(va);this.IA=new ia(R,ua);this.yA=new ia(T,va)};ta.prototype.toString=function(){return"LayoutNetwork"+(null!==this.jc?"("+this.jc.toString()+")":"")};t.A(ta,{jc:"layout"},function(){return this.Pd});
function On(a,b){e&&null!==b&&t.l(b,be,ta,"setLayout");a.Pd=b}ta.prototype.createVertex=function(){return new ua};ta.prototype.createEdge=function(){return new va};
ta.prototype.addParts=ta.prototype.Ks=function(a,b){if(null!==a){void 0===b&&(b=!1);t.i(b,"boolean",ta,"addParts:toplevelonly");for(var c=a.k;c.next();){var d=c.value;if(d instanceof R&&(!b||d.sp)&&d.canLayout()&&!d.bi)if(d instanceof S&&null===d.jc)this.Ks(d.Rc,!1);else if(null===this.Rm(d)){var f=this.createVertex();f.Fc=d;this.Yk(f)}}for(c.reset();c.next();)if(d=c.value,d instanceof T&&(!b||d.sp)&&d.canLayout()&&null===this.Bw(d)){var g=d.aa,f=d.ca;g!==f&&(g=this.findGroupVertex(g),f=this.findGroupVertex(f),
null!==g&&null!==f&&this.an(g,f,d))}}};ta.prototype.findGroupVertex=function(a){if(null===a)return null;var b=a.findVisibleNode();if(null===b)return null;a=this.Rm(b);if(null!==a)return a;for(b=b.hb;null!==b;){a=this.Rm(b);if(null!==a)return a;b=b.hb}return null};ta.prototype.addVertex=ta.prototype.Yk=function(a){if(null!==a){e&&t.l(a,ua,ta,"addVertex:vertex");this.vertexes.add(a);var b=a.Fc;null!==b&&this.IA.add(b,a);a.network=this}};
ta.prototype.addNode=ta.prototype.Ko=function(a){if(null===a)return null;e&&t.l(a,R,ta,"addNode:node");var b=this.Rm(a);null===b&&(b=this.createVertex(),b.Fc=a,this.Yk(b));return b};ta.prototype.deleteVertex=ta.prototype.rE=function(a){if(null!==a&&(e&&t.l(a,ua,ta,"deleteVertex:vertex"),Qn(this,a))){for(var b=a.ff,c=b.count-1;0<=c;c--){var d=b.wa(c);this.Zo(d)}b=a.Te;for(c=b.count-1;0<=c;c--)d=b.wa(c),this.Zo(d)}};
function Qn(a,b){if(null===b)return!1;var c=a.vertexes.remove(b);c&&(a.IA.remove(b.Fc),b.network=null);return c}ta.prototype.deleteNode=function(a){null!==a&&(e&&t.l(a,R,ta,"deleteNode:node"),a=this.Rm(a),null!==a&&this.rE(a))};ta.prototype.findVertex=ta.prototype.Rm=function(a){if(null===a)return null;e&&t.l(a,R,ta,"findVertex:node");return this.IA.za(a)};
ta.prototype.addEdge=ta.prototype.Jo=function(a){if(null!==a){e&&t.l(a,va,ta,"addEdge:edge");this.edges.add(a);var b=a.link;null!==b&&null===this.Bw(b)&&this.yA.add(b,a);b=a.toVertex;null!==b&&b.HD(a);b=a.fromVertex;null!==b&&b.FD(a);a.network=this}};
ta.prototype.addLink=function(a){if(null===a)return null;e&&t.l(a,T,ta,"addLink:link");var b=a.aa,c=a.ca,d=this.Bw(a);null===d?(d=this.createEdge(),d.link=a,null!==b&&(d.fromVertex=this.Ko(b)),null!==c&&(d.toVertex=this.Ko(c)),this.Jo(d)):(d.fromVertex=null!==b?this.Ko(b):null,d.toVertex=null!==c?this.Ko(c):null);return d};ta.prototype.deleteEdge=ta.prototype.Zo=function(a){if(null!==a){e&&t.l(a,va,ta,"deleteEdge:edge");var b=a.toVertex;null!==b&&b.qE(a);b=a.fromVertex;null!==b&&b.pE(a);Rn(this,a)}};
function Rn(a,b){null!==b&&a.edges.remove(b)&&(a.yA.remove(b.link),b.network=null)}ta.prototype.deleteLink=function(a){null!==a&&(e&&t.l(a,T,ta,"deleteLink:link"),a=this.Bw(a),null!==a&&this.Zo(a))};ta.prototype.findEdge=ta.prototype.Bw=function(a){if(null===a)return null;e&&t.l(a,T,ta,"findEdge:link");return this.yA.za(a)};
ta.prototype.linkVertexes=ta.prototype.an=function(a,b,c){if(null===a||null===b)return null;e&&(t.l(a,ua,ta,"linkVertexes:fromVertex"),t.l(b,ua,ta,"linkVertexes:toVertex"),null!==c&&t.l(c,T,ta,"linkVertexes:link"));if(a.network===this&&b.network===this){var d=this.createEdge();d.link=c;d.fromVertex=a;d.toVertex=b;this.Jo(d);return d}return null};
ta.prototype.reverseEdge=ta.prototype.ox=function(a){if(null!==a){e&&t.l(a,va,ta,"reverseEdge:edge");var b=a.fromVertex,c=a.toVertex;null!==b&&null!==c&&(b.pE(a),c.qE(a),a.ox(),b.HD(a),c.FD(a))}};ta.prototype.deleteSelfEdges=ta.prototype.xw=function(){for(var a=t.Db(),b=this.edges.k;b.next();){var c=b.value;c.fromVertex===c.toVertex&&a.push(c)}b=a.length;for(c=0;c<b;c++)this.Zo(a[c]);t.ya(a)};
ta.prototype.deleteArtificialVertexes=function(){for(var a=t.Db(),b=this.vertexes.k;b.next();){var c=b.value;null===c.Fc&&a.push(c)}c=a.length;for(b=0;b<c;b++)this.rE(a[b]);c=t.Db();for(b=this.edges.k;b.next();){var d=b.value;null===d.link&&c.push(d)}d=c.length;for(b=0;b<d;b++)this.Zo(c[b]);t.ya(a);t.ya(c)};function Sn(a){for(var b=t.Db(),c=a.edges.k;c.next();){var d=c.value;null!==d.fromVertex&&null!==d.toVertex||b.push(d)}c=b.length;for(d=0;d<c;d++)a.Zo(b[d]);t.ya(b)}
ta.prototype.splitIntoSubNetworks=ta.prototype.VJ=function(){this.deleteArtificialVertexes();Sn(this);this.xw();for(var a=new B(ta),b=!0;b;)for(var b=!1,c=this.vertexes.k;c.next();){var d=c.value;if(0<d.ff.count||0<d.Te.count){b=this.jc.createNetwork();a.add(b);Tn(this,b,d);b=!0;break}}a.sort(function(a,b){return null===a||null===b||a===b?0:b.vertexes.count-a.vertexes.count});return a};
function Tn(a,b,c){if(null!==c&&c.network!==b){Qn(a,c);b.Yk(c);for(var d=c.tc;d.next();){var f=d.value;f.network!==b&&(Rn(a,f),b.Jo(f),Tn(a,b,f.fromVertex))}for(d=c.gc;d.next();)f=d.value,f.network!==b&&(Rn(a,f),b.Jo(f),Tn(a,b,f.toVertex))}}ta.prototype.findAllParts=function(){for(var a=new ma(C),b=this.vertexes.k;b.next();)a.add(b.value.Fc);for(b=this.edges.k;b.next();)a.add(b.value.link);return a};
function ua(){t.Ac(this);this.network=null;this.T=(new x(0,0,10,10)).freeze();this.O=(new w(5,5)).freeze();this.clear()}t.ga("LayoutVertex",ua);ua.prototype.clear=function(){this.rd=null;this.ff=new B(va);this.Te=new B(va)};ua.prototype.toString=function(){return"LayoutVertex#"+t.od(this)+(null!==this.Fc?"("+this.Fc.toString()+")":"")};t.g(ua,"node",ua.prototype.Fc);
t.defineProperty(ua,{Fc:"node"},function(){return this.rd},function(a){if(this.rd!==a){e&&null!==a&&t.l(a,R,ua,"node");this.rd=a;a.sf();var b=a.oa,c=b.x,d=b.y,f=b.width,b=b.height;isNaN(c)&&(c=0);isNaN(d)&&(d=0);this.T.p(c,d,f,b);if(!(a instanceof S)&&(a=a.kc.pb(Hb),a.N())){this.O.p(a.x-c,a.y-d);return}this.O.p(f/2,b/2)}});t.g(ua,"bounds",ua.prototype.Pb);t.defineProperty(ua,{Pb:"bounds"},function(){return this.T},function(a){this.T.M(a)||(e&&t.l(a,x,ua,"bounds"),this.T.assign(a))});
t.g(ua,"focus",ua.prototype.focus);t.defineProperty(ua,{focus:"focus"},function(){return this.O},function(a){this.O.M(a)||(e&&t.l(a,w,ua,"focus"),this.O.assign(a))});t.g(ua,"centerX",ua.prototype.Da);t.defineProperty(ua,{Da:"centerX"},function(){return this.T.x+this.O.x},function(a){var b=this.T;b.x+this.O.x!==a&&(e&&t.q(a,ua,"centerX"),b.Ma(),b.x=a-this.O.x,b.freeze())});t.g(ua,"centerY",ua.prototype.Oa);
t.defineProperty(ua,{Oa:"centerY"},function(){return this.T.y+this.O.y},function(a){var b=this.T;b.y+this.O.y!==a&&(e&&t.q(a,ua,"centerY"),b.Ma(),b.y=a-this.O.y,b.freeze())});t.g(ua,"focusX",ua.prototype.fp);t.defineProperty(ua,{fp:"focusX"},function(){return this.O.x},function(a){var b=this.O;b.x!==a&&(b.Ma(),b.x=a,b.freeze())});t.g(ua,"focusY",ua.prototype.gp);t.defineProperty(ua,{gp:"focusY"},function(){return this.O.y},function(a){var b=this.O;b.y!==a&&(b.Ma(),b.y=a,b.freeze())});t.g(ua,"x",ua.prototype.x);
t.defineProperty(ua,{x:"x"},function(){return this.T.x},function(a){var b=this.T;b.x!==a&&(b.Ma(),b.x=a,b.freeze())});t.g(ua,"y",ua.prototype.y);t.defineProperty(ua,{y:"y"},function(){return this.T.y},function(a){var b=this.T;b.y!==a&&(b.Ma(),b.y=a,b.freeze())});t.g(ua,"width",ua.prototype.width);t.defineProperty(ua,{width:"width"},function(){return this.T.width},function(a){var b=this.T;b.width!==a&&(b.Ma(),b.width=a,b.freeze())});t.g(ua,"height",ua.prototype.height);
t.defineProperty(ua,{height:"height"},function(){return this.T.height},function(a){var b=this.T;b.height!==a&&(b.Ma(),b.height=a,b.freeze())});ua.prototype.commit=function(){var a=this.Fc;if(null!==a){var b=this.Pb;if(!(a instanceof S)){var c=a.oa,d=a.kc.pb(Hb);if(c.N()&&d.N()){a.moveTo(b.x+this.fp-(d.x-c.x),b.y+this.gp-(d.y-c.y));return}}a.moveTo(b.x,b.y)}};ua.prototype.addSourceEdge=ua.prototype.HD=function(a){null!==a&&(e&&t.l(a,va,ua,"addSourceEdge:edge"),this.ff.contains(a)||this.ff.add(a))};
ua.prototype.deleteSourceEdge=ua.prototype.qE=function(a){null!==a&&(e&&t.l(a,va,ua,"deleteSourceEdge:edge"),this.ff.remove(a))};ua.prototype.addDestinationEdge=ua.prototype.FD=function(a){null!==a&&(e&&t.l(a,va,ua,"addDestinationEdge:edge"),this.Te.contains(a)||this.Te.add(a))};ua.prototype.deleteDestinationEdge=ua.prototype.pE=function(a){null!==a&&(e&&t.l(a,va,ua,"deleteDestinationEdge:edge"),this.Te.remove(a))};
t.A(ua,{SJ:"sourceVertexes"},function(){for(var a=new ma(ua),b=this.tc;b.next();){var c=b.value.fromVertex;null===c||a.contains(c)||a.add(c)}return a.k});t.A(ua,{xI:"destinationVertexes"},function(){for(var a=new ma(ua),b=this.gc;b.next();){var c=b.value.toVertex;null===c||a.contains(c)||a.add(c)}return a.k});
t.A(ua,{vertexes:"vertexes"},function(){for(var a=new ma(ua),b=this.tc;b.next();){var c=b.value,c=c.fromVertex;null===c||a.contains(c)||a.add(c)}for(b=this.gc;b.next();)c=b.value,c=c.toVertex,null===c||a.contains(c)||a.add(c);return a.k});t.A(ua,{tc:"sourceEdges"},function(){return this.ff.k});t.A(ua,{gc:"destinationEdges"},function(){return this.Te.k});t.A(ua,{edges:"edges"},function(){for(var a=new B(va),b=this.tc;b.next();){var c=b.value;a.add(c)}for(b=this.gc;b.next();)c=b.value,a.add(c);return a.k});
t.A(ua,{DI:"edgesCount"},function(){return this.ff.count+this.Te.count});var Un;ua.standardComparer=Un=function(a,b){e&&t.l(a,ua,ua,"standardComparer:m");e&&t.l(b,ua,ua,"standardComparer:n");var c=a.rd,d=b.rd;return c?d?(c=c.text,d=d.text,c<d?-1:c>d?1:0):1:null!==d?-1:0};
ua.smartComparer=function(a,b){e&&t.l(a,ua,ua,"smartComparer:m");e&&t.l(b,ua,ua,"smartComparer:n");if(null!==a){if(null!==b){var c=a.rd,d=b.rd;if(null!==c){if(null!==d){var c=c.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),d=d.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),f;for(f=0;f<c.length;f++)if(""!==d[f]&&void 0!==d[f]){var g=parseFloat(c[f]),h=parseFloat(d[f]);if(isNaN(g)){if(!isNaN(h))return 1;if(0!==c[f].localeCompare(d[f]))return c[f].localeCompare(d[f])}else{if(isNaN(h))return-1;
if(0!==g-h)return g-h}}else if(""!==c[f])return 1;return""!==d[f]&&void 0!==d[f]?-1:0}return 1}return null!==d?-1:0}return 1}return null!==b?-1:0};function va(){t.Ac(this);this.network=null;this.clear()}t.ga("LayoutEdge",va);va.prototype.clear=function(){this.toVertex=this.fromVertex=this.link=null};va.prototype.toString=function(){return"LayoutEdge#"+t.od(this)+(null!==this.link?"("+this.link.toString()+")":"")};
va.prototype.ox=function(){var a=this.fromVertex;this.fromVertex=this.toVertex;this.toVertex=a};va.prototype.commit=function(){};va.prototype.getOtherVertex=va.prototype.II=function(a){e&&t.l(a,ua,va,"getOtherVertex:v");return this.toVertex===a?this.fromVertex:this.fromVertex===a?this.toVertex:null};
function ck(){0<arguments.length&&t.ad(ck);be.call(this);this.st=!0;this.Hs=this.Is=NaN;this.pj=(new fa(NaN,NaN)).freeze();this.Nh=(new fa(10,10)).freeze();this.ue=ll;this.cd=jl;this.Mh=fl;this.th=Vn}t.ga("GridLayout",ck);t.Ka(ck,be);ck.prototype.cloneProtected=function(a){be.prototype.cloneProtected.call(this,a);a.Is=this.Is;a.Hs=this.Hs;a.pj.assign(this.pj);a.Nh.assign(this.Nh);a.ue=this.ue;a.cd=this.cd;a.Mh=this.Mh;a.th=this.th};
ck.prototype.doLayout=function(a){a||t.m("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");this.Fd=this.initialOrigin(this.Fd);var b=this.h,c=new ma(C);a instanceof z?(b=a,Pn(this,c,a.jh,!0,null,!0,!0,!0),Pn(this,c,a.links,!0,null,!0,!0,!0),Pn(this,c,a.sl,!0,null,!0,!0,!0)):a instanceof S?(b=a.h,Pn(this,c,a.Rc,!1,null,!0,!0,!0)):c.Oe(a.k);for(a=c.copy().k;a.next();){var d=a.value;if(d instanceof T&&(null!==d.aa||null!==d.ca))c.remove(d);else if(d.sf(),
d instanceof S)for(d=d.Rc;d.next();)c.remove(d.value)}c=c.Ie();if(0!==c.length){switch(this.sorting){case il:c.reverse();break;case fl:c.sort(this.comparer);break;case gl:c.sort(this.comparer),c.reverse()}var f=this.ZG;isNaN(f)&&(f=0);var g=this.qB,g=isNaN(g)&&null!==b?Math.max(b.xb.width-b.padding.left-b.padding.right,0):Math.max(this.qB,0);0>=f&&0>=g&&(f=1);a=this.spacing.width;isFinite(a)||(a=0);d=this.spacing.height;isFinite(d)||(d=0);null!==b&&b.uc("Layout");var h=[];switch(this.alignment){case ml:var k=
a,l=d,m=Math.max(this.al.width,1);if(!isFinite(m))for(var n=m=0;n<c.length;n++)var p=c[n],q=p.Ga,m=Math.max(m,q.width);var m=Math.max(m+k,1),r=Math.max(this.al.height,1);if(!isFinite(r))for(n=r=0;n<c.length;n++)p=c[n],q=p.Ga,r=Math.max(r,q.height);for(var r=Math.max(r+l,1),s=this.Qe,u=this.Fd.x,v=u,D=this.Fd.y,A=0,G=0,n=0;n<c.length;n++){var p=c[n],q=p.Ga,K=Math.ceil((q.width+k)/m)*m,N=Math.ceil((q.height+l)/r)*r,O;switch(s){case kl:O=Math.abs(v-q.width);break;default:O=v+q.width}if(0<f&&A>f-1||0<
g&&0<A&&O>g)h.push(new x(0,D,g,G+l)),A=0,v=u,D+=G,G=0;G=Math.max(G,N);switch(s){case kl:q=-q.width;break;default:q=0}p.moveTo(v+q,D);switch(s){case kl:v-=K;break;default:v+=K}A++}h.push(new x(0,D,g,G+l));break;case ll:k=a;l=d;m=Math.max(this.al.width,1);n=A=q=0;p=t.K();for(r=0;r<c.length;r++)s=c[r],u=s.Ga,v=Cm(s,s.kc,s.bf,p),q=Math.max(q,v.x),A=Math.max(A,u.width-v.x),n=Math.max(n,v.y);D=this.Qe;switch(D){case kl:q+=k;break;default:A+=k}var m=isFinite(m)?Math.max(m+k,1):Math.max(q+A,1),U=A=this.Fd.x,
G=this.Fd.y,K=0;g>=q&&(g-=q);q=0;N=Math.max(this.al.height,1);O=n=0;for(var X=!0,Z=t.K(),r=0;r<c.length;r++){s=c[r];u=s.Ga;v=Cm(s,s.kc,s.bf,p);if(0<K)switch(D){case kl:U=Math.floor((U-A-(u.width-v.x))/m)*m+A;break;default:U=Math.ceil((U-A+v.x)/m)*m+A}else switch(D){case kl:q=U+v.x+u.width;break;default:q=U-v.x}var P;switch(D){case kl:P=-(U+v.x)+q;break;default:P=U+u.width-v.x-q}if(0<f&&K>f-1||0<g&&0<K&&P>g){h.push(new x(0,G,g,(X?O:O+n)+l));for(U=0;U<K&&r!==K;U++){P=c[r-K+U];var pa=Cm(P,P.kc,P.bf,
Z);P.moveTo(P.position.x,P.position.y+n-pa.y)}O+=l;G=X?G+O:G+(O+n);K=O=n=0;U=A;X=!1}n=Math.max(n,v.y);O=Math.max(O,u.height-v.y);isFinite(N)&&(O=Math.max(O,Math.max(u.height,N)-v.y));X?s.moveTo(U-v.x,G-v.y):s.moveTo(U-v.x,G);switch(D){case kl:U-=v.x+k;break;default:U+=u.width-v.x+k}K++}h.push(new x(0,G,g,(X?O:O+n)+l));for(U=0;U<K&&r!==K;U++)P=c[r-K+U],pa=Cm(P,P.kc,P.bf,p),P.moveTo(P.position.x,P.position.y+n-pa.y);t.B(p);t.B(Z)}this.commitLayers(h,new w(-a/2,-d/2));null!==b&&b.Ce("Layout");this.$e=
!0}};ck.prototype.commitLayers=function(){};t.g(ck,"wrappingWidth",ck.prototype.qB);t.defineProperty(ck,{qB:"wrappingWidth"},function(){return this.Is},function(a){this.Is!==a&&(t.i(a,"number",ck,"wrappingWidth"),0<a||isNaN(a))&&(this.Is=a,this.st=isNaN(a),this.J())});t.g(ck,"wrappingColumn",ck.prototype.ZG);t.defineProperty(ck,{ZG:"wrappingColumn"},function(){return this.Hs},function(a){this.Hs!==a&&(t.i(a,"number",ck,"wrappingColumn"),0<a||isNaN(a))&&(this.Hs=a,this.J())});t.g(ck,"cellSize",ck.prototype.al);
t.defineProperty(ck,{al:"cellSize"},function(){return this.pj},function(a){t.l(a,fa,ck,"cellSize");this.pj.M(a)||(this.pj.assign(a),this.J())});t.g(ck,"spacing",ck.prototype.spacing);t.defineProperty(ck,{spacing:"spacing"},function(){return this.Nh},function(a){t.l(a,fa,ck,"spacing");this.Nh.M(a)||(this.Nh.assign(a),this.J())});t.g(ck,"alignment",ck.prototype.alignment);
t.defineProperty(ck,{alignment:"alignment"},function(){return this.ue},function(a){this.ue!==a&&(t.Aa(a,ck,ck,"alignment"),a===ll||a===ml)&&(this.ue=a,this.J())});t.g(ck,"arrangement",ck.prototype.Qe);t.defineProperty(ck,{Qe:"arrangement"},function(){return this.cd},function(a){this.cd!==a&&(t.Aa(a,ck,ck,"arrangement"),a===jl||a===kl)&&(this.cd=a,this.J())});t.g(ck,"sorting",ck.prototype.sorting);
t.defineProperty(ck,{sorting:"sorting"},function(){return this.Mh},function(a){this.Mh!==a&&(t.Aa(a,ck,ck,"sorting"),a===hl||a===il||a===fl||a===gl)&&(this.Mh=a,this.J())});t.g(ck,"comparer",ck.prototype.comparer);t.defineProperty(ck,{comparer:"comparer"},function(){return this.th},function(a){this.th!==a&&(t.i(a,"function",ck,"comparer"),this.th=a,this.J())});var Vn;
ck.standardComparer=Vn=function(a,b){e&&t.l(a,C,ck,"standardComparer:a");e&&t.l(b,C,ck,"standardComparer:b");var c=a.text,d=b.text;return c<d?-1:c>d?1:0};
ck.smartComparer=function(a,b){e&&t.l(a,C,ck,"standardComparer:a");e&&t.l(b,C,ck,"standardComparer:b");if(null!==a){if(null!==b){var c=a.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),d=b.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/),f;for(f=0;f<c.length;f++)if(""!==d[f]&&void 0!==d[f]){var g=parseFloat(c[f]),h=parseFloat(d[f]);if(isNaN(g)){if(!isNaN(h))return 1;if(0!==c[f].localeCompare(d[f]))return c[f].localeCompare(d[f])}else{if(isNaN(h))return-1;
if(0!==g-h)return g-h}}else if(""!==c[f])return 1;return""!==d[f]&&void 0!==d[f]?-1:0}return 1}return null!==b?-1:0};var ml;ck.Position=ml=t.w(ck,"Position",0);var ll;ck.Location=ll=t.w(ck,"Location",1);var jl;ck.LeftToRight=jl=t.w(ck,"LeftToRight",2);var kl;ck.RightToLeft=kl=t.w(ck,"RightToLeft",3);var hl;ck.Forward=hl=t.w(ck,"Forward",4);var il;ck.Reverse=il=t.w(ck,"Reverse",5);var fl;ck.Ascending=fl=t.w(ck,"Ascending",6);var gl;ck.Descending=gl=t.w(ck,"Descending",7);
function nl(){0<arguments.length&&t.ad(nl);be.call(this);this.Ny=this.Nn=this.Ad=0;this.Jq=360;this.My=El;this.Bk=0;this.eC=El;this.uu=this.tg=this.wD=0;this.Xv=new Wn;this.xu=this.mm=0;this.sH=600;this.Rr=NaN;this.pq=1;this.ss=0;this.us=360;this.cd=El;this.sa=vl;this.Mh=sl;this.th=Un;this.Nh=6;this.Ir=Hl}t.ga("CircularLayout",nl);t.Ka(nl,be);
nl.prototype.cloneProtected=function(a){be.prototype.cloneProtected.call(this,a);a.Rr=this.Rr;a.pq=this.pq;a.ss=this.ss;a.us=this.us;a.cd=this.cd;a.sa=this.sa;a.Mh=this.Mh;a.th=this.th;a.Nh=this.Nh;a.Ir=this.Ir};nl.prototype.createNetwork=function(){var a=new Xn;On(a,this);return a};
nl.prototype.doLayout=function(a){a||t.m("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));a=this.network.vertexes;if(1>=a.count)1===a.count&&(a=a.$a(),a.Da=0,a.Oa=0);else{var b=new B(Yn);b.Oe(a.k);a=new B(Yn);var c=new B(Yn),b=this.sort(b);a=new B(Yn);var c=new B(Yn),d=this.My,f=this.eC,g=this.Ad,h=this.Nn,k=this.Ny,l=this.Jq,m=this.Bk,n=this.wD,p=this.tg,q=this.uu,d=this.Qe,f=this.Bt,g=
this.KF;if(!isFinite(g)||0>=g)g=NaN;h=this.ND;if(!isFinite(h)||0>=h)h=1;k=this.mh;isFinite(k)||(k=0);l=this.gi;if(!isFinite(l)||360<l||1>l)l=360;m=this.spacing;isFinite(m)||(m=NaN);d===Fl&&f===Gl?d=El:d===Fl&&f!==Gl&&(f=Gl,d=this.Qe);if((this.direction===tl||this.direction===ul)&&this.sorting!==sl){for(var r=0;!(r>=b.length);r+=2){a.add(b.wa(r));if(r+1>=b.length)break;c.add(b.wa(r+1))}this.direction===tl?(this.Qe===Fl&&a.reverse(),b=new B(Yn),b.Oe(a),b.Oe(c)):(this.Qe===Fl&&c.reverse(),b=new B(Yn),
b.Oe(c),b.Oe(a))}for(var s=b.length,u=n=0,r=0;r<b.length;r++){var p=k+l*u*(this.direction===vl?1:-1)/s,v=b.wa(r).diameter;isNaN(v)&&(v=Zn(b.wa(r),p));360>l&&(0===r||r===b.length-1)&&(v/=2);n+=v;u++}if(isNaN(g)||d===Fl){isNaN(m)&&(m=6);if(d!==El&&d!==Fl){v=-Infinity;for(r=0;r<s;r++){var q=b.wa(r),D=b.wa(r===s-1?0:r+1);isNaN(q.diameter)&&Zn(q,0);isNaN(D.diameter)&&Zn(D,0);v=Math.max(v,(q.diameter+D.diameter)/2)}q=v+m;d===xl?(p=2*Math.PI/s,g=(v+m)/p):g=$n(this,q*(360<=l?s:s-1),h,k*Math.PI/180,l*Math.PI/
180)}else g=$n(this,n+(360<=l?s:s-1)*(d!==Fl?m:1.6*m),h,k*Math.PI/180,l*Math.PI/180);p=g*h}else if(p=g*h,u=ao(this,g,p,k*Math.PI/180,l*Math.PI/180),isNaN(m)){if(d===El||d===Fl)m=(u-n)/(360<=l?s:s-1)}else if(d===El||d===Fl)r=(u-n)/(360<=l?s:s-1),r<m?(g=$n(this,n+m*(360<=l?s:s-1),h,k*Math.PI/180,l*Math.PI/180),p=g*h):m=r;else{v=-Infinity;for(r=0;r<s;r++)q=b.wa(r),D=b.wa(r===s-1?0:r+1),isNaN(q.diameter)&&Zn(q,0),isNaN(D.diameter)&&Zn(D,0),v=Math.max(v,(q.diameter+D.diameter)/2);q=v+m;r=$n(this,q*(360<=
l?s:s-1),h,k*Math.PI/180,l*Math.PI/180);r>g?(g=r,p=g*h):q=u/(360<=l?s:s-1)}this.My=d;this.eC=f;this.Ad=g;this.Nn=h;this.Ny=k;this.Jq=l;this.Bk=m;this.wD=n;this.tg=p;this.uu=q;c=[b,a,c];b=c[0];a=c[1];c=c[2];d=this.My;f=this.Ad;h=this.Ny;k=this.Jq;l=this.Bk;m=this.tg;n=this.uu;if(this.direction!==tl&&this.direction!==ul||d!==Fl)if(this.direction===tl||this.direction===ul){g=0;switch(d){case Dl:g=180*bo(this,f,m,h,n)/Math.PI;break;case El:n=b=0;g=a.$a();null!==g&&(b=Zn(g,Math.PI/2));g=c.$a();null!==
g&&(n=Zn(g,Math.PI/2));g=180*bo(this,f,m,h,l+(b+n)/2)/Math.PI;break;case xl:g=k/b.length}if(this.direction===tl){switch(d){case Dl:co(this,a,h,wl);break;case El:eo(this,a,h,wl);break;case xl:fo(this,a,k/2,h,wl)}switch(d){case Dl:co(this,c,h+g,vl);break;case El:eo(this,c,h+g,vl);break;case xl:fo(this,c,k/2,h+g,vl)}}else{switch(d){case Dl:co(this,c,h,wl);break;case El:eo(this,c,h,wl);break;case xl:fo(this,c,k/2,h,wl)}switch(d){case Dl:co(this,a,h+g,vl);break;case El:eo(this,a,h+g,vl);break;case xl:fo(this,
a,k/2,h+g,vl)}}}else switch(d){case Dl:co(this,b,h,this.direction);break;case El:eo(this,b,h,this.direction);break;case xl:fo(this,b,k,h,this.direction);break;case Fl:ho(this,b,k,h,this.direction)}else ho(this,b,k,h-k/2,vl)}this.updateParts();this.network=null;this.$e=!0};
function fo(a,b,c,d,f){var g=a.Jq,h=a.Ad;a=a.tg;d=d*Math.PI/180;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=d+(f===vl?l*c/(360<=g?k:k-1):-(l*c)/k),n=b.wa(l),p=h*Math.tan(m)/a,p=Math.sqrt((h*h+a*a*p*p)/(1+p*p));n.Da=p*Math.cos(m);n.Oa=p*Math.sin(m);n.actualAngle=180*m/Math.PI}}
function eo(a,b,c,d){var f=a.Ad,g=a.tg,h=a.Bk;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=b.wa(l),n=b.wa(l===k-1?0:l+1),p=g*Math.sin(c);m.Da=f*Math.cos(c);m.Oa=p;m.actualAngle=180*c/Math.PI;isNaN(m.diameter)&&Zn(m,0);isNaN(n.diameter)&&Zn(n,0);m=bo(a,f,g,d===vl?c:-c,(m.diameter+n.diameter)/2+h);c+=d===vl?m:-m}}
function co(a,b,c,d){var f=a.Ad,g=a.tg,h=a.uu;c=c*Math.PI/180;for(var k=b.length,l=0;l<k;l++){var m=b.wa(l);m.Da=f*Math.cos(c);m.Oa=g*Math.sin(c);m.actualAngle=180*c/Math.PI;m=bo(a,f,g,d===vl?c:-c,h);c+=d===vl?m:-m}}function ho(a,b,c,d,f){var g=a.xu,g=a.Jq;a.mm=0;a.Xv=new Wn;if(360>c){for(g=d+(f===vl?g:-g);0>g;)g+=360;g%=360;180<g&&(g-=360);g*=Math.PI/180;a.xu=g;io(a,b,c,d,f)}else jo(a,b,c,d,f);a.Xv.commit(b)}
function jo(a,b,c,d,f){var g=a.Ad,h=a.Bk,k=a.Nn,l=g*Math.cos(d*Math.PI/180),m=a.tg*Math.sin(d*Math.PI/180),n=b.Ie();if(3===n.length)n[0].Da=g,n[0].Oa=0,n[1].Da=n[0].Da-n[0].width/2-n[1].width/2-h,n[1].y=n[0].y,n[2].Da=(n[0].Da+n[1].Da)/2,n[2].y=n[0].y-n[2].height-h;else if(4===n.length)n[0].Da=g,n[0].Oa=0,n[2].Da=-n[0].Da,n[2].Oa=n[0].Oa,n[1].Da=0,n[1].y=Math.min(n[0].y,n[2].y)-n[1].height-h,n[3].Da=0,n[3].y=Math.max(n[0].y+n[0].height+h,n[2].y+n[2].height+h);else{for(g=0;g<n.length;g++){n[g].Da=
l;n[g].Oa=m;if(g>=n.length-1)break;var p=lo(a,l,m,n,g,f);p[0]||(p=mo(a,l,m,n,g,f));l=p[1];m=p[2]}a.mm++;if(!(23<a.mm)){var l=n[0].Da,m=n[0].Oa,g=n[n.length-1].Da,p=n[n.length-1].Oa,q=Math.abs(l-g)-((n[0].width+n[n.length-1].width)/2+h),r=Math.abs(m-p)-((n[0].height+n[n.length-1].height)/2+h),h=0;1>Math.abs(r)?Math.abs(l-g)<(n[0].width+n[n.length-1].width)/2&&(h=0):h=0<r?r:1>Math.abs(q)?0:q;q=!1;q=Math.abs(g)>Math.abs(p)?0<g!==m>p:0<p!==l<g;if(q=f===vl?q:!q)h=-Math.abs(h),h=Math.min(h,-n[n.length-
1].width),h=Math.min(h,-n[n.length-1].height);l=a.Xv;if(0<h&&0>l.jp||Math.abs(h)<Math.abs(l.jp)&&!(0>h&&0<l.jp))for(l.jp=h,l.qn=[],l.$p=[],m=0;m<n.length;m++)l.qn[m]=n[m].Pb.x,l.$p[m]=n[m].Pb.y;1<Math.abs(h)&&(a.Ad=8>a.mm?a.Ad-h/(2*Math.PI):5>n.length&&10<h?a.Ad/2:a.Ad-(0<h?1.7:-2.3),a.tg=a.Ad*k,jo(a,b,c,d,f))}}}
function io(a,b,c,d,f){for(var g=a.Ad,h=a.tg,k=a.Nn,l=g*Math.cos(d*Math.PI/180),m=h*Math.sin(d*Math.PI/180),n=b.Ie(),p=0;p<n.length;p++){n[p].Da=l;n[p].Oa=m;if(p>=n.length-1)break;var q=lo(a,l,m,n,p,f);q[0]||(q=mo(a,l,m,n,p,f));l=q[1];m=q[2]}a.mm++;if(!(23<a.mm)){l=Math.atan2(m,l);l=f===vl?a.xu-l:l-a.xu;l=Math.abs(l)<Math.abs(l-2*Math.PI)?l:l-2*Math.PI;g=l*(g+h)/2;h=a.Xv;if(Math.abs(g)<Math.abs(h.jp))for(h.jp=g,h.qn=[],h.$p=[],l=0;l<n.length;l++)h.qn[l]=n[l].Pb.x,h.$p[l]=n[l].Pb.y;1<Math.abs(g)&&
(a.Ad=8>a.mm?a.Ad-g/(2*Math.PI):a.Ad-(0<g?1.7:-2.3),a.tg=a.Ad*k,io(a,b,c,d,f))}}function lo(a,b,c,d,f,g){var h=a.Ad,k=a.tg,l=0,m=0;a=(d[f].width+d[f+1].width)/2+a.Bk;var n=!1;if(0<=c!==(g===vl)){if(l=b+a,l>h){l=b-a;if(l<-h)return b=[!1],b[1]=l,b[2]=m,b;n=!0}}else if(l=b-a,l<-h){l=b+a;if(l>h)return b=[!1],b[1]=l,b[2]=m,b;n=!0}m=Math.sqrt(1-Math.min(1,l*l/(h*h)))*k;0>c!==n&&(m=-m);b=Math.abs(c-m)>(d[f].height+d[f+1].height)/2?[!1]:[!0];b[1]=l;b[2]=m;return b}
function mo(a,b,c,d,f,g){var h=a.Ad,k=a.tg,l=0,m=0;a=(d[f].height+d[f+1].height)/2+a.Bk;var n=!1;if(0<=b!==(g===vl)){if(m=c-a,m<-k){m=c+a;if(m>k)return b=[!1],b[1]=l,b[2]=m,b;n=!0}}else if(m=c+a,m>k){m=c-a;if(m<-k)return b=[!1],b[1]=l,b[2]=m,b;n=!0}l=Math.sqrt(1-Math.min(1,m*m/(k*k)))*h;0>b!==n&&(l=-l);b=Math.abs(b-l)>(d[f].width+d[f+1].width)/2?[!1]:[!0];b[1]=l;b[2]=m;return b}function Wn(){this.jp=-Infinity;this.$p=this.qn=null}
Wn.prototype.commit=function(a){if(null!==this.qn&&null!==this.$p)for(var b=0;b<this.qn.length;b++){var c=a.wa(b);c.x=this.qn[b];c.y=this.$p[b]}};nl.prototype.commitLayout=function(){this.commitNodes();this.rt&&this.commitLinks()};nl.prototype.commitNodes=function(){for(var a=this.AH,b=this.network.vertexes.k,c;b.next();)c=b.value,c.x+=a.x,c.y+=a.y,c.commit()};nl.prototype.commitLinks=function(){for(var a=this.network.edges.k;a.next();)a.value.commit()};
function ao(a,b,c,d,f){var g=a.sH;if(0.001>Math.abs(a.Nn-1))return void 0!==d&&void 0!==f?f*b:2*Math.PI*b;a=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c;var h=0,k;k=void 0!==d&&void 0!==f?f/(g+1):Math.PI/(2*(g+1));for(var l=0;l<=g;l++)var m=Math.sin(void 0!==d&&void 0!==f?d+l*f/g:l*Math.PI/(2*g)),h=h+Math.sqrt(1-a*a*m*m)*k;return void 0!==d&&void 0!==f?(b>c?b:c)*h:4*(b>c?b:c)*h}function $n(a,b,c,d,f){a=void 0!==d&&void 0!==f?ao(a,1,c,d,f):ao(a,1,c);return b/a}
function bo(a,b,c,d,f){if(0.001>Math.abs(a.Nn-1))return f/b;var g=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c,h=0;a=2*Math.PI/(700*a.network.vertexes.count);b>c&&(d+=Math.PI/2);for(var k=0;;k++){var l=Math.sin(d+k*a),h=h+(b>c?b:c)*Math.sqrt(1-g*g*l*l)*a;if(h>=f)return k*a}return 0}
nl.prototype.sort=function(a){switch(this.sorting){case ql:break;case rl:a.reverse();break;case ol:a.sort(this.comparer);break;case pl:a.sort(this.comparer);a.reverse();break;case sl:for(var b=[],c=0;c<a.length;c++)b.push(0);for(var d=new B(Yn),c=0;c<a.length;c++){var f=-1,g=-1;if(0===c)for(var h=0;h<a.length;h++){var k=a.wa(h).DI;k>f&&(f=k,g=h)}else for(h=0;h<a.length;h++)k=b[h],k>f&&(f=k,g=h);d.add(a.wa(g));b[g]=-1;g=a.wa(g);f=g.tc;for(g=g.gc;f.next();)h=f.value,h=h.fromVertex,h=a.indexOf(h),0>
h||0<=b[h]&&b[h]++;for(;g.next();)h=g.value,h=h.toVertex,h=a.indexOf(h),0>h||0<=b[h]&&b[h]++}a=[];for(b=0;b<d.length;b++){k=d.wa(b);a[b]=[];for(var c=k.gc,f=k.tc,l,m;c.next();)l=c.value,m=d.indexOf(l.toVertex),m!==b&&0>a[b].indexOf(m)&&a[b].push(m);for(;f.next();)l=f.value,m=d.indexOf(l.fromVertex),m!==b&&0>a[b].indexOf(m)&&a[b].push(m)}k=[];for(b=0;b<a.length;b++)k[b]=0;for(var c=[],n=[],p=[],f=[],g=new B(Yn),h=b=0;b<a.length;b++){var q=a[b].length;if(1===q)f.push(b);else if(0===q)g.add(d.wa(b));
else{if(0===h)c.push(b);else{for(var r=Infinity,s=Infinity,u=-1,v=[],q=0;q<c.length;q++)0>a[c[q]].indexOf(c[q===c.length-1?0:q+1])&&v.push(q===c.length-1?0:q+1);if(0===v.length)for(q=0;q<c.length;q++)v.push(q);for(q=0;q<v.length;q++){var D=v[q],A;m=a[b];l=n;A=p;for(var G=k,K=D,N=c,O=0,U=0;U<l.length;U++){var X=G[l[U]],Z=G[A[U]],P=0,pa=0;X<Z?(P=X,pa=Z):(P=Z,pa=X);if(P<K&&K<=pa)for(X=0;X<m.length;X++)Z=m[X],0>N.indexOf(Z)||P<G[Z]&&G[Z]<pa||P===G[Z]||pa===G[Z]||O++;else for(X=0;X<m.length;X++)Z=m[X],
0>N.indexOf(Z)||P<G[Z]&&G[Z]<pa&&P!==G[Z]&&pa!==G[Z]&&O++}A=O;for(l=G=0;l<a[b].length;l++)m=c.indexOf(a[b][l]),0<=m&&(m=Math.abs(D-(m>=D?m+1:m)),G+=m<c.length+1-m?m:c.length+1-m);for(l=0;l<n.length;l++)m=k[n[l]],K=k[p[l]],m>=D&&m++,K>=D&&K++,m>K&&(N=K,K=m,m=N),K-m<(c.length+2)/2===(m<D&&D<=K)&&G++;if(A<r||A===r&&G<s)r=A,s=G,u=D}c.splice(u,0,b);for(q=0;q<c.length;q++)k[c[q]]=q;for(q=0;q<a[b].length;q++)r=a[b][q],0<=c.indexOf(r)&&(n.push(b),p.push(r))}h++}}n=!1;for(p=c.length;;){n=!0;for(k=0;k<f.length;k++)if(b=
f[k],q=a[b][0],m=c.indexOf(q),0<=m){for(h=s=0;h<a[q].length;h++)r=a[q][h],r=c.indexOf(r),0>r||r===m||(u=r>m?r-m:m-r,s+=r<m!==u>p-u?1:-1);c.splice(0>s?m:m+1,0,b);f.splice(k,1);k--}else n=!1;if(n)break;else c.push(f[0]),f.splice(0,1)}for(b=0;b<c.length;b++)m=c[b],g.add(d.wa(m));return g;default:t.m("Invalid sorting type.")}return a};t.g(nl,"radius",nl.prototype.KF);
t.defineProperty(nl,{KF:"radius"},function(){return this.Rr},function(a){this.Rr!==a&&(t.i(a,"number",nl,"radius"),0<a||isNaN(a))&&(this.Rr=a,this.J())});t.g(nl,"aspectRatio",nl.prototype.ND);t.defineProperty(nl,{ND:"aspectRatio"},function(){return this.pq},function(a){this.pq!==a&&(t.i(a,"number",nl,"aspectRatio"),0<a&&(this.pq=a,this.J()))});t.g(nl,"startAngle",nl.prototype.mh);
t.defineProperty(nl,{mh:"startAngle"},function(){return this.ss},function(a){this.ss!==a&&(t.i(a,"number",nl,"startAngle"),this.ss=a,this.J())});t.g(nl,"sweepAngle",nl.prototype.gi);t.defineProperty(nl,{gi:"sweepAngle"},function(){return this.us},function(a){this.us!==a&&(t.i(a,"number",nl,"sweepAngle"),this.us=0<a&&360>=a?a:360,this.J())});t.g(nl,"arrangement",nl.prototype.Qe);
t.defineProperty(nl,{Qe:"arrangement"},function(){return this.cd},function(a){this.cd!==a&&(t.Aa(a,nl,nl,"arrangement"),a===Fl||a===El||a===Dl||a===xl)&&(this.cd=a,this.J())});t.g(nl,"direction",nl.prototype.direction);t.defineProperty(nl,{direction:"direction"},function(){return this.sa},function(a){this.sa!==a&&(t.Aa(a,nl,nl,"direction"),a===vl||a===wl||a===tl||a===ul)&&(this.sa=a,this.J())});t.g(nl,"sorting",nl.prototype.sorting);
t.defineProperty(nl,{sorting:"sorting"},function(){return this.Mh},function(a){this.Mh!==a&&(t.Aa(a,nl,nl,"sorting"),a===ql||a===rl||a===ol||pl||a===sl)&&(this.Mh=a,this.J())});t.g(nl,"comparer",nl.prototype.comparer);t.defineProperty(nl,{comparer:"comparer"},function(){return this.th},function(a){this.th!==a&&(t.i(a,"function",nl,"comparer"),this.th=a,this.J())});t.g(nl,"spacing",nl.prototype.spacing);
t.defineProperty(nl,{spacing:"spacing"},function(){return this.Nh},function(a){this.Nh!==a&&(t.i(a,"number",nl,"spacing"),this.Nh=a,this.J())});t.g(nl,"nodeDiameterFormula",nl.prototype.Bt);t.defineProperty(nl,{Bt:"nodeDiameterFormula"},function(){return this.Ir},function(a){this.Ir!==a&&(t.Aa(a,nl,nl,"nodeDiameterFormula"),a===Hl||a===Gl)&&(this.Ir=a,this.J())});t.A(nl,{BH:"actualXRadius"},function(){return this.Ad});t.A(nl,{CH:"actualYRadius"},function(){return this.tg});
t.A(nl,{eK:"actualSpacing"},function(){return this.Bk});t.A(nl,{AH:"actualCenter"},function(){return isNaN(this.Fd.x)||isNaN(this.Fd.y)?new w(0,0):new w(this.Fd.x+this.BH,this.Fd.y+this.CH)});var El;nl.ConstantSpacing=El=t.w(nl,"ConstantSpacing",0);var Dl;nl.ConstantDistance=Dl=t.w(nl,"ConstantDistance",1);var xl;nl.ConstantAngle=xl=t.w(nl,"ConstantAngle",2);var Fl;nl.Packed=Fl=t.w(nl,"Packed",3);var vl;nl.Clockwise=vl=t.w(nl,"Clockwise",4);var wl;
nl.Counterclockwise=wl=t.w(nl,"Counterclockwise",5);var tl;nl.BidirectionalLeft=tl=t.w(nl,"BidirectionalLeft",6);var ul;nl.BidirectionalRight=ul=t.w(nl,"BidirectionalRight",7);var ql;nl.Forwards=ql=t.w(nl,"Forwards",8);var rl;nl.Reverse=rl=t.w(nl,"Reverse",9);var ol;nl.Ascending=ol=t.w(nl,"Ascending",10);var pl;nl.Descending=pl=t.w(nl,"Descending",11);var sl;nl.Optimized=sl=t.w(nl,"Optimized",12);var Hl;nl.Pythagorean=Hl=t.w(nl,"Pythagorean",13);var Gl;nl.Circular=Gl=t.w(nl,"Circular",14);
function Xn(){ta.call(this)}t.ga("CircularNetwork",Xn);t.Ka(Xn,ta);Xn.prototype.createVertex=function(){return new Yn};Xn.prototype.createEdge=function(){return new no};function Yn(){ua.call(this);this.actualAngle=this.diameter=NaN}t.ga("CircularVertex",Yn);t.Ka(Yn,ua);
function Zn(a,b){var c=a.network;if(null===c)return NaN;c=c.jc;if(null===c)return NaN;if(c.Qe===Fl)if(c.Bt===Gl)a.diameter=Math.max(a.width,a.height);else{var c=Math.abs(Math.sin(b)),d=Math.abs(Math.cos(b));if(0===c)return a.width;if(0===d)return a.height;a.diameter=Math.min(a.height/c,a.width/d)}else a.diameter=c.Bt===Gl?Math.max(a.width,a.height):Math.sqrt(a.width*a.width+a.height*a.height);return a.diameter}function no(){va.call(this)}t.ga("CircularEdge",no);t.Ka(no,va);
function oo(){0<arguments.length&&t.ad(oo);be.call(this);this.sg=null;this.ir=0;this.bg=(new fa(100,100)).freeze();this.oq=!1;this.Lh=!0;this.sh=!1;this.oo=100;this.Lq=1;this.Fh=1E3;this.In=0.05;this.Hn=50;this.En=150;this.Gn=0;this.Fq=10;this.Eq=5}t.ga("ForceDirectedLayout",oo);t.Ka(oo,be);
oo.prototype.cloneProtected=function(a){be.prototype.cloneProtected.call(this,a);a.bg.assign(this.bg);a.oq=this.oq;a.Lh=this.Lh;a.sh=this.sh;a.oo=this.oo;a.Lq=this.Lq;a.Fh=this.Fh;a.In=this.In;a.Hn=this.Hn;a.En=this.En;a.Gn=this.Gn;a.Fq=this.Fq;a.Eq=this.Eq};oo.prototype.createNetwork=function(){var a=new po;On(a,this);return a};
oo.prototype.doLayout=function(a){a||t.m("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));a=this.ex;if(0<this.network.vertexes.count){this.network.xw();for(var b=this.network.vertexes.k;b.next();){var c=b.value;c.charge=this.electricalCharge(c);c.mass=this.gravitationalMass(c)}for(b=this.network.edges.k;b.next();)c=b.value,c.stiffness=this.springStiffness(c),c.length=this.springLength(c);
this.Mz();this.ir=0;if(this.needsClusterLayout()){for(var b=this.network,d=b.VJ().k;d.next();){var f=d.value;this.network=f;for(c=this.network.vertexes.k;c.next();){for(var f=c.value,g=0,h=f.vertexes;h.next();)g++;f.Xf=g;f.gk=1;f.Jm=null;f.kh=null}qo(this,0,a)}this.network=b;d.reset();e&&t.l(b,po,oo,"arrangeConnectedGraphs:singletons");for(var c=this.fw,k=d.count,l=!0,g=f=0,h=t.Db(),m=0;m<k+b.vertexes.count+2;m++)h[m]=null;k=0;d.reset();for(m=t.Gf();d.next();){var n=d.value;this.qf(n,m);if(l)l=!1,
f=m.x+m.width/2,g=m.y+m.height/2,h[0]=new w(m.x+m.width+c.width,m.y),h[1]=new w(m.x,m.y+m.height+c.height),k=2;else{var p=ro(h,k,f,g,m.width,m.height,c),q=h[p],r=new w(q.x+m.width+c.width,q.y),s=new w(q.x,q.y+m.height+c.height);p+1<k&&h.splice(p+1,0,null);h[p]=r;h[p+1]=s;k++;p=q.x-m.x;q=q.y-m.y;for(n=n.vertexes.k;n.next();){var u=n.value;u.Da+=p;u.Oa+=q}}}t.hc(m);for(n=b.vertexes.k;n.next();)u=n.value,l=u.T,2>k?(f=l.x+l.width/2,g=l.y+l.height/2,h[0]=new w(l.x+l.width+c.width,l.y),h[1]=new w(l.x,l.y+
l.height+c.height),k=2):(p=ro(h,k,f,g,l.width,l.height,c),q=h[p],r=new w(q.x+l.width+c.width,q.y),s=new w(q.x,q.y+l.height+c.height),p+1<k&&h.splice(p+1,0,null),h[p]=r,h[p+1]=s,k++,u.Da=q.x+u.width/2,u.Oa=q.y+u.height/2);t.ya(h);for(d.reset();d.next();){f=d.value;for(g=f.vertexes.k;g.next();)c=g.value,b.Yk(c);for(f=f.edges.k;f.next();)c=f.value,b.Jo(c)}}so(this,a);this.updateParts()}this.oo=a;this.network=null;this.$e=!0};
oo.prototype.needsClusterLayout=function(){if(3>this.network.vertexes.count)return!1;var a=0,b=0,c=this.network.vertexes.k;c.next();for(var d=c.value.T;c.next();){if(c.value.T.Tf(d)&&(a++,1<a))return!0;if(10<b)break;b++}return!1};oo.prototype.qf=function(a,b){for(var c=!0,d=a.vertexes.k;d.next();){var f=d.value;c?(c=!1,b.set(f.T)):b.ii(f.T)}return b};
function to(a,b,c){e&&(t.q(b,oo,"computeClusterLayoutIterations:level"),t.q(c,oo,"computeClusterLayoutIterations:maxiter"));return Math.max(Math.min(a.network.vertexes.count,c*(b+1)/11),10)}
function qo(a,b,c){e&&(t.q(b,oo,"layoutClusters:level"),t.q(c,oo,"layoutClusters:maxiter"));if(uo(a,b)){var d=a.Fh;a.Fh*=1+1/(b+1);var f=vo(a,b),g=Math.max(0,to(a,b,c));a.ex+=g;qo(a,b+1,c);so(a,g);wo(a,f,b);c=a.sg;null===c?c=new B(xo):c.clear();c.Oe(f.vertexes);c.sort(function(a,b){return null===a||null===b||a===b?0:b.Xf-a.Xf});for(f=c.k;f.next();)yo(a,f.value,b);a.Fh=d}}
function uo(a,b){e&&t.q(b,oo,"hasClusters:level");if(10<b||3>a.network.vertexes.count)return!1;null===a.sg?a.sg=new B(xo):a.sg.clear();a.sg.Oe(a.network.vertexes);var c=a.sg;c.sort(function(a,b){return null===a||null===b||a===b?0:b.Xf-a.Xf});for(var d=c.count-1;0<=d&&1>=c.n[d].Xf;)d--;return 1<c.count-d}
function vo(a,b){e&&t.q(b,oo,"pushSubNetwork:level");for(var c=a.network,d=new po,f=a.sg.k;f.next();){var g=f.value;if(1<g.Xf){d.Yk(g);var h={vB:g.Xf,xB:g.width,uB:g.height,$G:g.O.x,aH:g.O.y};null===g.kh&&(g.kh=new B);g.kh.add(h);g.XA=g.kh.count-1}else break}for(var k=c.edges.k;k.next();){var l=k.value;if(l.fromVertex.network===d&&l.toVertex.network===d)d.Jo(l);else if(l.fromVertex.network===d){var m=l.fromVertex.Jm;null===m&&(m=new B(xo),l.fromVertex.Jm=m);m.add(l.toVertex);l.fromVertex.Xf--;l.fromVertex.gk+=
l.toVertex.gk}else l.toVertex.network===d&&(m=l.toVertex.Jm,null===m&&(m=new B(xo),l.toVertex.Jm=m),m.add(l.fromVertex),l.toVertex.Xf--,l.toVertex.gk+=l.fromVertex.gk)}for(k=d.edges.k;k.next();)l=k.value,l.length*=Math.max(1,F.sqrt((l.fromVertex.gk+l.toVertex.gk)/(4*b+1)));for(f=d.vertexes.k;f.next();)if(g=f.value,m=g.Jm,null!==m&&0<m.count&&(h=g.kh.n[g.kh.count-1],h=h.vB-g.Xf,!(0>=h))){for(var n=0,p=0,q=m.count-h;q<m.count;q++){for(var r=m.n[q],l=null,k=r.edges.k;k.next();){var s=k.value;if(s.II(r)===
g){l=s;break}}null!==l&&(p+=l.length,n+=r.width*r.height)}m=g.Da;k=g.Oa;l=g.width;q=g.height;r=g.O;s=l*q;1>s&&(s=1);n=F.sqrt((n+s+p*p*4/(h*h))/s);h=(n-1)*l/2;n=(n-1)*q/2;g.Pb=new x(m-r.x-h,k-r.y-n,l+2*h,q+2*n);g.focus=new w(r.x+h,r.y+n)}a.network=d;return c}
function wo(a,b,c){e&&(t.l(b,po,oo,"popNetwork:oldnet"),t.q(c,oo,"popNetwork:level"));for(c=a.network.vertexes.k;c.next();){var d=c.value;d.network=b;if(null!==d.kh){var f=d.kh.n[d.XA];d.Xf=f.vB;var g=f.$G,h=f.aH;d.Pb=new x(d.Da-g,d.Oa-h,f.xB,f.uB);d.focus=new w(g,h);d.XA--}}for(c=a.network.edges.k;c.next();)c.value.network=b;a.network=b}
function yo(a,b,c){e&&(t.l(b,xo,oo,"surroundNode:oldnet"),t.q(c,oo,"surroundNode:level"));var d=b.Jm;if(null!==d&&0!==d.count){c=b.Da;var f=b.Oa,g=b.width,h=b.height;null!==b.kh&&0<b.kh.count&&(h=b.kh.n[0],g=h.xB,h=h.uB);for(var g=F.sqrt(g*g+h*h)/2,k=!1,l=h=0,m=0,n=b.vertexes.k;n.next();){var p=n.value;1>=p.Xf?l++:(k=!0,m++,h+=Math.atan2(b.Oa-p.Oa,b.Da-p.Da))}if(0!==l)for(0<m&&(h/=m),m=b=0,b=k?2*Math.PI/(l+1):2*Math.PI/l,0===l%2&&(m=b/2),1<d.count&&d.sort(function(a,b){return null===a||null===b||
a===b?0:b.width*b.height-a.width*a.height}),k=0===l%2?0:1,n=d.k;n.next();)if(p=n.value,!(1<p.Xf||a.isFixed(p))){d=null;for(l=p.edges.k;l.next();){d=l.value;break}var l=p.width,q=p.height,l=F.sqrt(l*l+q*q)/2,d=g+d.length+l,l=h+(b*(k/2>>1)+m)*(0===k%2?1:-1);p.Da=c+d*Math.cos(l);p.Oa=f+d*Math.sin(l);k++}}}
function ro(a,b,c,d,f,g,h){var k=9E19,l=-1,m=0;a:for(;m<b;m++){var n=a[m],p=n.x-c,q=n.y-d,p=p*p+q*q;if(p<k){for(q=m-1;0<=q;q--)if(a[q].y>n.y&&a[q].x-n.x<f+h.width)continue a;for(q=m+1;q<b;q++)if(a[q].x>n.x&&a[q].y-n.y<g+h.height)continue a;l=m;k=p}}return l}oo.prototype.Mz=function(){if(this.comments)for(var a=this.network.vertexes.k;a.next();)this.addComments(a.value)};
oo.prototype.addComments=function(a){var b=a.Fc;if(null!==b)for(b=b.zE();b.next();){var c=b.value;if("Comment"===c.Zc&&c.La()){var d=this.network.Rm(c);null===d&&(d=this.network.Ko(c));d.charge=this.jE;for(var c=null,f=d.gc;f.next();)if(f.value.toVertex===a){c=f.value;break}if(null===c)for(f=d.tc;f.next();)if(f.value.fromVertex===a){c=f.value;break}null===c&&(c=this.network.an(a,d,null));c.length=this.kE}}};
function zo(a,b){e&&(t.l(a,xo,oo,"getNodeDistance:vertexA"),t.l(b,xo,oo,"getNodeDistance:vertexB"));var c=a.T,d=c.x,f=c.y,g=c.width,c=c.height,h=b.T,k=h.x,l=h.y,m=h.width,h=h.height;return d+g<k?f>l+h?(d=d+g-k,f=f-l-h,F.sqrt(d*d+f*f)):f+c<l?(d=d+g-k,f=f+c-l,F.sqrt(d*d+f*f)):k-(d+g):d>k+m?f>l+h?(d=d-k-m,f=f-l-h,F.sqrt(d*d+f*f)):f+c<l?(d=d-k-m,f=f+c-l,F.sqrt(d*d+f*f)):d-(k+m):f>l+h?f-(l+h):f+c<l?l-(f+c):0.1}
function so(a,b){e&&t.q(b,oo,"performIterations:num");a.sg=null;for(var c=a.ir+b;a.ir<c&&(a.ir++,Ao(a)););a.sg=null}
function Ao(a){null===a.sg&&(a.sg=new B(xo),a.sg.Oe(a.network.vertexes));var b=a.sg.n;if(0>=b.length)return!1;var c=b[0];c.forceX=0;c.forceY=0;for(var d=c.Da,f=d,g=c.Oa,h=g,c=1;c<b.length;c++){var k=b[c];k.forceX=0;k.forceY=0;var l=k.Da,k=k.Oa,d=Math.min(d,l),f=Math.max(f,l),g=Math.min(g,k),h=Math.max(h,k)}(g=f-d>h-g)?b.sort(function(a,b){return null===a||null===b||a===b?0:a.Da-b.Da}):b.sort(function(a,b){return null===a||null===b||a===b?0:a.Oa-b.Oa});for(var h=a.Fh,m=0,n=0,p=0,c=0;c<b.length;c++){var k=
b[c],l=k.T,q=k.O,d=l.x+q.x,l=l.y+q.y,n=k.charge*a.electricalFieldX(d,l),p=k.charge*a.electricalFieldY(d,l),n=n+k.mass*a.gravitationalFieldX(d,l),p=p+k.mass*a.gravitationalFieldY(d,l);k.forceX+=n;k.forceY+=p;for(q=c+1;q<b.length;q++)if(f=b[q],f!==k){var n=f.T,r=f.O,p=n.x+r.x,r=n.y+r.y;if(d-p>h||p-d>h){if(g)break}else if(l-r>h||r-l>h){if(!g)break}else{var s=zo(k,f);1>s?(d>p?(n=Math.abs(f.T.right-k.T.x),n=(1+n)*Math.random()):d<p?(n=Math.abs(f.T.x-k.T.right),n=-(1+n)*Math.random()):(n=Math.max(f.width,
k.width),n=(1+n)*Math.random()-n/2),l>r?(p=Math.abs(f.T.bottom-k.T.y),p=(1+p)*Math.random()):d<p?(p=Math.abs(f.T.y-k.T.bottom),p=-(1+p)*Math.random()):(p=Math.max(f.height,k.height),p=(1+p)*Math.random()-p/2)):(m=-(k.charge*f.charge)/(s*s),n=(p-d)/s*m,p=(r-l)/s*m);k.forceX+=n;k.forceY+=p;f.forceX-=n;f.forceY-=p}}}for(c=a.network.edges.k;c.next();)g=c.value,k=g.fromVertex,f=g.toVertex,l=k.T,q=k.O,d=l.x+q.x,l=l.y+q.y,n=f.T,r=f.O,p=n.x+r.x,r=n.y+r.y,s=zo(k,f),1>s?(n=(d>p?1:-1)*(1+(f.width>k.width)?f.width:
k.width)*Math.random(),p=(l>r?1:-1)*(1+(f.height>k.height)?f.height:k.height)*Math.random()):(m=g.stiffness*(s-g.length),n=(p-d)/s*m,p=(r-l)/s*m),k.forceX+=n,k.forceY+=p,f.forceX-=n,f.forceY-=p;c=0;d=Math.max(a.Fh/20,50);for(f=0;f<b.length;f++)k=b[f],a.isFixed(k)?a.moveFixedVertex(k):(g=k.forceX,h=k.forceY,g<-d?g=-d:g>d&&(g=d),h<-d?h=-d:h>d&&(h=d),k.Da+=g,k.Oa+=h,c=Math.max(c,g*g+h*h));return c>a.bA*a.bA}oo.prototype.moveFixedVertex=function(){};
oo.prototype.commitLayout=function(){this.bB();this.commitNodes();this.rt&&this.commitLinks()};oo.prototype.bB=function(){if(this.yl)for(var a=this.network.edges.k;a.next();){var b=a.value.link;null!==b&&(b.ob=xb,b.rb=xb)}};oo.prototype.commitNodes=function(){var a=0,b=0;if(this.LD){var c=t.Gf();this.qf(this.network,c);b=this.Fd;a=b.x-c.x;b=b.y-c.y;t.hc(c)}for(var c=t.Gf(),d=this.network.vertexes.k;d.next();){var f=d.value;if(0!==a||0!==b)c.assign(f.T),c.x+=a,c.y+=b,f.Pb=c;f.commit()}t.hc(c)};
oo.prototype.commitLinks=function(){for(var a=this.network.edges.k;a.next();)a.value.commit()};oo.prototype.springStiffness=function(a){a=a.stiffness;return isNaN(a)?this.In:a};oo.prototype.springLength=function(a){a=a.length;return isNaN(a)?this.Hn:a};oo.prototype.electricalCharge=function(a){a=a.charge;return isNaN(a)?this.En:a};oo.prototype.electricalFieldX=function(){return 0};oo.prototype.electricalFieldY=function(){return 0};
oo.prototype.gravitationalMass=function(a){a=a.mass;return isNaN(a)?this.Gn:a};oo.prototype.gravitationalFieldX=function(){return 0};oo.prototype.gravitationalFieldY=function(){return 0};oo.prototype.isFixed=function(a){return a.isFixed};t.A(oo,{gK:"currentIteration"},function(){return this.ir});t.g(oo,"arrangementSpacing",oo.prototype.fw);t.defineProperty(oo,{fw:"arrangementSpacing"},function(){return this.bg},function(a){t.l(a,fa,oo,"arrangementSpacing");this.bg.M(a)||(this.bg.assign(a),this.J())});
t.g(oo,"arrangesToOrigin",oo.prototype.LD);t.defineProperty(oo,{LD:"arrangesToOrigin"},function(){return this.oq},function(a){this.oq!==a&&(t.i(a,"boolean",oo,"arrangesToOrigin"),this.oq=a,this.J())});t.g(oo,"setsPortSpots",oo.prototype.yl);t.defineProperty(oo,{yl:"setsPortSpots"},function(){return this.Lh},function(a){this.Lh!==a&&(t.i(a,"boolean",oo,"setsPortSpots"),this.Lh=a,this.J())});t.g(oo,"comments",oo.prototype.comments);
t.defineProperty(oo,{comments:"comments"},function(){return this.sh},function(a){this.sh!==a&&(t.i(a,"boolean",oo,"comments"),this.sh=a,this.J())});t.g(oo,"maxIterations",oo.prototype.ex);t.defineProperty(oo,{ex:"maxIterations"},function(){return this.oo},function(a){this.oo!==a&&(t.i(a,"number",oo,"maxIterations"),0<=a&&(this.oo=a,this.J()))});t.g(oo,"epsilonDistance",oo.prototype.bA);
t.defineProperty(oo,{bA:"epsilonDistance"},function(){return this.Lq},function(a){this.Lq!==a&&(t.i(a,"number",oo,"epsilonDistance"),0<a&&(this.Lq=a,this.J()))});t.g(oo,"infinityDistance",oo.prototype.RI);t.defineProperty(oo,{RI:"infinityDistance"},function(){return this.Fh},function(a){this.Fh!==a&&(t.i(a,"number",oo,"infinityDistance"),1<a&&(this.Fh=a,this.J()))});t.g(oo,"defaultSpringStiffness",oo.prototype.wI);
t.defineProperty(oo,{wI:"defaultSpringStiffness"},function(){return this.In},function(a){this.In!==a&&(t.i(a,"number",oo,"defaultSpringStiffness"),this.In=a,this.J())});t.g(oo,"defaultSpringLength",oo.prototype.vI);t.defineProperty(oo,{vI:"defaultSpringLength"},function(){return this.Hn},function(a){this.Hn!==a&&(t.i(a,"number",oo,"defaultSpringLength"),this.Hn=a,this.J())});t.g(oo,"defaultElectricalCharge",oo.prototype.pI);
t.defineProperty(oo,{pI:"defaultElectricalCharge"},function(){return this.En},function(a){this.En!==a&&(t.i(a,"number",oo,"defaultElectricalCharge"),this.En=a,this.J())});t.g(oo,"defaultGravitationalMass",oo.prototype.qI);t.defineProperty(oo,{qI:"defaultGravitationalMass"},function(){return this.Gn},function(a){this.Gn!==a&&(t.i(a,"number",oo,"defaultGravitationalMass"),this.Gn=a,this.J())});t.g(oo,"defaultCommentSpringLength",oo.prototype.kE);
t.defineProperty(oo,{kE:"defaultCommentSpringLength"},function(){return this.Fq},function(a){this.Fq!==a&&(t.i(a,"number",oo,"defaultCommentSpringLength"),this.Fq=a,this.J())});t.g(oo,"defaultCommentElectricalCharge",oo.prototype.jE);t.defineProperty(oo,{jE:"defaultCommentElectricalCharge"},function(){return this.Eq},function(a){this.Eq!==a&&(t.i(a,"number",oo,"defaultCommentElectricalCharge"),this.Eq=a,this.J())});function po(){ta.call(this)}t.ga("ForceDirectedNetwork",po);t.Ka(po,ta);
po.prototype.createVertex=function(){return new xo};po.prototype.createEdge=function(){return new Bo};function xo(){ua.call(this);this.isFixed=!1;this.mass=this.charge=NaN;this.gk=this.Xf=this.forceY=this.forceX=0;this.kh=this.Jm=null;this.XA=0}t.ga("ForceDirectedVertex",xo);t.Ka(xo,ua);function Bo(){va.call(this);this.length=this.stiffness=NaN}t.ga("ForceDirectedEdge",Bo);t.Ka(Bo,va);
function Y(){0<arguments.length&&t.ad(Y);be.call(this);this.zd=this.dm=25;this.sa=0;this.Dn=Co;this.mo=Do;this.Yn=Eo;this.am=4;this.vn=Fo;this.Hi=Go;this.Lh=!0;this.yj=4;this.Ib=this.cv=this.kb=-1;this.Kf=this.zr=0;this.Ob=this.Jf=this.ig=this.Wg=this.Od=null;this.Fr=0;this.lv=this.im=null;this.lg=0;this.Gr=null;this.Zg=[];this.Zg.length=100}t.ga("LayeredDigraphLayout",Y);t.Ka(Y,be);
Y.prototype.cloneProtected=function(a){be.prototype.cloneProtected.call(this,a);a.dm=this.dm;a.zd=this.zd;a.sa=this.sa;a.Dn=this.Dn;a.mo=this.mo;a.Yn=this.Yn;a.am=this.am;a.vn=this.vn;a.Hi=this.Hi;a.Lh=this.Lh;a.yj=this.yj};Y.prototype.createNetwork=function(){var a=new No;On(a,this);return a};
Y.prototype.doLayout=function(a){a||t.m("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));this.Fd=this.initialOrigin(this.Fd);this.cv=-1;this.Kf=this.zr=0;this.Gr=this.lv=this.im=null;for(a=0;a<this.Zg.length;a++)this.Zg[a]=null;if(0<this.network.vertexes.count){this.network.xw();for(a=this.network.edges.k;a.next();)a.value.rev=!1;switch(this.Dn){default:case Ro:var b=0,c=this.network.vertexes.count-
1;a=[];a.length=c+1;for(var d=this.network.vertexes.k;d.next();)d.value.valid=!0;for(;null!==So(this.network);){for(d=To(this.network);null!==d;)a[c]=d,c--,d.valid=!1,d=To(this.network);for(d=Uo(this.network);null!==d;)a[b]=d,b++,d.valid=!1,d=Uo(this.network);for(var d=null,f=0,g=this.network.vertexes.k;g.next();){var h=g.value;if(h.valid){for(var k=0,l=h.gc;l.next();)l.value.toVertex.valid&&k++;for(var l=0,m=h.tc;m.next();)m.value.fromVertex.valid&&l++;if(null===d||f<k-l)d=h,f=k-l}}null!==d&&(a[b]=
d,b++,d.valid=!1)}for(b=0;b<this.network.vertexes.count;b++)a[b].index=b;for(a=this.network.edges.k;a.next();)b=a.value,b.fromVertex.index>b.toVertex.index&&(this.network.ox(b),b.rev=!0);break;case Co:for(b=this.network.vertexes.k;b.next();)b.value.$o=-1,b.value.finish=-1;for(a=this.network.edges.k;a.next();)a.value.forest=!1;this.Fr=0;for(b.reset();b.next();)0===b.value.tc.count&&Vo(this,b.value);for(b.reset();b.next();)-1===b.value.$o&&Vo(this,b.value);for(a.reset();a.next();)b=a.value,b.forest||
(c=b.fromVertex,d=c.finish,f=b.toVertex,g=f.finish,f.$o<c.$o&&d<g&&(this.network.ox(b),b.rev=!0))}for(a=this.network.vertexes.k;a.next();)b=a.value,b.layer=-1;this.kb=-1;this.assignLayers();for(a.reset();a.next();)this.kb=Math.max(this.kb,a.value.layer);c=this.network.edges.k;for(b=[];c.next();)a=c.value,a.valid=!1,b.push(a);for(c=0;c<b.length;c++)if(a=b[c],!a.valid&&(null!==a.fromVertex.rd&&null!==a.toVertex.rd||a.fromVertex.layer!==a.toVertex.layer)){g=k=h=l=0;f=a.fromVertex;d=a.toVertex;if(null!==
a.link){k=a.link;if(null===k)continue;var n=f.rd,g=d.rd;if(null===n||null===g)continue;var p=k.aa,h=k.ca,q=k.ud,l=k.fe;a.rev&&(k=p,m=q,p=h,q=l,h=k,l=m);var r=f.O,k=a.toVertex.O,s=a.rev?d.T:f.T,m=t.K();isNaN(s.x)?m.assign(r):Cm(p,q,Hb,m);n!==p&&!isNaN(s.x)&&p.La()&&(n=f.T,isNaN(n.x)||(m.x+=s.x-n.x,m.y+=s.y-n.y));p=a.rev?f.T:d.T;n=t.K();isNaN(p.x)?n.assign(k):Cm(h,l,Hb,n);g!==h&&!isNaN(p.x)&&h.La()&&(g=d.T,isNaN(g.x)||(n.x+=p.x-g.x,n.y+=p.y-g.y));90===this.sa||270===this.sa?(g=Math.round((m.x-r.x)/
this.zd),h=m.x,k=Math.round((n.x-k.x)/this.zd),l=n.x):(g=Math.round((m.y-r.y)/this.zd),h=m.y,k=Math.round((n.y-k.y)/this.zd),l=n.y);t.B(m);t.B(n);a.portFromColOffset=g;a.portFromPos=h;a.portToColOffset=k;a.portToPos=l}else a.portFromColOffset=0,a.portFromPos=0,a.portToColOffset=0,a.portToPos=0;m=f.layer;r=d.layer;n=a;p=0;s=n.link;if(null!==s){var u=s.ud,v=s.fe;if(null!==u&&null!==v){var D=s.aa,q=s.ca;if(null!==D&&null!==q){var A=u.ob,G=v.rb;this.yl||(s.ob.Pc()||(A=s.ob),s.rb.Pc()||(G=s.rb));if(A.Pc()||
A===wb)A=Wo(this,!0);if(G.Pc()||G===wb)G=Wo(this,!1);var K=s.ic,N=s.getLinkPoint(D,u,A,!0,K,q,v,t.K()),A=s.getLinkDirection(D,u,N,A,!0,K,q,v);t.B(N);A===Xo(this,n,!0)?p+=1:this.yl&&null!==D&&1===D.ports.count&&n.rev&&(p+=1);N=s.getLinkPoint(q,v,G,!1,K,D,u,t.K());s=s.getLinkDirection(q,v,N,G,!1,K,D,u);t.B(N);s===Xo(this,n,!1)?p+=2:this.yl&&null!==q&&1===q.ports.count&&n.rev&&(p+=2)}}}n=1===p||3===p?!0:!1;if(p=2===p||3===p?!0:!1)q=this.network.createVertex(),q.rd=null,q.Em=1,q.layer=m,q.near=f,this.network.Yk(q),
f=this.network.an(f,q,a.link),f.valid=!1,f.rev=a.rev,f.portFromColOffset=g,f.portToColOffset=0,f.portFromPos=h,f.portToPos=0,f=q;s=1;n&&s--;if(m-r>s&&0<m){a.valid=!1;q=this.network.createVertex();q.rd=null;q.Em=2;q.layer=m-1;this.network.Yk(q);f=this.network.an(f,q,a.link);f.valid=!0;f.rev=a.rev;f.portFromColOffset=p?0:g;f.portToColOffset=0;f.portFromPos=p?0:h;f.portToPos=0;f=q;for(m--;m-r>s&&0<m;)q=this.network.createVertex(),q.rd=null,q.Em=3,q.layer=m-1,this.network.Yk(q),f=this.network.an(f,q,
a.link),f.valid=!0,f.rev=a.rev,f.portFromColOffset=0,f.portToColOffset=0,f.portFromPos=0,f.portToPos=0,f=q,m--;f=this.network.an(q,d,a.link);f.valid=!n;n&&(q.near=d);f.rev=a.rev;f.portFromColOffset=0;f.portToColOffset=k;f.portFromPos=0;f.portToPos=l}else a.valid=!0}b=this.Od=[];for(c=0;c<=this.kb;c++)b[c]=0;for(a=this.network.vertexes.k;a.next();)a.value.index=-1;this.initializeIndices();this.cv=-1;for(c=this.Kf=this.zr=0;c<=this.kb;c++)b[c]>b[this.Kf]&&(this.cv=b[c]-1,this.Kf=c),b[c]<b[this.zr]&&
(this.zr=c);this.Gr=[];for(c=0;c<b.length;c++)this.Gr[c]=[];for(a.reset();a.next();)c=a.value,b=c.layer,this.Gr[b][c.index]=c;this.Ib=-1;for(a=0;a<=this.kb;a++){b=Yo(this,a);c=0;d=this.Od[a];for(f=0;f<d;f++)g=b[f],c+=this.nodeMinColumnSpace(g,!0),g.column=c,c+=1,c+=this.nodeMinColumnSpace(g,!1);this.Ib=Math.max(this.Ib,c-1);Zo(this,a,b)}this.reduceCrossings();this.straightenAndPack();this.updateParts()}this.network=null;this.$e=!0};
Y.prototype.linkMinLength=function(a){var b=a.toVertex,c=0;for(a=a.fromVertex.gc;a.next();)a.value.toVertex===b&&c++;return 1<c?2:1};function $o(a){var b=a.fromVertex.rd;a=a.toVertex.rd;return null===b&&null===a?8:null===b||null===a?4:1}Y.prototype.nodeMinLayerSpace=function(a,b){return null===a.rd?0:90===this.sa||270===this.sa?b?a.O.y+10:a.T.height-a.O.y+10:b?a.O.x+10:a.T.width-a.O.x+10};
Y.prototype.nodeMinColumnSpace=function(a,b){if(null===a.rd)return 0;var c=b?a.HA:a.GA;if(void 0!==c)return c;c=this.sa;return 90===c||270===c?b?a.HA=a.O.x/this.zd+1|0:a.GA=(a.T.width-a.O.x)/this.zd+1|0:b?a.HA=a.O.y/this.zd+1|0:a.GA=(a.T.height-a.O.y)/this.zd+1|0};function ap(a){null===a.im&&(a.im=[]);for(var b=0,c=a.network.vertexes.k,d;c.next();)d=c.value,a.im[b]=d.layer,b++,a.im[b]=d.column,b++,a.im[b]=d.index,b++;return a.im}
function bp(a,b){for(var c=0,d=a.network.vertexes.k,f;d.next();)f=d.value,f.layer=b[c],c++,f.column=b[c],c++,f.index=b[c],c++}
function cp(a,b,c){e&&(t.q(b,Y,"crossingMatrix:unfixedLayer"),t.q(c,Y,"crossingMatrix:direction"));var d=Yo(a,b),f=a.Od[b];a.lv||(a.lv=[]);for(var g=a.lv,h,k=0;k<f;k++){h=0;var l=d[k],m=l.near,n,p;if(null!==m&&m.layer===l.layer)if(l=m.index,l>k)for(p=k+1;p<l;p++)n=d[p],n.near===m&&n.Em===m.Em||h++;else for(p=k-1;p>l;p--)n=d[p],n.near===m&&n.Em===m.Em||h++;var q,r,s,u,v,D;if(0<=c)for(l=d[k].ff,m=0;m<l.count;m++)if(q=l.n[m],q.valid&&q.fromVertex.layer!==b)for(n=q.fromVertex.index,p=q.portToPos,q=q.portFromPos,
r=m+1;r<l.count;r++)s=l.n[r],s.valid&&s.fromVertex.layer!==b&&(u=s.fromVertex.index,v=s.portToPos,s=s.portFromPos,p<v&&(n>u||n===u&&q>s)&&h++,v<p&&(u>n||u===n&&s>q)&&h++);if(0>=c)for(l=d[k].Te,m=0;m<l.count;m++)if(q=l.n[m],q.valid&&q.toVertex.layer!==b)for(n=q.toVertex.index,p=q.portToPos,q=q.portFromPos,r=m+1;r<l.count;r++)s=l.n[r],s.valid&&s.toVertex.layer!==b&&(u=s.toVertex.index,v=s.portToPos,s=s.portFromPos,q<s&&(n>u||n===u&&p>v)&&h++,s<q&&(u>n||u===n&&v>p)&&h++);g[k*f+k]=h;for(l=k+1;l<f;l++){var A=
0,G=0;if(0<=c)for(h=d[k].ff,D=d[l].ff,m=0;m<h.count;m++)if(q=h.n[m],q.valid&&q.fromVertex.layer!==b)for(n=q.fromVertex.index,q=q.portFromPos,r=0;r<D.count;r++)s=D.n[r],s.valid&&s.fromVertex.layer!==b&&(u=s.fromVertex.index,s=s.portFromPos,(n<u||n===u&&q<s)&&G++,(u<n||u===n&&s<q)&&A++);if(0>=c)for(h=d[k].Te,D=d[l].Te,m=0;m<h.count;m++)if(q=h.n[m],q.valid&&q.toVertex.layer!==b)for(n=q.toVertex.index,p=q.portToPos,r=0;r<D.count;r++)s=D.n[r],s.valid&&s.toVertex.layer!==b&&(u=s.toVertex.index,v=s.portToPos,
(n<u||n===u&&p<v)&&G++,(u<n||u===n&&v<p)&&A++);g[k*f+l]=A;g[l*f+k]=G}}Zo(a,b,d);return g}Y.prototype.countCrossings=function(){var a,b=0,c,d;for(a=0;a<=this.kb;a++){c=cp(this,a,1);d=this.Od[a];for(var f=0;f<d;f++)for(var g=f;g<d;g++)b+=c[f*d+g]}return b};
function dp(a){for(var b=0,c=0;c<=a.kb;c++){for(var d=a,f=c,g=Yo(d,f),h=d.Od[f],k=0,l=void 0,l=0;l<h;l++){var m=g[l].Te,n,p,q;if("undefined"!==typeof m)for(var r=0;r<m.count;r++)n=m.n[r],n.valid&&n.toVertex.layer!==f&&(p=n.fromVertex.column+n.portFromColOffset,q=n.toVertex.column+n.portToColOffset,k+=(Math.abs(p-q)+1)*$o(n))}Zo(d,f,g);b+=k}return b}
Y.prototype.normalize=function(){var a=Infinity;this.Ib=-1;for(var b=this.network.vertexes.k,c;b.next();)c=b.value,a=Math.min(a,c.column-this.nodeMinColumnSpace(c,!0)),this.Ib=Math.max(this.Ib,c.column+this.nodeMinColumnSpace(c,!1));for(b.reset();b.next();)b.value.column-=a;this.Ib-=a};
function ep(a,b,c){e&&(t.q(b,Y,"barycenters:unfixedLayer"),t.q(c,Y,"barycenters:direction"));var d=Yo(a,b),f=a.Od[b],g=[],h;for(h=0;h<f;h++){var k=d[h],l=null;0>=c&&(l=k.ff);var m=null;0<=c&&(m=k.Te);var n=0,p=0,q=k.near;null!==q&&q.layer===k.layer&&(n+=q.column-1,p++);if(null!==l)for(q=0;q<l.count;q++){var k=l.n[q],r=k.fromVertex;k.valid&&!k.rev&&r.layer!==b&&(n+=r.column+k.portFromColOffset,p++)}if(null!==m)for(l=0;l<m.count;l++)k=m.n[l],q=k.toVertex,k.valid&&!k.rev&&q.layer!==b&&(n+=q.column+k.portToColOffset,
p++);g[h]=0===p?-1:n/p}Zo(a,b,d);return g}
function fp(a,b,c){e&&(t.q(b,Y,"medians:unfixedLayer"),t.q(c,Y,"medians:direction"));for(var d=Yo(a,b),f=a.Od[b],g=[],h=0;h<f;h++){var k=d[h],l=null;0>=c&&(l=k.ff);var m=null;0<=c&&(m=k.Te);var n=0,p=[],q=k.near;null!==q&&q.layer===k.layer&&(p[n]=q.column-1,n++);if(null!==l)for(q=0;q<l.count;q++){var k=l.n[q],r=k.fromVertex;k.valid&&!k.rev&&r.layer!==b&&(p[n]=r.column+k.portFromColOffset,n++)}if(null!==m)for(l=0;l<m.count;l++)k=m.n[l],q=k.toVertex,k.valid&&!k.rev&&q.layer!==b&&(p[n]=q.column+k.portToColOffset,
n++);0===n?g[h]=-1:(p.sort(function(a,b){return a-b}),m=n>>1,g[h]=n&1?p[m]:p[m-1]+p[m]>>1)}Zo(a,b,d);return g}function gp(a,b,c,d,f,g){if(b.component===d){b.component=c;var h,k;if(f)for(var l=b.gc;l.next();){var m=l.value.toVertex;h=b.layer-m.layer;k=a.linkMinLength(l.value);h===k&&gp(a,m,c,d,f,g)}if(g)for(l=b.tc;l.next();)m=l.value.fromVertex,h=m.layer-b.layer,k=a.linkMinLength(l.value),h===k&&gp(a,m,c,d,f,g)}}
function hp(a,b,c,d,f,g){if(b.component===d){b.component=c;if(f)for(var h=b.gc,k;h.next();)k=h.value.toVertex,hp(a,k,c,d,f,g);if(g)for(b=b.tc;b.next();)h=b.value.fromVertex,hp(a,h,c,d,f,g)}}function So(a){for(a=a.vertexes.k;a.next();){var b=a.value;if(b.valid)return b}return null}function To(a){for(a=a.vertexes.k;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.gc;d.next();)if(d.value.toVertex.valid){c=!1;break}if(c)return b}}return null}
function Uo(a){for(a=a.vertexes.k;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.tc;d.next();)if(d.value.fromVertex.valid){c=!1;break}if(c)return b}}return null}function Vo(a,b){b.$o=a.Fr;a.Fr++;for(var c=b.gc;c.next();){var d=c.value,f=d.toVertex;-1===f.$o&&(d.forest=!0,Vo(a,f))}b.finish=a.Fr;a.Fr++}
Y.prototype.assignLayers=function(){switch(this.mo){case ip:jp(this);break;case kp:for(var a,b=this.network.vertexes.k;b.next();)a=lp(this,b.value),this.kb=Math.max(a,this.kb);for(b.reset();b.next();)a=b.value,a.layer=this.kb-a.layer;break;default:case Do:jp(this);for(b=this.network.vertexes.k;b.next();)b.value.valid=!1;for(b.reset();b.next();)0===b.value.tc.count&&mp(this,b.value);a=Infinity;for(b.reset();b.next();)a=Math.min(a,b.value.layer);this.kb=-1;for(b.reset();b.next();)b.value.layer-=a,this.kb=
Math.max(this.kb,b.value.layer)}};function jp(a){for(var b=a.network.vertexes.k;b.next();){var c=np(a,b.value);a.kb=Math.max(c,a.kb)}}function np(a,b){var c=0;if(-1===b.layer){for(var d=b.gc;d.next();)var f=d.value,g=a.linkMinLength(f),c=Math.max(c,np(a,f.toVertex)+g);b.layer=c}else c=b.layer;return c}function lp(a,b){var c=0;if(-1===b.layer){for(var d,f,g=b.tc;g.next();)f=g.value,d=a.linkMinLength(f),c=Math.max(c,lp(a,f.fromVertex)+d);b.layer=c}else c=b.layer;return c}
function mp(a,b){if(!b.valid){b.valid=!0;for(var c=b.gc;c.next();)mp(a,c.value.toVertex);for(c=a.network.vertexes.k;c.next();)c.value.component=-1;for(var d=b.ff.n,f=d.length,g=0;g<f;g++){var h=d[g],k=a.linkMinLength(h);h.fromVertex.layer-h.toVertex.layer>k&&gp(a,h.fromVertex,0,-1,!0,!1)}for(gp(a,b,1,-1,!0,!0);0!==b.component;){for(var k=0,d=Infinity,l=0,m=null,n=a.network.vertexes.k;n.next();){var p=n.value;if(1===p.component){for(var q=0,r=!1,s=p.ff.n,f=s.length,g=0;g<f;g++){var h=s[g],u=h.fromVertex,
q=q+1;1!==u.component&&(k+=1,u=u.layer-p.layer,h=a.linkMinLength(h),d=Math.min(d,u-h))}h=p.Te.n;f=h.length;for(g=0;g<f;g++)s=h[g].toVertex,q-=1,1!==s.component?k-=1:r=!0;(null===m||q<l)&&!r&&(m=p,l=q)}}if(0<k){for(c.reset();c.next();)f=c.value,1===f.component&&(f.layer+=d);b.component=0}else m.component=0}for(c=a.network.vertexes.k;c.next();)c.value.component=-1;for(gp(a,b,1,-1,!0,!1);0!==b.component;){g=0;f=Infinity;d=0;k=null;for(l=a.network.vertexes.k;l.next();)if(m=l.value,1===m.component){n=
0;p=!1;h=m.ff.n;q=h.length;for(r=0;r<q;r++)s=h[r].fromVertex,n+=1,1!==s.component?g+=1:p=!0;h=m.Te.n;q=h.length;for(r=0;r<q;r++)s=h[r],u=s.toVertex,n-=1,1!==u.component&&(g-=1,u=m.layer-u.layer,s=a.linkMinLength(s),f=Math.min(f,u-s));(null===k||n>d)&&!p&&(k=m,d=n)}if(0>g){for(c.reset();c.next();)g=c.value,1===g.component&&(g.layer-=f);b.component=0}else k.component=0}}}
function Xo(a,b,c){return 90===a.sa?c&&!b.rev||!c&&b.rev?270:90:180===a.sa?c&&!b.rev||!c&&b.rev?0:180:270===a.sa?c&&!b.rev||!c&&b.rev?90:270:c&&!b.rev||!c&&b.rev?180:0}
Y.prototype.initializeIndices=function(){switch(this.Yn){default:case op:for(var a,b=this.network.vertexes.k;b.next();){var c=b.value;a=c.layer;c.index=this.Od[a];this.Od[a]++}break;case Eo:b=this.network.vertexes.k;for(a=this.kb;0<=a;a--){for(;b.next();)b.value.layer===a&&-1===b.value.index&&pp(this,b.value);b.reset()}break;case qp:for(b=this.network.vertexes.k,a=0;a<=this.kb;a++){for(;b.next();)b.value.layer===a&&-1===b.value.index&&rp(this,b.value);b.reset()}}};
function pp(a,b){var c=b.layer;b.index=a.Od[c];a.Od[c]++;for(var c=b.Te.Ie(),d=!0,f;d;)for(d=!1,f=0;f<c.length-1;f++){var g=c[f],h=c[f+1];g.portFromColOffset>h.portFromColOffset&&(d=!0,c[f]=h,c[f+1]=g)}for(f=0;f<c.length;f++)d=c[f],d.valid&&(d=d.toVertex,-1===d.index&&pp(a,d))}
function rp(a,b){var c=b.layer;b.index=a.Od[c];a.Od[c]++;for(var c=b.ff.Ie(),d=!0,f;d;)for(d=!1,f=0;f<c.length-1;f++){var g=c[f],h=c[f+1];g.portToColOffset>h.portToColOffset&&(d=!0,c[f]=h,c[f+1]=g)}for(f=0;f<c.length;f++)d=c[f],d.valid&&(d=d.fromVertex,-1===d.index&&rp(a,d))}
Y.prototype.reduceCrossings=function(){var a=this.countCrossings(),b=ap(this),c,d,f;for(c=0;c<this.am;c++){for(d=0;d<=this.kb;d++)sp(this,d,1),tp(this,d,1);f=this.countCrossings();f<a&&(a=f,b=ap(this));for(d=this.kb;0<=d;d--)sp(this,d,-1),tp(this,d,-1);f=this.countCrossings();f<a&&(a=f,b=ap(this))}bp(this,b);for(c=0;c<this.am;c++){for(d=0;d<=this.kb;d++)sp(this,d,0),tp(this,d,0);f=this.countCrossings();f<a&&(a=f,b=ap(this));for(d=this.kb;0<=d;d--)sp(this,d,0),tp(this,d,0);f=this.countCrossings();
f<a&&(a=f,b=ap(this))}bp(this,b);var g,h,k;switch(this.vn){case up:break;case vp:for(k=a+1;(d=this.countCrossings())<k;)for(k=d,c=this.kb;0<=c;c--)for(h=0;h<=c;h++){for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=tp(this,d,-1)||g;f=this.countCrossings();f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=tp(this,d,1)||g;f=this.countCrossings();f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=tp(this,d,1)||g;f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=
tp(this,d,-1)||g;f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=tp(this,d,0)||g;f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=tp(this,d,0)||g;f>=a?bp(this,b):(a=f,b=ap(this))}break;default:case Fo:for(c=this.kb,h=0,k=a+1;(d=this.countCrossings())<k;){k=d;for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=tp(this,d,-1)||g;f=this.countCrossings();f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=tp(this,d,1)||g;f=this.countCrossings();f>=a?bp(this,b):
(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=tp(this,d,1)||g;f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=tp(this,d,-1)||g;f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=c;d>=h;d--)g=tp(this,d,0)||g;f>=a?bp(this,b):(a=f,b=ap(this));for(g=!0;g;)for(g=!1,d=h;d<=c;d++)g=tp(this,d,0)||g;f>=a?bp(this,b):(a=f,b=ap(this))}}bp(this,b)};
function sp(a,b,c){e&&(t.q(b,Y,"medianBarycenterCrossingReduction:unfixedLayer"),t.q(c,Y,"medianBarycenterCrossingReduction:direction"));var d=Yo(a,b),f=a.Od[b],g=fp(a,b,c),h=ep(a,b,c);for(c=0;c<f;c++)-1===h[c]&&(h[c]=d[c].column),-1===g[c]&&(g[c]=d[c].column);for(var k=!0,l;k;)for(k=!1,c=0;c<f-1;c++)if(g[c+1]<g[c]||g[c+1]===g[c]&&h[c+1]<h[c])k=!0,l=g[c],g[c]=g[c+1],g[c+1]=l,l=h[c],h[c]=h[c+1],h[c+1]=l,l=d[c],d[c]=d[c+1],d[c+1]=l;for(c=g=0;c<f;c++)l=d[c],l.index=c,g+=a.nodeMinColumnSpace(l,!0),l.column=
g,g+=1,g+=a.nodeMinColumnSpace(l,!1);Zo(a,b,d)}
function tp(a,b,c){var d=Yo(a,b),f=a.Od[b];c=cp(a,b,c);var g,h;h=[];for(g=0;g<f;g++)h[g]=-1;var k;k=[];for(g=0;g<f;g++)k[g]=-1;for(var l=!1,m=!0;m;)for(m=!1,g=0;g<f-1;g++){var n=c[d[g].index*f+d[g+1].index],p=c[d[g+1].index*f+d[g].index],q=0,r=0,s=d[g].column,u=d[g+1].column,v=a.nodeMinColumnSpace(d[g],!0),D=a.nodeMinColumnSpace(d[g],!1),A=a.nodeMinColumnSpace(d[g+1],!0),G=a.nodeMinColumnSpace(d[g+1],!1),v=s-v+A,D=u-D+G,K=d[g].tc.k;for(K.reset();K.next();)if(G=K.value,G.valid&&G.fromVertex.layer===
b){G=G.fromVertex;for(A=0;d[A]!==G;)A++;A<g&&(q+=2*(g-A),r+=2*(g+1-A));A===g+1&&(q+=1);A>g+1&&(q+=4*(A-g),r+=4*(A-(g+1)))}K=d[g].gc.k;for(K.reset();K.next();)if(G=K.value,G.valid&&G.toVertex.layer===b){G=G.toVertex;for(A=0;d[A]!==G;)A++;A===g+1&&(r+=1)}K=d[g+1].tc.k;for(K.reset();K.next();)if(G=K.value,G.valid&&G.fromVertex.layer===b){G=G.fromVertex;for(A=0;d[A]!==G;)A++;A<g&&(q+=2*(g+1-A),r+=2*(g-A));A===g&&(r+=1);A>g+1&&(q+=4*(A-(g+1)),r+=4*(A-g))}K=d[g+1].gc.k;for(K.reset();K.next();)if(G=K.value,
G.valid&&G.toVertex.layer===b){G=G.toVertex;for(A=0;d[A]!==G;)A++;A===g&&(q+=1)}var A=G=0,K=h[d[g].index],N=k[d[g].index],O=h[d[g+1].index],U=k[d[g+1].index];-1!==K&&(G+=Math.abs(K-s),A+=Math.abs(K-D));-1!==N&&(G+=Math.abs(N-s),A+=Math.abs(N-D));-1!==O&&(G+=Math.abs(O-u),A+=Math.abs(O-v));-1!==U&&(G+=Math.abs(U-u),A+=Math.abs(U-v));if(r<q-0.5||r===q&&p<n-0.5||r===q&&p===n&&A<G-0.5)m=l=!0,d[g].column=D,d[g+1].column=v,n=d[g],d[g]=d[g+1],d[g+1]=n}for(g=0;g<f;g++)d[g].index=g;Zo(a,b,d);return l}
Y.prototype.straightenAndPack=function(){var a,b,c=0!==(this.Hi&wp);a=this.Hi===Go;1E3<this.network.edges.count&&!a&&(c=!1);if(c){b=[];for(a=0;a<=this.kb;a++)b[a]=0;for(var d=this.network.vertexes.k,f,g;d.next();)f=d.value,a=f.layer,g=f.column,f=this.nodeMinColumnSpace(f,!1),b[a]=Math.max(b[a],g+f);for(d.reset();d.next();)f=d.value,a=f.layer,g=f.column,f.column=(8*(this.Ib-b[a])>>1)+8*g;this.Ib*=8}if(0!==(this.Hi&xp))for(b=!0;b;){b=!1;for(a=this.Kf+1;a<=this.kb;a++)b=yp(this,a,1)||b;for(a=this.Kf-
1;0<=a;a--)b=yp(this,a,-1)||b;b=yp(this,this.Kf,0)||b}if(0!==(this.Hi&zp)){for(a=this.Kf+1;a<=this.kb;a++)Ap(this,a,1);for(a=this.Kf-1;0<=a;a--)Ap(this,a,-1);Ap(this,this.Kf,0)}c&&(Bp(this,-1),Bp(this,1));if(0!==(this.Hi&xp))for(b=!0;b;){b=!1;b=yp(this,this.Kf,0)||b;for(a=this.Kf+1;a<=this.kb;a++)b=yp(this,a,0)||b;for(a=this.Kf-1;0<=a;a--)b=yp(this,a,0)||b}};function yp(a,b,c){e&&(t.q(b,Y,"bendStraighten:unfixedLayer"),t.q(c,Y,"bendStraighten:direction"));for(var d=!1;Cp(a,b,c);)d=!0;return d}
function Cp(a,b,c){e&&(t.q(b,Y,"shiftbendStraighten:unfixedLayer"),t.q(c,Y,"shiftbendStraighten:direction"));var d,f=Yo(a,b),g=a.Od[b],h=ep(a,b,-1);if(0<c)for(d=0;d<g;d++)h[d]=-1;var k=ep(a,b,1);if(0>c)for(d=0;d<g;d++)k[d]=-1;for(var l=!1,m=!0;m;)for(m=!1,d=0;d<g;d++){var n=f[d].column,p=a.nodeMinColumnSpace(f[d],!0),q=a.nodeMinColumnSpace(f[d],!1),p=0>d-1||n-f[d-1].column-1>p+a.nodeMinColumnSpace(f[d-1],!1)?n-1:n,q=d+1>=g||f[d+1].column-n-1>q+a.nodeMinColumnSpace(f[d+1],!0)?n+1:n,r=0,s=0,u=0,v,D,
A,G;if(0>=c)for(var K=f[d].tc.k;K.next();)v=K.value,v.valid&&v.fromVertex.layer!==b&&(D=$o(v),A=v.portFromColOffset,G=v.portToColOffset,v=v.fromVertex.column,r+=(Math.abs(n+G-(v+A))+1)*D,s+=(Math.abs(p+G-(v+A))+1)*D,u+=(Math.abs(q+G-(v+A))+1)*D);if(0<=c)for(K=f[d].gc.k;K.next();)v=K.value,v.valid&&v.toVertex.layer!==b&&(D=$o(v),A=v.portFromColOffset,G=v.portToColOffset,v=v.toVertex.column,r+=(Math.abs(n+A-(v+G))+1)*D,s+=(Math.abs(p+A-(v+G))+1)*D,u+=(Math.abs(q+A-(v+G))+1)*D);G=A=D=0;v=h[f[d].index];
K=k[f[d].index];-1!==v&&(D+=Math.abs(v-n),A+=Math.abs(v-p),G+=Math.abs(v-q));-1!==K&&(D+=Math.abs(K-n),A+=Math.abs(K-p),G+=Math.abs(K-q));if(s<r||s===r&&A<D)m=l=!0,f[d].column=p;if(u<r||u===r&&G<D)m=l=!0,f[d].column=q}Zo(a,b,f);a.normalize();return l}
function Ap(a,b,c){e&&(t.q(b,Y,"medianStraighten:unfixedLayer"),t.q(c,Y,"medianStraighten:direction"));var d=Yo(a,b),f=a.Od[b],g=fp(a,b,c),h=[];for(c=0;c<f;c++)h[c]=g[c];for(g=!0;g;)for(g=!1,c=0;c<f;c++){var k=d[c].column,l=a.nodeMinColumnSpace(d[c],!0),m=a.nodeMinColumnSpace(d[c],!1),n=0,p;-1===h[c]?0===c&&c===f-1?n=k:0===c?(p=d[c+1].column,n=p-k===m+a.nodeMinColumnSpace(d[c+1],!0)?k-1:k):c===f-1?(p=d[c-1].column,n=k-p===l+a.nodeMinColumnSpace(d[c-1],!1)?k+1:k):(p=d[c-1].column,l=p+a.nodeMinColumnSpace(d[c-
1],!1)+l+1,p=d[c+1].column,m=p-a.nodeMinColumnSpace(d[c+1],!0)-m-1,n=(l+m)/2|0):0===c&&c===f-1?n=h[c]:0===c?(p=d[c+1].column,m=p-a.nodeMinColumnSpace(d[c+1],!0)-m-1,n=Math.min(h[c],m)):c===f-1?(p=d[c-1].column,l=p+a.nodeMinColumnSpace(d[c-1],!1)+l+1,n=Math.max(h[c],l)):(p=d[c-1].column,l=p+a.nodeMinColumnSpace(d[c-1],!1)+l+1,p=d[c+1].column,m=p-a.nodeMinColumnSpace(d[c+1],!0)-m-1,l<h[c]&&h[c]<m?n=h[c]:l>=h[c]?n=l:m<=h[c]&&(n=m));n!==k&&(g=!0,d[c].column=n)}Zo(a,b,d);a.normalize()}
function Dp(a,b){e&&(t.q(b,Y,"packAux:column"),t.q(1,Y,"packAux:direction"));for(var c=!0,d=a.network.vertexes.k,f;d.next();){f=d.value;var g=a.nodeMinColumnSpace(f,!0),h=a.nodeMinColumnSpace(f,!1);if(f.column-g<=b&&f.column+h>=b){c=!1;break}}g=!1;if(c)for(d.reset();d.next();)f=d.value,f.column>b&&(f.column-=1,g=!0);return g}
function Ep(a,b){e&&(t.q(b,Y,"tightPackAux:column"),t.q(1,Y,"tightPackAux:direction"));var c=b,c=b+1,d,f=[],g=[];for(d=0;d<=a.kb;d++)f[d]=!1,g[d]=!1;for(var h=a.network.vertexes.k;h.next();){d=h.value;var k=d.column-a.nodeMinColumnSpace(d,!0),l=d.column+a.nodeMinColumnSpace(d,!1);k<=b&&l>=b&&(f[d.layer]=!0);k<=c&&l>=c&&(g[d.layer]=!0)}k=!0;c=!1;for(d=0;d<=a.kb;d++)k=k&&!(f[d]&&g[d]);if(k)for(h.reset();h.next();)d=h.value,d.column>b&&(d.column-=1,c=!0);return c}
function Bp(a,b){e&&t.q(b,Y,"componentPack:direction");for(var c=0;c<=a.Ib;c++)for(;Dp(a,c););a.normalize();for(c=0;c<a.Ib;c++)for(;Ep(a,c););a.normalize();var d,f,g,h;if(0<b)for(c=0;c<=a.Ib;c++)for(d=ap(a),f=dp(a),g=f+1;f<g;)g=f,Fp(a,c,1),h=dp(a),h>f?bp(a,d):h<f&&(f=h,d=ap(a));if(0>b)for(c=a.Ib;0<=c;c--)for(d=ap(a),f=dp(a),g=f+1;f<g;)g=f,Fp(a,c,-1),h=dp(a),h>f?bp(a,d):h<f&&(f=h,d=ap(a));a.normalize()}
function Fp(a,b,c){a.lg=0;for(var d=a.network.vertexes.k,f;d.next();)d.value.component=-1;if(0<c)for(d.reset();d.next();)f=d.value,f.column-a.nodeMinColumnSpace(f,!0)<=b&&(f.component=a.lg);if(0>c)for(d.reset();d.next();)f=d.value,f.column+a.nodeMinColumnSpace(f,!1)>=b&&(f.component=a.lg);a.lg++;for(d.reset();d.next();)f=d.value,-1===f.component&&(hp(a,f,a.lg,-1,!0,!0),a.lg++);b=[];for(f=0;f<a.lg*a.lg;f++)b[f]=!1;var g=[];for(f=0;f<(a.kb+1)*(a.Ib+1);f++)g[f]=-1;for(d.reset();d.next();){f=d.value;
for(var h=f.layer,k=Math.max(0,f.column-a.nodeMinColumnSpace(f,!0)),l=Math.min(a.Ib,f.column+a.nodeMinColumnSpace(f,!1));k<=l;k++)g[h*(a.Ib+1)+k]=f.component}for(f=0;f<=a.kb;f++){if(0<c)for(k=0;k<a.Ib;k++)-1!==g[f*(a.Ib+1)+k]&&-1!==g[f*(a.Ib+1)+k+1]&&g[f*(a.Ib+1)+k]!==g[f*(a.Ib+1)+k+1]&&(b[g[f*(a.Ib+1)+k]*a.lg+g[f*(a.Ib+1)+k+1]]=!0);if(0>c)for(k=a.Ib;0<k;k--)-1!==g[f*(a.Ib+1)+k]&&-1!==g[f*(a.Ib+1)+k-1]&&g[f*(a.Ib+1)+k]!==g[f*(a.Ib+1)+k-1]&&(b[g[f*(a.Ib+1)+k]*a.lg+g[f*(a.Ib+1)+k-1]]=!0)}g=[];for(f=
0;f<a.lg;f++)g[f]=!0;h=new B("number");for(h.add(0);0!==h.count;)if(l=h.n[h.count-1],h.qd(h.count-1),g[l])for(g[l]=!1,f=0;f<a.lg;f++)b[l*a.lg+f]&&h.Jd(0,f);if(0<c)for(d.reset();d.next();)f=d.value,g[f.component]&&(f.column-=1);if(0>c)for(d.reset();d.next();)f=d.value,g[f.component]&&(f.column+=1)}Y.prototype.commitLayout=function(){if(this.yl)for(var a=Wo(this,!0),b=Wo(this,!1),c=this.network.edges.k,d;c.next();)d=c.value.link,null!==d&&(d.ob=a,d.rb=b);this.commitNodes();this.Uz();this.rt&&this.commitLinks()};
function Wo(a,b){return 270===a.sa?b?Qb:Xb:90===a.sa?b?Xb:Qb:180===a.sa?b?Vb:Wb:b?Wb:Vb}
Y.prototype.commitNodes=function(){this.Wg=[];this.ig=[];this.Jf=[];this.Ob=[];for(var a=0;a<=this.kb;a++)this.Wg[a]=0,this.ig[a]=0,this.Jf[a]=0,this.Ob[a]=0;for(var a=this.network.vertexes.k,b,c;a.next();)b=a.value,c=b.layer,this.Wg[c]=Math.max(this.Wg[c],this.nodeMinLayerSpace(b,!0)),this.ig[c]=Math.max(this.ig[c],this.nodeMinLayerSpace(b,!1));var d=0,f=this.dm;for(b=0;b<=this.kb;b++)c=f,0>=this.Wg[b]+this.ig[b]&&(c=0),0<b&&(d+=c/2),90===this.sa||0===this.sa?(d+=this.ig[b],this.Jf[b]=d,d+=this.Wg[b]):
(d+=this.Wg[b],this.Jf[b]=d,d+=this.ig[b]),b<this.kb&&(d+=c/2),this.Ob[b]=d;f=d;d=this.Fd;for(b=0;b<=this.kb;b++)270===this.sa?this.Jf[b]=d.y+this.Jf[b]:90===this.sa?(this.Jf[b]=d.y+f-this.Jf[b],this.Ob[b]=f-this.Ob[b]):180===this.sa?this.Jf[b]=d.x+this.Jf[b]:(this.Jf[b]=d.x+f-this.Jf[b],this.Ob[b]=f-this.Ob[b]);for(a.reset();a.next();){b=a.value;c=b.layer;var g=b.column|0;270===this.sa||90===this.sa?(f=d.x+this.zd*g,c=this.Jf[c]):(f=this.Jf[c],c=d.y+this.zd*g);b.Da=f;b.Oa=c;b.commit()}};
Y.prototype.Uz=function(){for(var a=0,b=this.dm,c=0;c<=this.kb;c++)a+=this.Wg[c],a+=this.ig[c];for(var a=a+this.kb*b,b=[],c=this.zd*this.Ib,d=this.mJ;0<=d;d--)270===this.sa?0===d?b.push(new x(0,0,c,Math.abs(this.Ob[0]))):b.push(new x(0,this.Ob[d-1],c,Math.abs(this.Ob[d-1]-this.Ob[d]))):90===this.sa?0===d?b.push(new x(0,this.Ob[0],c,Math.abs(this.Ob[0]-a))):b.push(new x(0,this.Ob[d],c,Math.abs(this.Ob[d-1]-this.Ob[d]))):180===this.sa?0===d?b.push(new x(0,0,Math.abs(this.Ob[0]),c)):b.push(new x(this.Ob[d-
1],0,Math.abs(this.Ob[d-1]-this.Ob[d]),c)):0===d?b.push(new x(this.Ob[0],0,Math.abs(this.Ob[0]-a),c)):b.push(new x(this.Ob[d],0,Math.abs(this.Ob[d-1]-this.Ob[d]),c));this.commitLayers(b,F.mj)};Y.prototype.commitLayers=function(){};
Y.prototype.commitLinks=function(){for(var a=this.network.edges.k,b,c;a.next();)c=a.value.link,"undefined"!==typeof c&&null!==c&&(c.zl(),c.Uo(),c.Xi());for(a.reset();a.next();)c=a.value.link,"undefined"!==typeof c&&null!==c&&c.updateRoute();for(a.reset();a.next();)if(b=a.value,c=b.link){c.zl();var d=c,f=d.aa,g=d.ca,h=d.ud,k=d.fe;if(b.valid){if(c.De===Pg&&4===c.pa){if(b.rev)var l=f,f=g,g=l,m=h,h=k,k=m;if(b.fromVertex.column===b.toVertex.column){var n=c.getLinkPoint(f,h,c.computeSpot(!0),!0,!1,g,k),
p=c.getLinkPoint(g,k,c.computeSpot(!1),!1,!1,f,h);c.Uo();c.Xk(n.x,n.y);c.Xk((2*n.x+p.x)/3,(2*n.y+p.y)/3);c.Xk((n.x+2*p.x)/3,(n.y+2*p.y)/3);c.Xk(p.x,p.y)}else{var q=!1,r=!1;null!==h&&c.computeSpot(!0)===wb&&(q=!0);null!==k&&c.computeSpot(!1)===wb&&(r=!0);if(q||r){var s=c.o(0).x,u=c.o(0).y,v=c.o(1).x,D=c.o(1).y,A=c.o(2).x,G=c.o(2).y,K=c.o(3).x,N=c.o(3).y;if(q){90===this.sa||270===this.sa?(v=s,D=(u+N)/2):(v=(s+K)/2,D=u);c.Y(1,v,D);var O=c.getLinkPoint(f,h,c.computeSpot(!0),!0,!1,g,k);c.Y(0,O.x,O.y)}r&&
(90===this.sa||270===this.sa?(A=K,G=(u+N)/2):(A=(s+K)/2,G=N),c.Y(2,A,G),O=c.getLinkPoint(g,k,c.computeSpot(!1),!1,!1,f,h),c.Y(3,O.x,O.y))}}}c.Xi()}else if(b.fromVertex.layer===b.toVertex.layer)c.Xi();else{var U=!1,X=!1,Z,P=c.gt+1;if(c.ic)X=!0,Z=c.pa,4<Z&&c.points.removeRange(2,Z-3);else if(c.De===Pg)U=!0,Z=c.pa,4<Z&&c.points.removeRange(2,Z-3),P=2;else{Z=c.pa;var pa=c.computeSpot(!0)===wb,Ya=c.computeSpot(!1)===wb;2<Z&&pa&&Ya?c.points.removeRange(1,Z-2):3<Z&&pa&&!Ya?c.points.removeRange(1,Z-3):3<
Z&&!pa&&Ya?c.points.removeRange(2,Z-2):4<Z&&!pa&&!Ya&&c.points.removeRange(2,Z-3)}var za=b.fromVertex,Na=b.toVertex,wa,Pa;if(b.rev){for(var Ga;null!==Na&&za!==Na;){Pa=wa=null;for(var Kf=Na.tc.k;Kf.next()&&(ic=Kf.value,ic.link!==b.link||(wa=ic.fromVertex,Pa=ic.toVertex,null!==wa.rd)););wa!==za&&(kb=c.o(P-1).x,fb=c.o(P-1).y,Ia=wa.Da,Ja=wa.Oa,X?180===this.sa||0===this.sa?2===P?(c.C(P++,kb,fb),c.C(P++,kb,Ja)):(md=null!==Pa?Pa.Oa:fb,md!==Ja&&(Xa=this.Ob[wa.layer-1],c.C(P++,Xa,fb),c.C(P++,Xa,Ja))):2===
P?(c.C(P++,kb,fb),c.C(P++,Ia,fb)):(mf=null!==Pa?Pa.Da:kb,mf!==Ia&&(Xa=this.Ob[wa.layer-1],c.C(P++,kb,Xa),c.C(P++,Ia,Xa))):2===P?U?(db=Math.max(10,this.Wg[Na.layer]),Ab=Math.max(10,this.ig[Na.layer]),180===this.sa?(Ga=Na.T.x,c.C(P++,Ga-db,Ja),c.C(P++,Ga,Ja),c.C(P++,Ga+Ab,Ja)):90===this.sa?(Ga=Na.T.y+Na.T.height,c.C(P++,Ia,Ga+Ab),c.C(P++,Ia,Ga),c.C(P++,Ia,Ga-db)):270===this.sa?(Ga=Na.T.y,c.C(P++,Ia,Ga-db),c.C(P++,Ia,Ga),c.C(P++,Ia,Ga+Ab)):(Ga=Na.T.x+Na.T.width,c.C(P++,Ga+Ab,Ja),c.C(P++,Ga,Ja),c.C(P++,
Ga-db,Ja))):(c.C(P++,kb,fb),180===this.sa||0===this.sa?c.C(P++,kb,Ja):c.C(P++,Ia,fb),c.C(P++,Ia,Ja)):(db=Math.max(10,this.Wg[wa.layer]),Ab=Math.max(10,this.ig[wa.layer]),180===this.sa?(U&&c.C(P++,Ia-db,Ja),c.C(P++,Ia,Ja),U&&c.C(P++,Ia+Ab,Ja)):90===this.sa?(U&&c.C(P++,Ia,Ja+Ab),c.C(P++,Ia,Ja),U&&c.C(P++,Ia,Ja-db)):270===this.sa?(U&&c.C(P++,Ia,Ja-db),c.C(P++,Ia,Ja),U&&c.C(P++,Ia,Ja+Ab)):(U&&c.C(P++,Ia+Ab,Ja),c.C(P++,Ia,Ja),U&&c.C(P++,Ia-db,Ja))));Na=wa}if(null===k||c.computeSpot(!1)!==wb)if(kb=c.o(P-
1).x,fb=c.o(P-1).y,Ia=c.o(P).x,Ja=c.o(P).y,X){var lf=this.ig[za.layer],$b;180===this.sa||0===this.sa?($b=fb,$b>=za.T.y&&$b<=za.T.bottom&&(Ga=za.Da+lf,$b=$b<za.T.y+za.T.height/2?za.T.y-this.zd/2:za.T.bottom+this.zd/2,c.C(P++,Ga,fb),c.C(P++,Ga,$b)),c.C(P++,Ia,$b)):($b=kb,$b>=za.T.x&&$b<=za.T.right&&(Ga=za.Oa+lf,$b=$b<za.T.x+za.T.width/2?za.T.x-this.zd/2:za.T.right+this.zd/2,c.C(P++,kb,Ga),c.C(P++,$b,Ga)),c.C(P++,$b,Ja));c.C(P++,Ia,Ja)}else U?(db=Math.max(10,this.Wg[za.layer]),Ab=Math.max(10,this.ig[za.layer]),
180===this.sa?(Ga=za.T.x+za.T.width,c.Y(P-2,Ga,fb),c.Y(P-1,Ga+Ab,fb)):90===this.sa?(Ga=za.T.y,c.Y(P-2,kb,Ga),c.Y(P-1,kb,Ga-db)):270===this.sa?(Ga=za.T.y+za.T.height,c.Y(P-2,kb,Ga),c.Y(P-1,kb,Ga+Ab)):(Ga=za.T.x,c.Y(P-2,Ga,fb),c.Y(P-1,Ga-db,fb))):(180===this.sa||0===this.sa?c.C(P++,Ia,fb):c.C(P++,kb,Ja),c.C(P++,Ia,Ja))}else{for(;null!==za&&za!==Na;){Pa=wa=null;for(var gg=za.gc.k,ic;gg.next()&&(ic=gg.value,ic.link!==b.link||(wa=ic.toVertex,Pa=ic.fromVertex,null!==Pa.rd&&(Pa=null),null!==wa.rd)););var kb,
fb,Ia,Ja,Xa,db,Ab;if(wa!==Na)if(kb=c.o(P-1).x,fb=c.o(P-1).y,Ia=wa.Da,Ja=wa.Oa,X)if(180===this.sa||0===this.sa){var md=null!==Pa?Pa.Oa:fb;md!==Ja&&(Xa=this.Ob[wa.layer],2===P&&(Xa=0===this.sa?Math.max(Xa,kb):Math.min(Xa,kb)),c.C(P++,Xa,fb),c.C(P++,Xa,Ja))}else{var mf=null!==Pa?Pa.Da:kb;mf!==Ia&&(Xa=this.Ob[wa.layer],2===P&&(Xa=90===this.sa?Math.max(Xa,fb):Math.min(Xa,fb)),c.C(P++,kb,Xa),c.C(P++,Ia,Xa))}else db=Math.max(10,this.Wg[wa.layer]),Ab=Math.max(10,this.ig[wa.layer]),180===this.sa?(c.C(P++,
Ia+Ab,Ja),U&&c.C(P++,Ia,Ja),c.C(P++,Ia-db,Ja)):90===this.sa?(c.C(P++,Ia,Ja-db),U&&c.C(P++,Ia,Ja),c.C(P++,Ia,Ja+Ab)):270===this.sa?(c.C(P++,Ia,Ja+Ab),U&&c.C(P++,Ia,Ja),c.C(P++,Ia,Ja-db)):(c.C(P++,Ia-db,Ja),U&&c.C(P++,Ia,Ja),c.C(P++,Ia+Ab,Ja));za=wa}X&&(kb=c.o(P-1).x,fb=c.o(P-1).y,Ia=c.o(P).x,Ja=c.o(P).y,180===this.sa||0===this.sa?fb!==Ja&&(Xa=0===this.sa?Math.min(Math.max((Ia+kb)/2,this.Ob[Na.layer]),Ia):Math.max(Math.min((Ia+kb)/2,this.Ob[Na.layer]),Ia),c.C(P++,Xa,fb),c.C(P++,Xa,Ja)):kb!==Ia&&(Xa=
90===this.sa?Math.min(Math.max((Ja+fb)/2,this.Ob[Na.layer]),Ja):Math.max(Math.min((Ja+fb)/2,this.Ob[Na.layer]),Ja),c.C(P++,kb,Xa),c.C(P++,Ia,Xa)))}if(null!==d&&U){if(null!==h){if(c.computeSpot(!0)===wb){var Dd=c.o(0),nd=c.o(2);Dd.M(nd)||c.Y(1,(Dd.x+nd.x)/2,(Dd.y+nd.y)/2)}O=c.getLinkPoint(f,h,wb,!0,!1,g,k);c.Y(0,O.x,O.y)}null!==k&&(c.computeSpot(!1)===wb&&(Dd=c.o(c.pa-1),nd=c.o(c.pa-3),Dd.M(nd)||c.Y(c.pa-2,(Dd.x+nd.x)/2,(Dd.y+nd.y)/2)),O=c.getLinkPoint(g,k,wb,!1,!1,f,h),c.Y(c.pa-1,O.x,O.y))}c.Xi();
b.commit()}}for(var Ne=new B(T),Ed=this.network.edges.k,se;Ed.next();)se=Ed.value.link,null!==se&&se.ic&&!Ne.contains(se)&&Ne.add(se);if(0<Ne.count)if(90===this.sa||270===this.sa){for(var Lf=0,Bb=new B(Gp),Cg=Ne.k,Ib,rb,Za;Cg.next();)if(Ib=Cg.value,null!==Ib&&Ib.ic)for(var yb=2;yb<Ib.pa-3;yb++)if(rb=Ib.o(yb),Za=Ib.o(yb+1),this.I(rb.y,Za.y)&&!this.I(rb.x,Za.x)){var jc=new Gp;jc.layer=Math.floor(rb.y/2);var Mf=Ib.o(0),Nf=Ib.o(Ib.pa-1);jc.$a=Mf.x*Mf.x+Mf.y;jc.ae=Nf.x*Nf.x+Nf.y;jc.Be=Math.min(rb.x,Za.x);
jc.Ud=Math.max(rb.x,Za.x);jc.index=yb;jc.link=Ib;if(yb+2<Ib.pa){var kc=Ib.o(yb-1),Uc=Ib.o(yb+2),od=0;kc.y<rb.y?od=Uc.y<rb.y?3:rb.x<Za.x?2:1:kc.y>rb.y&&(od=Uc.y>rb.y?0:Za.x<rb.x?2:1);jc.hi=od}Bb.add(jc)}if(1<Bb.count){Bb.sort(this.fG);for(var ec=0;ec<Bb.count;){for(var pd=Bb.n[ec].layer,$a=ec+1;$a<Bb.count&&Bb.n[$a].layer===pd;)$a++;if(1<$a-ec)for(var Ua=ec;Ua<$a;){for(var Rb=Bb.n[Ua].Ud,Jb=ec+1;Jb<$a&&Bb.n[Jb].Be<Rb;)Rb=Math.max(Rb,Bb.n[Jb].Ud),Jb++;var Oe=Jb-Ua;if(1<Oe){Bb.Up(this.px,Ua,Ua+Oe);for(var Od=
1,pc=Bb.n[Ua].ae,Kb,yb=Ua;yb<Jb;yb++)Kb=Bb.n[yb],Kb.ae!==pc&&(Od++,pc=Kb.ae);Bb.Up(this.eG,Ua,Ua+Oe);for(var de=1,pc=Bb.n[Ua].$a,yb=Ua;yb<Jb;yb++)Kb=Bb.n[yb],Kb.$a!==pc&&(de++,pc=Kb.$a);var sb,Fd;Od<de?(sb=!1,Fd=Od,pc=Bb.n[Ua].ae,Bb.Up(this.px,Ua,Ua+Oe)):(sb=!0,Fd=de,pc=Bb.n[Ua].$a);for(var Dg=0,yb=Ua;yb<Jb;yb++){Kb=Bb.n[yb];(sb?Kb.$a:Kb.ae)!==pc&&(Dg++,pc=sb?Kb.$a:Kb.ae);Ib=Kb.link;rb=Ib.o(Kb.index);Za=Ib.o(Kb.index+1);var Pe=this.yp*(Dg-(Fd-1)/2);if(!Ib.dj||Hp(rb.x,rb.y+Pe,Za.x,Za.y+Pe))Lf++,Ib.zl(),
Ib.Y(Kb.index,rb.x,rb.y+Pe),Ib.Y(Kb.index+1,Za.x,Za.y+Pe),Ib.Xi()}}Ua=Jb}ec=$a}}}else{for(var Gd=0,gb=new B(Gp),nf=Ne.k,ib,tb,Vc;nf.next();)if(ib=nf.value,null!==ib&&ib.ic)for(var Nb=2;Nb<ib.pa-3;Nb++)if(tb=ib.o(Nb),Vc=ib.o(Nb+1),this.I(tb.x,Vc.x)&&!this.I(tb.y,Vc.y)){var Pd=new Gp;Pd.layer=Math.floor(tb.x/2);var Qd=ib.o(0),ee=ib.o(ib.pa-1);Pd.$a=Qd.x+Qd.y*Qd.y;Pd.ae=ee.x+ee.y*ee.y;Pd.Be=Math.min(tb.y,Vc.y);Pd.Ud=Math.max(tb.y,Vc.y);Pd.index=Nb;Pd.link=ib;if(Nb+2<ib.pa){var Fc=ib.o(Nb-1),te=ib.o(Nb+
2),Gc=0;Fc.x<tb.x?Gc=te.x<tb.x?3:tb.y<Vc.y?2:1:Fc.x>tb.x&&(Gc=te.x>tb.x?0:Vc.y<tb.y?2:1);Pd.hi=Gc}gb.add(Pd)}if(1<gb.count){gb.sort(this.fG);for(var Cb=0;Cb<gb.count;){for(var Qe=gb.n[Cb].layer,qd=Cb+1;qd<gb.count&&gb.n[qd].layer===Qe;)qd++;if(1<qd-Cb)for(var lb=Cb;lb<qd;){for(var Hd=gb.n[lb].Ud,Db=Cb+1;Db<qd&&gb.n[Db].Be<Hd;)Hd=Math.max(Hd,gb.n[Db].Ud),Db++;var Rd=Db-lb;if(1<Rd){gb.Up(this.px,lb,lb+Rd);for(var Id=1,lc=gb.n[lb].ae,Ob,Nb=lb;Nb<Db;Nb++)Ob=gb.n[Nb],Ob.ae!==lc&&(Id++,lc=Ob.ae);gb.Up(this.eG,
lb,lb+Rd);for(var nb=1,lc=gb.n[lb].$a,Nb=lb;Nb<Db;Nb++)Ob=gb.n[Nb],Ob.$a!==lc&&(nb++,lc=Ob.$a);var of,bb;Id<nb?(of=!1,bb=Id,lc=gb.n[lb].ae,gb.Up(this.px,lb,lb+Rd)):(of=!0,bb=nb,lc=gb.n[lb].$a);for(var Jd=0,Nb=lb;Nb<Db;Nb++){Ob=gb.n[Nb];(of?Ob.$a:Ob.ae)!==lc&&(Jd++,lc=of?Ob.$a:Ob.ae);ib=Ob.link;tb=ib.o(Ob.index);Vc=ib.o(Ob.index+1);var Wc=this.yp*(Jd-(bb-1)/2);if(!ib.dj||Hp(tb.x+Wc,tb.y,Vc.x+Wc,Vc.y))Gd++,ib.zl(),ib.Y(Ob.index,tb.x+Wc,tb.y),ib.Y(Ob.index+1,Vc.x+Wc,Vc.y),ib.Xi()}}lb=Db}Cb=qd}}}};
function Gp(){this.index=this.Ud=this.Be=this.ae=this.$a=this.layer=0;this.link=null;this.hi=0}t.Vd(Gp,{layer:!0,$a:!0,ae:!0,Be:!0,Ud:!0,index:!0,link:!0,hi:!0});Y.prototype.fG=function(a,b){return a instanceof Gp&&b instanceof Gp&&a!==b?a.layer<b.layer?-1:a.layer>b.layer?1:a.Be<b.Be?-1:a.Be>b.Be?1:a.Ud<b.Ud?-1:a.Ud>b.Ud?1:0:0};
Y.prototype.eG=function(a,b){return a instanceof Gp&&b instanceof Gp&&a!==b?a.$a<b.$a?-1:a.$a>b.$a||a.hi<b.hi?1:a.hi>b.hi||a.Be<b.Be?-1:a.Be>b.Be?1:a.Ud<b.Ud?-1:a.Ud>b.Ud?1:0:0};Y.prototype.px=function(a,b){return a instanceof Gp&&b instanceof Gp&&a!==b?a.ae<b.ae?-1:a.ae>b.ae||a.hi<b.hi?1:a.hi>b.hi||a.Be<b.Be?-1:a.Be>b.Be?1:a.Ud<b.Ud?-1:a.Ud>b.Ud?1:0:0};Y.prototype.I=function(a,b){e&&(t.q(a,Y,"isApprox:a"),t.q(b,Y,"isApprox:b"));var c=a-b;return-1<c&&1>c};
function Hp(a,b,c,d){e&&(t.q(a,Y,"isUnoccupied2:px"),t.q(b,Y,"isUnoccupied2:py"),t.q(c,Y,"isUnoccupied2:qx"),t.q(d,Y,"isUnoccupied2:qy"));return!0}function Yo(a,b){var c,d=a.Od[b];if(d>=a.Zg.length){c=[];var f;for(f=0;f<a.Zg.length;f++)c[f]=a.Zg[f];a.Zg=c}"undefined"===typeof a.Zg[d]||null===a.Zg[d]?c=[]:(c=a.Zg[d],a.Zg[d]=null);var d=a.Gr[b],g;for(f=0;f<d.length;f++)g=d[f],g instanceof Ip&&(c[g.index]=g);return c}function Zo(a,b,c){a.Zg[a.Od[b]]=c}t.g(Y,"layerSpacing",Y.prototype.layerSpacing);
t.defineProperty(Y,{layerSpacing:"layerSpacing"},function(){return this.dm},function(a){this.dm!==a&&(t.i(a,"number",Y,"layerSpacing"),0<=a&&(this.dm=a,this.J()))});t.g(Y,"columnSpacing",Y.prototype.eI);t.defineProperty(Y,{eI:"columnSpacing"},function(){return this.zd},function(a){this.zd!==a&&(t.i(a,"number",Y,"columnSpacing"),0<=a&&(this.zd=a,this.J()))});t.g(Y,"direction",Y.prototype.direction);
t.defineProperty(Y,{direction:"direction"},function(){return this.sa},function(a){this.sa!==a&&(t.i(a,"number",Y,"direction"),this.sa=a,this.J())});t.g(Y,"cycleRemoveOption",Y.prototype.hE);t.defineProperty(Y,{hE:"cycleRemoveOption"},function(){return this.Dn},function(a){this.Dn!==a&&(t.Aa(a,Y,Y,"cycleRemoveOption"),a===Ro||a===Co)&&(this.Dn=a,this.J())});t.g(Y,"layeringOption",Y.prototype.lF);
t.defineProperty(Y,{lF:"layeringOption"},function(){return this.mo},function(a){this.mo!==a&&(t.Aa(a,Y,Y,"layeringOption"),a===Do||a===ip||a===kp)&&(this.mo=a,this.J())});t.g(Y,"initializeOption",Y.prototype.ZE);t.defineProperty(Y,{ZE:"initializeOption"},function(){return this.Yn},function(a){this.Yn!==a&&(t.Aa(a,Y,Y,"initializeOption"),a===Eo||a===qp||a===op)&&(this.Yn=a,this.J())});t.g(Y,"iterations",Y.prototype.eJ);
t.defineProperty(Y,{eJ:"iterations"},function(){return this.am},function(a){this.am!==a&&(t.q(a,No,"iterations"),0<=a&&(this.am=a,this.J()))});t.g(Y,"aggressiveOption",Y.prototype.JD);t.defineProperty(Y,{JD:"aggressiveOption"},function(){return this.vn},function(a){this.vn!==a&&(t.Aa(a,Y,Y,"aggressiveOption"),a===up||a===Fo||a===vp)&&(this.vn=a,this.J())});t.g(Y,"packOption",Y.prototype.BJ);
t.defineProperty(Y,{BJ:"packOption"},function(){return this.Hi},function(a){this.Hi!==a&&(t.i(a,"number",Y,"packOption"),0<=a&&8>a&&(this.Hi=a,this.J()))});t.g(Y,"setsPortSpots",Y.prototype.yl);t.defineProperty(Y,{yl:"setsPortSpots"},function(){return this.Lh},function(a){this.Lh!==a&&(t.i(a,"boolean",Y,"setsPortSpots"),this.Lh=a,this.J())});t.g(Y,"linkSpacing",Y.prototype.yp);
t.defineProperty(Y,{yp:"linkSpacing"},function(){return this.yj},function(a){this.yj!==a&&(t.i(a,"number",Y,"linkSpacing"),0<=a&&(this.yj=a,this.J()))});t.A(Y,{mJ:"maxLayer"},function(){return this.kb});t.A(Y,{EK:"maxIndex"},function(){return this.cv});t.A(Y,{DK:"maxColumn"},function(){return this.Ib});t.A(Y,{GK:"minIndexLayer"},function(){return this.zr});t.A(Y,{FK:"maxIndexLayer"},function(){return this.Kf});var Co;Y.CycleDepthFirst=Co=t.w(Y,"CycleDepthFirst",0);var Ro;
Y.CycleGreedy=Ro=t.w(Y,"CycleGreedy",1);var Do;Y.LayerOptimalLinkLength=Do=t.w(Y,"LayerOptimalLinkLength",0);var ip;Y.LayerLongestPathSink=ip=t.w(Y,"LayerLongestPathSink",1);var kp;Y.LayerLongestPathSource=kp=t.w(Y,"LayerLongestPathSource",2);var Eo;Y.InitDepthFirstOut=Eo=t.w(Y,"InitDepthFirstOut",0);var qp;Y.InitDepthFirstIn=qp=t.w(Y,"InitDepthFirstIn",1);var op;Y.InitNaive=op=t.w(Y,"InitNaive",2);var up;Y.AggressiveNone=up=t.w(Y,"AggressiveNone",0);var Fo;
Y.AggressiveLess=Fo=t.w(Y,"AggressiveLess",1);var vp;Y.AggressiveMore=vp=t.w(Y,"AggressiveMore",2);Y.PackNone=0;var wp;Y.PackExpand=wp=1;var xp;Y.PackStraighten=xp=2;var zp;Y.PackMedian=zp=4;var Go;Y.PackAll=Go=7;function No(){ta.call(this)}t.ga("LayeredDigraphNetwork",No);t.Ka(No,ta);No.prototype.createVertex=function(){return new Ip};No.prototype.createEdge=function(){return new Jp};
function Ip(){ua.call(this);this.index=this.column=this.layer=-1;this.component=NaN;this.near=null;this.valid=!1;this.finish=this.$o=NaN;this.Em=0;this.GA=this.HA=void 0}t.ga("LayeredDigraphVertex",Ip);t.Ka(Ip,ua);function Jp(){va.call(this);this.forest=this.rev=this.valid=!1;this.portToPos=this.portFromPos=NaN;this.portToColOffset=this.portFromColOffset=0}t.ga("LayeredDigraphEdge",Jp);t.Ka(Jp,va);
function W(){0<arguments.length&&t.ad(W);be.call(this);this.Yc=new ma(Object);this.Ji=Kp;this.If=Lp;this.Cs=Mp;this.Zu=Np;this.EB=null;this.sh=!0;this.cd=Op;this.bg=(new fa(10,10)).freeze();this.ua=new Pp;this.ta=new Pp}t.ga("TreeLayout",W);t.Ka(W,be);W.prototype.cloneProtected=function(a){be.prototype.cloneProtected.call(this,a);a.Ji=this.Ji;a.Cs=this.Cs;a.Zu=this.Zu;a.sh=this.sh;a.cd=this.cd;a.bg.assign(this.bg);a.ua.copyInheritedPropertiesFrom(this.ua);a.ta.copyInheritedPropertiesFrom(this.ta)};
W.prototype.createNetwork=function(){var a=new Qp;On(a,this);return a};W.prototype.makeNetwork=function(a){var b=this.createNetwork();a instanceof z?(Rp(this,b,a.jh,!0),Rp(this,b,a.links,!0)):a instanceof S?Rp(this,b,a.Rc,!1):Rp(this,b,a.k,!1);return b};
function Rp(a,b,c,d){for(c=c.k;c.next();){var f=c.value;f instanceof R&&f.canLayout()&&"Comment"!==f.Zc&&(!d||f.sp)&&(f.bi||(f instanceof S&&null===f.jc?Rp(a,b,f.Rc,!1):b.Ko(f)))}for(c.reset();c.next();)if(f=c.value,f instanceof T&&f.canLayout()&&"Comment"!==f.Zc&&(!d||f.sp)){var g=f.aa;a=f.ca;null!==g&&null!==a&&g!==a&&"Comment"!==g.Zc&&"Comment"!==a.Zc&&(g=b.findGroupVertex(g),a=b.findGroupVertex(a),null!==g&&null!==a&&b.an(g,a,f))}}
W.prototype.doLayout=function(a){a||t.m("Layout.doLayout(collection) argument must not be null but a Diagram, a Group, or an Iterable of Parts");null===this.network&&(this.network=this.makeNetwork(a));this.Qe!==Sp&&(this.Fd=this.initialOrigin(this.Fd));var b=this.h;null===b&&a instanceof z&&(b=a);this.If=this.path===Kp&&null!==b?b.pd?Lp:Tp:this.path===Kp?Lp:this.path;if(0<this.network.vertexes.count){this.network.xw();for(b=this.network.vertexes.k;b.next();)a=b.value,a.initialized=!1,a.level=0,a.parent=
null,a.children=[];if(0<this.Yc.count)for(b=this.Yc.k,this.Yc=new ma(Pp);b.next();)a=b.value,a instanceof R?(a=this.network.Rm(a),null!==a&&this.Yc.add(a)):a instanceof Pp&&this.Yc.add(a);0===this.Yc.count&&this.findRoots();for(b=this.Yc.copy().k;b.next();)a=b.value,a.initialized||(a.initialized=!0,Up(this,a));for(a=this.Yc.k;a.next();)Vp(this,a.value);for(a=this.Yc.k;a.next();)Wp(this,a.value);for(a=this.Yc.k;a.next();)Xp(this,a.value);this.Mz();if(this.Xw===Yp){var c=[];for(a=this.network.vertexes.k;a.next();){var b=
a.value,d=b.parent;d||(d=b);var d=0===d.angle||180===d.angle,f=c[b.level];void 0===f&&(f=0);c[b.level]=Math.max(f,d?b.width:b.height)}for(f=0;f<c.length;f++)void 0===c[f]&&(c[f]=0);this.EB=c;for(a=this.network.vertexes.k;a.next();)b=a.value,(d=b.parent)||(d=b),0===d.angle||180===d.angle?(180===d.angle&&(b.fp+=c[b.level]-b.width),b.width=c[b.level]):(270===d.angle&&(b.gp+=c[b.level]-b.height),b.height=c[b.level])}else if(this.Xw===Zp)for(a=this.network.vertexes.k;a.next();){b=a.value;d=0===b.angle||
180===b.angle;c=-1;for(f=0;f<b.children.length;f++)var g=b.children[f],c=Math.max(c,d?g.width:g.height);if(0<=c)for(f=0;f<b.children.length;f++)g=b.children[f],d?(180===b.angle&&(g.fp+=c-g.width),g.width=c):(270===b.angle&&(g.gp+=c-g.height),g.height=c)}for(a=this.Yc.k;a.next();)this.layoutTree(a.value);this.arrangeTrees();this.updateParts()}this.network=null;this.Yc=new ma(Object);this.$e=!0};
W.prototype.findRoots=function(){for(var a=this.network.vertexes.k;a.next();){var b=a.value;switch(this.If){case Lp:0===b.tc.count&&this.Yc.add(b);break;case Tp:0===b.gc.count&&this.Yc.add(b);break;default:t.m("Unhandled path value "+this.If.toString())}}if(0===this.Yc.count){for(var a=999999,b=null,c=this.network.vertexes.k;c.next();){var d=c.value;switch(this.If){case Lp:d.tc.count<a&&(a=d.tc.count,b=d);break;case Tp:d.gc.count<a&&(a=d.gc.count,b=d);break;default:t.m("Unhandled path value "+this.If.toString())}}null!==
b&&this.Yc.add(b)}};
function Up(a,b){if(null!==b){e&&t.l(b,Pp,W,"walkTree:v");switch(a.If){case Lp:if(0<b.gc.count){for(var c=new B(Pp),d=b.xI;d.next();){var f=d.value;$p(a,b,f)&&c.add(f)}0<c.count&&(b.children=c.Ie())}break;case Tp:if(0<b.tc.count){c=new B(Pp);for(d=b.SJ;d.next();)f=d.value,$p(a,b,f)&&c.add(f);0<c.count&&(b.children=c.Ie())}break;default:t.m("Unhandled path value"+a.If.toString())}for(var c=b.children,d=c.length,g=0;g<d;g++)f=c[g],f.initialized=!0,f.level=b.level+1,f.parent=b,a.Yc.remove(f);for(g=0;g<
d;g++)f=c[g],Up(a,f)}}function $p(a,b,c){e&&t.l(b,Pp,W,"walkOK:v");e&&t.l(c,Pp,W,"walkOK:c");if(c.initialized){var d;if(null===b)d=!1;else{e&&t.l(c,Pp,W,"isAncestor:a");e&&t.l(b,Pp,W,"isAncestor:b");for(d=b.parent;null!==d&&d!==c;)d=d.parent;d=d===c}if(d||c.level>b.level)return!1;a.removeChild(c.parent,c)}return!0}
W.prototype.removeChild=function(a,b){if(null!==a&&null!==b){e&&t.l(a,Pp,W,"removeChild:p");e&&t.l(b,Pp,W,"removeChild:c");for(var c=a.children,d=0,f=0;f<c.length;f++)c[f]===b&&d++;if(0<d){for(var d=Array(c.length-d),g=0,f=0;f<c.length;f++)c[f]!==b&&(d[g++]=c[f]);a.children=d}}};
function Vp(a,b){if(null!==b){e&&t.l(b,Pp,W,"initializeTree:v");a.initializeTreeVertexValues(b);b.alignment===aq&&a.sortTreeVertexChildren(b);for(var c=0,d=b.Im,f=0,g=b.children,h=g.length,k=0;k<h;k++){var l=g[k];Vp(a,l);c+=l.descendantCount+1;d=Math.max(d,l.maxChildrenCount);f=Math.max(f,l.maxGenerationCount)}b.descendantCount=c;b.maxChildrenCount=d;b.maxGenerationCount=0<d?f+1:0}}
function bq(a,b){e&&t.l(b,Pp,W,"mom:v");switch(a.Cs){default:case Mp:return null!==b.parent?b.parent:a.ua;case cq:return null===b.parent?a.ua:null===b.parent.parent?a.ta:b.parent;case dq:if(null!==b.parent)return null!==b.parent.parent?b.parent.parent:a.ta;case eq:var c=!0;if(0===b.Im)c=!1;else for(var d=b.children,f=d.length,g=0;g<f;g++)if(0<d[g].Im){c=!1;break}return c&&null!==b.parent?a.ta:null!==b.parent?b.parent:a.ua}}
W.prototype.initializeTreeVertexValues=function(a){e&&t.l(a,Pp,W,"initializeTreeVertexValues:v");var b=bq(this,a);a.copyInheritedPropertiesFrom(b);if(null!==a.parent&&a.parent.alignment===aq){for(var b=a.angle,c=a.parent.children,d=0;d<c.length&&a!==c[d];)d++;0===d%2?d!==c.length-1&&(b=90===b?180:180===b?270:270===b?180:270):b=90===b?0:180===b?90:270===b?0:90;a.angle=b}a.initialized=!0};
function Wp(a,b){if(null!==b){e&&t.l(b,Pp,W,"assignTree:v");a.assignTreeVertexValues(b);for(var c=b.children,d=c.length,f=0;f<d;f++)Wp(a,c[f])}}W.prototype.assignTreeVertexValues=function(){};function Xp(a,b){if(null!==b){e&&t.l(b,Pp,W,"sortTree:v");b.alignment!==aq&&a.sortTreeVertexChildren(b);for(var c=b.children,d=c.length,f=0;f<d;f++)Xp(a,c[f])}}
W.prototype.sortTreeVertexChildren=function(a){e&&t.l(a,Pp,W,"sortTreeVertexChildren:v");switch(a.sorting){case fq:break;case gq:a.children.reverse();break;case hq:a.children.sort(a.comparer);break;case iq:a.children.sort(a.comparer);a.children.reverse();break;default:t.m("Unhandled sorting value "+a.sorting.toString())}};W.prototype.Mz=function(){if(this.comments)for(var a=this.network.vertexes.k;a.next();)this.addComments(a.value)};
W.prototype.addComments=function(a){e&&t.l(a,Pp,W,"addComments:v");var b=a.angle,c=a.parent,d=0,f=jq,f=!1;null!==c&&(d=c.angle,f=c.alignment,f=kq(f));var b=90===b||270===b,d=90===d||270===d,c=0===a.Im,g=0,h=0,k=0,l=a.commentSpacing;if(null!==a.Fc)for(var m=a.Fc.zE();m.next();){var n=m.value;"Comment"===n.Zc&&n.La()&&(null===a.comments&&(a.comments=[]),a.comments.push(n),n.sf(),n=n.Ga,b&&!c||!f&&!d&&c||f&&d&&c?(g=Math.max(g,n.width),h+=n.height+Math.abs(k)):(g+=n.width+Math.abs(k),h=Math.max(h,n.height)),
k=l)}null!==a.comments&&(b&&!c||!f&&!d&&c||f&&d&&c?(g+=Math.abs(a.commentMargin),h=Math.max(0,h-a.height)):(h+=Math.abs(a.commentMargin),g=Math.max(0,g-a.width)),f=t.ik(0,0,a.T.width+g,a.T.height+h),a.Pb=f,t.hc(f))};function kq(a){return a===lq||a===aq||a===mq||a===nq}function oq(a){return a===lq||a===aq}
function pq(a){e&&t.l(a,Pp,W,"isLeftSideBus:v");var b=a.parent;if(null!==b){var c=b.alignment;if(kq(c)){if(oq(c)){b=b.children;for(c=0;c<b.length&&a!==b[c];)c++;return 0===c%2}if(c===mq)return!0}}return!1}
W.prototype.layoutComments=function(a){e&&t.l(a,Pp,W,"layoutComments:v");if(null!==a.comments){var b=a.Fc.Ga,c=a.parent,d=a.angle,f=0,g=jq,g=!1;null!==c&&(f=c.angle,g=c.alignment,g=kq(g));for(var d=90===d||270===d,h=90===f||270===f,k=0===a.Im,l=pq(a),m=0,n=a.comments,p=n.length,q=t.K(),r=0;r<p;r++){var c=n[r],s=c.Ga;if(d&&!k||!g&&!h&&k||g&&h&&k){if(135<f&&!g||h&&l)if(0<=a.commentMargin){q.p(a.T.x-a.commentMargin-s.width,a.T.y+m);c.move(q);for(var u=c.wg();u.next();)c=u.value,c.ob=Vb,c.rb=Wb}else for(q.p(a.T.x+
2*a.O.x-a.commentMargin,a.T.y+m),c.move(q),u=c.wg();u.next();)c=u.value,c.ob=Wb,c.rb=Vb;else if(0<=a.commentMargin)for(q.p(a.T.x+2*a.O.x+a.commentMargin,a.T.y+m),c.move(q),u=c.wg();u.next();)c=u.value,c.ob=Wb,c.rb=Vb;else for(q.p(a.T.x+a.commentMargin-s.width,a.T.y+m),c.move(q),u=c.wg();u.next();)c=u.value,c.ob=Vb,c.rb=Wb;m=0<=a.commentSpacing?m+(s.height+a.commentSpacing):m+(a.commentSpacing-s.height)}else{if(135<f&&!g||!h&&l)if(0<=a.commentMargin)for(q.p(a.T.x+m,a.T.y-a.commentMargin-s.height),
c.move(q),u=c.wg();u.next();)c=u.value,c.ob=Qb,c.rb=Xb;else for(q.p(a.T.x+m,a.T.y+2*a.O.y-a.commentMargin),c.move(q),u=c.wg();u.next();)c=u.value,c.ob=Xb,c.rb=Qb;else if(0<=a.commentMargin)for(q.p(a.T.x+m,a.T.y+2*a.O.y+a.commentMargin),c.move(q),u=c.wg();u.next();)c=u.value,c.ob=Xb,c.rb=Qb;else for(q.p(a.T.x+m,a.T.y+a.commentMargin-s.height),c.move(q),u=c.wg();u.next();)c=u.value,c.ob=Qb,c.rb=Xb;m=0<=a.commentSpacing?m+(s.width+a.commentSpacing):m+(a.commentSpacing-s.width)}}t.B(q);b=m-a.commentSpacing-
(d?b.height:b.width);if(this.If===Lp)for(a=a.gc;a.next();)f=a.value,(c=f.link)&&!c.dj&&(c.dk=0<b?b:NaN);else for(a=a.tc;a.next();)f=a.value,(c=f.link)&&!c.dj&&(c.jk=0<b?b:NaN)}};
W.prototype.layoutTree=function(a){if(null!==a){e&&t.l(a,Pp,W,"layoutTree:v");for(var b=a.children,c=b.length,d=0;d<c;d++)this.layoutTree(b[d]);switch(a.compaction){case qq:rq(this,a);break;case sq:if(a.alignment===aq)rq(this,a);else if(e&&t.l(a,Pp,W,"layoutTreeBlock:v"),0===a.Im){var d=a.parent,b=!1,c=0,f=jq;null!==d&&(c=d.angle,f=d.alignment,b=kq(f));d=pq(a);a.na.p(0,0);a.Za.p(a.width,a.height);null===a.parent||null===a.comments||(180!==c&&270!==c||b)&&!d?a.Ia.p(0,0):180===c&&!b||(90===c||270===
c)&&d?a.Ia.p(a.width-2*a.O.x,0):a.Ia.p(0,a.height-2*a.O.y);a.tt=null;a.Kt=null}else{for(var g=tq(a),b=90===g||270===g,h=0,k=a.children,l=k.length,m=0;m<l;m++)var n=k[m],h=Math.max(h,b?n.Za.width:n.Za.height);var p=a.alignment,d=p===uq,q=p===vq,r=kq(p),s=Math.max(0,a.breadthLimit),c=wq(a),u=a.nodeSpacing,v=xq(a),D=a.rowSpacing,A=0;if(d||q||a.Op||a.Pp&&1===a.maxGenerationCount)A=Math.max(0,a.rowIndent);var d=a.width,f=a.height,G=0,K=0,N=0,O=null,U=null,X=0,Z=0,P=0,pa=0,Ya=0,za=0,Na=0,wa=0,n=0;r&&!oq(p)&&
135<g&&k.reverse();if(oq(p))if(1<l)for(m=0;m<l;m++)0===m%2&&m!==l-1?wa=Math.max(wa,b?k[m].Za.width:k[m].Za.height):0!==m%2&&(n=Math.max(n,b?k[m].Za.width:k[m].Za.height));else 1===l&&(wa=b?k[0].Za.width:k[0].Za.height);if(r){switch(p){case lq:Z=135>g?yq(a,k,wa,G,K):zq(a,k,wa,G,K);wa=Z[0];G=Z[1];K=Z[2];break;case mq:for(m=0;m<l;m++){var n=k[m],Pa=n.Za,O=0===za?0:D;b?(n.na.p(h-Pa.width,pa+O),G=Math.max(G,Pa.width),K=Math.max(K,pa+O+Pa.height),pa+=O+Pa.height):(n.na.p(P+O,h-Pa.height),G=Math.max(G,P+
O+Pa.width),K=Math.max(K,Pa.height),P+=O+Pa.width);za++}break;case nq:for(m=0;m<l;m++)n=k[m],Pa=n.Za,O=0===za?0:D,b?(n.na.p(u/2+a.O.x,pa+O),G=Math.max(G,Pa.width),K=Math.max(K,pa+O+Pa.height),pa+=O+Pa.height):(n.na.p(P+O,u/2+a.O.y),G=Math.max(G,P+O+Pa.width),K=Math.max(K,Pa.height),P+=O+Pa.width),za++}O=Aq(this,2);U=Aq(this,2);b?(O[0].p(0,0),O[1].p(0,K),U[0].p(G,0)):(O[0].p(0,0),O[1].p(G,0),U[0].p(0,K));U[1].p(G,K)}else for(m=0;m<l;m++){n=k[m];Pa=n.Za;if(b){0<s&&0<za&&P+u+Pa.width>s&&(P<h&&Bq(a,p,
h-P,0,Na,m-1),Ya++,za=0,Na=m,N=K,P=0,pa=135<g?-K-D:K+D);Cq(this,n,0,pa);var Ga=0;if(0===za){if(O=n.tt,U=n.Kt,X=Pa.width,Z=Pa.height,null===O||null===U||g!==tq(n))O=Aq(this,2),U=Aq(this,2),O[0].p(0,0),O[1].p(0,Z),U[0].p(X,0),U[1].p(X,Z)}else Z=Dq(this,a,n,O,U,X,Z),Ga=Z[0],O=Z[1],U=Z[2],X=Z[3],Z=Z[4],P<Pa.width&&0>Ga&&(Eq(a,-Ga,0,Na,m-1),Fq(O,-Ga,0),Fq(U,-Ga,0),Ga=0);n.na.p(Ga,pa);G=Math.max(G,X);K=Math.max(K,N+(0===Ya?0:D)+Pa.height);P=X}else{0<s&&0<za&&pa+u+Pa.height>s&&(pa<h&&Bq(a,p,0,h-pa,Na,m-
1),Ya++,za=0,Na=m,N=G,pa=0,P=135<g?-G-D:G+D);Cq(this,n,P,0);Ga=0;if(0===za){if(O=n.tt,U=n.Kt,X=Pa.width,Z=Pa.height,null===O||null===U||g!==tq(n))O=Aq(this,2),U=Aq(this,2),O[0].p(0,0),O[1].p(X,0),U[0].p(0,Z),U[1].p(X,Z)}else Z=Dq(this,a,n,O,U,X,Z),Ga=Z[0],O=Z[1],U=Z[2],X=Z[3],Z=Z[4],pa<Pa.height&&0>Ga&&(Eq(a,0,-Ga,Na,m-1),Fq(O,0,-Ga),Fq(U,0,-Ga),Ga=0);n.na.p(P,Ga);K=Math.max(K,Z);G=Math.max(G,N+(0===Ya?0:D)+Pa.width);pa=Z}za++}0<Ya&&(b?(K+=Math.max(0,c),P<G&&Bq(a,p,G-P,0,Na,l-1),0<A&&(q||Eq(a,A,0,
0,l-1),G+=A)):(G+=Math.max(0,c),pa<K&&Bq(a,p,0,K-pa,Na,l-1),0<A&&(q||Eq(a,0,A,0,l-1),K+=A)));q=h=0;switch(p){case Gq:b?h+=G/2-a.O.x-v/2:q+=K/2-a.O.y-v/2;break;case jq:0<Ya?b?h+=G/2-a.O.x-v/2:q+=K/2-a.O.y-v/2:b?(m=k[0].na.x+k[0].Ia.x,wa=k[l-1].na.x+k[l-1].Ia.x+2*k[l-1].O.x,h+=m+(wa-m)/2-a.O.x-v/2):(m=k[0].na.y+k[0].Ia.y,wa=k[l-1].na.y+k[l-1].Ia.y+2*k[l-1].O.y,q+=m+(wa-m)/2-a.O.y-v/2);break;case uq:b?(h-=v,G+=v):(q-=v,K+=v);break;case vq:b?(h+=G-a.width+v,G+=v):(q+=K-a.height+v,K+=v);break;case lq:b?
h=1<l?h+(wa+u/2-a.O.x):h+(k[0].O.x-a.O.x+k[0].Ia.x):q=1<l?q+(wa+u/2-a.O.y):q+(k[0].O.y-a.O.y+k[0].Ia.y);break;case mq:b?h+=G+u/2-a.O.x:q+=K+u/2-a.O.y;break;case nq:break;default:t.m("Unhandled alignment value "+p.toString())}for(m=0;m<l;m++)n=k[m],b?n.na.p(n.na.x+n.Ia.x-h,n.na.y+(135<g?(r?-K:-n.Za.height)+n.Ia.y-c:f+c+n.Ia.y)):n.na.p(n.na.x+(135<g?(r?-G:-n.Za.width)+n.Ia.x-c:d+c+n.Ia.x),n.na.y+n.Ia.y-q);l=k=0;r?b?(G=Hq(a,G,h),0>h&&(h=0),135<g&&(q+=K+c),K+=f+c,p===nq&&(k+=u/2+a.O.x),l+=f+c):(135<g&&
(h+=G+c),G+=d+c,K=Iq(a,K,q),0>q&&(q=0),p===nq&&(l+=u/2+a.O.y),k+=d+c):b?(null===a.comments?d>G&&(p=Jq(p,d-G,0),k=p[0],l=p[1],G=d,h=0):G=Hq(a,G,h),0>h&&(k-=h,h=0),135<g&&(q+=K+c),K=Math.max(Math.max(K,f),K+f+c),l+=f+c):(135<g&&(h+=G+c),G=Math.max(Math.max(G,d),G+d+c),null===a.comments?f>K&&(p=Jq(p,0,f-K),k=p[0],l=p[1],K=f,q=0):K=Iq(a,K,q),0>q&&(l-=q,q=0),k+=d+c);if(0<Ya)g=Aq(this,4),p=Aq(this,4),b?(g[2].p(0,f+c),g[3].p(g[2].x,K),p[2].p(G,g[2].y),p[3].p(p[2].x,g[3].y)):(g[2].p(d+c,0),g[3].p(G,g[2].y),
p[2].p(g[2].x,K),p[3].p(g[3].x,p[2].y));else{g=Aq(this,O.length+2);p=Aq(this,U.length+2);for(m=0;m<O.length;m++)r=O[m],g[m+2].p(r.x+k,r.y+l);for(m=0;m<U.length;m++)r=U[m],p[m+2].p(r.x+k,r.y+l)}b?(g[0].p(h,0),g[1].p(g[0].x,f),g[2].y<g[1].y&&(g[2].x>g[0].x?g[2].assign(g[1]):g[1].assign(g[2])),g[3].y<g[2].y&&(g[3].x>g[0].x?g[3].assign(g[2]):g[2].assign(g[3])),p[0].p(h+d,0),p[1].p(p[0].x,f),p[2].y<p[1].y&&(p[2].x<p[0].x?p[2].assign(p[1]):p[1].assign(p[2])),p[3].y<p[2].y&&(p[3].x<p[0].x?p[3].assign(p[2]):
p[2].assign(p[3])),g[2].y-=c/2,p[2].y-=c/2):(g[0].p(0,q),g[1].p(d,g[0].y),g[2].x<g[1].x&&(g[2].y>g[0].y?g[2].assign(g[1]):g[1].assign(g[2])),g[3].x<g[2].x&&(g[3].y>g[0].y?g[3].assign(g[2]):g[2].assign(g[3])),p[0].p(0,q+f),p[1].p(d,p[0].y),p[2].x<p[1].x&&(p[2].y<p[0].y?p[2].assign(p[1]):p[1].assign(p[2])),p[3].x<p[2].x&&(p[3].y<p[0].y?p[3].assign(p[2]):p[2].assign(p[3])),g[2].x-=c/2,p[2].x-=c/2);Kq(this,O);Kq(this,U);a.tt=g;a.Kt=p;a.Ia.p(h,q);a.Za.p(G,K)}break;default:t.m("Unhandled compaction value "+
a.compaction.toString())}}};
function rq(a,b){e&&t.l(b,Pp,W,"layoutTreeNone:v");if(0===b.Im){var c=!1,d=0,f=jq;null!==b.parent&&(d=b.parent.angle,f=b.parent.alignment,c=kq(f));f=pq(b);b.na.p(0,0);b.Za.p(b.width,b.height);null===b.parent||null===b.comments||(180!==d&&270!==d||c)&&!f?b.Ia.p(0,0):180===d&&!c||(90===d||270===d)&&f?b.Ia.p(b.width-2*b.O.x,0):b.Ia.p(0,b.height-2*b.O.y)}else{for(var c=tq(b),d=90===c||270===c,g=0,f=b.children,h=f.length,k=0;k<h;k++)var l=f[k],g=Math.max(g,d?l.Za.width:l.Za.height);var m=b.alignment,n=
m===uq,p=m===vq,q=kq(m),r=Math.max(0,b.breadthLimit),s=wq(b),u=b.nodeSpacing,v=xq(b),D=n||p?0:v/2,A=b.rowSpacing,G=0;if(n||p||b.Op||b.Pp&&1===b.maxGenerationCount)G=Math.max(0,b.rowIndent);var n=b.width,K=b.height,N=0,O=0,U=0,X=0,Z=0,P=0,pa=0,Ya=0,za=0,Na=0;q&&!oq(m)&&135<c&&f.reverse();if(oq(m))if(1<h)for(k=0;k<h;k++){var l=f[k],wa=l.Za;0===k%2&&k!==h-1?za=Math.max(za,(d?wa.width:wa.height)+Lq(l)-u):0!==k%2&&(Na=Math.max(Na,(d?wa.width:wa.height)+Lq(l)-u))}else 1===h&&(za=d?f[0].Za.width:f[0].Za.height);
if(q)switch(m){case lq:case aq:O=135>c?yq(b,f,za,N,O):zq(b,f,za,N,O);za=O[0];N=O[1];O=O[2];break;case mq:for(k=0;k<h;k++)l=f[k],wa=l.Za,r=0===pa?0:A,d?(l.na.p(g-wa.width,Z+r),N=Math.max(N,wa.width),O=Math.max(O,Z+r+wa.height),Z+=r+wa.height):(l.na.p(X+r,g-wa.height),N=Math.max(N,X+r+wa.width),O=Math.max(O,wa.height),X+=r+wa.width),pa++;break;case nq:for(g=0;g<h;g++)l=f[g],wa=l.Za,r=0===pa?0:A,d?(l.na.p(u/2+b.O.x,Z+r),N=Math.max(N,wa.width),O=Math.max(O,Z+r+wa.height),Z+=r+wa.height):(l.na.p(X+r,u/
2+b.O.y),N=Math.max(N,X+r+wa.width),O=Math.max(O,wa.height),X+=r+wa.width),pa++}else for(k=0;k<h;k++)l=f[k],wa=l.Za,d?(0<r&&0<pa&&X+u+wa.width>r&&(X<g&&Bq(b,m,g-X,0,Ya,k-1),P++,pa=0,Ya=k,U=O,X=0,Z=135<c?-O-A:O+A),Na=0===pa?D:u,Cq(a,l,0,Z),l.na.p(X+Na,Z),N=Math.max(N,X+Na+wa.width),O=Math.max(O,U+(0===P?0:A)+wa.height),X+=Na+wa.width):(0<r&&0<pa&&Z+u+wa.height>r&&(Z<g&&Bq(b,m,0,g-Z,Ya,k-1),P++,pa=0,Ya=k,U=N,Z=0,X=135<c?-N-A:N+A),Na=0===pa?D:u,Cq(a,l,X,0),l.na.p(X,Z+Na),O=Math.max(O,Z+Na+wa.height),
N=Math.max(N,U+(0===P?0:A)+wa.width),Z+=Na+wa.height),pa++;0<P&&(d?(O+=Math.max(0,s),X<N&&Bq(b,m,N-X,0,Ya,h-1),0<G&&(p||Eq(b,G,0,0,h-1),N+=G)):(N+=Math.max(0,s),Z<O&&Bq(b,m,0,O-Z,Ya,h-1),0<G&&(p||Eq(b,0,G,0,h-1),O+=G)));G=p=0;switch(m){case Gq:d?p+=N/2-b.O.x-v/2:G+=O/2-b.O.y-v/2;break;case jq:0<P?d?p+=N/2-b.O.x-v/2:G+=O/2-b.O.y-v/2:d?(m=f[0].na.x+f[0].Ia.x,u=f[h-1].na.x+f[h-1].Ia.x+2*f[h-1].O.x,p+=m+(u-m)/2-b.O.x-v/2):(m=f[0].na.y+f[0].Ia.y,u=f[h-1].na.y+f[h-1].Ia.y+2*f[h-1].O.y,G+=m+(u-m)/2-b.O.y-
v/2);break;case uq:d?(p-=v,N+=v):(G-=v,O+=v);break;case vq:d?(p+=N-b.width+v,N+=v):(G+=O-b.height+v,O+=v);break;case lq:case aq:d?p=1<h?p+(za+u/2-b.O.x):p+(f[0].O.x-b.O.x+f[0].Ia.x):G=1<h?G+(za+u/2-b.O.y):G+(f[0].O.y-b.O.y+f[0].Ia.y);break;case mq:d?p+=N+u/2-b.O.x:G+=O+u/2-b.O.y;break;case nq:break;default:t.m("Unhandled alignment value "+m.toString())}for(k=0;k<h;k++)l=f[k],d?l.na.p(l.na.x+l.Ia.x-p,l.na.y+(135<c?(q?-O:-l.Za.height)+l.Ia.y-s:K+s+l.Ia.y)):l.na.p(l.na.x+(135<c?(q?-N:-l.Za.width)+l.Ia.x-
s:n+s+l.Ia.x),l.na.y+l.Ia.y-G);d?(N=Hq(b,N,p),0>p&&(p=0),135<c&&(G+=O+s),O+=K+s):(135<c&&(p+=N+s),N+=n+s,O=Iq(b,O,G),0>G&&(G=0));b.Ia.p(p,G);b.Za.p(N,O)}}
function yq(a,b,c,d,f){e&&t.l(a,Pp,W,"layoutBusChildrenPosDir:v");var g=b.length;if(0===g)return a=[],a[0]=c,a[1]=d,a[2]=f,a;if(1===g){var h=b[0];d=h.Za.width;f=h.Za.height;a=[];a[0]=c;a[1]=d;a[2]=f;return a}for(var k=a.nodeSpacing,l=a.rowSpacing,m=90===tq(a),n=0,p=0,q=0,r=0;r<g;r++)if(!(0!==r%2||1<g&&r===g-1)){var h=b[r],s=h.Za,u=0===n?0:l;if(m){var v=Lq(h)-k;h.na.p(c-(s.width+v),q+u);d=Math.max(d,s.width+v);f=Math.max(f,q+u+s.height);q+=u+s.height}else v=Lq(h)-k,h.na.p(p+u,c-(s.height+v)),f=Math.max(f,
s.height+v),d=Math.max(d,p+u+s.width),p+=u+s.width;n++}var n=0,D=p,A=q;m?(p=c+k,q=0):(p=0,q=c+k);for(r=0;r<g;r++)0!==r%2&&(h=b[r],s=h.Za,u=0===n?0:l,m?(v=Lq(h)-k,h.na.p(p+v,q+u),d=Math.max(d,p+s.width+v),f=Math.max(f,q+u+s.height),q+=u+s.height):(v=Lq(h)-k,h.na.p(p+u,q+v),d=Math.max(d,p+u+s.width),f=Math.max(f,q+s.height+v),p+=u+s.width),n++);1<g&&1===g%2&&(h=b[g-1],s=h.Za,b=Mq(h,m?Math.max(Math.abs(A),Math.abs(q)):Math.max(Math.abs(D),Math.abs(p))),m?(h.na.p(c+k/2-h.O.x-h.Ia.x,f+b),m=c+k/2-h.O.x-
h.Ia.x,d=Math.max(d,m+s.width),0>m&&(d-=m),f=Math.max(f,Math.max(A,q)+b+s.height),0>h.na.x&&(c=Sq(a,h.na.x,!1,c,k))):(h.na.p(d+b,c+k/2-h.O.y-h.Ia.y),d=Math.max(d,Math.max(D,p)+b+s.width),m=c+k/2-h.O.y-h.Ia.y,f=Math.max(f,m+s.height),0>m&&(f-=m),0>h.na.y&&(c=Sq(a,h.na.y,!0,c,k))));a=[];a[0]=c;a[1]=d;a[2]=f;return a}
function zq(a,b,c,d,f){e&&t.l(a,Pp,W,"layoutBusChildrenNegDir:v");var g=b.length;if(0===g)return a=[],a[0]=c,a[1]=d,a[2]=f,a;if(1===g){var h=b[0];d=h.Za.width;f=h.Za.height;a=[];a[0]=c;a[1]=d;a[2]=f;return a}for(var k=a.nodeSpacing,l=a.rowSpacing,m=270===tq(a),n=0,p=0,q=0,r=0;r<g;r++)if(!(0!==r%2||1<g&&r===g-1)){var h=b[r],s=h.Za,u=0===n?0:l;if(m){var v=Lq(h)-k,q=q-(u+s.height);h.na.p(c-(s.width+v),q);d=Math.max(d,s.width+v);f=Math.max(f,Math.abs(q))}else v=Lq(h)-k,p-=u+s.width,h.na.p(p,c-(s.height+
v)),f=Math.max(f,s.height+v),d=Math.max(d,Math.abs(p));n++}var n=0,D=p,A=q;m?(p=c+k,q=0):(p=0,q=c+k);for(r=0;r<g;r++)0!==r%2&&(h=b[r],s=h.Za,u=0===n?0:l,m?(v=Lq(h)-k,q-=u+s.height,h.na.p(p+v,q),d=Math.max(d,p+s.width+v),f=Math.max(f,Math.abs(q))):(v=Lq(h)-k,p-=u+s.width,h.na.p(p,q+v),f=Math.max(f,q+s.height+v),d=Math.max(d,Math.abs(p))),n++);1<g&&1===g%2&&(h=b[g-1],s=h.Za,l=Mq(h,m?Math.max(Math.abs(A),Math.abs(q)):Math.max(Math.abs(D),Math.abs(p))),m?(h.na.p(c+k/2-h.O.x-h.Ia.x,-f-s.height-l),p=c+
k/2-h.O.x-h.Ia.x,d=Math.max(d,p+s.width),0>p&&(d-=p),f=Math.max(f,Math.abs(Math.min(A,q))+l+s.height),0>h.na.x&&(c=Sq(a,h.na.x,!1,c,k))):(h.na.p(-d-s.width-l,c+k/2-h.O.y-h.Ia.y),d=Math.max(d,Math.abs(Math.min(D,p))+l+s.width),p=c+k/2-h.O.y-h.Ia.y,f=Math.max(f,p+s.height),0>p&&(f-=p),0>h.na.y&&(c=Sq(a,h.na.y,!0,c,k))));for(r=0;r<g;r++)h=b[r],m?h.na.p(h.na.x,h.na.y+f):h.na.p(h.na.x+d,h.na.y);a=[];a[0]=c;a[1]=d;a[2]=f;return a}
function Lq(a){e&&t.l(a,Pp,W,"fixRelativePostions:child");return null===a.parent?0:a.parent.nodeSpacing}function Mq(a){e&&t.l(a,Pp,W,"fixRelativePostions:lastchild");return null===a.parent?0:a.parent.rowSpacing}function Sq(a,b,c,d,f){e&&t.l(a,Pp,W,"fixRelativePostions:v");a=a.children;for(var g=a.length,h=0;h<g;h++)c?a[h].na.p(a[h].na.x,a[h].na.y-b):a[h].na.p(a[h].na.x-b,a[h].na.y);b=a[g-1];return Math.max(d,c?b.Ia.y+b.O.y-f/2:b.Ia.x+b.O.x-f/2)}
function Hq(a,b,c){e&&t.l(a,Pp,W,"calculateSubwidth:v");switch(a.alignment){case jq:case Gq:var d=b;c+a.width>d&&(d=c+a.width);0>c&&(d-=c);return d;case uq:return a.width>b?a.width:b;case vq:return 2*a.O.x>b?a.width:b+a.width-2*a.O.x;case lq:case aq:return d=Math.min(0,c),c=Math.max(b,c+a.width),Math.max(a.width,c-d);case mq:return a.width-a.O.x+a.nodeSpacing/2+b;case nq:return Math.max(a.width,a.O.x+a.nodeSpacing/2+b);default:return b}}
function Iq(a,b,c){e&&t.l(a,Pp,W,"calculateSubheight:v");switch(a.alignment){case jq:case Gq:var d=b;c+a.height>d&&(d=c+a.height);0>c&&(d-=c);return d;case uq:return a.height>b?a.height:b;case vq:return 2*a.O.y>b?a.height:b+a.height-2*a.O.y;case lq:case aq:return d=Math.min(0,c),c=Math.max(b,c+a.height),Math.max(a.height,c-d);case mq:return a.height-a.O.y+a.nodeSpacing/2+b;case nq:return Math.max(a.height,a.O.y+a.nodeSpacing/2+b);default:return b}}
function Jq(a,b,c){e&&t.l(a,ca,W,"alignOffset:align");switch(a){case Gq:b/=2;c/=2;break;case jq:b/=2;c/=2;break;case uq:c=b=0;break;case vq:break;default:t.m("Unhandled alignment value "+a.toString())}a=[];a[0]=b;a[1]=c;return a}function Bq(a,b,c,d,f,g){e&&t.l(a,Pp,W,"shiftRelPosAlign:v");e&&t.l(b,ca,W,"shiftRelPosAlign:align");b=Jq(b,c,d);Eq(a,b[0],b[1],f,g)}function Eq(a,b,c,d,f){e&&t.l(a,Pp,W,"shiftRelPos:v");if(0!==b||0!==c)for(a=a.children;d<=f;d++){var g=a[d].na;g.x+=b;g.y+=c}}
function Cq(a,b,c,d){e&&(t.l(b,Pp,W,"recordMidPoints:v"),t.i(c,"number",W,"recordMidPoints:x"),t.i(d,"number",W,"recordMidPoints:y"));var f=b.parent;switch(a.If){case Lp:for(a=b.tc;a.next();)b=a.value,b.fromVertex===f&&b.Sr.p(c,d);break;case Tp:for(a=b.gc;a.next();)b=a.value,b.toVertex===f&&b.Sr.p(c,d);break;default:t.m("Unhandled path value "+a.If.toString())}}function Fq(a,b,c){for(var d=0;d<a.length;d++){var f=a[d];f.x+=b;f.y+=c}}
function Dq(a,b,c,d,f,g,h){e&&t.l(b,Pp,W,"mergeFringes:parent");e&&t.l(c,Pp,W,"mergeFringes:child");var k=tq(b),l=90===k||270===k,m=b.nodeSpacing;b=d;var n=f;d=g;f=h;var p=c.tt,q=c.Kt;h=c.Za;var r=l?Math.max(f,h.height):Math.max(d,h.width);if(null===p||k!==tq(c))p=Aq(a,2),q=Aq(a,2),l?(p[0].p(0,0),p[1].p(0,h.height),q[0].p(h.width,0),q[1].p(q[0].x,p[1].y)):(p[0].p(0,0),p[1].p(h.width,0),q[0].p(0,h.height),q[1].p(p[1].x,q[0].y));if(l){c=d;d=9999999;if(!(null===n||2>n.length||null===p||2>p.length))for(g=
f=0;f<n.length&&g<p.length;){var k=n[f],s=p[g],l=s.x,u=s.y,l=l+c,v=k;f+1<n.length&&(v=n[f+1]);var D=s,s=D.x,D=D.y;g+1<p.length&&(D=p[g+1],s=D.x,D=D.y,s+=c);var A=d;k.y===u?A=l-k.x:k.y>u&&k.y<D?A=l+(k.y-u)/(D-u)*(s-l)-k.x:u>k.y&&u<v.y&&(A=l-(k.x+(u-k.y)/(v.y-k.y)*(v.x-k.x)));A<d&&(d=A);v.y<=k.y?f++:D<=u?g++:(v.y<=D&&f++,D<=v.y&&g++)}c-=d;c+=m;f=p;g=c;if(null===b||2>b.length||null===f||2>f.length)d=null;else{m=Aq(a,b.length+f.length);for(d=l=k=0;l<f.length&&f[l].y<b[0].y;)u=f[l++],m[d++].p(u.x+g,u.y);
for(;k<b.length;)u=b[k++],m[d++].p(u.x,u.y);for(k=b[b.length-1].y;l<f.length&&f[l].y<=k;)l++;for(;l<f.length&&f[l].y>k;)u=f[l++],m[d++].p(u.x+g,u.y);f=Aq(a,d);for(k=0;k<d;k++)f[k].assign(m[k]);Kq(a,m);d=f}l=q;u=c;if(null===n||2>n.length||null===l||2>l.length)f=null;else{m=Aq(a,n.length+l.length);for(g=v=f=0;f<n.length&&n[f].y<l[0].y;)k=n[f++],m[g++].p(k.x,k.y);for(;v<l.length;)k=l[v++],m[g++].p(k.x+u,k.y);for(l=l[l.length-1].y;f<n.length&&n[f].y<=l;)f++;for(;f<n.length&&n[f].y>l;)k=n[f++],m[g++].p(k.x,
k.y);k=Aq(a,g);for(f=0;f<g;f++)k[f].assign(m[f]);Kq(a,m);f=k}g=Math.max(0,c)+h.width;h=r}else{c=f;d=9999999;if(!(null===n||2>n.length||null===p||2>p.length))for(g=f=0;f<n.length&&g<p.length;)k=n[f],s=p[g],l=s.x,u=s.y,u+=c,v=k,f+1<n.length&&(v=n[f+1]),D=s,s=D.x,D=D.y,g+1<p.length&&(D=p[g+1],s=D.x,D=D.y,D+=c),A=d,k.x===l?A=u-k.y:k.x>l&&k.x<s?A=u+(k.x-l)/(s-l)*(D-u)-k.y:l>k.x&&l<v.x&&(A=u-(k.y+(l-k.x)/(v.x-k.x)*(v.y-k.y))),A<d&&(d=A),v.x<=k.x?f++:s<=l?g++:(v.x<=s&&f++,s<=v.x&&g++);c-=d;c+=m;f=p;g=c;
if(null===b||2>b.length||null===f||2>f.length)d=null;else{m=Aq(a,b.length+f.length);for(d=l=k=0;l<f.length&&f[l].x<b[0].x;)u=f[l++],m[d++].p(u.x,u.y+g);for(;k<b.length;)u=b[k++],m[d++].p(u.x,u.y);for(k=b[b.length-1].x;l<f.length&&f[l].x<=k;)l++;for(;l<f.length&&f[l].x>k;)u=f[l++],m[d++].p(u.x,u.y+g);f=Aq(a,d);for(k=0;k<d;k++)f[k].assign(m[k]);Kq(a,m);d=f}l=q;u=c;if(null===n||2>n.length||null===l||2>l.length)f=null;else{m=Aq(a,n.length+l.length);for(g=v=f=0;f<n.length&&n[f].x<l[0].x;)k=n[f++],m[g++].p(k.x,
k.y);for(;v<l.length;)k=l[v++],m[g++].p(k.x,k.y+u);for(l=l[l.length-1].x;f<n.length&&n[f].x<=l;)f++;for(;f<n.length&&n[f].x>l;)k=n[f++],m[g++].p(k.x,k.y);k=Aq(a,g);for(f=0;f<g;f++)k[f].assign(m[f]);Kq(a,m);f=k}g=r;h=Math.max(0,c)+h.height}Kq(a,b);Kq(a,p);Kq(a,n);Kq(a,q);a=[];a[0]=c;a[1]=d;a[2]=f;a[3]=g;a[4]=h;return a}function Aq(a,b){a.ws||(a.ws=[]);var c=a.ws[b];if(void 0!==c&&(c=c.pop(),void 0!==c))return c;for(var c=[],d=0;d<b;d++)c[d]=new w;return c}
function Kq(a,b){if(a.ws){var c=b.length,d=a.ws[c];void 0===d&&(d=[],a.ws[c]=d);d.push(b)}}
W.prototype.arrangeTrees=function(){if(this.cd===Sp)for(var a=this.Yc.k;a.next();){var b=a.value,c=b.Fc;if(null!==c){var d=c.position,c=d.x,d=d.y;isFinite(c)||(c=0);isFinite(d)||(d=0);Tq(this,b,c,d)}}else for(a=this.Fd,c=a.x,d=a.y,a=this.Yc.k;a.next();)switch(b=a.value,Tq(this,b,c+b.Ia.x,d+b.Ia.y),this.cd){case Op:d+=b.Za.height+this.bg.height;break;case Uq:c+=b.Za.width+this.bg.width;break;default:t.m("Unhandled arrangement value "+this.cd.toString())}};
function Tq(a,b,c,d){if(null!==b){e&&t.l(b,Pp,W,"assignAbsolutePositions:v");b.x=c;b.y=d;b=b.children;for(var f=b.length,g=0;g<f;g++){var h=b[g];Tq(a,h,c+h.na.x,d+h.na.y)}}}W.prototype.commitLayout=function(){this.bB();this.commitNodes();this.Uz();this.rt&&this.commitLinks()};W.prototype.commitNodes=function(){for(var a=this.network.vertexes.k,b;a.next();)b=a.value,b.commit();for(a.reset();a.next();)b=a.value,this.layoutComments(b)};
W.prototype.Uz=function(){if(this.Xw===Yp){for(var a=this.EB,b=[],c=null,d=this.network.vertexes.k;d.next();){var f=d.value;null===c?c=f.Pb.copy():c.ii(f.Pb);var g=b[f.level],g=void 0===g?wq(f):Math.max(g,wq(f));b[f.level]=g}for(d=0;d<b.length;d++)void 0===b[d]&&(b[d]=0);90===this.angle||270===this.angle?(c.Sf(this.nodeSpacing/2,this.layerSpacing),f=new w(-this.nodeSpacing/2,-this.layerSpacing/2)):(c.Sf(this.layerSpacing,this.nodeSpacing/2),f=new w(-this.layerSpacing/2,-this.nodeSpacing/2));var g=
[],c=90===this.angle||270===this.angle?c.width:c.height,h=0;if(180===this.angle||270===this.angle)for(d=0;d<a.length;d++)h+=a[d]+b[d];for(d=0;d<a.length;d++){var k=a[d]+b[d];270===this.angle?(h-=k,g.push(new x(0,h,c,k))):90===this.angle?(g.push(new x(0,h,c,k)),h+=k):180===this.angle?(h-=k,g.push(new x(h,0,k,c))):(g.push(new x(h,0,k,c)),h+=k)}this.commitLayers(g,f)}};W.prototype.commitLayers=function(){};W.prototype.commitLinks=function(){for(var a=this.network.edges.k;a.next();)a.value.commit()};
W.prototype.bB=function(){for(var a=this.Yc.k;a.next();)Vq(this,a.value)};function Vq(a,b){if(null!==b){e&&t.l(b,Pp,W,"setPortSpotsTree:v");a.setPortSpots(b);for(var c=b.children,d=c.length,f=0;f<d;f++)Vq(a,c[f])}}
W.prototype.setPortSpots=function(a){e&&t.l(a,Pp,W,"setPortSpots:v");var b=a.alignment;if(kq(b)){e&&t.l(a,Pp,W,"setPortSpotsBus:v");e&&t.l(b,ca,W,"setPortSpots:align");var c=this.If===Lp,d=tq(a),f;switch(d){case 0:f=Wb;break;case 90:f=Xb;break;case 180:f=Vb;break;default:f=Qb}var g=a.children,h=g.length;switch(b){case lq:case aq:for(b=0;b<h;b++){var k=g[b],k=c?k.tc:k.gc;if(k.next()){var l=k.value,l=l.link;if(null!==l){var m=90===d||270===d?Vb:Qb;if(1===h||b===h-1&&1===h%2)switch(d){case 0:m=Vb;break;
case 90:m=Qb;break;case 180:m=Wb;break;default:m=Xb}else 0===b%2&&(m=90===d||270===d?Wb:Xb);c?(a.setsPortSpot&&(l.ob=f),a.setsChildPortSpot&&(l.rb=m)):(a.setsPortSpot&&(l.ob=m),a.setsChildPortSpot&&(l.rb=f))}}}break;case mq:m=90===d||270===d?Wb:Xb;for(k=c?a.gc:a.tc;k.next();)l=k.value,l=l.link,null!==l&&(c?(a.setsPortSpot&&(l.ob=f),a.setsChildPortSpot&&(l.rb=m)):(a.setsPortSpot&&(l.ob=m),a.setsChildPortSpot&&(l.rb=f)));break;case nq:for(m=90===d||270===d?Vb:Qb,k=c?a.gc:a.tc;k.next();)l=k.value,l=
l.link,null!==l&&(c?(a.setsPortSpot&&(l.ob=f),a.setsChildPortSpot&&(l.rb=m)):(a.setsPortSpot&&(l.ob=m),a.setsChildPortSpot&&(l.rb=f)))}}else if(c=tq(a),this.If===Lp)for(d=a.gc;d.next();){if(f=d.value,f=f.link,null!==f){if(a.setsPortSpot)if(a.portSpot.Pc())switch(c){case 0:f.ob=Wb;break;case 90:f.ob=Xb;break;case 180:f.ob=Vb;break;default:f.ob=Qb}else f.ob=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Pc())switch(c){case 0:f.rb=Vb;break;case 90:f.rb=Qb;break;case 180:f.rb=Wb;break;default:f.rb=
Xb}else f.rb=a.childPortSpot}}else for(d=a.tc;d.next();)if(f=d.value,f=f.link,null!==f){if(a.setsPortSpot)if(a.portSpot.Pc())switch(c){case 0:f.rb=Wb;break;case 90:f.rb=Xb;break;case 180:f.rb=Vb;break;default:f.rb=Qb}else f.rb=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Pc())switch(c){case 0:f.ob=Vb;break;case 90:f.ob=Qb;break;case 180:f.ob=Wb;break;default:f.ob=Xb}else f.ob=a.childPortSpot}};function tq(a){a=a.angle;return 45>=a?0:135>=a?90:225>=a?180:315>=a?270:0}
function wq(a){e&&t.l(a,Pp,W,"computeLayerSpacing:v");var b=tq(a),b=90===b||270===b,c=a.layerSpacing;if(0<a.layerSpacingParentOverlap)var d=Math.min(1,a.layerSpacingParentOverlap),c=c-(b?a.height*d:a.width*d);c<(b?-a.height:-a.width)&&(c=b?-a.height:-a.width);return c}function xq(a){e&&t.l(a,Pp,W,"computeNodeIndent:v");var b=tq(a),b=90===b||270===b,c=a.nodeIndent;if(0<a.nodeIndentPastParent)var d=Math.min(1,a.nodeIndentPastParent),c=c+(b?a.width*d:a.height*d);return c=Math.max(0,c)}
t.g(W,"roots",W.prototype.MJ);t.defineProperty(W,{MJ:"roots"},function(){return this.Yc},function(a){this.Yc!==a&&(t.l(a,ma,W,"roots"),this.Yc=a,this.J())});t.g(W,"path",W.prototype.path);t.defineProperty(W,{path:"path"},function(){return this.Ji},function(a){this.Ji!==a&&(t.Aa(a,W,W,"path"),this.Ji=a,this.J())});t.g(W,"treeStyle",W.prototype.MG);
t.defineProperty(W,{MG:"treeStyle"},function(){return this.Cs},function(a){this.cd!==a&&(t.Aa(a,W,W,"treeStyle"),a===Mp||a===dq||a===eq||a===cq)&&(this.Cs=a,this.J())});t.g(W,"layerStyle",W.prototype.Xw);t.defineProperty(W,{Xw:"layerStyle"},function(){return this.Zu},function(a){this.cd!==a&&(t.Aa(a,W,W,"layerStyle"),a===Np||a===Zp||a===Yp)&&(this.Zu=a,this.J())});t.g(W,"comments",W.prototype.comments);
t.defineProperty(W,{comments:"comments"},function(){return this.sh},function(a){this.sh!==a&&(t.i(a,"boolean",W,"comments"),this.sh=a,this.J())});t.g(W,"arrangement",W.prototype.Qe);t.defineProperty(W,{Qe:"arrangement"},function(){return this.cd},function(a){this.cd!==a&&(t.Aa(a,W,W,"arrangement"),a===Op||a===Uq||a===Sp)&&(this.cd=a,this.J())});t.g(W,"arrangementSpacing",W.prototype.fw);
t.defineProperty(W,{fw:"arrangementSpacing"},function(){return this.bg},function(a){t.l(a,fa,W,"arrangementSpacing");this.bg.M(a)||(this.bg.assign(a),this.J())});t.g(W,"rootDefaults",W.prototype.LJ);t.defineProperty(W,{LJ:"rootDefaults"},function(){return this.ua},function(a){this.ua!==a&&(t.l(a,Pp,W,"rootDefaults"),this.ua=a,this.J())});t.g(W,"alternateDefaults",W.prototype.NH);
t.defineProperty(W,{NH:"alternateDefaults"},function(){return this.ta},function(a){this.ta!==a&&(t.l(a,Pp,W,"alternateDefaults"),this.ta=a,this.J())});t.g(W,"sorting",W.prototype.sorting);t.defineProperty(W,{sorting:"sorting"},function(){return this.ua.sorting},function(a){this.ua.sorting!==a&&(t.Aa(a,W,W,"sorting"),a===fq||a===gq||a===hq||iq)&&(this.ua.sorting=a,this.J())});t.g(W,"comparer",W.prototype.comparer);
t.defineProperty(W,{comparer:"comparer"},function(){return this.ua.comparer},function(a){this.ua.comparer!==a&&(t.i(a,"function",W,"comparer"),this.ua.comparer=a,this.J())});t.g(W,"angle",W.prototype.angle);t.defineProperty(W,{angle:"angle"},function(){return this.ua.angle},function(a){this.ua.angle!==a&&(t.i(a,"number",W,"angle"),0===a||90===a||180===a||270===a)&&(this.ua.angle=a,this.J())});t.g(W,"alignment",W.prototype.alignment);
t.defineProperty(W,{alignment:"alignment"},function(){return this.ua.alignment},function(a){this.ua.alignment!==a&&(t.Aa(a,W,W,"alignment"),this.ua.alignment=a,this.J())});t.g(W,"nodeIndent",W.prototype.nodeIndent);t.defineProperty(W,{nodeIndent:"nodeIndent"},function(){return this.ua.nodeIndent},function(a){this.ua.nodeIndent!==a&&(t.i(a,"number",W,"nodeIndent"),0<=a&&(this.ua.nodeIndent=a,this.J()))});t.g(W,"nodeIndentPastParent",W.prototype.nodeIndentPastParent);
t.defineProperty(W,{nodeIndentPastParent:"nodeIndentPastParent"},function(){return this.ua.nodeIndentPastParent},function(a){this.ua.nodeIndentPastParent!==a&&(t.i(a,"number",W,"nodeIndentPastParent"),0<=a&&1>=a&&(this.ua.nodeIndentPastParent=a,this.J()))});t.g(W,"nodeSpacing",W.prototype.nodeSpacing);t.defineProperty(W,{nodeSpacing:"nodeSpacing"},function(){return this.ua.nodeSpacing},function(a){this.ua.nodeSpacing!==a&&(t.i(a,"number",W,"nodeSpacing"),this.ua.nodeSpacing=a,this.J())});
t.g(W,"layerSpacing",W.prototype.layerSpacing);t.defineProperty(W,{layerSpacing:"layerSpacing"},function(){return this.ua.layerSpacing},function(a){this.ua.layerSpacing!==a&&(t.i(a,"number",W,"layerSpacing"),this.ua.layerSpacing=a,this.J())});t.g(W,"layerSpacingParentOverlap",W.prototype.layerSpacingParentOverlap);
t.defineProperty(W,{layerSpacingParentOverlap:"layerSpacingParentOverlap"},function(){return this.ua.layerSpacingParentOverlap},function(a){this.ua.layerSpacingParentOverlap!==a&&(t.i(a,"number",W,"layerSpacingParentOverlap"),0<=a&&1>=a&&(this.ua.layerSpacingParentOverlap=a,this.J()))});t.g(W,"compaction",W.prototype.compaction);
t.defineProperty(W,{compaction:"compaction"},function(){return this.ua.compaction},function(a){this.ua.compaction!==a&&(t.Aa(a,W,W,"compaction"),a===qq||a===sq)&&(this.ua.compaction=a,this.J())});t.g(W,"breadthLimit",W.prototype.breadthLimit);t.defineProperty(W,{breadthLimit:"breadthLimit"},function(){return this.ua.breadthLimit},function(a){this.ua.breadthLimit!==a&&(t.i(a,"number",W,"breadthLimit"),0<=a&&(this.ua.breadthLimit=a,this.J()))});t.g(W,"rowSpacing",W.prototype.rowSpacing);
t.defineProperty(W,{rowSpacing:"rowSpacing"},function(){return this.ua.rowSpacing},function(a){this.ua.rowSpacing!==a&&(t.i(a,"number",W,"rowSpacing"),this.ua.rowSpacing=a,this.J())});t.g(W,"rowIndent",W.prototype.rowIndent);t.defineProperty(W,{rowIndent:"rowIndent"},function(){return this.ua.rowIndent},function(a){this.ua.rowIndent!==a&&(t.i(a,"number",W,"rowIndent"),0<=a&&(this.ua.rowIndent=a,this.J()))});t.g(W,"commentSpacing",W.prototype.commentSpacing);
t.defineProperty(W,{commentSpacing:"commentSpacing"},function(){return this.ua.commentSpacing},function(a){this.ua.commentSpacing!==a&&(t.i(a,"number",W,"commentSpacing"),this.ua.commentSpacing=a,this.J())});t.g(W,"commentMargin",W.prototype.commentMargin);t.defineProperty(W,{commentMargin:"commentMargin"},function(){return this.ua.commentMargin},function(a){this.ua.commentMargin!==a&&(t.i(a,"number",W,"commentMargin"),this.ua.commentMargin=a,this.J())});t.g(W,"setsPortSpot",W.prototype.setsPortSpot);
t.defineProperty(W,{setsPortSpot:"setsPortSpot"},function(){return this.ua.setsPortSpot},function(a){this.ua.setsPortSpot!==a&&(t.i(a,"boolean",W,"setsPortSpot"),this.ua.setsPortSpot=a,this.J())});t.g(W,"portSpot",W.prototype.portSpot);t.defineProperty(W,{portSpot:"portSpot"},function(){return this.ua.portSpot},function(a){t.l(a,H,W,"portSpot");this.ua.portSpot.M(a)||(this.ua.portSpot=a,this.J())});t.g(W,"setsChildPortSpot",W.prototype.setsChildPortSpot);
t.defineProperty(W,{setsChildPortSpot:"setsChildPortSpot"},function(){return this.ua.setsChildPortSpot},function(a){this.ua.setsChildPortSpot!==a&&(t.i(a,"boolean",W,"setsChildPortSpot"),this.ua.setsChildPortSpot=a,this.J())});t.g(W,"childPortSpot",W.prototype.childPortSpot);t.defineProperty(W,{childPortSpot:"childPortSpot"},function(){return this.ua.childPortSpot},function(a){t.l(a,H,W,"childPortSpot");this.ua.childPortSpot.M(a)||(this.ua.childPortSpot=a,this.J())});t.g(W,"alternateSorting",W.prototype.YH);
t.defineProperty(W,{YH:"alternateSorting"},function(){return this.ta.sorting},function(a){this.ta.sorting!==a&&(t.Aa(a,W,W,"alternateSorting"),a===fq||a===gq||a===hq||iq)&&(this.ta.sorting=a,this.J())});t.g(W,"alternateComparer",W.prototype.MH);t.defineProperty(W,{MH:"alternateComparer"},function(){return this.ta.comparer},function(a){this.ta.comparer!==a&&(t.i(a,"function",W,"alternateComparer"),this.ta.comparer=a,this.J())});t.g(W,"alternateAngle",W.prototype.GH);
t.defineProperty(W,{GH:"alternateAngle"},function(){return this.ta.angle},function(a){this.ta.angle!==a&&(t.i(a,"number",W,"alternateAngle"),0===a||90===a||180===a||270===a)&&(this.ta.angle=a,this.J())});t.g(W,"alternateAlignment",W.prototype.FH);t.defineProperty(W,{FH:"alternateAlignment"},function(){return this.ta.alignment},function(a){this.ta.alignment!==a&&(t.Aa(a,W,W,"alternateAlignment"),this.ta.alignment=a,this.J())});t.g(W,"alternateNodeIndent",W.prototype.QH);
t.defineProperty(W,{QH:"alternateNodeIndent"},function(){return this.ta.nodeIndent},function(a){this.ta.nodeIndent!==a&&(t.i(a,"number",W,"alternateNodeIndent"),0<=a&&(this.ta.nodeIndent=a,this.J()))});t.g(W,"alternateNodeIndentPastParent",W.prototype.RH);
t.defineProperty(W,{RH:"alternateNodeIndentPastParent"},function(){return this.ta.nodeIndentPastParent},function(a){this.ta.nodeIndentPastParent!==a&&(t.i(a,"number",W,"alternateNodeIndentPastParent"),0<=a&&1>=a&&(this.ta.nodeIndentPastParent=a,this.J()))});t.g(W,"alternateNodeSpacing",W.prototype.SH);t.defineProperty(W,{SH:"alternateNodeSpacing"},function(){return this.ta.nodeSpacing},function(a){this.ta.nodeSpacing!==a&&(t.i(a,"number",W,"alternateNodeSpacing"),this.ta.nodeSpacing=a,this.J())});
t.g(W,"alternateLayerSpacing",W.prototype.OH);t.defineProperty(W,{OH:"alternateLayerSpacing"},function(){return this.ta.layerSpacing},function(a){this.ta.layerSpacing!==a&&(t.i(a,"number",W,"alternateLayerSpacing"),this.ta.layerSpacing=a,this.J())});t.g(W,"alternateLayerSpacingParentOverlap",W.prototype.PH);
t.defineProperty(W,{PH:"alternateLayerSpacingParentOverlap"},function(){return this.ta.layerSpacingParentOverlap},function(a){this.ta.layerSpacingParentOverlap!==a&&(t.i(a,"number",W,"alternateLayerSpacingParentOverlap"),0<=a&&1>=a&&(this.ta.layerSpacingParentOverlap=a,this.J()))});t.g(W,"alternateCompaction",W.prototype.LH);
t.defineProperty(W,{LH:"alternateCompaction"},function(){return this.ta.compaction},function(a){this.ta.compaction!==a&&(t.Aa(a,W,W,"alternateCompaction"),a===qq||a===sq)&&(this.ta.compaction=a,this.J())});t.g(W,"alternateBreadthLimit",W.prototype.HH);t.defineProperty(W,{HH:"alternateBreadthLimit"},function(){return this.ta.breadthLimit},function(a){this.ta.breadthLimit!==a&&(t.i(a,"number",W,"alternateBreadthLimit"),0<=a&&(this.ta.breadthLimit=a,this.J()))});t.g(W,"alternateRowSpacing",W.prototype.VH);
t.defineProperty(W,{VH:"alternateRowSpacing"},function(){return this.ta.rowSpacing},function(a){this.ta.rowSpacing!==a&&(t.i(a,"number",W,"alternateRowSpacing"),this.ta.rowSpacing=a,this.J())});t.g(W,"alternateRowIndent",W.prototype.UH);t.defineProperty(W,{UH:"alternateRowIndent"},function(){return this.ta.rowIndent},function(a){this.ta.rowIndent!==a&&(t.i(a,"number",W,"alternateRowIndent"),0<=a&&(this.ta.rowIndent=a,this.J()))});t.g(W,"alternateCommentSpacing",W.prototype.KH);
t.defineProperty(W,{KH:"alternateCommentSpacing"},function(){return this.ta.commentSpacing},function(a){this.ta.commentSpacing!==a&&(t.i(a,"number",W,"alternateCommentSpacing"),this.ta.commentSpacing=a,this.J())});t.g(W,"alternateCommentMargin",W.prototype.JH);t.defineProperty(W,{JH:"alternateCommentMargin"},function(){return this.ta.commentMargin},function(a){this.ta.commentMargin!==a&&(t.i(a,"number",W,"alternateCommentMargin"),this.ta.commentMargin=a,this.J())});t.g(W,"alternateSetsPortSpot",W.prototype.XH);
t.defineProperty(W,{XH:"alternateSetsPortSpot"},function(){return this.ta.setsPortSpot},function(a){this.ta.setsPortSpot!==a&&(t.i(a,"boolean",W,"alternateSetsPortSpot"),this.ta.setsPortSpot=a,this.J())});t.g(W,"alternatePortSpot",W.prototype.TH);t.defineProperty(W,{TH:"alternatePortSpot"},function(){return this.ta.portSpot},function(a){t.l(a,H,W,"alternatePortSpot");this.ta.portSpot.M(a)||(this.ta.portSpot=a,this.J())});t.g(W,"alternateSetsChildPortSpot",W.prototype.WH);
t.defineProperty(W,{WH:"alternateSetsChildPortSpot"},function(){return this.ta.setsChildPortSpot},function(a){this.ta.setsChildPortSpot!==a&&(t.i(a,"boolean",W,"alternateSetsChildPortSpot"),this.ta.setsChildPortSpot=a,this.J())});t.g(W,"alternateChildPortSpot",W.prototype.IH);t.defineProperty(W,{IH:"alternateChildPortSpot"},function(){return this.ta.childPortSpot},function(a){t.l(a,H,W,"alternateChildPortSpot");this.ta.childPortSpot.M(a)||(this.ta.childPortSpot=a,this.J())});var Kp;
W.PathDefault=Kp=t.w(W,"PathDefault",-1);var Lp;W.PathDestination=Lp=t.w(W,"PathDestination",0);var Tp;W.PathSource=Tp=t.w(W,"PathSource",1);var fq;W.SortingForwards=fq=t.w(W,"SortingForwards",10);var gq;W.SortingReverse=gq=t.w(W,"SortingReverse",11);var hq;W.SortingAscending=hq=t.w(W,"SortingAscending",12);var iq;W.SortingDescending=iq=t.w(W,"SortingDescending",13);var Gq;W.AlignmentCenterSubtrees=Gq=t.w(W,"AlignmentCenterSubtrees",20);var jq;
W.AlignmentCenterChildren=jq=t.w(W,"AlignmentCenterChildren",21);var uq;W.AlignmentStart=uq=t.w(W,"AlignmentStart",22);var vq;W.AlignmentEnd=vq=t.w(W,"AlignmentEnd",23);var lq;W.AlignmentBus=lq=t.w(W,"AlignmentBus",24);var aq;W.AlignmentBusBranching=aq=t.w(W,"AlignmentBusBranching",25);var mq;W.AlignmentTopLeftBus=mq=t.w(W,"AlignmentTopLeftBus",26);var nq;W.AlignmentBottomRightBus=nq=t.w(W,"AlignmentBottomRightBus",27);var qq;W.CompactionNone=qq=t.w(W,"CompactionNone",30);var sq;
W.CompactionBlock=sq=t.w(W,"CompactionBlock",31);var Mp;W.StyleLayered=Mp=t.w(W,"StyleLayered",40);var eq;W.StyleLastParents=eq=t.w(W,"StyleLastParents",41);var dq;W.StyleAlternating=dq=t.w(W,"StyleAlternating",42);var cq;W.StyleRootOnly=cq=t.w(W,"StyleRootOnly",43);var Op;W.ArrangementVertical=Op=t.w(W,"ArrangementVertical",50);var Uq;W.ArrangementHorizontal=Uq=t.w(W,"ArrangementHorizontal",51);var Sp;W.ArrangementFixedRoots=Sp=t.w(W,"ArrangementFixedRoots",52);var Np;
W.LayerIndividual=Np=t.w(W,"LayerIndividual",60);var Zp;W.LayerSiblings=Zp=t.w(W,"LayerSiblings",61);var Yp;W.LayerUniform=Yp=t.w(W,"LayerUniform",62);function Qp(){ta.call(this)}t.ga("TreeNetwork",Qp);t.Ka(Qp,ta);Qp.prototype.createVertex=function(){return new Pp};Qp.prototype.createEdge=function(){return new Wq};
function Pp(){ua.call(this);this.initialized=!1;this.parent=null;this.children=[];this.maxGenerationCount=this.maxChildrenCount=this.descendantCount=this.level=0;this.comments=null;this.na=new w(0,0);this.Za=new fa(0,0);this.Ia=new w(0,0);this.Pp=this.Op=this.NJ=!1;this.Kt=this.tt=null;this.sorting=fq;this.comparer=Un;this.angle=0;this.alignment=jq;this.nodeIndentPastParent=this.nodeIndent=0;this.nodeSpacing=20;this.layerSpacing=50;this.layerSpacingParentOverlap=0;this.compaction=sq;this.breadthLimit=
0;this.rowSpacing=25;this.commentSpacing=this.rowIndent=10;this.commentMargin=20;this.setsPortSpot=!0;this.portSpot=xb;this.setsChildPortSpot=!0;this.childPortSpot=xb}t.ga("TreeVertex",Pp);t.Ka(Pp,ua);
Pp.prototype.copyInheritedPropertiesFrom=function(a){null!==a&&(this.sorting=a.sorting,this.comparer=a.comparer,this.angle=a.angle,this.alignment=a.alignment,this.nodeIndent=a.nodeIndent,this.nodeIndentPastParent=a.nodeIndentPastParent,this.nodeSpacing=a.nodeSpacing,this.layerSpacing=a.layerSpacing,this.layerSpacingParentOverlap=a.layerSpacingParentOverlap,this.compaction=a.compaction,this.breadthLimit=a.breadthLimit,this.rowSpacing=a.rowSpacing,this.rowIndent=a.rowIndent,this.commentSpacing=a.commentSpacing,
this.commentMargin=a.commentMargin,this.setsPortSpot=a.setsPortSpot,this.portSpot=a.portSpot,this.setsChildPortSpot=a.setsChildPortSpot,this.childPortSpot=a.childPortSpot)};t.A(Pp,{Im:"childrenCount"},function(){return this.children.length});t.g(Pp,"relativePosition",Pp.prototype.HJ);t.defineProperty(Pp,{HJ:"relativePosition"},function(){return this.na},function(a){this.na.set(a)});t.g(Pp,"subtreeSize",Pp.prototype.$J);t.defineProperty(Pp,{$J:"subtreeSize"},function(){return this.Za},function(a){this.Za.set(a)});
t.g(Pp,"subtreeOffset",Pp.prototype.ZJ);t.defineProperty(Pp,{ZJ:"subtreeOffset"},function(){return this.Ia},function(a){this.Ia.set(a)});function Wq(){va.call(this);this.Sr=new w(0,0)}t.ga("TreeEdge",Wq);t.Ka(Wq,va);
Wq.prototype.commit=function(){var a=this.link;if(null!==a&&!a.dj){var b=this.network.jc,c=null,d=null;switch(b.If){case Lp:c=this.fromVertex;d=this.toVertex;break;case Tp:c=this.toVertex;d=this.fromVertex;break;default:t.m("Unhandled path value "+b.If.toString())}if(null!==c&&null!==d)if(b=this.Sr,0!==b.x||0!==b.y||c.NJ){var d=c.Pb,f=tq(c),g=wq(c),h=c.rowSpacing;a.updateRoute();var k=a.De===Pg,l=a.ic,m,n,p,q;a.zl();if(l||k){for(m=2;4<a.pa;)a.PF(2);n=a.o(1);p=a.o(2)}else{for(m=1;3<a.pa;)a.PF(1);n=
a.o(0);p=a.o(a.pa-1)}q=a.o(a.pa-1);0===f?(c.alignment===vq?(f=d.bottom+b.y,0===b.y&&n.y>q.y+c.rowIndent&&(f=Math.min(f,Math.max(n.y,f-xq(c))))):c.alignment===uq?(f=d.top+b.y,0===b.y&&n.y<q.y-c.rowIndent&&(f=Math.max(f,Math.min(n.y,f+xq(c))))):f=c.Op||c.Pp&&1===c.maxGenerationCount?d.top-c.Ia.y+b.y:d.y+d.height/2+b.y,k?(a.C(m,n.x,f),m++,a.C(m,d.right+g,f),m++,a.C(m,d.right+g+(b.x-h)/3,f),m++,a.C(m,d.right+g+2*(b.x-h)/3,f),m++,a.C(m,d.right+g+(b.x-h),f),m++,a.C(m,p.x,f)):(l&&(a.C(m,d.right+g/2,n.y),
m++),a.C(m,d.right+g/2,f),m++,a.C(m,d.right+g+b.x-(l?h/2:h),f),m++,l&&a.C(m,a.o(m-1).x,p.y))):90===f?(c.alignment===vq?(f=d.right+b.x,0===b.x&&n.x>q.x+c.rowIndent&&(f=Math.min(f,Math.max(n.x,f-xq(c))))):c.alignment===uq?(f=d.left+b.x,0===b.x&&n.x<q.x-c.rowIndent&&(f=Math.max(f,Math.min(n.x,f+xq(c))))):f=c.Op||c.Pp&&1===c.maxGenerationCount?d.left-c.Ia.x+b.x:d.x+d.width/2+b.x,k?(a.C(m,f,n.y),m++,a.C(m,f,d.bottom+g),m++,a.C(m,f,d.bottom+g+(b.y-h)/3),m++,a.C(m,f,d.bottom+g+2*(b.y-h)/3),m++,a.C(m,f,d.bottom+
g+(b.y-h)),m++,a.C(m,f,p.y)):(l&&(a.C(m,n.x,d.bottom+g/2),m++),a.C(m,f,d.bottom+g/2),m++,a.C(m,f,d.bottom+g+b.y-(l?h/2:h)),m++,l&&a.C(m,p.x,a.o(m-1).y))):180===f?(c.alignment===vq?(f=d.bottom+b.y,0===b.y&&n.y>q.y+c.rowIndent&&(f=Math.min(f,Math.max(n.y,f-xq(c))))):c.alignment===uq?(f=d.top+b.y,0===b.y&&n.y<q.y-c.rowIndent&&(f=Math.max(f,Math.min(n.y,f+xq(c))))):f=c.Op||c.Pp&&1===c.maxGenerationCount?d.top-c.Ia.y+b.y:d.y+d.height/2+b.y,k?(a.C(m,n.x,f),m++,a.C(m,d.left-g,f),m++,a.C(m,d.left-g+(b.x+
h)/3,f),m++,a.C(m,d.left-g+2*(b.x+h)/3,f),m++,a.C(m,d.left-g+(b.x+h),f),m++,a.C(m,p.x,f)):(l&&(a.C(m,d.left-g/2,n.y),m++),a.C(m,d.left-g/2,f),m++,a.C(m,d.left-g+b.x+(l?h/2:h),f),m++,l&&a.C(m,a.o(m-1).x,p.y))):270===f?(c.alignment===vq?(f=d.right+b.x,0===b.x&&n.x>q.x+c.rowIndent&&(f=Math.min(f,Math.max(n.x,f-xq(c))))):c.alignment===uq?(f=d.left+b.x,0===b.x&&n.x<q.x-c.rowIndent&&(f=Math.max(f,Math.min(n.x,f+xq(c))))):f=c.Op||c.Pp&&1===c.maxGenerationCount?d.left-c.Ia.x+b.x:d.x+d.width/2+b.x,k?(a.C(m,
f,n.y),m++,a.C(m,f,d.top-g),m++,a.C(m,f,d.top-g+(b.y+h)/3),m++,a.C(m,f,d.top-g+2*(b.y+h)/3),m++,a.C(m,f,d.top-g+(b.y+h)),m++,a.C(m,f,p.y)):(l&&(a.C(m,n.x,d.top-g/2),m++),a.C(m,f,d.top-g/2),m++,a.C(m,f,d.top-g+b.y+(l?h/2:h)),m++,l&&a.C(m,p.x,a.o(m-1).y))):t.m("Invalid angle "+f);a.Xi()}else g=c,h=d,e&&t.l(g,Pp,Wq,"adjustRouteForAngleChange:parent"),e&&t.l(h,Pp,Wq,"adjustRouteForAngleChange:child"),a=this.link,c=tq(g),c!==tq(h)&&(b=wq(g),d=g.Pb,g=h.Pb,0===c&&g.left-d.right<b+1||90===c&&g.top-d.bottom<
b+1||180===c&&d.left-g.right<b+1||270===c&&d.top-g.bottom<b+1||(a.updateRoute(),g=a.De===Pg,h=a.ic,k=kq(this.fromVertex.alignment),a.zl(),0===c?(c=d.right+b/2,g?4===a.pa&&(b=a.o(3).y,a.Y(1,c-20,a.o(1).y),a.C(2,c-20,b),a.C(3,c,b),a.C(4,c+20,b),a.Y(5,a.o(5).x,b)):h?k?a.Y(3,a.o(2).x,a.o(4).y):6===a.pa&&(a.Y(2,c,a.o(2).y),a.Y(3,c,a.o(3).y)):4===a.pa?a.C(2,c,a.o(2).y):3===a.pa?a.Y(1,c,a.o(2).y):2===a.pa&&a.C(1,c,a.o(1).y)):90===c?(b=d.bottom+b/2,g?4===a.pa&&(c=a.o(3).x,a.Y(1,a.o(1).x,b-20),a.C(2,c,b-20),
a.C(3,c,b),a.C(4,c,b+20),a.Y(5,c,a.o(5).y)):h?k?a.Y(3,a.o(2).x,a.o(4).y):6===a.pa&&(a.Y(2,a.o(2).x,b),a.Y(3,a.o(3).x,b)):4===a.pa?a.C(2,a.o(2).x,b):3===a.pa?a.Y(1,a.o(2).x,b):2===a.pa&&a.C(1,a.o(1).x,b)):180===c?(c=d.left-b/2,g?4===a.pa&&(b=a.o(3).y,a.Y(1,c+20,a.o(1).y),a.C(2,c+20,b),a.C(3,c,b),a.C(4,c-20,b),a.Y(5,a.o(5).x,b)):h?k?a.Y(3,a.o(2).x,a.o(4).y):6===a.pa&&(a.Y(2,c,a.o(2).y),a.Y(3,c,a.o(3).y)):4===a.pa?a.C(2,c,a.o(2).y):3===a.pa?a.Y(1,c,a.o(2).y):2===a.pa&&a.C(1,c,a.o(1).y)):270===c&&(b=
d.top-b/2,g?4===a.pa&&(c=a.o(3).x,a.Y(1,a.o(1).x,b+20),a.C(2,c,b+20),a.C(3,c,b),a.C(4,c,b-20),a.Y(5,c,a.o(5).y)):h?k?a.Y(3,a.o(2).x,a.o(4).y):6===a.pa&&(a.Y(2,a.o(2).x,b),a.Y(3,a.o(3).x,b)):4===a.pa?a.C(2,a.o(2).x,b):3===a.pa?a.Y(1,a.o(2).x,b):2===a.pa&&a.C(1,a.o(1).x,b)),a.Xi()))}};t.g(Wq,"relativePoint",Wq.prototype.GJ);t.defineProperty(Wq,{GJ:"relativePoint"},function(){return this.Sr},function(a){this.Sr.set(a)});function Xq(){this.Vn=[]}
function Xk(a){var b=new Xq,c;if("string"===typeof a)c=(new DOMParser).parseFromString(a,"text/xml");else if(a instanceof Document)c=a.implementation.createDocument("http://www.w3.org/2000/svg","svg",null),c.appendChild(c.importNode(a.documentElement,!0));else return null;a=c.getElementsByTagName("svg");if(0===a.length)return null;var d=a[0],f=c.getElementsByTagName("linearGradient"),g=c.getElementsByTagName("radialGradient");for(a=0;a<f.length;a++){for(var h=f[a],k=Yk(ea,Yd,{start:Yb,end:Zb}),l=
h.childNodes,m=0;m<l.length;m++)if("stop"===l[m].tagName){var n=Yq(b,l[m],"stop-color");if(n){var p=Yq(b,l[m],"offset");p||(p="0");var q=parseFloat(p);isNaN(q)&&(q=0);k.addColorStop((-1!==p.indexOf("%")?0.01:1)*q,n)}}h=h.getAttribute("id");"string"===typeof h&&(b["_brush"+h]=k)}for(a=0;a<g.length;a++){h=g[a];k=Yk(ea,Zd,{start:Hb,end:Hb});l=h.childNodes;for(m=0;m<l.length;m++)"stop"===l[m].tagName&&(n=Yq(b,l[m],"stop-color"))&&((p=Yq(b,l[m],"offset"))||(p="0"),q=parseFloat(p),isNaN(q)&&(q=0),k.addColorStop((-1!==
p.indexOf("%")?0.01:1)*q,n));h=h.getAttribute("id");"string"===typeof h&&(b["_brush"+h]=k)}for(f=!0;f;)for(f=!1,g=c.getElementsByTagName("use"),a=0;a<g.length;a++)k=g[a],0===k.childNodes.length&&(h=k.href,void 0!==h&&(h=c.getElementById(h.baseVal.substring(1)),null!==h&&(h=h.cloneNode(!0),h.removeAttribute("id"),l=parseFloat(k.getAttribute("x")),isNaN(l)&&(l=0),m=parseFloat(k.getAttribute("y")),isNaN(m)&&(m=0),n=k.getAttribute("transform"),null===n&&(n=""),k.setAttribute("transform",n+" translate("+
l+","+m+")"),k.appendChild(h),"use"===h.tagName&&(f=!0))));Zq(b,d,null);c=new y;if(0===b.Vn.length)return c;if(1===b.Vn.length)return b.Vn[0];for(a=0;a<b.Vn.length;a++)c.add(b.Vn[a]);return c}function $q(a,b){var c=a.getAttribute(b);"string"!==typeof c&&a.style&&(c=a.style[b]);return"string"!==typeof c?null:c}
function Yq(a,b,c){var d=b.getAttribute(c);"string"!==typeof d&&b.style&&(d=b.style[c]);return"string"!==typeof d||""===d||"inherit"===d?(b=b.parentNode,"g"===b.tagName||"use"===b.tagName?Yq(a,b,c):null):d}
function Zq(a,b,c){var d=b.tagName;if(("g"===d||"svg"===d||"use"===d||"symbol"===d)&&"none"!==Yq(a,b,"display")){for(var d=b.childNodes,f=0;f<d.length;f++){var g=d[f],h=null;if(void 0!==g.getAttribute){var k=g.getAttribute("transform");switch(g.tagName){case "g":null===k?Zq(a,g,null):(h=new y,Zq(a,g,h));break;case "use":null===k?Zq(a,g,null):(h=new y,Zq(a,g,h));break;case "symbol":if("use"!==b.tagName)break;h=new y;Zq(a,g,h);var l=h,m=a,n=g;Yq(m,n,"preserveAspectRatio");Yq(m,n,"viewBox");l.scale=
1;break;case "path":l=g;h=new V;l=l.getAttribute("d");"string"===typeof l&&(h.IE=Qc(l));break;case "line":var p=g,h=new V,l=parseFloat(p.getAttribute("x1"));isNaN(l)&&(l=0);m=parseFloat(p.getAttribute("y1"));isNaN(m)&&(m=0);n=parseFloat(p.getAttribute("x2"));isNaN(n)&&(n=0);p=parseFloat(p.getAttribute("y2"));isNaN(p)&&(p=0);var q=new zc(Dc);h.position=new w(Math.min(l,n),Math.min(m,p));0<(n-l)/(p-m)?(q.qa=0,q.ra=0,q.D=Math.abs(n-l),q.F=Math.abs(p-m)):(q.qa=0,q.ra=Math.abs(p-m),q.D=Math.abs(n-l),q.F=
0);h.nd=q;break;case "circle":n=g;h=new V;l=parseFloat(n.getAttribute("r"));isNaN(l)||0>l?h=null:(m=parseFloat(n.getAttribute("cx")),isNaN(m)&&(m=0),n=parseFloat(n.getAttribute("cy")),isNaN(n)&&(n=0),p=new zc(Mc),p.qa=0,p.ra=0,p.D=2*l,p.F=2*l,h.position=new w(m-l,n-l),h.nd=p);break;case "ellipse":p=g;h=new V;l=parseFloat(p.getAttribute("rx"));isNaN(l)||0>l?h=null:(m=parseFloat(p.getAttribute("ry")),isNaN(m)||0>m?h=null:(n=parseFloat(p.getAttribute("cx")),isNaN(n)&&(n=0),p=parseFloat(p.getAttribute("cy")),
isNaN(p)&&(p=0),q=new zc(Mc),q.qa=0,q.ra=0,q.D=2*l,q.F=2*m,h.position=new w(n-l,p-m),h.nd=q));break;case "rect":q=g;h=new V;l=parseFloat(q.getAttribute("width"));if(isNaN(l)||0>l)h=null;else if(m=parseFloat(q.getAttribute("height")),isNaN(m)||0>m)h=null;else{n=parseFloat(q.getAttribute("x"));isNaN(n)&&(n=0);p=parseFloat(q.getAttribute("y"));isNaN(p)&&(p=0);var r=q.getAttribute("rx"),s=q.getAttribute("ry"),q=parseFloat(r);if(isNaN(q)||0>q)q=0;var u=parseFloat(s);if(isNaN(u)||0>u)u=0;!r&&s?q=u:r&&!s&&
(u=q);q=Math.min(q,l/2);u=Math.min(u,m/2);s=void 0;0===q&&0===u?(s=new zc(Ec),s.qa=0,s.ra=0,s.D=l,s.F=m):(s=F.va/2,r=t.u(),I(r,q,0,!0),r.lineTo(l-q,0),J(r,l-q*s,0,l,u*s,l,u),r.lineTo(l,m-u),J(r,l,m-u*s,l-q*s,m,l-q,m),r.lineTo(q,m),J(r,q*s,m,0,m-u*s,0,m-u),r.lineTo(0,u),J(r,0,u*s,q*s,0,q,0),L(r),s=r.s,t.v(r));h.position=new w(n,p);h.nd=s}break;case "polygon":h=ar(g);break;case "polyline":h=ar(g)}if(null!==h){if(h instanceof V){l=Yq(a,g,"fill");null!==l&&-1!==l.indexOf("url")?(l=l.substring(l.indexOf("#")+
1,l.length-1),l=a["_brush"+l],h.fill=l instanceof ea?l:"black"):h.fill=null===l?"black":"none"===l?null:l;l=Yq(a,g,"stroke");null!==l&&-1!==l.indexOf("url")?(l=l.substring(l.indexOf("#")+1,l.length-1),l=a["_brush"+l],h.stroke=l instanceof ea?l:"black"):h.stroke="none"===l?null:l;l=parseFloat(Yq(a,g,"stroke-width"));isNaN(l)||(h.fb=l);l=Yq(a,g,"stroke-linecap");null!==l&&(h.wG=l);if(l=Yq(a,g,"stroke-dasharray")){m=l.split(",");n=[];for(l=0;l<m.length;l++)p=parseFloat(m[l]),!isNaN(p)&&0<p&&n.push(p);
h.zx=n}if(g=Yq(a,g,"stroke-dashoffset"))g=parseFloat(g),isNaN(g)||(h.xG=g);h.rA=!0}if(null!==k){k=k.split(")");g=!0;for(l=0;l<k.length;l++)/\(.*[^0-9\.,\s-]/.test(k[l])&&(g=!1),/\(.*[0-9]-[0-9]/.test(k[l])&&(g=!1);if(g)for(l=k.length-1;0<=l;l--)if(m=k[l],""!==m)switch(n=m.indexOf("("),g=m.substring(0,n).replace(/\s*/,""),n=m.substring(n+1).split(/\s*[\s,]\s*/),g){case "rotate":br(a,h,n);break;case "translate":g=h;m=parseFloat(n[0]);isNaN(m)&&(m=0);n=parseFloat(n[1]);isNaN(n)&&(n=0);if(0!==m||0!==
n)p=g.position.copy(),isNaN(p.x)&&(p.x=0),isNaN(p.y)&&(p.y=0),g.position=new w(m+p.x,n+p.y);break;case "scale":cr(a,h,n);break;case "skewX":dr(a,h,n);break;case "skewY":er(a,h,n);break;case "matrix":fr(a,h,n)}}if(h instanceof y){k=h.elements.k;l=g=0;m=h.position.copy();isNaN(m.x)&&(m.x=0);isNaN(m.y)&&(m.y=0);for(;k.next();)n=k.value.position.copy(),isNaN(n.x)&&(n.x=0),isNaN(n.y)&&(n.y=0),n.x<g&&(g=n.x),n.y<l&&(l=n.y);m.x+=g;m.y+=l;h.position=m}null===c?a.Vn.push(h):c.add(h)}}}if(null!==h){a=$q(b,
"visibility");if("hidden"===a||"collapse"===a)h.visible=!1;if(b=$q(b,"opacity"))b=parseFloat(b),isNaN(b)||(h.opacity=b)}}}
function fr(a,b,c){var d=parseFloat(c[0]),f=parseFloat(c[1]),g=parseFloat(c[2]),h=parseFloat(c[3]),k=parseFloat(c[4]),l=parseFloat(c[5]);if(!isNaN(d+f+g+h+k+l)){var m=b.position.copy();isNaN(m.x)&&(m.x=0);isNaN(m.y)&&(m.y=0);if(b instanceof V){c=b.nd.copy();if(c.type===Ec)c=a.Ds(c);else if(c.type===Mc)c=gr(c);else if(c.type===Dc){c.type=Ac;a=new Bc(c.qa,c.ra);var n=new id(Oc,c.D,c.F);a.Ea.add(n);c.Bb.add(a)}c.offset(m.x,m.y);c.transform(d,f,g,h,k-m.x,l-m.y);a=c.normalize();b.nd=c;m.x-=a.x;m.y-=a.y;
b.position=m}else{for(b=b.elements.k;b.next();)d=b.value.position.copy(),d.x+=m.x,d.y+=m.y,b.value.position=d;for(b.reset();b.next();)fr(a,b.value,c);for(b.reset();b.next();)d=b.value.position.copy(),d.x-=m.x,d.y-=m.y,b.value.position=d}}}
function br(a,b,c){var d=parseFloat(c[0]);isNaN(d)&&(d=0);var f=parseFloat(c[1]);isNaN(f)&&(f=0);var g=parseFloat(c[2]);isNaN(g)&&(g=0);if(0!==d){var h=d*Math.PI/180,k=b.position.copy();isNaN(k.x)&&(k.x=0);isNaN(k.y)&&(k.y=0);if(b instanceof V){c=b.nd.copy();c.type===Mc?c=gr(c):c.type===Ec&&(c=a.Ds(c));if(c.type===Ac)c.rotate(d,f-k.x,g-k.y),g=c.normalize(),b.nd=c,k.x-=g.x,k.y-=g.y,b.position=k;else{var d=c.qa-f+k.x,l=c.ra-g+k.y,m=c.D-f+k.x,n=c.F-g+k.y;a=d*Math.cos(h)-l*Math.sin(h)+f-k.x;d=l*Math.cos(h)+
d*Math.sin(h)+g-k.y;f=m*Math.cos(h)-n*Math.sin(h)+f-k.x;g=n*Math.cos(h)+m*Math.sin(h)+g-k.y;m=Math.min(a,f);n=Math.min(d,g);c.qa=a-m;c.ra=d-n;c.D=f-m;c.F=g-n;k.x+=m;k.y+=n;b.position=k;b.nd=c}b.fill instanceof ea&&(k=b.fill.copy(),c=Math.atan((0.5-k.start.y)/(0.5-k.start.x)),isNaN(c)||(c+=h,k.start=new H((1-Math.cos(c))/2,(1-Math.sin(c))/2),k.end=new H((1+Math.cos(c))/2,(1+Math.sin(c))/2)),b.fill=k);b.stroke instanceof ea&&(k=b.stroke.copy(),c=Math.atan((0.5-k.start.y)/(0.5-k.start.x)),isNaN(c)||
(c+=h,k.start=new H((1-Math.cos(c))/2,(1-Math.sin(c))/2),k.end=new H((1+Math.cos(c))/2,(1+Math.sin(c))/2)),b.stroke=k)}else{for(b=b.elements.k;b.next();)h=b.value.position.copy(),h.x+=k.x,h.y+=k.y,b.value.position=h;for(b.reset();b.next();)br(a,b.value,c);for(b.reset();b.next();)h=b.value.position.copy(),h.x-=k.x,h.y-=k.y,b.value.position=h}}}
function cr(a,b,c){var d=parseFloat(c[0]);isNaN(d)&&(d=1);var f=parseFloat(c[1]);isNaN(f)&&(f=d);if(1!==d||1!==f){var g=b.position.copy();isNaN(g.x)&&(g.x=0);isNaN(g.y)&&(g.y=0);if(b instanceof V)a=b.nd.copy(),g.x*=d,g.y*=f,b.position=g,a.scale(d,f),b.nd=a;else{for(b=b.elements.k;b.next();)d=b.value.position.copy(),d.x+=g.x,d.y+=g.y,b.value.position=d;for(b.reset();b.next();)cr(a,b.value,c);for(b.reset();b.next();)d=b.value.position.copy(),d.x-=g.x,d.y-=g.y,b.value.position=d}}}
function dr(a,b,c){var d=parseFloat(c[0]);if(!isNaN(d)){var d=d*Math.PI/180,f=b.position.copy();isNaN(f.x)&&(f.x=0);isNaN(f.y)&&(f.y=0);if(b instanceof V){c=b.nd.copy();if(c.type===Ec)c=a.Ds(c);else if(c.type===Mc)c=gr(c);else if(c.type===Dc){c.type=Ac;a=new Bc(c.qa,c.ra);var g=new id(Oc,c.D,c.F);a.Ea.add(g);c.Bb.add(a)}c.offset(f.x,f.y);c.transform(1,0,Math.tan(d),1,-f.x,-f.y);a=c.normalize();b.nd=c;f.x-=a.x;f.y-=a.y;b.position=f}else{for(b=b.elements.k;b.next();)d=b.value.position.copy(),d.x+=f.x,
d.y+=f.y,b.value.position=d;for(b.reset();b.next();)dr(a,b.value,c);for(b.reset();b.next();)d=b.value.position.copy(),d.x-=f.x,d.y-=f.y,b.value.position=d}}}
function er(a,b,c){var d=parseFloat(c[0]);if(!isNaN(d)){var d=d*Math.PI/180,f=b.position.copy();isNaN(f.x)&&(f.x=0);isNaN(f.y)&&(f.y=0);if(b instanceof V){c=b.nd.copy();if(c.type===Ec)c=a.Ds(c);else if(c.type===Mc)c=gr(c);else if(c.type===Dc){c.type=Ac;a=new Bc(c.qa,c.ra);var g=new id(Oc,c.D,c.F);a.Ea.add(g);c.Bb.add(a)}c.offset(f.x,f.y);c.transform(1,Math.tan(d),0,1,-f.x,-f.y);a=c.normalize();b.nd=c;f.x-=a.x;f.y-=a.y;b.position=f}else{for(b=b.elements.k;b.next();)d=b.value.position.copy(),d.x+=f.x,
d.y+=f.y,b.value.position=d;for(b.reset();b.next();)er(a,b.value,c);for(b.reset();b.next();)d=b.value.position.copy(),d.x-=f.x,d.y-=f.y,b.value.position=d}}}
function ar(a){var b=!1;if("polygon"===a.tagName)b=!0;else if("polyline"!==a.tagName)return null;var c=new V,d=a.getAttribute("points");a=new zc;var f=new B(Bc),g=d.split(/\s*[\s,]\s*/);if(4>g.length)return null;for(var h,d=new B(id),k,l,m=1;m<g.length;m+=2){k=eval(g[m-1]);l=eval(g[m]);if("number"!==typeof k||isNaN(k)||"number"!==typeof l||isNaN(l))return null;1===m?h=new Bc(k,l):d.add(new id(Oc,k,l))}b&&(b=new id(Oc,h.qa,h.ra),b.close(),d.add(b));h.Ea=d;f.add(h);a.Bb=f;h=a.normalize();c.position=
new w(-h.x,-h.y);c.nd=a;return c}
function gr(a){var b=a.qa,c=a.ra,d=a.D,f=a.F,g=Math.abs(d-b)/2,h=Math.abs(f-c)/2,b=Math.min(b,d)+g,c=Math.min(c,f)+h;new w;f=new Bc(b,c-h);d=new id(cd);d.Cb=b+F.va*g;d.Sb=c-h;d.re=b+g;d.se=c-F.va*h;d.D=b+g;d.F=c;f.Ea.add(d);d=new id(cd);d.Cb=b+g;d.Sb=c+F.va*h;d.re=b+F.va*g;d.se=c+h;d.D=b;d.F=c+h;f.Ea.add(d);d=new id(cd);d.Cb=b-F.va*g;d.Sb=c+h;d.re=b-g;d.se=c+F.va*h;d.D=b-g;d.F=c;f.Ea.add(d);d=new id(cd);d.Cb=b-g;d.Sb=c-F.va*h;d.re=b-F.va*g;d.se=c-h;d.D=b;d.F=c-h;f.Ea.add(d);a.type=Ac;a.Bb.add(f);
return a}Xq.prototype.Ds=function(a){var b=a.qa,c=a.ra,d=a.D,f=a.F,g=Math.min(b,d),h=Math.min(c,f),b=Math.abs(d-b),c=Math.abs(f-c),f=new Bc(g,h);f.Ea.add(new id(Oc,g+b,h));f.Ea.add(new id(Oc,g+b,h+c));f.Ea.add((new id(Oc,g,h+c)).close());a.type=Ac;a.Bb.add(f);return a};ba.version="1.4.27";
window&&(window.module&&"object"===typeof window.module&&"object"===typeof window.module.exports?window.module.exports=ba:window.define&&"function"===typeof window.define&&window.define.amd?(window.go=ba,window.define(ba)):window.go=ba); })(window);

/*** EXPORTS FROM exports-loader ***/
module.exports = go;
