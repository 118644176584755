/**
 * Created by moshemal on 1/13/15.
 */


define(['ember', 'kendo.ui.core'],
function (Ember) {
	'use strict';

	function merge () {
		var i, p, target,
				res = {},
				argc = arguments.length;
		for (i=0; i<argc; i++){
			target = arguments[i];
			for (p in target){
				if (target.hasOwnProperty(p)){
					res[p] = target[p];
				}
			}
		}
		return res;
	}

	function decorateCallbacks (kendoView, callbacks) {
		var p, baseCallback;
		for (p in callbacks){
			if (callbacks.hasOwnProperty(p)){
				baseCallback = callbacks[p].bind(kendoView);
				callbacks[p] = function(e){
					baseCallback(e);
				}
			}
		}
	}
	function getConfigurationProperties(view, propertiesNames) {
		var config 	= {};
		propertiesNames.forEach(function(propName){
			var propValue = view.get(propName);
			if (propValue !== undefined){
				config[propName] = propValue;
			}
		});
		return config;
	}

	function createComponent (componentName, tagName, confProperties, template, selector, isDidInsert) {
		function applyKendo(){
			var that = this;
			this._super(arguments);
			var config = getConfigurationProperties(this, confProperties);
			config.change = function(){
				that.set('value', this.value());
			};
			var kendoWidgetElem = $(this.$().find(selector)[0]);
			var kendoWidget = kendoWidgetElem[componentName](config).data(componentName);
			this.set('kendoWidget', kendoWidget);

//TODO: this solve a bug that happen when inserting the element to the dom after timeout
//			setTimeout(function(){
//				kendoWidget = kendoWidgetElem[componentName](config).data(componentName);
//				that.set('kendoWidget', kendoWidget);
//			}, 500);

		}
		var componentConfs = {
			tagName: 						tagName,
			layout: 						Ember.Handlebars.compile(template),
			willDestroyElement: function(){
				var kendoWidget = this.get('kendoWidget');
				//some widgets destroy throw exception
				try{
					kendoWidget.destroy();
				}catch (err) {}
				this._super(arguments);
			},
			_valueChanged: 			function(){
				var kendoWidget = this.get('kendoWidget');
				if (!kendoWidget){
					console ? console.error("kendoWidget is not defined") : "";
					return;
				}
				kendoWidget.value(this.get('value'));
			}.observes('value')
		};

		if (isDidInsert) {
			componentConfs.didInsertElement = applyKendo;
		}else {
			componentConfs.willInsertElement = applyKendo
		}
		return Ember.Component.extend(componentConfs);
	}

	function createComponents(componentsData, template, selector, isDidInsert){
		var res = {};
		componentsData.forEach(function(compData){
			res[compData.name || compData.className] = {
				tagName: compData.tagName,
				component: createComponent(compData.className, compData.tagName, compData.confProperties,
						compData.template || template, selector, isDidInsert)
			};
		});
		return res;
	}

	function createDidInsertComponents(componentsData, template, selector) {
		return createComponents (componentsData, template, selector, true);
	}

	return {
		decorateCallbacks:          decorateCallbacks,
		merge: 											merge,
		getConfigurationProperties: getConfigurationProperties,
		createComponents: 					createComponents,
		createDidInsertComponents: 	createDidInsertComponents
	}
});