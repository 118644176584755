/**
 * Created by moshemal on 1/14/15.
 */

/**
 * Created by moshemal on 1/13/15.
 */


define(['./common'],
function (common) {
	'use strict';

	var selectComponentsData = [
		{
			name: "kendoSelectComboBox",
			className: "kendoComboBox",
			tagName: 'kendo-select-combo-box',
			confProperties: ['animation', 'animation.close', 'animation.close.effects', 'animation.close.duration',
				'animation.open', 'animation.open.effects', 'animation.open.duration', 'autoBind', 'cascadeFrom',
				'cascadeFromField', 'dataSource', 'dataTextField', 'dataValueField', 'delay', 'enable', 'filter', 'height',
				'highlightFirst', 'ignoreCase', 'index', 'minLength', 'placeholder', 'suggest', 'headerTemplate', 'template',
				'text', 'value', 'valuePrimitive']
		},
		{
			name: "kendoSelectDropDownList",
			className: "kendoDropDownList",
			tagName: 'kendo-select-drop-down-list',
			confProperties: ['animation', 'animation.close', 'animation.close.effects', 'animation.close.duration',
				'animation.open', 'animation.open.effects', 'animation.open.duration', 'autoBind', 'cascadeFrom',
				'cascadeFromField', 'dataSource', 'dataTextField', 'dataValueField', 'delay', 'enable', 'filter', 'height',
				'ignoreCase', 'index', 'minLength', 'optionLabel', 'headerTemplate', 'template', 'valueTemplate', 'text',
				'value', 'valuePrimitive']
		},
		{
			className: "kendoMultiSelect",
			tagName: 'kendo-multi-select',
			confProperties: ['animation', 'animation.close', 'animation.close.effects', 'animation.close.duration',
				'animation.open', 'animation.open.effects', 'animation.open.duration', 'autoBind', 'autoClose',
				'dataSource', 'dataTextField', 'dataValueField', 'delay', 'enable', 'filter', 'height', 'highlightFirst',
				'ignoreCase', 'minLength', 'maxSelectedItems', 'placeholder', 'headerTemplate', 'itemTemplate',
				'tagTemplate', 'value', 'valuePrimitive']
		}
	];

	var defaultTemplate = '<select>{{yield}}</select>';

	return common.createComponents(selectComponentsData, defaultTemplate, 'select');
});