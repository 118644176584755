/**
 * Created by moshemal on 1/12/15.
 */


define(['./common', './Button', './inputComponents', './selectComponents', './divComponents', './TabStrip/TabStrip'],
function (common, Button, inputComponents, selectComponents, divComponents, TabStrip) {
  'use strict';
  var p;
  var components = common.merge(
    divComponents,
    inputComponents,
    selectComponents,
    {
      kendoTabStrip: TabStrip
    },
    {
      kendoButton: Button
    }
  );

  for (p in components) {
    var component = components[p];
    Ember.Handlebars.helper(component.tagName, component.component);
  }
  //for better performance, if Array generics are available use Array.slice
  return components;
});