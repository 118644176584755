/**
 * Created by moshemal on 1/15/15.
 */


define(['./../common', 'ember', 'text!./template.hbs', './DataSourceView'],
function (common, Ember, template, DataSourceView) {
	'use strict';



	var componentData =
		{
			className: "kendoTabStrip",
			tagName: 'kendo-tab-strip',
			confProperties: ['animation', 'animation.close', 'animation.close.duration', 'animation.close.effects',
				'animation.open', 'animation.open.duration', 'animation.open.effects', 'collapsible', 'contentUrls',
				'dataContentField', 'dataContentUrlField', 'dataImageUrlField', 'dataSpriteCssClass', 'dataTextField',
				'dataUrlField', 'navigatable', 'contentUrls'], //, 'dataSource']
      events: ['activate', 'contentLoad', 'error', 'select', 'show']
		};
	var componentName = "kendoTabStrip",
	    selector			= "div.kendo-tab-strip-root";


	function willDestroyElement () {
		this.get('kendoWidget').destroy();
		this._super();
	}

	function setDefaults (config) {
		config.dataContentField 	  = config.dataContentField    ? config.dataContentField    : "content";
		config.dataTextField			  = config.dataTextField       ? config.dataTextField       : "text";
		config.dataImageUrlField	  = config.dataImageUrlField   ? config.dataImageUrlField   : "imageUrl";
		config.dataContentUrlField	= config.dataContentUrlField ? config.dataContentUrlField : "contentUrl";
	}

	function applyKendoWidget(view){
    var kendoWidgetElem = $(view.$().find(selector)[0]);
		var config = common.getConfigurationProperties(view, componentData.confProperties);
		var events = common.getConfigurationProperties(view, componentData.events);
		common.decorateCallbacks(view, events);
		setDefaults(config);
		var kendoWidget = kendoWidgetElem[componentName](common.merge(config, events)).data(componentName);
		view.set('kendoWidget', kendoWidget);
		return kendoWidget;
	}

	function didInsertElement () {
		applyKendoWidget(this).select(0);
	}

	function _dataSourceChanged(){
		var kendoWidget 		= this.get('kendoWidget');
		kendoWidget.destroy();
		var that = this;
		Ember.run.scheduleOnce('afterRender', function(){
			applyKendoWidget(that).select(0);
		});
	}

	function applyKendoMethod(){
		var kendoWidget 		= this.get('kendoWidget');
		var method = arguments[0];
		var args = Array.prototype.slice.call(arguments, 1);
		kendoWidget[method].apply(kendoWidget, args);
	}

	function getCurrentIndex() {
		var widget = this.get("kendoWidget");
		var index = widget.select().attr("aria-controls").split("-")[1];
		return index - 1;
	}

	var componentConfs = {
		getCurrentIndex:    getCurrentIndex,
		applyKendoMethod:   applyKendoMethod,
		dataSourceView: 		DataSourceView,
		tagName: 						componentData.tagName,
		layout: 						Ember.Handlebars.compile(template),
		willDestroyElement:  willDestroyElement,
		//willInsert is not working since the sub views are not created yet
		didInsertElement:	didInsertElement,
		_dataSourceChanged: _dataSourceChanged.observes('dataSource.@each')
	};

	var TabStrip = Ember.Component.extend(componentConfs);

	return {
		tagName: 'kendo-tab-strip',
		component: TabStrip
	}
});