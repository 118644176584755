var cbs = [], 
	data;
module.exports = function(cb) {
	if(cbs) cbs.push(cb);
	else cb(data);
}
require.ensure([], function(require) {
	data = require("!!../../node_modules/exports-loader/dist/cjs.js??ruleSet[1].rules[4].use[0]!../../node_modules/imports-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!./ember.prod.js");
	var callbacks = cbs;
	cbs = null;
	for(var i = 0, l = callbacks.length; i < l; i++) {
		callbacks[i](data);
	}
});