/**
 * Created by moshemal on 1/13/15.
 */


define(['./common'],
function (common) {
	'use strict';

	var inputComponentsData = [
		{
			className: "kendoDatePicker",
			tagName: 'kendo-date-picker',
			confProperties: ['animation', 'animation.close', 'animation.close.effects',
				'animation.close.duration', 'animation.open', 'animation.open.effects', 'animation.open.duration',
				'ARIATemplate', 'culture', 'dates', 'depth', 'footer', 'format', 'max', 'min', 'month',
				'month.content', 'month.empty', 'parseFormats', 'start', 'value']
		},
		{
			className: "kendoDateTimePicker",
			tagName: 'kendo-date-time-picker',
			confProperties: ['animation', 'animation.close', 'animation.close.effects', 'animation.close.duration',
				'animation.open', 'animation.open.effects', 'animation.open.duration', 'ARIATemplate', 'culture', 'dates',
				'depth', 'footer', 'format', 'interval', 'max', 'min', 'month', 'month.content', 'month.empty',
				'parseFormats', 'start', 'timeFormat', 'value']
		},
		{
			className: "kendoTimePicker",
			tagName: 'kendo-time-picker',
			confProperties: ['animation', 'animation.close', 'animation.close.effects', 'animation.close.duration',
				'animation.open', 'animation.open.effects', 'animation.open.duration', 'ARIATemplate', 'culture', 'dates',
				'depth', 'footer', 'format', 'interval', 'max', 'min', 'month', 'month.content', 'month.empty',
				'parseFormats', 'start', 'timeFormat', 'value']
		},
		{
			className: "kendoColorPicker",
			tagName: 'kendo-color-picker',
			confProperties: ['buttons', 'columns', 'tileSize', 'tileSize.width', 'tileSize.height', 'messages',
				'messages.apply', 'messages.cancel', 'palette', 'opacity', 'preview', 'toolIcon', 'value']
		},
		{
			className: "kendoAutoComplete",
			tagName: 'kendo-auto-complete',
			confProperties: ['animation', 'animation.close', 'animation.close.duration', 'animation.close.effects',
				'animation.open', 'animation.open.duration', 'animation.open.effects', 'dataSource', 'dataTextField',
				'delay', 'enable', 'filter', 'height', 'highlightFirst', 'ignoreCase', 'minLength', 'placeholder',
				'separator', 'suggest', 'headerTemplate', 'template', 'valuePrimitive']
		},
		{
			className: "kendoComboBox",
			tagName: 'kendo-combo-box',
			confProperties: ['animation', 'animation.close', 'animation.close.effects', 'animation.close.duration',
				'animation.open', 'animation.open.effects', 'animation.open.duration', 'autoBind', 'cascadeFrom',
				'cascadeFromField', 'dataSource', 'dataTextField', 'dataValueField', 'delay', 'enable', 'filter', 'height',
				'highlightFirst', 'ignoreCase', 'index', 'minLength', 'placeholder', 'suggest', 'headerTemplate', 'template',
				'text', 'value', 'valuePrimitive']
		},
		{
			className: "kendoDropDownList",
			tagName: 'kendo-drop-down-list',
			confProperties: ['animation', 'animation.close', 'animation.close.effects', 'animation.close.duration',
				'animation.open', 'animation.open.effects', 'animation.open.duration', 'autoBind', 'cascadeFrom',
				'cascadeFromField', 'dataSource', 'dataTextField', 'dataValueField', 'delay', 'enable', 'filter', 'height',
				'ignoreCase', 'index', 'minLength', 'optionLabel', 'headerTemplate', 'template', 'valueTemplate', 'text',
				'value', 'valuePrimitive']
		},
		{
			className: "kendoMaskedTextBox",
			tagName: 'kendo-masked-text-box',
			confProperties: ['clearPromptChar', 'culture', 'mask', 'promptChar', 'rules', 'value']
		},
		{
			className: "kendoNumericTextBox",
			tagName: 'kendo-numeric-text-box',
			confProperties: ['culture', 'decimals', 'downArrowText', 'format', 'max', 'min', 'placeholder', 'spinners',
				'step', 'upArrowText', 'value']
		}
	];

	var inputDidInsertComponentsData = [
		{
			className: "kendoSlider",
			tagName: 'kendo-slider',
			confProperties: ['decreaseButtonTitle', 'increaseButtonTitle', 'largeStep', 'max', 'min', 'orientation',
				'showButtons', 'smallStep', 'tickPlacement', 'tooltip', 'tooltip.enabled', 'tooltip.format', 'tooltip.template', 'value']
		}
	];

	var defaultTemplate = '<input>';

	var components = common.createComponents(inputComponentsData, defaultTemplate, 'input');
	var didInsetComponents = common.createDidInsertComponents(inputDidInsertComponentsData, defaultTemplate, 'input');

	return common.merge(components, didInsetComponents);
});