/**
 * Created by moshemal on 1/11/15.
 */


define(['./common', 'ember'],
function (common, Ember) {
	'use strict';

	var template = '<button type="button">{{yield}}</button>';

	var confProperties = ['enable', 'icon', 'imageUrl', 'spriteCssClass'];



	function willInsertElement(){
		var config 	= common.getConfigurationProperties(this, confProperties);
		var kendoButton = this.$().find('button').kendoButton(config).data("kendoButton");
		this.set("kendoWidget", kendoButton);
	}

	var Button = Ember.Component.extend({
		tagName: 'kendo-button',
		layout: Ember.Handlebars.compile(template),
		willInsertElement: willInsertElement,
		willDestroyElement: function(){
			this.get('kendoWidget').destroy();
			this._super(arguments);
		}
	});
	return {
		tagName: 'kendo-button',
		component: Button
	};
});