/**
 * Created by moshemal on 1/25/15.
 */


define(['ember', 'text!./dataSource.hbs'],
function (Ember, template) {
	'use strict';

	var TabList = Ember.CollectionView.extend({
		tagName: 'ul',
		itemViewClass: Ember.View.extend({
			layout: Ember.Handlebars.compile('{{view.content.text}}')
		})
	});

	var ContentView = Ember.ContainerView.extend({
		init: function(){
			this._super(arguments);
			var dataSourceElem = this.get('dataSourceElem');
			var view = dataSourceElem.view;

			if (view instanceof Ember.View){

			} else if (dataSourceElem.viewClass !== undefined){
				view = dataSourceElem.viewClass.create(dataSourceElem.viewInitObj);
			} else {
				view = Ember.View.extend({
					layout: Ember.Handlebars.compile(dataSourceElem.content || "")
				}).create({})
			}
			dataSourceElem.view = view;
			this.pushObject(view);
		}
	});

	return Ember.View.extend({
		layout: Ember.Handlebars.compile(template),
		tabListView: TabList,
		contentView: ContentView
	});
});