/**
 * Created by moshemal on 1/15/15.
 */


define(['./common'],
function (common) {
  'use strict';

	var componentsData = [
		{
			className: "kendoProgressBar",
			tagName: 'kendo-progress-bar',
			confProperties: ['animation', 'animation.duration', 'chunkCount', 'enable', 'max',
				'min', 'orientation', 'reverse', 'showStatus', 'type', 'value']
		}
	];

	var defaultTemplate = '<div>{{yield}}</div>';

	return common.createComponents(componentsData, defaultTemplate, 'div');
});